import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import get from 'lodash/get';

import { useTranslation } from 'shared/hooks/useTranslation';
import type { Translations } from 'shared/utils/translations';
import { FormToggleButton } from 'shared/components/FormToggleButton';

import { ToggleOption, MultiSelectOption } from '../car-status-data';
import { EquipmentMultiSelect } from '../components/EquipmentMultiSelect';
import type { EquipmentFields } from '../types'
import styles from '../index.module.scss';

const booleanOptions = ({ source, id }: { source: string, id: number }, translations: Translations) => [
  {
    value: `${source},${id}`,
    label: translations.YES,
  },
  {
    value: ToggleOption.NO,
    label: translations.NO,
  },
];

type itemOption = { label: string, value: string };
const addNotPresentOption = (translations: Translations, items: itemOption[]) => [
  {
    label: translations.NOT_PRESENT,
    value: MultiSelectOption.NOT_PRESENT,
  },
  ...items,
];

type Props = {
  qaIdPrefix: string,
  index: number,
} & EquipmentFields;

const EquipmentSelector: React.FC<Props> = ({
  translationKey,
  name,
  items,
  isToggleField,
  qaIdPrefix,
  index,
}) => {
  const { translate, translations } = useTranslation();
  const { register, unregister, setValue } = useFormContext();
  const transform = React.useCallback(
    (value) => {
      const label = value
        .map((sourceId = '') => {
          if (sourceId === MultiSelectOption.NOT_PRESENT) {
            return translations.NOT_PRESENT;
          }

          const [, id] = sourceId.split(',');
          return translate(
            get(
              items ? items?.find((item) => item.id === Number(id)) : '',
              'description',
              '',
            ),
          );
        })
        .join(', ');
      return label;
    },
    [items, translate, translations.NOT_PRESENT],
  );

  const toggleTransform = React.useCallback((value) => {
    if (!value) {
      return '';
    }

    if (!Array.isArray(value)) {
      return value;
    }

    if (value.length) {
      return value[0];
    }

    return '';
  }, []);

  const isNoneOrNotPresentOption = React.useCallback(
    (selected) => {
      if (selected === MultiSelectOption.NOT_PRESENT) {
        return true;
      }

      const [, id] = selected.split(',');
      const description = get(
        items?.find((item) => item.id === Number(id)),
        'description',
        '',
      );
      return description?.endsWith('.None');
    },
    [items],
  );

  const handleValidateSelection = React.useCallback(
    (value) => {
      if (value === undefined || value === null) {
        return [];
      }

      if (value.length <= 1) return value;

      const [first, ...rest] = value;

      if (isNoneOrNotPresentOption(first)) return rest;
      const [last] = value.slice(-1);

      if (isNoneOrNotPresentOption(last)) return [last];

      return value;
    },
    [isNoneOrNotPresentOption],
  );

  React.useEffect(() => {
    register(`equipments.items[${index}].isToggle`);
    register(`equipments.items[${index}].name`);

    return () => {
      unregister(`equipments.items[${index}].isToggle`);
      unregister(`equipments.items[${index}].name`);
    };
  }, [index, register, unregister]);

  React.useEffect(() => {
    setValue(`equipments.items[${index}].isToggle`, isToggleField);
    setValue(`equipments.items[${index}].name`, name);
  }, [isToggleField, name, setValue, index]);

  if (
    typeof items === 'undefined' ||
    items === null ||
    items.length === 0
  ) {
    return null;
  }

  if (isToggleField) {
    return (
      <FormToggleButton
        label={translate(translationKey as string)}
        name={`equipments.items[${index}].value`}
        qaIdPrefix={qaIdPrefix}
        options={booleanOptions(items![0], translations)}
        transform={toggleTransform}
        defaultValue={null}
      />
    );
  }

  return (
    <EquipmentMultiSelect
      label={translate(translationKey as string)}
      name={`equipments.items[${index}].value`}
      placeholder={translations.PLEASE_SELECT}
      qaIdPrefix={qaIdPrefix}
      transform={transform}
      onValidateSelection={handleValidateSelection}
      options={addNotPresentOption(
        translations,
        items!
          .filter((item) => Boolean(item.description))
          .map(({ description, id, source }) => ({
            label: translate(description as string),
            value: `${source},${id}`,
          })),
      )}
      defaultValue={null}
      containerClassName={styles.inputContainerFullWidth}
    />
  );
};

export { EquipmentSelector };
