import * as React from 'react';
import { Dropdown, DropdownOption } from '@auto1-ui/dropdown';
import cn from 'classnames';
import styles from './index.module.scss';

import { withFormItem } from '../withFormItem';

type DropdownProps = React.ComponentProps<typeof Dropdown>;
type Option<V> = Omit<DropdownOption, 'value'> & {
  value: V;
};

type Props<T extends { [key: string]: unknown; value: unknown }> = T extends {
  [key: string]: unknown;
  value: infer V;
}
  ? Omit<DropdownProps, 'value' | 'defaultValue' | 'options' | 'onChange'> & {
    options?: ReadonlyArray<Option<V>>;
    value?: V;
    defaultValue?: V;
    className?: string;
    footerHeight?: number;
    onChange: (value: V) => void;
  }
  : never;

function toValueAsString<V>({
  value,
  ...rest
}: Option<V>): DropdownOption & { originalValue: V } {
  return {
    ...rest,
    value: `${value}`,
    originalValue: value,
  };
}

function DropdownCustom<T extends { [key: string]: unknown; value: unknown }>({
  className,
  listClassName,
  options = [],
  value,
  defaultValue,
  footerHeight = 92,
  onChange,
  ...props
}: Props<T>) {
  const ops = options.map(toValueAsString);

  return (
    <Dropdown
      {...props}
      inputClassname={cn(className, styles.container)}
      listClassName={cn(styles.list, listClassName)}
      options={ops}
      footerHeight={footerHeight}
      value={value !== null && value !== undefined ? `${value}` : ''}
      onChange={(val) => {
        const selectedValue = ops.find((op) => op.value === val);

        if (!selectedValue) {
          console.error('selected value does not exist');
          return;
        }

        onChange(selectedValue.originalValue);
      }}
    />
  );
}

const FormDropdown = withFormItem(DropdownCustom, {
  classNames: {
    error: styles.error,
  },
});

export { FormDropdown };
