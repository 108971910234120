import { getAuthToken, refreshToken } from 'auth/tokenManager';
import { imageServiceBaseUrl } from 'config/urls';
import type { UploadResponse, UploadResponseError } from 'shared/utils/types';
import { useUploadPool } from 'shared/utils/uploadPool';

export type UploadData = {
  stockNumber?: string | null;
  file: Blob;
  part: string;
  source: number;
};

function getFetch() {
  const baseUrl = imageServiceBaseUrl();
  const baseUrlSanitized = baseUrl.endsWith('/')
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl;
  const authToken = getAuthToken();

  return function (
    url: Parameters<typeof window.fetch>[0],
    options: Parameters<typeof window.fetch>[1] = {},
  ) {
    return window.fetch(`${baseUrlSanitized}${url}`, {
      ...options,
      headers: authToken
        ? {
            ...options.headers,
            Authorization: `Bearer ${authToken}`,
          }
        : options.headers,
    });
  };
}

function isFulfilledPromiseResult<T>(
  promiseResult: PromiseSettledResult<T>,
): promiseResult is PromiseFulfilledResult<T> {
  return promiseResult.status === 'fulfilled';
}

function isNotFalse<T>(item: T | false): item is T {
  return item !== false;
}

async function upload(url: string, data: UploadData) {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(
      key,
      value === null ? '' : typeof value === 'number' ? `${value}` : value,
    );
  });

  const fetch = getFetch();
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  try {
    const responseData: UploadResponse | UploadResponseError =
      await response.json();

    if (response.status === 200) {
      return { data: responseData as UploadResponse, mimeType: data.file.type };
    }

    throw new Error('Error uploading file');
  } catch {
    throw new Error('Error uploading file');
  }
}

function useMultipleMediaUploader(url: string) {
  const { uploadPool } = useUploadPool();
  const uploadMedia = (
    itemsToUpload: ReadonlyArray<UploadData>,
    attempts = 0,
  ) => {
    const fetchPromises = itemsToUpload.map((data) =>
      uploadPool.add(() => upload(url, data)),
    );
    const hasSupportForAllSettled = 'allSettled' in Promise;
    const settledPromises = hasSupportForAllSettled
      ? Promise.allSettled(fetchPromises)
      : Promise.all(
          fetchPromises.map((promise) =>
            promise
              .then((value) => ({ status: 'fulfilled' as const, value }))
              .catch((reason) => ({ status: 'rejected' as const, reason })),
          ),
        );
    return settledPromises.then(async (results): Promise<
      Array<{ data: UploadResponse; mimeType: string }>
    > => {
      const successfullUploads = results.map((result) =>
        isFulfilledPromiseResult(result) ? result.value : null,
      );
      const failedUploads = results
        .map((result, index) =>
          'reason' in result ? itemsToUpload[index] : false,
        )
        .filter(isNotFalse);

      if (failedUploads.length) {
        if (attempts < 3) {
          await refreshToken();

          const failedReAttempteds = await uploadMedia(
            failedUploads,
            attempts + 1,
          );
          let failedIndex = -1;
          return successfullUploads.map((uploadResult) => {
            if (uploadResult !== null) {
              return uploadResult;
            }

            failedIndex += 1;
            return failedReAttempteds[failedIndex];
          });
        }

        throw new Error('Some files could not be uploaded');
      }

      return successfullUploads as Array<{
        data: UploadResponse;
        mimeType: string;
      }>;
    });
  };

  return {
    uploadMedia,
  };
}

export { useMultipleMediaUploader };
