import * as React from 'react';

import { MediaUploader } from '@auto1-ui/mediauploader';

import type { VehicleImageArea, VehicleImagePart } from 'gql/graphql';
import { Modal } from 'shared/components/Modal';
import { ImagePreview } from 'shared/components/ImagePreview';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { useCarGeneralPictureItem } from './useCarGeneralPictureItem';
import styles from './index.module.scss';

type Props = {
  name: string;
  image: { absoluteUrl: string };
  index: number;
  mediaUrl: string;
  mediaData: { stockNumber: string; part: VehicleImagePart };
  area: VehicleImageArea;
  placeholder?: React.ReactNode;
  qaIdPrefix?: string;
};

function handleChange({
  target: { files },
}: {
  target: { files: ReadonlyArray<File> };
}) {
  if (!files || !files.length) return '';
  const [file] = files;
  return file;
}

const CarGeneralPictureItem: React.FC<Props> = ({
  name,
  image,
  index,
  mediaUrl,
  mediaData,
  area,
  placeholder = '',
  qaIdPrefix = '',
}) => {
  const { translations } = useTranslation();
  const { inspectionId, stockNumber } = useInspection();

  const {
    ref,
    isOpen,
    buttons,
    setIsOpen,
    isOpenConfirmation,
    handleCancelConfirmation,
    handleDeleteConfirmation,
    handleUploadMedia,
  } = useCarGeneralPictureItem({
    area,
    sourceType: 'image',
    name,
    mediaUrl,
    mediaData,
    selectedImageIndex: index,
  });

  return (
    <>
      <div
        ref={ref}
        data-qa-id={`${qaIdPrefix}-car-picture-item`}
        className={styles.col}
      >
        <MediaUploader
          onUpload={(e) => {
            handleUploadMedia(handleChange(e));
          }}
          imageSrc={image.absoluteUrl}
          qaIdPrefix={`${qaIdPrefix}-car-picture-item`}
          sourceType="image"
          inputProps={{
            accept: 'image/png, image/jpeg, image/heic, image/heif',
          }}
          placeHolder={placeholder}
          onClickIcon={() => {
            window.tracking.eva.previewPhotoCarPhotos({
              area,
              inspectionId,
              stockNumber,
            });
            setIsOpen(true);
          }}
        />
      </div>

      <ImagePreview
        isOpen={isOpen}
        source={image}
        handleClose={() => setIsOpen(false)}
        buttons={buttons}
      />

      <Modal
        isOpen={isOpenConfirmation}
        onOk={handleDeleteConfirmation}
        title={translations.ARE_YOU_SURE_YOU_WANT_TO_DELETE}
        okLabel={translations.DELETE}
        onCancel={handleCancelConfirmation}
      />
    </>
  );
};

export { CarGeneralPictureItem };
