import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';

import { Button, ButtonTypes } from '@auto1-ui/button';
import { FormProvider } from 'react-hook-form';

import { useScrollToSection } from 'shared/utils/useSectionScroll';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useSideMenuNavigationTracking } from 'shared/hooks/useSideMenuNavigationTracking';
import { appRoutes } from 'appRoutes';
import styles from 'shared/components/NavigationFooter/index.module.scss';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import Loader from 'shared/components/Loader';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import { useInspection } from 'shared/components/InspectionContext';
import { useCarStatus } from './hooks/useCarStatus';
import { Equipment } from './Equipment';
import { OtherEquipment } from './OtherEquipment';
import { OtherInformation } from './OtherInformation';
import { Placeholder } from './Placeholder';
import { AlertBar } from 'shared/components/AlertBar';
import type { Translations } from 'shared/utils/translations';

type Props = {
  qaIdPrefix?: string;
};

const CarStatus: React.FC<Props> = ({ qaIdPrefix = 'car-status' }) => {
  const location =
    useLocation<{
      prev?: {
        pathname: string;
        hash: string;
      };
      sideMenuNavigation?: boolean;
    }>();
  const { inspectionId } = useInspection();

  useScrollToSection(location.hash);
  useSideMenuNavigationTracking(
    location.state && location.state.prev,
    location,
  );

  const {
    loading,
    formMethods,
    submitContext,
    handleSubmit: onSubmit,
    onSubmitError,
    mandatoryEquipments,
    mutationError,
  } = useCarStatus();

  const { scroll } = useScrollToSection(location.hash);
  React.useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (!loading && mandatoryEquipments.length) {
      timeoutId = setTimeout(scroll, 100);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading, mandatoryEquipments.length, scroll]);

  const { translations } = useTranslation();
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  if (loading) {
    return <Placeholder />;
  }

  return (
    <LayoutContainer>
      <FormProvider {...formMethods}>
        <Equipment equipments={mandatoryEquipments} qaIdPrefix={qaIdPrefix} />
        <OtherEquipment qaIdPrefix={qaIdPrefix} />
        <OtherInformation qaIdPrefix={`${qaIdPrefix}-other-information`} />
        <NavigationFooter
          backButtonLink={appRoutes.tiresBrakes(`${inspectionId}`, true)}
          qaPrefix={qaIdPrefix}
          rightAreaContent={
            <>
              <Button
                type={ButtonTypes.button}
                theme="outlined"
                isActivated={false}
                qaId={`${qaIdPrefix}-footer-save-link`}
                extraClass={styles.saveButton}
                onClick={() => {
                  submitContext.current = 'save';
                  handleSubmit(onSubmit, onSubmitError)();
                }}
                isDisabled={isSubmitting}
              >
                {translations.SAVE}
                {isSubmitting && submitContext.current === 'save' && (
                  <Loader extraClass={styles.saveButtonLoader} />
                )}
              </Button>
              <Button
                type={ButtonTypes.button}
                theme="orange"
                extraClass={styles.nextButton}
                qaId={`${qaIdPrefix}-footer-next-link`}
                isActivated={false}
                onClick={() => {
                  submitContext.current = 'submit';
                  handleSubmit(onSubmit, onSubmitError)();
                }}
                isDisabled={isSubmitting}
              >
                {translations.NEXT}
                {isSubmitting && submitContext.current === 'submit' && (
                  <Loader extraClass={styles.nextButtonLoader} />
                )}
              </Button>
            </>
          }
          bottomAreaContent={
            mutationError && (
              <AlertBar
                type="error"
                message={
                  mutationError in translations
                    ? translations[mutationError as keyof Translations]
                    : translations.GENERIC_ERROR_MESSAGE
                }
                additionalClassNames={styles.alertBar}
              />
            )
          }
        />
        <RouteLeavingGuard when={isDirty} title={translations.UNSAVED_CHANGES}>
          <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
        </RouteLeavingGuard>
      </FormProvider>
    </LayoutContainer>
  );
};

const CarStatusWithTransaction = withTransaction(
  'CarStatus',
  'component',
)(CarStatus);

export { CarStatusWithTransaction as CarStatus };
