import * as React from 'react';
import { CheckoutTypeTypes } from 'shared/constants';
import type { CheckoutTypeType, InsantOfferStatusesType } from '../types';
import { AuctionSelectorPage } from '../constants';

const useAuctionSelector = () => {
  const [showAuctionSelectorPage, setShowAuctionSelectorPage] =
    React.useState(false);

  function isBatchAuctionEnabled(
    checkoutTypes: ReadonlyArray<CheckoutTypeType> = [],
  ) {
    return checkoutTypes.some(
      ({ type, enabled }) => type === CheckoutTypeTypes.BATCH_AUCTION && enabled,
    );
  }

  function getAuctionSelectorPage(
    { checkoutTypes, instantOfferButtonStatus }:
      { checkoutTypes: ReadonlyArray<CheckoutTypeType>, instantOfferButtonStatus: InsantOfferStatusesType }
  ) {
    const isChooseAuctionEnabled = [CheckoutTypeTypes.AUCTION, CheckoutTypeTypes.DIRECT_AUCTION].every(
      (checkoutType) =>
        checkoutTypes.some(
          ({ type, enabled }) => type === checkoutType && enabled,
        ),
    );
    if (isChooseAuctionEnabled) {
      return AuctionSelectorPage.ChooseAuctionPage;
    }

    if (isBatchAuctionEnabled(checkoutTypes) || !instantOfferButtonStatus?.isInvisible) {
      return AuctionSelectorPage.SellingChannelPage;
    }
    return null;
  }

  function isFullServiceAllowed(
    checkoutTypes: ReadonlyArray<CheckoutTypeType> = [],
  ) {
    // workaround on FE side to display the 24H auction without checking checkoutType
    return checkoutTypes.some(
      ({ type }) => type === CheckoutTypeTypes.AUCTION,
    );
  }

  return {
    getAuctionSelectorPage,
    isFullServiceAllowed,
    showAuctionSelectorPage,
    setShowAuctionSelectorPage,
    isBatchAuctionEnabled,
  };
};

export { useAuctionSelector };
