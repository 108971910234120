import * as React from 'react';
import { Card } from 'shared/components/Card';
import styles from './index.module.scss';
import { Typography } from '../../shared/components/Typography';
import { useTranslation } from '../../shared/hooks/useTranslation';

type Props = {
  qaPrefix?: string;
};

const UnAuthorizedPage: React.FC<Props> = ({
  qaPrefix = 'unauthorized-page',
}) => {
  const { translations } = useTranslation();

  return (
    <Card externalStyle={styles.carInformationCard}>
      <Typography
        variant="titleXL"
        tag="div"
        data-qa-id={`${qaPrefix}-heading`}
        additonalClassNames={styles.heading}
      >
        403
      </Typography>
      <Typography
        variant="textSmall"
        tag="div"
        data-qa-id={`${qaPrefix}-subheading`}
        additonalClassNames={styles.subheading}
      >
        {translations.UNAUTHORIZED_PAGE_MESSAGE}
      </Typography>
    </Card>
  );
};

export { UnAuthorizedPage };
