import { matchPath } from 'react-router-dom';
import { appRoutes } from '../../appRoutes';

const useIsBranchManagerPage = (location: string) => {
  if (
    matchPath(location, {
      path: appRoutes.locationsList(),
      exact: true,
    }) !== null
  ) {
    return true;
  }

  if (
    matchPath(location, {
      path: appRoutes.editLocation(),
      exact: true,
    }) !== null
  ) {
    return true;
  }

  if (
    matchPath(location, {
      path: appRoutes.addLocation(),
      exact: true,
    }) !== null
  ) {
    return true;
  }

  return false;
};

export { useIsBranchManagerPage };
