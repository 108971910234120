import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { FormToggleButtonPlaceholder } from 'shared/components/FormToggleButton/Placeholder';
import styles from './index.module.scss';

const AccidentsPlaceholder: React.FC = () => (
  <Card
    paddingTop={32}
    paddingBottom={32}
    id="accidents"
    qaIdPrefix="accidents-loader"
    showBottomBorder
  >
    <div className={styles.title}>
      <Typography variant="titleXL">
        <Skeleton width="100px" height="100%" />
      </Typography>
    </div>
    <div className={styles.formElementContainer}>
      <FormToggleButtonPlaceholder width="216px" />
    </div>
  </Card>
);

export { AccidentsPlaceholder };
