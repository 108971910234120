import * as React from 'react';
import styles from './index.module.scss';

const FrontRightSide: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 175 0)">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g className={styles.stroke} strokeWidth="1.5" mask="url(#prefix__d)">
          <g transform="matrix(-1 0 0 1 175.972 6.843)">
            <path d="M76.035 109.337c15.302 5.904 33.185 7.04 53.65 3.409 3.946-2.777 6.883-7.365 17.093-13.093s16.93-16.631 17.819-18.399c.89-1.767 3.637-31.031 2.686-33.243-.95-2.212-9.332-8.704-15.552-11.33-6.22-2.626-47.357-11.015-49.334-12.252-1.976-1.237-21.144-13.38-25.22-16.006C73.1 5.796 54.02 2.505 24.068 1.375 21.644 1.375 22.085 0 17.33 0 14.156 0 8.38 2.808 0 8.423V57.41l38.397 27.486c-3.705-14.969-2.146-24.693 4.676-29.171 16.302-5.928 33.205 26.193 34.103 50.778l-1.141 2.834z" />
            <path d="M160.98 42.614c-21.608 6.056-33.132 9.878-34.57 11.468-2.158 2.383 2.025 19.19 12.76 24.579 3.381 0 14.447.089 20.591-4.06 4.096-2.766 6.268-5.298 6.514-7.597M119.223 59.412c-13.456-6.194-38.774-9.77-42.255-5.627-3.48 4.143 3.499 16.36 8.23 18.283 4.733 1.924 17.995 8.23 34.025 6.593 4.806-1.287 7.834-2.649 9.102-3.596 0 0-2.465-11.52-9.102-15.653zM77.648 11.047c.93.616 7.582 5.6 19.955 14.954-31.574-1.344-52.222-1.344-61.943 0-2.183 0-8.427-14.48-11.346-20.613-.544-1.144 25.3-.382 33.46.876s17.94 3.417 19.874 4.783zM27.861 27.672C23.181 13.6 19.904 6.09 18.028 5.143c-1.56-.786-3.728-1.265-5.03-.632 0 0-4.619 9.568-4.619 18.891l19.482 4.27zM10.838 5.08c0-.462-4.038.956-5.98 2.631 0 0-3.886 2.685-3.886 13.673l5.826 1.58c.184-6.294 1.53-12.255 4.04-17.885z" />
            <path
              className={styles.fill}
              d="M21.313 24.43s.178-6.605-2.469-7.19c-2.647-.587-14.665-.354-15.296 2.685-.631 3.04-.856 7.257 2.95 7.747 3.805.49 18.48.562 19.225 0 .746-.562-.088-3.243-4.41-3.243z"
            />
            <ellipse
              cx="56.18"
              cy="89.516"
              rx="13.84"
              ry="28.566"
              transform="rotate(-16 56.18 89.516)"
            />
            <path d="M149.784 81.37c.757-.906 12.392-3.4 15.856-9.95.443-.789.198 1.813-.735 7.804-10.09 8.209-15.379 12.313-15.864 12.313-.729 0-.014-9.26.743-10.166zM82.957 52.121C70.363 43.595 55.195 37.11 37.452 32.666c-1.893-1.024-3.33-4.599-2.448-7.245" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { FrontRightSide };
