import type { Translations } from 'shared/utils/translations';

const wheelPositionMapper = Object.freeze({
  FRONT_LEFT: 'FRONT_LEFT_WHEEL',
  FRONT_RIGHT: 'FRONT_RIGHT_WHEEL',
  REAR_LEFT: 'REAR_LEFT_WHEEL',
  REAR_RIGHT: 'REAR_RIGHT_WHEEL',
});

const rimTypeEnum = Object.freeze({
  WITHOUT_RIMS: 'WITHOUT_RIMS',
  ALUMINIUM_ORIGINAL: 'ALUMINIUM_ORIGINAL',
  ALUMINIUM_SUPPLIER: 'ALUMINIUM_SUPPLIER',
  STEEL: 'STEEL',
  STEEL_WITH_COVER: 'STEEL_WITH_COVER',
});

const brakesConditionValues = (translations: Translations) =>
  ({
    AS_GOOD_AS_NEW: translations.AS_GOOD_AS_NEW,
    MORE_THAN_50: translations.MORE_THAN_50,
    LESS_THAN_50: translations.LESS_THAN_50,
    WEAR_LIMIT: translations.WEAR_LIMIT,
    BURR: translations.BURR,
    BAD_SUPPORTING_BRAKE: translations.BAD_SUPPORTING_BRAKE,
    DRUM_BRAKE: translations.DRUM_BRAKE,
    CARBON_CERAMIC_BRAKES: translations.CARBON_CERAMIC_BRAKES,
  } as const);

const tireTypesValues = (translations: Translations) =>
  ({
    WINTER: translations.WINTER,
    SUMMER: translations.SUMMER,
    EMERGENCY: translations.EMERGENCY,
    ALL_SEASON: translations.ALL_SEASON,
  } as const);

const rimTypeValues = (translations: Translations) =>
  (Object.keys(rimTypeEnum) as ReadonlyArray<keyof typeof rimTypeEnum>).reduce(
    (acc, key) => ({
      ...acc,
      [key]: translations[key],
    }),
    {} as Record<keyof typeof rimTypeEnum, string>,
  );

export {
  rimTypeEnum,
  wheelPositionMapper,
  brakesConditionValues,
  rimTypeValues,
  tireTypesValues,
};
