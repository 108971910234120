import * as React from 'react';
import styles from './index.module.scss';

const NavigationSystem: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd" className={styles.stroke}>
        <use
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
          xlinkHref="#prefix__a"
        />
        <g strokeWidth="1.5">
          <path
            d="M8.571 9.549H133.18800000000002V87.23599999999999H8.571z"
            transform="translate(16.62 17.597)"
          />
          <path
            d="M141.987.75H.75v94.306h141.237V.75zM121.291 22.485S25.29 33.937 133.939 69.41"
            transform="translate(16.62 17.597)"
          />
          <path
            strokeDasharray="30"
            d="M105.866 24.44c-21.386 1.656-71.434 22.397 2.654 60.612"
            transform="translate(16.62 17.597)"
          />
          <path
            strokeLinecap="square"
            d="M8.648 24.925L133.266 21.419"
            transform="translate(16.62 17.597) rotate(1.419 70.957 23.172)"
          />
          <path
            d="M115.363 23.463s-96.73 3.888-48.452 63.544M29.634 36.823V32.75l12.893 6.832-12.893 6.832v-3.968h-5.027v20.612h-5.542V36.824h10.57z"
            transform="translate(16.62 17.597)"
          />
        </g>
      </g>
    </svg>
  );
};

export { NavigationSystem };
