import type { Translations } from 'shared/utils/translations';
import {
  SERVICE_BOOK_AVAILABLE_PAPER,
  SERVICE_BOOK_AVAILABLE_DIGITAL,
  SERVICE_BOOK_AVAILABLE_UNAVAILABLE,
  SERVICE_BOOK_APPOINTMENTS_ALL,
  SERVICE_BOOK_APPOINTMENTS_SOME,
  SERVICE_BOOK_APPOINTMENTS_NONE,
  SERVICE_BOOK_APPOINTMENTS_NOT_DUE_YET,
} from '../constants';

export const serviceBookOptions = (translations: Translations) => [
  {
    label: translations.SERVICE_ANSWER_SERVICE_BOOK_AVAILABLE_PAPER,
    value: SERVICE_BOOK_AVAILABLE_PAPER,
  },
  {
    label: translations.SERVICE_ANSWER_SERVICE_BOOK_AVAILABLE_DIGITAL,
    value: SERVICE_BOOK_AVAILABLE_DIGITAL,
  },
  {
    label: translations.SERVICE_ANSWER_SERVICE_BOOK_AVAILABLE_UNAVAILABLE,
    value: SERVICE_BOOK_AVAILABLE_UNAVAILABLE,
  },
];

export const yesOrNoToggle = (translations: Translations) => [
  {
    label: translations.YES,
    value: true,
  },
  {
    label: translations.NO,
    value: false,
  },
];

export const serviceBookAppointmentsOptions = (translations: Translations) => [
  {
    label: translations.SERVICE_ANSWER_SERVICE_APPOINTMENTS_ALL,
    value: SERVICE_BOOK_APPOINTMENTS_ALL,
  },
  {
    label: translations.SERVICE_ANSWER_SERVICE_APPOINTMENTS_SOME,
    value: SERVICE_BOOK_APPOINTMENTS_SOME,
  },
  {
    label: translations.SERVICE_ANSWER_SERVICE_APPOINTMENTS_NONE,
    value: SERVICE_BOOK_APPOINTMENTS_NONE,
  },
  {
    label: translations.SERVICE_ANSWER_SERVICE_APPOINTMENTS_NOT_DUE,
    value: SERVICE_BOOK_APPOINTMENTS_NOT_DUE_YET,
  },
];
