import * as React from 'react';
import cn from 'classnames';
import { apm } from '@elastic/apm-rum';

import { useFormContext } from 'react-hook-form';
import { Button, ButtonThemes } from '@auto1-ui/button';
import { FormTextarea } from 'shared/components/FormTextarea';
import { Card } from 'shared/components/Card';
import { FormUploader } from 'shared/components/FormUploader';
import { InputLabel } from 'shared/components/InputLabel';
import { Typography } from 'shared/components/Typography';
import { FormDropdown } from 'shared/components/FormDropdown';
import { useTranslation } from 'shared/hooks/useTranslation';
import { usePdfUpdater } from 'shared/hooks/usePdfUpdater';
import { useUploadContext } from 'shared/components/UploadContext';
import { PdfFile } from 'shared/components/PdfFile';
import { getBaseName } from 'shared/utils/url';
import { useInspection } from 'shared/components/InspectionContext';

import { OtherDocumentsPlaceholder } from './Placeholder';
import { useInspectionCompanies } from '../hooks/useInspectionCompanies';
import styles from '../index.module.scss';

type Props = {
  qaIdPrefix: string;
  inspectionId: number;
};

const OtherDocuments: React.FC<Props> = ({ qaIdPrefix, inspectionId }) => {
  const { translations } = useTranslation();
  const { isPdfInspection } = useInspection();
  const { watch, setValue } = useFormContext();
  const { notifyUploadStart, notifyUploadFinish } = useUploadContext();
  const pdfFile = watch('inspectionReport');
  const companyId = watch('companyId');
  const updatePdf = usePdfUpdater();
  const [isUploadingPdf, setIsUploadingPdf] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handlePdfChange = async (name: string, files: File[]) => {
    const [file] = files;
    if (!file) return;

    try {
      setIsUploadingPdf(true);
      notifyUploadStart();
      const pdfUrl = await updatePdf(file);
      setIsUploadingPdf(false);
      notifyUploadFinish();
      setValue(name, { absoluteUrl: pdfUrl });
    } catch (error) {
      apm.captureError(error as Error);
      setIsUploadingPdf(false);
      notifyUploadFinish();
      handlePdfRemove();
    }
  };

  const handlePdfRemove = () => {
    setValue('inspectionReport', null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const pdfFileName = getBaseName(pdfFile?.absoluteUrl ?? '');
  const { companies, loading } = useInspectionCompanies({ inspectionId });

  const inspectionCompanyOptions = React.useMemo(() => {
    return [
      {
        label: translations.PLEASE_SELECT,
        value: null,
      },
      ...companies,
    ];
  }, [companies, translations]);

  React.useEffect(() => {
    // wait for inspection companies list to be loaded from query
    if (inspectionCompanyOptions.length > 1) {
      const inspectionCompany = inspectionCompanyOptions.find(
        ({ value }) => value === companyId,
      );
      if (!inspectionCompany) {
        setValue('companyId', null);
      }
    }
  }, [companyId, inspectionCompanyOptions, setValue]);

  if (loading) {
    return <OtherDocumentsPlaceholder />;
  }

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      externalStyle={styles.card}
      qaIdPrefix={qaIdPrefix}
      id="other-documents"
    >
      <div className={styles.title}>
        <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
          {translations.SERVICE_OTHER_DOCUMENT_HEADER}
        </Typography>
      </div>

      <div data-qa-id={`${qaIdPrefix}-other-note`}>
        <InputLabel>
          <Typography
            additonalClassNames={styles.labelTitle}
            data-qa-id={`${qaIdPrefix}-label`}
          >
            {translations.PDF_INSPECTION_REPORT}
            {!isPdfInspection && (
              <span className={styles.optionalLabel}>
                {translations.OPTIONAL}
              </span>
            )}
          </Typography>
        </InputLabel>
        <FormUploader
          name="inspectionReport"
          extraClassName={cn(styles.uploaderStyles, {
            [styles.hiddenField]: !!pdfFileName || isUploadingPdf,
          })}
          errorClassName={cn(styles.uploaderErrorMessage, {
            [styles.hiddenField]: !!pdfFileName || isUploadingPdf,
          })}
          allowMultipleFiles={false}
          qaIdPrefix={`${qaIdPrefix}-upload-inspection-pdf`}
          inputQaId={`${qaIdPrefix}-upload-inspection-pdf-input`}
          uploadFileTypes="application/pdf"
          includeCarousel={false}
          onSelectFile={handlePdfChange}
          inputRef={fileInputRef}
          defaultValue={null}
        >
          {({ handleUploadClick }) => (
            <>
              <div className={styles.uploadCloudIcon} />
              <Typography>{translations.DROP_PDF_HERE}</Typography>
              <Button
                qaId={`${qaIdPrefix}-browse-button`}
                extraClass={styles.uploadButtonText}
                theme={ButtonThemes.link}
                onClick={(event) => {
                  event.preventDefault();
                  handleUploadClick();
                }}
              >
                {translations.BROWSE}
              </Button>
            </>
          )}
        </FormUploader>
        {(isUploadingPdf || pdfFileName) && (
          <PdfFile
            name={pdfFileName ?? ''}
            url={pdfFile?.absoluteUrl}
            containerClassName={styles.pdfContainer}
            isUploadingPdf={isUploadingPdf}
            onRemove={handlePdfRemove}
            qaIdPrefix={`${qaIdPrefix}-inspection-pdf-file`}
          />
        )}
        <div className={styles.inspectionCompanyInput}>
          <FormDropdown
            label={translations.SERVICE_OTHER_DOCUMENT_INSPECTION_COMPANY}
            name="companyId"
            qaIdPrefix={`${qaIdPrefix}-inspection-company-id`}
            options={inspectionCompanyOptions}
            containerClassName={styles.inputContainerFullWidth}
            placeholder={translations.PLEASE_SELECT}
            defaultValue={null}
            beforeChange={() => {
              setValue('companyId', null);
            }}
          />
        </div>

        <FormTextarea
          label={
            <>
              {translations.SERVICE_OTHER_DOCUMENT_QUESTION_NOTES}{' '}
              <span className={styles.optionalLabel}>
                {translations.OPTIONAL}
              </span>
            </>
          }
          name="otherNote"
          rows={5}
          qaIdPrefix="other-documents-text-area"
          defaultValue=""
          containerClassName={styles.inputContainerFullWidth}
        />
      </div>
    </Card>
  );
};

export { OtherDocuments };
