import * as React from 'react';

import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { descriptionGenerator } from './descriptionGenerator';

import styles from './index.module.scss';

type Props = {
  title: string;
  description?: number | string | string[];
  qaIdPrefix: string;
};

const ItemDetail: React.FC<Props> = ({
  title,
  description = '',
  qaIdPrefix,
}) => {
  const { translate, translations } = useTranslation();

  return (
    <Typography
      tag="p"
      additonalClassNames={styles.container}
      data-qa-id={`${qaIdPrefix}-item-detail-container`}
    >
      <Typography
        tag="span"
        variant="textSmall"
        data-qa-id={`${qaIdPrefix}-item-detail-title`}
      >
        {title}
      </Typography>
      <span
        className={styles.dotted}
        data-qa-id={`${qaIdPrefix}-item-detail-spacing`}
      />
      <Typography
        tag="span"
        variant="textSmall"
        additonalClassNames={styles.description}
        data-qa-id={`${qaIdPrefix}-item-detail-description`}
      >
        {descriptionGenerator({
          title,
          description,
          translations,
          translate,
        }) ?? ''}
      </Typography>
    </Typography>
  );
};

export { ItemDetail };
