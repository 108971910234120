import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { apm } from '@elastic/apm-rum';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, ButtonTypes, ButtonThemes } from '@auto1-ui/button';

import { useAccidentsInitialDataQuery } from 'gql/graphql';
import { appRoutes } from 'appRoutes';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { useInspection } from 'shared/components/InspectionContext';
import { scrollToError } from 'shared/utils/scrollToError';
import { useTranslation } from 'shared/hooks/useTranslation';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import type { Translations } from 'shared/utils/translations';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import Loader from 'shared/components/Loader';
import { AccidentsPlaceholder } from './Placeholder';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { RoutesEnum } from 'shared/utils/route';
import { AlertBar } from 'shared/components/AlertBar';
import { getAccidentSchema } from './schema';
import { useAccidentsUpdater } from './hooks/useAccidentsUpdater';
import { mapAccidentToMutation, mapDataToFormValues } from './helpers';
import { AccidentsSection } from './components/Accidents';
import { Areas } from './components/Areas';
import { Repair } from './components/Repair';
import { useProgress } from './hooks/useProgress';
import type { Form } from './types';
import styles from 'shared/components/NavigationFooter/index.module.scss';

type Props = {
  qaIdPrefix?: string;
};

const AccidentsGate: React.FC<Props> = ({ children, ...props }) => {
  const { shouldUseNewAccidentModel } = useInspection();

  if (!shouldUseNewAccidentModel) {
    return <Redirect to={appRoutes.notFound()} />;
  }

  return <Accidents {...props} />;
};

const Accidents: React.FC<Props> = ({ qaIdPrefix = 'accidents' }) => {
  const { inspectionId, stockNumber, removeStepFromNotCompleted } =
    useInspection();
  const { translations, translate } = useTranslation();
  const submitContext = React.useRef<'save' | 'submit' | null>(null);
  const formMethods = useForm<Form>({
    resolver: (values, context, options) =>
      yupResolver(
        getAccidentSchema(
          translations,
          data?.inspection?.vehicle?.damages ?? [],
          data?.inspection?.fields?.vehicle?.accidents.areas ?? [],
          submitContext?.current === 'save',
          translate,
        ),
      )(values, context, options),
  });
  const { submitTracking } = useSubmitTracking('accidents');
  const { data, loading } = useAccidentsInitialDataQuery({
    variables: {
      inspectionId,
    },
    onCompleted: (result) => {
      formMethods.reset(mapDataToFormValues(result.inspection));
    },
  });
  const [updateAccidents, { loading: isSubmitting, error: mutationError }] =
    useAccidentsUpdater(inspectionId, stockNumber);
  const history = useHistory();
  const [uploadError, setUploadError] = React.useState('');
  const setProgress = useProgress();
  const serverError = mutationError?.graphQLErrors?.[0]?.message ?? uploadError;
  const {
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;

  const hasHadAccident = watch('accidents.hasHadAccident');
  const onError = () => {
    const formData = getValues();
    scrollToError();
    setProgress(
      formData,
      data?.inspection?.vehicle?.damages ?? [],
      data?.inspection?.fields?.vehicle?.accidents.areas ?? [],
    );
  };
  const onSubmit = async (values: Form) => {
    const optionalSchema = submitContext.current === 'save';
    const redirect = submitContext.current === 'submit';

    try {
      await updateAccidents({
        optionalSchema,
        accident: mapAccidentToMutation(values),
      });
      reset(getValues());
      removeStepFromNotCompleted(RoutesEnum.CAR_QUALITY);

      if (redirect) {
        submitTracking();
        history.push(appRoutes.tiresBrakes(`${inspectionId}`, true));
      }
    } catch (e) {
      console.log('catch', e);
      apm.captureError(e as Error);
      setUploadError((e as Error).message);
    }
  };
  const accidentAreaOptions = React.useMemo(
    () => data?.inspection?.fields?.vehicle?.accidents.areas,
    [data],
  );

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <AccidentsPlaceholder />;
  }

  return (
    <FormProvider {...formMethods}>
      <LayoutContainer>
        <AccidentsSection
          qaIdPrefix={qaIdPrefix}
          damages={data?.inspection?.vehicle?.damages ?? []}
        />
        {hasHadAccident && (
          <>
            <Areas
              qaIdPrefix={`${qaIdPrefix}-areas`}
              accidentAreaOptions={accidentAreaOptions}
              damages={data?.inspection?.vehicle?.damages ?? []}
            />
            <Repair qaIdPrefix={`${qaIdPrefix}-repair`} />
          </>
        )}
        <NavigationFooter
          backButtonLink={appRoutes.damages(`${inspectionId}`, true)}
          rightAreaContent={
            <>
              <Button
                type={ButtonTypes.button}
                theme={ButtonThemes.outlined}
                isActivated={false}
                qaId={`${qaIdPrefix}-footer-save-link`}
                extraClass={styles.saveButton}
                onClick={() => {
                  submitContext.current = 'save';
                  handleSubmit(onSubmit, onError)();
                }}
                isDisabled={isSubmitting}
              >
                {translations.SAVE}
                {isSubmitting && submitContext.current === 'save' && (
                  <Loader extraClass={styles.saveButtonLoader} />
                )}
              </Button>
              <Button
                type={ButtonTypes.button}
                theme={ButtonThemes.orange}
                extraClass={styles.nextButton}
                qaId={`${qaIdPrefix}-footer-next-link`}
                isActivated={false}
                onClick={() => {
                  submitContext.current = 'submit';
                  handleSubmit(onSubmit, onError)();
                }}
                isDisabled={isSubmitting}
              >
                {translations.NEXT}
                {isSubmitting && submitContext.current === 'submit' && (
                  <Loader extraClass={styles.nextButtonLoader} />
                )}
              </Button>
            </>
          }
          bottomAreaContent={
            serverError && (
              <AlertBar
                type="error"
                message={
                  serverError in translations
                    ? translations[serverError as keyof Translations]
                    : translations.GENERIC_ERROR_MESSAGE
                }
                additionalClassNames={styles.alertBar}
              />
            )
          }
        />
        <RouteLeavingGuard when={isDirty} title={translations.UNSAVED_CHANGES}>
          <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
        </RouteLeavingGuard>
      </LayoutContainer>
    </FormProvider>
  );
};

export { AccidentsGate as Accidents };
