import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormUploader } from 'shared/components/FormUploader';
import { FormDropdown } from 'shared/components/FormDropdown';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';

import { useDamageOptions } from '../../hooks/useDamageOptions';

import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  fieldName: string;
  index: number;
};

const HighlightItem: React.FC<Props> = ({
  qaIdPrefix = 'auto1',
  fieldName,
}) => {
  const { translations } = useTranslation();
  const { watch, setValue } = useFormContext();
  const selectedArea = watch(`${fieldName}.area`);
  const selectedPart = watch(`${fieldName}.part`);
  const { areas, parts } = useDamageOptions({
    selectedArea,
    onCompleted: () => {
      setValue(`${fieldName}.area`, selectedArea);
      setValue(`${fieldName}.part`, selectedPart);
    },
  });

  return (
    <div data-qa-id={`${qaIdPrefix}-container`}>
      <FormUploader
        name={`${fieldName}.files`}
        allowMultipleFiles
        uploadFileTypes="image/png, image/jpeg, image/heic, image/heif"
        processSelectedFile={processHeicFile}
        defaultValue={[]}
        extraClassName={styles.uploaderStyles}
        qaIdPrefix={`${qaIdPrefix}-form-uploader`}
      >
        {({ handleUploadClick }) => (
          <>
            <div
              className={styles.uploadCloudIcon}
              data-qa-id={`${qaIdPrefix}-file-upload`}
            />
            <Typography>{translations.DROP_PHOTOS_HERE}</Typography>
            <button
              type="button"
              className={styles.browseButton}
              data-qa-id={`${qaIdPrefix}-file-upload-button`}
              onClick={(event) => {
                event.preventDefault();
                handleUploadClick();
              }}
            >
              {translations.BROWSE}
            </button>
          </>
        )}
      </FormUploader>

      <div
        className={styles.carInfoContainer}
        data-qa-id={`${qaIdPrefix}-car-info-container`}
      >
        <div className={styles.carInfoColumn}>
          <FormDropdown
            defaultValue={null}
            qaIdPrefix={`${qaIdPrefix}-area-dropdown`}
            name={`${fieldName}.area`}
            label={translations.QUALITY_AREA}
            placeholder={translations.PLEASE_SELECT}
            containerClassName={styles.label}
            options={areas}
            beforeChange={() => {
              setValue(`${fieldName}.part`, null);
            }}
            searchable
          />
        </div>
        <div className={styles.carInfoColumn} data-qa-id={`${qaIdPrefix}-area`}>
          <FormDropdown
            defaultValue={null}
            qaIdPrefix={`${qaIdPrefix}-part-dropdown`}
            name={`${fieldName}.part`}
            label={translations.QUALITY_PART}
            placeholder={translations.PLEASE_SELECT}
            containerClassName={styles.label}
            disabled={parts.length === 0}
            options={parts}
            searchable
          />
        </div>
      </div>
    </div>
  );
};

export { HighlightItem };
