import * as React from 'react';
import type { VariationType } from '../types';

type Props = {
  variant: VariationType;
  qaIdPrefix?: string;
};

const ExteriorLeft: React.FC<Props> = ({ variant, qaIdPrefix }) => (
  <>
    <mask
      id="mask2_431_9762"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="102"
      width="179"
      height="324"
    >
      <path
        d="M43.7235 248.725C19.9058 250.852 0.0363758 230.904 2.15521 206.991C3.78901 188.657 18.4847 173.903 36.7459 172.263C60.5636 170.135 80.433 190.084 78.3142 213.997C76.6804 232.33 61.9847 247.085 43.7235 248.725ZM73.651 106.65C66.5627 103.874 67.7626 102.063 58.632 102.182C49.5014 102.31 48.5399 104.113 46.1402 107.496C43.7406 110.879 38.2095 109.547 37.0096 108.462C35.8098 107.377 32.3295 106.283 28.8406 110.751C25.3603 115.211 24.5604 125.582 24.5604 125.582V163.942V167.179C29.4533 165.394 34.7291 164.42 40.2347 164.42C66.1798 164.42 87.0959 186.034 86.0918 212.305C85.1813 236.192 65.8139 255.628 42.0217 256.533C35.8694 256.773 29.9724 255.782 24.5604 253.808V257.772V416.497C40.2347 413.285 68.9283 410.551 95.0351 412.192C127.345 413.952 180.571 425.502 180.571 425.502V406.365C180.571 374.764 169.756 340.992 165.671 330.142C161.587 319.284 120.019 240.566 120.019 240.566C120.019 240.566 117.296 212.425 114.573 192.809C111.85 173.185 103.204 142.105 98.1155 126.915C93.0354 111.717 80.7393 109.427 73.651 106.65Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_431_9762)">
      <path
        d="M120.342 435.062V514.907C120.342 520.469 125.542 524.544 130.919 523.194L162.608 515.24C164.88 514.668 166.472 512.617 166.472 510.268V436.377C166.472 433.831 164.608 431.661 162.098 431.294L130.077 426.604C124.946 425.852 120.342 429.85 120.342 435.062Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M116.368 296.849L120.104 399.607C120.248 403.494 122.98 406.792 126.758 407.638L160.6 415.19C163.74 415.89 166.752 413.532 166.812 410.295C167.016 399.077 164.548 374.712 152.695 347.989C137.335 313.346 126.401 299.924 120.699 294.798C118.989 293.26 116.283 294.542 116.368 296.849Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M167.238 334.508L164.081 342.581C163.383 344.366 160.881 344.401 160.141 342.632C155.81 332.269 145.428 308.211 138.604 298.617C129.796 286.238 118.743 272.885 118.581 265.333C118.504 261.557 119.262 250.937 120.045 241.275"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M117.321 323.12C125.788 337.02 140.041 322.112 135.285 313.851"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M171.935 426.954C171.935 426.954 127.346 417.01 95.044 415.25C70.2137 413.9 47.9106 415.968 32.8149 418.112V285.401C32.8149 281.206 36.2017 277.806 40.3798 277.806H104.745C108.021 277.806 111.195 278.866 113.85 280.796C129.175 291.92 143.505 314.653 157.367 345.913C170.888 376.395 171.935 402.401 171.935 409.501V532.985C171.935 534.83 172.565 536.616 173.73 538.042L175.934 540.751"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.8062 415.908L34.491 506.364C60.1213 504.519 71.7281 514.737 81.5819 526.022L90.4827 538.538C95.7501 543.929 103.655 545.766 110.744 543.245L171.926 521.554"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.5517 227.832C67.0591 218.246 67.0079 202.719 57.4372 193.15C47.8666 183.582 32.4008 183.596 22.8934 193.181C13.386 202.766 13.4372 218.293 23.0079 227.862C32.5785 237.431 48.0443 237.417 57.5517 227.832Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M118.81 258.473C115.67 256.072 112.343 251.57 111.696 243.146C110.411 226.418 101.128 144.728 90.874 139.585C80.6201 134.442 73.8977 136.372 73.5744 128.008C73.251 119.645 73.5744 86.1978 73.5744 86.1978"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M76.8081 182.669V133.998"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M99.8853 386.245V399.462C99.8853 401.991 101.927 404.041 104.446 404.041H104.455C106.974 404.041 109.016 401.991 109.016 399.462V386.245C109.016 383.716 106.974 381.666 104.455 381.666H104.446C101.927 381.666 99.8853 383.716 99.8853 386.245Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M90.2021 108.863C92.6869 119.03 94.5249 132.016 95.4099 146.599"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M35.7759 98.4917V113.459C35.7759 116.287 38.4053 118.38 41.1453 117.74L46.4892 116.484C48.6591 115.98 50.1908 114.04 50.1908 111.802V98.4917H35.7759Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <mask
      id="mask3_431_9762"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="403"
      width="179"
      height="281"
    >
      <path
        d="M43.7235 595.573C19.9058 597.7 0.0363758 577.751 2.15521 553.839C3.78901 535.514 18.4847 520.751 36.7459 519.119C60.5636 516.983 80.433 536.932 78.3142 560.844C76.6804 579.178 61.9847 593.941 43.7235 595.573ZM95.0351 403.52C68.9283 401.88 40.2347 404.614 24.5604 407.826V509.927L24.7902 513.95C30.6531 511.849 37.0607 510.9 43.7491 511.404C66.2649 513.096 84.3984 531.362 86.0067 553.967C87.9213 580.938 66.6904 603.415 40.2347 603.415C36.6522 603.415 33.1719 602.988 29.8277 602.211L30.0064 605.295C30.0064 605.295 34.0058 662.706 34.3292 668.173C34.6525 673.641 38.0137 676.05 40.7368 675.726C42.1919 675.555 44.8383 677.802 46.9826 679.92C49.3312 682.244 52.5137 683.526 55.8154 683.526H68.8432C75.3359 683.526 81.5818 672.676 81.5818 672.676L85.6663 674.846C85.6663 674.846 106.327 674.846 117.142 674.846C123.746 674.846 128.434 671.471 127.056 652.471C139.241 625.149 178.861 532.626 179.627 530.328C180.248 528.457 180.571 526.5 180.571 524.527V416.831C180.571 416.831 127.345 405.28 95.0351 403.52Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_431_9762)">
      <path
        d="M120.342 435.063V514.908C120.342 520.47 125.542 524.545 130.919 523.195L162.608 515.241C164.88 514.669 166.472 512.618 166.472 510.269V436.378C166.472 433.832 164.608 431.662 162.098 431.295L130.077 426.605C124.946 425.853 120.342 429.851 120.342 435.063Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M127.83 545.313C123.32 546.851 120.291 551.106 120.291 555.89V579.034C120.291 585.714 128.63 588.653 132.8 583.527C132.876 583.433 132.944 583.331 133.013 583.228L162.498 536.146C163.808 534.053 161.774 531.465 159.443 532.259L127.839 545.313H127.83Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M116.368 296.849L120.104 399.607C120.248 403.495 122.98 406.792 126.758 407.638L160.6 415.19C163.74 415.891 166.752 413.533 166.812 410.295C167.016 399.078 164.548 374.712 152.695 347.989C137.335 313.346 126.401 299.925 120.699 294.799C118.989 293.261 116.283 294.542 116.368 296.849Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M171.024 552.259L170.547 551.738C168.973 550.029 164.54 547.56 163.519 549.653L126.273 625.432C124.248 629.55 123.201 634.069 123.201 638.657L127.064 652.471"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M171.935 423.897C171.935 423.897 127.346 413.952 95.044 412.192C70.2137 410.843 47.9106 412.91 32.8149 415.054V285.393C32.8149 281.199 36.2017 277.798 40.3798 277.798H104.745C108.021 277.798 111.195 278.858 113.85 280.788C129.175 291.912 143.505 314.645 157.367 345.905C170.888 376.388 171.935 402.393 171.935 409.493V532.977C171.935 534.822 172.565 536.608 173.73 538.035L175.934 540.743"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.8062 415.055L34.491 505.511C60.1213 503.665 71.7281 513.883 81.5819 525.169L90.4827 537.685C95.7501 543.075 103.655 544.912 110.744 542.392L171.926 520.701"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M96.686 658.733V675.273C96.686 679.166 99.8291 682.321 103.706 682.321H110.556C114.433 682.321 117.577 679.166 117.577 675.273V658.733C117.577 654.841 114.433 651.685 110.556 651.685H103.706C99.8291 651.685 96.686 654.841 96.686 658.733Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.5483 574.7C67.0557 565.115 67.0045 549.588 57.4338 540.019C47.8632 530.451 32.3974 530.464 22.89 540.049C13.3826 549.635 13.4338 565.162 23.0044 574.731C32.5751 584.299 48.0409 584.286 57.5483 574.7Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M81.1055 677.862V578.333"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M102.566 505.664V518.88C102.566 521.409 104.608 523.459 107.127 523.459H107.135C109.654 523.459 111.696 521.409 111.696 518.88V505.664C111.696 503.135 109.654 501.085 107.135 501.085H107.127C104.608 501.085 102.566 503.135 102.566 505.664Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M99.8853 386.246V399.463C99.8853 401.992 101.927 404.042 104.446 404.042H104.455C106.974 404.042 109.016 401.992 109.016 399.463V386.246C109.016 383.717 106.974 381.667 104.455 381.667H104.446C101.927 381.667 99.8853 383.717 99.8853 386.246Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <g className={variant} data-qa-id={`${qaIdPrefix}-exterior-left`}>
      <path
        d="M78.3142 214.496C76.6804 232.829 61.9847 247.584 43.7235 249.224C19.9058 251.351 0.0363758 231.403 2.15521 207.49C3.78901 189.156 18.4847 174.402 36.7458 172.762C60.5636 170.635 80.433 190.583 78.3142 214.496Z"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M85.4243 553.967C83.816 531.362 65.6825 513.096 43.1667 511.405C36.4783 510.9 30.0707 511.849 24.2078 513.95L23.978 509.927V416.497V257.772V253.808C29.39 255.782 35.287 256.773 41.4393 256.533C65.2315 255.628 84.5989 236.192 85.5094 212.305C86.5135 186.034 65.5974 164.42 39.6523 164.42C34.1467 164.42 28.8709 165.394 23.978 167.179V163.942V125.582C23.978 125.582 24.7779 115.211 28.2582 110.751C31.7471 106.283 35.2274 107.377 36.4273 108.462C37.6271 109.547 43.1582 110.879 45.5578 107.496C47.9575 104.113 48.919 102.31 58.0496 102.182C67.1802 102.063 65.9803 103.874 73.0686 106.65C80.157 109.427 92.453 111.717 97.5331 126.915C102.622 142.105 111.267 173.185 113.99 192.809C116.713 212.425 119.436 240.566 119.436 240.566C119.436 240.566 161.005 319.284 165.089 330.142C169.174 340.992 179.989 374.764 179.989 406.365V425.502V524.527C179.989 526.501 179.666 528.457 179.045 530.328C178.279 532.626 138.659 625.149 126.474 652.471C127.852 671.471 123.163 674.846 116.56 674.846C105.745 674.846 85.0839 674.846 85.0839 674.846L80.9994 672.676C80.9994 672.676 74.7535 683.526 68.2609 683.526H55.233C51.9314 683.526 48.7488 682.244 46.4003 679.92C44.2559 677.802 41.6095 675.555 40.1544 675.726C37.4314 676.05 34.0702 673.641 33.7468 668.173C33.4234 662.706 29.424 605.295 29.424 605.295L29.2453 602.211C32.5895 602.988 36.0699 603.415 39.6523 603.415C66.108 603.415 87.3389 580.938 85.4243 553.967Z"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M36.7458 519.618C60.5636 517.482 80.433 537.431 78.3142 561.343C76.6804 579.677 61.9847 594.44 43.7235 596.072C19.9058 598.199 0.0363758 578.25 2.15521 554.338C3.78901 536.012 18.4847 521.25 36.7458 519.618Z"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
  </>
);

export { ExteriorLeft };
