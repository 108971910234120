import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = {
  color?: string;
  size?: number;
  extraClass?: string;
  qaIdPrefix?: string;
};

const Loader: React.FC<Props> = ({
  color = '',
  size = null,
  extraClass = '',
  qaIdPrefix = 'loader-icon',
}) => {
  const dynamicStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderLeftColor: color,
    borderRightColor: color,
    borderBottomColor: color,
  };

  return (
    <div
      className={cn(styles.loader, extraClass)}
      style={dynamicStyle}
      data-qa-id={qaIdPrefix}
    />
  );
};

export default Loader;
