import { withFormItem } from '../withFormItem';
import { NumberInput } from '../NumberInput';
import styles from './index.module.scss';

const FormNumberInput = withFormItem(NumberInput, {
  classNames: {
    error: styles.error,
  },
});

export { FormNumberInput };
