import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useUserBranchesQuery } from 'gql/graphql';
import type { UserBranchesQuery } from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { FormDropdown } from 'shared/components/FormDropdown';
import { appRoutes } from 'appRoutes';
import { Typography } from 'shared/components/Typography';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import styles from './index.module.scss';
import { Translations } from 'shared/utils/translations';

type Branch = UserBranchesQuery['userBranches'][number];
type Props = {
  additionalClassName?: string;
  setHasUserBranches?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBranch?: React.Dispatch<React.SetStateAction<Branch | undefined>>;
};

const LocationSettings: React.FC<{ translations: Translations }> = ({
  translations,
}) => {
  return (
    <Link
      className={styles.location}
      to={appRoutes.locationsList()}
      data-qa-id="location-settings-link"
    >
      <Typography variant="linkSmallSky">
        {translations.LOCATION_SETTINGS}
      </Typography>
    </Link>
  );
};

const BranchSelection: React.FC<Props> = ({
  setHasUserBranches = () => {},
  setSelectedBranch = () => {},
  additionalClassName = '',
}) => {
  const location = useLocation();
  const [defaultBranch, setDefaultBranch] = React.useState<number>();
  const { watch, setValue } = useFormContext();
  const currentBranchId = watch('branch');
  const [currentBranch, setCurrentBranch] = React.useState<Branch | null>();
  const [backTo, setBackTo] = React.useState('');
  const searchParams = new URLSearchParams(location.search);

  const { data: branchesData, loading } = useUserBranchesQuery({
    onCompleted: () => {
      setDefaultBranch(
        branchesData?.userMerchant?.defaultBranchId ?? undefined,
      );
      setHasUserBranches((branchesData?.userBranches?.length ?? 0) > 0);

      const branchId = Number(searchParams.get('branchId'));
      if (branchId) {
        setValue('branch', branchId);
      }
    },
  });

  const userBranches = [...(branchesData?.userBranches ?? [])].sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  React.useEffect(() => {
    const branch = userBranches?.find(({ value }) => value === currentBranchId);

    setSelectedBranch(branch);
    setCurrentBranch(branch);
  }, [currentBranchId, userBranches, setSelectedBranch, setCurrentBranch]);

  React.useEffect(() => {
    searchParams.set('branchId', currentBranchId);
    setBackTo(`${location.pathname}?${searchParams}`);
  }, [currentBranchId, location, searchParams]);

  const { translations } = useTranslation();

  if (loading) {
    return <>loading</>;
  }

  return (
    <>
      <div className={additionalClassName}>
        <FormDropdown
          name="branch"
          options={userBranches}
          disabled={userBranches.length === 0}
          qaIdPrefix="dropdown-qaId"
          searchable
          label={translations.BRANCH}
          placeholder={translations.PLEASE_SELECT}
          listClassName={styles.dropDownContainer}
          containerClassName={styles.fullWidth}
          additionalOption={
            !isMobileEvaluatorApp() ? (
              <LocationSettings translations={translations} />
            ) : undefined
          }
          defaultValue={defaultBranch}
        />
      </div>
      {userBranches?.length === 0 && (
        <Typography data-qa-id="user-has-no-branch-text">
          {translations.YOU_HAVE_NO_REGISTERED_LOCATION}{' '}
          <Link
            className={styles.addLocationLink}
            to={appRoutes.addLocation()}
            data-qa-id="add-location-link"
          >
            <Typography variant="linkSmallSky">
              {translations.ADD_LOCATION_LINK}
            </Typography>
          </Link>
        </Typography>
      )}
      {currentBranch && (
        <div className={styles.branchLocationContainer}>
          <Typography additonalClassNames={styles.branchLocationTitle}>
            {translations.THIS_IS_YOUR_LOCATION_ADDRESS}
          </Typography>
          <div className={styles.addressContainer}>
            <address className={styles.address} data-qa-id="branch-address">
              {currentBranch.street}
              <br />
              {currentBranch.zipcode} - {currentBranch.city}{' '}
              {currentBranch.country}
            </address>
            <Link
              to={`${appRoutes.editLocation(
                currentBranchId,
              )}?backTo=${encodeURIComponent(backTo)}&ref=carInfo`}
              className={styles.editAddressLink}
              data-qa-id="branch-edit-link"
            >
              <Typography variant="linkSmallSky">
                {translations.EDIT_ADDRESS}
              </Typography>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export { BranchSelection };
