import * as React from 'react';
import styles from './index.module.scss';

const RearInterior: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g mask="url(#prefix__d)">
          <g transform="translate(11.667 -3.61)">
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M9.931 3.61C1.103 31.27-1.839 48.287 1.103 54.66c4.414 9.56 12.36 16.516 20.241 26.236 7.882 9.72 38.347 54.212 45.639 55.694"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M4.864 21.345c2.712-.827 6.098-.324 10.16 1.51 3.285 2.75 6.976 14.065 4.532 17.122-1.63 2.037-3.875 2.992-6.739 2.862-4.291-6.193-8.055-9.29-11.292-9.29"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M5.642 20.96c3.53-1.14 7.441-2.262 11.734-3.37 4.293-1.106 7.706 2.888 10.238 11.984-.055 3.063-.38 4.875-.975 5.436-.893.84.504.47-8.335 6.322M0 42.85c11.085 9.81 24.968 27.68 41.649 53.608 25.02 38.893 25.03 39.123 39.79 41.312"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M25.07 36.68c8.299 8.828 17.373 28.11 27.222 57.847 0 0 41.453-11.73 45.739-10.753 4.286.977 10.906 22.458 10.906 27.373 0 4.915-17.312 16.559-19.992 18.452-2.68 1.892-16.62 6.941-19.517 5.01M52.292 94.527c-2 8.269-2 14.318 0 18.147"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M53.969 114.829c15.374-1.584 29.807-4.704 43.3-9.36 5.199-1.654 8.829 2.155 8.829 4.68v5.662M22.305 19.251c2.029-1.84 4.4-2.454 7.115-1.84 4.071.92 6.986 9.426 5.529 11.96-1.458 2.534-6.437 9.466-7.478 8.753-.694-.475-1.537-.986-2.53-1.534"
            />
            <path
              className={styles.stroke}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M25.07 22.1c3.435 1.718 5.153 6.377 5.153 13.976M34.378 30.671c8.483 9.739 15.3 24.18 20.454 43.327 0 0 31.2-7.604 35.957-5.969 1.228 1.381 3.058 6.678 5.488 15.89M54.832 73.998c-2.693 5.415-4.04 10.852-4.04 16.312M22.305 18.331c.634-3.176 2.805-4.764 6.512-4.764 5.561 0 7.16-1.031 10.265 6.023 1.092 4.031-.159 6.377-3.752 7.037"
            />
            <path
              className={styles.stroke}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M27.614 13.515c4.888-1.5 8.085-1.999 9.59-1.5 2.26.75 4.616 6.198 4.616 8.944 0 1.831-1.856 3.72-5.567 5.668M40.72 24.085c9.326 12.884 14.812 25.048 16.458 36.492 0 0 .8 1.48 8.091-1.357 7.292-2.837 12.077-3.62 14.764-3.229 2.687.392 4.8 1.305 7.124 1.305 2.323 0 6.218 8.954 6.218 14.258M56.83 65.177c-1.332 1.494-1.998 3.62-1.998 6.377"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M56.83 65.177c3.136.44 6.542.11 10.218-.986 3.675-1.097 9.758-3.395 18.247-6.895"
            />
            <path
              className={styles.stroke}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M45.764 3.61c-1.253 9.85-1.253 15.056 0 15.618 1.253.562 19.946 1.71 56.079 3.445l2.8-19.063"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M126.944 8.936c3.242-.522 6.528-.171 9.856 1.054 1.153 1.839-1.279 22.142-1.279 24.184 0 2.042-5.39 1.021-8.577 0-2.125-.68-5.022-2.282-8.693-4.803-.565-9.084.386-14.931 2.854-17.542 1.024-1.226 2.97-2.19 5.839-2.893z"
            />
            <path
              className={styles.stroke}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M125.659 8.702c2.035-2.408 4.2-4.105 6.496-5.092 3.443-1.481 6.933-3.61 11.88-3.61 4.947 0 8.592 4.378 9.884 8.702 1.292 4.324 2.656 13.964 0 20.67-2.655 6.704-11.504 7.915-18.016 5.771M115.604 36.68c3.945-3.665 5.45-2.083 10.055-1.327 4.604.757 9.205 6.675 14.89 8.577 5.687 1.902 9.613 7.435 10.907 9.886 1.293 2.451 9.106 15.307 12.814 19.204 2.472 2.598 2.774 23.128.905 61.59-5.511.473-10.084-1.765-13.72-6.716-1.465-2.692-2.07-4.385-1.812-5.08-13.11-16.765-20.061-26.194-20.853-28.287-1.188-3.14-3.17-19.785-4.728-22.973-1.558-3.189-10.264-21.448-10.384-25.076-.119-3.627-.119-7.9 1.926-9.799z"
            />
            <path
              className={styles.fill}
              d="M129.436 34.893H132.353V41.736000000000004H129.436zM122.631 32.938H124.575V36.848H122.631z"
            />
            <path
              fill="#20419A"
              className={styles.stroke}
              d="M113.408 18.774H114.408V21.684H113.408zM110.492 17.796H111.492V19.729H110.492z"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M130.894 40.842c-1.937.284-3.983.742-3.292 2.009.69 1.266 6.614 1.266 6.614 0 0-1.267-1.836-2.227-3.322-2.009zM123.65 35.901c-1.675.279-3.442.728-2.845 1.97.596 1.243 5.714 1.243 5.714 0 0-1.242-1.586-2.184-2.87-1.97zM118.093 18.055c-6.463-.285-10.947-1.113-13.45-2.484 0 0 .74-9.691 2.516-11.961 1.778-2.27 2.02-3.61 6.478-3.61 4.456 0 8.994 2.06 8.994 3.61v6.96"
            />
            <ellipse
              cx="113.394"
              cy="22.673"
              fill="#20419A"
              className={styles.stroke}
              rx="1.931"
              ry="1"
            />
            <ellipse
              cx="110.478"
              cy="21.695"
              fill="#20419A"
              className={styles.stroke}
              rx="1.931"
              ry="1"
            />
            <circle
              cx="110.478"
              cy="20.718"
              r="1"
              fill="#20419A"
              className={styles.stroke}
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M118.093 24.085c-2.456-1.919-5.508-3.041-9.156-3.367-5.473-.489-4.6 4.392-5.036 7.79-.437 3.398 6.09 20.756 7.063 22.118.972 1.362.759 12.328 1.305 14.038.364 1.14 1.301 2.374 2.811 3.701M107.16 3.61c2.536-.202 4.128.082 4.776.851.972 1.155.95 11.626 0 12.72"
            />
            <path
              className={styles.stroke}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M120.686 64.07c-3.833 1.18-6.75 4.49-8.75 9.928 0 3.91 6.415 9.922 7.583 9.922"
            />
            <path
              className={styles.stroke}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M127.602 93.312c-4.853.73-7.651.73-8.394 0-.743-.73-2.681-3.86-5.814-9.392l.487-4.47"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M115.825 80.64c3.815-6.057 6.407-9.086 7.778-9.086M91.623 64.07c4.132 1.175 7.207.662 9.227-1.54 2.02-2.2 3.284-12.065 3.793-29.592"
            />
            <path
              className={styles.stroke}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M94.52 37.337l-9.225 7.187c-3.192-1.115-5.385-1.673-6.579-1.673H58.952l3.892 7.34h20.154c1.531 0 6.577-4.006 15.135-12.016l-3.612-.838z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { RearInterior };
