import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@auto1-ui/button';
import { useTranslation } from 'shared/hooks/useTranslation';
import { FormVinInput } from 'shared/components/FormVinInput';
import { FormInput } from 'shared/components/FormInput';
import { Grid } from 'shared/components/Grid';
import Loader from 'shared/components/Loader';
import styles from './index.module.scss';

type Props = {
  branchId: number | null;
  initialVin?: string;
  serverError?: string;
  isSubmitting?: boolean;
  qaIdPrefix: string;
  onFillManuallyClick: React.MouseEventHandler<HTMLButtonElement>;
  handlePasteVinInput: React.ClipboardEventHandler<HTMLInputElement>;
  handleLoadDataClick: React.MouseEventHandler<HTMLButtonElement>;
};

const VinSearch: React.FC<Props> = ({
  branchId: branch = null,
  qaIdPrefix,
  initialVin = '',
  serverError = '',
  isSubmitting = false,
  onFillManuallyClick,
  handlePasteVinInput,
  handleLoadDataClick,
}) => {
  const { translations } = useTranslation();
  const { setValue } = useFormContext();

  React.useEffect(() => {
    setValue('vin', initialVin);
  }, [initialVin, setValue]);

  return (
    <div className={styles.container}>
      <div
        className={styles.vinContainer}
        data-qa-id={`${qaIdPrefix}-vin-container`}
      >
        <div className={styles.form}>
          {/* VIN */}
          <FormVinInput
            label={translations.VIN}
            qaIdPrefix={`${qaIdPrefix}-vin-input`}
            name="vin"
            containerClassName={styles.vinInput}
            placeholder={translations.VIN}
            className={styles.input}
            defaultValue=""
            onPaste={handlePasteVinInput}
          />

          <Button
            theme="orange"
            extraClass={styles.button}
            qaId={`${qaIdPrefix}-load-vin-data`}
            isDisabled={isSubmitting}
            onClick={handleLoadDataClick}
          >
            {translations.LOAD_VIN_DATA}
            {isSubmitting && <Loader extraClass={styles.buttonLoader} />}
          </Button>
          <Button
            theme="link"
            extraClass={styles.fillManuallyButton}
            onClick={onFillManuallyClick}
            qaId={`${qaIdPrefix}-fill-manually-button`}
            isDisabled={!branch}
          >
            {translations.EDIT_DATA}
          </Button>
        </div>
        <p className={styles.errorMsg}>{serverError}</p>
      </div>

      {/* LICENCE PLATE AND DEALER ID */}
      <Grid gutter={[2, 3]} columns={2}>
        <Grid item data-qa-id={`${qaIdPrefix}-container`}>
          <div className={styles.inputWrapper}>
            <FormInput
              name="licensePlate"
              className={styles.input}
              containerClassName={styles.inputContainerFullWidth}
              qaIdPrefix={`${qaIdPrefix}-license-plate`}
              label={
                <>
                  {translations.LICENSE_PLATE}
                  <span className={styles.optionalLabel}>
                    {translations.OPTIONAL}
                  </span>
                </>
              }
              defaultValue=""
            />
          </div>
        </Grid>
        <Grid item data-qa-id={`${qaIdPrefix}-container`}>
          <div className={styles.inputWrapper}>
            <FormInput
              name="externalId"
              className={styles.input}
              containerClassName={styles.inputContainerFullWidth}
              qaIdPrefix={`${qaIdPrefix}-external-id`}
              label={
                <>
                  {translations.INTERNAL_NUMBER}
                  <span className={styles.optionalLabel}>
                    {translations.OPTIONAL}
                  </span>
                </>
              }
              defaultValue=""
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export { VinSearch };
