import * as React from 'react';
import { Checked } from '@auto1-ui/icons';
import { useOtherEquipmentQuery } from 'gql/graphql';

import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { FormTextarea } from 'shared/components/FormTextarea';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { CollapseLink } from 'shared/components/CollapseLink';
import { keyExtractor } from 'shared/utils/keyExtractor';
import type { EquipmentMeta } from 'gql/graphql';
import styles from '../index.module.scss';

const defaultOptionsToShow = 6;

const filterEquipments = (equipment: Partial<EquipmentMeta>): boolean => {
  return (
    equipment.isOriginal === true &&
    (equipment.source === 'vin' || equipment.source === 'ecode')
  );
};

type Props = {
  qaIdPrefix?: string;
};

const OtherEquipment: React.FC<Props> = ({ qaIdPrefix }) => {
  const { inspectionId } = useInspection();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { data } = useOtherEquipmentQuery({
    variables: {
      inspectionId,
    },
  });

  const otherEquipments = data?.inspection?.meta?.equipment ?? [];
  const filteredEquipments = otherEquipments.filter(filterEquipments);
  const noOfItemsInCollapsedMode =
    filteredEquipments.length < 6
      ? filteredEquipments.length
      : defaultOptionsToShow;

  const optionsToShow = isExpanded
    ? filteredEquipments.length
    : noOfItemsInCollapsedMode;
  const { translations } = useTranslation();

  if (!filteredEquipments.length) {
    return null;
  }
  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      id="other-equipment"
      qaIdPrefix={`${qaIdPrefix}-other-equipment-container`}
      showBottomBorder
    >
      <Typography
        variant="titleXL"
        additonalClassNames={styles.title}
        tag="div"
        data-qa-id={`${qaIdPrefix}-other-equipment-title`}
      >
        {translations.OTHER_EQUIPMENT_ACCORDING_VIN_DATA}
      </Typography>

      <ul
        className={styles.otherEquipmentList}
        data-qa-id={`${qaIdPrefix}-other-equipment-item-details-container`}
      >
        {filteredEquipments.slice(0, optionsToShow).map((equipment, index) => {
          return (
            <li
              data-qa-id={`${qaIdPrefix}-other-equipment-item-${index}`}
              className={styles.otherEquipmentItem}
              key={keyExtractor(equipment, index)}
            >
              <Checked
                className={styles.checkedIcon}
                data-qa-id={`${qaIdPrefix}-other-equipment-item-${index}-checked`}
              />
              {equipment.description}
            </li>
          );
        })}
      </ul>

      {filteredEquipments.length > 0 && (
        <button
          data-qa-id={`${qaIdPrefix}-other-equipment-expand-button`}
          className={styles.showMoreButton}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!isExpanded ? translations.SHOW_MORE : translations.SHOW_LESS}
        </button>
      )}

      <div
        className={styles.reportDataWrapper}
        data-qa-id={`${qaIdPrefix}-other-equipment-report-wrong-data-wrapper`}
      >
        <CollapseLink
          title={translations.REPORT_WRONG_DATA}
          qaIdPrefix={`${qaIdPrefix}-other-equipment-report-wrong-data-container`}
        >
          <FormTextarea
            rows={5}
            qaIdPrefix={`${qaIdPrefix}-other-equipment-report-wrong-data-text-area`}
            name="otherInformation.otherNote"
            defaultValue=""
            containerClassName={styles.inputContainerFullWidth}
          />
        </CollapseLink>
      </div>
    </Card>
  );
};

export { OtherEquipment };
