import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';

type Props = {
  component?: React.ReactNode;
};

const ErrorMessagePage: React.FC<Props> = ({ component = null }) => {
  const location =
    useLocation<{
      message: string;
    }>();

  return (
    <div className={styles.errorMessage}>
      {component || <p>{location?.state?.message}</p>}
    </div>
  );
};

export { ErrorMessagePage };
