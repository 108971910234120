import { InMemoryCache } from '@apollo/client/cache';
import type { FieldFunctionOptions } from '@apollo/client/cache/inmemory/policies';
import type { UserBranchesPaginatedQuery } from 'gql/graphql';

const TYPES_WITH_MERGE = [
  'InspectionFlags',
  'MetaPurchasing',
  'MetaEvaluation',
  'MetaPaintThickness',
  'VehicleFile',
  'PriceIndicatorPrice',
  'Highlight',
  'EquipmentMeta',
  'Accident',
  'MetaVerification',
  'FailedReason',
  'TestDrive',
  'Vehicle',
  'VehicleIdentifiers',
  'VehicleModel',
  'VehicleEngine',
  'VehicleEngineDetails',
  'VehicleCondition',
  'VehicleConditionBrake',
  'VehicleInterior',
  'VehicleExterior',
  'VehicleExteriorColorDetails',
  'VehicleExteriorWheelSet',
  'VehicleExteriorWheelDetail',
  'VehicleFeatures',
  'VehicleFields',
  'VehicleFieldsAccidents',
  'Car',
];
const TYPE_POLICIES_WITH_MERGE = TYPES_WITH_MERGE.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: {
      merge: true,
    },
  }),
  {},
);

function getUserBranchesPaginated(
  ref: UserBranchesPaginatedQuery,
  options: FieldFunctionOptions,
) {
  const name = options.readField(
    'name',
    ref,
  ) as UserBranchesPaginatedQuery['userBranchesPaginated']['name'];
  const page = options.readField(
    'page',
    ref,
  ) as UserBranchesPaginatedQuery['userBranchesPaginated']['page'];
  const pageSize = options.readField(
    'pageSize',
    ref,
  ) as UserBranchesPaginatedQuery['userBranchesPaginated']['pageSize'];
  const totalPageCount = options.readField(
    'totalPageCount',
    ref,
  ) as UserBranchesPaginatedQuery['userBranchesPaginated']['totalPageCount'];
  const entities = options.readField(
    'entities',
    ref,
  ) as UserBranchesPaginatedQuery['userBranchesPaginated']['entities'];

  return {
    name,
    page,
    pageSize,
    totalPageCount,
    entities,
  };
}

function mergetUserBranchesPaginated(
  existingRef: UserBranchesPaginatedQuery | undefined,
  incommingRef: UserBranchesPaginatedQuery,
  options: FieldFunctionOptions,
) {
  if (existingRef === undefined) {
    return incommingRef;
  }

  const existing = getUserBranchesPaginated(existingRef, options);
  const incomming = getUserBranchesPaginated(incommingRef, options);

  return {
    ...existing,
    page: incomming.page,
    entities: [...existing.entities, ...incomming.entities],
  };
}

export const cache = new InMemoryCache({
  typePolicies: {
    User: {
      keyFields: ['email'],
      merge: true,
    },
    Inspection: {
      keyFields: ['inspectionId'],
      merge: true,
    },
    Viewer: {
      keyFields: ['userEmail'],
      merge: true,
    },
    VehicleDamage: {
      keyFields: ['uuid'],
      merge: true,
    },
    VehicleImage: {
      keyFields: ['absoluteUrl'],
      merge: true,
    },
    Query: {
      fields: {
        inspection: {
          keyArgs: ['inspectionId'],
          merge: true,
        },
        userBranchesPaginated: {
          keyArgs: ['name'],
          merge: mergetUserBranchesPaginated,
        },
        userMerchant: {
          merge: true,
        },
      },
    },
    Mutation: {
      fields: {
        updateBranch: {
          keyArgs: ['id'],
          merge: true,
        },
      },
    },
    ...TYPE_POLICIES_WITH_MERGE,
  },
});

export const resetApolloCache = async () => {
  await cache.reset();
};
