import * as yup from 'yup';
import { CarInfoSchema } from 'shared/components/VinInfo/car-info-schema';
import type { Translations } from 'shared/utils/translations';

const ManualFlowSchema = (translations: Translations) =>
  CarInfoSchema(translations).shape({
    manufacturerId: yup.string().required(),
    mainTypeId: yup.string().required(),
    builtDate: yup.string().required(),
    bodyType: yup.string().required(),
    mainTypeDetailId: yup.string().required(),
    ecode: yup.string().required(),
  });

export { ManualFlowSchema };
