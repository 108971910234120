import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { appRoutes } from 'appRoutes';
import { yupResolver } from '@hookform/resolvers/yup';
import { apm } from '@elastic/apm-rum';
import {
  useBranchQuery,
  useCreateBranchMutation,
  useUpdateBranchMutation,
} from 'gql/graphql';
import type { Translations } from 'shared/utils/translations';
import {
  mapFormDataToMutation,
  mapDataFromQueryToForm,
  buildAddress,
} from './helpers';
import { locationSettingsFormSchema } from './location-settings-form-schema';
import type { Form, FormCountry } from './types';

const useEditLocation = ({
  translations,
  clickedOnManualFlow,
}: {
  translations: Translations;
  clickedOnManualFlow: boolean;
}) => {
  const [addLocation] = useCreateBranchMutation({
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          userBranches(existing = []) {
            return [...existing, data?.createBranch];
          },
        },
      });
    },
  });
  const [editLocation] = useUpdateBranchMutation();
  const [loading, setLoading] = React.useState(false);
  const [oldBranchName, setOldBranchName] = React.useState('');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isEditPage = id;

  const formMethods = useForm<Form>({
    resolver: yupResolver(locationSettingsFormSchema(translations)),
  });

  const { dirtyFields } = formMethods.formState;

  useBranchQuery({
    variables: {
      id: Number(id),
    },
    skip: !isEditPage,
    onCompleted: (data) => {
      setOldBranchName(data?.branch?.name ?? '');
      formMethods.reset(mapDataFromQueryToForm(data?.branch));
    },
  });

  const handleAddLocation = () =>
    formMethods.handleSubmit(async (form) => {
      try {
        await addLocation({
          variables: {
            branch: { ...mapFormDataToMutation(form) },
          },
        });

        window.tracking.eva.createBranch({
          branchName: form.name,
          addressFilledManually: clickedOnManualFlow ? 'Yes' : 'No',
        });

        history.push(`${appRoutes.locationsList()}?state=success`);
      } catch (e) {
        apm.captureError(e as Error);
        console.error(e);
      }
    });

  const handleEditLocation = () =>
    formMethods.handleSubmit(async (form) => {
      try {
        await editLocation({
          variables: {
            id: Number(id),
            branch: { ...mapFormDataToMutation(form) },
          },
        });
        const dirtyFieldsList = Object.keys(dirtyFields).filter(
          (key) => dirtyFields[key as keyof typeof dirtyFields] === true,
        );
        window.tracking.eva.saveBranch({
          editedFields: dirtyFieldsList.join(', '),
          branchName: form.name,
          previousBranchName: oldBranchName,
        });

        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('backTo')) {
          window.location.assign(searchParams.get('backTo') as string);
          return;
        }

        history.push(`${appRoutes.locationsList()}`);
      } catch (e) {
        apm.captureError(e as Error);
        console.error(e);
      }
    });

  const getAddressItem = (
    address: ReadonlyArray<google.maps.GeocoderAddressComponent> | undefined,
    property: string,
  ) => {
    return (
      address?.find((item) => item.types.includes(property))?.short_name ?? ''
    );
  };

  const setAddressComponentsFormFields = (
    addressComponents?: ReadonlyArray<google.maps.GeocoderAddressComponent>,
  ) => {
    const setValue = formMethods.setValue;

    const streetNumber = getAddressItem(addressComponents, 'street_number');
    const street = getAddressItem(addressComponents, 'route');
    const zipcode = getAddressItem(addressComponents, 'postal_code');
    const city =
      getAddressItem(addressComponents, 'locality') ||
      getAddressItem(addressComponents, 'postal_town');
    const country = getAddressItem(addressComponents, 'country') as
      | FormCountry
      | '';

    setValue('streetNumber', streetNumber);
    setValue('street', street);
    setValue('zipcode', zipcode);
    setValue('country', country);
    setValue('city', city);

    setValue(
      'address',
      buildAddress({ streetNumber, street, city, zipcode, country }),
    );
  };

  const getAddressComponents = async (placeId: string) => {
    setLoading(true);

    if (window.google) {
      const request = {
        placeId,
        fields: ['address_component'],
      };
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
      placesService.getDetails(request, (response) => {
        setLoading(false);
        const addressComponents = response?.address_components;
        setAddressComponentsFormFields(addressComponents);
      });
    }
  };

  return {
    getAddressComponents,
    loading,
    setLoading,
    setAddressComponentsFormFields,
    formMethods,
    isEditPage,
    handleEditLocation,
    handleAddLocation,
  };
};
export { useEditLocation };
