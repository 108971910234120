import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { apm } from '@elastic/apm-rum';
import { Button, ButtonTypes, ButtonThemes } from '@auto1-ui/button';
import { withTransaction } from '@elastic/apm-rum-react';

import { appRoutes } from 'appRoutes';
import { useServiceDocumentsInitialDataQuery } from 'gql/graphql';
import { useScrollToSection } from 'shared/utils/useSectionScroll';
import styles from 'shared/components/NavigationFooter/index.module.scss';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { useInspection } from 'shared/components/InspectionContext';
import { useTranslation } from 'shared/hooks/useTranslation';
import { RoutesEnum } from 'shared/utils/route';
import { scrollToError } from 'shared/utils/scrollToError';
import Loader from 'shared/components/Loader';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import {
  useUploadContext,
  withUploadContext,
} from 'shared/components/UploadContext';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { useSideMenuNavigationTracking } from 'shared/hooks/useSideMenuNavigationTracking';
import { AlertBar } from 'shared/components/AlertBar';
import type { Translations } from 'shared/utils/translations';

import { useServiceDocumentsUpdater } from './hooks/useServiceDocumentsUpdater';
import { useProgress } from './hooks/useProgress';
import { Service } from './Service';
import { Accidents } from './Accidents';
import { OtherDocuments } from './OtherDocuments';
import { CarServiceSchema } from './car-service.schema';
import { Placeholder } from './Placeholder';
import { formatDataToMutation, formatDataToFormValues } from './helpers';
import type { Form } from './types';

type Props = {
  qaIdPrefix?: string;
};

const ServicesDocuments: React.FC<Props> = withUploadContext(
  ({ qaIdPrefix = 'service-and-documents' }) => {
    const history = useHistory();
    const location =
      useLocation<{
        prev?: {
          pathname: string;
          hash: string;
        };
        sideMenuNavigation?: boolean;
      }>();
    const submitContext = React.useRef<'save' | 'submit' | null>(null);
    const {
      removeStepFromNotCompleted,
      isPdfInspection,
      inspectionId,
      shouldUseNewAccidentModel,
    } = useInspection();
    const { translations } = useTranslation();
    const { submitTracking } = useSubmitTracking('serviceAndDocuments');
    useSideMenuNavigationTracking(
      location.state && location.state.prev,
      location,
    );

    const { hasUploadInProgress } = useUploadContext();

    const { scroll } = useScrollToSection(location.hash);
    const formMethods = useForm<Form>({
      context: submitContext,
      resolver: (values, context, options) =>
        yupResolver(
          CarServiceSchema(
            translations,
            context?.current === 'save',
            isPdfInspection,
            shouldUseNewAccidentModel,
          ),
        )(values, context, options),
    });
    const { data, loading } = useServiceDocumentsInitialDataQuery({
      variables: {
        inspectionId,
      },
      onCompleted: (result) => {
        const finalFormValues = formatDataToFormValues(
          result?.inspection,
          shouldUseNewAccidentModel,
        );
        formMethods.reset({ ...finalFormValues });
        setTimeout(scroll);
      },
    });

    const [updateServiceDocuments, { error: mutationError }] =
      useServiceDocumentsUpdater({
        stockNumber: data?.inspection?.car?.stockNumber ?? '',
        isPdfInspection,
        shouldUseNewAccidentModel,
      });
    const [uploadError, setUploadError] = React.useState('');
    const serverError =
      mutationError?.graphQLErrors?.[0]?.message ?? uploadError;

    const setProgress = useProgress();

    const {
      handleSubmit,
      formState: { isSubmitting, isDirty },
    } = formMethods;

    const onError = () => {
      scrollToError();
      setProgress(formMethods.getValues());
    };

    const onSubmit = async (values: Form) => {
      const optionalSchema = submitContext.current === 'save';
      const redirect = submitContext.current === 'submit';

      try {
        await updateServiceDocuments({
          inspectionId,
          ...formatDataToMutation(values),
          incomplete: optionalSchema,
        });

        formMethods.reset(values);
        removeStepFromNotCompleted(
          RoutesEnum.SERVICE_AND_DOCUMENTS as keyof typeof RoutesEnum,
        );
        if (redirect) {
          submitTracking();
          history.push(appRoutes.summary(`${inspectionId}`, true));
        }
      } catch (e) {
        apm.captureError(e as Error);
        setUploadError((e as Error).message);
      }
    };

    if (loading) {
      return <Placeholder />;
    }

    return (
      <LayoutContainer>
        <FormProvider {...formMethods}>
          <Service qaIdPrefix="service" />
          {!shouldUseNewAccidentModel && <Accidents qaIdPrefix="accidents" />}
          <OtherDocuments
            inspectionId={inspectionId}
            qaIdPrefix="other-documents"
          />
          <NavigationFooter
            backButtonLink={appRoutes.carStatus(`${inspectionId}`, true)}
            rightAreaContent={
              <>
                <Button
                  type={ButtonTypes.button}
                  theme={ButtonThemes.outlined}
                  isActivated={false}
                  qaId={`${qaIdPrefix}-footer-save-link`}
                  extraClass={styles.saveButton}
                  onClick={() => {
                    submitContext.current = 'save';
                    handleSubmit(onSubmit, onError)();
                  }}
                  isDisabled={isSubmitting || hasUploadInProgress}
                >
                  {translations.SAVE}
                  {isSubmitting && submitContext.current === 'save' && (
                    <Loader extraClass={styles.saveButtonLoader} />
                  )}
                </Button>
                <Button
                  type={ButtonTypes.button}
                  theme={ButtonThemes.orange}
                  extraClass={styles.nextButton}
                  qaId={`${qaIdPrefix}-footer-next-link`}
                  isActivated={false}
                  onClick={() => {
                    submitContext.current = 'submit';
                    handleSubmit(onSubmit, onError)();
                  }}
                  isDisabled={isSubmitting || hasUploadInProgress}
                >
                  {translations.NEXT}
                  {isSubmitting && submitContext.current === 'submit' && (
                    <Loader extraClass={styles.nextButtonLoader} />
                  )}
                </Button>
              </>
            }
            bottomAreaContent={
              serverError && (
                <AlertBar
                  type="error"
                  message={
                    serverError in translations
                      ? translations[serverError as keyof Translations]
                      : translations.GENERIC_ERROR_MESSAGE
                  }
                  additionalClassNames={styles.alertBar}
                />
              )
            }
          />
          <RouteLeavingGuard
            when={isDirty}
            title={translations.UNSAVED_CHANGES}
          >
            <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
          </RouteLeavingGuard>
        </FormProvider>
      </LayoutContainer>
    );
  },
);

const ServicesDocumentsWithTransaction = withTransaction(
  'ServicesDocuments',
  'component',
)(ServicesDocuments);

export { ServicesDocumentsWithTransaction as ServicesDocuments };
