import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { mapPathToRoute, RoutesEnum } from 'shared/utils/route';

import { useCarDetailsProgress } from './useCarDetailsProgress';
import { useTestDriveProgress } from './useTestDriveProgress';
import { useCarPicturesProgress } from './useCarPicturesProgress';
import { useCarQualityProgress } from './useCarQualityProgress';
import { useTiresAndBrakesProgress } from './useTiresAndBrakesProgress';
import { useCarStatusProgress } from './useCarStatusProgress';
import { useServicesDocumentsProgress } from './useServicesDocumentsProgress';

function useProgressCalculation(inspectionId: number) {
  const history = useHistory();
  const location = useLocation();

  const updateCarDetailsProgress = useCarDetailsProgress(inspectionId);
  const updateTestDriveProgress = useTestDriveProgress(inspectionId);
  const updateCarPicturesProgress = useCarPicturesProgress(inspectionId);
  const updateCarQualityProgress = useCarQualityProgress(inspectionId);
  const updateTiresBrakesProgress = useTiresAndBrakesProgress(inspectionId);
  const updateCarStatusProgress = useCarStatusProgress(inspectionId);
  const updateServicesDocumentsProgress =
    useServicesDocumentsProgress(inspectionId);

  React.useEffect(() => {
    return history.listen(() => {
      const section = Object.keys(mapPathToRoute).find((path) =>
        location.pathname.includes(path),
      );

      if (!section) {
        return;
      }

      switch ((mapPathToRoute as Record<string, string>)[section]) {
        case RoutesEnum.CAR_DETAILS:
          updateCarDetailsProgress();
          break;
        case RoutesEnum.TEST_DRIVE:
          updateTestDriveProgress();
          break;
        case RoutesEnum.CAR_PICTURES:
          updateCarPicturesProgress();
          break;
        case RoutesEnum.CAR_QUALITY:
          updateCarQualityProgress();
          break;
        case RoutesEnum.TIRES_AND_BRAKES:
          updateTiresBrakesProgress();
          break;
        case RoutesEnum.CAR_STATUS:
          updateCarStatusProgress();
          break;
        case RoutesEnum.SERVICE_AND_DOCUMENTS:
          updateServicesDocumentsProgress();
          break;
        default:
      }
    });
  }, [
    history,
    location,
    updateCarDetailsProgress,
    updateCarPicturesProgress,
    updateCarQualityProgress,
    updateCarStatusProgress,
    updateServicesDocumentsProgress,
    updateTestDriveProgress,
    updateTiresBrakesProgress,
  ]);
}

export { useProgressCalculation };
