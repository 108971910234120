import * as React from 'react';
import { Link } from 'react-router-dom';
import { appRoutes } from 'appRoutes';
import styles from './index.module.scss';

type Props = {
  locations?: Array<{
    id: number;
    name: string;
    zipcode: string;
    street: string;
    city: string;
  }>
}
const LocationsList: React.FC<Props> = ({ locations = [] }) => {
  return (
    <ul className={styles.locationsList} data-qa-id="locations-list">
      {!!locations.length &&
        locations.map((item) => {
          return (
            <li key={item.id}>
              <h2 className={styles.name}>{item.name}</h2>
              <h4 className={styles.address}>
                {item?.street}, {item?.zipcode} {item?.city}
              </h4>
              <Link
                to={() => appRoutes.editLocation(`${item.id}`)}
                className={styles.editLink}
                onClick={() => {
                  window.tracking.eva.editBranch({
                    branchName: item.name,
                    branchId: item.id,
                  });
                }}
              >
                Edit
              </Link>
            </li>
          );
        })}
    </ul>
  );
};


export { LocationsList };
