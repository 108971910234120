import { format } from 'date-fns';

import type {
  ServiceBookAppointmentsEnum,
  ServiceDocumentsInitialDataQuery,
} from 'gql/graphql';
import { parseMaskedDate } from 'shared/utils/parseMaskedDate';

import {
  SERVICE_BOOK_APPOINTMENTS_ALL,
  SERVICE_BOOK_APPOINTMENTS_SOME,
  SERVICE_BOOK_AVAILABLE_UNAVAILABLE,
  ACCIDENT_REPAIRED,
  ACCIDENT_NOT_REPAIRED,
} from '../constants';
import type { ServiceForm, Form, AccidentForm } from '../types';

const formatDataToFormValues = (
  inspection: ServiceDocumentsInitialDataQuery['inspection'],
  shouldUseNewAccidentModel: boolean,
) => {
  const {
    condition = {},
    engine = {},
    damages = [],
  } = inspection?.vehicle ?? {};
  const {
    serviceBookAppointments,
    serviceBookImages,
    kmEngineExchange,
    driveBeltChanged,
    technicalInspectionReport,
    technicalInspectionCompanyId,
    otherNote,
  } = inspection?.meta?.evaluation ?? {};

  const engineList = engine?.engineList;
  const {
    hasHadAccident = null,
    hasServiceBook = null,
    serviceBookType = null,
    lastServiceAtDate = null,
    lastServiceAtMileage = null,
    lastDriveBeltChangeDate = null,
    lastDriveBeltChangeMileage = null,
    isUnroadworthy = null,
    cannotBeRolled = null,
    inspectionExpiryDate = null,
  } = condition ?? {};

  const getServiceData = () => {
    const getServiceBook = () => {
      if (hasServiceBook === null) {
        return {};
      }
      return {
        serviceBook: hasServiceBook
          ? serviceBookType
          : SERVICE_BOOK_AVAILABLE_UNAVAILABLE,
      };
    };

    const getDriveBeltData = () => {
      if (driveBeltChanged !== null) {
        const driveBeltDate = lastDriveBeltChangeDate
          ? format(new Date(lastDriveBeltChangeDate), 'MM/yyyy')
          : '';

        return {
          driveBeltChanged,
          driveBeltDate,
          driveBeltMileage: lastDriveBeltChangeMileage,
        };
      }
      return {};
    };

    const getEngineData = () => {
      if (!Array.isArray(engineList)) {
        return {};
      }
      const [item] = engineList;
      const { isOriginal } = item || {};
      if (typeof isOriginal !== 'boolean') {
        return {};
      }
      return {
        isOriginalEngine: isOriginal,
        kmEngineExchange: kmEngineExchange ?? null,
      };
    };

    const getCarRoadworthyData = () => {
      if (isUnroadworthy !== null) {
        return {
          isRoadworthy: !isUnroadworthy,
          cannotBeRolled: cannotBeRolled === null ? null : !cannotBeRolled,
        };
      }
      return {};
    };

    const getInspectionExpiryDate = () => {
      return (
        inspectionExpiryDate !== null && {
          inspectionExpiryDate: format(
            new Date(inspectionExpiryDate),
            'MM/yyyy',
          ),
        }
      );
    };

    return {
      serviceBookAvailable: hasServiceBook,
      ...getServiceBook(),
      serviceBookImages: Array.isArray(serviceBookImages)
        ? serviceBookImages
        : [],
      // PI2 can send this info regardless of hasServiceBook or serviceBookAvailable
      ...(serviceBookAppointments && { serviceBookAppointments }),
      lastServiceMileage: lastServiceAtMileage,
      ...(lastServiceAtDate && {
        lastServiceDate: format(new Date(lastServiceAtDate), 'MM/yyyy'),
      }),
      ...getInspectionExpiryDate(),
      ...getEngineData(),
      ...getDriveBeltData(),
      ...getCarRoadworthyData(),
    } as ServiceForm;
  };
  const accidents = inspection?.meta?.accidents;

  const getAccidentData = () => {
    if (!Array.isArray(accidents)) {
      return {};
    }
    const [accident] = accidents;
    if (!accident) {
      const hasDamageFromAccidents: true | null =
        damages?.some(({ isAccidentReason }) => isAccidentReason) === true
          ? true
          : null;
      return {
        hasHadAccident: hasHadAccident ?? hasDamageFromAccidents,
        wasRepaired: null,
        repairedBy: '',
        repairCost: null,
        isRepairCostsAvailable: null,
      };
    }
    let wasRepaired = null;
    if (accident.repairStatus !== null) {
      wasRepaired = accident.repairStatus !== ACCIDENT_NOT_REPAIRED;
    }

    return {
      hasHadAccident: true,
      wasRepaired,
      repairedBy: (wasRepaired && accident.repairedBy) || '',
      repairCost: accident.repairCost ?? null,
      isRepairCostsAvailable:
        accident.isRepairCostsAvailable !== null
          ? !accident.isRepairCostsAvailable
          : null,
      invoiceImages: accident.invoiceImages,
    };
  };

  return {
    service: getServiceData(),
    ...(!shouldUseNewAccidentModel && {
      accident: getAccidentData(),
    }),
    ...(technicalInspectionReport && {
      inspectionReport: technicalInspectionReport,
    }),
    ...(technicalInspectionCompanyId && {
      companyId: technicalInspectionCompanyId,
    }),
    ...(otherNote && { otherNote }),
  } as Form;
};

const formatServiceToMutation = (data: ServiceForm) => {
  const {
    serviceBookAppointments,
    serviceBook,
    inspectionExpiryDate,
    lastServiceDate,
    lastServiceMileage,
    driveBeltMileage,
    kmEngineExchange,
    cannotBeRolled,
    ...restData
  } = data;

  const isServiceBookAvailable =
    serviceBook !== null && serviceBook !== ''
      ? serviceBook !== SERVICE_BOOK_AVAILABLE_UNAVAILABLE
      : null;

  const dataForMutation = {
    ...restData,
    serviceBookAppointmentsAvailable: (
      [
        SERVICE_BOOK_APPOINTMENTS_ALL,
        SERVICE_BOOK_APPOINTMENTS_SOME,
      ] as (ServiceBookAppointmentsEnum | null)[]
    ).includes(serviceBookAppointments),
    serviceBookAppointments: serviceBookAppointments || null,
    serviceBook:
      serviceBook !== SERVICE_BOOK_AVAILABLE_UNAVAILABLE && serviceBook !== ''
        ? serviceBook
        : undefined,
    serviceBookAvailable: isServiceBookAvailable,
    driveBeltMileage,
    lastServiceDate: parseMaskedDate(lastServiceDate),
    lastServiceMileage: lastServiceMileage ?? null,
    kmEngineExchange: kmEngineExchange ?? null,
    inspectionExpiryDate: parseMaskedDate(inspectionExpiryDate),
    cannotBeRolled:
      cannotBeRolled !== null && cannotBeRolled !== undefined
        ? !cannotBeRolled
        : null,
  };

  if (dataForMutation.driveBeltChanged) {
    dataForMutation.driveBeltDate = parseMaskedDate(
      dataForMutation.driveBeltDate,
    );
  } else {
    delete dataForMutation.driveBeltDate;
    delete dataForMutation.driveBeltMileage;
  }

  return dataForMutation;
};

const formatAccidentToMutation = (data: AccidentForm) => {
  const {
    isRepairCostsAvailable,
    hasHadAccident,
    wasRepaired,
    repairCost,
    invoiceImages,
  } = data;

  const repaired = hasHadAccident && wasRepaired;
  let repairStatus = null;
  if (typeof repaired === 'boolean') {
    repairStatus = repaired ? ACCIDENT_REPAIRED : ACCIDENT_NOT_REPAIRED;
  }

  return {
    hasHadAccident,
    repairStatus,
    repairCost: !isRepairCostsAvailable ? repairCost : null,
    isRepairCostsAvailable:
      !isRepairCostsAvailable && !repairCost ? null : !isRepairCostsAvailable,
    repairedBy: repaired ? data.repairedBy || null : undefined,
    invoiceImages,
  };
};

const formatDataToMutation = (formData: Form) => {
  const { accident, service, ...restData } = formData;

  return {
    ...restData,
    service: formatServiceToMutation(service),
    ...(accident && {
      accident: formatAccidentToMutation(accident),
      hasHadAccident: formData.accident?.hasHadAccident,
    }),
  };
};

export { formatDataToFormValues, formatDataToMutation };
