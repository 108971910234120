import * as React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { apm } from '@elastic/apm-rum';
import { withTransaction } from '@elastic/apm-rum-react';
import { appRoutes } from 'appRoutes';
import {
  useMsiPdfSubmitVinMutation,
  useCreateInspectionFromPdfMutation,
} from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Button, ButtonThemes } from '@auto1-ui/button';
import { AlertBar } from 'shared/components/AlertBar';
import { BranchSelection } from 'shared/components/BranchSelection';
import { BranchSelectionPaginated } from 'shared/components/BranchSelectionPaginated';
import { Card } from 'shared/components/Card';
import { Grid } from 'shared/components/Grid';
import { Typography } from 'shared/components/Typography';
import { useRedirectToInspection } from 'shared/components/CarVinInfo/hooks/useRedirectToInspection';
import { FormUploader } from 'shared/components/FormUploader';
import { InputLabel } from 'shared/components/InputLabel';
import { PdfFile } from 'shared/components/PdfFile';
import { CarVinInfo } from 'shared/components/CarVinInfo';
import { usePdfInspectionStatus } from 'shared/hooks/usePdfInspectionStatus';
import { usePdfParserUploader } from 'shared/hooks/usePdfParserUploader';
import { useCheckVin } from 'shared/hooks/useCheckVin';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { formatVinToAPI } from 'shared/utils/formatVin';
import { getPdfFilename } from 'shared/utils/url';
import { PdfJobStatus } from 'shared/constants';
import { AllInspectionsLink } from 'shared/components/AllInspectionsLink';
import { CarInfoSchema } from './pdfInfo.schema';
import type { Form } from './types';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
};

const PdfInspectionInfo: React.FC<Props> = ({
  qaIdPrefix = 'pdf-inspection',
}) => {
  const history = useHistory();
  const [serverError, setServerError] = React.useState('');
  const { translations } = useTranslation();
  const [pdfInspectionLoading, setPdfInspectionLoading] = React.useState(false);
  const [checkStatus, setCheckStatus] = React.useState(false);
  const [submitAutomatically, setSubmitAutomatically] = React.useState(true);
  const [pdfInspectionStatus, setPdfInspectionStatus] =
    React.useState<keyof typeof PdfJobStatus | null>(null);
  const [pdfInspectionId, setPdfInspectionId] = React.useState<number | null>();
  const formMethods = useForm<Form>({
    resolver: (data, context, options) =>
      yupResolver(
        CarInfoSchema(
          translations,
          pdfInspectionStatus !== PdfJobStatus.TIMED_OUT,
        ),
      )(data, context, options),
  });
  const {
    watch,
    setError,
    setValue,
    handleSubmit,
    formState: { isSubmitting: isSubmitting },
  } = formMethods;
  const formValues = watch();
  const vinValue = watch('vin');
  const branchId = watch('branch');

  const [hasUserBranches, setHasUserBranches] = React.useState(
    isMobileEvaluatorApp(),
  );

  const { handleCompleted, handleTimeoutError } = useRedirectToInspection();
  const [pdfSubmitVin] = useMsiPdfSubmitVinMutation({
    onCompleted: (data) => {
      const result = data.msiPdfSubmitVin ?? {};
      setServerError('');
      handleCompleted({ result, branchId, vinValue, action: 'loadVIN' });
    },
    onError: (error) => {
      if (
        // @ts-ignore
        error.networkError?.statusCode === 504 ||
        // @ts-ignore
        error.graphQLErrors?.some(({ code }) => code === 504)
      ) {
        handleTimeoutError({ branchId, vinValue });
      } else if (
        error.graphQLErrors?.some(
          ({ message }) => message === 'RERUN_LIMIT_EXHAUSTED',
        )
      ) {
        setServerError(translations.RERUN_LIMIT_EXHAUSTED);
      } else {
        setServerError(translations.LOAD_DATA_SERVER_ERROR);
      }
    },
  });

  const [submitPdfToParser] = useCreateInspectionFromPdfMutation({
    onCompleted: (data) => {
      setPdfInspectionLoading(true);

      setServerError('');
      setPdfInspectionId(data?.createInspectionFromPdf?.inspectionId);
      setPdfInspectionStatus(data?.createInspectionFromPdf?.status ?? null);

      setCheckStatus(
        data?.createInspectionFromPdf?.status === PdfJobStatus.IN_PROGRESS,
      );
    },
    onError: () => {
      setServerError(translations.LOAD_DATA_SERVER_ERROR);

      setPdfInspectionId(null);
      setPdfInspectionStatus(null);
      setCheckStatus(false);
      setPdfInspectionLoading(false);
    },
  });
  const pdfFile = watch('inspectionReport');
  const pdfFileName = getPdfFilename(pdfFile?.absoluteUrl ?? '');

  const pdfInspection = usePdfInspectionStatus({
    pdfUrl: pdfFileName ?? '',
    checkStatus,
    setPdfInspectionLoading,
  });

  React.useEffect(() => {
    setPdfInspectionStatus(
      pdfInspection?.inspectionFromPdfStatus?.status ?? null,
    );
    setPdfInspectionId(pdfInspection?.inspectionFromPdfStatus?.inspectionId);
  }, [pdfInspection]);

  React.useEffect(() => {
    if (
      !serverError &&
      pdfInspectionId &&
      (
        [PdfJobStatus.FINISHED, PdfJobStatus.FAILED] as ReadonlyArray<
          keyof typeof PdfJobStatus
        >
      ).includes(pdfInspectionStatus as keyof typeof PdfJobStatus)
    ) {
      history.push({
        pathname: appRoutes.carDetails(`${pdfInspectionId}`, true),
        state: { pdfStatus: pdfInspectionStatus },
      });
    }
  }, [pdfInspectionStatus, pdfInspectionId, history, serverError]);

  React.useEffect(
    () => {
      if (pdfInspectionStatus === PdfJobStatus.TIMED_OUT) {
        if (vinValue && submitAutomatically) {
          const vin = formatVinToAPI(vinValue);
          const { inspectionReport, ...values } = formValues;

          pdfSubmitVin({
            variables: {
              ...values,
              vin,
            },
          });
        }
        if (!vinValue) {
          setSubmitAutomatically(false);
          setError('vin', {
            type: 'required',
            message: translations.THIS_FIELD_CANNOT_BE_EMPTY,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pdfInspectionStatus,
      setError,
      translations,
      vinValue,
      submitAutomatically,
      pdfSubmitVin,
    ],
  );

  const {
    checkVin,
    loading: loadingCheckVin,
    modal: modalCheckVin,
  } = useCheckVin();

  const handleLoadVin = async (values: Form) => {
    try {
      const vin = formatVinToAPI(values.vin);
      const { inspectionReport, branch, ...data } = values;
      const url =
        (inspectionReport as Form['inspectionReport'] | null)?.absoluteUrl ||
        '';
      if (!branch) {
        throw new Error('No branch id');
      }

      if (pdfInspectionStatus === PdfJobStatus.TIMED_OUT && vinValue) {
        checkVin({
          branchId: branch,
          vin: formatVinToAPI(vin),
          onVinFound: () => {
            pdfSubmitVin({
              variables: {
                ...data,
                branch,
                vin,
              },
            });
            formMethods.reset(values);
          },
          onError: (error) => {
            setServerError(error.message);
          },
        });
        return;
      }
      if (url) {
        const pdfUrl = getPdfFilename(url);
        if (!pdfUrl || !branch) {
          throw new Error('pdfUrl is empty');
        }
        submitPdfToParser({
          variables: {
            ...data,
            branch,
            pdfUrl,
            vin,
          },
        });
        return;
      }

      checkVin({
        branchId: branch,
        vin: formatVinToAPI(vin),
        onVinFound: () => {
          pdfSubmitVin({
            variables: {
              ...data,
              branch,
              vin,
            },
          });
          formMethods.reset(values);
        },
        onError: (error) => {
          setServerError(error.message);
        },
      });
      return;
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return;
    }
  };

  const [isUploadingPdf, setIsUploadingPdf] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const updatePdf = usePdfParserUploader();
  const [originalFilename, setOriginalFilname] = React.useState('');

  const handlePdfChange = async (_: string, [file]: File[]) => {
    if (!file) return;

    try {
      setIsUploadingPdf(true);
      const pdfUrl = await updatePdf(file);
      setIsUploadingPdf(false);
      setValue('inspectionReport', { absoluteUrl: pdfUrl });
      setOriginalFilname(file.name);
    } catch (error) {
      apm.captureError(error as Error);
      setValue('inspectionReport', null);
      setIsUploadingPdf(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handlePdfRemove = () => {
    setValue('inspectionReport', null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setOriginalFilname('');
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <AllInspectionsLink />
        <Card
          paddingBottom={34}
          paddingTop={34}
          externalStyle={styles.carInformationCard}
        >
          <Grid item className={styles.contentContainer}>
            <Typography
              tag="h2"
              data-qa-id={`${qaIdPrefix}-header`}
              variant="titleXL"
              additonalClassNames={styles.pageHeader}
            >
              {translations.CAR_INFO}
            </Typography>
            <Grid item className={styles.fieldWrapper}>
              {isMobileEvaluatorApp() ? (
                <BranchSelectionPaginated
                  additionalClassName={
                    isSubmitting || pdfInspectionLoading
                      ? styles.disabledElement
                      : undefined
                  }
                  isDisabled={isSubmitting || pdfInspectionLoading}
                />
              ) : (
                  <BranchSelection setHasUserBranches={setHasUserBranches} />
                )}
            </Grid>
            {hasUserBranches && (
              <>
                <Grid item className={styles.fieldWrapper}>
                  <InputLabel>
                    <Typography
                      additonalClassNames={styles.labelTitle}
                      data-qa-id={`${qaIdPrefix}-label`}
                    >
                      {translations.PDF_INSPECTION_REPORT}{' '}
                      <span className={styles.optionalLabel}>
                        {translations.OPTIONAL}
                      </span>
                    </Typography>
                    <Typography
                      additonalClassNames={styles.labelSubtitle}
                      data-qa-id={`${qaIdPrefix}-label-subtitle`}
                    >
                      {translations.PDF_INSPECTION_REPORT_HINT}
                    </Typography>
                  </InputLabel>
                  <FormUploader
                    name="inspectionReport"
                    extraClassName={cn(styles.uploaderStyles, {
                      [styles.hiddenField]: !!pdfFileName || isUploadingPdf,
                    })}
                    allowMultipleFiles={false}
                    qaIdPrefix={`${qaIdPrefix}-upload-inspection-pdf`}
                    inputQaId={`${qaIdPrefix}-upload-inspection-pdf-input`}
                    uploadFileTypes="application/pdf"
                    includeCarousel={false}
                    onSelectFile={handlePdfChange}
                    inputRef={fileInputRef}
                    defaultValue={null}
                  >
                    {({ handleUploadClick }) => (
                      <>
                        <div className={styles.uploadCloudIcon} />
                        <Typography>{translations.DROP_PDF_HERE}</Typography>
                        <Button
                          qaId={`${qaIdPrefix}-browse-button`}
                          extraClass={styles.uploadButtonText}
                          theme={ButtonThemes.link}
                          onClick={(event) => {
                            event.preventDefault();
                            handleUploadClick();
                          }}
                        >
                          {translations.BROWSE}
                        </Button>
                      </>
                    )}
                  </FormUploader>
                  {(isUploadingPdf || pdfFileName) && (
                    <PdfFile
                      name={originalFilename}
                      url={pdfFile?.absoluteUrl}
                      linkAdditonalClassname={styles.disabledElement}
                      removeAdditionalClassname={
                        pdfInspectionLoading ? styles.disabledElement : undefined
                      }
                      isUploadingPdf={isUploadingPdf}
                      onRemove={handlePdfRemove}
                      qaIdPrefix={`${qaIdPrefix}-inspection-pdf-file`}
                    />
                  )}
                  {pdfFileName && (
                    <Typography
                      additonalClassNames={styles.vinDisclaimer}
                      data-qa-id={`${qaIdPrefix}-vin-optional-disclaimer`}
                    >
                      {translations.VIN_IS_OPTIONAL_DISCLAIMER}
                    </Typography>
                  )}
                </Grid>
                <CarVinInfo
                  qaIdPrefix={qaIdPrefix}
                  isSubmitting={isSubmitting || pdfInspectionLoading || loadingCheckVin}
                  isButtonDisabled={
                    isSubmitting || isUploadingPdf || pdfInspectionLoading || loadingCheckVin
                  }
                  serverError={serverError}
                  handleLoadVin={handleSubmit(handleLoadVin)}
                  flow="pdf-inspection"
                >
                  {!vinValue &&
                    pdfInspectionStatus === PdfJobStatus.TIMED_OUT && (
                      <AlertBar
                        message={translations.PDF_INSPECTION_PARSING_TIMEOUT}
                        type="error"
                        additionalClassNames={styles.alertBar}
                      />
                    )}
                </CarVinInfo>
              </>
            )}
          </Grid>
        </Card>
      </FormProvider>
      {modalCheckVin}
    </>
  );
};

const PdfInspectionInfoWithTransaction = withTransaction(
  'PdfInspectionInfo',
  'component',
)(PdfInspectionInfo);

export { PdfInspectionInfoWithTransaction as PdfInspectionInfo };
