import { authManager } from '../authManager';
import { GOOGLE_USER_ACCESS_KEY } from '../constants';

type Viewer = {
  userEmail: string;
  userName: string;
  userImage: string;
  userLocale: string;
  refreshToken: string;
  __typename: 'Viewer';
};

const viewerResolver = async () => {
  const viewer = await authManager(GOOGLE_USER_ACCESS_KEY).getViewer();

  if (!viewer) {
    return null;
  }

  return {
    userEmail: viewer.email,
    userName: viewer.name,
    userImage: viewer.picture,
    userLocale: viewer.locale,
    refreshToken: viewer.refresh_token,
    __typename: 'Viewer',
  };
};

export { viewerResolver };
export type { Viewer };
