import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = {
  paddingTop?: number;
  externalStyle?: string;
  paddingBottom?: number;
  showBottomBorder?: boolean;
  qaIdPrefix?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Card: React.FC<Props> = ({
  children,
  externalStyle,
  paddingTop,
  paddingBottom,
  showBottomBorder = false,
  qaIdPrefix = 'card',
  ...props
}) => {
  return (
    <div
      className={cn(
        styles.card,
        {
          [styles.borderBottom]: showBottomBorder,
        },
        externalStyle,
      )}
      data-qa-id={`${qaIdPrefix}-container`}
      style={{ paddingTop, paddingBottom }}
      {...props}
    >
      {children}
    </div>
  );
};

export { Card };
