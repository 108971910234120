import * as React from 'react';
import { appRoutes } from 'appRoutes';
import { differenceInSeconds } from 'date-fns';
import pick from 'lodash/pick';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import type { ValidationError } from 'yup';
import {
  CarStatusDataDocument,
  useMsiPdfSubmitVinMutation,
  useMsiSubmitVinMutation,
  useSubmitLicensePlateMutation,
  useResolveLicensePlateLazyQuery,
} from 'gql/graphql';
import type {
  MsiSubmitVinMutation,
  MsiPdfSubmitVinMutation,
} from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Card } from 'shared/components/Card';
import { useInspection } from 'shared/components/InspectionContext';
import { Typography } from 'shared/components/Typography';
import { formatVinToUI, formatVinToAPI } from 'shared/utils/formatVin';
import { useCheckVin } from 'shared/hooks/useCheckVin';
import { useTrackingEvents } from './hooks/useTrackingEvents';
import { VinSearch } from './VinSearch';
import { LicensePlateSearch } from './LicensePlateSearch';
import { CarInfoSchema } from './car-info-schema';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
  inspectionId: number | null;
  branchId: number | null;
  showManualFlow: boolean;
  licensePlateCallEnabled?: boolean;
  vin?: string;
  setIsValidVinInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBeforeEcodeSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
};

const VININFO_FIELDS = ['vin', 'licensePlate', 'externalId'];

const VinInfo: React.FC<Props> = ({
  inspectionId,
  branchId,
  licensePlateCallEnabled = false,
  qaIdPrefix,
  showManualFlow,
  setIsValidVinInfo,
  vin,
  setIsBeforeEcodeSubmit,
}) => {
  const history = useHistory();
  const location = useLocation<{ vin?: string }>();
  const { translations, translate } = useTranslation();
  const { stockNumber, isPdfInspection } = useInspection();
  const [serverError, setServerError] = React.useState('');

  const {
    clearErrors,
    getValues,
    reset,
    setValue,
    setError,
    watch,
    formState: { dirtyFields },
  } = useFormContext();
  const vinValue = watch('vin', '');
  const licensePlateValue = watch('licensePlate', '');
  const externalIdValue = watch('externalId', '');

  const [renderPageStartTime] = React.useState(Date.now());

  const initialVin = React.useMemo(() => {
    const vinFromInoField = location?.state?.vin;
    return formatVinToUI(vin || vinFromInoField || '');
  }, [vin, location?.state?.vin]);

  const { trackClickManualFlow } = useTrackingEvents();

  const refetchVinInfo = (
    response:
      | MsiSubmitVinMutation['msiSubmitVin']
      | MsiPdfSubmitVinMutation['msiPdfSubmitVin'],
  ) => {
    const inspecId = response?.inspectionId;
    if (!inspectionId && branchId) {
      history.push(appRoutes.carDetails(`${inspecId}`, true));
    } else if (response?.vehicle?.model) {
      history.replace(appRoutes.carDetails(`${inspecId}`, true));
    } else {
      history.replace(appRoutes.manualFlow(`${inspecId}`, true));
    }
  };

  const sendTrackEvent = ({
    result,
    source,
  }: {
    result:
      | MsiSubmitVinMutation['msiSubmitVin']
      | MsiPdfSubmitVinMutation['msiPdfSubmitVin'];
    source: string;
  }) => {
    const hasVin = location?.state?.vin;
    const vinFromVehicle = result?.vehicle?.identifiers?.vin;
    const licensePlateFromVehicle = result?.vehicle?.identifiers?.licensePlate;
    const stockNumberFromResponse = result?.car?.stockNumber;
    const inspectionIdFromResponse = result?.inspectionId;

    if (!hasVin && source === 'vin') {
      window.tracking.eva.loadVIN({
        page: 'carDetails',
        duration: differenceInSeconds(Date.now(), renderPageStartTime),
        inspectionId: inspectionIdFromResponse,
        stockNumber: stockNumberFromResponse,
        vin: vinFromVehicle,
        carTriggered: 'yes',
      });
    }
    window.tracking.eva.loadDataSuccess({
      source,
      inspectionId,
      stockNumber,
      vin: vinFromVehicle,
      licensePlate: licensePlateFromVehicle,
      duration: differenceInSeconds(Date.now(), renderPageStartTime),
    });
  };

  React.useEffect(() => {
    try {
      CarInfoSchema(translations).validateSync(
        { vin: vinValue, licensePlate: licensePlateValue },
        { abortEarly: false },
      );
      setIsValidVinInfo?.(true);
      clearErrors();
    } catch (validationErrors) {
      setIsValidVinInfo?.(false);
      (validationErrors as ValidationError).inner.forEach((error) => {
        if (!error.path) {
          return;
        }

        setError(error.path, {
          type: error.type ?? 'validation',
          message: error.message,
        });
      });
    }
  }, [
    vinValue,
    licensePlateValue,
    setIsValidVinInfo,
    setError,
    clearErrors,
    translations,
  ]);

  const useSubmitVinMutation = isPdfInspection
    ? useMsiPdfSubmitVinMutation
    : useMsiSubmitVinMutation;
  const [submitVin, { loading: isLoadingVin }] = useSubmitVinMutation({
    onCompleted: (res: MsiPdfSubmitVinMutation | MsiSubmitVinMutation) => {
      const result = isPdfInspection
        ? (res as MsiPdfSubmitVinMutation).msiPdfSubmitVin
        : (res as MsiSubmitVinMutation).msiSubmitVin;
      setServerError('');
      sendTrackEvent({ result, source: 'vin' });
      refetchVinInfo(result);
    },
    onError: (error) => {
      if (error.message === 'RERUN_LIMIT_EXHAUSTED') {
        setServerError(translations.CHANGE_VIN_ERROR_RERUN_LIMIT_EXHAUSTED);
      } else {
        const msg = error.message;
        const removeGraphql = msg.replace('GraphQL error: ', '');
        const val = removeGraphql.replace(/^_/g, '');
        setServerError(translate(val));
      }
    },
    refetchQueries: [CarStatusDataDocument],
  });
  const {
    checkVin,
    loading: loadingCheckVin,
    modal: modalCheckVin,
  } = useCheckVin();

  const [submitLicensePlate, { loading: isSubmittingLicensePlate }] =
    useSubmitLicensePlateMutation({
      onCompleted: (res) => {
        setServerError('');
        sendTrackEvent({
          result: res?.submitLicensePlate,
          source: 'licensePlate',
        });
        refetchVinInfo(res?.submitLicensePlate);
      },
      onError: (error) => {
        if (error.message === 'RERUN_LIMIT_EXHAUSTED') {
          setServerError(translations.CHANGE_VIN_ERROR_RERUN_LIMIT_EXHAUSTED);
        } else {
          setServerError(translations.LOAD_DATA_SERVER_ERROR);
        }
      },
      refetchQueries: [CarStatusDataDocument],
    });

  const [getCarFromLicensePlate, { loading: isResolvingLicensePlate }] =
    useResolveLicensePlateLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: async (result) => {
        setServerError('');
        const vin = result?.resolveLicensePlate;

        if (vin) {
          if (!branchId) {
            throw new Error('No branch id');
          }

          checkVin({
            branchId,
            vin: formatVinToAPI(vin),
            licensePlate: licensePlateValue ?? undefined,
            onBeforeRedirectToManualFlow: () => {
              reset(getValues());
            },
            onVinFound: () => {
              setValue('vin', formatVinToUI(vin));

              submitLicensePlate({
                variables: {
                  branch: branchId,
                  licensePlate: licensePlateValue,
                  inspectionId,
                  externalId: externalIdValue,
                },
              });
            },
            onError: (error) => {
              setServerError(error.message);
            },
          });
        } else {
          setError('licensePlate', {
            type: 'custom',
            message: translations.CAR_DETAILS_LICENSE_PLATE_FAIL_MESSAGE,
          });
        }
      },
      onError: () => {
        setError('licensePlate', {
          type: 'custom',
          message: translations.CAR_DETAILS_LICENSE_PLATE_FAIL_MESSAGE,
        });
      },
    });

  const trackLoadDataClick = ({
    source,
    licensePlate,
    vin,
  }: {
    source: string;
    licensePlate?: string | null;
    vin: string;
  }) => {
    window.tracking.eva.clickLoadData({
      source,
      inspectionId,
      stockNumber,
      vin: formatVinToAPI(vin),
      licensePlate,
      duration: differenceInSeconds(Date.now(), renderPageStartTime),
    });
  };

  const handleLoadDataClick = async () => {
    const formValues = getValues();
    const values = pick(formValues, ...VININFO_FIELDS);
    CarInfoSchema(translations)
      .validate(values)
      .then(async ({ vin, licensePlate }) => {
        if (!branchId) {
          throw new Error('No branch id');
        }

        const shouldUseLicensePlate =
          licensePlateCallEnabled && dirtyFields.licensePlate && licensePlate;

        setIsBeforeEcodeSubmit?.(true);
        if (shouldUseLicensePlate) {
          trackLoadDataClick({ source: 'licensePlate', licensePlate, vin });

          getCarFromLicensePlate({
            variables: {
              branch: branchId,
              licensePlate,
            },
          });
        } else {
          checkVin({
            branchId,
            vin: formatVinToAPI(vin),
            licensePlate: licensePlate ?? undefined,
            onBeforeRedirectToManualFlow: () => {
              reset({
                ...getValues(),
                ...values,
              });
            },
            onVinFound: () => {
              trackLoadDataClick({ source: 'vin', licensePlate, vin });
              submitVin({
                variables: {
                  ...values,
                  branch: branchId,
                  vin: formatVinToAPI(vin),
                  inspectionId,
                },
              });
            },
            onError: (error) => {
              setServerError(error.message);
            },
          });
        }
        reset({
          ...getValues(),
          ...values,
        });
      })
      .catch(({ type, message, path }) => {
        setError(path, { type, message });
      });
  };

  const handleEditDataClick = () => {
    trackClickManualFlow({
      page: 'carDetails',
      vin: formatVinToAPI(initialVin),
      inspectionId,
      stockNumber,
    });
    setServerError('');

    if (!inspectionId) {
      throw new Error('No inspection id');
    }

    history.push(appRoutes.manualFlow(`${inspectionId}`, true));
  };

  const handlePasteVinInput = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const sanitizedValue = event.clipboardData
      .getData('text')
      .replace(/\s+/g, '');
    setValue('vin', formatVinToUI(sanitizedValue));
  };

  return (
    <>
      <Card
        paddingTop={32}
        paddingBottom={licensePlateCallEnabled && !showManualFlow ? 0 : 32}
        id="carInfo"
        showBottomBorder={!licensePlateCallEnabled && !showManualFlow}
        qaIdPrefix={qaIdPrefix}
      >
        <div className={styles.title}>
          <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
            {translations.CAR_INFO}
          </Typography>
        </div>
        {licensePlateCallEnabled ? (
          <LicensePlateSearch
            qaIdPrefix={`${qaIdPrefix}-vin-search`}
            isSubmitting={
              isSubmittingLicensePlate ||
              isResolvingLicensePlate ||
              loadingCheckVin ||
              isLoadingVin
            }
            serverError={serverError}
            handleEditDataClick={handleEditDataClick}
            handlePasteVinInput={handlePasteVinInput}
            handleLoadDataClick={handleLoadDataClick}
          />
        ) : (
          <VinSearch
            branchId={branchId}
            qaIdPrefix={`${qaIdPrefix}-vin-search`}
            initialVin={initialVin}
            handlePasteVinInput={handlePasteVinInput}
            onFillManuallyClick={handleEditDataClick}
            isSubmitting={loadingCheckVin || isLoadingVin}
            serverError={serverError}
            handleLoadDataClick={handleLoadDataClick}
          />
        )}
      </Card>
      {modalCheckVin}
    </>
  );
};

export { VinInfo };
