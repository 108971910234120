import * as yup from 'yup';
import type { AccidentReasonOption, AccidentReason } from 'gql/graphql';
import type { Translations, Translate } from 'shared/utils/translations';

type NotNullReason = Omit<AccidentReasonOption, 'reason'> & {
  reason: AccidentReason;
};

const MAX_FILE_SIZE_MB = 10;

function getAccidentAreaSchema(
  accidentAreaOptions: AccidentReasonOption[],
  translations: Translations,
  t: Translate,
) {
  return yup.object({
    root: yup
      .object(
        accidentAreaOptions
          .filter(
            (accidentReason): accidentReason is NotNullReason =>
              !!accidentReason.reason,
          )
          .reduce((acc, { reason, minImageCount, maxImageCount }) => {
            let schema = yup.array().of(
              yup
                .mixed()
                .test(
                  'maxFilesize',
                  t('a1-inspectionApp-max-file-size', {
                    size: `${MAX_FILE_SIZE_MB} MB`,
                  }),
                  (value) => {
                    if (!(value instanceof File)) {
                      return true;
                    }

                    return value.size <= MAX_FILE_SIZE_MB * 1024 * 1024;
                  },
                ),
            );

            if (minImageCount) {
              schema = schema.min(
                minImageCount,
                t(translations.ACCIDENT_IMAGES_MIN_ERROR, {
                  count: `${minImageCount}`,
                }),
              );
            }
            if (maxImageCount) {
              schema = schema.max(
                maxImageCount,
                t(translations.ACCIDENT_IMAGES_MAX_ERROR, {
                  count: `${maxImageCount}`,
                }),
              );
            }

            return {
              ...acc,
              [reason]: schema.nullable().optional(),
            };
          }, {}),
      )
      .test('required', translations.CHOOSE_ONE_OPTION, (value) => {
        return Object.values(value).some((v) => !!v);
      }),
  });
}

export { getAccidentAreaSchema };
