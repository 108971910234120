import type { MutationResult } from '@apollo/client';
import { useMultipleMediaUploader } from 'shared/utils/useMultipleMediaUploader';
import {
  useUpdateCarQualityMutation,
  useUpdateCarQualityV2Mutation,
} from 'gql/graphql';
import type {
  HighlightInput,
  VehicleDamageArea,
  VehicleDamagePart,
  VehicleDamageType,
  VehicleMimeType,
  MetaPaintThicknessInput,
  VehiclePaintThicknessInput,
  UpdateCarQualityMutation,
  UpdateCarQualityV2Mutation,
} from 'gql/graphql';
import { mimeTypeToVehicleMimeType } from 'shared/utils/mimeType';
import type { ImageData, PaintThicknessVersion } from '../types';

type BuildMultipleImageMapper = (
  items: ReadonlyArray<{
    area: VehicleDamageArea | null;
    part: VehicleDamagePart | null;
    type?: VehicleDamageType | null;
    files: ReadonlyArray<ImageData | File>;
    isAccidentReason?: boolean;
  }>,
  imageUploader: ReturnType<typeof useMultipleMediaUploader>['uploadMedia'],
  stockNumber: string | null,
) => Promise<Array<HighlightInput>>;

type UpdateCarQuality = ({
  isPaintThicknessChecked,
  paintThickness,
  highlights,
  optionalSchema,
}: {
  isPaintThicknessChecked: boolean | null;
  paintThickness: MetaPaintThicknessInput | Array<VehiclePaintThicknessInput>;
  highlights?: Array<{
    area: VehicleDamageArea | null;
    part: VehicleDamagePart | null;
    files: Array<ImageData | File>;
  }>;
  optionalSchema: boolean;
}) => Promise<void>;

export const buildMultipleImageMapper: BuildMultipleImageMapper = async (
  items = [],
  imageUploader,
  stockNumber,
) => {
  const uploadedImages = await Promise.all(
    items.map(async (item) => {
      const previouslyUpload: Array<ImageData> = [];
      const imagesToUpload: Array<{
        part: string;
        source: 11;
        stockNumber: string;
        file: File;
      }> = [];
      Array.from(item.files ?? []).forEach((file) => {
        if (file instanceof File) {
          if (stockNumber === null) {
            throw new Error('stock number is null');
          }

          imagesToUpload.push({
            part: `${item.part}`,
            source: 11,
            stockNumber,
            file,
          });
        } else {
          previouslyUpload.push({
            ...file,
          });
        }
      });
      const uploadedImages = await imageUploader(imagesToUpload);
      const images = uploadedImages.map<{
        absoluteUrl: string;
        mimeType: VehicleMimeType;
        pointsOfInterest: never[];
      }>(
        ({
          data: {
            wkda: { url },
          },
          mimeType,
        }) => ({
          absoluteUrl: url,
          mimeType: mimeTypeToVehicleMimeType(mimeType) ?? 'JPEG',
          pointsOfInterest: [],
        }),
      );
      const { files, ...mutationValues } = item;
      return {
        ...mutationValues,
        images: [...previouslyUpload, ...images],
      };
    }),
  );

  return uploadedImages;
};

export function useCarQualityUpdater(
  inspectionId: number,
  stockNumber: string | null,
  paintThicknessVersion: PaintThicknessVersion,
  shouldUseNewAccidentModel: boolean,
) {
  const { uploadMedia: uploadHighlightsMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );

  const [mutateV1, mutateV1Result] = useUpdateCarQualityMutation();
  const [mutateV2, mutateV2Result] = useUpdateCarQualityV2Mutation();

  const updateCarQuality: UpdateCarQuality = async ({
    isPaintThicknessChecked,
    paintThickness,
    highlights,
    optionalSchema,
  }) => {
    const highlightsToUpload = await buildMultipleImageMapper(
      highlights ?? [],
      uploadHighlightsMedia,
      stockNumber,
    );

    if (paintThicknessVersion === 1) {
      await mutateV1({
        variables: {
          inspectionId,
          highlights: highlightsToUpload as Array<HighlightInput>,
          incomplete: optionalSchema,
          paintThickness: paintThickness as MetaPaintThicknessInput,
          skipHighlights: shouldUseNewAccidentModel,
        },
      });
    } else {
      await mutateV2({
        variables: {
          inspectionId: inspectionId as number,
          highlights: highlightsToUpload as Array<HighlightInput>,
          incomplete: optionalSchema,
          paintThickness: paintThickness as Array<VehiclePaintThicknessInput>,
          skipPaintThickness:
            isPaintThicknessChecked === null ||
            isPaintThicknessChecked === undefined,
          isPaintThicknessChecked: !!isPaintThicknessChecked,
          skipHighlights: shouldUseNewAccidentModel,
        },
      });
    }
  };

  return [
    updateCarQuality,
    paintThicknessVersion === 1 ? mutateV1Result : mutateV2Result,
  ] as [
      UpdateCarQuality,
      MutationResult<UpdateCarQualityMutation | UpdateCarQualityV2Mutation>,
    ];
}
