import * as React from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { Input } from '@auto1-ui/input';
import { useUserBranchesPaginatedQuery } from 'gql/graphql';
import type { UserBranchesPaginatedQuery } from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { InputLabel } from 'shared/components/InputLabel';
import { Typography } from 'shared/components/Typography';
import { FormDropdownPlaceholder } from 'shared/components/FormDropdown/Placeholder';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { useRegister } from 'shared/hooks/useRegister';
import { LoadingCircle } from 'shared/components/LoaderCircle';
import styles from './index.module.scss';
import { ListFooter } from './ListFooter';
import { ArrowIcon } from './ArrowIcon';

const PAGE_SIZE = 25;

type Branch = NonNullable<
  UserBranchesPaginatedQuery['userBranchesPaginated']
>['entities'][number];

type Props = {
  isDisabled?: boolean;
  additionalClassName?: string;
  pageSize?: number;
  setHasUserBranches?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBranch?: React.Dispatch<React.SetStateAction<Branch | undefined>>;
};

const BranchSelectionPaginated: React.FC<Props> = ({
  additionalClassName = '',
  pageSize = PAGE_SIZE,
  isDisabled = false,
  setHasUserBranches = () => { },
  setSelectedBranch = () => { },
}) => {
  const { translations } = useTranslation();
  const [currentBranch, setCurrentBranch] = React.useState<Branch>();
  const [inputBranchValue, setInputBranchValue] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const containerElement = React.useRef<HTMLDivElement>(null);
  const [isFirstResponse, setIsFirstResponse] = React.useState(true);
  const [queryVariables, setQueryVariables] = React.useState({
    pageSize,
    page: 0,
    name: null,
  });

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const currentBranchId = watch('branch');

  const {
    data: branchesData,
    loading,
    fetchMore,
  } = useUserBranchesPaginatedQuery({
    variables: queryVariables,
    onCompleted: (data) => {
      setValue('branch', data?.userMerchant?.defaultBranchId);
      setHasUserBranches(data?.userBranchesPaginated?.entities.length > 0);
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadNextPage = React.useCallback(() => {
    setIsFirstResponse(false);
    fetchMore({
      variables: {
        page: currentPage,
      },
    });
    setIsOpen(true);
  }, [currentPage, fetchMore]);

  const searchBranch = React.useCallback(
    debounce((searchQuery) => {
      setIsFirstResponse(false);
      setQueryVariables({
        pageSize,
        page: 0,
        name: searchQuery || null,
      });
      setCurrentPage(0);
    }, 300),
    [],
  );

  React.useEffect(() => {
    if (
      branchesData?.userBranchesPaginated?.page !== undefined &&
      branchesData?.userBranchesPaginated?.page !== null
    ) {
      setCurrentPage(branchesData.userBranchesPaginated.page + 1);
    }
  }, [branchesData]);

  React.useEffect(() => {
    if (!isFirstResponse) {
      setIsOpen(true);
    }
  }, [branchesData, isFirstResponse]);

  const [hasMorePages, setHasMorePages] = React.useState(false);
  React.useEffect(() => {
    setHasMorePages(
      (branchesData?.userBranchesPaginated?.totalPageCount ?? 1) - 1 >
      (branchesData?.userBranchesPaginated?.page ?? 0),
    );
  }, [branchesData, currentPage]);

  useClickOutside(containerElement, () => setIsOpen(false));
  useRegister('branch');

  React.useEffect(() => {
    const branch = branchesData?.userBranchesPaginated?.entities.find(
      ({ value }) => value === parseInt(currentBranchId, 10),
    );
    setSelectedBranch(branch);
    setCurrentBranch(branch);
  }, [currentBranchId, branchesData, setSelectedBranch]);

  if (loading && isFirstResponse) {
    return (
      <FormDropdownPlaceholder
        labelWidth="58px"
        qaIdPrefix="branch-dropdown-placeholder"
      />
    );
  }

  return (
    <div className={cn(styles.branchSelection, additionalClassName)}>
      <InputLabel additionalClassName={styles.label}>
        {translations.BRANCH}
      </InputLabel>
      <div className={styles.branchSelection} ref={containerElement}>
        <div onClick={() => setIsOpen(true)}>
          <Input
            placeholder={translations.PLEASE_SELECT}
            actionIcon={
              loading && currentPage === 0 ? (
                <LoadingCircle size={16} color="#358ccb" />
              ) : (
                  <span
                    className={cn(styles.arrowIcon, {
                      [styles.isOpen]: isOpen,
                    })}
                  >
                    <ArrowIcon />
                  </span>
                )
            }
            qaTag="branch"
            value={inputBranchValue}
            onChange={(evt) => {
              setInputBranchValue(evt.target.value);
              searchBranch.cancel();
              if (evt.target.value.length >= 3) {
                searchBranch(evt.target.value);
              } else {
                searchBranch('');
              }
            }}
            extraClass={cn({
              [styles.error]: !!errors.branch,
            })}
            isDisabled={isDisabled}
          />
        </div>
        {isOpen && !(loading && currentPage === 0) && (
          <div className={styles.suggestionsListWrapper}>
            <ul
              onClick={(evt) => {
                const liElement = evt.target as HTMLLIElement;

                setIsOpen(false);

                if (!liElement.dataset.id || !liElement.dataset.name) {
                  return;
                }

                setValue('branch', Number(liElement.dataset.id));
                setInputBranchValue(liElement.dataset.name);
              }}
            >
              {branchesData?.userBranchesPaginated?.entities.map((branch) => (
                <li
                  key={branch.value}
                  data-id={branch.value}
                  data-name={branch.label}
                  data-qa-id={`dropdown-qaId-list-list-item-${branch.value}`}
                >
                  {branch.value} - {branch.label}
                </li>
              ))}
              {(branchesData?.userBranchesPaginated?.entities.length ?? 0) ===
                0 && (
                  <li data-qa-id="dropdown-qaId-list-list-item-not-found">
                    {translations.BRANCH_NOT_FOUND}
                  </li>
                )}
            </ul>
            {hasMorePages && (
              <ListFooter
                loading={loading}
                currentPage={currentPage}
                loadNextPage={loadNextPage}
              />
            )}
          </div>
        )}
      </div>
      {errors.branch && (
        <Typography
          variant="textXSRed"
          additonalClassNames={styles.validationMessage}
          tag="div"
        >
          {errors.branch.message}
        </Typography>
      )}
      {currentBranch && (
        <div className={styles.branchLocationContainer}>
          <Typography additonalClassNames={styles.branchLocationTitle}>
            {translations.THIS_IS_YOUR_LOCATION_ADDRESS}
          </Typography>
          <div className={styles.addressContainer}>
            <address className={styles.address} data-qa-id="branch-address">
              {currentBranch.street}
              <br />
              {currentBranch.zipcode} - {currentBranch.city}{' '}
              {currentBranch.country}
            </address>
          </div>
        </div>
      )}
    </div>
  );
};

export { BranchSelectionPaginated };
