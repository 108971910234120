import * as React from 'react';
import { Input } from '@auto1-ui/input';
import { withFormItem } from '../withFormItem';

import styles from './index.module.scss';

type Props = {
  qaId?: string;
  className?: string;
} & Omit<React.ComponentProps<typeof Input>, 'qaTag' | 'extraClass'>;

const InputUI: React.FC<Props> = ({
  qaId = 'form-input',
  className,
  ...props
}) => {
  return <Input {...props} qaTag={qaId} extraClass={className} />;
};

const FormInput = withFormItem(InputUI, {
  classNames: {
    error: styles.error,
  },
});

export { FormInput };
