import * as React from 'react';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Checked } from '@auto1-ui/icons';
import styles from './index.module.scss';

const SuccessAlert: React.FC = () => {
  const { translations } = useTranslation();
  return (
    <div className={styles.alertWrapper}>
      <Checked fill="#1d915c" className={styles.icon} width="16" />
      <p>{translations.WAS_SUCESSFULLY_CREATED} </p>
    </div>
  );
};

export { SuccessAlert };
