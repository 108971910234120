import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'type'>;

const Checkbox: React.VFC<Props> = ({ className, ...props }) => {
  return (
    <input
      className={cn(styles.checkbox, className)}
      {...props}
      type="checkbox"
    />
  );
};

export { Checkbox };
