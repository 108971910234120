import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { ButtonThemes } from '@auto1-ui/button';
import { Carousel } from 'shared/components/Carousel';
import { ImagePreview } from 'shared/components/ImagePreview';
import { PdfPreview } from 'shared/components/PdfPreview';
import { Typography } from 'shared/components/Typography';

import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './styles.module.scss';

type FileData = { id?: string; absoluteUrl: string };
type FileValue = File | FileData;

type Props = {
  name: string;
};

const FormUploaderCarousel: React.FC<Props> = ({ name }) => {
  const { translate } = useTranslation();
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const arrError = Array.isArray(error) ? error : [];
  const files: FileValue[] = watch(name);
  const [selectedFileIndex, setSelectedFileIndex] =
    React.useState<number | null>(null);
  const closePreview = () => setSelectedFileIndex(null);

  const deleteSelectedFile = () => {
    if (selectedFileIndex === null) return;
    setValue(
      name,
      [
        ...files.slice(0, selectedFileIndex),
        ...files.slice(selectedFileIndex + 1),
      ],
      { shouldValidate: true, shouldDirty: true },
    );
    closePreview();
  };

  const renderPreview = (mediaFile: FileValue) => {
    const isPdf =
      (mediaFile instanceof File && mediaFile.type === 'application/pdf') ||
      ('mimeType' in mediaFile && mediaFile.mimeType === 'APPLICATION_PDF');
    const PreviewComponent = isPdf ? PdfPreview : ImagePreview;
    return (
      <PreviewComponent
        isOpen={true}
        source={mediaFile}
        handleClose={closePreview}
        buttons={[
          {
            label: translate('a1-inspectionApp-fileUploader-delete'),
            alignment: 'right',
            theme: ButtonThemes.outlined,
            onClick: deleteSelectedFile,
          },
        ]}
      />
    );
  };

  return files?.length ? (
    <>
      <Carousel
        extraContainerClass={styles.carousel}
        extraimageContainerClass={styles.extraimageContainerClass}
      >
        {files.map((formFile, index) => {
          if (
            (formFile instanceof File && formFile.type === 'application/pdf') ||
            ('mimeType' in formFile && formFile.mimeType === 'APPLICATION_PDF')
          ) {
            const key =
              formFile instanceof File
                ? kebabCase(formFile.name)
                : formFile.id ??
                  (formFile.absoluteUrl
                    ? kebabCase(formFile.absoluteUrl.split('/').at(-1))
                    : '');

            return (
              <div key={key}>
                <div
                  className={styles.pdfFile}
                  onClick={() => setSelectedFileIndex(index)}
                  data-qa-id={key}
                />
                {arrError[index]?.message ? (
                  <Typography
                    tag="div"
                    additonalClassNames={styles.message}
                    variant="textXSRed"
                  >
                    {arrError[index]?.message || ''}
                  </Typography>
                ) : null}
              </div>
            );
          }
          return (
            <div
              key={
                formFile instanceof File
                  ? formFile.name
                  : formFile.id ?? formFile.absoluteUrl
              }
              data-qa-id={`${kebabCase(name)}-carousel-item-${index}`}
            >
              <img
                src={
                  formFile instanceof File
                    ? URL.createObjectURL(formFile)
                    : formFile.absoluteUrl
                }
                alt="car-registration"
                onClick={() => setSelectedFileIndex(index)}
              />
              {arrError[index]?.message ? (
                <Typography
                  tag="div"
                  additonalClassNames={styles.message}
                  variant="textXSRed"
                >
                  {arrError[index]?.message || ''}
                </Typography>
              ) : null}
            </div>
          );
        })}
      </Carousel>
      {selectedFileIndex !== null && renderPreview(files[selectedFileIndex])}
    </>
  ) : null;
};

export { FormUploaderCarousel };
