import { useMemo, useCallback } from 'react';
import { useDamageOptionsQuery } from 'gql/graphql';
import type {
  DamageOptionsQuery,
  VehicleDamageArea,
  VehicleDamagePart,
} from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';

type UseDamageOptionsParams = {
  selectedArea: VehicleDamageArea | null;
  selectedPart?: VehicleDamagePart | null;
  onCompleted: () => void;
};

function getPartsFromArea(
  damageOptions: DamageOptionsQuery['damageOptions'],
  area: VehicleDamageArea | null,
): DamageOptionsQuery['damageOptions'][number]['carParts'] {
  if (area === null) {
    return [];
  }

  return (
    damageOptions.find(({ area: optionArea }) => optionArea === area)
      ?.carParts ?? []
  );
}

function getTypesFromPart(
  carParts: DamageOptionsQuery['damageOptions'][number]['carParts'],
  part: VehicleDamagePart | null,
): DamageOptionsQuery['damageOptions'][number]['carParts'][number]['damageTypes'] {
  if (part === null) {
    return [];
  }

  return carParts.find(({ value }) => value === part)?.damageTypes ?? [];
}

export function useDamageOptions({
  selectedArea,
  selectedPart = null,
  onCompleted,
}: UseDamageOptionsParams) {
  const { data, loading } = useDamageOptionsQuery({
    onCompleted,
    fetchPolicy: 'cache-first',
  });
  const { translate } = useTranslation();

  const availableAreas = data?.damageOptions || [];

  const translateDamage = useCallback(
    (key) => translate(`global.damages.${key}`),
    [translate],
  );

  const areas = useMemo(() => {
    if (!loading && Array.isArray(availableAreas)) {
      return availableAreas
        .map(({ area, areaTranslationKey }) => ({
          label: areaTranslationKey ? translate(areaTranslationKey) : area,
          value: area,
        }))
        .filter(({ value }) => value !== 'GENERAL');
    }
    return [];
  }, [availableAreas, loading, translate]);

  const parts = useMemo(
    () => getPartsFromArea(availableAreas, selectedArea),
    [availableAreas, selectedArea],
  );
  const partsOptions = useMemo(
    () =>
      parts.map(({ translationKey, value }) => ({
        label: translateDamage(translationKey),
        value,
      }))
        .filter(({ value }) => value !== 'GENERAL'),
    [parts],
  );

  const types = useMemo(
    () => getTypesFromPart(parts, selectedPart),
    [parts, selectedPart],
  );
  const typesOptions = useMemo(
    () =>
      types.map(({ translationKey, value }) => ({
        label: translateDamage(translationKey),
        value,
      }))
        .filter(({ value }) => value !== 'GENERAL'),
    [types],
  );

  return {
    areas,
    parts: partsOptions,
    types: typesOptions,
  };
}
