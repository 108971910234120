import { AuthManager } from '@pa/auth-manager';
import { AuthService } from '@pa/auth-service';
import { BaseApiService } from '@pa/base-api-service';
import { endpoints as authEndpoints } from './endpoints';
import * as urlsConfig from '../config/urls';

const authService = new AuthService(
  new BaseApiService({
    baseURL: urlsConfig.authBaseUrl,
    extraURLParams: urlsConfig.authUrlParams,
  }),
  authEndpoints,
);

const authManager = (userAccessKey: string) =>
  new AuthManager(authService, window.localStorage, userAccessKey);

export { authManager };
