import type { VehicleMimeType, VehicleFileMimeType } from 'gql/graphql';

function mimeTypeToVehicleMimeType(mimeType: string): VehicleMimeType | null {
  switch (mimeType) {
    case 'image/jpeg':
      return 'JPEG';
    case 'image/png':
      return 'PNG';
    case 'video/mp4':
      return 'MP4';
    default:
      return null;
  }
}

function mimeTypeToVehicleFileMimeType(
  mimeType: string,
): VehicleFileMimeType | null {
  switch (mimeType) {
    case 'image/jpeg':
      return 'IMAGE_JPEG';
    case 'image/png':
      return 'IMAGE_PNG';
    case 'video/mp4':
      return 'VIDEO_MP4';
    case 'application/pdf':
      return 'APPLICATION_PDF';
    default:
      return null;
  }
}

export { mimeTypeToVehicleMimeType, mimeTypeToVehicleFileMimeType };
