import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { withTransaction } from '@elastic/apm-rum-react';

import { Button, ButtonTypes } from '@auto1-ui/button';

import { useTiresBrakes } from 'pages/TiresBrakes/useTiresBrakes';
import { appRoutes } from 'appRoutes';
import navigationFooterStyles from 'shared/components/NavigationFooter/index.module.scss';
import { useScrollToSection } from 'shared/utils/useSectionScroll';
import Loader from 'shared/components/Loader';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { Card } from 'shared/components/Card';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import { Typography } from 'shared/components/Typography';
import { AlertBar } from 'shared/components/AlertBar';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { useSideMenuNavigationTracking } from 'shared/hooks/useSideMenuNavigationTracking';
import type { Translations } from 'shared/utils/translations';

import styles from './styles.module.scss';
import { WheelDetails } from './WheelDetails';
import { wheelPositionMapper } from './tires-brakes-data';
import { Placeholder } from './Placeholder';

type Props = {
  qaIdPrefix?: string;
};

const TiresBrakes: React.FC<Props> = ({
  qaIdPrefix = 'tires-and-brakes-page',
}) => {
  const location =
    useLocation<{
      prev: { pathname: string; hash: string };
      sideMenuNavigation?: boolean;
    }>();
  const { translations } = useTranslation();
  const { isPdfInspection, inspectionId, shouldUseNewAccidentModel } =
    useInspection();

  useScrollToSection(location.hash);
  useSideMenuNavigationTracking(
    location.state && location.state.prev,
    location,
  );

  const {
    loading,
    formMethods,
    handleSubmit: onSubmit,
    applyAllWheels,
    submitContext,
    onSubmitError,
    serverError,
  } = useTiresBrakes();

  const { scroll } = useScrollToSection(location.hash);
  React.useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (!loading) {
      timeoutId = setTimeout(scroll, 100);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading, scroll]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, errors },
  } = formMethods;

  const { fields: primaryWheels } = useFieldArray({
    control,
    name: 'primaryWheels',
  });

  const {
    fields: additionalWheels,
    remove: removeAdditionalWheel,
    append: appendAdditionalWheel,
  } = useFieldArray({
    control,
    name: 'additionalWheels',
  });

  if (loading) {
    return <Placeholder />;
  }

  return (
    <LayoutContainer>
      <FormProvider {...formMethods}>
        <Card
          paddingTop={32}
          paddingBottom={32}
          externalStyle={styles.card}
          data-qa-id={`${qaIdPrefix}-installed-wheels`}
          id="installed-wheels"
        >
          <>
            {isPdfInspection && (
              <AlertBar
                message={
                  Object.keys(errors).length > 0
                    ? translations.TIRES_BRAKES_PDF_VALIDATION_DISCLAIMER
                    : translations.TIRES_BRAKES_PDF_DISCLAIMER
                }
                type={Object.keys(errors).length > 0 ? 'error' : 'info'}
                additionalClassNames={styles.subtitle}
              />
            )}
            <Typography
              variant="titleXL"
              additonalClassNames={styles.title}
              data-qa-id={`${qaIdPrefix}-installed-wheels-title`}
            >
              {translations.INSTALLED_WHEELS}
            </Typography>

            {primaryWheels.map((field, index) => (
              <React.Fragment key={field.id}>
                <WheelDetails
                  qaIdPrefix={`primary-wheels-${index}`}
                  fieldName={`primaryWheels.${index}`}
                />
                {index === 0 && (
                  <Button
                    theme="outlined"
                    qaId={`${qaIdPrefix}-apply-to-primary-all-wheels`}
                    isActivated={false}
                    extraClass={styles.applyToAllButton}
                    onClick={() => applyAllWheels('primaryWheels')}
                  >
                    {translations.APPLY_ALL_WHEELS}
                  </Button>
                )}
              </React.Fragment>
            ))}
          </>
        </Card>
        <Card
          paddingTop={32}
          paddingBottom={32}
          data-qa-id={`${qaIdPrefix}-extra-wheels`}
          externalStyle={styles.card}
          id="extra-wheels"
        >
          {additionalWheels.length > 0 && (
            <Button
              theme="link"
              extraClass={styles.delete}
              onClick={() => {
                removeAdditionalWheel([0, 1, 2, 3]);
              }}
              qaId={`${qaIdPrefix}-remove-extra-wheels`}
            >
              {translations.DELETE}
            </Button>
          )}

          <Typography
            variant="titleXL"
            additonalClassNames={styles.title}
            data-qa-id={`${qaIdPrefix}-extra-wheels-title`}
          >
            {translations.EXTRA_WHEEL_TITLE}
          </Typography>

          {additionalWheels.length === 0 && (
            <Button
              theme="outlined"
              isActivated={false}
              onClick={() => {
                const areas = Object.keys(wheelPositionMapper) as Array<
                  keyof typeof wheelPositionMapper
                >;
                appendAdditionalWheel(
                  areas.map((area) => ({
                    area,
                    dotNumber: '',
                    isPrimary: false,
                    notAvailable: false,
                    profileDepth: '',
                    rimType: '',
                    tireType: '',
                  })),
                );
              }}
              qaId={`${qaIdPrefix}-add-extra-wheels`}
              extraClass={styles.btn}
            >
              + {translations.ADD_EXTRA_WHEELS}
            </Button>
          )}

          {additionalWheels.map((field, index) => (
            <React.Fragment key={field.id}>
              <WheelDetails
                qaIdPrefix={`additional-wheels-${index}`}
                fieldName={`additionalWheels.${index}`}
              />
              {index === 0 && (
                <Button
                  theme="outlined"
                  qaId={`${qaIdPrefix}-apply-to-all-additional-wheels`}
                  isActivated={false}
                  extraClass={styles.applyToAllButton}
                  onClick={() => applyAllWheels('additionalWheels')}
                >
                  {translations.APPLY_ALL_WHEELS}
                </Button>
              )}
            </React.Fragment>
          ))}
        </Card>
        <NavigationFooter
          backButtonLink={
            shouldUseNewAccidentModel
              ? appRoutes.accidents(`${inspectionId}`, true)
              : appRoutes.carQuality(`${inspectionId}`, true)
          }
          rightAreaContent={
            <>
              <Button
                type={ButtonTypes.button}
                theme="outlined"
                isActivated={false}
                qaId={`${qaIdPrefix}-footer-save-link`}
                extraClass={navigationFooterStyles.saveButton}
                onClick={() => {
                  submitContext.current = 'save';
                  handleSubmit(onSubmit, onSubmitError)();
                }}
                isDisabled={isSubmitting}
              >
                {translations.SAVE}
                {isSubmitting && submitContext.current === 'save' && (
                  <Loader
                    extraClass={navigationFooterStyles.saveButtonLoader}
                  />
                )}
              </Button>
              <Button
                type={ButtonTypes.button}
                theme="orange"
                extraClass={navigationFooterStyles.nextButton}
                qaId={`${qaIdPrefix}-footer-next-link`}
                isActivated={false}
                onClick={() => {
                  submitContext.current = 'submit';
                  handleSubmit(onSubmit, onSubmitError)();
                }}
                isDisabled={isSubmitting}
              >
                {translations.NEXT}
                {isSubmitting && submitContext.current === 'submit' && (
                  <Loader
                    extraClass={navigationFooterStyles.nextButtonLoader}
                  />
                )}
              </Button>
            </>
          }
          bottomAreaContent={
            serverError && (
              <AlertBar
                type="error"
                message={
                  serverError in translations
                    ? translations[serverError as keyof Translations]
                    : translations.GENERIC_ERROR_MESSAGE
                }
                additionalClassNames={navigationFooterStyles.alertBar}
              />
            )
          }
        />
        <RouteLeavingGuard when={isDirty} title={translations.UNSAVED_CHANGES}>
          <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
        </RouteLeavingGuard>
      </FormProvider>
    </LayoutContainer>
  );
};

const TiresBrakesWithTransaction = withTransaction(
  'TiresBrakes',
  'component',
)(TiresBrakes);

export { TiresBrakesWithTransaction as TiresBrakes };
