import * as React from 'react';
import { MobileEvaluatorsHeader } from 'shared/components/MobileEvaluatorsHeader';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';

type Props = {
  layout: React.ComponentType;
  child: React.ComponentType;
};

const WithLayout: React.FC<Props> = ({
  layout: LayoutComponent,
  child: ChildComponent,
}) => {
  return (
    <>
      {isMobileEvaluatorApp() && <MobileEvaluatorsHeader />}
      <LayoutComponent>
        <ChildComponent />
      </LayoutComponent>
    </>
  );
};

export { WithLayout };
