import * as React from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { appRoutes } from 'appRoutes';
import { usePiConvertInspectionToFullEvaMutation } from 'gql/graphql';
import type { InspectionsV2Query } from 'gql/graphql';
import type { ObjectKeys } from 'shared/utils/types';
import { filterImagesByArea } from 'shared/utils/filterImagesByArea';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Modal } from 'shared/components/Modal';
import { useInspection } from 'shared/components/InspectionContext';
import { PagesEnum } from 'shared/utils/route';
import { Typography } from 'shared/components/Typography';
import { useProgressCalculation } from 'shared/components/Sidebar/hooks/useProgressCalculation';
import styles from './index.module.scss';

type Inspection = Omit<
  NonNullable<InspectionsV2Query['inspectionsV2']>['inspections'][number],
  'status'
>;

type Props = {
  isOkButtonDisabled?: boolean;
  inspectionInfo?: Inspection;
  handleClose: () => void;
};

const ConvertToFullEvaModal: React.FC<Props> = ({
  isOkButtonDisabled = false,
  inspectionInfo = {} as Inspection,
  handleClose,
}) => {
  const history = useHistory();
  const { translations } = useTranslation();
  const containerDiv = React.useRef<HTMLDivElement>(null);

  useClickOutside(containerDiv, () => handleClose());

  const { car, vehicle, branch, lastChangedTime, inspectionId } =
    inspectionInfo;
  const stockNumber = car?.stockNumber;
  const evaluationUpdated = lastChangedTime
    ? format(new Date(lastChangedTime), 'dd/MM/yyyy')
    : '';
  const imgUrl = filterImagesByArea(vehicle?.images, 'FRONT_LEFT');

  const { percentages } = useInspection();
  useProgressCalculation(inspectionId);
  const progressSum = (
    Object.keys(PagesEnum) as ObjectKeys<typeof PagesEnum>
  ).reduce<number>((acc, id) => {
    return acc + (percentages[id] ?? 0);
  }, 0);
  const progress = Math.round(progressSum / Object.keys(PagesEnum).length) || 0;

  const [piConvertInspectionToFullEva] =
    usePiConvertInspectionToFullEvaMutation({
      errorPolicy: 'none',
      onCompleted: (data) => {
        const inspectionId = data?.piConvertInspectionToFullEva?.inspectionId;

        if (inspectionId) {
          history.push(appRoutes.carDetails(`${inspectionId}`, true));
        }
      },
    });

  const handleConvertToFullEvaluation = () => {
    if (!stockNumber) {
      throw new Error('stock number is not defined');
    }
    piConvertInspectionToFullEva({
      variables: {
        stockNumber,
      },
    });
  };

  return (
    <Modal
      isOpen
      className={styles.warningContainer}
      title={translations.WANT_CONVERT_FULL_EVA}
      okLabel={translations.CONVERT_FULL_EVA}
      onOk={handleConvertToFullEvaluation}
      okButtonExtraClass={styles.okButton}
      showCancelButton={false}
      onClose={handleClose}
      isOkButtonDisabled={isOkButtonDisabled}
      qaIdPrefix="car-information-warning-modal"
      ref={containerDiv}
    >
      <div className={styles.info}>
        <div className={styles.imageContainer}>
          {imgUrl && <img src={imgUrl} alt="warning-modal-car" />}
        </div>
        <div
          className={styles.contentContainer}
          data-qa-id="car-information-warning-content-container"
        >
          <Typography
            additonalClassNames={styles.title}
            tag="div"
            data-qa-id="car-information-warning-title"
          >
            {car?.stockNumber} - {vehicle?.model?.make} {vehicle?.model?.model}{' '}
            {vehicle?.model?.subModel}
          </Typography>
          <Typography
            variant="textXS"
            tag="div"
            data-qa-id="car-information-warning-vin"
          >
            {vehicle?.identifiers?.vin ?? ''}
          </Typography>
          <Typography
            variant="textXS"
            tag="div"
            data-qa-id="car-information-warning-branch-name"
          >
            {branch?.name ?? ''}
          </Typography>
          <Typography
            variant="textXS"
            tag="div"
            data-qa-id="car-information-warning-inspection-date"
          >
            {translations.EVALUATION_UPDATED}: {evaluationUpdated}
          </Typography>
        </div>
        <div
          data-qa-id="car-information-warning-percentage"
          className={styles.percentageLabel}
        >
          {progress}%
        </div>
      </div>
    </Modal>
  );
};

export { ConvertToFullEvaModal };
