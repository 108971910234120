import * as React from 'react';
import { FormToggleButtonPlaceholder } from 'shared/components/FormToggleButton/Placeholder';
import styles from './index.module.scss';

const ThicknessItemPlaceholder: React.FC = () => (
  <div className={styles.container}>
    <FormToggleButtonPlaceholder labelWidth="179px" width="218px" />
  </div>
);

export { ThicknessItemPlaceholder };
