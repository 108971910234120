import * as React from 'react';
import cls from 'classnames';
import type { AutoPriceStatus } from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { formatLocaleNumber } from 'shared/utils/formatLocaleNumber';
import Loader from 'shared/components/Loader';
import styles from './index.module.scss';

type Props = {
  status: AutoPriceStatus;
  priceInMinorUnits?: number | null;
};

const AutoPrice: React.FC<Props> = ({ status, priceInMinorUnits = null }) => {
  const { translations } = useTranslation();

  switch (status) {
    case 'RUNNING':
      return (
        <div data-qa-id="autoPriceStatus" className={styles.autoPrice}>
          <Loader extraClass={styles.autoPriceLoader} />
          {translations.AUTOPRICE_MODAL_LOADING}
        </div>
      );
    case 'FINISHED':
      if (priceInMinorUnits === null) {
        return (
          <div
            data-qa-id="autoPriceStatus"
            className={cls(
              styles.autoPrice,
              styles.autoPriceIcon,
              styles.autoPriceNote,
            )}
          >
            {translations.AUTOPRICE_MODAL_NOTE}
          </div>
        );
      }

      return (
        <div
          data-qa-id="autoPriceStatus"
          className={cls(styles.autoPrice)}
        >
          <div>
            <p className={styles.autoPriceSuggestion}>
              {translations.AUTOPRICE_MODAL_OUR_SUGGESTION}:&nbsp;
              <span>
                {formatLocaleNumber(Math.round(priceInMinorUnits / 100))}€
              </span>
            </p>
            <p className={styles.autoPriceMarketPrice}>
              {translations.AUTOPRICE_MODAL_MARKET_PRICE}
            </p>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export { AutoPrice };
