import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { apm } from '@elastic/apm-rum';
import { useCarDetailsInitialDataQuery } from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useScrollToSection } from 'shared/utils/useSectionScroll';
import { RoutesEnum } from 'shared/utils/route';
import { scrollToError } from 'shared/utils/scrollToError';
import { useInspection } from 'shared/components/InspectionContext';
import { appRoutes } from 'appRoutes';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import {
  mapDataToFormValues,
  mapFormDataToMutation,
  isElectricVehicleWithData,
} from './helpers';
import { useCarDetailsUpdater } from './hooks/useCarDetailsUpdater';
import { useProgress } from './hooks/useProgress';
import { CarDetailsSchema } from './car-details.schema';
import type { Form } from './types';

const useCarDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const { removeStepFromNotCompleted, inspectionId, isPdfInspection } =
    useInspection();
  const { translations } = useTranslation();
  const submitContext = React.useRef<'save' | 'submit' | null>(null);
  const formMethods = useForm<Form>({
    context: submitContext,
    resolver: (data, context, options) => {
      const isElectricCarSectionOptional =
        isPdfInspection && !isElectricVehicleWithData(data?.electricCar);
      const optionalSchema = context?.current === 'save';

      return yupResolver(
        CarDetailsSchema(
          translations,
          optionalSchema,
          isElectricCarSectionOptional,
        ),
      )(data, context, options);
    },
  });

  const { reset } = formMethods;

  const { submitTracking } = useSubmitTracking('carDetails');

  const {
    data,
    refetch: refetchCarDetailsInitialData,
    ...queryProps
  } = useCarDetailsInitialDataQuery({
    variables: { inspectionId },
  });

  React.useEffect(() => {
    if (data === undefined) {
      return;
    }

    reset(mapDataToFormValues(data?.inspection));
  }, [data, reset]);

  const [submitVehicle, { error: mutationError }] = useCarDetailsUpdater(
    data?.inspection?.car?.stockNumber,
  );
  const [uploadError, setUploadError] = React.useState('');
  const serverError = mutationError?.graphQLErrors?.[0]?.message ?? uploadError;

  const redirectToTestDrive = () => {
    return history.push(appRoutes.testDrive(`${inspectionId}`, true));
  };

  const setProgress = useProgress();
  const onSubmitError = () => {
    const formValues = formMethods.getValues();
    scrollToError();
    setProgress({
      vin: formValues.vin,
      showManualFlow: false,
      formValues,
    });
  };

  const handleSubmit = async (values: Form) => {
    const optionalSchema = submitContext.current === 'save';
    const shouldRedirect = submitContext.current === 'submit';

    try {
      await submitVehicle({
        inspectionId,
        ...mapFormDataToMutation(values, isPdfInspection),
        incomplete: optionalSchema,
      });
      reset(values);
      setProgress({
        vin: values.vin,
        showManualFlow: false,
        formValues: formMethods.getValues(),
      });
      removeStepFromNotCompleted(RoutesEnum.CAR_DETAILS);

      if (shouldRedirect) {
        redirectToTestDrive();
        submitTracking();
      }
    } catch (e) {
      apm.captureError(e as Error);
      setUploadError((e as Error).message);
    }
  };

  const onRefetch = () => {
    refetchCarDetailsInitialData();
    history.replace(
      appRoutes.carDetails(`${inspectionId}#additionalInfo`, true),
    );
  };

  useScrollToSection(location.hash);

  return {
    data,
    translations,
    formMethods,
    onSubmitError,
    submitContext,
    handleSubmit,
    refetch: onRefetch,
    serverError,
    ...queryProps,
  };
};

export { useCarDetails };
