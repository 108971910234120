import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormDropdown } from 'shared/components/FormDropdown';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import { FormTextarea } from 'shared/components/FormTextarea';
import { FormCheckbox } from 'shared/components/FormCheckbox';

import { Card } from 'shared/components/Card';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Typography } from 'shared/components/Typography';
import type { Translations, Translate } from 'shared/utils/translations';
import {
  Colors,
  Upholstery,
  ToggleOption,
} from 'pages/CarStatus/car-status-data';
import { isFullyElectricCar } from 'shared/constants';
import type {
  VehicleInteriorUpholsteryType,
  VehicleInteriorColorType,
} from '../types'
import styles from '../index.module.scss';

const generateOptions = (max: number) =>
  Array(max)
    .fill(null)
    .map((_, index) => ({
      value: index + 1,
      label: (index + 1).toString(),
    }));

const colorOptions = (enumMap: { [key in VehicleInteriorColorType]: string }, translate: Translate) =>
  Object.keys(enumMap).map((key) => ({
    value: key,
    label: translate(`global.car_details.outside_colour.${enumMap[key as VehicleInteriorColorType]}`)
  }));

const enumToOptions = (enumMap: { [key in VehicleInteriorUpholsteryType]: string }) =>
  Object.keys(enumMap).map((key) => ({
    value: key,
    label: enumMap[key as VehicleInteriorUpholsteryType],
  }));

const booleanOptions = (translations: Translations) => [
  {
    value: true,
    label: translations.YES,
  },
  {
    value: false,
    label: translations.NO,
  },
];

const getFieldName = (name: string) => `otherInformation.${name}`;

type Props = {
  qaIdPrefix?: string;
};

const OtherInformation: React.FC<Props> = ({ qaIdPrefix = 'car-status-other-information' }) => {
  const { translations, translate } = useTranslation();
  const { watch, register, unregister } = useFormContext();

  const hasTuning = watch(getFieldName('hasTuning'));
  const hasLpg = watch(getFieldName('hasLpg'));

  React.useEffect(() => {
    register('fuelType');

    return () => {
      unregister('fuelType');
    };
  }, [register, unregister]);

  const fuelType = watch('fuelType');

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      id="other-information"
      qaIdPrefix={`${qaIdPrefix}-container`}
    >
      <Typography
        tag="div"
        data-qa-id={`${qaIdPrefix}-title`}
        additonalClassNames={styles.title}
        variant="titleXL"
      >
        {translations.OTHER_INFORMATION}
      </Typography>
      {/* FIRST SECTION */}
      <div
        className={styles.carStatusContainer}
        data-qa-id={`${qaIdPrefix}-fields-container`}
      >
        <div
          className={styles.carStatusColumn}
          data-qa-id={`${qaIdPrefix}-fields-column-left`}
        >
          {/* NUMBER OF SEATS */}
          <div
            className={styles.formElementContainer}
            data-qa-id={`${qaIdPrefix}-fields-number-of-seats-container`}
          >
            <FormDropdown
              id="number-of-seats"
              label={translations.SEATS_NUMBER}
              name={getFieldName('seatCount')}
              placeholder={translations.PLEASE_SELECT}
              qaIdPrefix={`${qaIdPrefix}-number-of-seats`}
              options={generateOptions(20)}
              containerClassName={styles.inputContainerFullWidth}
              defaultValue={null}
            />
          </div>
          {/* EXTERIOR COLOR */}
          <div
            className={styles.formElementContainerWithCheckBox}
            data-qa-id={`${qaIdPrefix}-fields-exterior-color-container`}
          >
            <FormDropdown
              id="exterior-color"
              label={translations.EXTERIOR_COLOR}
              name={getFieldName('exteriorColor')}
              placeholder={translations.PLEASE_SELECT}
              qaIdPrefix={`${qaIdPrefix}-exterior-color`}
              options={colorOptions(Colors, translate)}
              containerClassName={styles.inputContainerFullWidth}
              defaultValue={null}
            />
          </div>

          <FormCheckbox
            uiLabel={translations.METALLIC}
            name={getFieldName('isMetallic')}
            qaIdPrefix={`${qaIdPrefix}-isMetallic`}
            containerClassName={styles.checkBoxLabel}
            defaultValue={false}
          />

          <FormCheckbox
            uiLabel={translations.ORIGINAL_COLOR}
            name={getFieldName('isOriginalColor')}
            qaIdPrefix={`${qaIdPrefix}-originalColor`}
            containerClassName={styles.checkBoxLabel}
            defaultValue={false}
          />
        </div>

        <div
          className={styles.carStatusColumn}
          data-qa-id={`${qaIdPrefix}-fields-column-right`}
        >
          {/* KEYS AMOUNT */}
          <div
            className={styles.formElementContainer}
            data-qa-id={`${qaIdPrefix}-fields-keys-amount-container`}
          >
            <FormDropdown
              id="keys-amount"
              label={translations.KEYS_AMOUNT}
              name={getFieldName('keyCount')}
              placeholder={translations.PLEASE_SELECT}
              qaIdPrefix={`${qaIdPrefix}-keys-amount-dropdown`}
              options={generateOptions(5)}
              containerClassName={styles.inputContainerFullWidth}
              defaultValue={null}
            />
          </div>
          {/* MATERIAL */}
          <div
            className={styles.formElementContainerWithCheckBox}
            data-qa-id={`${qaIdPrefix}-fields-upholstery-material-container`}
          >
            <FormDropdown
              id="upholstery-material"
              label={translations.MATERIAL}
              name={getFieldName('upholstery')}
              placeholder={translations.PLEASE_SELECT}
              qaIdPrefix={`${qaIdPrefix}-upholstery-material-dropdown`}
              options={enumToOptions(Upholstery())}
              containerClassName={styles.inputContainerFullWidth}
              defaultValue={null}
            />
          </div>
          <FormCheckbox
            name={getFieldName('isUpholsteryOriginal')}
            uiLabel={translations.ORIGINAL_UPHOLSTERY}
            qaIdPrefix={`${qaIdPrefix}-original-upholstery`}
            containerClassName={styles.checkBoxLabel}
            defaultValue={false}
          />
        </div>
      </div>
      {/* // TODO: REMOVE IT WHEN UPLOADER IS READY */}

      {/* TUNING NOTES */}
      <div
        className={styles.formElementContainer}
        data-qa-id={`${qaIdPrefix}-fields-tunning-toggle-container`}
      >
        <FormToggleButton
          label={translations.TUNING}
          name={getFieldName('hasTuning')}
          className={styles.toggleButton}
          qaIdPrefix={`${qaIdPrefix}-tunning-toggle`}
          options={booleanOptions(translations)}
          defaultValue={ToggleOption.UNSELECTED}
        />
      </div>
      {hasTuning === true && (
        <div
          className={styles.formElementContainer}
          data-qa-id={`${qaIdPrefix}-fields-tunning-note-container`}
        >
          <FormTextarea
            rows={5}
            label={translations.TUNING_NOTE}
            qaIdPrefix={`${qaIdPrefix}-tuning-notes`}
            name={getFieldName('tuningNote')}
            defaultValue=""
            containerClassName={styles.inputContainerFullWidth}
          />
        </div>
      )}

      {/* LPG */}
      {!isFullyElectricCar(fuelType) ? (
        <>
          <div
            className={styles.formElementContainer}
            data-qa-id={`${qaIdPrefix}-fields-has-lpg-container`}
          >
            <FormToggleButton
              label={translations.LPG}
              name={getFieldName('hasLpg')}
              className={styles.toggleButton}
              qaIdPrefix={`${qaIdPrefix}-has-lpg`}
              options={booleanOptions(translations)}
              defaultValue={ToggleOption.UNSELECTED}
            />
          </div>
          {hasLpg === true && (
            <div
              className={styles.formElementContainer}
              data-qa-id={`${qaIdPrefix}-fields-lpg-note-container`}
            >
              <FormTextarea
                label={translations.LPG_NOTE}
                rows={5}
                qaIdPrefix={`${qaIdPrefix}-lpg-note`}
                name={getFieldName('lpgNote')}
                defaultValue=""
                containerClassName={styles.inputContainerFullWidth}
              />
            </div>
          )}
        </>)
        : null}
    </Card>
  );
};

export { OtherInformation };
