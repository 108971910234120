import * as React from 'react';
import type { VariationType } from '../types';

type Props = {
  variant: VariationType;
  qaIdPrefix?: string;
};

const Rear: React.FC<Props> = ({ variant, qaIdPrefix }) => (
  <>
    <path
      d="M338.288 671.805H294.614"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M363.991 738.234V720.462H355.568V700.653"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M338.288 671.805H355.568V700.662"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M340.437 632.495L340.641 630.74L329.976 544.06C310.343 548.652 282.867 551.515 252.449 551.515"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M294.614 671.805C311.289 669.13 325.833 665.39 336.95 662.221L340.428 632.495"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.364 671.814V671.805"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M333.378 759.031V785.808C333.378 792.539 338.834 798 345.577 798H351.801C358.535 798 364 792.547 364 785.808V738.234"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M333.378 759.03H252.424"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M330.701 691.903L325.168 706.574C322.653 713.245 316.26 717.659 309.124 717.659H252.39"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M330.701 691.903L338.288 671.805"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M330.701 691.903C328.459 697.842 333.523 700.662 337.282 700.662H355.56"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.381 651.86C287.513 651.298 317.726 646.22 340.436 632.495"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M333.378 759.03C338.22 763.009 340.121 765.769 346.566 766.886C350.641 767.593 355.705 768.47 358.177 765.923C360.027 764.014 358.791 761.876 359.183 752.453C359.362 748.219 359.465 746.055 360.189 744.036C361.025 741.71 362.406 739.801 363.992 738.225"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.372 684.304H282.73C286.489 684.304 289.533 687.345 289.533 691.102V696.427C289.533 700.185 286.489 703.226 282.73 703.226H252.389"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M294.614 671.805H270.88H252.364"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M164.291 632.495L167.769 662.221C178.886 665.39 193.43 669.13 210.105 671.805"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M164.291 632.495L164.086 630.74L174.751 544.06C194.385 548.652 221.861 551.515 252.278 551.515"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M166.44 671.805H149.159V700.662"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M140.736 738.234V720.462H149.159V700.653"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M171.358 759.03H252.304"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M166.439 671.805H210.113"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M140.736 738.234V785.808C140.736 792.539 146.192 798 152.936 798H159.159C165.894 798 171.358 792.547 171.358 785.808V759.031"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.364 671.805V671.814"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.304 759.03H252.424"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M166.439 671.805L179.56 706.574C182.074 713.245 188.468 717.658 195.604 717.658H252.338"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.33 717.659H252.398"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M174.027 691.903C176.269 697.842 171.205 700.662 167.445 700.662H149.167"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M164.291 632.495C187.002 646.22 217.215 651.298 252.347 651.86"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M171.359 759.03C166.516 763.009 164.615 765.769 158.17 766.886C154.095 767.593 149.032 768.47 146.559 765.923C144.709 764.014 145.945 761.876 145.553 752.453C145.374 748.219 145.272 746.055 144.547 744.036C143.712 741.71 142.331 739.801 140.745 738.225"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.355 684.304H221.997C218.238 684.304 215.194 687.345 215.194 691.102V696.427C215.194 700.185 218.238 703.226 221.997 703.226H252.338"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.389 703.226H252.347"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M252.355 684.304H252.372"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M210.113 671.805H233.847H252.364"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <g className={variant} data-qa-id={`${qaIdPrefix}-rear`}>
      <path
        d="M252.594 759.031H171.521V785.808C171.521 792.539 166.064 798 159.321 798H153.098C146.363 798 140.898 792.547 140.898 785.808V723.018C140.898 721.603 142.041 720.462 143.456 720.462H146.764C148.179 720.462 149.321 719.32 149.321 717.906V674.353C149.321 672.939 150.464 671.797 151.879 671.797H210.267C194.402 669.25 180.463 665.739 169.568 662.681C168.571 662.4 167.846 661.548 167.727 660.517L164.24 630.732L174.905 544.052C194.538 548.644 222.015 551.507 252.432 551.507C282.85 551.507 310.334 548.652 329.959 544.052L340.624 630.732L337.137 660.517C337.018 661.548 336.293 662.4 335.296 662.681C324.401 665.748 310.471 669.258 294.597 671.797H352.985C354.401 671.797 355.543 672.939 355.543 674.353V717.906C355.543 719.32 356.685 720.462 358.1 720.462H361.408C362.823 720.462 363.966 721.603 363.966 723.018V785.808C363.966 792.539 358.51 798 351.766 798H345.543C338.808 798 333.344 792.547 333.344 785.808V759.031H252.27H252.56H252.594Z"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
  </>
);

export { Rear };
