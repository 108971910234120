import * as React from 'react';

import { FormMaskedInput } from 'shared/components/FormMaskedInput';

const mask = [/[0-5]/, /[0-9]/, /[0-9]/, /[0-9]/];
const separators = {};

type Props = Omit<
  React.ComponentProps<typeof FormMaskedInput>,
  'mask' | 'separators'
>;

const FormDotInput = (props: Props): React.ReactElement => (
  <FormMaskedInput
    disabled={false}
    mask={mask}
    separators={separators}
    {...props}
  />
);

export { FormDotInput };
