import * as yup from 'yup';
import { isValid, parse, isBefore } from 'date-fns';
import { isElectricCar } from 'shared/constants';
import {
  requiredString,
  getRequiredMessage,
} from 'shared/utils/yupValidationHelpers';
import type { Translations } from 'shared/utils/translations';
import { VehicleEngineFuelType } from 'gql/graphql';

const nullableBool = () => yup.boolean().default(false).nullable();

const requiredBool = (
  optional: boolean,
  translations: Translations,
  type?: 'toggle' | 'file',
) => {
  if (optional) {
    return yup.bool().nullable();
  }
  return yup
    .bool()
    .test(
      'required',
      getRequiredMessage(translations, type),
      function required(value) {
        if (value === null) {
          return false;
        }
        return true;
      },
    )
    .nullable();
};

const requiredStringNullable = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  return requiredString(optionalSchema, translations).nullable();
};

const firstRegistrationDateValidation = (
  optionalSchema: boolean,
  translations: Translations,
  isBuildYearValue: boolean,
) => {
  const schema = optionalSchema
    ? yup.string().nullable()
    : yup.string().required(getRequiredMessage(translations));

  return schema
    .test(
      'is-after-build-date',
      translations.AFTER_BUILD_DATE,
      function isAfterBuildDate(value = '') {
        if ((optionalSchema && value === '') || !isBuildYearValue) {
          return true;
        }
        let buildYear = this.parent.builtDate;
        const date = value?.match(/\d{4}$/);

        if (!buildYear) {
          const buildDate = this.parent.buildDate.match(/^\d{4}/);
          if (!Array.isArray(buildDate)) {
            return false;
          }

          buildYear = buildDate[0];
        }

        if (!Array.isArray(date)) {
          return false;
        }
        return parseInt(date[0], 10) >= parseInt(buildYear, 10) - 1;
      },
    )
    .test('is-past', translations.DATE_NOT_IN_FUTURE, (value) => {
      if (!value) {
        return true;
      }
      return isBefore(parse(value, 'dd/MM/yyyy', Date.now()), Date.now());
    })
    .test('is-valid-date', translations.NOT_VALID_DATE, (value) => {
      if (!value) {
        return true;
      }
      return isValid(parse(value, 'dd/MM/yyyy', Date.now()));
    });
};

const electricCarSchema = (translations: Translations, optional: boolean) => {
  return yup.object().shape({
    batteryLeased: requiredBool(optional, translations, 'toggle'),
    batteryLeasingCost: yup
      .number()
      .nullable(true)
      .when(['batteryLeased', 'isBatteryLeasingCostNotAvailable'], {
        is: (
          batteryLeased: boolean | null,
          isBatteryLeasingCostNotAvailable: boolean | null,
        ) => {
          return batteryLeased && !isBatteryLeasingCostNotAvailable;
        },
        then: (schema) =>
          optional
            ? schema
            : schema.required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
      }),
    isBatteryLeasingCostNotAvailable: nullableBool(),
    isBatteryConditionReportAvailable: requiredBool(
      optional,
      translations,
      'toggle',
    ),
    batteryHealth: yup.mixed().when('isBatteryConditionReportAvailable', {
      is: true,
      then: requiredStringNullable(optional, translations),
    }),
    hasChargingCables: requiredBool(optional, translations, 'toggle'),
    isBatteryChargeLevelGuaranteed: yup
      .bool()
      .test(
        'required',
        translations.THIS_FIELD_CANNOT_BE_EMPTY,
        (value, context) => {
          // @ts-ignore
          const fuelType = context.from[1].value.fuelType;

          if (fuelType !== 'ELECTRIC' || optional) {
            return true;
          }

          return !!value;
        },
      ),
  });
};

const CarDetailsSchema = (
  translations: Translations,
  optional: boolean,
  isElectricCarSectionOptional: boolean,
) => {
  return yup.object({
    licensePlate: yup
      .string()
      .uppercase()
      .nullable()
      .max(45, translations.INVALID_LENGTH),
    countryOfOrigin: requiredStringNullable(optional, translations),
    countryOfRegistration: requiredStringNullable(optional, translations),
    totalOwnerCount: requiredStringNullable(optional, translations),
    inventoryType: requiredStringNullable(optional, translations),
    envClass: requiredStringNullable(optional, translations),
    co2EmissionAvailable: nullableBool(),
    commercialUseType: requiredStringNullable(optional, translations),
    firstRegistrationDate: yup.mixed().when('buildDate', {
      is: Boolean,
      then: firstRegistrationDateValidation(optional, translations, true),
      otherwise: firstRegistrationDateValidation(optional, translations, false),
    }),
    co2Emission: yup
      .number()
      .nullable(true)
      .when('co2EmissionAvailable', {
        is: false,
        then: (schema) =>
          optional
            ? schema
            : schema.required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
      }),
    vatType: requiredStringNullable(optional, translations),
    currentOwnerType: yup
      .string()
      .nullable()
      .when('vatType', {
        is: 'NOT_DEDUCTIBLE',
        then: requiredStringNullable(optional, translations),
      }),
    electricCar: yup.mixed().when('fuelType', {
      is: (fuelType: VehicleEngineFuelType | null) => isElectricCar(fuelType),
      then: electricCarSchema(
        translations,
        optional || isElectricCarSectionOptional,
      ),
    }),
  });
};

export { CarDetailsSchema };
