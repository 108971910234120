import * as React from 'react';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';
import { format, parse, isValid } from 'date-fns';
import type { Locale } from 'date-fns';
import cn from 'classnames';

import { DEFAULT_LOCALE } from 'language/i18n';
import { useTranslation } from 'shared/hooks/useTranslation';
import { LOCALES_DATEPICKER } from 'config/locales-datepicker';

import styles from './index.module.scss';
import { InputDate } from './InputDate';
import { DatePickerFormat } from './DatePickerFormat';
import type { DatePickerFormatType } from './DatePickerFormat';

type Props = {
  qaId?: string;
  value?: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  placeholder?: string;
  className?: string;
  dateType: DatePickerFormatType;
  datePickerProps?: Partial<ReactDatePickerProps>;
};

const DateFormatMap = Object.freeze({
  [DatePickerFormat.Day]: 'dd/MM/yyyy',
  [DatePickerFormat.Month]: 'MM/yyyy',
});

const DatePicker: React.FC<Props> = ({
  qaId = 'date-picker',
  value = '',
  onChange,
  isDisabled = false,
  placeholder = '',
  className = '',
  dateType = DatePickerFormat.Day,
  datePickerProps = {},
  ...props
}) => {
  const translation = useTranslation();
  const Container = React.useCallback(
    ({ className, children }) => (
      <CalendarContainer
        className={cn(className, styles.calendar, {
          [styles.withHeader]: dateType !== DatePickerFormat.Day,
        })}
      >
        <div data-qa-id={`${qaId}-calendar`}>{children}</div>
      </CalendarContainer>
    ),
    [qaId, dateType],
  );
  const dateFormat = DateFormatMap[dateType];

  const [locale, setLocale] = React.useState<Locale | null>(null);

  React.useEffect(() => {
    let mounted = true;
    const importLocaleFile = async () => {
      const locale = translation.locale ?? DEFAULT_LOCALE;
      const datePickerLocale =
        locale in LOCALES_DATEPICKER
          ? LOCALES_DATEPICKER[locale as keyof typeof LOCALES_DATEPICKER]
          : DEFAULT_LOCALE;
      const localeToSet = await import(
        `date-fns/locale/${datePickerLocale}/index.js`
      );

      if (mounted) {
        setLocale(localeToSet);
      }
    };
    importLocaleFile();

    return () => {
      mounted = false;
    };
  }, [translation.locale]);

  const parsedValue = parse(value, dateFormat, new Date());

  return (
    <div data-qa-id={qaId} {...props}>
      <ReactDatePicker
        calendarClassName="eva-datepicker"
        dateFormat={dateFormat}
        selected={isValid(parsedValue) ? parsedValue : null}
        onChange={(date) => {
          if (isValid(date) && date instanceof Date) {
            onChange(format(date, dateFormat));
          }
        }}
        customInput={<InputDate qaId={`${qaId}-input`} dateType={dateType} />}
        disabled={isDisabled || locale === null}
        placeholderText={placeholder}
        calendarContainer={Container}
        showMonthDropdown
        showYearDropdown
        popperPlacement="bottom-start"
        showMonthYearPicker={dateType === DatePickerFormat.Month}
        locale={locale ?? ''}
        className={cn(styles.input, className)}
        maxDate={new Date('9999-12-31')}
        {...datePickerProps}
      />
    </div>
  );
};

export { DatePicker };
