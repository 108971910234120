import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';
import { Typography } from 'shared/components/Typography';
import styles from './index.module.scss';

const CarPicturesPlaceholder: React.FC = () => (
  <>
    <Typography variant="titleXL" tag="div" additonalClassNames={styles.title}>
      <Skeleton width="115px" height="100%" />
    </Typography>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '40px',
      }}
    >
      <Skeleton width="180px" height="135px" />
      <Skeleton width="180px" height="135px" />
      <Skeleton width="180px" height="135px" />
      <Skeleton width="180px" height="135px" />
    </div>
  </>
);

export { CarPicturesPlaceholder };
