import type { AuthManager } from '@pa/auth-manager';
import { authManager } from '../authManager';
import {
  GOOGLE_USER_ACCESS_REFRESH_TOKEN,
  GOOGLE_USER_UUID,
  GOOGLE_USER_ACCESS_KEY,
} from '../constants';

const getResponse = (
  success: boolean,
  error: Error | null,
  data?: Awaited<
    ReturnType<InstanceType<typeof AuthManager>['loginWithGoogleCode']>
  >,
) => ({
  errorMessage: (error && error.message) || null,
  success,
  data,
  __typename: 'LoginWithGoogleCodeMutation',
});

export const loginWithGoogleCodeMutationResolver = (
  _: unknown,
  { authCode }: { authCode: string },
) => {
  return authManager(GOOGLE_USER_ACCESS_KEY)
    .loginWithGoogleCode(authCode)
    .then(async (data) => {
      localStorage.setItem(
        GOOGLE_USER_ACCESS_REFRESH_TOKEN,
        data?.refreshToken,
      );
      localStorage.setItem(GOOGLE_USER_UUID, data?.userUuid);
      return getResponse(true, null, data);
    })
    .catch((err) => getResponse(false, err));
};
