import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = {
  additionalClassName?: string;
};

const InputLabel: React.FC<Props> = ({
  additionalClassName = '',
  children,
  ...props
}) => {
  return (
    <label className={cn(styles.inputLabel, additionalClassName)} {...props}>
      {children}
    </label>
  );
};

export { InputLabel };
