import * as React from 'react';

import cn from 'classnames';
import { Button } from '@auto1-ui/button';
import { Dialog } from '@auto1-ui/dialog';

import { Typography } from '../Typography';
import styles from './index.module.scss';

type Props = {
  className?: string;
  bodyClassName?: string;
  onCancel?: (evt?: React.MouseEvent<HTMLButtonElement>) => void;
  onOk?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  isOpen?: boolean;
  showOkButton?: boolean;
  showCancelButton?: boolean;
  okLabel?: React.ReactNode;
  cancelLabel?: string;
  okButtonExtraClass?: string;
  cancelButtonExtraClass?: string;
  titleExtraClassName?: string;
  actionContainerExtraClassName?: string;
  qaIdPrefix?: string;
  isCancelButtonDisabled?: boolean;
  isOkButtonDisabled?: boolean;
  onClose?: () => void;
};

const Modal = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      className,
      bodyClassName,
      okButtonExtraClass,
      cancelButtonExtraClass,
      titleExtraClassName,
      actionContainerExtraClassName,
      children,
      onCancel = () => { },
      title = '',
      onOk = () => { },
      isOpen = false,
      showOkButton = true,
      showCancelButton = true,
      okLabel = 'Ok',
      cancelLabel = 'Cancel',
      qaIdPrefix = '',
      isOkButtonDisabled = false,
      isCancelButtonDisabled = false,
      onClose,
    },
    ref,
  ) => {
    const handleClose = () => {
      if (onClose && typeof onClose === 'function') {
        onClose();
      } else {
        onCancel();
      }
    };

    return (
      <Dialog
        isCloseIconVisible
        handleClose={handleClose}
        isOpen={isOpen}
        closeIconQaId={`${qaIdPrefix}-close-icon`}
      >
        <div
          ref={ref}
          className={cn(styles.root, className)}
          data-qa-id={qaIdPrefix}
        >
          {title && (
            <Typography
              tag="div"
              variant="titleXL"
              additonalClassNames={cn(styles.title, titleExtraClassName)}
              data-qa-id={`${qaIdPrefix}-title`}
            >
              {title}
            </Typography>
          )}
          {children && (
            <div className={cn(styles.bodyContainer, bodyClassName)}>
              {children}
            </div>
          )}
          {(showOkButton || showCancelButton) && (
            <div
              className={cn(
                styles.actionContainer,
                actionContainerExtraClassName,
              )}
            >
              {showOkButton && (
                <Button
                  theme="orange"
                  onClick={onOk}
                  extraClass={cn(
                    styles.button,
                    styles.okButton,
                    okButtonExtraClass,
                  )}
                  isDisabled={isOkButtonDisabled}
                  qaId={`${qaIdPrefix}-ok-button`}
                >
                  {okLabel}
                </Button>
              )}
              {showCancelButton && (
                <Button
                  isActivated={false}
                  theme="link"
                  onClick={onCancel}
                  extraClass={cn(
                    styles.button,
                    styles.cancelButton,
                    cancelButtonExtraClass,
                  )}
                  isDisabled={isCancelButtonDisabled}
                  qaId={`${qaIdPrefix}-cancel-button`}
                >
                  {cancelLabel}
                </Button>
              )}
            </div>
          )}
        </div>
      </Dialog>
    );
  },
);

Modal.displayName = 'Modal';

export { Modal };
