import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import { Button } from '@auto1-ui/button';
import { useBranchCountryQuery, useCarDetailsCarInfoQuery } from 'gql/graphql';
import type { PdfJobStatus as PdfJobStatusType } from 'gql/graphql';
import { AlertBanner } from 'shared/components/AlertBanner';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import styles from 'shared/components/NavigationFooter/index.module.scss';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import Loader from 'shared/components/Loader';
import { useSideMenuNavigationTracking } from 'shared/hooks/useSideMenuNavigationTracking';
import { appRoutes } from 'appRoutes';
import { useScrollToSection } from 'shared/utils/useSectionScroll';
import { useInspection } from 'shared/components/InspectionContext';
import { NavigationFooterPlaceholder } from 'shared/components/NavigationFooter/Placeholder';
import { PdfJobStatus, isElectricCar } from 'shared/constants';
import { VinInfo } from 'shared/components/VinInfo';
import { AlertBar } from 'shared/components/AlertBar';
import type { Translations } from 'shared/utils/translations';
import { VinInfoPlaceholder } from 'shared/components/VinInfo/Placeholder';

import { CarInfo } from './CarInfo';
import { AdditionalInfo } from './AdditionalInfo';
import { useCarDetails } from './useCarDetails';
import { ElectricCarInfo } from './ElectricCarInfo';

type Props = {
  qaIdPrefix?: string;
};

const CarDetails: React.FC<Props> = ({ qaIdPrefix = 'car-details-page' }) => {
  const location =
    useLocation<{
      prev?: {
        pathname: string;
        hash: string;
      };
      sideMenuNavigation?: boolean;
      pdfStatus?: PdfJobStatusType | null;
    }>();
  const params = useParams<{ branchId?: string }>();
  const history = useHistory();
  const { inspectionId, isPdfInspection } = useInspection();
  const initialBranchId = params?.branchId;
  const [branchId, setBranchId] = React.useState(
    initialBranchId && Number(initialBranchId),
  );

  const [isAlertVisible, setIsAlertVisible] = React.useState(false);

  useSideMenuNavigationTracking(
    location.state && location.state.prev,
    location,
  );
  const carInfoQueryResult = useCarDetailsCarInfoQuery({
    variables: { inspectionId },
    fetchPolicy: 'network-only',
  });
  const { data: carInfoData, loading: carInfoLoading } = carInfoQueryResult;

  const {
    formMethods,
    onSubmitError,
    submitContext,
    handleSubmit: onSubmit,
    translations,
    data,
    refetch,
    loading,
    serverError,
  } = useCarDetails();
  const { data: branchCountryData } = useBranchCountryQuery({
    variables: { branchId: branchId as number },
    skip: !branchId,
  });

  React.useEffect(() => {
    if (!data?.inspection?.vehicle?.model && data?.inspection?.inspectionId) {
      history.push(
        appRoutes.manualFlow(`${data?.inspection?.inspectionId}`, true),
      );
    } else if (data?.inspection?.branch.id && branchId === undefined) {
      setBranchId(data?.inspection?.branch.id);
    }
  }, [branchId, data, history]);

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  const vin = watch('vin');
  const fuelType = data?.inspection?.vehicle?.engine?.fuelType;
  const isStockNumberAvailable = !!data?.inspection?.car?.stockNumber;
  const [carInfoLoaded, setCarInfoLoaded] = React.useState(false);
  const didLoadHandler = React.useCallback(() => {
    setCarInfoLoaded(true);
  }, [setCarInfoLoaded]);

  const { scroll } = useScrollToSection(location.hash);
  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    if (carInfoLoaded && data && isFirstRender.current) {
      isFirstRender.current = false;
      scroll();
    }
  }, [carInfoLoaded, data, scroll]);

  React.useLayoutEffect(() => {
    if (location?.state?.pdfStatus === PdfJobStatus.FINISHED) {
      setIsAlertVisible(true);
    }
  }, [location]);

  return (
    <FormProvider {...formMethods}>
      <LayoutContainer>
        {isAlertVisible && (
          <AlertBanner onClose={() => setIsAlertVisible(false)}>
            {translations.PDF_INFO_POPULATED_SUCCESSFULLY}
          </AlertBanner>
        )}
        {carInfoLoading ? (
          <VinInfoPlaceholder />
        ) : (
          <VinInfo
            vin={
              carInfoData?.inspection?.vehicle?.identifiers?.vin ?? undefined
            }
            inspectionId={inspectionId}
            branchId={branchId || data?.inspection?.branch?.id || null}
            licensePlateCallEnabled={
              data?.inspection?.branch?.licensePlateCallEnabled
            }
            qaIdPrefix={`${qaIdPrefix}-vin-info`}
            showManualFlow={false}
          />
        )}
        <CarInfo
          carInfoQueryResult={carInfoQueryResult}
          qaIdPrefix={`${qaIdPrefix}-car-info`}
          onDidLoad={didLoadHandler}
        />
        {isStockNumberAvailable && (
          <AdditionalInfo
            qaIdPrefix={`${qaIdPrefix}-additional-info`}
            branchCountry={
              data?.inspection?.branch?.country ??
              branchCountryData?.branch?.country
            }
            countryOfOrigin={
              data?.inspection?.vehicle?.condition?.countryOfOrigin ?? undefined
            }
          />
        )}
        {isStockNumberAvailable && isElectricCar(fuelType) && (
          <ElectricCarInfo
            qaIdPrefix={`${qaIdPrefix}-electric-car-info`}
            isPdfInspection={isPdfInspection}
          />
        )}
      </LayoutContainer>
      {loading ? (
        <NavigationFooterPlaceholder />
      ) : (
        <NavigationFooter
          backButtonLink={appRoutes.carInfo(true)}
          rightAreaContent={
            <>
              <Button
                theme="outlined"
                isActivated={false}
                extraClass={styles.saveButton}
                onClick={() => {
                  submitContext.current = 'save';
                  handleSubmit(onSubmit, onSubmitError)();
                }}
                qaId={`${qaIdPrefix}-footer-save-link`}
                isDisabled={!isStockNumberAvailable || isSubmitting}
              >
                {translations.SAVE}
                {isSubmitting && submitContext.current === 'save' && (
                  <Loader extraClass={styles.saveButtonLoader} />
                )}
              </Button>
              <Button
                theme="orange"
                extraClass={styles.nextButton}
                qaId={`${qaIdPrefix}-footer-next-link`}
                isActivated={false}
                onClick={() => {
                  if (isStockNumberAvailable) {
                    submitContext.current = 'submit';
                    handleSubmit(onSubmit, onSubmitError)();
                  } else {
                    refetch();
                  }
                }}
                isDisabled={!vin || isSubmitting}
              >
                {translations.NEXT}
                {isSubmitting && submitContext.current === 'submit' && (
                  <Loader extraClass={styles.nextButtonLoader} />
                )}
              </Button>
            </>
          }
          bottomAreaContent={
            serverError && (
              <AlertBar
                type="error"
                message={
                  serverError in translations
                    ? translations[serverError as keyof Translations]
                    : translations.GENERIC_ERROR_MESSAGE
                }
                additionalClassNames={styles.alertBar}
              />
            )
          }
        />
      )}
      <RouteLeavingGuard when={isDirty} title={translations.UNSAVED_CHANGES}>
        <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
      </RouteLeavingGuard>
    </FormProvider>
  );
};

const CarDetailsWithTransaction = withTransaction(
  'CarDetails',
  'component',
)(CarDetails);

export { CarDetailsWithTransaction as CarDetails };
