import * as React from 'react';
import type {
  SummaryInitialDataQuery,
  VehicleMimeType,
  VehicleImageArea,
} from 'gql/graphql';
import { Typography } from 'shared/components/Typography';
import { Carousel } from 'shared/components/Carousel';
import { ImagePreview } from 'shared/components/ImagePreview';
import { VideoPreview } from 'shared/components/VideoPreview';
import { VEHICLE_MIME_TYPE } from 'pages/CarPictures/constants';
import { useInspection } from 'shared/components/InspectionContext';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';

type Damage = {
  images?: ReadonlyArray<{
    absoluteUrl: string;
    mimeType: VehicleMimeType;
  }> | null;
};
type Props = {
  qaIdPrefix: string;
  data: SummaryInitialDataQuery;
};

function mapImages(image: Damage) {
  if (Array.isArray(image?.images)) {
    return image?.images;
  }
  return [];
}

const CarPictures: React.FC<Props> = ({ qaIdPrefix, data }) => {
  const { inspectionId, stockNumber } = useInspection();
  const images = React.useMemo(() => {
    const carPictures = data?.inspection?.vehicle?.images ?? [];
    const damages = data?.inspection?.vehicle?.damages ?? [];
    const highlights = data?.inspection?.meta?.highlights ?? [];

    return [
      ...carPictures,
      ...damages.flatMap(mapImages),
      ...highlights.flatMap(mapImages),
    ] as ReadonlyArray<{
      absoluteUrl: string;
      mimeType: VehicleMimeType;
      area?: VehicleImageArea;
    }>;
  }, [data]);
  const [carMedia, setCarMedia] =
    React.useState<{ url: string; type: 'video' | 'image' } | null>(null);
  const { translations } = useTranslation();
  return (
    <>
      <Typography
        data-qa-id={`${qaIdPrefix}-title`}
        variant="titleXL"
        tag="div"
        additonalClassNames={styles.title}
      >
        {translations.CAR_PICTURES_TITLE}
      </Typography>
      <Carousel
        extraContainerClass={styles.carousel}
        extraimageContainerClass={styles.extraimageContainerClass}
      >
        {!!images.length &&
          images.map((image) => {
            return image.mimeType === VEHICLE_MIME_TYPE.MP4 ? (
              <div key={image.absoluteUrl}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  src={image.absoluteUrl}
                  controls={false}
                  onClick={() => {
                    window.tracking.eva.previewVideoSummary({
                      area: image.area,
                      inspectionId,
                      stockNumber,
                    });
                    setCarMedia({ url: image.absoluteUrl, type: 'video' });
                  }}
                />
              </div>
            ) : (
              <img
                key={image.absoluteUrl}
                src={image.absoluteUrl}
                alt="car registration"
                onClick={() => {
                  window.tracking.eva.previewPhotoSummary({
                    area: image.area,
                    inspectionId,
                    stockNumber,
                  });
                  setCarMedia({ url: image.absoluteUrl, type: 'image' });
                }}
              />
            );
          })}
      </Carousel>
      {carMedia?.url && (
        <>
          <ImagePreview
            isOpen={carMedia?.type === 'image'}
            handleClose={() => setCarMedia(null)}
            source={{ absoluteUrl: carMedia?.url }}
          />
          <VideoPreview
            isOpen={carMedia?.type === 'video'}
            handleClose={() => setCarMedia(null)}
            videoFile={carMedia?.url}
          />
        </>
      )}
    </>
  );
};

export { CarPictures };
