import * as React from 'react';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { NavigationFooterPlaceholder } from 'shared/components/NavigationFooter/Placeholder';

import { ServicePlaceholder } from './Service/Placeholder';
import { AccidentsPlaceholder } from './Accidents/Placeholder';
import { OtherDocumentsPlaceholder } from './OtherDocuments/Placeholder';

const Placeholder: React.FC = () => (
  <LayoutContainer>
    <ServicePlaceholder />
    <AccidentsPlaceholder />
    <OtherDocumentsPlaceholder />
    <NavigationFooterPlaceholder />
  </LayoutContainer>
);

export { Placeholder };
