import * as React from 'react';
import { differenceInSeconds } from 'date-fns';
import type { UseFormSetError, UseFormClearErrors } from 'react-hook-form';
import {
  useCheckBeforeSubmitLicensePlateV2LazyQuery,
  CheckBeforeSubmitVinV2Query,
} from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import type { Form } from '../types';

type Params = {
  branchId?: number;
  licensePlateValue?: string;
  setError: UseFormSetError<Form>;
  clearErrors: UseFormClearErrors<Form>;
  setServerError: React.Dispatch<React.SetStateAction<string>>;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  checkCompleted: (
    result: CheckBeforeSubmitVinV2Query['checkBeforeSubmitVinV2'],
  ) => void;
  updateContext: (context: 'vinInfo' | 'licensePlateInfo') => void;
};

const useLicensePlate = ({
  branchId,
  licensePlateValue,
  setError,
  clearErrors,
  setServerError,
  setIsSubmitting,
  checkCompleted,
  updateContext,
}: Params) => {
  const { translations } = useTranslation();
  const [submitNb, setSubmitNb] = React.useState(0);
  const [shouldUseVin, setShouldUseVin] = React.useState(false);
  const [renderPageStartTime] = React.useState(Date.now());

  const trackOpenVINScreen = ({ reason }: { reason: string }) => {
    window.tracking.eva.auto1EVAVINScreen({
      reason,
      branch: branchId && `${branchId}`,
      duration: differenceInSeconds(Date.now(), renderPageStartTime),
    });
  };

  const handleUseVin = () => {
    setShouldUseVin(true);
    setServerError('');
    trackOpenVINScreen({ reason: 'useVINInstead' });
  };

  React.useEffect(() => {
    setShouldUseVin(false);
    setServerError('');
    clearErrors();
    setSubmitNb(0);
  }, [branchId, clearErrors, setServerError, setShouldUseVin]);

  const handleResolveLicensePlateFail = () => {
    setIsSubmitting(false);
    if (submitNb === 0) {
      setSubmitNb(1);
      setError('licensePlate', {
        type: 'custom',
        message: translations.LICENSE_PLATE_FAIL_MESSAGE,
      });
      window.tracking.eva.loadLicensePlateWarning({
        licensePlate: licensePlateValue,
        branch: branchId && `${branchId}`,
        duration: differenceInSeconds(Date.now(), renderPageStartTime),
      });
    } else {
      setShouldUseVin(true);
      updateContext('vinInfo');
      trackOpenVINScreen({ reason: 'error' });
    }
  };

  const [checkBeforeSubmitLicensePlateV2, { data, loading, refetch }] =
    useCheckBeforeSubmitLicensePlateV2LazyQuery({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (result) => {
        if (result?.checkBeforeSubmitLicensePlateV2?.vinStatus === 'NO_VIN') {
          handleResolveLicensePlateFail();
        } else {
          checkCompleted(result?.checkBeforeSubmitLicensePlateV2);
        }
      },
      onError: () => {
        handleResolveLicensePlateFail();
      },
    });

  return {
    checkBeforeSubmitLicensePlateV2,
    handleUseVin,
    shouldUseVin,
    licensePlateData: data,
    licensePlateLoading: loading,
    licensePlateRefetch: refetch,
  };
};

export { useLicensePlate };
