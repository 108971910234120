import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { appRoutes } from 'appRoutes';
import { inspectionSources, InspectionActions } from 'shared/constants';
import type { InspectionsV2Query } from 'gql/graphql';

type Inspection = Omit<
  NonNullable<InspectionsV2Query['inspectionsV2']>['inspections'][number],
  'status'
>;

const useCheckInspectionBeforeOpen = () => {
  const history = useHistory();
  const [showModal, setShowModal] = React.useState(false);
  const [inspectionInfo, setInspectionInfo] = React.useState<Inspection>();
  const [isPiConvertibleToFullEva, setIsPiConvertibleToFullEva] =
    React.useState(false);

  const handleOpenInspection = (inspection: Inspection) => {
    setInspectionInfo(inspection);
    if (inspection?.sourceType === inspectionSources.PI_APP) {
      setShowModal(true);
      if (
        inspection?.actions?.find(
          ({ name }) => name === InspectionActions.ADD_TO_FULL_EVALUATION,
        )
      ) {
        setIsPiConvertibleToFullEva(true);
      }
    } else {
      history.push(appRoutes.carDetails(`${inspection.inspectionId}`, true));
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return {
    showModal,
    isPiConvertibleToFullEva,
    inspectionInfo,
    handleOpenInspection,
    handleCloseModal,
  };
};

export { useCheckInspectionBeforeOpen };
