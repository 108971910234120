import * as React from 'react';
import { VinStatus } from 'shared/utils/graphqlEnums';
import { useCheckBeforeSubmitToAuctionLazyQuery } from 'gql/graphql';
import type { QueryLazyOptions } from '@apollo/client';
import type {
  CheckBeforeSubmitToAuctionQuery,
  VinStatus as VinStatusType,
  CheckBeforeSubmitToAuctionQueryVariables,
} from 'gql/graphql';
import { SubmitToAuctionModal } from '../constants';

type CheckBeforeSubmitToAuctionQueryOptions =
  QueryLazyOptions<CheckBeforeSubmitToAuctionQueryVariables>;
type WarningModalProps = {
  isOpen: boolean;
  vinStatus?: VinStatusType;
  inspectionInfo?: NonNullable<
    CheckBeforeSubmitToAuctionQuery['checkBeforeSubmitToAuction']
  >['relatedInspection'];
  currentStockNumber?: string;
  onSecondaryButtonClick?: () => void;
  handleClose?: () => void;
};

function useBlockingVin(stockNumber: string) {
  const [modalToOpen, setModalToOpen] =
    React.useState<keyof typeof SubmitToAuctionModal | null>(null);
  const [openedModal, setOpenedModal] =
    React.useState<keyof typeof SubmitToAuctionModal | null>(modalToOpen);
  const [warningModalProps, setWarningModalProps] =
    React.useState<WarningModalProps>({
      isOpen: false,
    });

  const [checkBeforeSubmitToAuction, { loading: isCheckingBeforeSubmit }] =
    useCheckBeforeSubmitToAuctionLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (result) => {
        const vinStatus = result?.checkBeforeSubmitToAuction?.vinStatus;
        const inspection =
          result?.checkBeforeSubmitToAuction?.relatedInspection;
        switch (vinStatus) {
          case VinStatus.AuctionEnded:
            setWarningModalProps({
              isOpen: true,
              vinStatus,
              inspectionInfo: inspection,
              currentStockNumber: stockNumber,
              onSecondaryButtonClick: () => {
                setWarningModalProps({ isOpen: false });
                setOpenedModal(modalToOpen);
              },
              handleClose: () => {
                setWarningModalProps({ isOpen: false });
              },
            });
            break;
          case VinStatus.RunningAuction:
          case VinStatus.RerunLimitExhausted:
            setWarningModalProps({
              isOpen: true,
              vinStatus,
              inspectionInfo: inspection,
              currentStockNumber: stockNumber,
              handleClose: () => {
                setWarningModalProps({ isOpen: false });
              },
            });
            break;
          default:
            setOpenedModal(modalToOpen);
            break;
        }
      },
    });

  const checkBeforeSubmitToFullService = (
    options: CheckBeforeSubmitToAuctionQueryOptions,
  ) => {
    setModalToOpen(SubmitToAuctionModal.FullService);
    checkBeforeSubmitToAuction(options);
  };
  const checkBeforeSubmitToDirectAuction = (
    options: CheckBeforeSubmitToAuctionQueryOptions,
  ) => {
    setModalToOpen(SubmitToAuctionModal.DirectAuction);
    checkBeforeSubmitToAuction(options);
  };

  const checkBeforeSubmitToBatchAuction = (
    options: CheckBeforeSubmitToAuctionQueryOptions,
  ) => {
    setModalToOpen(SubmitToAuctionModal.BatchAuction);
    checkBeforeSubmitToAuction(options);
  };

  return {
    checkBeforeSubmitToFullService,
    checkBeforeSubmitToDirectAuction,
    checkBeforeSubmitToBatchAuction,
    isCheckingBeforeSubmit,
    openedModal,
    setOpenedModal,
    warningModalProps,
  };
}

export { useBlockingVin };
