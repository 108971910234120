import * as React from 'react';
import { FormTextarea } from 'shared/components/FormTextarea';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Card } from 'shared/components/Card';
import { CollapseLink } from 'shared/components/CollapseLink';
import styles from './index.module.scss';
import { CarInfoItems } from './CarInfoItems';
import { carInfoDataItems } from './carInfoDataItems';
import { CarInfoPlaceholder } from './Placeholder';

type Props = {
  qaIdPrefix?: string;
  onDidLoad: () => void;
  carInfoQueryResult: {
    data?: object;
    loading?: boolean;
  };
};

const CarInfo: React.FC<Props> = ({
  qaIdPrefix = 'car-info',
  onDidLoad,
  carInfoQueryResult,
}) => {
  const { translations } = useTranslation();
  const { data: carInfo, loading: queryLoading } = carInfoQueryResult;

  React.useEffect(() => {
    if (!queryLoading && carInfo) {
      onDidLoad();
    }
  }, [queryLoading, carInfo, onDidLoad]);

  if (queryLoading) {
    return <CarInfoPlaceholder />;
  }

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      qaIdPrefix={qaIdPrefix}
      externalStyle={styles.card}
      showBottomBorder
    >
      {carInfo && (
        <>
          <div className={styles.flexGrid} data-qa-id={`${qaIdPrefix}-content`}>
            <CarInfoItems
              items={carInfoDataItems(carInfo, qaIdPrefix, translations)}
            />
          </div>
          <div className={styles.reportContainer}>
            <CollapseLink
              title={translations.REPORT_WRONG_DATA}
              qaIdPrefix={`${qaIdPrefix}-report-wrong-data-container`}
            >
              <FormTextarea
                rows={5}
                qaIdPrefix={`${qaIdPrefix}-report-wrong-data-text-area`}
                name="otherNote"
                containerClassName={styles.inputContainerFullWidth}
                defaultValue=""
              />
            </CollapseLink>
          </div>
        </>
      )}
    </Card>
  );
};

export { CarInfo };
