import * as React from 'react';
import omit from 'lodash/omit';
import type { AccidentReason } from 'gql/graphql';
import { useFormContext, useFormState } from 'react-hook-form';
import { Carousel } from 'shared/components/Carousel';
import { Checkbox } from 'shared/components/Checkbox';
import { Typography } from 'shared/components/Typography';
import type { ImageData, Damage } from '../../../types';
import { ReasonsForm } from './types';
import styles from './index.module.scss';

type Props = {
  name: AccidentReason;
  damages: Damage[];
  qaIdPrefix?: string;
};

type Image = ImageData | File;

function mapDamagesToImagesArray(damages: Damage[]) {
  return damages
    .filter(
      (damage): damage is Damage & { images: NonNullable<Damage['images']> } =>
        !!damage.images,
    )
    .flatMap(({ images }) =>
      images?.map((image) => {
        if (image instanceof File) {
          return { file: image, absoluteUrl: URL.createObjectURL(image) };
        }

        return omit(image, ['__typename']);
      }),
    );
}

function isDefaultChecked(
  fields: Image[],
  image: ReturnType<typeof mapDamagesToImagesArray>[number],
) {
  return fields?.some((field) => {
    if (field instanceof File && image instanceof File && field === image) {
      return true;
    }
    if (
      !(field instanceof File) &&
      !(image instanceof File) &&
      field.absoluteUrl === image?.absoluteUrl
    ) {
      return true;
    }

    return false;
  });
}

const CarouselWithSelectableImages: React.FC<Props> = ({
  name,
  damages,
  qaIdPrefix = 'carousel-with-selectable-images',
}) => {
  const fieldName = `root.${name}` as `root.${AccidentReason}`;
  const { setValue, getValues } = useFormContext<ReasonsForm>();
  const [initialValues] = React.useState(getValues(fieldName) ?? []);
  const images = React.useMemo(
    () => mapDamagesToImagesArray(damages),
    [damages],
  );
  const { errors, isSubmitted } = useFormState({
    name: fieldName,
    exact: true,
  });
  const errorMessage = errors.root?.[name]?.message;

  return (
    <>
      <Carousel
        extraContainerClass={styles.carousel}
        extraimageContainerClass={styles.carouselExtraImageContainer}
        qaIdPrefix={qaIdPrefix}
      >
        {images.map((image) => {
          return (
            <div
              className={styles.imageContainer}
              key={image instanceof File ? image.name : image?.absoluteUrl}
            >
              <img src={image.absoluteUrl} />
              <Checkbox
                className={styles.checkbox}
                defaultChecked={isDefaultChecked(initialValues, image)}
                onChange={(e) => {
                  const isChecked = (e.target as HTMLInputElement).checked;
                  const values = getValues(fieldName) ?? [];

                  if (isChecked) {
                    setValue(
                      fieldName,
                      [...values, 'file' in image ? image.file : image],
                      {
                        shouldValidate: isSubmitted,
                      },
                    );
                  } else {
                    setValue(
                      fieldName,
                      values.filter((value) => {
                        if (
                          value instanceof File &&
                          image instanceof File &&
                          value === image
                        ) {
                          return false;
                        }
                        if (
                          !(value instanceof File) &&
                          !(image instanceof File) &&
                          value.absoluteUrl === image.absoluteUrl
                        ) {
                          return false;
                        }

                        return true;
                      }),
                      {
                        shouldValidate: isSubmitted,
                      },
                    );
                  }
                }}
              />
            </div>
          );
        })}
      </Carousel>
      {errorMessage && (
        <Typography
          tag="p"
          variant="textXSRed"
          additonalClassNames={styles.carouselErrorMessage}
          data-qa-id={`${qaIdPrefix}-error-message`}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export { CarouselWithSelectableImages };
