import * as React from 'react';
import styles from './index.module.scss';

const Rear: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g className={styles.stroke} strokeWidth="1.5" mask="url(#prefix__d)">
          <g transform="translate(17.5 13.687)">
            <path d="M10.033 44.154c3.538.694 19.866 5.143 22.035 7.407 2.17 2.264 2.004 5.35 1.783 6.496-.22 1.145-2.405 3.11-3.8 3.977-1.394.866-15.863 4.012-22.208 1.59-2.339-.894-4.687-2.591-6.832-4.703-.622-4.39 2.041-13.006 3.221-15.906l5.801 1.14zM131.803 44.154c-3.538.694-19.866 5.143-22.035 7.407-2.17 2.264-2.004 5.35-1.783 6.496.22 1.145 2.405 3.11 3.8 3.977 1.394.866 15.863 4.012 22.207 1.59 2.34-.894 4.688-2.591 6.833-4.703.622-4.39-2.041-13.006-3.221-15.906l-5.801 1.14z" />
            <path d="M70.71 0l2.57.005c15.342.062 25.944.554 31.808 1.477l.268.043.494.084c4.207 1.145 15.503 16.107 22.832 32.962 2.984 1.719 7.468 6.27 8.452 8.071.984 1.802 4.724 12.419 3.563 16.867s0 5.578 0 8.975c0 3.397-5.668 26.129-8.841 29.71-2.98 3.361-12.829 5.2-21.502 5.412l-.836.016-.84.005c-2.828 0-5.832-3.126-8.522-3.126l-.18.005c-2.05.096-12.36 1.573-27.737 1.832h-2.642l-1.029-.02c-15.318-.315-25.38-1.817-26.888-1.817-2.69 0-5.694 3.126-8.523 3.126l-.84-.005-.835-.016c-8.673-.212-18.522-2.051-21.502-5.413-3.174-3.58-8.84-26.312-8.84-29.71 0-3.396 1.16-4.526 0-8.974-1.162-4.448 2.578-15.065 3.562-16.867.984-1.802 5.468-6.352 8.452-8.07C20.482 17.715 31.78 2.753 35.986 1.608l.494-.084.822-.127C43.552.488 54.688.022 70.71 0z" />
            <path d="M15.501 64.522c2.862 8.563 7.796 13.256 14.8 14.08 10.506 1.235 39.707 1.562 41.59 1.562" />
            <path d="M127.307 64.522c-2.862 8.563-7.795 13.256-14.8 14.08-10.506 1.235-39.707 1.562-41.59 1.562" />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12.585 44.97c2.421-6.824 4.366-11.386 5.833-13.686M129.251 44.97c-2.422-6.824-4.366-11.386-5.833-13.686"
            />
            <path
              strokeLinecap="round"
              d="M14.529 100.798c.646-1.62 2.343-2.284 5.09-1.993 4.122.437 14.402.964 15.28.964.586 0 1.25.634 1.991 1.903M127.307 99.792c-.37-.921-1.893-1.238-4.569-.95-4.014.43-14.026.95-14.881.95-.57 0-1.217.627-1.94 1.88"
            />
            <path d="M74.44 3.91c16.728.623 27.286 1.236 31.673 1.84 3.09 1.172 18.449 23.897 17.237 26.46-1.15 2.432-32.53 7.365-51.946 6.858-19.415.507-50.796-4.426-51.946-6.858-1.212-2.563 14.147-25.288 17.237-26.46l.745-.1c6.838-.88 17.147-1.435 30.927-1.662l2.037-.057 1.001.012 3.036-.033z" />
            <path
              strokeLinecap="round"
              d="M17.446 45.948c19.682 1.303 38.268 1.955 55.757 1.955 17.49 0 35.2-.652 53.132-1.955M30.085 49.858c15.029 1.304 29.22 1.955 42.575 1.955 13.354 0 26.709-.651 40.063-1.955"
            />
            <rect width="50.028" height="10.231" x="46.39" y="56.474" rx="2" />
            <path
              strokeLinecap="round"
              d="M11.325 98.739c-1.898-4.562.148-6.843 6.139-6.843 8.986 0 36.354 1.864 55.237 1.864 18.883 0 49.438-1.864 55.642-1.864 4.07 0 4.797 2.363 4.797 4.129"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { Rear };
