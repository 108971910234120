import * as React from 'react';
import { isBefore } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'shared/hooks/useTranslation';
import { FormUploader } from 'shared/components/FormUploader';
import { FormDropdown } from 'shared/components/FormDropdown';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import { Typography } from 'shared/components/Typography';
import { InputLabel } from 'shared/components/InputLabel';
import { Card } from 'shared/components/Card';
import { FormNumberInput } from 'shared/components/FormNumberField';
import { FormDateInput } from 'shared/components/FormDateInput';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';

import styles from './index.module.scss';
import {
  commercialUseOptions,
  inventoryTypeOptions,
  envClassOptions,
  vatOptions,
  getCountryOfOriginsOptions,
  ownerOptions,
  currentOwnerOptions,
} from './options';

type Props = {
  branchCountry?: string;
  countryOfOrigin?: string;
  qaIdPrefix: string;
};

const isGreaterThanOrEqualBuildYear = (
  date: Date,
  builtDate: string | null,
  buildDate: string,
) => {
  if (!buildDate) {
    return true;
  }

  const buildYear = builtDate ?? buildDate.match(/^\d{4}/)?.[0];
  const year = date.getFullYear();

  return year >= Number(buildYear) - 1;
};

const AdditionalInfo: React.FC<Props> = ({
  qaIdPrefix,
  branchCountry = '',
  countryOfOrigin = '',
}) => {
  const {
    watch,
    setValue,
    register,
    unregister,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();
  const { translations } = useTranslation();

  const inventoryType = watch('inventoryType');
  const co2EmissionAvailable = watch('co2EmissionAvailable');
  const builtDate = watch('builtDate');
  const buildDate = watch('buildDate');
  const vatType = watch('vatType');

  const countries = React.useMemo(
    () => getCountryOfOriginsOptions(countryOfOrigin, branchCountry),
    [branchCountry, countryOfOrigin],
  );

  React.useEffect(() => {
    switch (inventoryType) {
      case 'NEW':
        setValue('totalOwnerCount', '0');
        break;
      default:
    }
  }, [inventoryType, setValue]);

  React.useEffect(() => {
    if (!co2EmissionAvailable) return;
    setValue('co2Emission', null);
  }, [co2EmissionAvailable, setValue]);

  React.useEffect(() => {
    register('buildDate');

    return () => {
      unregister('buildDate');
    };
  }, [register, unregister]);

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      showBottomBorder
      qaIdPrefix={qaIdPrefix}
      id="additionalInfo"
    >
      <div className={styles.title}>
        <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
          {translations.ADDITIONAL_INFO}
        </Typography>
      </div>

      {/* FIRST SECTION */}
      <div className={styles.carInfoContainer}>
        <div className={styles.carInfoColumn}>
          {/* Country of origin */}
          <FormDropdown
            label={translations.COUNTRY_OF_ORIGIN}
            qaIdPrefix={`${qaIdPrefix}-country-of-origin`}
            placeholder={translations.PLEASE_SELECT}
            name="countryOfOrigin"
            options={countries}
            className={styles.dropdown}
            searchable
            defaultValue={null}
          />

          {/* First registeration date */}
          <FormDateInput
            name="firstRegistrationDate"
            label={translations.FIRST_REGISTRATION_DATE}
            className={styles.input}
            qaIdPrefix={`${qaIdPrefix}-first-registration-date`}
            defaultValue=""
            datePickerProps={{
              filterDate: (date) =>
                isBefore(date, Date.now()) &&
                isGreaterThanOrEqualBuildYear(date, builtDate, buildDate),
            }}
          />

          {/* Inventory Type */}
          <FormDropdown
            label={translations.INVENTORY_TYPE}
            qaIdPrefix={`${qaIdPrefix}-inventory-type`}
            placeholder={translations.PLEASE_SELECT}
            name="inventoryType"
            options={inventoryTypeOptions(translations)}
            className={styles.dropdown}
            searchable
            defaultValue={null}
          />
          {/* Environmental class */}
          <FormDropdown
            label={translations.ENVIRONMENTAL_CLASS}
            qaIdPrefix={`${qaIdPrefix}-environmental-class`}
            placeholder={translations.PLEASE_SELECT}
            name="envClass"
            defaultValue=""
            options={envClassOptions}
            className={styles.dropdown}
            searchable
          />
          {/* CO2 Emission */}
          <FormNumberInput
            qaIdPrefix={`${qaIdPrefix}-co2-emission`}
            disabled={co2EmissionAvailable}
            label={translations.CO2_EMISSION}
            className={styles.input}
            name="co2Emission"
            defaultValue={null}
          />
          {/* NO CO2  */}
          <FormCheckbox
            name="co2EmissionAvailable"
            uiLabel={translations.NO_CO2_EMISSION}
            qaIdPrefix={`${qaIdPrefix}-co2-emission-available`}
            containerClassName={styles.checkBoxLabel}
            defaultValue={false}
            afterChange={() => {
              if (isSubmitted) {
                trigger('co2Emission');
              }
            }}
          />
        </div>
        <div className={styles.carInfoColumn}>
          {/* Registration County */}
          <FormDropdown
            label={translations.REGISTRATION_COUNTRY}
            qaIdPrefix={`${qaIdPrefix}-registration-contry`}
            placeholder={translations.PLEASE_SELECT}
            name="countryOfRegistration"
            options={countries}
            className={styles.dropdown}
            searchable
            defaultValue={null}
          />
          {/* Total Owners */}
          <FormDropdown
            label={translations.TOTAL_OWENRS}
            qaIdPrefix={`${qaIdPrefix}-total-owners`}
            placeholder={translations.PLEASE_SELECT}
            name="totalOwnerCount"
            options={ownerOptions}
            className={styles.dropdown}
            searchable
            defaultValue=""
          />

          {/* Usage type */}
          <FormDropdown
            label={translations.USAGE_TYPE}
            qaIdPrefix={`${qaIdPrefix}-usage-type`}
            placeholder={translations.PLEASE_SELECT}
            name="commercialUseType"
            options={commercialUseOptions(translations)}
            className={styles.dropdown}
            searchable
            defaultValue={null}
          />
        </div>
      </div>

      {/* SECOND SECTION */}

      <div className={styles.toggleWrapper}>
        <FormToggleButton
          name="vatType"
          label={translations.VAT_TYPE}
          containerClassName={styles.toggleButton}
          errorClassName={styles.toggleError}
          qaIdPrefix={`${qaIdPrefix}-vat-type`}
          options={vatOptions(translations)}
          defaultValue={null}
        />
        {vatType === 'NOT_DEDUCTIBLE' && (
          <FormToggleButton
            name="currentOwnerType"
            label={translations.CURRENT_OWNER_TYPE_LABEL}
            containerClassName={styles.toggleButton}
            errorClassName={styles.toggleError}
            qaIdPrefix={`${qaIdPrefix}-current-owner-type`}
            options={currentOwnerOptions(translations)}
            defaultValue={null}
          />
        )}
      </div>

      {/* THIRD SECTION */}
      {/* Car Registeration document */}
      <div className={styles.uploaderWrapper}>
        <InputLabel>
          {translations.CAR_REGISTERATION_DOCUMENTS}{' '}
          <span className={styles.optionalLabel}>
            {' '}
            {translations.IF_AVALIABLE}
          </span>
        </InputLabel>

        <FormUploader
          allowMultipleFiles
          uploadFileTypes="image/png, image/jpeg, image/heic, image/heif"
          processSelectedFile={processHeicFile}
          extraClassName={styles.uploaderStyles}
          name="carRegistrationImages"
          qaIdPrefix={`${qaIdPrefix}-car-registration-images`}
        >
          {({ handleUploadClick }) => (
            <>
              <div className={styles.uploadCloudIcon} />
              <Typography>{translations.DROP_PHOTOS_HERE}</Typography>
              <button
                type="button"
                className={styles.browseButton}
                onClick={(event) => {
                  event.preventDefault();
                  handleUploadClick();
                }}
              >
                {translations.BROWSE}
              </button>
            </>
          )}
        </FormUploader>
      </div>

      {/* COC pictures */}
      <div className={styles.uploaderWrapper}>
        <InputLabel>
          {translations.COC_PICTURES}{' '}
          <span className={styles.optionalLabel}>
            {translations.IF_AVALIABLE}
          </span>
        </InputLabel>

        <FormUploader
          allowMultipleFiles
          uploadFileTypes="image/png, image/jpeg, image/heic, image/heif"
          processSelectedFile={processHeicFile}
          name="certificateImages"
          extraClassName={styles.uploaderStyles}
          qaIdPrefix={`${qaIdPrefix}-coc-images`}
        >
          {({ handleUploadClick }) => (
            <>
              <div className={styles.uploadCloudIcon} />
              <Typography>{translations.DROP_PHOTOS_HERE}</Typography>
              <button
                type="button"
                className={styles.browseButton}
                onClick={(event) => {
                  event.preventDefault();
                  handleUploadClick();
                }}
              >
                {translations.BROWSE}
              </button>
            </>
          )}
        </FormUploader>
      </div>
    </Card>
  );
};

export { AdditionalInfo };
