import * as React from 'react';
import { Input } from '@auto1-ui/input';

type Props = {
  mask: ReadonlyArray<RegExp>;
  onChange: (value: string) => void;
  separators: { [key: number]: string };
  initialValue?: string;
  qaId?: string;
  transform?: (value: string) => string;
  value?: string;
  className?: string;
} & React.ComponentProps<typeof Input>;

const MaskedInput: React.FC<Props> = ({
  mask,
  onChange,
  separators,
  className,
  initialValue = '',
  qaId = 'masked-input',
  transform = (value) => value,
  ...props
}) => {
  const [controlledValue, setControlledValue] = React.useState(initialValue);

  const handleChange = React.useCallback(
    (event) => {
      if (event.target.value === '') {
        onChange('');
        setControlledValue('');
      } else {
        const withoutSpacesValue = event.target.value.replace(/\s+/g, '');
        const characters = transform(withoutSpacesValue) // eslint-disable-line no-multi-assign
          .split('')
          .filter((character) =>
            mask.some((maskItem) => maskItem.test(character)),
          );
        const maskedValue = mask.map((_, index) => separators[index]);
        let maskIndex = 0;
        let valueIndex = 0;
        let omittedSeparatorOffset = 0;

        while (valueIndex < characters.length && maskIndex < mask.length) {
          if (mask[maskIndex].test(characters[valueIndex])) {
            maskedValue[maskIndex] = characters[valueIndex];

            valueIndex += 1;
          } else if (separators[maskIndex] !== undefined) {
            omittedSeparatorOffset += 1;
          }

          maskIndex += 1;
        }

        const maskedValueParsed = maskedValue
          .filter((value) => value !== undefined)
          .slice(0, valueIndex + omittedSeparatorOffset)
          .join('');

        onChange(maskedValueParsed);
        setControlledValue(maskedValueParsed);
      }
    },
    [mask, onChange, separators, transform],
  );

  return (
    <Input
      onChange={handleChange}
      value={controlledValue}
      {...props}
      qaTag={qaId}
      extraClass={className}
    />
  );
};

export { MaskedInput };
