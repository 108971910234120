import * as React from 'react';
import { GoogleAuthenticatorButton } from '@pa/react-google-authenticator';
import { Button } from '@auto1-ui/button';
import { GOOGLE_CLIENT_ID } from 'auth/constants';
import { useTranslation } from 'shared/hooks/useTranslation';

type Props = {
  onGoogleResponseSuccess: (response: { code: string }) => void;
  onGoogleResponseFailure: () => void;
  qaIdPrefix?: string;
};

const GOOGLE_SCOPE = 'email profile openid';

const GoogleAuth: React.FC<Props> = ({
  onGoogleResponseSuccess,
  onGoogleResponseFailure,
  qaIdPrefix = 'login',
}) => {
  const isSubmitting = false;
  const { translations } = useTranslation();
  return (
    <div>
      <GoogleAuthenticatorButton
        googleScope={GOOGLE_SCOPE}
        googleClientId={GOOGLE_CLIENT_ID}
        onSuccess={onGoogleResponseSuccess}
        onFailure={onGoogleResponseFailure}
        render={({ onClick, disabled }) => (
          <Button
            onClick={onClick}
            isDisabled={disabled || isSubmitting}
            theme="orange"
            qaId={`${qaIdPrefix}-google-auth-btn`}
          >
            {translations.LOGIN_BTN}
          </Button>
        )}
      />
    </div>
  );
};

export default GoogleAuth;
