import * as React from 'react';
import { useTranslation } from 'shared/hooks/useTranslation';
import {
  useTechnicalInspectionCompaniesQuery,
} from 'gql/graphql';
import { OTHER_INSPECTION_COMPANY_ID } from '../constants';

function useInspectionCompanies(
  { inspectionId }: { inspectionId: number },
  options = {},
) {
  const { translations } = useTranslation();
  const { data, loading } = useTechnicalInspectionCompaniesQuery({
    variables: {
      inspectionId,
    },
    ...options,
  });

  const companies = React.useMemo(() => {
    if (data?.technicalInspectionCompanies && !loading) {
      return data.technicalInspectionCompanies
        .map(({ name, id }) => {
          if (id === OTHER_INSPECTION_COMPANY_ID) {
            return {
              label: translations.OTHER,
              value: id,
            };
          }
          return {
            label: name,
            value: id,
          };
        })
        .sort((company1, company2) =>
          company1.label.localeCompare(company2.label),
        );
    }

    return [];
  }, [data, loading, translations]);

  return { companies, loading };
}

export { useInspectionCompanies };
