import * as React from 'react';
import styles from './index.module.scss';

const EngineVideo: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g mask="url(#prefix__d)">
          <g className={styles.stroke} strokeWidth="1.5">
            <path
              d="M161.983 81.467c1.055-1.647-.42-2.817-2.171-3.97-1.752-1.153-3.804-.773-4.808.794-1.003 1.566-.181 3.107 1.57 4.26 1.752 1.153 4.675.063 5.41-1.084zM127.945 52.386c1.055-1.647-.42-2.817-2.172-3.97-1.751-1.154-3.804-.774-4.807.793-1.004 1.567-.182 3.107 1.57 4.26 1.751 1.154 4.674.064 5.409-1.083zM.904 86.955c14.813 17.726 42.528 26.746 94.596 26.494 42.493-.206 60.96.923 80.906-20.833"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              d="M7.426 86.51c14.814 17.725 45.787 22.141 95.711 21.729 49.924-.413 57.915-5.18 62.934-13.278 5.018-8.1-3.247-31.578-3.247-31.578l-6.058-17.893 4.324.855"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              d="M170.11 75.545L159.141 40.795 156.873 39.76 146.978 3.105M6.16 77.993l13.343-36.581s41.956-1.316 70.054-.82c28.098.496 56.221 5.536 65.063 7.602"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              d="M18.134 38.003s34.753-2.287 73.422-1.143c38.669 1.143 62.46 7.343 62.46 7.343"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              strokeLinecap="square"
              d="M7.116 63.347c2.999-8.46 9.82-28.24 9.82-28.24l2.798-1.897L30.947.906"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              d="M20.448 96.925l-1.593-5.177s2.745-3.93 6.594-5.547c3.849-1.616 8.05-2.078 8.05-2.078l3.283 2.414h2.279l20.782 20.214M135.554 90.807c2.627-1.796.724-5.144-1.656-8.721-2.38-3.578-6.051-4.848-8.55-3.14-2.5 1.71-1.892 5.075.488 8.652 2.38 3.578 7.888 4.46 9.718 3.21z"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              d="M12.8 59.416l10.224-2.887 14.76 18.694s2.36 8.613.246 10.88M61.725 107.256l.263-22.525-.928-1.647s.47-10.457.47-13.173c0-2.717 4.13-7.056 6.937-8.585 2.808-1.53 6.864-2.711 6.864-2.711l.231 11.302-1.04 2.825.228 10.837-.863 2.966s.095 3.776.095 5.197c0 1.422 5.18 3.464 8.546 3.464 3.365 0 21.452.349 24.471.349 3.02 0 4.395-3.054 4.395-6.102 0-3.049-.922-18.36-.922-18.36l-2.088-1.062-.465-10.224 11.582 7.287 4.41 18.059-1.388 1.392 4.328 20.72"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              d="M39.654 86.563l8.249-21.332 19.13-10.077 1.062-9.568 18.308-.857s13.145 6.111 22.465 6.111C115.3 57.21 119.973 64.75 125.27 64.75c1.382 5.024 2.756 9.144 2.756 9.144l7.628 3.012 9.56 28.404M28.1 63.019L33.228 59.797 34.401 61.617 29.266 64.748M31.726 66.998L36.262 63.887 37.849 66.33 33.024 69.565M30.088 55.043L39.606 68.656 45.431 64.581 35.896 50.633zM39.163 54.66c5.505-4.072 19.696-9.225 28.084-2.248M44.338 62.843c9.113-6.93 10.712-3.974 14.108-2.688"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              d="M75.797 58.946L75.797 53.775 66.999 53.775M107.703 59.439L107.809 55.257 113.663 55.908M138.06 71.86c-3.388 0-7.826-.426-9.208-7.395-1.382-6.968-5.073-4.6-8.146-5.68-3.073-1.079-4.463-5.784-1.59-9.122 2.872-3.339 9.321-2.591 12.284-.116 2.963 2.476 8.124 2.037 10.252 3.861 2.127 1.824 8.677 12.796 8.677 14.58 0 1.785-.738 3.213.642 4.941 1.38 1.729 1.871 6.207-3.724 1.526-5.595-4.682-5.799-2.595-9.187-2.595z"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              strokeLinecap="round"
              d="M149.853 66.095s7.321.807 9.023-.924c1.701-1.731-.734-5.777-1.7-8.914-.965-3.138-1.709-4.32-.274-4.643 1.435-.324 1.322-.654 1.322-.654"
              transform="rotate(-1 649.091 172.881)"
            />
            <path
              d="M148.7 64.219s7.344.986 8.648-.27c1.304-1.256-1.37-5.638-2.334-8.776-.965-3.137-1.108-4.667.325-5.079 1.433-.411 2.26-.69 2.26-.69"
              transform="rotate(-1 649.091 172.881)"
            />
          </g>
        </g>
        <g className={styles.fill} fillRule="nonzero">
          <path
            d="M24 0c13.234 0 24 10.767 24 24S37.234 48 24 48 0 37.233 0 24 10.766 0 24 0zm-4.459 14.16c-.307-.201-.703-.212-1.02-.038-.322.176-.521.512-.521.878v18c0 .366.2.702.522.878.148.081.314.122.478.122.188 0 .377-.053.541-.16l14-9c.285-.183.459-.5.459-.84 0-.34-.174-.657-.459-.84z"
            transform="translate(64 42)"
          />
        </g>
      </g>
    </svg>
  );
};

export { EngineVideo };
