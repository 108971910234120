import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { TestDriveQuesitonsTree } from './TestDriveQuesitonsTree';
import { findNestedQuestionById } from '../utils';
import { testDriveQuestions } from '../TestDriveSection/TestDriveQuestionid';
import type { Question } from '../types';
import styles from '../index.module.scss';

type Props = {
  qaIdPrefix: string;
  questionIds: ReadonlyArray<number>;
  questions?: ReadonlyArray<Question>;
  section?: string;
};

const TestDrivePageSection: React.FC<Props> = ({
  questionIds,
  questions = [],
  qaIdPrefix,
  section = '',
}) => {
  const { watch } = useFormContext();
  const testDrivePerformed = watch(
    `question[_${testDriveQuestions.PERFORMED}]`,
  );

  return (
    <>
      {questionIds.map((questionId) => {
        if (
          questionId !== testDriveQuestions.PERFORMED &&
          testDrivePerformed !== testDriveQuestions.PERFORMED_YES_RESPONSE
        ) {
          return null;
        }

        return (
          <div
            className={cn({ [styles.questionWrapper]: section === 'findings' })}
            key={questionId}
          >
            <TestDriveQuesitonsTree
              question={findNestedQuestionById(questions, questionId)}
              qaIdPrefix={qaIdPrefix}
              section={section}
            />
          </div>
        );
      })}
    </>
  );
};

export { TestDrivePageSection };
