import React from 'react';
import { apm } from '@elastic/apm-rum';

const REACT_APP_TRANSLATOR_VERSION =
  process.env.REACT_APP_TRANSLATOR_VERSION ?? '4.83.0';

const TRANSLATOR_SCRIPT = `https://static.auto1.com/@auto1/auto1-platform/${REACT_APP_TRANSLATOR_VERSION}/js/vendor/translator.min.js`;
const TRANSLATOR_SCRIPT_START =
  'https://static.auto1.com/@auto1/auto1-platform/';
const TRANSLATOR_SCRIPT_END = '/js/vendor/translator.min.js';

const getMessageScript = (lang: string) =>
  `https://static.auto1.com/@auto1/translations/translations-messages.${lang}.js`;

const loadScriptFile = (scriptName: string, scriptUrl: string) => {
  return new Promise<void>((resolve, reject) => {
    const id = `script-${scriptName}`;

    const existingElement = document.getElementById(id);
    if (existingElement) {
      console.warn(`script "${id}" exists`);
      return;
    }

    const js = document.createElement('script');
    js.type = 'text/javascript';
    js.id = id;
    js.async = true;

    js.addEventListener('load', () => {
      resolve();
    });
    js.addEventListener('error', (e: ErrorEvent) => {
      reject(e);
    });
    js.src = scriptUrl;
    document.body.appendChild(js);
  });
};

const loadTranslatorScript = () => {
  if (
    typeof window?.Translator === 'object' &&
    typeof window?.Translator?.trans === 'function'
  ) {
    return Promise.resolve();
  }
  return loadScriptFile('translator', TRANSLATOR_SCRIPT);
};

const loadLanguageScript = ({ language }: { language: string }) => {
  return loadScriptFile(`language-${language}`, getMessageScript(language));
};

const findScript = () => {
  const query = `script[src^="${TRANSLATOR_SCRIPT_START}"][src$="${TRANSLATOR_SCRIPT_END}"]`;
  const script = document.querySelector(query);

  return script;
};

const waitForTranslator = () => {
  let countAttempts = 0;

  return new Promise<void>((resolve, reject) => {
    const intervalId = setInterval(() => {
      if (
        typeof window?.Translator === 'object' &&
        typeof window?.Translator?.trans === 'function'
      ) {
        clearInterval(intervalId);
        resolve();
      } else {
        countAttempts += 1;
        if (countAttempts === 5) {
          clearInterval(intervalId);
          reject();
        }
      }
    }, 100);
  });
};

const useTranslationLoader = ({ language }: { language: string }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const loadScript = React.useCallback(async () => {
    try {
      setLoading(true);
      const script = findScript();
      if (script) {
        await waitForTranslator();
        return;
      }
      await loadTranslatorScript();
      window.Translator.locale = language;
      await loadLanguageScript({ language });
      await waitForTranslator();
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [language]);

  React.useEffect(() => {
    loadScript();
  }, [loadScript]);

  return {
    loading,
    error,
  };
};

export { useTranslationLoader };
