import * as yup from 'yup';
import type { ValidationError } from 'yup';
import type { Translations } from './translations';

export const getRequiredMessage = (
  translations: Translations,
  fieldType?: 'toggle' | 'file',
) => {
  if (fieldType === 'toggle') return translations.CHOOSE_ONE_OPTION;
  if (fieldType === 'file') return translations.AT_LEAST_ONE_FILE_IS_REQUIRED;
  return translations.THIS_FIELD_CANNOT_BE_EMPTY;
};

export const requiredString = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  if (!optionalSchema) {
    return yup.string().required(getRequiredMessage(translations));
  }
  return yup.string();
};

export const requiredNum = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  if (!optionalSchema) {
    return yup.number().integer().required(getRequiredMessage(translations));
  }

  return yup.number().integer();
};

export const requiredBoolean = (optionalSchema: boolean) => {
  if (optionalSchema) return yup.boolean().required().default(false);

  return yup.boolean().default(false);
};

export const requiredToggle = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  if (!optionalSchema) {
    return yup.boolean().required(getRequiredMessage(translations, 'toggle'));
  }
  return yup.boolean();
};

export const requiredArray = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  if (optionalSchema) {
    return yup.array();
  }
  return yup
    .array()
    .test(
      'required',
      getRequiredMessage(translations),
      (value) => (value?.length ?? 0) > 0,
    );
};

export const requiredUrl = (optionalSchema: boolean, message?: string) => {
  if (optionalSchema) {
    return yup.string().nullable();
  }
  return yup.string().nullable().required(message);
};

export const yupValidationErrorsToFormErrors = (
  validationError: ValidationError,
) =>
  validationError?.inner?.reduce(
    (
      allErrors: Record<string, { type: string; message: string }>,
      currentError: ValidationError,
    ) => {
      if (!currentError.path) {
        return allErrors;
      }

      return {
        ...allErrors,
        [currentError.path]: {
          type: currentError.type ?? 'validation',
          message: currentError.message,
        },
      };
    },
    {},
  );
