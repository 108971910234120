import type { Translations } from 'shared/utils/translations';

export const SERVICE_BOOK_AVAILABLE_PAPER = 'PAPER';
export const SERVICE_BOOK_AVAILABLE_DIGITAL = 'DIGITAL';
export const SERVICE_BOOK_AVAILABLE_UNAVAILABLE = 'UNAVAILABLE';

export const SERVICE_BOOK_APPOINTMENTS_ALL = 'ALL';
export const SERVICE_BOOK_APPOINTMENTS_SOME = 'SOME';
export const SERVICE_BOOK_APPOINTMENTS_NONE = 'NONE';
export const SERVICE_BOOK_APPOINTMENTS_NOT_DUE_YET = 'NOT_DUE_YET';

export const SERVICE_BOOK_AREA = 'DOCUMENTS';
export const SERVICE_BOOK_PART = 'SERVICE_BOOK';

export const REPAIRER_OEM_FRANCHISE_DEALERSHIP = 'OEM_FRANCHISE_DEALERSHIP';
export const REPAIRER_INDEPENDENT = 'INDEPENDENT';
export const REPAIRER_UNKNOWN = 'UNKNOWN';

export const OTHER_INSPECTION_COMPANY_ID = 162;

export const ACCIDENT_REPAIRED = 'REPAIRED';
export const ACCIDENT_NOT_REPAIRED = 'NOT_REPAIRED';

export const ACCIDENT_REPAIRERS = (translations: Translations) => [
  {
    value: REPAIRER_OEM_FRANCHISE_DEALERSHIP,
    label: translations.OEM_FRANCHISE_DEALERSHIP,
  },
  {
    value: REPAIRER_INDEPENDENT,
    label: translations.REPAIRER_INDEPENDENT,
  },
  {
    value: REPAIRER_UNKNOWN,
    label: translations.REPAIRER_UNKNOWN,
  },
];
