import isMobileDevice from 'is-mobile';

function isAndroidDevice() {
  return window.navigator.userAgent.match(/Android/) !== null;
}

function useMobileDetector() {
  return {
    isMobile: isMobileDevice(),
    isAndroid: isAndroidDevice(),
  };
}

export { useMobileDetector };
