import * as React from 'react';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { NavigationFooterPlaceholder } from 'shared/components/NavigationFooter/Placeholder';
import { EquipmentPlaceholder } from './Equipment/Placeholder';
import { OtherInformationPlaceholder } from './OtherInformation/Placeholder';

const Placeholder: React.FC = () => (
  <LayoutContainer>
    <EquipmentPlaceholder />
    <OtherInformationPlaceholder />
    <NavigationFooterPlaceholder />
  </LayoutContainer>
);

export { Placeholder };
