import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';
import { Typography } from 'shared/components/Typography';
import { Card } from '../Card';
import styles from './index.module.scss';

const Placeholder: React.FC = () => (
  <Card
    paddingTop={24}
    paddingBottom={24}
    externalStyle={styles.card}
    showBottomBorder
    qaIdPrefix="car-information-top-banner-loader"
  >
    <div className={styles.imageContainer}>
      <Skeleton width="100%" height="100%" />
    </div>
    <div className={styles.contentContainer}>
      <Typography variant="titleXL" tag="h2">
        <Skeleton width="400px" height="100%" />
      </Typography>
      <Typography variant="textSmall" tag="h6">
        <Skeleton width="100px" height="100%" />
      </Typography>
      <Typography variant="textSmall" tag="h6">
        <Skeleton width="100px" height="100%" />
      </Typography>
    </div>
  </Card>
);

export { Placeholder };
