import omit from 'lodash/omit';
import type { TestDriveInitialDataQuery } from 'gql/graphql';
import type { Question, FormMileage, FormQuestion } from './types';

export const IGNORED_QUESTIONS = [11130] as const;
export const FIRST_QUESTION = 11000;

export const findNestedQuestionById = (
  questions: ReadonlyArray<Question> | null | undefined,
  questionId: number,
): Question | null => {
  if (!questions) {
    return null;
  }

  for (let i = 0; i < questions.length; i += 1) {
    if (questions[i].id === questionId) {
      return questions[i];
    }

    const nestedQuestion = findNestedQuestionById(
      questions[i].options,
      questionId,
    );

    if (nestedQuestion) {
      return nestedQuestion;
    }
  }

  return null;
};

const mapQuestionToAnswer = (
  question: Question | null = null,
  answersId: number[],
): Record<string, number | boolean | null> => {
  if (question === null || !Array.isArray(question.options)) return {};

  if (question.allowedOptions > 1) {
    return question.options.reduce((acc, option) => {
      return {
        ...acc,
        [`_${option.id}`]: answersId.some((answerId) => answerId === option.id),
        [`_${question.id}`]: null,
      };
    }, {});
  }

  if (question.allowedOptions === 0) {
    // eslint-disable-next-line no-use-before-define
    return mapQuestionsToAnswer(question.options, answersId);
  }

  if (question.allowedOptions === 1) {
    const answer = question.options.find((option) =>
      answersId.some((answerId) => answerId === option.id),
    );

    return {
      [`_${question.id}`]: answer?.id ?? null,
      ...mapQuestionToAnswer(answer, answersId),
    };
  }

  return {};
};

const getMileage = (data: TestDriveInitialDataQuery) => {
  const value = data?.inspection?.vehicle?.condition?.mileage;

  return {
    currentMileage: value ?? null,
    ...mapMileageManipulationSuspiciousToForm(data?.inspection),
  };
};

const mapQuestionsToAnswer = (
  questions: ReadonlyArray<Question>,
  answersIds: number[],
): Record<string, number | boolean | null> =>
  questions.reduce(
    (acc, question) => ({
      ...acc,
      ...mapQuestionToAnswer(question, answersIds),
    }),
    {},
  );

const mapMileageManipulationSuspiciousToForm = (
  inspection: TestDriveInitialDataQuery['inspection'],
):
  | {
      isMileageManipulated: null;
    }
  | {
      isMileageManipulated: boolean;
      mileageSuspicions: {
        isMileageConflictingCarCondition: boolean | null;
        isOdometerBroken: boolean | null;
        isMileageConflictingServiceHistory: boolean | null;
        isMileageConflictingDatabase: boolean | null;
        isTachometerReplaced: boolean | null;
        tachometerReplacementMileage?: number | null;
        isTachometerReplacementMileageAvailable?: boolean;
      };
    } => {
  if (!inspection) {
    return {
      isMileageManipulated: null,
    };
  }

  const evaluation = inspection?.meta?.evaluation;
  const isMileageManipulated = evaluation?.isMileageManipulated ?? null;
  const isTachometerReplaced =
    evaluation?.mileageManipulationSuspicions?.isTachometerReplaced ?? null;

  if (isMileageManipulated === null) {
    return {
      isMileageManipulated: null,
    };
  }

  return {
    isMileageManipulated,
    mileageSuspicions: {
      isMileageConflictingCarCondition:
        evaluation?.mileageManipulationSuspicions
          ?.isMileageConflictingCarCondition ?? null,
      isOdometerBroken:
        evaluation?.mileageManipulationSuspicions?.isOdometerBroken ?? null,
      isMileageConflictingServiceHistory:
        evaluation?.mileageManipulationSuspicions
          ?.isMileageConflictingServiceHistory ?? null,
      isMileageConflictingDatabase:
        evaluation?.mileageManipulationSuspicions
          ?.isMileageConflictingDatabase ?? null,
      isTachometerReplaced,
      ...(isTachometerReplaced
        ? {
            tachometerReplacementMileage:
              evaluation?.mileageManipulationSuspicions
                ?.tachometerReplacementMileage ?? null,
            isTachometerReplacementMileageAvailable:
              evaluation?.mileageManipulationSuspicions
                ?.isTachometerReplacementMileageAvailable === false
                ? true
                : false,
          }
        : {}),
    },
  };
};

export const mapDataToFormValues = (data: TestDriveInitialDataQuery) => {
  const questions = data?.testDriveQuestions || [];
  const answers = data?.inspection?.meta?.testDrive?.answers || [];
  const mappedAnswers = mapQuestionsToAnswer(questions, answers);
  const form = {
    mileage: getMileage(data),
    question: omit(
      mappedAnswers,
      IGNORED_QUESTIONS.map((id) => `_${id}`),
    ),
  };
  return { form, questions };
};
export const mapAnswersToMutation = (formValues: FormQuestion) => {
  const result: number[] = [];
  const values = Object.entries(formValues);

  values.forEach((item) => {
    const [key, value] = item;
    if (!value) return false;

    // checkbox question
    if (value === true) {
      return result.push(Number(key));
    }

    return result.push(Number(value));
  });

  return result;
};

export const mapMileageManipulationSuspicionsToMutation = (
  mileage: FormMileage,
) => {
  if (!mileage.isMileageManipulated) {
    return null;
  }

  const {
    mileageSuspicions: {
      isTachometerReplacementMileageAvailable,
      tachometerReplacementMileage,
      ...mileageSuspicions
    },
  } = mileage;

  return {
    ...mileageSuspicions,
    ...(mileageSuspicions.isTachometerReplaced
      ? {
          isTachometerReplacementMileageAvailable:
            !isTachometerReplacementMileageAvailable,
          tachometerReplacementMileage,
        }
      : {}),
  };
};
