import * as React from 'react';
import { Button } from '@auto1-ui/button';
import { useTranslation } from 'shared/hooks/useTranslation';
import Loader from 'shared/components/Loader';
import { Grid } from 'shared/components/Grid';
import { FormInput } from 'shared/components/FormInput';
import { Typography } from 'shared/components/Typography';
import styles from '../../index.module.scss';

type Props = {
  qaIdPrefix?: string;
  serverError?: string;
  isSubmitting?: boolean;
  branchId: number;
  handleLoadCarData: () => void;
  handleUseVin: () => void;
};

const LicensePlateInfo: React.FC<Props> = ({
  qaIdPrefix = 'license-plate-info-page',
  serverError = 'server error',
  isSubmitting = false,
  branchId,
  handleLoadCarData,
  handleUseVin,
}) => {
  const { translations } = useTranslation();

  React.useEffect(() => {
    window.tracking.eva.openAuto1EVALicensePlateScreen({
      branch: branchId && `${branchId}`,
    });
  }, [branchId]);

  return (
    <>
      <Grid item>
        <FormInput
          label={translations.LICENSE_PLATE}
          name="licensePlate"
          qaIdPrefix={`${qaIdPrefix}-license-plate`}
          className={styles.input}
          placeholder={translations.LICENSE_PLATE}
          defaultValue=""
        />
        <Typography tag="p" variant="textXSRed">
          {serverError}
        </Typography>
      </Grid>
      <Button
        theme="orange"
        extraClass={styles.button}
        isDisabled={isSubmitting}
        onClick={handleLoadCarData}
        qaId={`${qaIdPrefix}-load-car-data`}
      >
        {translations.LOAD_CAR_DATA}
        {isSubmitting && <Loader size={16} />}
      </Button>
      <Typography
        variant="textSmall"
        additonalClassNames={styles.useVinText}
        data-qa-id={`${qaIdPrefix}-use-vin-text`}
      >
        {translations.DO_NOT_HAVE_LICENSE_PLATE}{' '}
        <Button
          theme="link"
          extraClass={styles.useVinLink}
          isDisabled={isSubmitting}
          onClick={handleUseVin}
          qaId={`${qaIdPrefix}-use-vin-instead-link`}
        >
          {translations.USE_VIN_INSTEAD}
        </Button>
      </Typography>
    </>
  );
};

export { LicensePlateInfo };
