import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import { allCountries } from 'shared/utils/allCountries';
import type { Translations } from 'shared/utils/translations';

export const inventoryTypeOptions = (translation: Translations) => [
  {
    label: translation.TRADE_IN,
    value: 'TRADE_IN',
  },
  {
    label: translation.LEASING,
    value: 'LEASING',
  },
  {
    label: translation.RENTAL,
    value: 'RENTAL',
  },
  {
    label: translation.NEW,
    value: 'NEW',
  },
  {
    label: translation.DAMAGED,
    value: 'DAMAGED',
  },
  {
    label: translation.FLEET,
    value: 'FLEET',
  },
  {
    label: translation.OTHER,
    value: 'OTHER',
  },
];

export const commercialUseOptions = (translation: Translations) => [
  {
    label: translation.NOT_IN_COMMERCIAL_USE,
    value: 'NO',
  },
  {
    label: translation.DRIVING_SCHOOL,
    value: 'DRIVING_SCHOOL',
  },
  {
    label: translation.RENTAL,
    value: 'RENTAL',
  },
  {
    label: translation.AUTHORITIES,
    value: 'AUTHORITIES',
  },
  {
    label: translation.COMPANY_CAR,
    value: 'COMPANY_CAR',
  },
  {
    label: translation.TAXI,
    value: 'TAXI',
  },
];

export const envClassOptions = [
  {
    label: 'EURO1',
    value: 'EURO1',
  },
  {
    label: 'EURO2',
    value: 'EURO2',
  },
  {
    label: 'EURO3',
    value: 'EURO3',
  },
  {
    label: 'EURO4',
    value: 'EURO4',
  },
  {
    label: 'EURO5',
    value: 'EURO5',
  },
  {
    label: 'EURO6',
    value: 'EURO6',
  },
  {
    label: 'N/A',
    value: 'N/A',
  },
];

export const vatOptions = (translation: Translations) => [
  {
    label: translation.NOT_DEDUCTIBLE,
    value: 'NOT_DEDUCTIBLE',
  },
  {
    label: translation.DEDUCTIBLE,
    value: 'DEDUCTIBLE',
  },
];

export const currentOwnerOptions = (translations: Translations) => [
  {
    label: translations.CURRENT_OWNER_TYPE_PRIVATE,
    value: 'PRIVATE',
  },
  {
    label: translations.CURRENT_OWNER_TYPE_COMPANY,
    value: 'COMPANY',
  },
  {
    label: translations.CURRENT_OWNER_TYPE_UNKNOWN,
    value: 'UNKNOWN',
  },
];

export const getCountryOfOriginsOptions = (
  countryOfOrigin?: string,
  branchCountry?: string,
  translate = (key: string) => window.Translator.trans(key),
) => {
  const options = Object.keys(allCountries).map((value) => {
    const translationKey = `global.country-code-${value}`;
    const label = translate(translationKey);
    const hasTranslation = translationKey !== label;
    let weight = hasTranslation ? 0 : 1;
    if (value === countryOfOrigin) {
      weight -= 2;
    } else if (value === branchCountry) {
      weight -= 1;
    }

    return {
      label: hasTranslation
        ? label
        : allCountries[value as keyof typeof allCountries],
      value,
      weight,
    };
  });

  return sortBy(options, ['weight', 'label']);
};

export const seatsOptions = range(10).map((i) => ({
  label: `${i}`,
  value: i,
}));

export const ownerOptions = [
  ...range(0, 11).map((i) => {
    return {
      label: `${i}`,
      value: `${i}`,
    };
  }),
  {
    label: 'N/A',
    value: 'N/A',
  },
];
