import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonThemes } from '@auto1-ui/button';
import styles from './index.module.scss';

const NotFoundPage = () => {
  const history = useHistory();

  const goHomeClickHandler = () => {
    history.push(`/`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.indicator}></div>
      <div className={styles.message}>PAGE NOT FOUND</div>
      <Button
        extraClass={styles.button}
        theme={ButtonThemes.orange}
        onClick={goHomeClickHandler}
      >
        GO TO HOME PAGE
      </Button>
    </div>
  );
};

export { NotFoundPage };
