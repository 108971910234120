import * as React from 'react';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import { NavigationFooterPlaceholder } from 'shared/components/NavigationFooter/Placeholder';
import { CarPictureSectionPlaceholder } from './components/CarPictureSection/CarPictureSectionPlaceholder';
import styles from './index.module.scss';

const Placeholder: React.FC = () => (
  <LayoutContainer>
    <Card paddingTop={32} paddingBottom={32} qaIdPrefix="car-pictures-loader">
      <Typography
        variant="titleXL"
        tag="div"
        additonalClassNames={styles.title}
      >
        <Skeleton width="115px" height="100%" />
      </Typography>
      <Typography tag="div" additonalClassNames={styles.subtitle}>
        <Skeleton width="448px" height="100%" />
      </Typography>
      <CarPictureSectionPlaceholder />
      <CarPictureSectionPlaceholder size={8} />
      <CarPictureSectionPlaceholder size={3} />
      <CarPictureSectionPlaceholder size={1} />
    </Card>
    <NavigationFooterPlaceholder />
  </LayoutContainer>
);

export { Placeholder };
