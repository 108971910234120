import * as React from 'react';
import type { ValidationError } from 'yup';
import type { AccidentAreaOption } from 'gql/graphql';
import { useInspection } from 'shared/components/InspectionContext';
import { useTranslation } from 'shared/hooks/useTranslation';
import {
  getSectionProgressYup,
  getPageProgress,
} from 'shared/utils/calculateProgress';
import { RoutesEnum } from 'shared/utils/route';
import { getAccidentSchema } from '../schema';
import type { Form, Damage } from '../types';
import { ACCIDENT_NOT_REPAIRED, REPAIRER_UNKNOWN } from '../constants';
import { isDocumentsRequired } from '../utils';

function getMandatoryRepairFieldsCount(formValues: Form) {
  const { repair } = formValues;
  let total = 0;

  // Were the damages repaired?
  total += 1;

  // Who repaired the damage?
  if (repair.repairStatus && repair.repairStatus !== ACCIDENT_NOT_REPAIRED) {
    total += 1;
  }

  // Documents
  if (isDocumentsRequired(formValues)) {
    total += 1;
  }

  // Preinvested repair cost
  if (repair.repairedBy && repair.repairedBy !== REPAIRER_UNKNOWN) {
    total += 1;
  }

  // Is this car declared a total loss?
  total += 1;

  return total;
}

function getProgressFromErrors(formValues: Form, errors: ValidationError[]) {
  const accidentsProgress = getSectionProgressYup({
    errors: errors?.filter(({ path }) => path?.startsWith('accidents')) ?? [],
    totalFieldsNb: 1,
  });

  if (!formValues.accidents.hasHadAccident) {
    return {
      [RoutesEnum.ACCIDENTS_ACCIDENTS]: accidentsProgress,
      [RoutesEnum.ACCIDENTS_AREAS]: 100,
      [RoutesEnum.ACCIDENTS_REPAIR]: 100,
      [RoutesEnum.ACCIDENTS]: getPageProgress([accidentsProgress]),
    };
  }

  const areasProgress = getSectionProgressYup({
    errors: errors?.filter(({ path }) => path?.startsWith('areas')) ?? [],
    totalFieldsNb: 1,
  });

  const repairProgress = getSectionProgressYup({
    errors: errors?.filter(({ path }) => path?.startsWith('repair')) ?? [],
    totalFieldsNb: getMandatoryRepairFieldsCount(formValues),
  });

  return {
    [RoutesEnum.ACCIDENTS_ACCIDENTS]: accidentsProgress,
    [RoutesEnum.ACCIDENTS_AREAS]: areasProgress,
    [RoutesEnum.ACCIDENTS_REPAIR]: repairProgress,
    [RoutesEnum.ACCIDENTS]: getPageProgress([
      accidentsProgress,
      areasProgress,
      repairProgress,
    ]),
  };
}

function useProgress() {
  const { setProgress } = useInspection();
  const { translations, translate } = useTranslation();

  const getAccidentsProgress = React.useCallback(
    (
      formValues: Form,
      damages: Damage[],
      accidentAreaOptions: AccidentAreaOption[],
    ) => {
      let errors: ValidationError[] = [];
      try {
        getAccidentSchema(
          translations,
          damages,
          accidentAreaOptions,
          false,
          translate,
        ).validateSync(formValues, {
          abortEarly: false,
        });
      } catch (validationError) {
        const { inner } = validationError as ValidationError;
        errors = inner;
      }

      setProgress(getProgressFromErrors(formValues, errors));
    },
    [setProgress, translations, translate],
  );

  return getAccidentsProgress;
}

export { useProgress, getProgressFromErrors };
