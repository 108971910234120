const formatLocaleNumber = (number: number) => {
  const language = JSON.parse(
    window.localStorage.getItem('cache.language.eva') ?? 'null',
  );

  if (!language) return String(number);
  return new Intl.NumberFormat(language).format(number);
};

export { formatLocaleNumber };
