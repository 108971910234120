type QueueItem<Output> = {
  task: () => Promise<Output>;
  handler: {
    resolve: (output: Output) => void;
    reject: (error: Error) => void;
  };
};

class TaskPool<Output> extends EventTarget {
  #maxConcurrentTasks: number;
  #queue: Array<QueueItem<Output>>;
  #runningTaskCount: number;

  constructor(maxConcurrentTasks: number) {
    super();

    this.#maxConcurrentTasks = maxConcurrentTasks;
    this.#queue = [];
    this.#runningTaskCount = 0;
  }

  add(task: () => Promise<Output>) {
    const handler: {
      resolve: (output: Output) => void;
      reject: (error: Error) => void;
    } = {
      resolve: () => {},
      reject: () => {},
    };
    const promise = new Promise<Output>((resolveCallback, rejectCallback) => {
      handler.resolve = resolveCallback;
      handler.reject = rejectCallback;
    });

    this.#queue.push({ task, handler });
    this.#processQueue();

    return promise;
  }

  #processQueue() {
    while (
      this.#runningTaskCount < this.#maxConcurrentTasks &&
      this.#queue.length > 0
    ) {
      const {
        task,
        handler: { resolve, reject },
      } = this.#queue.shift() as QueueItem<Output>;

      this.#runningTaskCount += 1;
      task()
        .then(resolve)
        .catch(reject)
        .finally(() => {
          this.#runningTaskCount -= 1;
          this.#processQueue();

          this.#emitRunningTaskCountChange();
        });
      this.#emitRunningTaskCountChange();
    }
  }

  #emitRunningTaskCountChange() {
    this.dispatchEvent(
      new CustomEvent<number>('onRunningTaskCountChange', {
        detail: this.#runningTaskCount,
      }),
    );
  }
}

export { TaskPool };
