import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const useRegister = (name: string) => {
  const { register, unregister } = useFormContext();
  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [name, register, unregister]);
};

export { useRegister };
