import * as React from 'react';
import { useFieldArray } from 'react-hook-form';

import { Button } from '@auto1-ui/button';

import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';

import { CarQualityItemContainer } from '../components/CarQualityItemContainer';
import { HighlightItem } from '../components/HighlightItem';

import styles from './index.module.scss';

type Props = {
  inspectionId: number;
  className?: string;
  qaIdPrefix?: string;
};

const Highlights: React.FC<Props> = ({
  className = '',
  qaIdPrefix = 'auto1',
}) => {
  const { translations } = useTranslation();

  const {
    fields: highlights,
    append,
    remove,
  } = useFieldArray({
    name: 'highlights',
  });

  return (
    <Card
      paddingTop={32}
      paddingBottom={48}
      externalStyle={className}
      qaIdPrefix={`${qaIdPrefix}-container`}
      id="highlights"
    >
      <Typography
        variant="titleXL"
        data-qa-id={`${qaIdPrefix}-highlights-title`}
        tag="div"
        additonalClassNames={styles.title}
      >
        {translations.HIGHLIGHTS_HEADER}
      </Typography>
      {highlights.map((key, index) => (
        <CarQualityItemContainer
          key={key.id}
          title={`${translations.QUALITY_HIGHLIGHT} #${index + 1}`}
          initialState
          onDelete={() => remove(index)}
          qaIdPrefix={`${qaIdPrefix}-highlights-item-container-${index}`}
        >
          <HighlightItem
            index={index}
            fieldName={`highlights[${index}]`}
            qaIdPrefix={`${qaIdPrefix}-highlights-item-${index}`}
          />
        </CarQualityItemContainer>
      ))}
      <Button
        theme="outlined"
        isActivated={false}
        onClick={() => {
          append({
            files: [],
            area: null,
            part: null,
          });
        }}
        qaId={`${qaIdPrefix}-add-button`}
        extraClass={styles.button}
      >
        + {translations.HIGHLIGHTS_ADD_BUTTON}
      </Button>
    </Card>
  );
};

export { Highlights };
