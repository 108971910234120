import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';

type Props = {
  labelWidth?: string;
};

const FormTextareaPlaceholder: React.FC<Props> = ({ labelWidth = '100%' }) => (
  <div style={{ marginBottom: '6px' }}>
    <Skeleton
      width={labelWidth}
      height="19px"
      style={{ marginBottom: '8px', display: 'block' }}
    />
    <Skeleton width="100%" height="121px" />
  </div>
);

export { FormTextareaPlaceholder };
