import { authManager } from '../authManager';
import { GOOGLE_USER_ACCESS_KEY } from '../constants';

const getResponse = (success: boolean, error?: Error) => ({
  errorMessage: (error && error.message) || '',
  success,
  __typename: 'LogoutMutation',
});

export const logoutMutationResolver = () => {
  return (
    authManager(GOOGLE_USER_ACCESS_KEY)
      .logout()
      // Destroy local storage on logout to remove any user preferences
      .then(() => localStorage.clear())
      .then(() => getResponse(true))
      .catch((err) => getResponse(false, err))
  );
};
