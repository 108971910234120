import * as React from 'react';
import styles from './index.module.scss';

const Trunk: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g className={styles.stroke} strokeWidth="1.5" mask="url(#prefix__d)">
          <g>
            <path
              d="M72.99 0l-.001.002 1.758.024C88.258.231 96.112.873 98.31 1.953l.076.039c3.279 1.733 24.91 1.105 26.948 4.202 2.039 3.097 19.42 43.835 19.42 54.586l.003 1.162c.07 10.54.93 23.417-5.76 39.098-2.09 3.694-23.263 5.847-63.518 6.459l-2.7.038-2.488-.035c-40.255-.615-61.427-2.768-63.517-6.462-6.938-16.262-5.757-29.508-5.757-40.26 0-10.751 17.38-51.489 19.419-54.586 2.017-3.065 23.221-2.482 26.84-4.149l.108-.053C49.42.915 56.982.264 70.065.042l2.714-.04V0h.21z"
              transform="translate(15.556 11.731)"
            />
            <path
              d="M72.985 3.91v.003l1.687.022c12.963.19 20.498.785 22.607 1.787l.073.036c3.145 1.606 23.899 1.024 25.855 3.896 1.956 2.872 18.632 40.647 18.632 50.616l.004 1.078c.067 9.773.891 21.713-5.528 36.254-2.005 3.426-22.32 5.422-60.942 5.99l-2.59.035-2.387-.033c-38.623-.57-58.938-2.566-60.943-5.992C2.796 82.522 3.929 70.24 3.929 60.27c0-9.969 16.676-47.744 18.632-50.616 1.936-2.842 22.28-2.301 25.752-3.847l.104-.05c1.955-.999 9.21-1.601 21.763-1.808l2.603-.036V3.91h.202z"
              transform="translate(15.556 11.731)"
            />
            <path
              strokeLinecap="round"
              d="M34.298 7.82c-5.235 9.36-6.49 14.762-3.764 16.206 4.089 2.166 79.559 1.323 81.897.661 2.338-.661 4.646-1.776-2.91-16.866"
              transform="translate(15.556 11.731)"
            />
            <path
              d="M29.134 28.35h86.206c.795 2.386.117 4.189-2.034 5.41-3.227 1.834-25.129 5.344-39.107 5.344-13.979 0-38.29-2.808-41.678-4.914-2.258-1.405-3.387-3.35-3.387-5.84zM78.048 46.925c7.802.025 11.574.171 24.229 1.052 16.275 14.662 32.848 39.321 29.47 42.15-8.66 5.229-28.74 5.679-58.94 5.679-30.202 0-50.298-.555-58.787-5.678-3.377-2.83 13.197-27.49 29.471-42.15l5.647-.383c8.377-.544 12.08-.65 18.583-.67h10.327z"
              transform="translate(15.556 11.731)"
            />
            <path
              strokeLinecap="round"
              d="M4.829 53.563c.064.512 1.248.067 3.55-1.336 2.303-1.403 3.963-3.01 4.98-4.819 3.509-23.388 8.767-36.258 15.775-38.61M138.995 52.587c-.063.51-1.212.066-3.448-1.33-2.236-1.396-3.847-2.994-4.834-4.795-3.23-23.42-8.246-36.301-15.051-38.641M43.717 48.881L39.829 38.127"
              transform="translate(15.556 11.731)"
            />
            <path
              strokeLinecap="round"
              d="M105.94 48.881L102.051 38.127"
              transform="translate(15.556 11.731) matrix(-1 0 0 1 207.99 0)"
            />
            <path
              strokeLinecap="round"
              d="M30.106 24.44L15.523 36.172"
              transform="translate(15.556 11.731)"
            />
            <path
              strokeLinecap="round"
              d="M129.273 25.418L113.717 36.172"
              transform="translate(15.556 11.731) matrix(-1 0 0 1 242.99 0)"
            />
            <path
              strokeLinecap="round"
              d="M30.106 32.261L14.551 43.993"
              transform="translate(15.556 11.731)"
            />
            <path
              strokeLinecap="round"
              d="M130.245 32.261L114.69 43.015"
              transform="translate(15.556 11.731) matrix(-1 0 0 1 244.935 0)"
            />
            <path
              strokeLinecap="round"
              d="M12.606 88.963C6.074 72.324 5.028 60.593 9.47 53.769M132.19 88.963c7.384-16.672 8.76-28.403 4.125-35.194"
              transform="translate(15.556 11.731)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { Trunk };
