import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  qaIdPrefix?: string;
};

const DeleteButton: React.FC<Props> = ({
  className,
  qaIdPrefix = 'area-delete-button',
  ...props
}) => (
  <button
    className={cn(styles.areaButtonButton, className)}
    data-qa-id={qaIdPrefix}
    {...props}
  >
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
        fill="#358CCB"
      />
    </svg>
  </button>
);

export { DeleteButton };
