import * as yup from 'yup';
import type { Translations } from 'shared/utils/translations';
import {
  getRequiredMessage,
} from 'shared/utils/yupValidationHelpers';

const requiredDamageOption = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  const schema = optionalSchema
    ? yup.string().nullable()
    : yup.string().nullable().required(getRequiredMessage(translations));

  return schema.test('required', getRequiredMessage(translations), (value) => {
    if (optionalSchema) {
      return true;
    }
    if (value === 'GENERAL') {
      return false;
    }

    return !!value;
  });
};

const filesArray = (translations: Translations, optionalSchema: boolean) => {
  if (!optionalSchema) {
    return yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            'isFile',
            translations.AT_LEAST_ONE_FILE_IS_REQUIRED,
            (value) => {
              return value instanceof File || value?.absoluteUrl;
            },
          ),
      )
      .test('required', getRequiredMessage(translations, 'file'), (value) =>
        value !== undefined ? value.length > 0 : false,
      );
  }
  return yup.array().of(
    yup
      .mixed()
      .test('isFile', translations.AT_LEAST_ONE_FILE_IS_REQUIRED, (value) => {
        return value instanceof File || value?.absoluteUrl;
      }),
  );
};

const getDamagesSchema = (
  translations: Translations,
  optionalSchema: boolean,
) => {
  return yup.object({
    damages: yup.array().of(
      yup.object().shape({
        area: requiredDamageOption(optionalSchema, translations),
        part: requiredDamageOption(optionalSchema, translations),
        type: requiredDamageOption(optionalSchema, translations),
        severity: yup.string().notRequired(),
        isAccidentReason: yup.boolean().default(false),
        files: filesArray(translations, optionalSchema),
        source: yup.string().nullable().notRequired(),
      }),
    )
  });
};

export { getDamagesSchema };
