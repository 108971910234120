import { viewerResolver } from './viewerResolver';
import { logoutMutationResolver } from './logoutMutationResolver';
import { loginLocalMutationResolver } from './loginLocalMutationResolver';
import { loginWithGoogleCodeMutationResolver } from './loginWithGoogleCodeMutationResolver';

export default {
  Query: {
    viewer: viewerResolver,
  },
  Mutation: {
    loginWithGoogleCode: loginWithGoogleCodeMutationResolver,
    loginLocal: loginLocalMutationResolver,
    logout: logoutMutationResolver,
  },
};
