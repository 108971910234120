import * as React from 'react';
import cn from 'classnames';
import { Button, ButtonTypes, ButtonThemes } from '@auto1-ui/button';
import { Dialog } from '@auto1-ui/dialog';
import { keyExtractor } from '../../utils/keyExtractor';
import styles from './index.module.scss';

type Props = {
  source: { id?: string; absoluteUrl: string } | File;
  qaIdPrefix?: string;
  buttons?: ReadonlyArray<{
    onClick: () => void;
    label: string;
    theme?: ButtonThemes;
    alignment?: 'left' | 'right';
    type?: ButtonTypes;
  }>;
  handleClose: () => void;
  isOpen: boolean;
};

function addRawPrefixToPdfUrl(url: string) {
  const parts = url.split('/');
  return [...parts.slice(0, -1), `raw-${parts[parts.length - 1]}`].join('/');
}

const PdfPreview: React.FC<Props> = ({
  source,
  buttons = [],
  qaIdPrefix = 'pdf-preview',
  isOpen,
  handleClose,
}) => {
  return (
    <Dialog isOpen={isOpen} handleClose={handleClose} isCloseIconVisible>
      <div
        className={cn(styles.pdfPreview, {
          noButtons: buttons.length === 0,
        })}
      >
        <div className={styles.content}>
          <iframe
            className={styles.preview}
            src={
              source instanceof File
                ? URL.createObjectURL(source)
                : addRawPrefixToPdfUrl(source.absoluteUrl)
            }
            data-qa-id={`${qaIdPrefix}-iframe`}
          ></iframe>
        </div>

        {buttons.length > 0 && (
          <div className={styles.footer}>
            <span className={styles.buttonGroup}>
              {buttons
                .filter(
                  (button) => !button.alignment || button.alignment === 'left',
                )
                .map(({ theme, type, onClick, label, ...rest }, index) => (
                  <Button
                    qaId={`${qaIdPrefix}-left-btn-${index + 1}`}
                    theme={theme}
                    extraClass={styles.btn}
                    type={type ?? ButtonTypes.button}
                    onClick={onClick}
                    key={keyExtractor(rest, index)}
                  >
                    {label}
                  </Button>
                ))}
            </span>
            <span className={styles.buttonGroup}>
              {buttons
                .filter((button) => button.alignment === 'right')
                .map(({ theme, type, onClick, label, ...rest }, index) => (
                  <Button
                    qaId={`${qaIdPrefix}-right-btn-${index + 1}`}
                    extraClass={styles.btn}
                    theme={theme}
                    type={type ?? ButtonTypes.button}
                    onClick={onClick}
                    key={keyExtractor(rest, index)}
                  >
                    {label}
                  </Button>
                ))}
            </span>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export { PdfPreview };
