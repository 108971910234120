import * as React from 'react';
import { Button, ButtonTypes } from '@auto1-ui/button';
import { useTranslation } from 'shared/hooks/useTranslation';
import Loader from 'shared/components/Loader';

import styles from './index.module.scss';

type Props = {
  onClick: () => void;
  submitting: boolean;
  instantOfferButtonStatus: { [key: string]: boolean }
};

const InstantOfferButton: React.FC<Props> = ({
  onClick,
  submitting,
  instantOfferButtonStatus,
}) => {
  const { translations } = useTranslation();

  const instantPriceButtonProps = instantOfferButtonStatus.isDisabled
    ? {
      'data-microtip-position': 'top',
      'aria-label': translations.GET_INSTANT_PRICE_TOOLTIP,
      role: 'tooltip',
    }
    : {};

  return !instantOfferButtonStatus.isInvisible ? (
    <Button
      type={ButtonTypes.button}
      theme="blue"
      onClick={onClick}
      extraClass={styles.instantPriceButton}
      isDisabled={Boolean(instantOfferButtonStatus.isDisabled) || submitting}
      {...instantPriceButtonProps}
    >
      {translations.GET_INSTANT_PRICE}
      {submitting && <Loader extraClass={styles.instantPriceButtonLoader} />}
    </Button>
  ) : null;
};

export { InstantOfferButton };
