import { ApiResponseError } from '@pa/base-api-service';
import { authManager } from '../authManager';
import { GOOGLE_USER_ACCESS_KEY } from '../constants';

export const loginLocalMutationResolver = (
  _: unknown,
  { email, password }: { email: string; password: string },
) => {
  return authManager(GOOGLE_USER_ACCESS_KEY)
    .loginLocal({ email, password })
    .then(() => ({
      errorMessage: null,
      success: true,
      __typename: 'LoginLocalMutation',
    }))
    .catch((error) => {
      return {
        errorMessage:
          error instanceof ApiResponseError
            ? error.data.body.error_description
            : error.message,
        success: false,
        __typename: 'LoginLocalMutation',
      };
    });
};
