import * as React from 'react';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';

type Props = {
  isAndroid: boolean;
};

const MobilePage: React.FC<Props> = ({ isAndroid }) => {
  const { translations } = useTranslation();
  const appLink = isAndroid
    ? 'https://play.google.com/store/apps/details?id=com.auto1.inspectionapp'
    : 'https://apps.apple.com/de/app/auto1-eva-app/id1504637176';

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h2 className={styles.title}>{translations.MOBILE_PAGE_TITLE}</h2>
        <div className={styles.buttonWrapper}>
          <a href={appLink} className={styles.downloadButton}>
            {translations.MOBILE_PAGE_DOWNLOAD}
          </a>
          <p className={styles.description}>
            {translations.MOBILE_PAGE_DESCRIPTION}
          </p>
        </div>
      </div>
    </div>
  );
};

export { MobilePage };
