import * as React from 'react';
import kebabCase from 'lodash/kebabCase';

import type { VehicleImagePart } from 'gql/graphql';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';

import { CarPictureItem } from '../CarPictureItem';
import { CarPicturePlaceholder } from '../CarPicturePlaceholder';

import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  stockNumber: string;
  title: string;
  items: ReadonlyArray<{
    area?: string;
    label: string;
    sourceType?: 'image' | 'video';
  }>;
  optional?: boolean;
  id?: string;
};

const CarPictureSection: React.FC<Props> = ({
  title,
  items = [],
  qaIdPrefix = 'car-pictures',
  stockNumber,
  optional = false,
  id = '',
}) => {
  const { translations } = useTranslation();
  return (
    <div data-qa-id={`${qaIdPrefix}-section-container`} id={id}>
      <Typography
        tag="div"
        variant="titleL"
        additonalClassNames={styles.title}
        data-qa-id={`${qaIdPrefix}-section-title`}
      >
        {title}{' '}
        {optional && (
          <span className={styles.optionalLabel}>{translations.OPTIONAL}</span>
        )}
      </Typography>
      <div
        className={styles.grid}
        data-qa-id={`${qaIdPrefix}-section-grid-items`}
      >
        {items.map(({ area = '', label, sourceType = 'image' }) => (
          <CarPictureItem
            key={label}
            sourceType={sourceType}
            qaIdPrefix={`${qaIdPrefix}-${kebabCase(area)}`}
            mediaUrl={`/v1.1/car/${stockNumber}/image`}
            mediaData={{
              stockNumber,
              part: `vehicle__${area}`.toLowerCase() as VehicleImagePart,
            }}
            label={label}
            area={sourceType === 'video' ? `${area}__VIDEO` : area}
            className={styles.fullWidth}
            errorClassName="placeholderError"
            placeholder={
              <CarPicturePlaceholder
                area={sourceType === 'video' ? `${area}__VIDEO` : area}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

export { CarPictureSection };
