import * as React from 'react';
import type { CheckBeforeSubmitToAuctionQuery } from 'gql/graphql';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Typography } from 'shared/components/Typography';
import { Modal } from 'shared/components/Modal';
import { appRoutes } from 'appRoutes';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { lowerFirstChar } from 'shared/utils/strings';
import { CarCard } from './CarCard';
import styles from './index.module.scss';

type Inspection =
  CheckBeforeSubmitToAuctionQuery['checkBeforeSubmitToAuction']['relatedInspection'];

type Props = {
  onSecondaryButtonClick: () => void;
  secondaryButtonLabel?: string;
  handleClose?: () => void;
  inspectionInfo?: Inspection;
  isSubmitting?: boolean;
  isOpen?: boolean;
  vinStatus: string;
  currentStockNumber?: string;
  trackingEvents: {
    view: string;
    mainButtonClick: string;
    secondaryButtonClick: string;
  };
};

const RerunRemainingModal: React.FC<Props> = ({
  onSecondaryButtonClick,
  secondaryButtonLabel,
  handleClose = () => {},
  inspectionInfo,
  isSubmitting = false,
  isOpen = false,
  vinStatus,
  currentStockNumber = '',
  trackingEvents,
}) => {
  const { translations } = useTranslation();
  const containerDiv = React.useRef<HTMLDivElement>(null);
  const { car, vehicle } = inspectionInfo ?? {};
  const trackingOriginalStockNumber = car?.stockNumber;
  const trackingVin = vehicle?.identifiers?.vin;
  const trackingInspectionId = inspectionInfo?.inspectionId;
  const okClickHandler = () => {
    window.tracking.eva[lowerFirstChar(trackingEvents.mainButtonClick)]?.({
      originalStockNumber: trackingOriginalStockNumber,
      vin: trackingVin,
      stockNumber: currentStockNumber,
      inspectionId: trackingInspectionId,
    });
    window.open(
      vinStatus === 'RUNNING_AUCTION'
        ? appRoutes.runningAuctions()
        : appRoutes.auctionEnded(),
      '_blank',
    );
  };
  const cancelClickHandler = () => {
    window.tracking.eva[lowerFirstChar(trackingEvents.secondaryButtonClick)]?.({
      originalStockNumber: trackingOriginalStockNumber,
      vin: trackingVin,
      stockNumber: currentStockNumber,
      inspectionId: trackingInspectionId,
    });
    onSecondaryButtonClick();
  };

  React.useEffect(() => {
    window.tracking.eva[lowerFirstChar(trackingEvents.view)]?.({
      originalStockNumber: trackingOriginalStockNumber,
      vin: trackingVin,
      stockNumber: currentStockNumber,
      inspectionId: trackingInspectionId,
    });
  }, [
    trackingEvents.view,
    trackingOriginalStockNumber,
    trackingVin,
    currentStockNumber,
    trackingInspectionId,
  ]);

  useClickOutside(containerDiv, () => {
    handleClose();
  });

  return (
    <Modal
      isOpen={isOpen}
      className={styles.warningContainer}
      title={
        inspectionInfo
          ? translations.EXISTING_AUCTION_FOR_THIS_VIN
          : translations.EXISTING_AUCTION_TITLE
      }
      okLabel={translations.VIEW_AUCTION}
      onOk={okClickHandler}
      showOkButton={!isMobileEvaluatorApp() && Boolean(inspectionInfo)}
      okButtonExtraClass={styles.okButton}
      cancelLabel={secondaryButtonLabel ?? translations.CREATE_NEW_EVALUATION}
      cancelButtonExtraClass={styles.cancelButtonExtraClass}
      onCancel={cancelClickHandler}
      onClose={handleClose}
      isCancelButtonDisabled={isSubmitting}
      isOkButtonDisabled={isSubmitting}
      qaIdPrefix="car-information-warning-modal"
      ref={containerDiv}
    >
      {Boolean(inspectionInfo) && (
        <div>
          <Typography
            variant="textSmall"
            tag="div"
            data-qa-id="car-information-warning-title"
            additonalClassNames={styles.description}
          >
            <p>{translations.VIEW_EXISTING_AUCTION}</p>
            <p>
              <strong>{translations.VIEW_EXISTING_AUCTION_WARNING}:</strong>
              &nbsp;{translations.VIEW_EXISTING_AUCTION_WARNING_TEXT}
            </p>
          </Typography>
          <CarCard inspection={inspectionInfo} />
        </div>
      )}
    </Modal>
  );
};

export { RerunRemainingModal };
