import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { appRoutes } from 'appRoutes';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
};

const PageHeader: React.FC<Props> = ({ qaIdPrefix = 'page-header' }) => {
  const { translations } = useTranslation();
  const handleAddButtonClick = () => {
    window.tracking.eva.addNewBranchButton();
  };
  return (
    <div className={styles.headerWrapper}>
      <Typography variant="titleXL" tag="h1" data-qa-id={`${qaIdPrefix}-title`}>
        {translations.LOCATIONS_PAGE_TITLE}
      </Typography>

      <Link
        onClick={handleAddButtonClick}
        to={appRoutes.addLocation()}
        className={styles.addLocation}
        data-qa-id={`${qaIdPrefix}-add-location-button`}
      >
        {translations.ADD_LOCATION}
      </Link>
    </div>
  );
};

export { PageHeader };
