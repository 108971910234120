import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useProtectedRouteViewerQuery } from 'gql/graphql';
import { appRoutes } from 'appRoutes';
import { useTranslation } from 'shared/hooks/useTranslation';
import { PageLoader } from 'shared/components/PageLoader';
import { useUserPermissionsContext } from 'shared/components/PermissionsContext';
import { useUserActionsQuery } from 'gql/graphql';
import { ErrorMessagePage } from 'pages/ErrorMessagePage';
import { PermissionGuardedRoute } from '../PermissionGuardedRoute';

type Props = {
  permissions?: string[];
};

const MobileEvaluatorRoute: React.FC<Props> = ({ permissions, ...rest }) => {
  const { translations } = useTranslation();
  const { data, loading } = useProtectedRouteViewerQuery();
  const { userPermissions, setUserPermissions } = useUserPermissionsContext();
  const { loading: userActionsLoading, error } = useUserActionsQuery({
    skip: !!userPermissions || !data?.viewer,
    onCompleted: (result) => {
      if (result?.user?.actions) {
        setUserPermissions(result.user.actions);
      }
    },
  });

  if (error) {
    return <ErrorMessagePage component={translations.FAILED_LOADING_USER} />;
  }

  if (loading || userActionsLoading) {
    return <PageLoader message={translations.LOADING_INSPECTION_APP} />;
  }

  if (!data?.viewer) {
    return <Redirect to={appRoutes.login()} />;
  }

  if (!userPermissions) {
    return <PageLoader message={translations.LOADING_INSPECTION_APP} />;
  }

  if (permissions) {
    return (
      <PermissionGuardedRoute permissions={permissions}>
        <Route {...rest} />
      </PermissionGuardedRoute>
    );
  }

  return <Route {...rest} />;
};

export { MobileEvaluatorRoute };
