import * as React from 'react';

import { useTiresBrakesInitialDataQuery } from 'gql/graphql';
import { useProgress } from 'pages/TiresBrakes/hooks/useProgress';
import { graphqlToSchema } from 'pages/TiresBrakes/helpers';

function useTiresAndBrakesProgress(inspectionId: number) {
  const { data } = useTiresBrakesInitialDataQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setProgress = useProgress();
  const updateTiresBrakesProgress = React.useCallback(() => {
    setProgress(graphqlToSchema(data));
  }, [data, setProgress]);

  React.useEffect(() => {
    setProgress(graphqlToSchema(data));
  }, [data, setProgress]);

  return updateTiresBrakesProgress;
}

export { useTiresAndBrakesProgress };
