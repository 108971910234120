import * as React from 'react';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import { VinSearchPlaceholder } from './VinSearch/Placeholder';
import styles from './index.module.scss';

const VinInfoPlaceholder: React.FC = () => (
  <Card
    paddingTop={32}
    paddingBottom={32}
    showBottomBorder
    qaIdPrefix="car-details-page-vin-info-loader"
  >
    <div className={styles.title}>
      <Typography variant="titleXL">
        <Skeleton width="169px" height="100%" />
      </Typography>
    </div>
    <VinSearchPlaceholder />
  </Card>
);

export { VinInfoPlaceholder };
