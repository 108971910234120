import * as React from 'react';
import { Front } from './Front';
import { Rear } from './Rear';
import { ExteriorRight } from './ExteriorRight';
import { ExteriorLeft } from './ExteriorLeft';
import { Roof } from './Roof';
import type { VariationType } from '../types';

type Props = {
  areas: {
    FRONT: VariationType;
    LEFT: VariationType;
    RIGHT: VariationType;
    REAR: VariationType;
    ROOF: VariationType;
    UNDERBODY: VariationType;
    INTERIOR: VariationType;
  };
  qaIdPrefix?: string;
};

const CarImage: React.FC<Props> = ({
  areas,
  qaIdPrefix = 'car-image-area',
}) => (
  <svg
    width="504"
    height="800"
    viewBox="0 0 504 800"
    fill="none"
    data-qa-id={qaIdPrefix}
  >
    <defs>
      <style>
        {`
      .default path {
        stroke: #99A9D4;
      }
      .accident path, .damage path {
        fill: #C43546;
        fill-opacity: 0.15;
        stroke: #C43546;
      }
`}
      </style>
    </defs>
    <Roof variant={areas.ROOF} qaIdPrefix={qaIdPrefix} />
    <ExteriorRight variant={areas.RIGHT} qaIdPrefix={qaIdPrefix} />
    <Rear variant={areas.REAR} qaIdPrefix={qaIdPrefix} />
    <ExteriorLeft variant={areas.LEFT} qaIdPrefix={qaIdPrefix} />
    <Front variant={areas.FRONT} qaIdPrefix={qaIdPrefix} />
  </svg>
);

export { CarImage };
