import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePiConvertInspectionToFullEvaMutation } from 'gql/graphql';
import { PageLoader } from 'shared/components/PageLoader';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { appRoutes } from 'appRoutes';
import styles from './index.module.scss';

const ConvertToFullEva: React.FC = () => {
  const history = useHistory();
  const { inspectionId: stockNumber } = useParams<{ inspectionId: string }>();
  const { translations } = useTranslation();
  const [hasError, setHasError] = React.useState(false);
  const [piConvertInspectionToFullEva] =
    usePiConvertInspectionToFullEvaMutation({
      errorPolicy: 'none',
      onCompleted: (data) => {
        const inspectionId = data?.piConvertInspectionToFullEva?.inspectionId;

        if (inspectionId) {
          history.push(appRoutes.carDetails(`${inspectionId}`, true));
        } else {
          setHasError(true);
        }
      },
      onError: () => {
        setHasError(true);
      },
    });

  React.useEffect(() => {
    piConvertInspectionToFullEva({
      variables: {
        stockNumber,
      },
    });
  }, []);

  if (hasError) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.errorContainer}>
          <Typography variant="textXSRed">
            <>{translations.INVALID_STOCK_NUMBER}</>
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <PageLoader
      className={styles.loader}
      message={translations.LOADING_INSPECTION_APP}
    />
  );
};

export { ConvertToFullEva };
