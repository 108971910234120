import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import { useGetLocationsListQuery } from 'gql/graphql';
import { Card } from 'shared/components/Card';
import { SuccessAlert } from 'pages/LocationsList/components/successAlert';
import { PageHeader } from './components/pageHeader/index';
import { LocationsList } from './components/LocationsList';

const LocationsListPage: React.FC = () => {
  const { data: LocationsData, loading } = useGetLocationsListQuery();
  const [isOpenedFromMenu, setIsOpenedFromMenu] = React.useState(false);
  const location = useLocation();
  const history = useHistory();

  const state = location.search.match(/(state=)([\w%]+)/);
  const stateValue = state ? state[2] : null;

  const locationName = React.useRef(stateValue);

  React.useEffect(() => {
    setIsOpenedFromMenu(location.search.includes('ref=menuClick'));
    history.replace(location.pathname);
  }, []); //eslint-disable-line

  React.useEffect(() => {
    if (LocationsData) {
      window.tracking.eva.openBranchesList({
        menuClick: isOpenedFromMenu ? 'yes' : 'no',
        branchesCount: LocationsData.userBranches.length,
      });
    }
  }, [LocationsData, isOpenedFromMenu]);

  return (
    <>
      {locationName.current && <SuccessAlert />}

      <Card paddingTop={32} paddingBottom={32}>
        <PageHeader />

        {loading && 'loading'}
        {!loading && <LocationsList locations={LocationsData?.userBranches} />}
      </Card>
    </>
  );
};

const LocationsListPageWithTransaction = withTransaction(
  'LocationsListPage',
  'component',
)(LocationsListPage);

export { LocationsListPageWithTransaction as LocationsListPage };
