import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonTypes, ButtonThemes } from '@auto1-ui/button';
import { Input } from '@auto1-ui/input';
import { appRoutes } from 'appRoutes';
import {
  useGetInspectionByStockNumberLazyQuery,
  GetInspectionByStockNumberQuery,
} from 'gql/graphql';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { userActions } from 'shared/constants';
import { PermissionGuard } from 'shared/components/PermissionGuard';
import styles from './index.module.scss';

type Inspection = NonNullable<GetInspectionByStockNumberQuery['search']>;

type Props = {
  qaIdPrefix: string;
  filterValue: string;
  setFilterValue: (value: string) => void;
  handleOpenInspection: (_: Inspection) => void;
};

const Header: React.FC<Props> = ({
  qaIdPrefix,
  filterValue,
  setFilterValue,
  handleOpenInspection,
}) => {
  const { translations } = useTranslation();

  const [getInspectionByStockNumber] = useGetInspectionByStockNumberLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { inspectionId } = data?.search ?? {};
      if (inspectionId) {
        handleOpenInspection(data?.search as Inspection);
      }
    },
  });

  const handleSearch = () => {
    if (filterValue.trim()) {
      getInspectionByStockNumber({
        variables: {
          stockNumberOrVin: filterValue.trim(),
        },
      });
    }
  };

  return (
    <div data-qa-id={`${qaIdPrefix}-header`}>
      <Typography variant="titleXL" tag="h1" data-qa-id={`${qaIdPrefix}-title`}>
        {translations.INSPECTIONS}
      </Typography>
      <div className={styles.actions}>
        <Input
          qaTag={`${qaIdPrefix}-stock-number-search`}
          extraClass={styles.input}
          placeholder={translations.SEARCH_BY_STOCK_NUMBER}
          value={filterValue}
          onChange={(event) => {
            setFilterValue(event.target.value);
          }}
        />

        <Button
          type={ButtonTypes.button}
          theme={ButtonThemes.outlined}
          extraClass={styles.searchButton}
          qaId={`${qaIdPrefix}-search-stock`}
          isActivated={false}
          isDisabled={filterValue.trim()?.length === 0}
          onClick={handleSearch}
        >
          {translations.SEARCH}
        </Button>
        <div className={styles.rightActions} style={{ position: 'relative' }}>
          {/* // * drop down will be hidden for now */}
          {/* <Dropdown
            qaId={`${qaIdPrefix}-built-year-dropdown`}
            selectedValue="test"
            extraClass={styles.dropdown}
            initialOptions={[
              {
                value: 'test',
                label: 'test',
              },
            ]}
          /> */}
          <PermissionGuard
            permissions={[userActions.CAN_CREATE_PDF_INSPECTION]}
          >
            <Link
              to={appRoutes.pdfInspectionCarInfo()}
              className={styles.pdfEvaluation}
              data-qa-id={`${qaIdPrefix}-pdf-inspect-button`}
            >
              {translations.PDF_EVALUATION}
            </Link>
          </PermissionGuard>
          <Link
            to={appRoutes.carInfo(true)}
            className={styles.inspectButton}
            data-qa-id={`${qaIdPrefix}-inspect-button`}
          >
            {translations.INSPECT_CAR}
          </Link>
        </div>
      </div>
    </div>
  );
};

export { Header };
