import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';
import type { SummaryInitialDataQuery } from 'gql/graphql';
import { Grid } from 'shared/components/Grid';
import { Typography } from 'shared/components/Typography';
import { ItemDetail } from '../ItemDetail';
import type { DetailsDescription } from '../../types';
import styles from './index.module.scss';

type Props = {
  className?: string;
  qaIdPrefix: string;
  items: ReadonlyArray<{
    title: string;
    description: DetailsDescription;
  }>;
  title: string;
  data?: SummaryInitialDataQuery;
};

function graphqlToFieldValue(graphqlObject: SummaryInitialDataQuery) {
  return function (field: string, defaultValue?: unknown) {
    return get(graphqlObject, field) ?? defaultValue;
  };
}

const Details: React.FC<Props> = ({
  qaIdPrefix,
  title,
  items,
  className = '',
  data = {} as SummaryInitialDataQuery,
}) => {
  const getValue = React.useMemo(() => graphqlToFieldValue(data), [data]);

  return (
    <div data-qa-id={qaIdPrefix} className={className}>
      <Typography
        data-qa-id={`${qaIdPrefix}-title`}
        variant="titleXL"
        tag="div"
        additonalClassNames={styles.title}
      >
        {title}
      </Typography>
      <Grid columns={2} gutter={[3, 2]} qaIdPrefix={qaIdPrefix}>
        {items.map(({ title: itemTitle, description }, index) => (
          <Grid
            item
            key={itemTitle}
            qaIdPrefix={`${qaIdPrefix}-${kebabCase(itemTitle)}`}
          >
            <ItemDetail
              title={itemTitle}
              description={
                typeof description === 'function'
                  ? description(getValue)
                  : getValue(description)
              }
              qaIdPrefix={`${qaIdPrefix}-${index}`}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export { Details };
