import * as React from 'react';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Grid } from 'shared/components/Grid';
import { keyExtractor } from '../../../shared/utils/keyExtractor';
import { InfoField } from '../../../shared/components/InfoField';
import styles from './index.module.scss';

type Props = {
  items?: ReadonlyArray<{
    label: string;
    value?: string | number | null;
    qaIdPrefix?: string;
  }>;
};

const CarInfoItems: React.FC<Props> = ({ items = [] }) => {
  const { translate } = useTranslation();
  return (
    <Grid gutter={[2, 0]} columns={2} className={styles.grid}>
      {items.map((car, index) => {
        return (
          <Grid
            item
            key={keyExtractor(car, index)}
            data-qa-id={`${car.qaIdPrefix}-container`}
          >
            <InfoField
              label={car.label}
              qaIdPrefix={car.qaIdPrefix}
              extraClassName={styles.overflownText}
            >
              {car.value !== null && car.value !== undefined
                ? translate(`${car.value}`)
                : ''}
            </InfoField>
          </Grid>
        );
      })}
    </Grid>
  );
};

export { CarInfoItems };
