import * as React from 'react';

import styles from './index.module.scss';

type Props = {
  url: string;
  qaIdPrefix?: string;
};

const VideoThumb: React.FC<Props> = ({ url, qaIdPrefix = 'video-thumb' }) => {
  return (
    <video
      src={url}
      className={styles.video}
      data-qa-id={`${qaIdPrefix}-video`}
    ></video>
  );
};

export { VideoThumb };
