import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';
import { Typography } from 'shared/components/Typography';
import { Grid } from 'shared/components/Grid';
import styles from './index.module.scss';

type Props = {
  titleWidth?: string;
  linesCount?: number;
};

const DetailsPlaceholder: React.FC<Props> = ({
  titleWidth = '115px',
  linesCount = 5,
}) => (
  <div style={{ marginBottom: '40px' }}>
    <Typography variant="titleXL" tag="div" additonalClassNames={styles.title}>
      <Skeleton width={titleWidth} height="100%" />
    </Typography>
    <Grid columns={2} gutter={[3, 2]}>
      {new Array(linesCount).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <Grid item>
            <Typography tag="div" variant="textSmall" style={{ width: '100%' }}>
              <Skeleton width="100%" height="100%" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography tag="div" variant="textSmall" style={{ width: '100%' }}>
              <Skeleton width="100%" height="100%" />
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  </div>
);

export { DetailsPlaceholder };
