const DatePickerFormat = Object.freeze({
  Day: 'day',
  Month: 'month',
});

type DatePickerFormatType =
  typeof DatePickerFormat[keyof typeof DatePickerFormat];

export { DatePickerFormat };
export type { DatePickerFormatType };
