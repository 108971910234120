import * as React from 'react';
import type { DocumentNode } from 'graphql';
import type { LazyQueryHookOptions, OperationVariables } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

function useLazyQueryPromise<TData = unknown, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>,
) {
  const resolveRef =
    React.useRef<(value: TData | PromiseLike<TData>) => void>();
  const rejectRef = React.useRef<(reason?: unknown) => void>();
  const [queryFunction, queryResult] = useLazyQuery<TData, TVariables>(
    query,
    options,
  );

  React.useEffect(() => {
    if (queryResult.called) {
      if (queryResult.data !== undefined && resolveRef.current) {
        resolveRef.current(queryResult.data);
      } else if (queryResult.error !== undefined && rejectRef.current) {
        rejectRef.current(queryResult.error);
      } else {
        return;
      }

      resolveRef.current = undefined;
      rejectRef.current = undefined;
    }
  }, [queryResult.data, queryResult.error, queryResult.called]);

  const promisifiedQueryFunction = React.useCallback(
    (options) => {
      queryFunction(options);
      return new Promise<TData>((resolve, reject) => {
        resolveRef.current = resolve;
        rejectRef.current = reject;
      });
    },
    [queryFunction],
  );

  return [promisifiedQueryFunction, queryResult] as const;
}

export { useLazyQueryPromise };
