import * as React from 'react';
import { Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { useTranslation } from 'shared/hooks/useTranslation';
import { PageLoader } from 'shared/components/PageLoader';
import { useUserPermissionsContext } from 'shared/components/PermissionsContext';
import { ErrorMessagePage } from 'pages/ErrorMessagePage';
import { useUserActionsQuery } from 'gql/graphql';
import { useIsBranchManagerPage } from 'shared/hooks/useIsBranchManagerPage';
import { PermissionGuardedRoute } from '../PermissionGuardedRoute';

type Props = {
  permissions?: string[];
} & RouteProps;

const MerchantRoute: React.FC<Props> = ({ permissions, ...rest }) => {
  const { translations } = useTranslation();
  const { userPermissions, setUserPermissions } = useUserPermissionsContext();
  const { loading: userActionsLoading, error } = useUserActionsQuery({
    skip: !!userPermissions,
    onCompleted: (result) => {
      if (result?.user?.actions) {
        setUserPermissions(result.user.actions);
      }
    },
  });

  const isBranchManagerPage = useIsBranchManagerPage(
    rest.location?.pathname ?? '',
  );

  if (error) {
    return <ErrorMessagePage component={translations.FAILED_LOADING_USER} />;
  }

  if (userActionsLoading || !userPermissions) {
    return (
      <PageLoader
        message={
          isBranchManagerPage
            ? translations.LOADING_BRANCHES_PAGE
            : translations.LOADING_INSPECTION_APP
        }
      />
    );
  }

  if (permissions) {
    return (
      <PermissionGuardedRoute permissions={permissions}>
        <Route {...rest} />
      </PermissionGuardedRoute>
    );
  }

  return <Route {...rest} />;
};

export { MerchantRoute };
