/**
 * Doesn't match
 * - /en/inspection/carDetails/branch/1848028#fill-manually
 * - /en/app/remarketing-branches/edit/1848028
 * - /en/404
 */

function getInspectionIdFromUrl(url: string) {
  if (/((branch.*?|\/\w{2})\/(\d+)[#/]?)/.test(url)) {
    return null;
  }
  const matches = url.match(/\/(\d+)[#/]?/g);
  const inspectionId = matches?.[0]?.replace(/\D/g, '');

  return inspectionId ? Number(inspectionId) : null;
}

export { getInspectionIdFromUrl };
