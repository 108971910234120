import * as React from 'react';
import { ApolloQueryResult } from '@apollo/client';
import {
  CheckBeforeSubmitVinV2QueryVariables,
  CheckBeforeSubmitLicensePlateV2QueryVariables,
  CheckBeforeSubmitVinV2Query,
  CheckBeforeSubmitLicensePlateV2Query,
  VinStatus as VinStatusType
} from 'gql/graphql';
import { VinStatus } from 'shared/utils/graphqlEnums';

const REFETCH_MAX_ATTEMPT = 2;
const REFETCH_DELAY = 10; // in seconds

type Params = {
  loading: boolean;
  refetch: ((variables?: Partial<CheckBeforeSubmitVinV2QueryVariables | CheckBeforeSubmitLicensePlateV2QueryVariables> | undefined) => Promise<ApolloQueryResult<CheckBeforeSubmitVinV2Query | CheckBeforeSubmitLicensePlateV2Query>>) | undefined;
  vinStatus: VinStatusType | undefined;
  retryAfter?: number | null;
};

const useDATTimeout = ({ loading, refetch, vinStatus, retryAfter }: Params) => {
  const [refetchCount, setRefetchCount] = React.useState(0);
  const [refetchCompleted, setRefetchCompleted] = React.useState(false);

  React.useEffect(() => {
    if (loading) {
      return undefined;
    }

    if (vinStatus !== VinStatus.VehicleInfoTimeout) {
      return undefined;
    }

    if (
      vinStatus === VinStatus.VehicleInfoTimeout &&
      refetchCount < REFETCH_MAX_ATTEMPT
    ) {
      const timeoutId = setTimeout(() => {
        setRefetchCount((count) => count + 1);
        refetch && refetch();
      }, (retryAfter ?? REFETCH_DELAY) * 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (refetchCount === REFETCH_MAX_ATTEMPT) {
      setRefetchCompleted(true);
      return undefined;
    }

    return undefined;
  }, [vinStatus, retryAfter, loading, refetch, refetchCount]);

  return { refetchCompleted };

};

export { useDATTimeout };
