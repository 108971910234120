import * as yup from 'yup';
import type { Translations } from 'shared/utils/translations';

const VinSchema = (translations: Translations) =>
  yup
    .string()
    .required(translations.THIS_FIELD_CANNOT_BE_EMPTY)
    .matches(/^[^qioQIO]+$/, translations.PLEASE_DO_NOT_USE_THIS_LETTERS)
    .length(20, ({ value = '', length }) =>
      window.Translator.trans('a1-inspectionApp-vin-length', {
        length,
        current: value.length,
      }),
    );

const CarInfoSchema = (translations: Translations) => {
  return yup.object().shape({
    vin: VinSchema(translations),
    licensePlate: yup
      .string()
      .uppercase()
      .nullable()
      .max(45, translations.INVALID_LENGTH),
    externalId: yup.string().nullable(),
  });
};

export { VinSchema, CarInfoSchema };
