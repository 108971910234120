import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import { useTranslation } from 'shared/hooks/useTranslation';
import { getYesOrNoToggleValues } from '../../utils';
import type { Form, Damage } from '../../types';
import styles from '../../index.module.scss';
import { shouldDisableAccidentOptionNo } from './model';

type Props = {
  qaIdPrefix: string;
  damages: Damage[];
};

const AccidentsSection: React.FC<Props> = ({ qaIdPrefix, damages }) => {
  const { translations } = useTranslation();
  const yesOrNoToggle = getYesOrNoToggleValues(translations);

  const { watch } = useFormContext<Form>();
  const [hasHadAccident] = watch(['accidents.hasHadAccident']);

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      id="accidents"
      qaIdPrefix={qaIdPrefix}
      showBottomBorder
    >
      <div className={styles.title}>
        <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
          {translations.ACCIDENT_HEADER}
        </Typography>
      </div>
      <div
        className={hasHadAccident ? styles.formElementContainer : ''}
        data-qa-id={`${qaIdPrefix}-car-in-accident-container`}
      >
        <FormToggleButton
          label={translations.ACCIDENT_QUESTION_HAD_ACCIDENT}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix={`${qaIdPrefix}-car-in-accident-toggle`}
          name="accidents.hasHadAccident"
          options={yesOrNoToggle.map((option) =>
            option.value === false
              ? {
                ...option,
                isDisabled: shouldDisableAccidentOptionNo(damages),
              }
              : option,
          )}
          defaultValue={null}
        />
      </div>
    </Card>
  );
};

export { AccidentsSection };
