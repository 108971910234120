import * as React from 'react';

import differenceInSeconds from 'date-fns/differenceInSeconds';
import { useInspection } from 'shared/components/InspectionContext';
import { upperFirstChar } from 'shared/utils/strings';

const useSubmitTracking = (sectionName: string) => {
  const [renderPageStartTime] = React.useState<number>(Date.now());
  const { inspectionId, stockNumber } = useInspection();

  const submitTracking = (action = 'submit', payload?: { expectedPrice?: number, startPrice?: number }) => {
    window.tracking.eva[
      `submitPage${action === 'submit' ? '' : upperFirstChar(action)}`
    ]?.({
      page: sectionName,
      inspectionId,
      stockNumber,
      duration: differenceInSeconds(Date.now(), renderPageStartTime),
      ...(action !== 'submit') && payload && { ...payload }
    });
  };

  return {
    submitTracking,
  };
};

export { useSubmitTracking };
