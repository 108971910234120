const pages = Object.freeze({
  carDetails: 'carDetails',
  testDrive: 'testDrive',
  carPictures: 'carPhotos',
  carQuality: 'carQuality',
  tiresBrakes: 'wheels',
  carStatus: 'equipment',
  serviceDocuments: 'serviceAndDocuments',
  summary: 'summary',
});

const sections = Object.freeze({
  carInfo: 'carInformation',
  additionalInfo: 'additionalInformation',
  mileage: 'mileage',
  'test-drive': 'testDrive',
  findings: 'testDriveFindings',
  thickness: 'paintThickness',
  damages: 'damages',
  highlights: 'highlights',
  'installed-wheels': 'installedWheels',
  'extra-wheels': 'extraSetOfWheels',
  equipment: 'equipment',
  'other-equipment': 'otherEquipment',
  'other-information': 'otherInformation',
  service: 'service',
  accidents: 'accidents',
  'other-documents': 'otherDocuments',
});

export { pages, sections };
