import * as React from 'react';
import { ToggleButtonQuestion } from './ToggleButtonQuestion';
import { CheckboxQuestion } from './CheckboxQuestion';
import { testDriveQuestions } from '../TestDriveSection/TestDriveQuestionid';
import type { Question } from '../types';

type Props = {
  qaIdPrefix: string;
  question: Question;
  onChange?: () => void;
  section?: string;
};

const SingleQuestionRenderer: React.FC<Props> = ({
  question,
  qaIdPrefix,
  onChange = () => {},
  section = '',
}) => {
  const isPerformedNo =
    question?.id === testDriveQuestions.PERFORMED_NO_RESPONSE;

  return (
    <>
      {question?.allowedOptions === 1 && (
        <ToggleButtonQuestion
          question={question}
          qaIdPrefix={qaIdPrefix}
          onChange={onChange}
          section={section}
        />
      )}
      {question?.allowedOptions > 1 && (
        <CheckboxQuestion
          question={question}
          qaIdPrefix={qaIdPrefix}
          isPerformedNo={isPerformedNo}
          onChange={onChange}
          section={section}
        />
      )}
    </>
  );
};

export { SingleQuestionRenderer };
