import * as React from 'react';

import { useTestDriveInitialDataQuery } from 'gql/graphql';
import { useProgress } from 'pages/TestDrive/hooks/useProgress';
import { mapDataToFormValues } from 'pages/TestDrive/utils';

function useTestDriveProgress(inspectionId: number) {
  const { data } = useTestDriveInitialDataQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setTestDriveProgress = useProgress();
  const formValues = data
    ? mapDataToFormValues(data)
    : { form: undefined, questions: undefined };
  const updateProgress = React.useCallback(() => {
    setTestDriveProgress({
      formValues: formValues.form,
      questions: formValues.questions,
    });
  }, [data, setTestDriveProgress]);

  React.useEffect(() => {
    setTestDriveProgress({
      formValues: formValues.form,
      questions: formValues.questions,
    });
  }, [data, setTestDriveProgress]);

  return updateProgress;
}

export { useTestDriveProgress };
