import { apm, init } from '@elastic/apm-rum';
import { isLocalEnv, isProductionEnv } from 'shared/utils/isProductionUrl';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';

type InstrumentationTypes = NonNullable<
  NonNullable<Parameters<typeof init>[0]>['disableInstrumentations']
>[number];

const isActive = () => {
  if (
    isLocalEnv() ||
    (!isProductionEnv() && process.env.RUM_SERVICE_VERSION === 'master') ||
    isProductionEnv()
  ) {
    return true;
  }

  return false;
};

const getConfig = () => {
  const env = isProductionEnv() ? 'prod' : 'qa';

  const mainConfig = {
    serviceName: process.env.RUM_SERVICE_NAME,
    serviceVersion: process.env.RUM_SERVICE_VERSION,
    serverUrl: `https://rum.${env}.services.auto1.team`,
    active: isActive(),
  };

  if (!isMobileEvaluatorApp()) {
    return mainConfig;
  }

  const disableInstrumentations = [
    'page-load',
    'eventtarget',
    'history',
    'fetch',
    'xmlhttprequest',
  ] as InstrumentationTypes[];
  const errorsOnlyConfig = {
    ...mainConfig,
    disableInstrumentations,
  };

  // Enable full RUM for 10% of traffic. The other 90% will only capture errors.
  const enabledForTraffic = Math.random() <= 0.1;
  if (enabledForTraffic) {
    return mainConfig;
  }

  return errorsOnlyConfig;
};

const ignoreErrorMessages = [
  '[GatewayError] INCOMPLETE_INSPECTION',
  '[GatewayError] Response not successful: Received status code 403',
  '[GatewayError] SERVICE UNAVAILABLE',
  '[GatewayError] Response not successful: Received status code 504',
  '[GatewayError] Access denied to this field.',
  '[GatewayError] INCOMPLETE_MUTATION_NOT_ALLOWED',
  "Unhandled promise rejection: TypeError: undefined is not an object (evaluating 'e.ai.getAISearchId')",
  "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  'Unhandled promise rejection: {"isTrusted":true}',
  "Unhandled promise rejection: TypeError: Cannot read properties of null (reading 'querySelectorAll')",
  'Some files could not be uploaded',
  'Unhandled promise rejection: AbortError: The user aborted a request.',
];

if (isLocalEnv() || isMobileEvaluatorApp()) {
  init(getConfig());
} else {
  console.log('apm config', getConfig());
  apm.config(getConfig());
}

apm.addFilter((payload) => {
  if (payload.exception) {
    const errorMessage = payload.exception.message;
    if (ignoreErrorMessages.includes(errorMessage)) {
      return false;
    }
  }
  if (Array.isArray(payload.errors) && payload.errors.length > 0) {
    payload.errors = payload.errors.filter(
      (error: { exception: { message: string } }) =>
        !ignoreErrorMessages.includes(error.exception.message),
    );
    if (payload.errors.length === 0) {
      return false;
    }
  }

  return payload;
});
