import type { Translations } from 'shared/utils/translations';

const paintThicknessItems = (translations: Translations) => [
  {
    label: translations.PAINT_THICKNESS_QUESTION_FRONT,
    name: 'hood',
  },
  {
    label: translations.PAINT_THICKNESS_QUESTION_RIGHT,
    name: 'right',
  },
  {
    label: translations.PAINT_THICKNESS_QUESTION_LEFT,
    name: 'left',
  },
  {
    label: translations.PAINT_THICKNESS_QUESTION_ROOF,
    name: 'roof',
  },
  {
    label: translations.PAINT_THICKNESS_QUESTION_REAR,
    name: 'back',
  },
];

export { paintThicknessItems };
