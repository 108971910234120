import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { testDriveQuestions } from '../TestDriveSection/TestDriveQuestionid';
import { SingleQuestionRenderer } from './SingleQuestionRenderer';
import type { Question } from '../types';

type Props = {
  question?: Question | null;
  shouldRenderQuestion?: boolean;
  qaIdPrefix: string;
  section?: string;
};

const FOOTER_HEIGHT = 93;
const isInView = (element: Element) => {
  const offset = -(FOOTER_HEIGHT + element?.getBoundingClientRect().height);
  const top = element?.getBoundingClientRect().top;
  return top >= 0 && top - offset <= window.innerHeight;
};

const TestDriveQuesitonsTree: React.FC<Props> = ({
  question,
  qaIdPrefix,
  shouldRenderQuestion = true,
  section = '',
}) => {
  const { watch } = useFormContext();
  const ref = React.useRef<HTMLDivElement>(null);
  const value = watch(`question[_${question?.id}]`);

  const shouldRenderOptions =
    question?.allowedOptions === 0 &&
    question?.options?.length &&
    question?.id !== testDriveQuestions.PERFORMED_YES_RESPONSE;

  const shouldRenderNestedQuestion = React.useCallback(
    (nestedQuestionId) => {
      return (
        shouldRenderOptions ||
        (shouldRenderQuestion && `${value}`.includes(`${nestedQuestionId}`))
      );
    },
    [shouldRenderOptions, shouldRenderQuestion, value],
  );

  const onChange = () => {
    setTimeout(() => {
      if (!ref.current) {
        return undefined;
      }

      const nextElement = ref.current?.nextElementSibling;
      if (!nextElement) {
        return ref.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }

      const nextElementInView = isInView(nextElement);
      if (!nextElementInView) {
        // scroll down
        return window.scrollBy({
          top: FOOTER_HEIGHT + nextElement?.getBoundingClientRect().height,
          left: 0,
          behavior: 'smooth',
        });
      }
      const elementInView = isInView(ref.current);
      if (!elementInView) {
        // scroll up
        return window.scrollBy({
          top: -ref.current?.offsetHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
      return undefined;
    }, 0);
  };

  return (
    <>
      {shouldRenderQuestion && question && (
        <div ref={ref}>
          <SingleQuestionRenderer
            question={question}
            qaIdPrefix={qaIdPrefix}
            onChange={onChange}
            section={section}
          />
        </div>
      )}
      {/* // RENDER OPTIONS RECURSIVLY */}
      {question?.options?.map((nestedQuestion) => {
        return (
          nestedQuestion &&
          shouldRenderNestedQuestion(nestedQuestion.id) && (
            <TestDriveQuesitonsTree
              key={nestedQuestion.id}
              question={nestedQuestion}
              qaIdPrefix={qaIdPrefix}
              section={section}
            />
          )
        );
      })}
    </>
  );
};

export { TestDriveQuesitonsTree };
