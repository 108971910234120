import * as React from 'react';
import cn from 'classnames';
import { FormInput } from 'shared/components/FormInput';
import styles from '../../index.module.scss';

type Props = {
  name: string;
  className?: string;
  label?: React.ReactElement | string;
  component?: React.ComponentType<{
    name: string;
    qaIdPrefix?: string;
    label?: React.ReactElement | string;
    className?: string;
    [key: string]: unknown;
  }>;
  [key: string]: unknown;
};

const qaIdPrefix = 'locations-form-page';

const EditLocationField: React.FC<Props> = ({
  name,
  label,
  className = '',
  children,
  component = FormInput,
  ...props
}) => {
  const Component = component;

  return (
    <div className={`${styles.formGrid} ${className}`}>
      <Component
        qaIdPrefix={`${qaIdPrefix}-${name}`}
        label={label}
        className={cn({
          [styles.input]: Component === FormInput,
        })}
        name={name}
        {...props}
      />
      {children}
    </div>
  );
};

export { EditLocationField };
