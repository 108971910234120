import type {
  EquipmentGroup,
  EquipmentItem,
  VehicleEngineDetails,
  VehicleEngineBatteryOwnership,
  VehicleEngineFuelType,
  VehicleConditionCommercialType,
} from 'gql/graphql';
import {
  isHybridCar,
  BatteryOwnershipOptions,
  FuelTypeOptions,
} from 'shared/constants';
import type { Translations } from 'shared/utils/translations';
import type { DetailsDescription } from './types';

const buildDetails = (
  translationKey: string,
  graphqlValuePath: DetailsDescription,
) => ({
  title: translationKey,
  description: graphqlValuePath,
});

const conditionField = (field: string) =>
  `inspection.vehicle.condition.${field}`;
const modelField = (field: string) => `inspection.vehicle.model.${field}`;
const interiorField = (field: string) => `inspection.vehicle.interior.${field}`;
const engineField = (field: string) => `inspection.vehicle.engine.${field}`;

const kwHpFormat = (parse?: (key: string, defaultValue: string) => unknown) => {
  if (!parse) return '';

  const kw = parse(engineField('kw'), '') as number | '';
  const hp = parse(engineField('hp'), '') as number | '';

  return `${hp} / ${kw}`;
};

const usageTypeFormat =
  (translation: Translations) =>
  (parse: (key: string, defaultValue?: unknown) => unknown) => {
    const usageTypes = parse(conditionField('commercialTypes'), [
      translation.NOT_IN_COMMERCIAL_USE,
    ]) as ReadonlyArray<VehicleConditionCommercialType>;

    if (!usageTypes.length) return '';

    return usageTypes.join(', ');
  };

const formatDate = (field: string) => (parse: (key: string) => unknown) => {
  const value = parse(field);
  if (typeof value !== 'string') return '';
  const date = value
    .split('-')
    .slice(0, 2)
    .reverse()
    .join('-')
    .replace('-', '/');

  return date;
};

const getAirCondition = (
  parse: (key: string, defaultValue?: unknown) => unknown,
) => {
  const equipment = parse(
    'getMandatoryEquipment',
  ) as ReadonlyArray<EquipmentGroup>;
  if (!equipment) return '';
  const airConditioningObj = equipment.find(
    (item) => item.name === 'air-conditioning',
  );

  const filterSelectedValues = (option: EquipmentItem) => option.exists;
  const optionDescription = (option: EquipmentItem) => option.description ?? '';

  return (
    airConditioningObj?.items
      ?.filter(filterSelectedValues)
      .map(optionDescription) ?? ''
  );
};

const accidentCar =
  (translation: Translations) =>
  (parse: (key: string, defaultValue?: string | null) => unknown) => {
    const accidents = parse('inspection.meta.accidents') as unknown[] | null;
    const hasServiceBook = parse(
      'inspection.vehicle.condition.hasServiceBook',
      null,
    );
    const otherNote = parse('inspection.meta.evaluation.otherNote', null);
    if (!accidents) return '';
    if (accidents.length) return translation.YES;
    return hasServiceBook !== null || otherNote !== null ? translation.NO : '';
  };

const engineCondition =
  (translations: Translations) => (parse: (key: string) => unknown) => {
    const engine = parse(engineField('engineList[0]')) as VehicleEngineDetails;
    if (engine?.isOriginal) return translations.ORIGINAL;
    if (engine?.isOriginal === false) return translations.NOT_ORIGINAL;
    return '';
  };

const totalOwnerCount = (
  parse: (key: string, defaultValue?: unknown) => unknown,
) => {
  const totalOwnersCount = parse(
    'inspection.vehicle.condition.totalOwnerCount',
  );
  const totalOwnersNumberAvailable = parse(
    'inspection.flags.totalOwnersNumberAvailable',
  );

  if (totalOwnersNumberAvailable === false) return 'a1-common-not-available';
  return totalOwnersCount as string;
};

const firstRegisterationField = (
  parse: (key: string, defaultValue?: string) => unknown,
) => {
  const value = formatDate(conditionField('firstRegistrationDate'))(parse);
  return value || 'a1-common-not-available';
};

function insertIf<T>(condition: boolean, ...elements: T[]): T[] {
  return condition ? elements : [];
}

const isBatteryLeased =
  (translations: Translations) => (parse: (key: string) => unknown) => {
    const fuelType = parse(engineField('fuelType'));

    let batteryOwnership: VehicleEngineBatteryOwnership | undefined | null =
      parse(engineField('batteryOwnership')) as VehicleEngineBatteryOwnership;
    if (isHybridCar(fuelType as VehicleEngineFuelType)) {
      const engineList = (parse(engineField('engineList')) ||
        []) as ReadonlyArray<VehicleEngineDetails>;
      batteryOwnership = (
        engineList.find(
          ({ engineFuelType }) => engineFuelType === FuelTypeOptions.ELECTRIC,
        ) || {}
      )?.batteryOwnership;
    }

    if (batteryOwnership === null || typeof batteryOwnership === 'undefined') {
      return '';
    }
    if (batteryOwnership === BatteryOwnershipOptions.LEASED) {
      return translations.YES;
    }
    return translations.NO;
  };

const batteryConditionsReport =
  (translations: Translations) => (parse: (key: string) => unknown) => {
    const isBatteryConditionReportAvailable = parse(
      'inspection.meta.evaluation.isBatteryConditionReportAvailable',
    );
    if (
      isBatteryConditionReportAvailable === null ||
      typeof isBatteryConditionReportAvailable === 'undefined'
    ) {
      return '';
    }
    if (isBatteryConditionReportAvailable === true) {
      return translations.YES;
    }
    return translations.NO;
  };

const getCarDetails = (
  translation: Translations,
  isElectricCar: boolean,
): Array<ReturnType<typeof buildDetails>> => [
  buildDetails(translation.VIN, 'inspection.vehicle.identifiers.vin'),
  buildDetails(
    translation.FIRST_REGISTRATION_DATE_SUMMARY,
    firstRegisterationField,
  ),
  buildDetails(translation.MANUFACTURER, modelField('make')),
  buildDetails(
    translation.COUNTRY_OF_REGISTRATION,
    'inspection.meta.evaluation.countryOfRegistration',
  ),
  buildDetails(translation.VEHICLE_MODEL, modelField('model')),
  buildDetails(translation.KW_HP, kwHpFormat),
  buildDetails(translation.KEYS_NUMBER, conditionField('keyCount')),
  buildDetails(translation.TRANSMITION_TYPE, modelField('gearBox')),
  buildDetails(
    translation.SERVICE_QUESTION_INSPECTION_EXPIRY_DATE,
    formatDate(conditionField('technicalInspectionCertificateExpiryAtDate')),
  ),
  buildDetails(translation.MILEAGE_KM, conditionField('mileage')),
  buildDetails(translation.USAGE_TYPE, usageTypeFormat(translation)),
  ...insertIf(
    isElectricCar,
    buildDetails(
      translation.BATTERY_LEASED_SUMMARY,
      isBatteryLeased(translation),
    ),
  ),
  buildDetails(translation.TOTAL_OWNERS, totalOwnerCount),
  ...insertIf(
    isElectricCar,
    buildDetails(
      translation.BATTERY_CONDITIONS_REPORT_AVAILABLE_SUMMARY,
      batteryConditionsReport(translation),
    ),
  ),
];

const getConditional = (translations: Translations) => [
  buildDetails(translations.ACCIDENT_CAR, accidentCar(translations)),
  buildDetails(translations.UPHOLSTERY, interiorField('upholstery')),
  buildDetails(translations.ENGINE_CONDITION, engineCondition(translations)),
  buildDetails(translations.AIR_CONDITIONING, getAirCondition),
];

const getService = (translations: Translations) => [
  buildDetails(
    translations.SERVICE_APPOINTMENT,
    'inspection.meta.evaluation.serviceBookAppointments',
  ),
  buildDetails(
    translations.LAST_SERVICE_MILEAGE_KM,
    conditionField('lastServiceAtMileage'),
  ),
  buildDetails(
    translations.LAST_SERVICE_DATE,
    formatDate(conditionField('lastServiceAtDate')),
  ),
];

export { getCarDetails, getConditional, getService };
