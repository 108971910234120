import get from 'lodash/get';
import { CarDetailsCarInfoQuery } from 'gql/graphql';
import type { Translations } from 'shared/utils/translations';

const concatValues = (
  value1: string | undefined,
  value2: string | undefined,
  defaultValue: string,
) => {
  if (value1 && value2) {
    return `${value1} / ${value2}`;
  }
  return defaultValue;
};

const carInfoDataItems = (
  carInfo: CarDetailsCarInfoQuery,
  qaIdPrefix: string,
  translation: Translations,
) => {
  return [
    {
      label: translation.MAKE,
      value: carInfo?.inspection?.vehicle?.model?.make,
      qaIdPrefix: `${qaIdPrefix}-make`,
    },
    {
      label: translation.MAIN_TYPE,
      value: carInfo?.inspection?.vehicle?.model?.model,
      qaIdPrefix: `${qaIdPrefix}-main_type`,
    },
    {
      label: translation.BUILT_YEAR,
      value: (() => {
        const date = carInfo?.inspection?.vehicle?.model?.buildDate;
        if (date) {
          return new Date(date).getFullYear();
        }
        return '';
      })(),
      qaIdPrefix: `${qaIdPrefix}-built_year`,
    },
    {
      label: translation.BODY_TYPE,
      value: carInfo?.inspection?.vehicle?.model?.bodyType,
      qaIdPrefix: `${qaIdPrefix}-body_type`,
    },
    {
      label: translation.SUBTYPE,
      value: carInfo?.inspection?.vehicle?.model?.subModel,
      qaIdPrefix: `${qaIdPrefix}-subtype`,
    },
    {
      label: translation.MOTOR_ENGINE,
      value: get(carInfo, 'inspection.meta.evaluation.engineName'),
      qaIdPrefix: `${qaIdPrefix}-motor-engine`,
    },
    {
      label: translation.CCM,
      value: get(carInfo, 'inspection.vehicle.engine.cubicCapacity'),
      qaIdPrefix: `${qaIdPrefix}-ccm`,
    },
    {
      label: translation.KW_HP,
      value: concatValues(
        `${get(carInfo, 'inspection.vehicle.engine.hp', '')}`,
        `${get(carInfo, 'inspection.vehicle.engine.kw', '')}`,
        'NA',
      ),
      qaIdPrefix: `${qaIdPrefix}-kw-hp`,
    },
    {
      label: translation.FUEL_TYPE,
      value: get(carInfo, 'inspection.vehicle.engine.fuelTypeTranslationKey'),
      qaIdPrefix: `${qaIdPrefix}-fuel-type`,
    },
    {
      label: translation.CYLINDERS,
      value: get(carInfo, 'inspection.vehicle.engine.cylinder'),
      qaIdPrefix: `${qaIdPrefix}-cylinder`,
    },
    {
      label: translation.GEAR,
      value: get(carInfo, 'inspection.vehicle.model.gearBox'),
      qaIdPrefix: `${qaIdPrefix}-gear`,
    },
    {
      label: translation.DRIVE_TYPE,
      value: get(carInfo, 'inspection.vehicle.model.driveType'),
      qaIdPrefix: `${qaIdPrefix}-drive-type`,
    },
  ];
};

export { carInfoDataItems };
