import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { Typography } from 'shared/components/Typography';
import { Card } from 'shared/components/Card';
import { NavigationFooterPlaceholder } from 'shared/components/NavigationFooter/Placeholder';
import { CarPicturesPlaceholder } from './components/CarPictures/Placeholder';
import { DetailsPlaceholder } from './components/Details/Placeholder';
import styles from './index.module.scss';

const Placeholder: React.FC = () => (
  <LayoutContainer>
    <Card
      paddingTop={32}
      paddingBottom={32}
      qaIdPrefix="summary-container-loader"
    >
      <Typography
        variant="titleXL"
        tag="div"
        additonalClassNames={styles.title}
      >
        <Skeleton width="130px" height="100%" />
      </Typography>
      <CarPicturesPlaceholder />
      <DetailsPlaceholder titleWidth="115px" linesCount={6} />
      <DetailsPlaceholder titleWidth="346px" linesCount={2} />
      <DetailsPlaceholder titleWidth="155px" linesCount={2} />
    </Card>
    <NavigationFooterPlaceholder />
  </LayoutContainer>
);

export { Placeholder };
