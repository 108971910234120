import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import set from 'lodash/set';
import type { ValidationError } from 'yup';
import { apm } from '@elastic/apm-rum';

import { appRoutes } from 'appRoutes';
import { useTranslation } from 'shared/hooks/useTranslation';

import {
  useCarStatusDataQuery,
  CarStatusDataDocument,
  useSubmitEquipmentPageMutation,
  useSubmitOtherInformationMutation,
} from 'gql/graphql';
import { RoutesEnum } from 'shared/utils/route';
import { scrollToError } from 'shared/utils/scrollToError';
import { useInspection } from 'shared/components/InspectionContext';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { useProgress } from './useProgress';
import {
  graphqlDataToSchema,
  mapEquipmentsToMutation,
  mapOtherInfoToMutation,
} from '../graphqlMapper';
import { CarStatusSchema } from '../car-status-schema';
import { isToggleField } from '../helpers';
import type { EquipmentFields, FormValues } from '../types';

const useCarStatus = () => {
  const history = useHistory();
  const { removeStepFromNotCompleted, inspectionId } = useInspection();
  const { translations } = useTranslation();
  const { submitTracking } = useSubmitTracking('equipment');
  const submitContext = React.useRef<'save' | 'submit' | null>(null);
  const formMethods = useForm<FormValues>({
    context: submitContext,
    resolver: async (data, context) => {
      const optionalSchema = context?.current === 'save';

      try {
        const values = await CarStatusSchema(
          translations,
          optionalSchema,
        ).validate(data, { abortEarly: false });

        return {
          values,
          errors: {},
        };
      } catch (error) {
        const { inner } = error as ValidationError;
        const errors = inner.reduce<{
          [key: string]: { type: string; message: string };
        }>((acc, { path, type, message }) => {
          if (path === undefined) {
            return acc;
          }

          return set(acc, path, { type, message });
        }, {});

        return {
          values: {},
          errors,
        };
      }
    },
  });
  const { reset } = formMethods;

  const { loading, data } = useCarStatusDataQuery({
    variables: {
      inspectionId,
    },
  });

  React.useEffect(() => {
    if (!loading) {
      const form = graphqlDataToSchema(data);
      reset(form);
    }
  }, [loading, data, reset]);

  const mandatoryEquipments: Array<EquipmentFields> = React.useMemo(
    () =>
      (data?.getMandatoryEquipment ?? [])
        .map((equipment) => ({
          ...equipment,
          isToggleField: isToggleField(equipment.items ?? []),
        }))
        .sort((a, b) => {
          if (a.isToggleField === b.isToggleField) return 0;
          if (a.isToggleField && !b.isToggleField) return 1;
          return -1;
        }),
    [data],
  );
  const [submitEquipmentPage, { error: mutationEquipmentError }] =
    useSubmitEquipmentPageMutation({
      refetchQueries: [
        {
          query: CarStatusDataDocument,
          variables: {
            inspectionId,
          },
        },
      ],
    });
  const [submitOtherInformation, { error: mutationOtherInformationError }] =
    useSubmitOtherInformationMutation();
  const mutationError =
    mutationEquipmentError?.graphQLErrors?.[0]?.message ??
    mutationOtherInformationError?.graphQLErrors?.[0]?.message;

  const setProgress = useProgress();

  const handleSubmit = async (values: FormValues) => {
    const optionalSchema = submitContext.current === 'save';
    const shouldRedirect = submitContext.current === 'submit';

    const { otherInformation, equipments, fuelType } = values;
    const { otherNote, ...otherInformationObj } = mapOtherInfoToMutation(otherInformation, fuelType) ?? {};

    try {
      if (equipments.items.length === 0) {
        await submitOtherInformation({
          variables: {
            inspectionId,
            otherInformation: otherInformationObj,
            incomplete: optionalSchema,
          },
        });
      } else {
        await submitEquipmentPage({
          variables: {
            inspectionId,
            otherInformation: otherInformationObj,
            equipment: mapEquipmentsToMutation(equipments),
            otherNote,
            incomplete: optionalSchema,
            skipOtherNote: !otherNote,
          },
        });
        removeStepFromNotCompleted(
          RoutesEnum.CAR_STATUS as keyof typeof RoutesEnum,
        );
      }

      formMethods.reset(values);
      if (shouldRedirect) {
        submitTracking();
        setTimeout(() => {
          history.push(appRoutes.serviceDocuments(`${inspectionId}`, true));
        });
      }
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
    }
  };
  const onSubmitError = () => {
    scrollToError();
    setProgress(formMethods.getValues());
  };

  return {
    loading,
    mandatoryEquipments,
    submitContext,
    formMethods,
    handleSubmit,
    onSubmitError,
    mutationError,
  };
};

export { useCarStatus };
