import * as React from 'react';
import { useTranslation } from 'shared/hooks/useTranslation';
import { AlertBar } from 'shared/components/AlertBar';
import type { Translations } from 'shared/utils/translations';
import styles from './index.module.scss';

type Props = {
  instantOfferSubmitError?: { message?: string };
  bottomErrorMessage: string;
};

const BottomAreaContent: React.FC<Props> = ({
  instantOfferSubmitError = null,
  bottomErrorMessage,
}) => {
  const { translations } = useTranslation();

  if (instantOfferSubmitError) {
    return (
      <AlertBar
        type="error"
        message={
          translations[instantOfferSubmitError.message as keyof Translations] ??
          translations.GET_INSTANT_OFFER_ERROR
        }
        additionalClassNames={styles.alertBar}
      />
    );
  }

  if (bottomErrorMessage) {
    return (
      <AlertBar
        type="error"
        message={bottomErrorMessage}
        additionalClassNames={styles.alertBar}
      />
    );
  }

  return null;
};

export { BottomAreaContent };
