import { allCountries } from 'shared/utils/allCountries';
import sortBy from 'lodash/sortBy';
import type { BranchQuery, BranchInput } from 'gql/graphql';
import type { FormCountry, Form } from './types';

export const buildAddress = ({
  street,
  city,
  zipcode,
  country,
  streetNumber,
}: {
  street: string;
  city: string;
  zipcode: string;
  country: string;
  streetNumber?: string;
}) => {
  if (!street || !city || !country) return '';

  const streetNumberParsed = streetNumber ? ` ${streetNumber}` : '';
  return `${street}${streetNumberParsed}, ${zipcode} ${city}, ${country}`;
};

const mapFormDataToMutation: (form: Form) => BranchInput = ({
  address,
  street,
  streetNumber,
  country,
  ...form
}) => {
  return {
    ...form,
    country: country as FormCountry,
    street: `${street} ${streetNumber}`,
  };
};

const mapDataFromQueryToForm: (data: BranchQuery['branch']) => Form = ({
  __typename,
  ...data
}) => {
  if (!data)
    return {
      name: '',
      address: '',
      street: '',
      phone: '',
      email: '',
      streetNumber: '',
      zipcode: '',
      city: '',
      country: '',
    };

  const streetNumberValue = data?.street?.trim()?.match(/\d+$/);
  const streetNumber = streetNumberValue ? streetNumberValue[0] : null;

  const street = streetNumber
    ? data?.street?.replace(streetNumber, '').trim()
    : data?.street;
  return {
    ...data,
    address: buildAddress({ ...data }),
    street: street ?? '',
    streetNumber: streetNumber ?? '',
    country: data.country as FormCountry,
    email: data?.email ?? '',
    phone: data.phone ?? '',
  };
};

const allowedCountries = [
  'DE',
  'FR',
  'IT',
  'ES',
  'PT',
  'NL',
  'BE',
  'LU',
  'AT',
  'DK',
  'SE',
  'FI',
  'PL',
] as const;

const getAllowedCountryOptions = () => {
  const options = allowedCountries.map((value) => {
    const translationKey = `global.country-code-${value}`;
    const label = window.Translator.trans(translationKey);
    const hasTranslation = translationKey !== label;

    return {
      label: hasTranslation
        ? label
        : allCountries[value as keyof typeof allCountries],
      value,
      weight: hasTranslation ? 0 : 1,
    };
  });

  return sortBy(options, ['weight', 'label']);
};

export {
  mapFormDataToMutation,
  mapDataFromQueryToForm,
  allowedCountries,
  getAllowedCountryOptions,
};
