import * as React from 'react';
import type { ValidationError } from 'yup';
import { useInspection } from 'shared/components/InspectionContext';
import { useTranslation } from 'shared/hooks/useTranslation';
import {
  getSectionErrorsYup,
  getPageProgress,
  getSectionProgress
} from 'shared/utils/calculateProgress';
import { RoutesEnum } from 'shared/utils/route';
import { getDamagesSchema } from '../schema';
import type { Form, PaintThicknessDataType } from '../types';


const isDamageOptional = (paintThicknessData?: PaintThicknessDataType) => {
  if (!paintThicknessData) {
    return true;
  }
  const { threshold, isPaintThicknessChecked, paintThicknesses } = paintThicknessData;
  if (!threshold) {
    return true;
  }

  if (isPaintThicknessChecked) {
    return paintThicknesses.some(paintThickness => paintThickness?.value != null && paintThickness?.value >= threshold);
  }

  return true;
};

function getProgressFromErrors(formValues: Form, errors: ValidationError[], paintThicknessData?: PaintThicknessDataType) {
  const sectionErrors = getSectionErrorsYup(errors, 'damages');

  const damagesProgress = getSectionProgress({
    errors: sectionErrors,
    totalFieldsNb: (formValues.damages?.length ?? 0) * 4,
    isOptional: isDamageOptional(paintThicknessData),
  });
  return {
    [RoutesEnum.DAMAGES]: getPageProgress([
      damagesProgress,
    ]),
  };
}

function useProgress() {
  const { setProgress } = useInspection();
  const { translations, translate } = useTranslation();

  const getDamagesProgress = React.useCallback(
    (
      formValues: Form,
      paintThicknessData: PaintThicknessDataType,
    ) => {
      let errors: ValidationError[] = [];
      try {
        getDamagesSchema(
          translations,
          false,
        ).validateSync(
          formValues,
          {
            abortEarly: false,
          },
        );
      } catch (validationError) {
        const { inner } = validationError as ValidationError;
        errors = inner;
      }

      setProgress(getProgressFromErrors(
        formValues,
        errors,
        paintThicknessData,
      ));
    },
    [setProgress, translations, translate],
  );

  return getDamagesProgress;
}

export { useProgress, getProgressFromErrors };
