import { APP_NAMES } from 'config/urls';
import * as urlsConfig from 'config/urls';
import { localStorageService } from 'config/localStorageService';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import {
  GOOGLE_USER_ACCESS_KEY,
  USER_ACCESS_KEY,
  GOOGLE_USER_ACCESS_REFRESH_TOKEN,
} from './constants';

type GetTokenResponse = {
  response: {
    accessToken: string;
    expirationTime: number;
    uuid: string;
  };
};

type UserAccessToken = {
  jwt: string;
  uuid: string;
};

const setUserAccessLocalStorage = ({ response }: GetTokenResponse) => {
  return localStorageService.setItem(USER_ACCESS_KEY, {
    jwt: response.accessToken,
    uuid: response.uuid,
  });
};

/**
 * sigin to qa server with provided email & password
 */
const signin = async () => {
  const { email, password, url } = urlsConfig.signinSettings;
  const params = new URLSearchParams();
  params.append('user_signin[email]', email);
  params.append('user_signin[password]', password);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
    body: params,
  });
  const data = await response.json();
  try {
    if (!data.success) {
      console.error('signin failed', data);
    }
  } catch (err) {
    console.error(`signin failed: ${err}, response: ${data}`);
  }
};

const getTokenAPI = async () => {
  if (process.env.NODE_ENV === 'development') {
    await signin();
  }

  const response = await fetch(urlsConfig.auto1AccessTokenUrl, {
    credentials: 'include',
  });
  const data = await response.json();

  return {
    data,
    status: response.status,
  };
};

const getGoogleRefreshTokenAPI = async () => {
  const refreshToken =
    window.localStorage.getItem(GOOGLE_USER_ACCESS_REFRESH_TOKEN) ?? '';

  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('client_id', 'admin');
  formData.append('refresh_token', refreshToken);

  const response = await fetch(urlsConfig.mobileEvaluatorAccessTokenUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
    body: formData,
  });
  const data = (await response.json()) as {
    access_token: string;
    refresh_token: string;
  };

  return {
    data,
    status: response.status,
  };
};

const getToken = async () => {
  const { data, status } = await getTokenAPI();
  setUserAccessLocalStorage(data);

  return { data, status };
};

const refetchGoogleAuthToken = async () => {
  const { data, status } = await getGoogleRefreshTokenAPI();
  window.localStorage.setItem(GOOGLE_USER_ACCESS_KEY, data?.access_token);
  window.localStorage.setItem(
    GOOGLE_USER_ACCESS_REFRESH_TOKEN,
    data?.refresh_token,
  );
  return { data, status };
};

function refreshToken() {
  if (isMobileEvaluatorApp()) {
    return refetchGoogleAuthToken();
  } else {
    return getToken();
  }
}

function getAuthToken() {
  const tokenObj = localStorageService.getItem(
    USER_ACCESS_KEY,
  ) as UserAccessToken | null;
  let token = tokenObj?.jwt ?? null;
  if (process.env.REACT_APP_NAME === APP_NAMES.MOBILE_EVALUATOR) {
    token = window.localStorage.getItem(GOOGLE_USER_ACCESS_KEY);
  }

  return token;
}

export { getToken, refetchGoogleAuthToken, getAuthToken, refreshToken };
export type { UserAccessToken };
