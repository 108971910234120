import 'intl';

export const DEFAULT_LOCALE = 'en';
export const APP_NAME = 'eva';
export const LANG_PROJECTS = Object.freeze([
  'Auto1_V2',
  'Car Details',
  'Damages and Equipment',
] as const);
export const FALLBACK_LOCALE = 'en';
export const SUPPORTED_LOCALES = Object.freeze({
  bg: 'bulgarian',
  ch: 'swiss_german',
  cs: 'czech',
  da: 'danish',
  de: 'german',
  el: 'greek',
  en: 'english',
  es: 'spanish',
  et: 'estonian',
  fi: 'finnish',
  fr: 'french',
  hr: 'croatian',
  hu: 'hungarian',
  it: 'italian',
  lt: 'lithuanian',
  lv: 'latvian',
  nl: 'dutch',
  pl: 'polish',
  pt: 'portuguese',
  ro: 'romanian',
  ru: 'russian',
  sk: 'slovakian',
  sr: 'serbian',
  sv: 'swedish',
  uk: 'ukrainian',
});
