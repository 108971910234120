import * as React from 'react';
import { FormInputPlaceholder } from 'shared/components/FormInput/Placeholder';
import { Skeleton } from 'shared/components/Skeleton';
import { Grid } from 'shared/components/Grid';
import styles from './index.module.scss';

const VinSearchPlaceholder = () => (
  <div className={styles.container}>
    <div
      className={styles.vinContainer}
      data-qa-id="car-details-page-vin-info-vin-search-vin-container-loader"
    >
      <FormInputPlaceholder labelWidth="24px" width="370px" />
      <Skeleton
        width="160px"
        height="40px"
        style={{ marginLeft: '32px', marginTop: '27px' }}
      />
      <Skeleton
        width="57px"
        height="24px"
        style={{ marginLeft: '24px', marginTop: '35px' }}
      />
    </div>
    <Grid gutter={[2, 3]} columns={2}>
      <Grid item>
        <div className={styles.inputWrapper}>
          <FormInputPlaceholder labelWidth="152px" />
        </div>
      </Grid>
      <Grid item>
        <div className={styles.inputWrapper}>
          <FormInputPlaceholder labelWidth="175px" />
        </div>
      </Grid>
    </Grid>
  </div>
);

export { VinSearchPlaceholder };
