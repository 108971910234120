const VinStatus = Object.freeze({
  Ok: 'OK',
  DuplicatedVinUnfinished: 'DUPLICATED_VIN_UNFINISHED',
  AuctionEnded: 'AUCTION_ENDED',
  RerunLimitExhausted: 'RERUN_LIMIT_EXHAUSTED',
  RunningAuction: 'RUNNING_AUCTION',
  NoVin: 'NO_VIN',
  NoVehicleInfo: 'NO_VEHICLE_INFO',
  VehicleInfoTimeout: 'VEHICLE_INFO_TIMEOUT',
});

export { VinStatus };
