import {
  getRequiredMessage,
  requiredString,
  requiredArray,
} from 'shared/utils/yupValidationHelpers';
import type { Translations } from 'shared/utils/translations';
import * as yup from 'yup';
import {
  SERVICE_BOOK_AVAILABLE_PAPER,
  SERVICE_BOOK_APPOINTMENTS_ALL,
  SERVICE_BOOK_APPOINTMENTS_SOME,
} from './constants';

const requiredStringToggle = (
  optional: boolean,
  translations: Translations,
) => {
  if (optional) {
    return yup.string();
  }
  return yup.string().required(getRequiredMessage(translations, 'toggle'));
};

const requiredBool = (
  optional: boolean,
  translations: Translations,
  type: 'toggle' | 'file',
) => {
  if (optional) {
    return yup.bool().nullable();
  }
  return yup.bool().nullable().required(getRequiredMessage(translations, type));
};

const CarServiceSchema = (
  translations: Translations,
  optional: boolean,
  isPdfInspection: boolean,
  shouldUseNewAccidentModel: boolean,
) => {
  const isServiceSectionOptional = isPdfInspection;

  const inspectionCompany = () => {
    if (optional) {
      return yup.number().nullable();
    }

    const requiredCompany = yup
      .number()
      .nullable()
      .required(getRequiredMessage(translations));

    if (isPdfInspection) {
      return requiredCompany;
    }

    return yup.mixed().when('inspectionReport', {
      is: Boolean,
      then: requiredCompany,
    });
  };

  const inspectionReport = () => {
    if (optional) {
      return yup.mixed().nullable();
    }

    const requiredInspectionReport = yup
      .mixed()
      .test('isFile', translations.PLEASE_CHOOSE_FILE, (value) => {
        return value?.[0] instanceof File || value?.absoluteUrl;
      })
      .required(translations.PLEASE_CHOOSE_FILE);

    if (isPdfInspection) {
      return requiredInspectionReport;
    }

    return yup.mixed().when('companyId', {
      is: Boolean,
      then: requiredInspectionReport,
    });
  };

  return yup.object().shape(
    {
      service: yup.object().shape({
        serviceBook: requiredStringToggle(
          optional || isServiceSectionOptional,
          translations,
        ),
        serviceBookImages: yup.mixed().when('serviceBook', {
          is: (value: string) => value === SERVICE_BOOK_AVAILABLE_PAPER,
          then: requiredArray(optional, translations),
        }),
        serviceBookAppointments: requiredStringToggle(
          optional || isServiceSectionOptional,
          translations,
        ),
        lastServiceDate: yup.mixed().when('serviceBookAppointments', {
          is: (value: string) =>
            [
              SERVICE_BOOK_APPOINTMENTS_ALL,
              SERVICE_BOOK_APPOINTMENTS_SOME,
            ].includes(value),
          then: requiredString(optional, translations),
        }),
        lastServiceMileage: yup
          .number()
          .nullable(true)
          .when('serviceBookAppointments', {
            is: (value: string) =>
              [
                SERVICE_BOOK_APPOINTMENTS_ALL,
                SERVICE_BOOK_APPOINTMENTS_SOME,
              ].includes(value),
            then: (schema) =>
              optional
                ? schema
                : schema.required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
          }),
        driveBeltChanged: requiredBool(
          optional || isServiceSectionOptional,
          translations,
          'toggle',
        ),
        driveBeltDate: yup.mixed().when('driveBeltChanged', {
          is: true,
          then: requiredString(optional, translations),
        }),
        driveBeltMileage: yup
          .number()
          .nullable(true)
          .when('driveBeltChanged', {
            is: true,
            then: (schema) =>
              optional
                ? schema
                : schema.required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
          }),
        isOriginalEngine: requiredBool(optional, translations, 'toggle'),
        kmEngineExchange: yup
          .number()
          .nullable(true)
          .when('isOriginalEngine', {
            is: false,
            then: (schema) =>
              optional
                ? schema
                : schema.required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
          }),
        isRoadworthy: requiredBool(optional, translations, 'toggle'),
        cannotBeRolled: yup.mixed().when('isRoadworthy', {
          is: false,
          then: requiredBool(optional, translations, 'toggle'),
        }),
        inspectionExpiryDate: requiredString(
          optional || isServiceSectionOptional,
          translations,
        ),
      }),
      ...(!shouldUseNewAccidentModel && {
        accident: yup.object().shape({
          hasHadAccident: requiredBool(optional, translations, 'toggle'),
          wasRepaired: yup.mixed().when('hasHadAccident', {
            is: true,
            then: requiredBool(optional, translations, 'toggle'),
          }),
          repairedBy: yup.mixed().when(['hasHadAccident', 'wasRepaired'], {
            is: (hasHadAccident: boolean, wasRepaired: boolean) => {
              return hasHadAccident && wasRepaired;
            },
            then: requiredString(optional, translations),
          }),
          isRepairCostsAvailable: yup.boolean().nullable(),
          repairCost: yup.number().nullable(true),
          invoiceImages: yup.array().of(
            yup
              .mixed()
              .test('isFile', 'Please provide at least one file', (value) => {
                return value instanceof File || value?.absoluteUrl;
              }),
          ),
        }),
      }),
      companyId: inspectionCompany(),
      inspectionReport: inspectionReport(),
    },
    // @ts-ignore
    ['inspectionReport', 'companyId'],
  );
};

export { CarServiceSchema };
