import * as React from 'react';
import type { UserActionsQuery } from 'gql/graphql';

type UserActions = UserActionsQuery['user']['actions'];

type Context = {
  userPermissions: UserActions | null;
  setUserPermissions: React.Dispatch<React.SetStateAction<UserActions | null>>;
};

const initialUserPermissions = {
  canArchiveInspection: false,
  canCreatePdfInspection: false,
};
const initialData = {
  userPermissions: initialUserPermissions,
  setUserPermissions: () => {},
};

const UserPermissionsContext = React.createContext<Context>(initialData);

function withUserPermissions<T extends Record<string, unknown>>(
  Component: React.ComponentType<T>,
) {
  const UserPermissions = React.memo((props: T) => {
    const [userPermissions, setUserPermissions] =
      React.useState<UserActions | null>(null);

    return (
      <UserPermissionsContext.Provider
        value={{ userPermissions, setUserPermissions }}
      >
        <Component {...(props as T)} />
      </UserPermissionsContext.Provider>
    );
  });

  UserPermissions.displayName = 'UserPermissions';

  return UserPermissions;
}

const useUserPermissionsContext = () => {
  return React.useContext(UserPermissionsContext);
};

export {
  withUserPermissions,
  UserPermissionsContext,
  useUserPermissionsContext,
};
export type { Context };
