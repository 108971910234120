import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Modal } from 'shared/components/Modal';
import { useTranslation } from 'shared/hooks/useTranslation';

type HistoryUnregisterCallback = ReturnType<
  ReturnType<typeof useHistory>['block']
>;

type HistoryLocation = ReturnType<typeof useHistory>['location'];

type Props = {
  when: boolean;
  title: string;
};

const RouteLeavingGuard: React.FC<Props> = ({
  when: shouldGuard,
  title,
  children,
}) => {
  const history = useHistory();
  const { translations } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [nextLocation, setNextLocation] = React.useState<HistoryLocation>();
  const unblock = React.useRef<HistoryUnregisterCallback>();

  React.useEffect(() => {
    unblock.current = history.block((nextLoc) => {
      if (!shouldGuard || history.location.pathname === nextLoc.pathname) {
        return;
      }

      setModalIsOpen(true);
      setNextLocation(nextLoc);
      return false;
    });

    return () => {
      unblock.current?.();
    };
  }, [shouldGuard, history, unblock]);

  React.useEffect(() => {
    const prompt = (e: BeforeUnloadEvent) => {
      if (shouldGuard) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', prompt);

    return () => {
      window.removeEventListener('beforeunload', prompt);
    };
  }, [shouldGuard]);

  const handleCancel = () => {
    setModalIsOpen(false);
  };

  const handleOk = () => {
    setModalIsOpen(false);
    unblock.current?.();

    if (nextLocation) {
      history.push(nextLocation);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      title={title}
      onCancel={handleCancel}
      onOk={handleOk}
      okLabel={translations.LEAVE}
      cancelLabel={translations.CANCEL}
      qaIdPrefix="route-leaving-guard"
    >
      {children}
    </Modal>
  );
};

export { RouteLeavingGuard };
