import * as React from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import type { AccidentReason as AccidentReasonType } from 'gql/graphql';
import { Label } from 'shared/components/Label';
import { Checkbox } from 'shared/components/Checkbox';
import { FormUploader } from 'shared/components/FormUploader';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Typography } from 'shared/components/Typography';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';
import warningIcon from 'assets/icons/warning.svg';
import { REASON_LABELS, AccidentReason } from '../constants';
import styles from './index.module.scss';
import type { ReasonsForm } from './types';
import { ImageData, Damage } from '../../../types';
import {
  filterDamagesForPaintThicknessReason,
  filterDamagesForBodyGap,
} from '../../../utils';
import { REASONS_WITH_DAMAGE_IMAGES } from '../../../constants';
import { CarouselWithSelectableImages } from './CarouselWithSelectableImages';

type Props = {
  reason: AccidentReasonType;
  value?: (File | ImageData)[] | null;
  damages: Damage[];
  qaIdPrefix: string;
};

const ReasonOption: React.FC<Props> = ({
  reason,
  value,
  qaIdPrefix,
  damages,
}) => {
  const [isChecked, setIsChecked] = React.useState(!!value);
  const { translations, translate: t } = useTranslation();
  const {
    setValue,
    formState: { isSubmitted },
  } = useFormContext<ReasonsForm>();
  const shouldUseDamageImages = REASONS_WITH_DAMAGE_IMAGES.includes(reason);
  const filteredDamages =
    reason === AccidentReason.PanelsWithHighPaintThickness
      ? filterDamagesForPaintThicknessReason(damages)
      : filterDamagesForBodyGap(damages);
  const isDisabled = shouldUseDamageImages && filteredDamages.length === 0;

  return (
    <>
      <div
        className={cn(styles.labelWrapper, {
          [styles.notChecked]: !isChecked,
        })}
      >
        <Label
          className={cn(styles.checkboxLabel)}
          data-qa-id={`${qaIdPrefix}-label`}
        >
          <Checkbox
            checked={isChecked}
            data-qa-id={`${qaIdPrefix}-checkbox`}
            disabled={isDisabled}
            onChange={(e) => {
              const isChecked = (e.target as HTMLInputElement).checked;

              setValue(`root.${reason}`, isChecked ? [] : undefined, {
                shouldValidate: isSubmitted,
              });
              setIsChecked(isChecked);
            }}
          />
          {t(REASON_LABELS[reason])}
        </Label>
        {isDisabled && (
          <Typography
            additonalClassNames={styles.noDamagesText}
            data-qa-id={`${qaIdPrefix}-warning`}
          >
            <img src={warningIcon} alt="warning icon" />
            {translations.ACCIDENT_REASON_NO_DAMAGES_WARNING}
          </Typography>
        )}
      </div>
      {isChecked &&
        !isDisabled &&
        (shouldUseDamageImages ? (
          <CarouselWithSelectableImages
            name={reason}
            damages={filteredDamages}
          />
        ) : (
          <FormUploader
            allowMultipleFiles
            uploadFileTypes="image/png, image/jpeg, image/heic, image/heif"
            processSelectedFile={processHeicFile}
            extraClassName={styles.uploader}
            name={`root.${reason}`}
            qaIdPrefix={`${qaIdPrefix}-uploader`}
            includeCarousel={true}
          >
            {({ handleUploadClick }) => (
              <>
                <div className={styles.uploadCloudIcon} />
                <Typography>{translations.DROP_PHOTOS_HERE}</Typography>
                <button
                  type="button"
                  className={styles.browseButton}
                  onClick={() => {
                    handleUploadClick();
                  }}
                >
                  {translations.BROWSE}
                </button>
              </>
            )}
          </FormUploader>
        ))}
    </>
  );
};

export { ReasonOption };
