import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { FormNumberInput } from 'shared/components/FormNumberField';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import styles from '../index.module.scss';
import mileageStyles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
};

const Mileage: React.FC<Props> = ({ qaIdPrefix }) => {
  const { translations } = useTranslation();
  const yesOrNoToggle = React.useMemo(
    () => [
      {
        label: translations.YES,
        value: true,
      },
      {
        label: translations.NO,
        value: false,
      },
    ],
    [translations],
  );
  const {
    watch,
    formState: { errors, isSubmitted },
    register,
    unregister,
    trigger,
    setValue,
  } = useFormContext();
  const isMileageManipulated = watch('mileage.isMileageManipulated');
  const isTachometerReplaced = watch(
    'mileage.mileageSuspicions.isTachometerReplaced',
  );
  const isTachometerReplacementMileageAvailable = watch(
    'mileage.mileageSuspicions.isTachometerReplacementMileageAvailable',
  );

  React.useEffect(() => {
    register('mileage.mileageSuspicionsOptions');

    return () => {
      unregister('mileage.mileageSuspicionsOptions');
    };
  }, []);

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      showBottomBorder
      id="mileage"
      qaIdPrefix={`${qaIdPrefix}-mileage`}
    >
      <div className={styles.title}>
        <Typography
          variant="titleXL"
          data-qa-id={`${qaIdPrefix}-mileage-title`}
        >
          {translations.MILEAGE}
        </Typography>
      </div>
      <FormNumberInput
        qaIdPrefix={`${qaIdPrefix}-mileage-input`}
        label={translations.CURRENT_MILEAGE}
        className={styles.input}
        containerClassName={styles.input}
        name="mileage.currentMileage"
        defaultValue={null}
      />
      <FormToggleButton
        label={translations.SERVICE_QUESTION_MILEAGE_MANIPULATION}
        containerClassName={styles.toggleButtonHorizontal}
        errorClassName={styles.toggleErrorHorizontal}
        qaIdPrefix={`${qaIdPrefix}-is-mileage-manipulated`}
        options={yesOrNoToggle}
        name="mileage.isMileageManipulated"
        defaultValue={null}
      />
      {isMileageManipulated && (
        <>
          <div
            className={mileageStyles.checkboxGrid}
            data-qa-id={`${qaIdPrefix}-mileage-suspicions-options`}
          >
            <FormCheckbox
              className={mileageStyles.checkbox}
              name="mileage.mileageSuspicions.isOdometerBroken"
              uiLabel={translations.MILEAGE_ODOMETER_BROKEN}
              qaIdPrefix={`${qaIdPrefix}-mileage-suspicions-isOdometerBroken`}
              defaultValue={false}
              afterChange={() => {
                if (isSubmitted) {
                  trigger('mileage.mileageSuspicionsOptions');
                }
              }}
            />
            <FormCheckbox
              className={mileageStyles.checkbox}
              name="mileage.mileageSuspicions.isMileageConflictingServiceHistory"
              uiLabel={translations.MILEAGE_CONFLICTING_SERVICE_HISTORY}
              qaIdPrefix={`${qaIdPrefix}-mileage-suspicions-isMileageConflictingServiceHistory`}
              defaultValue={false}
              afterChange={() => {
                if (isSubmitted) {
                  trigger('mileage.mileageSuspicionsOptions');
                }
              }}
            />
            <FormCheckbox
              className={mileageStyles.checkbox}
              name="mileage.mileageSuspicions.isMileageConflictingCarCondition"
              uiLabel={translations.MILEAGE_CONDITION_SUSPICIOUS}
              qaIdPrefix={`${qaIdPrefix}-mileage-suspicions-isMileageConflictingCarCondition`}
              defaultValue={false}
              afterChange={() => {
                if (isSubmitted) {
                  trigger('mileage.mileageSuspicionsOptions');
                }
              }}
            />
            <FormCheckbox
              className={mileageStyles.checkbox}
              name="mileage.mileageSuspicions.isMileageConflictingDatabase"
              uiLabel={translations.MILEAGE_CONFLICTING_DATABASE}
              qaIdPrefix={`${qaIdPrefix}-mileage-suspicions-isMileageConflictingDatabase`}
              defaultValue={false}
              afterChange={() => {
                if (isSubmitted) {
                  trigger('mileage.mileageSuspicionsOptions');
                }
              }}
            />
            <div className={mileageStyles.checkboxWithTextField}>
              <FormCheckbox
                className={mileageStyles.checkbox}
                name="mileage.mileageSuspicions.isTachometerReplaced"
                uiLabel={translations.MILEAGE_INSTRUMENT_REPLACED}
                qaIdPrefix={`${qaIdPrefix}-mileage-suspicions-isTachometerReplaced`}
                defaultValue={false}
                afterChange={() => {
                  if (isSubmitted) {
                    trigger('mileage.mileageSuspicionsOptions');
                  }
                }}
              />
              {isTachometerReplaced && (
                <div className={mileageStyles.textFieldContainer}>
                  <FormNumberInput
                    qaIdPrefix={`${qaIdPrefix}-tachometer-replacement-mileage`}
                    label={translations.MILEAGE_AT_REPLACEMENT}
                    className={styles.input}
                    containerClassName={
                      mileageStyles.containerTachometerMileage
                    }
                    name="mileage.mileageSuspicions.tachometerReplacementMileage"
                    placeholder="Km"
                    defaultValue={null}
                    disabled={isTachometerReplacementMileageAvailable}
                  />
                  <FormCheckbox
                    name="mileage.mileageSuspicions.isTachometerReplacementMileageAvailable"
                    uiLabel={translations.UNKNOWN}
                    qaIdPrefix={`${qaIdPrefix}-tachometer-replacement-mileage-unknown`}
                    defaultValue={false}
                    containerClassName={mileageStyles.checkBoxLabel}
                    afterChange={(isChecked) => {
                      if (isChecked) {
                        setValue(
                          'mileage.mileageSuspicions.tachometerReplacementMileage',
                          null,
                        );
                      }
                      if (isSubmitted) {
                        trigger(
                          'mileage.mileageSuspicions.tachometerReplacementMileage',
                        );
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {errors.mileage?.mileageSuspicionsOptions && (
            <Typography
              tag="div"
              additonalClassNames={styles.message}
              variant="textXSRed"
              data-qa-id={`${qaIdPrefix}-mileage-suspicions-options-error-message`}
            >
              {errors.mileage.mileageSuspicionsOptions.message}
            </Typography>
          )}
        </>
      )}
    </Card>
  );
};

export { Mileage };
