import * as React from 'react';
import cn from 'classnames';
import type { InspectionsV2Query } from 'gql/graphql';
import { inspectionSources } from 'shared/constants';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { keyExtractor } from 'shared/utils/keyExtractor';
import styles from './index.module.scss';

type Inspection = Omit<
  NonNullable<InspectionsV2Query['inspectionsV2']>['inspections'][number],
  'status'
>;

type Props = {
  qaIdPrefix: string;
  inspectionsData: InspectionsV2Query;
  filterValue: string;
  handleOpenInspection: (_: Inspection) => void;
};

const Inspections: React.FC<Props> = ({
  qaIdPrefix,
  inspectionsData,
  filterValue,
  handleOpenInspection,
}) => {
  const { translations } = useTranslation();
  const inspectionsItems = inspectionsData?.inspectionsV2?.inspections ?? [];
  const { setNotCompletedSteps } = useInspection();

  React.useEffect(() => {
    setNotCompletedSteps([]);
  }, [setNotCompletedSteps]);

  return (
    <ul
      className={styles.inspectionList}
      data-qa-id={`${qaIdPrefix}-inspections-list`}
    >
      {inspectionsItems
        .filter((inspection) => {
          if (!inspection.car.stockNumber) {
            return false;
          }

          if (filterValue === '') {
            return true;
          }

          return inspection.car.stockNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        })
        .map((inspection, index) => {
          const { vehicle, car, branch, sourceType } = inspection;
          const frontLeftImage = vehicle?.images?.find(
            (image) => image.area === 'FRONT_LEFT',
          );
          return (
            <li
              className={styles.inspectionItem}
              key={keyExtractor(inspection, index)}
              data-qa-id={`${qaIdPrefix}-item-${index}`}
            >
              <a
                className={styles.itemLink}
                data-qa-id={`${qaIdPrefix}-item-link-${index}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenInspection(inspection);
                }}
              >
                <div className={styles.imageContainer}>
                  {frontLeftImage?.absoluteUrl && (
                    <img alt="Car" src={frontLeftImage?.absoluteUrl} />
                  )}
                </div>
                <div className={styles.itemInfo}>
                  <h3
                    className={styles.title}
                    data-qa-id={`${qaIdPrefix}-item-title`}
                  >
                    {vehicle?.model?.make} {vehicle?.model?.model}{' '}
                    {vehicle?.model?.subModel} {vehicle?.engine?.fuelType}
                  </h3>
                  <div
                    className={styles.secondaryInfo}
                    data-qa-id={`${qaIdPrefix}-item-secondary-info`}
                  >
                    {car?.stockNumber} ･ {branch?.name}
                    {sourceType === inspectionSources.PDF_INSPECTION && (
                      <span className={styles.pdfLabel}>
                        {translations.PDF_LABEL}
                      </span>
                    )}
                  </div>
                </div>
                <span
                  data-qa-id={`${qaIdPrefix}-item-label`}
                  className={cn(styles.label, {
                    [styles.failed]: inspection.status === 'FAILED',
                    [styles.piGiven]:
                      inspection.status === 'A1_PRICE_INDICATOR_GIVEN',
                  })}
                >
                  {translations[inspection.status as keyof typeof translations]}
                </span>
              </a>
            </li>
          );
        })}
    </ul>
  );
};

export { Inspections };
