import * as React from 'react';

type Props = {
  fill: string;
} & React.SVGAttributes<SVGSVGElement>;

const Checked: React.FC<Props> = ({ fill = '#a0a0a0', ...props }) => (
  <svg
    width="12px"
    height="10px"
    viewBox="0 0 12 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Checked</title>
    <g
      id="UI-design---Approved"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="InspectionCar-VIN-info"
        transform="translate(-386.000000, -264.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="Group-6" transform="translate(150.000000, 153.000000)">
          <polygon
            id="Checked"
            points="240 118.8 237.2 116 236.266667 116.933333 240 120.666667 248 112.666667 247.066667 111.733333"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

export { Checked };
