import * as React from 'react';

import { Skeleton } from 'shared/components/Skeleton';

import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
};

const CarPictureItemPlaceholder: React.FC<Props> = ({
  qaIdPrefix = 'car-pictures',
}) => {
  return (
    <div
      className={styles.colPlaceholder}
      data-qa-id={`${qaIdPrefix}-placeholder-picture-item`}
    >
      <Skeleton width="100%" height="131px" />
      <Skeleton width="100%" height="14px" style={{ marginTop: '8px' }} />
    </div>
  );
};

export { CarPictureItemPlaceholder };
