import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { AlertBar } from 'shared/components/AlertBar';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useInspection } from 'shared/components/InspectionContext';
import { useTranslation } from 'shared/hooks/useTranslation';

import styles from '../index.module.scss';
import type { Question } from '../types';
import { TestDrivePageSection } from './TestDriveQuestionSection';

type Props = {
  title: string;
  questionIds: ReadonlyArray<number>;
  questions?: ReadonlyArray<Question>;
  qaIdPrefix: string;
  id: string;
  showDisclaimerMsg?: boolean;
  section?: string;
};

const TestDriveSection: React.FC<Props> = ({
  id,
  questions = [],
  qaIdPrefix,
  title,
  questionIds,
  showDisclaimerMsg = false,
  section = '',
}) => {
  const { isPdfInspection } = useInspection();
  const {
    formState: { errors },
  } = useFormContext();
  const { translations } = useTranslation();

  const hasErrors = Object.keys(errors?.question ?? {}).length > 0;

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      showBottomBorder
      id={id}
      qaIdPrefix={`${qaIdPrefix}-container`}
    >
      <div
        className={section === 'findings' ? styles.titleFindings : styles.title}
      >
        <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
          {title}
        </Typography>
      </div>
      {isPdfInspection && showDisclaimerMsg && (
        <AlertBar
          message={
            hasErrors
              ? translations.TEST_DRIVE_PDF_VALIDATION_DISCLAIMER
              : translations.TEST_DRIVE_PDF_DISCLAIMER
          }
          type={hasErrors ? 'error' : 'info'}
          additionalClassNames={styles.subtitle}
        />
      )}

      <TestDrivePageSection
        questionIds={questionIds}
        questions={questions}
        qaIdPrefix={qaIdPrefix}
        section={section}
      />
    </Card>
  );
};

export { TestDriveSection };
