import { DatePicker } from '../DatePicker';
import { withFormItem } from '../withFormItem';

import styles from './index.module.scss';

const FormDateInput = withFormItem(DatePicker, {
  classNames: {
    error: styles.error,
  },
});

export { FormDateInput };
