import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { PdfJobStatus } from 'shared/constants';

type PdfInspectionData = {
  inspectionFromPdfStatus: {
    inspectionId: number | null;
    status: keyof typeof PdfJobStatus;
  };
};

const PDF_INSPECTION_STATUS = gql`
  query inspectionFromPdfStatus($pdfUrl: URL!) {
    inspectionFromPdfStatus(pdfUrl: $pdfUrl) {
      status
      inspectionId
    }
  }
`;
const REFETCH_MAX_ATTEMPT = 20;
const REFETCH_DELAY = 5_000; // in ms

export function usePdfInspectionStatus({
  pdfUrl,
  checkStatus,
  setPdfInspectionLoading,
}: {
  pdfUrl: string;
  checkStatus: boolean;
  setPdfInspectionLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const finalStatuses = [PdfJobStatus.FINISHED, PdfJobStatus.FAILED];
  const runningStatuses = [PdfJobStatus.PENDING, PdfJobStatus.IN_PROGRESS];
  const [refetchCount, setRefetchCount] = useState(0);
  const [pdfInspectionData, setPdfInspectionData] =
    useState<PdfInspectionData | null>(null);

  useEffect(() => {
    setRefetchCount(1);
  }, [checkStatus]);

  const { data, loading, refetch, error } = useQuery(PDF_INSPECTION_STATUS, {
    variables: {
      pdfUrl,
    },
    skip: !pdfUrl || !checkStatus,
    fetchPolicy: 'no-cache',
  });

  useEffect(
    () => {
      if (loading) {
        return undefined;
      }

      if (error) {
        setPdfInspectionLoading(false);
        return undefined;
      }

      if (
        checkStatus &&
        data?.inspectionFromPdfStatus?.status === PdfJobStatus.IN_PROGRESS &&
        refetchCount < REFETCH_MAX_ATTEMPT
      ) {
        const timeoutId = setTimeout(() => {
          setRefetchCount((count) => count + 1);
          refetch();
        }, REFETCH_DELAY);
        return () => {
          clearTimeout(timeoutId);
        };
      }

      if (finalStatuses.includes(data?.inspectionFromPdfStatus?.status)) {
        setPdfInspectionData(data);
        setPdfInspectionLoading(false);
        return undefined;
      }

      if (
        runningStatuses.includes(data?.inspectionFromPdfStatus?.status) &&
        refetchCount === REFETCH_MAX_ATTEMPT
      ) {
        // reached timeout
        setPdfInspectionData({
          inspectionFromPdfStatus: {
            ...data?.inspectionFromPdfStatus,
            status: PdfJobStatus.TIMED_OUT,
          },
        });
        setPdfInspectionLoading(false);
        return undefined;
      }

      setPdfInspectionLoading(false);

      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkStatus, data, loading, refetch, refetchCount, setPdfInspectionData],
  );

  return pdfInspectionData;
}
