import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, ButtonTypes, ButtonThemes } from '@auto1-ui/button';
import { appRoutes } from 'appRoutes';
import { Card } from 'shared/components/Card';
import Loader from 'shared/components/Loader';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import { Typography } from 'shared/components/Typography';
import { useInspection } from 'shared/components/InspectionContext';
import { useTranslation } from 'shared/hooks/useTranslation';

import { BottomAreaContent } from '../BottomAreaContent';
import { RadioItem } from './RadioItem';

import icon24h from 'assets/icons/24h-auction.svg';
import batchAuction from 'assets/icons/batch-auction.svg';
import styles from './index.module.scss';

type Form = { sellingChannel: string | null };

type Props = {
  qaIdPrefix?: string;
  setSellingChannel: (selectedValue: string | null) => void;
  isBatchAuctionEnabled: boolean;
  instantOfferButtonStatus: { [key: string]: boolean };
  instantOfferSubmitError?: { message?: string };
  bottomErrorMessage: string;
};

const ChooseSellingChannel: React.FC<Props> = ({
  qaIdPrefix = 'choose-selling-channel',
  setSellingChannel,
  isBatchAuctionEnabled,
  instantOfferButtonStatus,
  instantOfferSubmitError,
  bottomErrorMessage,
}) => {
  const { translations } = useTranslation();
  const { inspectionId } = useInspection();

  const formMethods = useForm<Form>({
    resolver: yupResolver(
      yup.object({
        sellingChannel: yup
          .string()
          .required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
      }),
    ),
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = formMethods;

  const sellingChannel = watch('sellingChannel');

  const onSubmit = ({ sellingChannel }: Form) => {
    setSellingChannel(sellingChannel);
  };

  const instantOfferTooltipProps = instantOfferButtonStatus.isDisabled
    ? {
      'data-microtip-position': 'top',
      'aria-label': translations.GET_INSTANT_PRICE_TOOLTIP,
      role: 'tooltip',
    }
    : {};

  return (
    <FormProvider {...formMethods}>
      <Card paddingTop={32} paddingBottom={32} qaIdPrefix={qaIdPrefix}>
        <Typography
          data-qa-id={`${qaIdPrefix}-title`}
          variant="titleXL"
          tag="h2"
          additonalClassNames={styles.title}
        >
          {translations.SELLING_CHANNEL}
        </Typography>
        {!instantOfferButtonStatus.isInvisible ? (
          <div className={styles.instantOfferOption}>
            <Typography
              tag="div"
              variant="titleL"
              data-qa-id={`${qaIdPrefix}-subtitle`}
              additonalClassNames={styles.subTitle}
            >
              {translations.SELL_DIRECTLY_TO_AUTO1}
            </Typography>
            <RadioItem
              name="sellingChannel"
              value="instant-offer"
              title={translations.INSTANT_OFFER}
              description={translations.INSTANT_OFFER_DESCRIPTION}
              qaIdPrefix={`${qaIdPrefix}-instant-offer`}
              isDisabled={instantOfferButtonStatus.isDisabled}
              tooltipProps={instantOfferTooltipProps}
            />
          </div>
        ) : null}
        <div className={styles.container}>
          <Typography
            tag="div"
            variant="titleL"
            data-qa-id={`${qaIdPrefix}-subtitle`}
            additonalClassNames={styles.subTitle}
          >
            {translations.SELL_BY_AUCTION}
          </Typography>
          <RadioItem
            name="sellingChannel"
            value="remarketing-24h-auction"
            title={translations.REMARKETING_24H_AUCTION}
            description={translations.REMARKETING_24H_AUCTION_DESCRIPTION}
            qaIdPrefix={`${qaIdPrefix}-remarketing-24h-auction`}
            icon={<img src={icon24h} alt="Remarketing 24h auction icon" />}
          />
          {isBatchAuctionEnabled ? (<RadioItem
            name="sellingChannel"
            value="batch-auction"
            title={translations.BATCH_AUCTION}
            description={translations.BATCH_AUCTION_DESCRIPTION}
            qaIdPrefix={`${qaIdPrefix}-batch-auction`}
            icon={<img src={batchAuction} alt="Batch auction icon" />}
          />) : null}
        </div>
      </Card>
      <NavigationFooter
        backButtonLink={appRoutes.summary(`${inspectionId}`, true)}
        qaPrefix={qaIdPrefix}
        rightAreaContent={
          <>
            <Button
              type={ButtonTypes.button}
              theme={ButtonThemes.orange}
              extraClass={styles.submitButton}
              qaId={`${qaIdPrefix}-footer-submit-link`}
              isActivated={false}
              isDisabled={!sellingChannel || isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {translations.SUBMIT_AUCTION_BUTTON}
              {isSubmitting && <Loader extraClass={styles.buttonLoader} />}
            </Button>
          </>
        }
        bottomAreaContent={
          <BottomAreaContent
            instantOfferSubmitError={instantOfferSubmitError}
            bottomErrorMessage={bottomErrorMessage}
          />
        }
      />
    </FormProvider>
  );
};

export { ChooseSellingChannel };
