import * as yup from 'yup';
import type { Translations } from 'shared/utils/translations';

const phoneRegExp = /^(\+?)[\d]+$/;
const getRequiredMessage = (translations: Translations) => {
  return translations.THIS_FIELD_CANNOT_BE_EMPTY;
};
const locationSettingsFormSchema = (translations: Translations) => {
  const requiredString = yup
    .string()
    .nullable()
    .required(getRequiredMessage(translations));

  return yup.object().shape({
    name: requiredString,
    address: yup
      .mixed()
      .when(['streetNumber', 'street', 'zipcode', 'city', 'country'], {
        is: (
          streetNumber: string,
          street: string,
          zipcode: string,
          city: string,
          country: string,
        ) => {
          return !streetNumber || !zipcode || !city || !country || !street;
        },
        then: requiredString,
      }),
    street: requiredString,
    phone: yup
      .string()
      .nullable()
      .required(getRequiredMessage(translations))
      .matches(phoneRegExp, translations.PHONE_NUMBER_IS_NOT_VALID),
    email: yup.string().nullable().email(translations.NOT_VALID_MAIL),
    streetNumber: requiredString,
    zipcode: requiredString,
    city: requiredString,
    country: requiredString,
  });
};

export { locationSettingsFormSchema };
