import omit from 'lodash/omit';
import type {
  CarDetailsInitialDataQuery,
  VehicleEngineFuelType,
  VehicleConditionCommercialType,
  Country,
  VehicleEngineEmissionNorm,
  InventoryType,
  VatType,
  OwnerType,
} from 'gql/graphql';
import { formatVinToUI } from 'shared/utils/formatVin';
import {
  isElectricCar,
  isHybridCar,
  BatteryOwnershipOptions,
  FuelTypeOptions,
} from 'shared/constants';
import {
  DOCUMENTS_AREA,
  REGISTRATION_PART,
  CERTIFICATE_PART,
  SUPPORTING_DOCUMENTS_PART,
} from '../constants';
import type { Form, ElectricCarMutation, Mutation } from '../types';

type Inspection =
  | Omit<NonNullable<CarDetailsInitialDataQuery['inspection']>, 'branch'>
  | null
  | undefined;

const reverseDate = (date: string) => {
  return date.split('/').reverse().join('/');
};

const mapFirstRegisterationDateQuery = (date: string) => {
  if (!date) return '';
  const dateWithDashes = date.replace(/-/g, '/');

  return reverseDate(dateWithDashes);
};

const mapFirstRegisterationDateMutation = (date: string) => {
  if (!date) return null;
  const dateWithDashes = date.replace(/\//g, '-');
  return dateWithDashes.split('-').reverse().join('-');
};

const getAvailabilityField = (
  inspection: Inspection,
  field: keyof NonNullable<
    NonNullable<NonNullable<Inspection>['meta']>['evaluation']
  >,
) => {
  const value = inspection?.meta?.evaluation?.[field];
  if (value === null || typeof value === 'undefined') {
    return null;
  }
  return !value;
};

const getElectricCarInfo = (inspection: Inspection) => {
  const getEngine = (fuelType?: VehicleEngineFuelType | null) => {
    if (isHybridCar(fuelType)) {
      return (
        inspection?.vehicle?.engine?.engineList?.find((engine) => {
          return engine?.engineFuelType === FuelTypeOptions.ELECTRIC;
        }) ?? {}
      );
    }
    return inspection?.vehicle?.engine ?? {};
  };

  const engine = getEngine(inspection?.vehicle?.engine?.fuelType);
  const getBatteryLeased = () => {
    if (
      engine?.batteryOwnership === null ||
      typeof engine?.batteryOwnership === 'undefined'
    ) {
      return null;
    }
    return engine?.batteryOwnership === BatteryOwnershipOptions.LEASED;
  };

  const getBatteryLeasingCost = () => {
    const val =
      inspection?.meta?.evaluation?.batteryLeasingCostPerMonthInMinorUnits;
    if (val === null || typeof val === 'undefined') {
      return null;
    }
    return Math.floor(val / 100);
  };

  return {
    batteryLeased: getBatteryLeased(),
    batteryLeasingCost: getBatteryLeasingCost(),
    isBatteryLeasingCostNotAvailable: getAvailabilityField(
      inspection,
      'isBatteryLeasingCostAvailable',
    ),
    isBatteryConditionReportAvailable:
      inspection?.meta?.evaluation?.isBatteryConditionReportAvailable ?? null,
    batteryHealth: engine?.batteryHealth ?? null,
    hasChargingCables: inspection?.meta?.evaluation?.hasChargingCables ?? null,
    isBatteryChargeLevelGuaranteed:
      inspection?.meta?.evaluation?.isBatteryChargeLevelGuaranteed ?? false,
    supportingDocuments:
      inspection?.vehicle?.images?.filter(
        (image) =>
          image.area === DOCUMENTS_AREA &&
          image.part === SUPPORTING_DOCUMENTS_PART,
      ) ?? [],
  };
};

const mapDataToFormValues = (inspection: Inspection): Form => {
  if (!inspection) {
    return {
      vin: '',
      licensePlate: '',
      externalId: '',
      otherNote: '',

      countryOfOrigin: '',
      firstRegistrationDate: '',
      inventoryType: '',
      envClass: '',
      co2Emission: null,
      co2EmissionAvailable: false,
      countryOfRegistration: '',
      totalOwnerCount: '',
      commercialUseType: '',
      vatType: '',
      carRegistrationImages: [],
      certificateImages: [],
      currentOwnerType: null,
      fuelType: null,
      buildDate: null,
    };
  }

  let totalOwners;
  if (
    inspection?.flags?.totalOwnersNumberAvailable === null &&
    inspection?.vehicle?.condition?.totalOwnerCount === null
  ) {
    totalOwners = '';
  } else {
    totalOwners = inspection?.flags?.totalOwnersNumberAvailable
      ? inspection?.vehicle?.condition?.totalOwnerCount
      : 'N/A';
  }
  const getCommercialUse = () => {
    const commercialUse =
      inspection?.vehicle?.condition?.commercialUseType?.[0];
    const wasInCommercialUse = inspection?.flags?.wasInCommercialUse;

    if (wasInCommercialUse === false) return 'NO';

    return commercialUse ?? null;
  };

  const co2EmissionAvailable = inspection?.flags?.co2EmissionAvailable;

  const {
    isBatteryConditionReportAvailable,
    hasChargingCables,
    batteryLeasingCostPerMonthInMinorUnits,
    isBatteryLeasingCostAvailable,
    ...evaluation
  } = inspection?.meta?.evaluation ?? {};
  const { batteryHealth, batteryOwnership, ...engine } =
    inspection?.vehicle?.engine ?? {};

  return {
    ...inspection?.vehicle?.condition,
    ...evaluation,
    ...engine,
    buildDate: inspection?.vehicle?.model?.buildDate ?? null,
    inventoryType: inspection?.meta?.evaluation?.inventoryType ?? null,
    countryOfOrigin: inspection?.vehicle?.condition?.countryOfOrigin ?? null,
    countryOfRegistration:
      inspection?.meta?.evaluation?.countryOfRegistration ?? null,
    vatType: inspection?.meta?.evaluation?.vatType ?? null,

    envClass: !inspection?.vehicle?.engine?.envClass
      ? 'N/A'
      : inspection?.vehicle?.engine?.envClass,
    vin: formatVinToUI(inspection?.vehicle?.identifiers?.vin ?? ''),
    externalId: inspection?.vehicle?.identifiers?.externalId ?? '',
    licensePlate: inspection?.vehicle?.identifiers?.licensePlate ?? '',
    co2Emission: inspection?.vehicle?.engine?.co2Emission ?? null,
    co2EmissionAvailable:
      co2EmissionAvailable !== null ? !co2EmissionAvailable : false,
    firstRegistrationDate: mapFirstRegisterationDateQuery(
      inspection?.vehicle?.condition?.firstRegistrationDate ?? '',
    ),
    commercialUseType: getCommercialUse(),
    totalOwnerCount: `${totalOwners ?? ''}`,
    carRegistrationImages:
      inspection?.vehicle?.images?.filter(
        (image) =>
          image.area === DOCUMENTS_AREA && image.part === REGISTRATION_PART,
      ) ?? [],
    certificateImages:
      inspection?.vehicle?.images?.filter(
        (image) =>
          image.area === DOCUMENTS_AREA && image.part === CERTIFICATE_PART,
      ) ?? [],
    otherNote: inspection?.meta?.evaluation?.otherNote ?? '',
    currentOwnerType: inspection?.meta?.evaluation?.currentOwnerType ?? null,
    electricCar: getElectricCarInfo(inspection),
    fuelType: inspection?.vehicle?.engine?.fuelType ?? null,
  };
};

const mapBatteryLeasingCostToMutation = ({
  batteryLeasingCost,
  isBatteryLeasingCostNotAvailable,
}: {
  batteryLeasingCost: number | null;
  isBatteryLeasingCostNotAvailable: boolean | null;
}) => {
  if (isBatteryLeasingCostNotAvailable || batteryLeasingCost === null) {
    return null;
  }
  return batteryLeasingCost * 100;
};

const mapElectricCarToMutation = ({
  data,
  fuelType,
}: {
  data: NonNullable<Form['electricCar']> | Record<string, never>;
  fuelType: VehicleEngineFuelType | null;
}): ElectricCarMutation => {
  if (!isElectricCar(fuelType)) {
    return {
      batteryLeased: null,
      isBatteryConditionReportAvailable: null,
      batteryHealth: null,
      hasChargingCables: null,
      batteryLeasingCostPerMonthInMinorUnits: null,
      isBatteryLeasingCostAvailable: null,
      isBatteryChargeLevelGuaranteed: false,
      supportingDocuments: [],
    };
  }

  const {
    batteryLeasingCost,
    isBatteryLeasingCostNotAvailable,
    supportingDocuments,
    ...electricCarData
  } = data as NonNullable<Form['electricCar']>;

  return {
    ...electricCarData,
    batteryLeasingCostPerMonthInMinorUnits: mapBatteryLeasingCostToMutation({
      batteryLeasingCost,
      isBatteryLeasingCostNotAvailable,
    }),
    isBatteryLeasingCostAvailable: electricCarData.batteryLeased
      ? !isBatteryLeasingCostNotAvailable
      : null,
    supportingDocuments: supportingDocuments.map((image) =>
      image instanceof File ? image : omit(image, '__typename'),
    ),
  };
};

const isElectricVehicleWithData = (electricVehicleData = {}) => {
  return Object.values(electricVehicleData).some((value) => {
    return (
      (Array.isArray(value) && value?.length > 0) ||
      (!Array.isArray(value) &&
        typeof value !== 'undefined' &&
        value !== null &&
        value !== '')
    );
  });
};

const mapFormDataToMutation = (
  data: Form,
  isPdfInspection = false,
): Mutation => {
  const {
    firstRegistrationDate,
    co2EmissionAvailable,
    totalOwnerCount,
    commercialUseType,
    envClass,
    electricCar = {},
    fuelType,
    countryOfOrigin,
    countryOfRegistration,
    inventoryType,
    vatType,
    currentOwnerType,
    buildDate,
    ...others
  } = data;
  others.carRegistrationImages =
    data.carRegistrationImages.map((image) =>
      image instanceof File ? image : omit(image, '__typename'),
    ) ?? [];
  others.certificateImages =
    data.certificateImages.map((image) =>
      image instanceof File ? image : omit(image, '__typename'),
    ) ?? [];

  const electricVehicleData = mapElectricCarToMutation({
    data: electricCar,
    fuelType,
  });

  return {
    ...others,
    countryOfOrigin: countryOfOrigin as Country,
    countryOfRegistration: countryOfRegistration as Country,
    inventoryType: inventoryType as InventoryType,
    vatType: vatType as VatType,
    currentOwnerType:
      vatType === 'NOT_DEDUCTIBLE'
        ? (currentOwnerType as OwnerType | null) ?? null
        : null,
    envClass:
      envClass === 'N/A' || envClass === ''
        ? null
        : (envClass as VehicleEngineEmissionNorm),
    firstRegistrationDate: mapFirstRegisterationDateMutation(
      firstRegistrationDate,
    ),
    co2EmissionAvailable: !co2EmissionAvailable,
    wasInCommercialUse: commercialUseType !== 'NO',
    commercialUseType:
      commercialUseType === 'NO'
        ? null
        : (commercialUseType as VehicleConditionCommercialType),
    totalOwnersNumberAvailable:
      totalOwnerCount === '' ? null : totalOwnerCount !== 'N/A',
    totalOwnerCount: ['N/A', ''].includes(totalOwnerCount)
      ? null
      : totalOwnerCount,
    ...electricVehicleData,
    skipElectricCar:
      !isElectricCar(fuelType) ||
      (isPdfInspection && !isElectricVehicleWithData(electricVehicleData)),
  };
};

export {
  mapDataToFormValues,
  mapFormDataToMutation,
  isElectricVehicleWithData,
};
