import * as React from 'react';
import type { ValidationError } from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import type { VehicleEngineFuelType } from 'gql/graphql';
import { RoutesEnum } from 'shared/utils/route';
import {
  calculateSectionProgress,
  getSectionProgress,
  getPageProgress,
  getSectionErrors,
  getSectionProgressYup,
} from 'shared/utils/calculateProgress';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { isElectricCar } from 'shared/constants';
import { CarDetailsSchema } from '../car-details.schema';
import { isElectricVehicleWithData } from '../helpers';
import type { Form } from '../types';

const VIN_INFO_FIELDS = 7; // including vin field
const CAR_INFO_FIELDS = 12;
const ELECTRIC_CAR_FIELDS = 3;

const getCarInfoProgress = ({
  vin,
  showManualFlow,
}: {
  vin?: string | null;
  showManualFlow: boolean;
}) => {
  let filledFields = 0;
  let totalFields;

  if (!showManualFlow) {
    totalFields = CAR_INFO_FIELDS + 1; // plus one for the vin field
    filledFields = CAR_INFO_FIELDS;
  } else {
    totalFields = VIN_INFO_FIELDS;
  }
  if (vin) {
    filledFields += 1;
  }

  return calculateSectionProgress(filledFields, totalFields);
};

const getAdditionalInfoProgress = ({
  fields = {},
  errors = [],
}: {
  fields: ReturnType<typeof CarDetailsSchema>['fields'] | Record<string, never>;
  errors: ReadonlyArray<ValidationError>;
}) => {
  const totalFields = Object.keys(fields).filter(
    (field) => field !== 'co2EmissionAvailable' && field !== 'electricCar',
  );
  const sectionErrors = errors.filter(
    ({ path }) => !path?.startsWith('electricCar'),
  );

  return getSectionProgressYup({
    errors: sectionErrors,
    totalFieldsNb: totalFields.length,
  });
};

const getConditionalFieldsNb = ({
  electricCar,
}: Form | Record<string, never>) => {
  let fieldsNb = 0;
  if (electricCar?.batteryLeased === true) {
    fieldsNb += 1;
  }
  if (electricCar?.isBatteryConditionReportAvailable === true) {
    fieldsNb += 1;
  }
  return fieldsNb;
};

const getElectricCarInfoProgress = ({
  formValues = {},
  errors = [],
  isOptional,
  fuelType,
}: {
  formValues: Form | Record<string, never>;
  errors: ReadonlyArray<ValidationError>;
  isOptional: boolean;
  fuelType: VehicleEngineFuelType | null;
}) => {
  const sectionErrors = getSectionErrors(errors, 'electricCar');
  return getSectionProgress({
    errors: sectionErrors,
    totalFieldsNb:
      ELECTRIC_CAR_FIELDS +
      getConditionalFieldsNb(formValues) +
      (fuelType === 'ELECTRIC' ? 1 : 0),
    isOptional,
  });
};

type GetCarDetailsProgressArgs = {
  vin?: string | null;
  showManualFlow: boolean;
  formValues?: Form;
};

const useProgress = () => {
  const { translations } = useTranslation();
  const { setProgress, isPdfInspection } = useInspection();

  const getCarDetailsProgress = React.useCallback(
    ({ vin, showManualFlow, formValues }: GetCarDetailsProgressArgs) => {
      const electricCarHasData = isElectricVehicleWithData(
        formValues?.electricCar,
      );

      const validationSchema = CarDetailsSchema(
        translations,
        false,
        isPdfInspection && !electricCarHasData,
      );
      const fields = validationSchema.fields;

      const isElectricVehicle = () => {
        return formValues?.fuelType
          ? isElectricCar(formValues?.fuelType)
          : false;
      };

      validationSchema
        .validate(cloneDeep(formValues), {
          abortEarly: false,
        })
        .then(() => {
          setProgress({
            [RoutesEnum.CAR_DETAILS_CAR_INFO]: 100,
            [RoutesEnum.CAR_DETAILS_ADDITIONAL_INFO]: 100,
            [RoutesEnum.CAR_DETAILS]: 100,
            [RoutesEnum.CAR_DETAILS_ELECTRIC_CAR_INFO]: 100,
          });
        })
        .catch(({ inner: errors }) => {
          const carInfoProgress = getCarInfoProgress({
            vin,
            showManualFlow,
          });

          const additionalInfoProgress = getAdditionalInfoProgress({
            fields,
            errors,
          });
          const progressArray = [additionalInfoProgress, carInfoProgress];

          const electricCarInfoProgress = getElectricCarInfoProgress({
            formValues: formValues as Form | Record<string, never>,
            errors,
            isOptional: isElectricVehicle(),
            fuelType: formValues?.fuelType ?? null,
          });

          if (isElectricVehicle()) {
            progressArray.push(electricCarInfoProgress);
          }

          setProgress({
            [RoutesEnum.CAR_DETAILS_CAR_INFO]: carInfoProgress,
            [RoutesEnum.CAR_DETAILS_ADDITIONAL_INFO]: additionalInfoProgress,
            [RoutesEnum.CAR_DETAILS]: getPageProgress(progressArray),
            [RoutesEnum.CAR_DETAILS_ELECTRIC_CAR_INFO]: electricCarInfoProgress,
          });
        });
    },
    [isPdfInspection, translations, setProgress],
  );

  return getCarDetailsProgress;
};

export { useProgress };
