import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { appRoutes } from 'appRoutes';
import { formatVinToAPI } from 'shared/utils/formatVin';
import { useTrackManualFlow } from './useTrackManualFlow';
import { useTracking } from './useTracking';

const useRedirectToInspection = () => {
  const history = useHistory();
  const { trackClickManualFlow } = useTrackManualFlow();
  const { trackRedirectToCarDetails } = useTracking();

  const handleCompleted = React.useCallback(
    ({
      result: { vehicle = {}, inspectionId },
      branchId,
      vinValue = '',
      action,
    }) => {
      const vin = vehicle?.identifiers?.vin ?? formatVinToAPI(vinValue);

      if (vehicle?.model) {
        history.push(appRoutes.carDetails(inspectionId, true));
        if (action === 'loadVIN') {
          trackRedirectToCarDetails({
            action,
            inspectionId,
            vin,
          });
        }
      } else {
        if (inspectionId) {
          history.push({
            pathname: appRoutes.manualFlow(inspectionId, true),
            state: { vin },
          });
        } else {
          history.push({
            pathname: appRoutes.manualFlowWithBranch(branchId, true),
            state: { vin },
          });
        }
        trackClickManualFlow({
          vin,
        });
      }
    },
    [history, trackClickManualFlow, trackRedirectToCarDetails],
  );

  const handleTimeoutError = React.useCallback(
    ({ branchId, vinValue = '' }) => {
      history.push({
        pathname: appRoutes.manualFlowWithBranch(branchId, true),
        state: { vin: formatVinToAPI(vinValue) },
      });
      trackClickManualFlow({
        vin: formatVinToAPI(vinValue),
      });
    },
    [history, trackClickManualFlow],
  );

  return { handleCompleted, handleTimeoutError, trackClickManualFlow };
};

export { useRedirectToInspection };
