import * as React from 'react';
import omit from 'lodash/omit';
import type { MutationResult } from '@apollo/client';
import type {
  VehicleMimeType,
  AccidentArea,
  EvaReplaceAccidentsMutation,
  VehicleFileInput,
  VehicleFileType,
} from 'gql/graphql';
import { useEvaReplaceNewAccidentsMutation } from 'gql/graphql';
import { useMultipleMediaUploader } from 'shared/utils/useMultipleMediaUploader';
import { fileFilter } from 'shared/utils/fileFilter';
import { mimeTypeToVehicleFileMimeType } from 'shared/utils/mimeType';
import { mapAccidentAreasToMutation } from '../helpers';
import type { ImageData, AccidentMutation, ReasonByArea } from '../types';

function accidentDataBuilder(
  data: AccidentMutation,
  accidentFiles: VehicleFileInput[],
  reasonImages: { area: string; reason: string; files: ImageData[] }[],
) {
  const { hasHadAccident, areas, ...restData } = data;
  if (!hasHadAccident) {
    return null;
  }

  return {
    ...restData,
    files: accidentFiles.map((image) => ({
      ...omit(image, '__typename'),
      type: 'INVOICE' as VehicleFileType,
    })),
    ...(areas
      ? {
          areas: mapAccidentAreasToMutation(areas, reasonImages),
        }
      : {}),
  };
}

function nonNullableImages(
  accidentReason: [string, (File | ImageData)[] | null],
): accidentReason is [string, (File | ImageData)[]] {
  return accidentReason[1] !== null && accidentReason[1] !== undefined;
}

function buildAreaReasonImageMapper(
  areas: Partial<Record<AccidentArea, ReasonByArea>>,
  imageUploader: ReturnType<typeof useMultipleMediaUploader>['uploadMedia'],
  stockNumber: string,
) {
  return Promise.all(
    Object.entries(areas)
      .filter(([_, areaReason]) => !!areaReason)
      .flatMap(([area, areaReason]) =>
        Object.entries(areaReason)
          .filter(nonNullableImages)
          .flatMap(([reason, images]) =>
            imageUploader(
              images
                .filter((file): file is File => file instanceof File)
                .map((file) => ({
                  stockNumber,
                  file,
                  part: `vehicle__accident_${area.toLowerCase()}_${reason.toLowerCase()}`,
                  source: 11,
                })),
            ).then((results) => ({
              area,
              reason,
              files: [
                ...images.filter(
                  (fileData): fileData is ImageData =>
                    !(fileData instanceof File),
                ),
                ...results.map(
                  ({
                    data: {
                      wkda: { url },
                    },
                  }) => ({
                    absoluteUrl: url,
                    mimeType: 'IMAGE_JPEG' as VehicleMimeType,
                  }),
                ),
              ],
            })),
          ),
      ),
  );
}

type UpdateAccidents = ({
  optionalSchema,
  accident,
}: {
  optionalSchema: boolean;
  accident: AccidentMutation;
}) => Promise<void>;

export function useAccidentsUpdater(
  inspectionId: number,
  stockNumber: string | null,
) {
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const { uploadMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );

  const [evaReplaceNewAccidentsMutation, { loading, error }] =
    useEvaReplaceNewAccidentsMutation();

  const updateAccidents: UpdateAccidents = async ({
    optionalSchema,
    accident,
  }) => {
    if (!stockNumber) {
      throw new Error('stock number is null');
    }

    setIsUploading(true);
    const accidentFiles = fileFilter(accident?.files ?? []);
    const [accidentFilesToUpload, areaReasonImagesToUpload] = await Promise.all(
      [
        uploadMedia(
          accidentFiles.files.map((file) => ({
            stockNumber,
            file,
            part: `vehicle__accident_documents_invoice`,
            source: 11,
          })),
        ).then((results) => [
          ...accidentFiles.previouslyUploadedImages,
          ...results.map(
            ({
              data: {
                wkda: { url },
              },
              mimeType,
            }) => ({
              absoluteUrl: url,
              mimeType: mimeTypeToVehicleFileMimeType(mimeType) ?? 'IMAGE_JPEG',
            }),
          ),
        ]),
        buildAreaReasonImageMapper(
          accident?.areas ?? {},
          uploadMedia,
          stockNumber,
        ),
      ],
    );
    setIsUploading(false);

    const accidentData = accidentDataBuilder(
      accident,
      accidentFilesToUpload,
      areaReasonImagesToUpload,
    );

    await evaReplaceNewAccidentsMutation({
      variables: {
        inspectionId,
        incomplete: optionalSchema,
        hasHadAccident: accident.hasHadAccident,
        accidents: [...(accidentData ? [accidentData] : [])],
      },
    });
  };

  return [updateAccidents, { loading: isUploading || loading, error }] as [
    UpdateAccidents,
    MutationResult<EvaReplaceAccidentsMutation>,
  ];
}
