import * as React from 'react';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { NavigationFooterPlaceholder } from 'shared/components/NavigationFooter/Placeholder';
import { MileagePlaceholder } from './Mileage/Placeholder';
import { TestDriveSectionPlaceholder } from './components/TestDriveSectionPlaceholder';

const Placeholder: React.FC = () => (
  <LayoutContainer>
    <MileagePlaceholder />
    <TestDriveSectionPlaceholder />
    <NavigationFooterPlaceholder />
  </LayoutContainer>
);

export { Placeholder };
