import * as React from 'react';

import { DatePickerFormat } from './DatePickerFormat';
import type { DatePickerFormatType } from './DatePickerFormat';

type Props = {
  dateType: DatePickerFormatType;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  qaId: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const formatDateTypeMonth = (value: string) => {
  if (value?.match(/\//) !== null) {
    return value.replace(/[^\d/]/g, '');
  }

  let newValue = value.replace(/\D/g, '').slice(0, 6);
  if (newValue.length > 2) {
    newValue = `${newValue.slice(0, 2)}/${newValue.slice(2)}`;
  }

  return newValue;
};

const formatDateTypeDay = (value: string) => {
  if (value?.match(/\//g)?.length === 2) {
    return value.replace(/[^\d/]/g, '');
  }

  let newValue = value.replace(/\D/g, '').slice(0, 8);
  if (newValue.length > 4) {
    newValue = `${newValue.slice(0, 2)}/${newValue.slice(
      2,
      4,
    )}/${newValue.slice(4)}`;
  } else if (newValue.length > 2) {
    newValue = `${newValue.slice(0, 2)}/${newValue.slice(2)}`;
  }

  return newValue;
};

const formatValue = (value: string, dateType: DatePickerFormatType) => {
  if (dateType === DatePickerFormat.Day) {
    return formatDateTypeDay(value);
  }

  return formatDateTypeMonth(value);
};

const InputDate = React.forwardRef<HTMLInputElement, Props>(
  ({ dateType, onChange = () => {}, qaId, ...props }, ref) => (
    <input
      type="text"
      ref={ref}
      {...props}
      onChange={(evt) => {
        onChange({
          ...evt,
          target: {
            ...evt.target,
            value: formatValue(evt.target.value, dateType),
          },
        });
      }}
      data-qa-id={qaId}
    />
  ),
);

InputDate.displayName = 'InputDate';

export { InputDate };
