import * as React from 'react';
import styles from './index.module.scss';

const Front: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g className={styles.stroke} strokeWidth="1.5" mask="url(#prefix__d)">
          <g transform="translate(11.667 12.709)">
            <path d="M23.92 44.74c53.075-3.603 53.562-3.603 106.15 0 1.395 2.14 7.054 12.162-8.5 30.123 0 0-41.175 5.301-44.575 5.301-3.4 0-33.69-3.772-44.575-5.3-6.615-4.567-15.438-22.604-8.5-30.125z" />
            <path d="M76.956 0h24.898c2.433 0 10.251 7.447 22.26 23.285 12.313 10.571 27.224 15.358 28.198 29.506.974 14.148.07 29.319-6.807 44.35-5.207 8.441-15.39 8.441-18.39 8.441-3.002 0-10.237-3.468-14.254-3.468-4.017 0-32.502 1.871-35.905 1.871-3.404 0-33.52-1.871-36.573-1.871-3.053 0-13.006 3.468-17.437 3.468-4.43 0-13.447-5.615-15.337-8.442-4.36-5.129-9.394-30.812-6.983-44.349 2.41-13.537 18.55-22.836 28.125-29.506C34.843 15.319 46.841 0 50.895 0h26.06z" />
            <path d="M51.392 2.73c16.905-1.033 33.848-1.033 50.827 0 7.398 5.887 12.538 12.472 15.42 19.755H35.972c4.911-9.099 10.051-15.684 15.42-19.755zM130.095 17.113c.341-1.938.205-3.035-.409-3.293-.92-.385-6.098.113-7.058.857s-2.71 3.577-1.835 4.258c.875.682 5.762 1.595 6.252 1.595.327 0 1.343-1.139 3.05-3.417zM22.544 17.113c-.341-1.938-.205-3.035.409-3.293.92-.385 6.098.113 7.058.857s2.71 3.577 1.835 4.258c-.875.682-5.762 1.595-6.252 1.595-.327 0-1.343-1.139-3.05-3.417zM6.543 40.004L21.118 51.749M27.222 69.9C16.782 69.148 7.71 64.911 0 57.19" />
            <path
              d="M131.521 40.004L146.096 51.749"
              transform="matrix(-1 0 0 1 277.617 0)"
            />
            <path d="M125.417 69.9c10.44-.751 19.513-4.988 27.222-12.71" />
            <rect width="55.861" height="11.209" x="48.389" y="84.825" rx="2" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { Front };
