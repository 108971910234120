import type { Translations } from 'shared/utils/translations';
import {
  SERVICE_BOOK_APPOINTMENTS_NONE,
  SERVICE_BOOK_APPOINTMENTS_ALL,
  SERVICE_BOOK_APPOINTMENTS_SOME,
  SERVICE_BOOK_APPOINTMENTS_NOT_DUE_YET,
} from 'pages/ServicesDocuments/constants';
import { Upholstery } from 'pages/CarStatus/car-status-data';
import { allCountries } from 'shared/utils/allCountries';

const getServiceAppointment = (translations: Translations) => {
  return {
    [SERVICE_BOOK_APPOINTMENTS_ALL]:
      translations.SERVICE_ANSWER_SERVICE_APPOINTMENTS_ALL,
    [SERVICE_BOOK_APPOINTMENTS_SOME]:
      translations.SERVICE_ANSWER_SERVICE_APPOINTMENTS_SOME,
    [SERVICE_BOOK_APPOINTMENTS_NONE]:
      translations.SERVICE_ANSWER_SERVICE_APPOINTMENTS_NONE,
    [SERVICE_BOOK_APPOINTMENTS_NOT_DUE_YET]:
      translations.SERVICE_ANSWER_SERVICE_APPOINTMENTS_NOT_DUE,
  };
};

const descriptionGenerator = ({
  title,
  description,
  translations,
  translate,
}: {
  title: string;
  description?: string | number | string[];
  translations: Translations;
  translate: (key: string) => string;
}) => {
  if (title === translations.UPHOLSTERY) {
    return Upholstery()[description as keyof ReturnType<typeof Upholstery>];
  }

  if (title === translations.TOTAL_OWNERS) {
    if (description === 0) return 0;
    if (typeof description === 'undefined') return ' ';
    return translate(
      (description as string | undefined) || 'a1-common-not-available',
    );
  }

  if (title === translations.AIR_CONDITIONING) {
    return Array.isArray(description) && description.length > 0
      ? description
          .map((item) => {
            return translate(item);
          })
          .join(', ')
      : translate('a1-common-not-available');
  }

  if (title === translations.SERVICE_APPOINTMENT) {
    return (
      getServiceAppointment(translations)[
        description as keyof ReturnType<typeof getServiceAppointment>
      ] ?? translate('a1-common-not-available')
    );
  }

  if (title === translations.LAST_SERVICE_DATE) {
    return description || translate('a1-common-not-available');
  }

  if (title === translations.COUNTRY_OF_REGISTRATION) {
    const translationKey = `global.country-code-${description}`;
    const label = translate(translationKey);
    const hasTranslation = translationKey !== label;
    return hasTranslation
      ? label
      : allCountries[description as keyof typeof allCountries];
  }

  return translate(
    (description as string | undefined) ?? 'a1-common-not-available',
  );
};

export { descriptionGenerator };
