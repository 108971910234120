import * as React from 'react';
import { Button } from '@auto1-ui/button';
import cn from 'classnames';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';
import { Typography } from '../Typography';

type Props = {
  title: string | React.ReactNode;
  showOptional?: boolean;
  isOpenInitially?: boolean;
  qaIdPrefix?: string;
};

const CollapseLink: React.FC<Props> = ({
  children,
  isOpenInitially = false,
  title,
  showOptional = true,
  qaIdPrefix = 'link-accordion',
}) => {
  const [isOpen, setOpen] = React.useState(isOpenInitially);
  const { translations } = useTranslation();
  return (
    <>
      <div
        className={cn(styles.accordionWrapper, { [styles.isExpanded]: isOpen })}
        data-qa-id={`${qaIdPrefix}-wrapper`}
      >
        <div
          className={styles.label}
          data-qa-id={`${qaIdPrefix}-label-container`}
        >
          <Button
            theme="link"
            onClick={() => setOpen(!isOpen)}
            extraClass={styles.button}
            qaId={`${qaIdPrefix}-toggle-link`}
          >
            {title}
          </Button>
          {showOptional && (
            <Typography
              data-qa-id={`${qaIdPrefix}-optional-label`}
              additonalClassNames={styles.optionalLabel}
            >
              {translations.OPTIONAL}
            </Typography>
          )}
        </div>
        <div
          data-qa-id={`${qaIdPrefix}-content-container`}
          className={styles.accordionContent}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export { CollapseLink };
