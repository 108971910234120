import { heicConvert } from 'shared/utils/heic-convert';

function processHeicFile(file: File): Promise<File> {
  if (
    file.type === 'image/heic' ||
    file.type === 'image/heif' ||
    file.name.toLowerCase().endsWith('.heic') ||
    file.name.toLowerCase().endsWith('.heif')
  ) {
    return heicConvert(file);
  }

  return Promise.resolve(file);
}

export { processHeicFile };
