import { useUserPermissionsContext } from 'shared/components/PermissionsContext';

function useHasPermissions(permissions: string[]) {
  const { userPermissions } = useUserPermissionsContext();

  if (
    permissions.every(
      (permission) =>
        userPermissions &&
        permission in userPermissions &&
        userPermissions[permission as keyof typeof userPermissions] === true,
    )
  ) {
    return true;
  }
  return false;
}

export { useHasPermissions };
