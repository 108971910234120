import * as React from 'react';
import { useHasPermissions } from 'auth/hooks/useHasPermissions';

type Props = {
  permissions: string[];
};

const PermissionGuard: React.FC<Props> = ({ permissions, children }) => {
  const hasPermissions = useHasPermissions(permissions);

  if (hasPermissions) {
    return <>{children}</>;
  }
  return null;
};

export { PermissionGuard };
