import * as React from 'react';
import type { ValidationError } from 'yup';
import type { VehicleEngineFuelType } from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { RoutesEnum } from 'shared/utils/route';
import {
  getSectionProgress,
  getPageProgress,
  getSectionErrorsYup,
} from 'shared/utils/calculateProgress';
import { useInspection } from 'shared/components/InspectionContext';
import { isFullyElectricCar } from 'shared/constants';
import { CarStatusSchema } from '../car-status-schema';

const sections = [
  {
    sectionName: RoutesEnum.CAR_STATUS_EQUIPMENTS,
    prefix: 'equipments',
    isOptional: false,
    requiredFieldsNb: 0,
  },
  {
    sectionName: RoutesEnum.CAR_STATUS_OTHER_EQUIPMENTS,
    prefix: 'otherEquipments',
    isOptional: true,
    requiredFieldsNb: 0,
  },
  {
    sectionName: RoutesEnum.CAR_STATUS_OTHER_INFORMATION,
    prefix: 'otherInformation',
    isOptional: false,
    requiredFieldsNb: 5,
  },
] as const;

type GetConditionalFieldsNbArgs = {
  fuelType: VehicleEngineFuelType | null;
  otherInformation: {
    hasTuning: boolean;
    hasLpg: boolean;
  };
};
const getConditionalFieldsNb = ({
  fuelType,
  otherInformation,
}: GetConditionalFieldsNbArgs) => {
  let fieldsNb = 0;
  if (otherInformation?.hasTuning === true) {
    fieldsNb += 1;
  }
  if (!isFullyElectricCar(fuelType)) {
    fieldsNb += 1;
    if (otherInformation?.hasLpg === true) {
      fieldsNb += 1;
    }
  }

  return fieldsNb;
};

const useProgress = () => {
  const { setProgress } = useInspection();
  const { translations } = useTranslation();
  const getCarStatusProgress = React.useCallback(
    (formValues) => {
      const progressArray: number[] = [];
      const validationSchema = CarStatusSchema(translations, false);

      validationSchema
        .validate(formValues, { abortEarly: false })
        .then(() => {
          const sectionsProgress = sections.reduce((acc, { sectionName }) => {
            acc[sectionName] = 100;

            return acc;
          }, {} as Record<typeof sections[number]['sectionName'], number>);

          setProgress({
            [RoutesEnum.CAR_STATUS]: 100,
            ...sectionsProgress,
          });
        })
        .catch(({ inner: errors }: ValidationError) => {
          const sectionsProgress = sections.reduce(
            (acc, { sectionName, requiredFieldsNb, prefix, isOptional }) => {
              let totalFieldsNb;
              const sectionErrors = getSectionErrorsYup(errors, prefix);

              if (sectionName === RoutesEnum.CAR_STATUS_EQUIPMENTS) {
                totalFieldsNb = formValues?.[prefix]?.items.length;
              } else {
                totalFieldsNb =
                  requiredFieldsNb + getConditionalFieldsNb(formValues);
              }

              const progress = getSectionProgress({
                errors: sectionErrors,
                totalFieldsNb,
                isOptional,
              });

              if (!isOptional) {
                progressArray.push(progress);
              }

              acc[sectionName] = progress;

              return acc;
            },
            {} as Record<typeof sections[number]['sectionName'], number>,
          );

          setProgress({
            [RoutesEnum.CAR_STATUS]: getPageProgress(progressArray),
            ...sectionsProgress,
          });
        });
    },
    [setProgress, translations],
  );

  return getCarStatusProgress;
};

export { useProgress };
