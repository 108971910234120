import * as React from 'react';
import { apm } from '@elastic/apm-rum';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, ButtonTypes } from '@auto1-ui/button';
import { useHistory } from 'react-router-dom';
import type {
  DamageInput,
} from 'gql/graphql';
import {
  useDamagesInitialDataQuery,
  useReplaceDamagesMutation,
} from 'gql/graphql';
import { appRoutes } from 'appRoutes';
import type { Translations } from 'shared/utils/translations';

import { AlertBar } from 'shared/components/AlertBar';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import Loader from 'shared/components/Loader';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import { useInspection } from 'shared/components/InspectionContext';
import stylesNavigationFooter from 'shared/components/NavigationFooter/index.module.scss';
import { buildMultipleImageMapper } from 'pages/CarQuality/hooks/useCarQualityUpdater';
import { useMultipleMediaUploader } from 'shared/utils/useMultipleMediaUploader';
import { scrollToError } from 'shared/utils/scrollToError';
import { RoutesEnum } from 'shared/utils/route';

import { DamageFields } from './components/DamageFields';
import { useProgress } from './hooks/useProgress';
import { Placeholder } from './Placeholder';
import { getDamagesSchema } from './schema';
import {
  getPaintThicknessData,
  mapDataToForm,
  mapDamagesToMutation,
  isPaintThicknessSkipped,
} from './helpers';
import type { Form } from './types';

import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
};

const Damages: React.FC<Props> = ({ qaIdPrefix = 'damages' }) => {
  const history = useHistory();
  const { translations, translate: t } = useTranslation();
  const formMethods = useForm<Form>({
    resolver: (values, context, options) =>
      yupResolver(
        getDamagesSchema(
          translations,
          formContext?.current === 'save',
        ),
      )(values, context, options),
  });
  const {
    getValues,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = formMethods;
  const {
    inspectionId,
    stockNumber,
    removeStepFromNotCompleted,
    shouldUseNewAccidentModel,
  } = useInspection();
  const formContext = React.useRef<'save' | 'submit'>();
  const { data, loading } = useDamagesInitialDataQuery({
    variables: { inspectionId },
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      reset({
        damages: mapDataToForm(result.inspection),
      });
    },
  });

  const { submitTracking } = useSubmitTracking('damages');

  const paintThicknessThreshold = data?.inspection?.fields?.vehicle?.damageAutoCreation?.paintThicknessTooHigh?.threshold as number;

  const [replaceDamages, { loading: mutationLoading, error: mutationError }] =
    useReplaceDamagesMutation();
  const { uploadMedia: uploadDamagesMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );
  const [uploadError, setUploadError] = React.useState('');
  const serverError = mutationError?.graphQLErrors?.[0]?.message ?? uploadError;

  const setProgress = useProgress();

  const onSubmit = async (values: Form) => {
    const optionalSchema = formContext.current === 'save';
    const redirect = formContext.current === 'submit';

    try {
      const damages = mapDamagesToMutation(values);
      const damagesToUpload = await buildMultipleImageMapper(
        damages,
        uploadDamagesMedia,
        stockNumber,
      );
      await replaceDamages({
        variables: {
          inspectionId,
          incomplete: optionalSchema,
          damages: damagesToUpload as DamageInput[],
        },
      });
      reset(getValues());
      removeStepFromNotCompleted(RoutesEnum.CAR_QUALITY);

      if (redirect) {
        submitTracking();
        if (shouldUseNewAccidentModel) {
          history.push(appRoutes.accidents(`${inspectionId}`, true));
        } else {
          history.push(appRoutes.tiresBrakes(`${inspectionId}`, true));
        }
      }
    } catch (e) {
      console.log('catch', e);
      apm.captureError(e as Error);
      setUploadError((e as Error).message);
    }
  };

  const paintThicknessData = getPaintThicknessData(data?.inspection);

  const onError = () => {
    const formData = getValues();
    scrollToError();
    setProgress(
      formData,
      paintThicknessData,
    );
  };

  if (loading || !data?.inspection) {
    return <Placeholder />;
  }

  return (
    <LayoutContainer>
      <FormProvider {...formMethods}>
        <Card
          paddingTop={32}
          paddingBottom={32}
          id="damages"
          qaIdPrefix={qaIdPrefix}
          showBottomBorder
        >
          <Typography
            variant="titleXL"
            data-qa-id={`${qaIdPrefix}-damages-title`}
            tag="div"
            additonalClassNames={styles.title}
          >
            {translations.DAMAGES_HEADER}
          </Typography>

          <Typography
            tag="div"
            data-qa-id={`${qaIdPrefix}-damages-subtitle`}
            additonalClassNames={styles.subtitle}
          >
            {isPaintThicknessSkipped(paintThicknessData) ?
              translations.DAMAGES_DESCRIPTION :
              t('a1-inspectionApp-damages-auto-updated-message', {
                s: `${paintThicknessThreshold}`,
              })}
          </Typography>
          <DamageFields
            qaIdPrefix={`${qaIdPrefix}-damages`}
            paintThicknessThreshold={paintThicknessThreshold}
          />
        </Card>
        <NavigationFooter
          backButtonLink={appRoutes.carQuality(`${inspectionId}`, true)}
          qaPrefix={qaIdPrefix}
          rightAreaContent={
            <>
              <Button
                type={ButtonTypes.button}
                qaId={`${qaIdPrefix}-footer-save-link`}
                onClick={() => {
                  formContext.current = 'save';
                  handleSubmit(onSubmit, onError)();
                }}
                theme="outlined"
                isActivated={false}
                extraClass={stylesNavigationFooter.saveButton}
                isDisabled={isSubmitting}
              >
                {translations.SAVE}
                {isSubmitting && formContext.current === 'save' && (
                  <Loader
                    extraClass={stylesNavigationFooter.saveButtonLoader}
                  />
                )}
              </Button>
              <Button
                type={ButtonTypes.button}
                qaId={`${qaIdPrefix}-footer-next-link`}
                isActivated={false}
                onClick={() => {
                  formContext.current = 'submit';
                  handleSubmit(onSubmit, onError)();
                }}
                theme="orange"
                extraClass={stylesNavigationFooter.nextButton}
                isDisabled={isSubmitting}
              >
                {translations.NEXT}
                {(isSubmitting || mutationLoading) &&
                  formContext.current === 'submit' && (
                    <Loader
                      extraClass={stylesNavigationFooter.nextButtonLoader}
                    />
                  )}
              </Button>
            </>
          }
          bottomAreaContent={
            serverError && (
              <AlertBar
                type="error"
                message={
                  serverError in translations
                    ? translations[serverError as keyof Translations]
                    : translations.GENERIC_ERROR_MESSAGE
                }
                additionalClassNames={styles.alertBar}
              />
            )
          }
        />
        <RouteLeavingGuard when={isDirty} title={translations.UNSAVED_CHANGES}>
          <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
        </RouteLeavingGuard>
      </FormProvider>
    </LayoutContainer>
  );
};

export { Damages };
