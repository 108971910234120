import * as React from 'react';
import styles from './index.module.scss';

const FrontLeft: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g className={styles.stroke} mask="url(#prefix__d)">
          <g>
            <path
              strokeWidth="1.5"
              d="M133.305 22.963s-.136-4.923 1.869-5.36c2.004-.437 11.102-.264 11.58 2.002.478 2.266.648 5.411-2.233 5.776-2.881.366-13.991.42-14.556 0-.565-.418.067-2.418 3.34-2.418zM68.486 58.007c9.893-4.546 32.175-9.18 34.734-6.139 2.559 3.04 1.55 5.536-6.051 13.419-7.601 7.883-38.45 8.578-39.383 7.883 0 0 5.82-12.13 10.7-15.163zM119.838 57.468c1.128.363 1.966 1.205 2.595 2.36.77 1.413 1.228 3.282 1.468 5.414.628 5.588-.264 12.94-1.465 18.953-1.056 5.292-3.204 10.259-5.726 13.74-1.227 1.694-2.537 3.032-3.856 3.862-1.125.708-2.25 1.045-3.316.832-1.185-.237-2.239-1.19-3.13-2.6-.992-1.573-1.768-3.72-2.241-6.249-.863-4.606-.713-10.467 1.021-16.515 1.95-6.803 4.364-12.575 7.147-16.197 1.208-1.572 2.478-2.731 3.818-3.352 1.181-.547 2.412-.658 3.685-.248zM154.153 34.137c.188.046.272.305.379.553.237.548.404 1.31.52 2.194.295 2.22.259 5.171.017 7.631-.218 2.223-.769 4.387-1.463 5.973-.324.74-.673 1.348-1.039 1.757-.189.211-.366.411-.574.402-.231-.01-.383-.302-.547-.587-.316-.55-.574-1.338-.757-2.294-.348-1.813-.411-4.203-.004-6.777.444-2.804 1.114-5.248 1.887-6.914.342-.737.695-1.314 1.065-1.671.165-.158.328-.314.516-.267zM25.247 55.781c-5.594-1.314-10.935-3.113-15.046-4.5-3.708-1.249-6.452-2.423-7.02-2.063-.326.205-.453.655-.539 1.18-.14.852-.094 1.943.055 3.129.463 3.676 1.946 8.222 2.632 9.617 1.11 2.26 11.222 9.52 14.613 10.555 3.421 1.045 25.848 3.306 28.646 1.445 2.709-1.802 5.144-4.601 5.983-8.83.344-1.735.277-2.98-.057-3.868-.385-1.024-1.138-1.503-1.855-1.723-.998-.305-3.353-.654-6.58-1.14-5.311-.799-12.944-1.948-20.832-3.802z"
              transform="translate(9 13)"
            />
            <path
              strokeWidth="1.5"
              d="M8.74 71.59v7.386c5.217 2.703 8.931 4.493 11.145 5.366 2.183.86 7.195 2.088 15.031 3.692v-7.96c-7.234-1.144-12.411-2.212-15.533-3.2-2.965-.937-6.514-2.696-10.642-5.284zM60.395 20.451c14.554-11.07 22.18-16.604 22.88-16.604 1.05 0 41.584-1.542 42.906 0 1.321 1.54-2.525 21.09-4.527 21.09-1.335 0-21.754-1.496-61.26-4.486z"
              transform="translate(9 13)"
            />
            <path
              strokeWidth="1.5"
              d="M121.654 24.936c1.767-.014 2.651 1.425 2.651 4.317 0 4.338-16.914 10.365-29.144 21.865M129.781 24.936c.758-11.202 1.933-17.876 3.523-20.02 2.562-3.457 8.948-.91 11.862 1.386 1.886 1.557 4.243 5.156 7.07 10.796l-5.925 1.747M149.982 47.496L125.516 72.238"
              transform="translate(9 13)"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M125.516 72.238c1.126-13.614-2.01-19.343-9.406-17.187-7.397 2.157-12.432 15.995-15.106 41.515-18.712 8.087-33.118 10.783-43.218 8.087-15.15-4.044-32.091-11.956-35.958-13.336-3.866-1.38-18.627-9.196-20.019-12.743-1.391-3.546-3.687-17.8.97-29.99"
              transform="translate(9 13)"
            />
            <path
              strokeWidth="1.5"
              d="M2.78 48.584c2.76-10.204 20.659-19.538 53.697-28 7.039-5.04 15.976-10.847 26.811-17.422C90.045 1.054 98.86 0 109.733 0c16.31 0 24.583 0 28.672 1.06 7.047 2.67 11.658 8.016 13.83 16.038 1.948 1.186 3.32 2.668 4.118 4.446 1.195 2.666.214 13.08-.873 15.324"
              transform="translate(9 13)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { FrontLeft };
