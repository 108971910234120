import * as React from 'react';
import { apm } from '@elastic/apm-rum';

type Props = {
  children: React.ReactNode;
};

class ErrorBoundary extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: Error) {
    console.trace(error.message);
    apm.captureError(error);
  }

  render() {
    return this.props.children;
  }
}

export { ErrorBoundary };
