import React from 'react';
import { MaskedInput } from '../MaskedInput';
import { withFormItem } from '../withFormItem';
import styles from './index.module.scss';

type Props = Omit<
  React.ComponentProps<typeof MaskedInput>,
  'mask' | 'separators'
>;

const defaultMmask = [
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
  /\s/,
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
  /\s/,
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
  /\s/,
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
  /[\d|\w]/,
];

const VinInput: React.FC<Props> = ({ ...props }) => (
  <MaskedInput
    mask={defaultMmask}
    separators={{
      3: ' ',
      9: ' ',
      13: ' ',
    }}
    {...props}
  />
);

const FormVinInput = withFormItem(VinInput, {
  classNames: {
    error: styles.error,
  },
});

export { FormVinInput };
