import React, { useEffect, useState } from 'react';
import 'intl';
import { useTranslationLoader } from 'language/components/LanguageProvider/translatorLoader';
import { PageLoader } from 'shared/components/PageLoader';
import { Typography } from 'shared/components/Typography';
import { useLocation } from 'react-router-dom';

import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'language/i18n';
import styles from './index.module.scss';

type Props = {
  locale: string;
};

const getLocale = (locale: string) => {
  if (locale in SUPPORTED_LOCALES) {
    return locale;
  }
  return DEFAULT_LOCALE;
};

const LanguageProvider: React.FC<Props> = ({ children, locale }) => {
  const [localeVal, setLocaleVal] = useState(getLocale(locale));
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (pathname === '/' && localeVal !== DEFAULT_LOCALE) {
      setLocaleVal(DEFAULT_LOCALE);
    }
  }, [localeVal, location, location.pathname]);

  const { loading, error } = useTranslationLoader({ language: localeVal });
  if (loading) {
    return <PageLoader message="Loading" />;
  }

  if (error) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.titleContainer}>
          <Typography variant="textXSRed">Something went wrong!</Typography>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default LanguageProvider;
