import * as React from 'react';
import * as placeholders from 'shared/components/CarPicturesPlaceholder';

type Props = {
  area: keyof typeof AREA_COMPONENT_MAPPER;
};

const AREA_COMPONENT_MAPPER: Record<string, keyof typeof placeholders> = {
  CONSOLE: 'ConsoleDashboard',
  ENGINE: 'Engine',
  ENGINE__VIDEO: 'EngineVideo',
  FRONT: 'Front',
  FRONT_INTERIOR: 'FrontInterior',
  FRONT_LEFT: 'FrontLeft',
  FRONT_LEFT_SIDE: 'FrontLeftSide',
  FRONT_RIGHT_SIDE: 'FrontRightSide',
  NAVIGATION_SYSTEM: 'NavigationSystem',
  REAR: 'Rear',
  REAR_INTERIOR: 'RearInterior',
  REAR_LEFT_SIDE: 'RearLeftSide',
  REAR_RIGHT_SIDE: 'RearRightSide',
  SPEEDOMETER: 'Speedometer',
  TRUNK: 'Trunk',
};

const CarPicturePlaceholder: React.FC<Props> = ({ area }) => {
  const Component = placeholders[AREA_COMPONENT_MAPPER[area]];

  if (Component) {
    return <Component />;
  }

  return null;
};

export { CarPicturePlaceholder };
