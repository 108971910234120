import * as React from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Button, ButtonThemes } from '@auto1-ui/button';

import { AlertBar } from 'shared/components/AlertBar';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import { FormUploader } from 'shared/components/FormUploader';
import { FormNumberInput } from 'shared/components/FormNumberField';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { FormDateInput } from 'shared/components/FormDateInput';
import { DatePickerFormat } from 'shared/components/DatePicker/DatePickerFormat';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';

import styles from '../index.module.scss';
import * as options from './options';
import {
  SERVICE_BOOK_AVAILABLE_UNAVAILABLE,
  SERVICE_BOOK_APPOINTMENTS_ALL,
  SERVICE_BOOK_APPOINTMENTS_SOME,
} from '../constants';

type Props = {
  qaIdPrefix: string;
};
const Service: React.FC<Props> = ({ qaIdPrefix }) => {
  const { translations } = useTranslation();

  const { isPdfInspection } = useInspection();
  const serviceBookOptions = options.serviceBookOptions(translations);
  const yesOrNoToggle = options.yesOrNoToggle(translations);
  const serviceBookAppointmentsOptions =
    options.serviceBookAppointmentsOptions(translations);
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const [
    serviceBook,
    serviceBookAppointments,
    driveBeltChanged,
    isOriginalEngine,
    isRoadworthy,
  ] = watch([
    'service.serviceBook',
    'service.serviceBookAppointments',
    'service.driveBeltChanged',
    'service.isOriginalEngine',
    'service.isRoadworthy',
  ]);

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      externalStyle={styles.card}
      id="service"
      qaIdPrefix={qaIdPrefix}
      showBottomBorder
    >
      <div className={styles.title}>
        <Typography
          variant="titleXL"
          data-qa-id={`${qaIdPrefix}-service-title`}
        >
          {translations.SERVICE_HEADER}
        </Typography>
      </div>
      {isPdfInspection && (
        <AlertBar
          message={
            Object.keys(errors?.service ?? {}).length > 0
              ? translations.SERVICE_PDF_VALIDATION_DISCLAIMER
              : translations.SERVICE_PDF_DISCLAIMER
          }
          type={
            Object.keys(errors?.service ?? {}).length > 0 ? 'error' : 'info'
          }
          additionalClassNames={styles.subtitle}
        />
      )}

      {/* SERVICE BOOK Available */}
      <div
        className={styles.formElementContainer}
        data-qa-id={`${qaIdPrefix}-service-book-avaliable`}
      >
        <FormToggleButton
          label={translations.SERVICE_QUESTION_SERVICE_BOOK_AVAILABLE}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix="service-book-avaliable"
          options={serviceBookOptions}
          name="service.serviceBook"
          defaultValue=""
        />

        {/* SERVICE BOOK PHOTOS */}
        {serviceBook && serviceBook !== SERVICE_BOOK_AVAILABLE_UNAVAILABLE && (
          <FormUploader
            allowMultipleFiles
            uploadFileTypes="image/png, image/jpeg, image/heic, image/heif"
            processSelectedFile={processHeicFile}
            extraClassName={styles.uploaderStyles}
            name="service.serviceBookImages"
            qaIdPrefix={`${qaIdPrefix}-service-photos-uploader`}
            defaultValue={[]}
          >
            {({ handleUploadClick }) => (
              <>
                <div className={styles.uploadCloudIcon} />
                <Typography>{translations.DROP_PHOTOS_HERE}</Typography>
                <Button
                  theme={ButtonThemes.link}
                  data-qa-id={`${qaIdPrefix}-service-photos-browse-button`}
                  extraClass={styles.browseButton}
                  onClick={(event) => {
                    event.preventDefault();
                    handleUploadClick();
                  }}
                >
                  {translations.BROWSE}
                </Button>
              </>
            )}
          </FormUploader>
        )}
      </div>
      {/* SERVICE BOOK APPOINTMENTS */}
      <div
        data-qa-id={`${qaIdPrefix}-service-book-appointments`}
        className={styles.formElementContainer}
      >
        <FormToggleButton
          label={translations.SERVICE_QUESTION_SERVICE_APPOINTMENTS}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix="service-book-appointments"
          options={serviceBookAppointmentsOptions}
          name="service.serviceBookAppointments"
          defaultValue=""
        />

        {serviceBookAppointments &&
          [
            SERVICE_BOOK_APPOINTMENTS_ALL,
            SERVICE_BOOK_APPOINTMENTS_SOME,
          ].includes(serviceBookAppointments) && (
            <div
              data-qa-id={`${qaIdPrefix}-last-service-date-container`}
              className={cn(styles.subField, styles.halfWidthGrid)}
            >
              <div className={styles.halfWidthColumn}>
                <FormDateInput
                  qaIdPrefix={`${qaIdPrefix}-lastServiceDate`}
                  name="service.lastServiceDate"
                  label={translations.SERVICE_QUESTION_LAST_SERVICE_DATE}
                  className={styles.input}
                  defaultValue=""
                  dateType={DatePickerFormat.Month}
                />
              </div>

              <div
                className={styles.halfWidthColumn}
                data-qa-id={`${qaIdPrefix}-last-service-mileage-container`}
              >
                <FormNumberInput
                  label={translations.SERVICE_QUESTION_LAST_SERVICE_MILEAGE}
                  qaIdPrefix={`${qaIdPrefix}-last-service-mileage`}
                  className={styles.input}
                  name="service.lastServiceMileage"
                  defaultValue={null}
                />
              </div>
            </div>
          )}
      </div>

      {/* Drive belt changed  */}
      <div
        className={styles.formElementContainer}
        data-qa-id={`${qaIdPrefix}-driver-belt-changed-container`}
      >
        <FormToggleButton
          label={translations.SERVICE_QUESTION_DRIVER_BELT_CHANGE}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix={`${qaIdPrefix}-driver-belt-changed`}
          options={yesOrNoToggle}
          name="service.driveBeltChanged"
          defaultValue={null}
        />

        {driveBeltChanged && (
          <div className={cn(styles.subField, styles.halfWidthGrid)}>
            <div className={styles.halfWidthColumn}>
              <FormDateInput
                name="service.driveBeltDate"
                qaIdPrefix={`${qaIdPrefix}-driver-belt-date`}
                label={translations.SERVICE_QUESTION_DRIVER_BELT_CHANGE_DATE}
                className={styles.input}
                defaultValue=""
                dateType={DatePickerFormat.Month}
              />
            </div>

            <div
              className={cn(styles.halfWidthColumn, {
                [styles.hiddenField]: !driveBeltChanged,
              })}
            >
              <FormNumberInput
                label={translations.SERVICE_QUESTION_DRIVER_BELT_CHANGE_MILEAGE}
                qaIdPrefix={`${qaIdPrefix}-driver-mileage`}
                className={styles.input}
                name="service.driveBeltMileage"
                defaultValue={null}
              />
            </div>
          </div>
        )}
      </div>

      {/* ORIGINAL ENGINE */}
      <div
        className={styles.formElementContainer}
        data-qa-id={`${qaIdPrefix}-is-original-engine-container`}
      >
        <FormToggleButton
          label={translations.SERVICE_QUESTION_ORIGINAL_ENGINE}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix={`${qaIdPrefix}-is-original-engine`}
          options={yesOrNoToggle}
          name="service.isOriginalEngine"
          defaultValue={null}
        />

        {isOriginalEngine === false && (
          <div
            className={styles.subField}
            data-qa-id={`${qaIdPrefix}-kn-enginge-exchange-container`}
          >
            <FormNumberInput
              label={translations.SERVICE_QUESTION_ENGINE_CHANGE_MILEAGE}
              qaIdPrefix={`${qaIdPrefix}-kn-enginge-exchange`}
              className={styles.input}
              name="service.kmEngineExchange"
              defaultValue={null}
            />
          </div>
        )}
      </div>

      {/* IS CAR ROADWORTHY */}
      <div className={styles.formElementContainer}>
        <FormToggleButton
          label={translations.SERVICE_QUESTION_ROADWORTHY}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix={`${qaIdPrefix}-car-roadworthy`}
          options={yesOrNoToggle}
          name="service.isRoadworthy"
          defaultValue={null}
        />
      </div>

      {isRoadworthy === false && (
        <div className={styles.formElementContainer}>
          <FormToggleButton
            label={
              <>
                <span>{translations.SERVICE_QUESTION_ROLLABLE}</span>
                <br />
                <span className={styles.labelDescription}>
                  {translations.SERVICE_QUESTION_ROLLABLE_DESCRIPTION}
                </span>
              </>
            }
            containerClassName={styles.toggleButtonHorizontal}
            errorClassName={styles.toggleErrorHorizontal}
            qaIdPrefix={`${qaIdPrefix}-car-rollable`}
            options={yesOrNoToggle}
            name="service.cannotBeRolled"
            defaultValue={null}
          />
        </div>
      )}

      {/* INSPECTION EXPIRY */}
      <div className={styles.textFieldContainer}>
        <FormDateInput
          qaIdPrefix={`${qaIdPrefix}-inspection-expiry`}
          name="service.inspectionExpiryDate"
          label={translations.SERVICE_QUESTION_INSPECTION_EXPIRY_DATE}
          className={styles.input}
          defaultValue=""
          dateType={DatePickerFormat.Month}
        />
      </div>
    </Card>
  );
};

export { Service };
