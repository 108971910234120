const scrollToError = (selector = '[data-qa-id*="error-message"]') => {
  const errorElement = [...(document?.querySelectorAll(selector) ?? [])].find(
    (element) => element.textContent !== '',
  );
  if (errorElement) {
    const el = errorElement.closest('label') || errorElement;
    el.parentElement?.scrollIntoView({ behavior: 'smooth' });
  }
};

export { scrollToError };
