import * as React from 'react';
import { UnAuthorizedPage } from 'pages/UnAuthorizedPage';
import { useHasPermissions } from '../../hooks/useHasPermissions';

type Props = {
  permissions: string[];
};

const PermissionGuardedRoute: React.FC<Props> = ({ permissions, children }) => {
  const hasPermissions = useHasPermissions(permissions);

  if (hasPermissions) {
    return <>{children}</>;
  }

  return <UnAuthorizedPage />;
};

export { PermissionGuardedRoute };
