import * as React from 'react';
import cn from 'classnames';
import { Button, ButtonThemes } from '@auto1-ui/button';
import { useFormContext } from 'react-hook-form';
import Loader from 'shared/components/Loader';
import { useTranslation } from 'shared/hooks/useTranslation';
import { FormInput } from 'shared/components/FormInput';
import { FormVinInput } from 'shared/components/FormVinInput';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  isSubmitting?: boolean;
  serverError?: string;
  handleLoadDataClick: React.MouseEventHandler<HTMLButtonElement>;
  handleEditDataClick: React.MouseEventHandler<HTMLButtonElement>;
  handlePasteVinInput: React.ClipboardEventHandler<HTMLInputElement>;
};

const LicensePlateSearch: React.FC<Props> = ({
  qaIdPrefix = 'form-container',
  isSubmitting = false,
  serverError = null,
  handleLoadDataClick,
  handleEditDataClick,
  handlePasteVinInput,
}) => {
  const { translations } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.LicensePlateSearch}>
      <div className={styles.form}>
        <div className={styles.row}>
          <FormVinInput
            label={translations.VIN}
            qaIdPrefix={`${qaIdPrefix}-vin-input`}
            name="vin"
            placeholder={translations.VIN}
            defaultValue=""
            onPaste={handlePasteVinInput}
          />
          <FormInput
            name="licensePlate"
            qaIdPrefix={`${qaIdPrefix}-license-plate`}
            label={translations.LICENSE_PLATE}
            defaultValue=""
          />
          <Button
            theme="orange"
            extraClass={cn(styles.button, {
              [styles.disabled]: errors.licensePlate?.type === 'custom',
            })}
            onClick={handleLoadDataClick}
            qaId={`${qaIdPrefix}-load-vin-data`}
            isDisabled={isSubmitting || errors.licensePlate?.type === 'custom'}
          >
            {translations.LOAD_DATA}
            {isSubmitting && <Loader size={16} />}
          </Button>
        </div>
        {serverError && <div className={styles.serverError}>{serverError}</div>}
        <div className={styles.row}>
          <FormInput
            name="externalId"
            qaIdPrefix={`${qaIdPrefix}-external-id-input`}
            label={
              <>
                {translations.INTERNAL_NUMBER}
                <span className={styles.optionalLabel}>
                  {translations.OPTIONAL}
                </span>
              </>
            }
            defaultValue=""
          />
        </div>
      </div>
      <div className={styles.editData}>
        <Button
          theme={ButtonThemes.link}
          extraClass={styles.link}
          qaId={`${qaIdPrefix}-edit-data`}
          onClick={handleEditDataClick}
        >
          {translations.EDIT_DATA}
        </Button>
      </div>
    </div>
  );
};

export { LicensePlateSearch };
