import * as React from 'react';
import cn from 'classnames';

import classes from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  className?: string;
};

const LayoutContainer: React.FC<Props> = ({
  qaIdPrefix = 'inspection-app',
  className = '',
  children,
}) => {
  return (
    <section
      id="layout-container"
      className={cn(classes.container, className)}
      data-qa-id={`${qaIdPrefix}-layout-container`}
    >
      {children}
    </section>
  );
};

export { LayoutContainer };
