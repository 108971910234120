import * as React from 'react';
import { debounce, chunk, flatten } from 'lodash';
import { allowedCountries } from './helpers';

const MAPS_SCRIPT_URI = 'https://maps.googleapis.com/maps/api/js';
const MAPS_SCRIPT = `${MAPS_SCRIPT_URI}?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&fields=address_component`;
const MAX_COUNTRIES_ACCEPTED_BY_API = 5;
const MIN_LENGTH_SEARCH = 3;

const allowedCountriesChunked = chunk(
  allowedCountries,
  MAX_COUNTRIES_ACCEPTED_BY_API,
);

const loadScriptFile = (scriptName: string, script: string) =>
  new Promise<void>((resolve, reject) => {
    const id = `script-${scriptName}`;

    const existingElement = document.getElementById(id);
    if (existingElement) {
      console.warn(`script "${id}" exists`);
      return existingElement;
    }

    const js = document.createElement('script');
    js.type = 'text/javascript';
    js.id = id;
    js.async = true;

    const handleLoad = () => {
      resolve();
    };
    const handleReject = (err: ErrorEvent) => {
      reject(err);
    };

    js.addEventListener('load', handleLoad);
    js.addEventListener('error', handleReject);
    js.src = script;
    document.body.appendChild(js);
    return js;
  });

const loadMapsScript = () => {
  return loadScriptFile('google-maps', MAPS_SCRIPT);
};

const findScript = (name: string) => {
  const query = `script[src^="${name}"]`;
  return document.querySelector(query);
};

const waitForMapObject = () => {
  return new Promise<void>((resolve, reject) => {
    let retryCount = 0;
    const intervalId = setInterval(() => {
      if (window?.google?.maps?.places) {
        clearInterval(intervalId);
        resolve();
      } else {
        retryCount += 1;
        if (retryCount >= 5) {
          clearInterval(intervalId);
          reject();
        }
      }
    }, 100);
  });
};

export default function useAddressPredictions(inp: string) {
  const [predictions, setPredictions] = React.useState<
    ReadonlyArray<google.maps.places.AutocompletePrediction>
  >([]);
  const [loading, setLoading] = React.useState(false);

  const autocomplete = React.useRef<google.maps.places.AutocompleteService>();

  const loadScript = React.useCallback(async () => {
    const script = findScript(MAPS_SCRIPT_URI);
    if (script) {
      await waitForMapObject();
      autocomplete.current =
        new window.google.maps.places.AutocompleteService();
      return;
    }
    await loadMapsScript();
    await waitForMapObject();
    if (!autocomplete.current && window?.google) {
      autocomplete.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, []);

  React.useEffect(() => {
    loadScript();
  }, [loadScript]);

  function getPlacePredictions(input: string) {
    if (autocomplete.current && input.length >= MIN_LENGTH_SEARCH) {
      setLoading(true);
      Promise.all(
        allowedCountriesChunked.map((allowedCountriesChunk) => {
          return new Promise<
            ReadonlyArray<google.maps.places.AutocompletePrediction>
          >((resolve) => {
            autocomplete.current?.getPlacePredictions(
              {
                input,
                componentRestrictions: { country: allowedCountriesChunk },
              },
              (results) => resolve(results ?? []),
            );
          });
        }),
      ).then((results) => {
        setLoading(false);
        setPredictions(flatten(results));
      });
    }
  }

  const debouncedGetPlacePredictions = React.useCallback(
    debounce(getPlacePredictions, 500),
    [],
  );

  React.useEffect(() => {
    debouncedGetPlacePredictions(inp);

    return () => {
      debouncedGetPlacePredictions.cancel();
    };
  }, [debouncedGetPlacePredictions, inp]);

  return { predictions, loading };
}

export { MIN_LENGTH_SEARCH };
