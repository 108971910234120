import * as React from 'react';
import { format } from 'date-fns';
import type {
  CheckBeforeSubmitToAuctionQuery,
} from 'gql/graphql';
import type { ObjectKeys } from 'shared/utils/types';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { filterImagesByArea } from 'shared/utils/filterImagesByArea';
import { PagesEnum } from 'shared/utils/route';
import { Typography } from 'shared/components/Typography';
import { Modal } from 'shared/components/Modal';
import { useProgressCalculation } from 'shared/components/Sidebar/hooks/useProgressCalculation';
import { inspectionSources, InspectionActions } from 'shared/constants';
import styles from './index.module.scss';

type Inspection = NonNullable<
  CheckBeforeSubmitToAuctionQuery['checkBeforeSubmitToAuction']['relatedInspection']
>;

type Props = {
  handleContinueEvaluation: () => void;
  handleCreateEvaluation: () => void;
  handleClose: () => void;
  inspectionInfo?: Inspection;
  isSubmitting?: boolean;
};

const InspectionCase = Object.freeze({
  FromPI: 'FromPI',
  FromPIAndConvertible: 'FromPIAndConvertible',
  FromEVA: 'FromEVA',
});

const getInspectionCase = (inspection: Inspection) => {
  if (inspection?.sourceType !== inspectionSources.PI_APP) {
    return InspectionCase.FromEVA;
  }

  if (
    inspection?.actions?.find(({ name }) => name === InspectionActions.ADD_TO_FULL_EVALUATION)
  ) {
    return InspectionCase.FromPIAndConvertible;
  }

  return InspectionCase.FromPI;
};

const DuplicateVinModal: React.FC<Props> = ({
  handleContinueEvaluation,
  handleCreateEvaluation,
  handleClose,
  inspectionInfo = {} as Inspection,
  isSubmitting = false,
}) => {
  const { translations } = useTranslation();
  const containerDiv = React.useRef<HTMLDivElement>(null);
  useClickOutside(containerDiv, () => handleClose());

  const { car, vehicle, branch, lastChangedTime, inspectionId } =
    inspectionInfo;
  const evaluationUpdated = lastChangedTime
    ? format(new Date(lastChangedTime), 'dd/MM/yyyy')
    : '';
  const imgUrl = filterImagesByArea(vehicle?.images, 'FRONT_LEFT');

  const { percentages } = useInspection();
  useProgressCalculation(inspectionId);
  const progressSum = (
    Object.keys(PagesEnum) as ObjectKeys<typeof PagesEnum>
  ).reduce<number>((acc, id) => {
    return acc + (percentages[id] ?? 0);
  }, 0);
  const progress = Math.round(progressSum / Object.keys(PagesEnum).length) || 0;
  const inspectionCase = getInspectionCase(inspectionInfo);
  const okLabel = {
    [InspectionCase.FromPIAndConvertible]: translations.CONVERT_FULL_EVA,
    [InspectionCase.FromPI]: translations.VIEW_PRICE_INDICATOR,
    [InspectionCase.FromEVA]: translations.COMPLETE_NOW,
  };
  const title = {
    [InspectionCase.FromPIAndConvertible]: translations.WANT_CONVERT_FULL_EVA,
    [InspectionCase.FromPI]: translations.PI_ALREADY_EXISTS,
    [InspectionCase.FromEVA]: translations.EVALUATION_ALREADY_EXISTS,
  };

  return (
    <Modal
      isOpen
      className={styles.warningContainer}
      title={title[inspectionCase]}
      okLabel={okLabel[inspectionCase]}
      onOk={handleContinueEvaluation}
      okButtonExtraClass={styles.okButton}
      cancelLabel={translations.CREATE_NEW_EVALUATION}
      cancelButtonExtraClass={styles.cancelButtonExtraClass}
      onCancel={() => handleCreateEvaluation()}
      onClose={handleClose}
      isCancelButtonDisabled={isSubmitting}
      isOkButtonDisabled={isSubmitting}
      qaIdPrefix="car-information-warning-modal"
      ref={containerDiv}
    >
      <div>
        <Typography
          variant="textSmall"
          tag="div"
          data-qa-id="car-information-warning-title"
        >
          {translations.WOULD_YOU_LIKE_CONTINUE_EVALUATION}
        </Typography>
        <div className={styles.info}>
          <div className={styles.imageContainer}>
            {imgUrl && <img src={imgUrl} alt="warning-modal-car" />}
          </div>
          <div
            className={styles.contentContainer}
            data-qa-id="car-information-warning-content-container"
          >
            <Typography
              additonalClassNames={styles.title}
              tag="div"
              data-qa-id="car-information-warning-title"
            >
              {car?.stockNumber} - {vehicle?.model?.make}{' '}
              {vehicle?.model?.model} {vehicle?.model?.subModel}
            </Typography>
            <Typography
              variant="textXS"
              tag="div"
              data-qa-id="car-information-warning-vin"
            >
              {vehicle?.identifiers?.vin ?? ''}
            </Typography>
            <Typography
              variant="textXS"
              tag="div"
              data-qa-id="car-information-warning-branch-name"
            >
              {branch?.name ?? ''}
            </Typography>
            <Typography
              variant="textXS"
              tag="div"
              data-qa-id="car-information-warning-inspection-date"
            >
              {translations.EVALUATION_UPDATED}: {evaluationUpdated}
            </Typography>
          </div>
          <div
            data-qa-id="car-information-warning-percentage"
            className={styles.percentageLabel}
          >
            {progress}%
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { DuplicateVinModal };
