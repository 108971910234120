import omit from 'lodash/omit';
import type { TiresBrakesInitialDataQuery } from 'gql/graphql';
import { wheelPositionMapper } from '../tires-brakes-data';
import type {
  WheelForm,
  Form,
  OmitEmptyValuesReturn,
  BrakesCondition,
} from '../types.ts';

type Wheel = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<TiresBrakesInitialDataQuery['inspection']>['vehicle']
    >['exterior']
  >['wheelSets']
>[number];

const omittedKeys = ['isPrimary', 'notAvailable', 'brakesCondition'] as const;

const isFormWithData = (
  primaryWheels: ReadonlyArray<WheelForm & BrakesCondition>,
  additionalWheels: ReadonlyArray<WheelForm>,
) =>
  [...primaryWheels, ...additionalWheels].some((wheel) => {
    return Object.entries(wheel).some(([key, value]) => {
      // Exclude fields that are always populated
      return key !== 'area' && key !== 'isPrimary' && value;
    });
  });

const graphqlToSchema = (data?: TiresBrakesInitialDataQuery) => {
  const wheels = data?.inspection?.vehicle?.exterior?.wheelSets ?? [];
  const brakes = data?.inspection?.vehicle?.condition?.brakes ?? [];

  if (
    wheels.length === 0 ||
    !wheels[0]?.wheelDetails ||
    wheels[0].wheelDetails.length === 0
  ) {
    const emptyWheels = (
      Object.keys(wheelPositionMapper) as ReadonlyArray<
        keyof typeof wheelPositionMapper
      >
    ).map((area) => ({
      area,
      tireType: '',
      dotNumber: '',
      profileDepth: '',
      rimType: '',
      notAvailable: false,
      brakesCondition: '',
      isPrimary: true,
    }));

    return {
      primaryWheels: emptyWheels,
      additionalWheels: [],
    } as Form;
  }

  const mapBrakes = (wheel: WheelForm) => {
    const brake = brakes.find((brake) => wheel.area === brake?.area);

    if (!brake) return wheel;
    const { condition } = brake;

    return {
      ...wheel,
      brakesCondition: condition ?? '',
    };
  };

  const map =
    ({ isPrimary }: { isPrimary: boolean }) =>
    (wheel: Wheel) => {
      const wheelDetails = wheel?.wheelDetails ?? [];
      return wheelDetails.map<WheelForm>((wheelDetails) => {
        const { profileDepth, area, rimType, tireType, dotNumber } =
          wheelDetails ?? {};

        if (!area) {
          throw new Error(
            `WheelDetails has no area. inspectionId: ${data?.inspection?.inspectionId}`,
          );
        }

        return {
          area: area,
          tireType: tireType ?? '',
          dotNumber: dotNumber ?? '',
          profileDepth: profileDepth?.toString() ?? '',
          rimType: rimType ?? '',
          notAvailable: dotNumber === null,
          isPrimary,
        };
      });
    };

  return {
    primaryWheels: wheels
      ?.filter((wheel) => wheel && wheel.isPrimarySet)
      .flatMap(map({ isPrimary: true }))
      .map(mapBrakes),
    additionalWheels: wheels
      .filter((wheel) => !wheel?.isPrimarySet)
      .flatMap(map({ isPrimary: false })),
  } as Form;
};

const omitKeys = (wheel: OmitEmptyValuesReturn) =>
  omit({ ...wheel }, omittedKeys) as Omit<
    OmitEmptyValuesReturn,
    'isPrimary' | 'notAvailable' | 'brakesCondition'
  >;

const omitEmptyValues = (wheel: WheelForm) =>
  Object.entries(wheel).reduce((acc, [key, val]) => {
    if (key === 'dotNumber' && wheel.notAvailable) {
      return { ...acc, [key]: null };
    }
    if (key !== 'dotNumber' && val === '') return acc;
    return { ...acc, [key]: val };
  }, {} as OmitEmptyValuesReturn);

const mapBrakes = ({
  brakesCondition: condition,
  area,
}: WheelForm & BrakesCondition) => ({
  condition: condition || null,
  area,
});

const mapSchemaToMutation = ({
  primaryWheels = [],
  additionalWheels = [],
}: Form) => {
  const wheels = primaryWheels.map(omitEmptyValues).map(omitKeys);
  const brakesCondition = primaryWheels.map(mapBrakes);
  const extraWheels = additionalWheels.map(omitEmptyValues).map(omitKeys);

  return {
    wheels,
    brakesCondition,
    extraWheels,
  };
};

export { graphqlToSchema, mapSchemaToMutation, isFormWithData };
