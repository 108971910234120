/** ***this file is not being used anywhere but maybe useful in future ******* */
import { getDomainName } from './url';

const checkIsProductionUrl = (productionUrls: ReadonlyArray<string>) => {
  const domainName = getDomainName();

  return productionUrls.some((url) => domainName.endsWith(url));
};

const isMobileEvaluatorProductionEnv = () => {
  const productionUrls = ['eva.auto1.com', 'eva-preprod.auto1.com'];
  return checkIsProductionUrl(productionUrls);
};

const isMerchantAppProductionEnv = () => {
  const productionUrls = ['auto1.com', 'preprod.auto1.com'];
  return checkIsProductionUrl(productionUrls);
};

const isProductionEnv = () =>
  isMobileEvaluatorProductionEnv() || isMerchantAppProductionEnv();

const isLocalEnv = () => process.env.NODE_ENV === 'development';

export {
  isMobileEvaluatorProductionEnv,
  isMerchantAppProductionEnv,
  isProductionEnv,
  isLocalEnv,
};
