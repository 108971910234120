import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTopBannerQuery } from 'gql/graphql';
import { filterImagesByArea } from 'shared/utils/filterImagesByArea';
import { Typography } from 'shared/components/Typography';
import { Card } from '../Card';
import { Placeholder } from './Placeholder';
import styles from './index.module.scss';

const CarInformationTopBanner: React.FC = () => {
  const { inspectionId } = useParams<{ inspectionId: string }>();

  const { data, loading } = useTopBannerQuery({
    variables: {
      inspectionId: Number.parseInt(inspectionId, 10),
    },
    skip: !inspectionId,
  });

  const imgUrl = filterImagesByArea(
    data?.inspection?.vehicle?.images,
    'FRONT_LEFT',
  );

  const { car, vehicle, branch } = data?.inspection ?? {};

  if (loading) {
    return <Placeholder />;
  }

  return (
    <Card
      paddingTop={24}
      paddingBottom={24}
      externalStyle={styles.card}
      showBottomBorder
      qaIdPrefix="car-information-top-banner-container"
    >
      <div className={styles.imageContainer}>
        {imgUrl && <img src={imgUrl} alt="car" />}
      </div>

      <div
        className={styles.contentContainer}
        data-qa-id="car-information-top-banner-content-container"
      >
        <Typography
          variant="titleXL"
          tag="h2"
          data-qa-id="car-information-top-banner-title"
        >
          {car?.stockNumber} - {vehicle?.model?.make} {vehicle?.model?.model}{' '}
          {vehicle?.model?.subModel}
        </Typography>
        <Typography
          variant="textSmall"
          tag="h6"
          data-qa-id="car-information-top-banner-vin"
        >
          {vehicle?.identifiers?.vin?.toUpperCase() ?? ''}
        </Typography>
        <Typography
          variant="textSmall"
          tag="h6"
          data-qa-id="car-information-top-banner-branch-name"
        >
          {branch?.name ?? ''}
        </Typography>
      </div>
      <span className={styles.carPercentage}>100%</span>
    </Card>
  );
};

export { CarInformationTopBanner };
