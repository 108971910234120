import * as React from 'react';
import cn from 'classnames';
import { debounce } from 'lodash';
import { ArrowLeft, ArrowRight } from '@auto1-ui/icons';
import styles from './index.module.scss';

type Props = {
  extraContainerClass?: string;
  extraLeftArrowContainerClass?: string;
  extraRightArrowContainerClass?: string;
  extraimageContainerClass?: string;
  qaIdPrefix?: string;
};

const Carousel: React.FC<Props> = ({
  children,
  extraContainerClass = '',
  extraLeftArrowContainerClass = '',
  extraRightArrowContainerClass = '',
  extraimageContainerClass = '',
  qaIdPrefix = 'carousel',
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [areArrowsVisible, setAreArrowsVisible] = React.useState(false);
  const [scrollEnd, setScrollEnd] =
    React.useState<'left' | 'right' | null>(null);
  const detectScrollbarVisibility = () => {
    const container = containerRef.current;

    if (container) {
      setAreArrowsVisible(container.scrollWidth > container.clientWidth);
    }
  };

  React.useLayoutEffect(detectScrollbarVisibility, []);
  React.useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const handleScroll = debounce((e: Event) => {
      const el = e.target as HTMLElement;
      const scrollLeft = el.scrollLeft;
      const scrollWidth = el.scrollWidth;
      const clientWidth = el.clientWidth;

      if (scrollLeft + clientWidth >= scrollWidth) {
        return setScrollEnd('right');
      }
      if (scrollLeft === 0) {
        return setScrollEnd('left');
      }

      setScrollEnd(null);
    }, 100);

    containerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={cn(styles.container, extraContainerClass)}
      ref={containerRef}
      data-qa-id={qaIdPrefix}
    >
      <div
        className={cn(
          styles.arrowContainer,
          styles.left,
          {
            [styles.hide]: !areArrowsVisible || scrollEnd === 'left',
          },
          extraLeftArrowContainerClass,
        )}
      >
        <ArrowLeft
          onClick={() => {
            const container = containerRef.current;
            if (container) {
              container.scrollBy({
                left: -500,
                behavior: 'smooth',
              });
            }
          }}
        />
      </div>
      {React.Children.map(children, (child) => {
        return (
          <div
            className={cn(styles.imageWrapper, extraimageContainerClass)}
            data-qa-id={`${qaIdPrefix}-item`}
          >
            {child}
          </div>
        );
      })}
      <div
        className={cn(
          styles.arrowContainer,
          styles.right,
          {
            [styles.hide]: !areArrowsVisible || scrollEnd === 'right',
          },
          extraRightArrowContainerClass,
        )}
      >
        <ArrowRight
          onClick={() => {
            const container = containerRef.current;
            if (container) {
              container.scrollBy({
                left: 500,
                behavior: 'smooth',
              });
            }
          }}
        />
      </div>
    </div>
  );
};

Carousel.defaultProps = {
  extraContainerClass: '',
  extraLeftArrowContainerClass: '',
  extraRightArrowContainerClass: '',
  extraimageContainerClass: '',
  qaIdPrefix: 'carousel',
};

export { Carousel };
