import * as React from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { Controller, useFormContext } from 'react-hook-form';
import type { ControllerProps } from 'react-hook-form';

import { Typography } from '../Typography';
import { InputLabel } from '../InputLabel';

import styles from './index.module.scss';

type FormItemProps = {
  name: string;
  id?: string;
  qaIdPrefix?: string;
  containerClassName?: string;
  label?: React.ReactElement | string;
  disabled?: boolean;
  className?: string;
  errorClassName?: string;
  defaultValue?: unknown;
  rules?: ControllerProps['rules'];
  beforeChange?: (event: React.ChangeEvent) => void;
  afterChange?: (event: React.ChangeEvent) => void;
  shouldUnregister?: boolean;
  validationMsg?: string;
};

function withFormItem<T extends object = Record<string, never>>(
  Component: React.ComponentType<T>,
  {
    reverseLabel = false,
    classNames,
    ...propsOverride
  }: {
    reverseLabel?: boolean;
    classNames?: { [key: string]: string };
  } & Partial<T>,
) {
  const FormItem: React.FC<FormItemProps & Partial<T>> = ({
    name,
    qaIdPrefix = '',
    containerClassName = '',
    label = '',
    disabled = false,
    className = '',
    errorClassName = '',
    defaultValue,
    rules,
    beforeChange,
    afterChange,
    shouldUnregister = true,
    validationMsg = '',
    ...props
  }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const error = get(errors, name);

    return (
      <div
        className={cn(
          styles.root,
          classNames?.containerClassName,
          containerClassName,
        )}
      >
        {!reverseLabel && label && (
          <InputLabel>
            <Typography
              tag="div"
              variant="textSmall"
              additonalClassNames={cn(styles.label, styles.labelFront)}
              data-qa-id={`${kebabCase(qaIdPrefix)}-label`}
            >
              {label}
            </Typography>
          </InputLabel>
        )}
        <Controller
          render={({ field: { ref, ...renderProps } }) => (
            <Component
              qaId={qaIdPrefix}
              isDisabled={disabled}
              {...({
                ...renderProps,
                onChange: (event: React.ChangeEvent) => {
                  if (beforeChange) {
                    beforeChange(event);
                  }
                  renderProps.onChange(event);
                  if (afterChange) {
                    afterChange(event);
                  }
                },
                ...propsOverride,
                ...props,
              } as T)}
              className={cn({ [classNames?.error ?? '']: !!error }, className)}
            />
          )}
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={rules}
          shouldUnregister={shouldUnregister}
        />
        {reverseLabel && label && (
          <InputLabel>
            <Typography
              tag="div"
              variant="textSmall"
              additonalClassNames={styles.label}
              data-qa-id={`${kebabCase(qaIdPrefix)}-label`}
            >
              {label}
            </Typography>
          </InputLabel>
        )}
        {validationMsg && Object.keys(error ?? {}).length === 0 ? (<Typography
          tag="div"
          additonalClassNames={styles.validationMessage}
          data-qa-id={`${qaIdPrefix}-validation-message`}
        >
          {validationMsg}
        </Typography>
        ) : (<Typography
          tag="div"
          additonalClassNames={cn(
            classNames?.errorMessage,
            styles.message,
            errorClassName,
          )}
          variant="textXSRed"
          data-qa-id={`${qaIdPrefix}-error-message`}
        >
          {error?.message ?? ''}
        </Typography>)}
      </div>
    );
  };

  return FormItem;
}

export { withFormItem };
