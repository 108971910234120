import * as React from 'react';
import cn from 'classnames';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { FormProvider } from 'react-hook-form';
import { Card } from 'shared/components/Card';
import { Button, ButtonTypes } from '@auto1-ui/button';
import { FormDropdown } from 'shared/components/FormDropdown';
import { appRoutes } from 'appRoutes';
import { useState } from 'react';
import { getAllowedCountryOptions } from 'pages/locationsForm/helpers';
import Loader from 'shared/components/Loader';
import { EditLocationField } from './components/editLocationField';
import { useEditLocation } from './useLocationFormLogic';
import { AddressField } from './components/addressField';
import useAddressPredictions from './useAddressPredictions';

type Props = {
  qaIdPrefix?: string;
};

import styles from './index.module.scss';

const EditLocationPage: React.FC<Props> = ({
  qaIdPrefix = 'edit-location-page',
}) => {
  const location = useLocation();
  const { translations } = useTranslation();
  const [isManualFlow, setManualFlow] = useState(false);
  const [clickedOnManualFlow, setClickedOnManualFlow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    getAddressComponents,
    isEditPage,
    formMethods,
    handleAddLocation,
    handleEditLocation,
  } = useEditLocation({
    translations,
    clickedOnManualFlow,
  });
  const {
    formState: { errors, isSubmitting },
  } = formMethods;

  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const hasBackToSearchParams = searchParams.has('backTo');

  const handleBackButtonClick = () => {
    if (hasBackToSearchParams) {
      window.location.assign(searchParams.get('backTo') as string);
      return;
    }

    history.push(appRoutes.locationsList());
  };

  const address = formMethods.watch('address');

  const { predictions = [], loading } = useAddressPredictions(address || '');

  const handleTyping = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    formMethods.setValue('address', val);
  };

  const setAddress = (_: string, id: string) => {
    getAddressComponents(id);
    setManualFlow(true);
  };

  const handleOnFillManuallyClick = () => {
    setManualFlow(true);
    setClickedOnManualFlow(true);
  };

  React.useEffect(() => {
    if (searchParams.has('ref', 'carInfo')) {
      window.tracking.eva.editBranch({
        branchName: undefined,
        branchId: undefined,
      });
      searchParams.delete('ref');
      history.replace(
        searchParams.size > 0
          ? `${location.pathname}?${searchParams.toString()}`
          : location.pathname,
      );
    }
  }, [history, location.pathname, searchParams]);

  return (
    <>
      {hasBackToSearchParams ? (
        <a
          className={styles.backLink}
          href={searchParams.get('backTo') as string}
        >
          {translations.BACK_BUTTON}
        </a>
      ) : (
        <Link className={styles.backLink} to={appRoutes.locationsList()}>
          {translations.BACK_TO_LIST}
        </Link>
      )}
      <Card paddingTop={32} paddingBottom={32}>
        <FormProvider {...formMethods}>
          <Typography
            variant="titleXL"
            tag="h1"
            additonalClassNames={styles.pageTitle}
            data-qa-id={`${qaIdPrefix}-title`}
          >
            {isEditPage
              ? translations.EDIT_LOCATION_TITLE
              : translations.ADD_LOCATION_TITLE}
          </Typography>

          <div className={styles.gridContainer}>
            {/* NAME */}

            <EditLocationField
              label={translations.LOCATION_NAME}
              className={styles.fullWidth}
              name="name"
              defaultValue=""
              containerClassName={styles.inputContainerFullWidth}
            />

            <div className={styles.gridContainer}>
              {/* ADDRESS */}

              <div className={styles.formGrid}>
                <AddressField
                  label={translations.ADDRESS}
                  name="address"
                  onKeyUp={handleTyping}
                  // onIconClick={getCurrentLocation}
                  qaIdPrefix={qaIdPrefix}
                  results={predictions}
                  loading={loading}
                  onFillManuallyClick={handleOnFillManuallyClick}
                  onOptionClick={setAddress}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  value={address ?? ''}
                  error={
                    !isManualFlow &&
                    !!errors.streetNumber &&
                    !!errors.streetNumber.message
                  }
                />
              </div>

              {/* EMAIL */}

              <EditLocationField
                name="email"
                label={
                  <>
                    {translations.EMAIL}{' '}
                    <span className={styles.optionalLabel}>
                      {translations.OPTIONAL}
                    </span>
                  </>
                }
                defaultValue=""
                containerClassName={styles.inputContainerFullWidth}
              />
            </div>

            <div className={styles.gridContainer}>
              <EditLocationField
                label={translations.STREET}
                name="street"
                defaultValue=""
                className={!isManualFlow ? styles.hidden : ''}
                containerClassName={styles.inputContainerFullWidth}
              />

              {/* {!isManualFlow && <div className={styles.formGrid}></div>} */}

              {/* PHONE */}
              <EditLocationField
                name="phone"
                label={<>{translations.PHONE}</>}
                defaultValue=""
                containerClassName={styles.inputContainerFullWidth}
              />
            </div>

            <div
              className={cn(styles.formGrid, styles.addressGrid, {
                [styles.hidden]: !isManualFlow,
              })}
            >
              <EditLocationField
                label={translations.STREETNUMBER}
                name="streetNumber"
                defaultValue=""
                containerClassName={styles.inputContainerFullWidth}
              />

              <EditLocationField
                label={translations.ZIPCODE}
                name="zipcode"
                defaultValue=""
                containerClassName={styles.inputContainerFullWidth}
              />

              <EditLocationField
                label={translations.CITY}
                name="city"
                defaultValue=""
                containerClassName={styles.inputContainerFullWidth}
              />

              <EditLocationField
                label={translations.COUNTRY}
                name="country"
                component={FormDropdown}
                options={getAllowedCountryOptions()}
                defaultValue=""
                containerClassName={styles.inputContainerFullWidth}
              />
            </div>

            {/* // ?  WILL BE ADDED LATER  */}
            {/* <EditLocationField
              label={translations.SET_AS_DEFAULT_BRANCH}
              name="defaultBranch"
              component={FormCheckbox}
              containerClassName={styles.checkBoxLabel}
            /> */}
          </div>

          <div className={styles.buttonsWrapper}>
            <button
              data-qa-id={`${qaIdPrefix}-footer-back-link`}
              className={styles.cancelLink}
              onClick={handleBackButtonClick}
              type="button"
            >
              {translations.CANCEL}
            </button>

            {/* ADD LOCATION BTN */}

            {!isEditPage && (
              <Button
                type={ButtonTypes.button}
                theme="orange"
                qaId={`${qaIdPrefix}-add-button`}
                isActivated={false}
                onClick={handleAddLocation()}
                isDisabled={isSubmitting}
              >
                {translations.ADD_LOCATION_BTN}
                {isSubmitting && (
                  <Loader extraClass={styles.nextButtonLoader} />
                )}
              </Button>
            )}

            {/* EDIT LOCAITON BTN */}
            {isEditPage && (
              <Button
                type={ButtonTypes.button}
                theme="orange"
                qaId={`${qaIdPrefix}-add-button`}
                isActivated={false}
                onClick={handleEditLocation()}
                isDisabled={isSubmitting}
              >
                {translations.EDIT_LOCATION_BTN}
                {isSubmitting && (
                  <Loader extraClass={styles.nextButtonLoader} />
                )}
              </Button>
            )}
          </div>
        </FormProvider>
      </Card>
    </>
  );
};

const EditLocationPageWithTransaction = withTransaction(
  'EditLocationPage',
  'component',
)(EditLocationPage);

export { EditLocationPageWithTransaction as EditLocationPage };
