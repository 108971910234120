import * as React from 'react';
import { formatLocaleNumber } from 'shared/utils/formatLocaleNumber';
import { Input } from '@auto1-ui/input';

type Props = {
  value: number | null;
  onChange: (value: number | null) => void;
  maxDigits?: number;
  qaId: string;
  className?: string;
} & Omit<
  React.ComponentProps<typeof Input>,
  'extraClass' | 'onChange' | 'value'
>;

const MAX_CHARS = 16;
const transformValue = (maxDigits: number, value: string | null) => {
  if (!value) return '';

  const digitsValue = String(value).replace(/\D/g, '');
  if (digitsValue.length === 0) return '';

  return formatLocaleNumber(
    Number(
      digitsValue.length > maxDigits
        ? digitsValue.substring(0, maxDigits)
        : digitsValue,
    ),
  );
};

const NumberInput: React.FC<Props> = ({
  value,
  onChange,
  maxDigits = MAX_CHARS,
  qaId,
  className,
  ...props
}) => {
  return (
    <Input
      {...props}
      qaTag={qaId}
      value={transformValue(maxDigits, value?.toString() ?? null)}
      onChange={(e) => {
        const transformedValue = e.target.value.replace(/\D/g, '').trim().substring(0, maxDigits);
        onChange(transformedValue.length > 0 ? Number(transformedValue) : null);
      }}
      extraClass={className}
    />
  );
};

export { NumberInput };
