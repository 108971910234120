import * as React from 'react';
import type { VariationType } from '../types';

type Props = {
  variant: VariationType;
  qaIdPrefix?: string;
};

const Front: React.FC<Props> = ({ variant, qaIdPrefix = '' }) => (
  <>
    <path
      d="M140.648 73.3785C139.761 71.6916 139.258 69.7746 139.258 67.7384V14.1918C139.258 7.46116 144.714 2 151.458 2H157.681C164.416 2 169.88 7.45264 169.88 14.1918V42.98"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M169.88 42.98H251.67"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M199.915 109.852H194.825"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M194.825 109.852H149.557C146.855 109.852 144.766 107.483 145.107 104.808L146.957 90.1284H146.863"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M140.648 73.3784L138.073 85.5106C137.57 87.8876 139.386 90.1283 141.816 90.1283H146.871"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M149.045 259.867L148.644 258.274L144.476 255.625L154.441 133.136C154.817 128.501 157.945 124.548 162.37 123.108L199.923 109.852"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M194.825 109.852L201.142 96.2541C203.145 91.9346 199.991 86.9932 195.234 86.9932H156.309C152.89 86.9932 149.582 88.1093 146.863 90.1199"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M251.67 87.6406H231.653C227.621 87.6406 223.751 89.2423 220.895 92.0965L199.915 109.852"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M214.194 68.7181H169.344C166.061 68.7181 163.41 66.06 163.41 62.7884C163.41 59.5168 166.07 56.8586 169.344 56.8586H251.662"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M214.194 68.7183V74.0005C214.194 78.5245 217.86 82.188 222.386 82.188H251.67"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M251.67 68.7183H214.194"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M140.648 73.3785L143.836 58.3752V49.3102C143.836 45.8085 146.675 42.98 150.17 42.98H169.88"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M199.915 109.852H251.67"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M149.045 259.867C159.216 241.874 201.279 228.387 251.67 228.327"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M362.692 73.3785C363.579 71.6916 364.082 69.7746 364.082 67.7384V14.1918C364.082 7.46116 358.626 2 351.883 2H345.659C338.925 2 333.46 7.45264 333.46 14.1918V42.98"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M333.46 42.98H251.67"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M303.426 109.852H308.515"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M308.516 109.852H353.784C356.486 109.852 358.575 107.483 358.234 104.808L356.384 90.1284H356.478"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M362.692 73.3784L365.267 85.5106C365.77 87.8876 363.954 90.1283 361.524 90.1283H356.469"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M354.295 259.867L354.695 258.274L358.864 255.625L348.898 133.136C348.523 128.501 345.394 124.548 340.97 123.108L303.417 109.852"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M169.31 340.225L149.045 259.867"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M354.295 259.867L334.031 340.225"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M308.516 109.852L302.199 96.2541C300.195 91.9346 303.35 86.9932 308.107 86.9932H347.032C350.451 86.9932 353.758 88.1093 356.478 90.1199"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M251.67 87.6406H271.687C275.72 87.6406 279.59 89.2423 282.446 92.0965L303.426 109.852"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M289.146 68.7181H333.997C337.279 68.7181 339.93 66.06 339.93 62.7884C339.93 59.5168 337.271 56.8586 333.997 56.8586H251.679"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M289.147 68.7183V74.0005C289.147 78.5245 285.481 82.188 280.954 82.188H251.67"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M251.67 68.7183H289.147"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M362.692 73.3785L359.504 58.3752V49.3102C359.504 45.8085 356.665 42.98 353.17 42.98H333.46"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M303.426 109.852H251.67"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M354.295 259.867C344.125 241.874 302.062 228.387 251.67 228.327"
      stroke="#E0E0E0"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <g className={variant} data-qa-id={`${qaIdPrefix}-front`}>
      <path
        d="M251.67 42.98H169.88V14.1918C169.88 7.46116 164.424 2 157.681 2H151.458C144.723 2 139.258 7.45264 139.258 14.1918V67.7384C139.258 69.7746 139.761 71.6916 140.648 73.3785L138.073 85.5106C137.57 87.8876 139.386 90.1283 141.816 90.1283H146.965L145.115 104.808C144.774 107.483 146.863 109.852 149.565 109.852H199.923L162.37 123.108C157.945 124.548 154.825 128.501 154.442 133.136L144.476 255.625L148.645 258.274L169.309 340.226C189.65 335.812 219.019 333.086 251.67 333.086C284.313 333.086 313.682 335.812 334.031 340.226L354.696 258.274L358.864 255.625L348.899 133.136C348.524 128.501 345.395 124.548 340.97 123.108L303.417 109.852H353.775C356.477 109.852 358.566 107.483 358.225 104.808L356.375 90.1283H361.524C363.954 90.1283 365.77 87.8876 365.267 85.5106L362.692 73.3785C363.579 71.6916 364.082 69.7746 364.082 67.7384V14.1918C364.082 7.46116 358.626 2 351.882 2H345.659C338.924 2 333.46 7.45264 333.46 14.1918V42.98H251.67Z"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
  </>
);

export { Front };
