import * as React from 'react';

import { FormToggleButton } from 'shared/components/FormToggleButton';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';

type Props = {
  label: string;
  name: string;
  qaIdPrefix: string;
};

const ThicknessItem: React.FC<Props> = ({ label, name, qaIdPrefix }) => {
  const { translations } = useTranslation();
  const options = React.useMemo(
    () => [
      {
        label: translations.YES,
        value: true,
      },
      {
        label: translations.NO,
        value: false,
      },
    ],
    [translations],
  );
  return (
    <div className={styles.container}>
      <FormToggleButton
        qaIdPrefix={`${qaIdPrefix}-${name}`}
        options={options}
        name={`paintThickness.${name}`}
        label={label}
        className={styles.toggleButton}
        defaultValue={null}
      />
    </div>
  );
};

export { ThicknessItem };
