import parse from 'date-fns/parse';
import format from 'date-fns/format';

export function parseMaskedDate(date: string | null | undefined) {
  if (!date) {
    return null;
  }

  const asDateObject = parse(date, 'MM/yyyy', new Date());
  asDateObject.setDate(1);

  return format(asDateObject, 'yyyy-MM-dd');
}
