import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { InfoInCircle } from 'shared/components/Icons';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import type { CarQualityInitialDataQuery } from 'gql/graphql';
import { filterNull } from 'shared/utils/filterNull';

import { ThicknessItemV2 } from '../components/ThicknessItemV2';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
  areas: NonNullable<
    NonNullable<
      NonNullable<CarQualityInitialDataQuery['inspection']>['fields']
    >['vehicle']
  >['paintThickness'];
};

const PaintThicknessV2: React.FC<Props> = ({ qaIdPrefix, areas }) => {
  const { translations } = useTranslation();
  const fieldsContainerRef = React.useRef<HTMLElement>(null);
  const { watch } = useFormContext();
  const isPaintThicknessChecked = watch(
    'paintThickness.isPaintThicknessChecked',
  );

  return (
    <Card
      externalStyle={styles.container}
      showBottomBorder
      id="thickness"
      qaIdPrefix={`${qaIdPrefix}-paint-thickness-container`}
    >
      <Typography
        data-qa-id={`${qaIdPrefix}-paint-thickness-title`}
        variant="titleXL"
        tag="div"
        additonalClassNames={styles.title}
      >
        {translations.PAINT_THICKNESS_HEADER}
      </Typography>

      <Typography
        tag="div"
        data-qa-id={`${qaIdPrefix}-paint-thickness-subtitle`}
        additonalClassNames={styles.paragraph}
      >
        {translations.PAINT_THICKNESS_DESCRIPTION}
      </Typography>

      <Typography
        tag="div"
        data-qa-id={`${qaIdPrefix}-paint-thickness-subtitle`}
        additonalClassNames={styles.paragraph}
      >
        <InfoInCircle
          width={20}
          height={20}
          style={{ marginRight: '8px', flexShrink: '0' }}
        />
        {translations.PAINT_THICKNESS_DESCRIPTION_INFO}
      </Typography>

      <Typography
        tag="div"
        data-qa-id={`${qaIdPrefix}-paint-thickness-subtitle`}
        additonalClassNames={styles.paragraph}
      >
        <InfoInCircle
          width={20}
          height={20}
          style={{ marginRight: '8px', flexShrink: '0' }}
        />
        {translations.PAINT_THICKNESS_DISCLAIMER}
      </Typography>

      <FormToggleButton
        name="paintThickness.isPaintThicknessChecked"
        label={translations.WAS_PAINT_THICKNESS_CHECKED}
        containerClassName={styles.horizontalToggle}
        errorClassName={styles.horizontalToggleError}
        qaIdPrefix={`${qaIdPrefix}-is-paint-thickness-checked`}
        options={[
          {
            value: false,
            label: translations.NO,
          },
          {
            value: true,
            label: translations.YES,
          },
        ]}
        defaultValue={null}
      />
      {isPaintThicknessChecked && (
        <section
          data-qa-id={`${qaIdPrefix}-paint-thickness-item-container`}
          ref={fieldsContainerRef}
        >
          {areas
            ?.filter(filterNull)
            .map(({ area, canBePlastic, canBeFabric }) => (
              <ThicknessItemV2
                key={area}
                area={area}
                canBePlastic={canBePlastic}
                canBeFabric={canBeFabric}
                qaIdPrefix={`${qaIdPrefix}-paint-thickness-item`}
              />
            ))}
        </section>
      )}
    </Card>
  );
};

export { PaintThicknessV2 };
