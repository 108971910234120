const LOCALES_DATEPICKER = {
  bg: 'bg',
  ch: 'de',
  cs: 'cs',
  da: 'da',
  de: 'de',
  el: 'el',
  en: 'en-GB',
  es: 'es',
  et: 'et',
  fi: 'fi',
  fr: 'fr',
  hr: 'hr',
  hu: 'hu',
  it: 'it',
  lt: 'lt',
  lv: 'lv',
  nl: 'nl',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sr: 'sr',
  sv: 'sv',
  uk: 'uk',
};

module.exports = { LOCALES_DATEPICKER };
