import * as React from 'react';
import * as yup from 'yup';
import { AccidentAreaOption } from 'gql/graphql';
import { RoutesEnum } from 'shared/utils/route';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import {
  getSectionProgress,
  getPageProgress,
  getSectionErrorsYup,
} from 'shared/utils/calculateProgress';
import type { Form as AccidentsForm, Damage } from 'pages/Accidents/types';
import type { Form as DamagesForm, PaintThicknessDataType } from 'pages/Damages/types';
import { getDamagesSchema } from 'pages/Damages/schema';
import { getAccidentSchema } from 'pages/Accidents/schema';
import { getProgressFromErrors } from 'pages/Accidents/hooks/useProgress';
import { getProgressFromErrors as getDamagesProgressFromErrors } from 'pages/Damages/hooks/useProgress';
import { CarQualitySchema } from '../car-quality.schema';
import type { Form, PaintThicknessV2Form } from '../types';

const sections = [
  {
    sectionName: RoutesEnum.CAR_QUALITY_THICKNESS,
    prefix: 'paintThickness',
    requiredFieldsNb: 5,
    isOptional: false,
  },
  {
    sectionName: RoutesEnum.CAR_QUALITY_HIGHLIGHTS,
    prefix: 'highlights',
    requiredFieldsNb: 3,
    isOptional: true,
  },
] as const;

function useProgress() {
  const { setProgress, shouldUseNewAccidentModel } = useInspection();
  const { translations, translate } = useTranslation();

  const getCarQualityProgress = React.useCallback(
    (
      {
        carQualityForm,
        damagesForm,
        accidentsData,
        paintThicknessData,
      }: {
        carQualityForm: Form;
        damagesForm?: DamagesForm;
        accidentsData?: {
          accidentsForm: AccidentsForm;
          damages: Damage[];
          accidentAreaOptions: AccidentAreaOption[];
        };
        paintThicknessData?: PaintThicknessDataType,
      }
    ) => {
      const progressArray: number[] = [];
      const paintThicknessVersion = carQualityForm.paintThickness.version;
      const paintThicknessAreas = (
        carQualityForm.paintThickness as PaintThicknessV2Form
      ).areas;

      const validationSchema = CarQualitySchema(
        translations,
        false,
        paintThicknessAreas,
        paintThicknessVersion,
        shouldUseNewAccidentModel,
      );

      const damagesSchema = getDamagesSchema(
        translations,
        !damagesForm,
      );
      const accidentsSchema = getAccidentSchema(
        translations,
        accidentsData?.damages ?? [],
        accidentsData?.accidentAreaOptions ?? [],
        !accidentsData,
        translate,
      );

      const unionSchema = yup.object({
        carQuality: validationSchema,
        damages: damagesSchema,
        accidents: accidentsSchema,
      });

      unionSchema
        .validate(
          {
            carQuality: carQualityForm,
            damages: damagesForm,
            accidents: accidentsData?.accidentsForm,
          },
          { abortEarly: false },
        )
        .then(() => {
          const sectionsProgress = sections.reduce((acc, { sectionName }) => {
            acc[sectionName] = 100;

            if (shouldUseNewAccidentModel) {
              sectionsProgress[RoutesEnum.CAR_QUALITY_ACCIDENTS] = 100;
            }
            sectionsProgress[RoutesEnum.CAR_QUALITY_DAMAGES] = 100;

            return acc;
          }, {} as Record<typeof sections[number]['sectionName'] | typeof RoutesEnum.CAR_QUALITY_ACCIDENTS | typeof RoutesEnum.CAR_QUALITY_DAMAGES, number>);
          setProgress({
            [RoutesEnum.CAR_QUALITY]: 100,
            ...sectionsProgress,
          });
        })
        .catch((errors) => {
          const {
            carQuality: carQualityErrors = [],
            damages: damagesError = [],
            accidents: accidentsError = [],
          } =
            (errors as yup.ValidationError).inner?.reduce((acc, error) => {
              if (error.path?.startsWith('carQuality')) {
                acc.carQuality = [
                  ...(acc.carQuality ?? []),
                  { ...error, path: error.path?.replace(/^carQuality\./, '') },
                ];
                return acc;
              }

              if (error.path?.startsWith('accidents')) {
                acc.accidents = [
                  ...(acc.accidents ?? []),
                  { ...error, path: error.path.replace(/^accidents\./, '') },
                ];
                return acc;
              }

              if (error.path?.startsWith('damages')) {
                acc.damages = [
                  ...(acc.damages ?? []),
                  { ...error, path: error.path.replace(/^damages\./, '') },
                ];
                return acc;
              }

              return acc;
            }, {} as Record<'carQuality' | 'accidents' | 'damages', yup.ValidationError[]>) ??
            {};
          const sectionsProgress = sections
            .filter(
              ({ sectionName }) =>
                !(
                  sectionName === RoutesEnum.CAR_QUALITY_HIGHLIGHTS &&
                  shouldUseNewAccidentModel
                ),
            )
            .reduce(
              (acc, { sectionName, requiredFieldsNb, prefix, isOptional }) => {
                let totalFieldsNb;
                const sectionErrors = getSectionErrorsYup(
                  carQualityErrors,
                  prefix,
                );
                if (sectionName === RoutesEnum.CAR_QUALITY_THICKNESS) {
                  if (paintThicknessVersion === 1) {
                    totalFieldsNb = requiredFieldsNb;
                  } else {
                    totalFieldsNb = carQualityForm.paintThickness
                      .isPaintThicknessChecked
                      ? paintThicknessAreas.length
                      : 1;
                  }
                } else {
                  totalFieldsNb =
                    (carQualityForm?.[prefix]?.length ?? 0) * requiredFieldsNb;
                }
                const progress = getSectionProgress({
                  errors: sectionErrors,
                  totalFieldsNb,
                  isOptional,
                });

                if (
                  sectionName === RoutesEnum.CAR_QUALITY_THICKNESS ||
                  carQualityForm?.[prefix]?.length
                ) {
                  progressArray.push(progress);
                }
                acc[sectionName] = progress;

                return acc;
              },
              {} as Record<
                | typeof sections[number]['sectionName']
                | typeof RoutesEnum.ACCIDENTS
                | typeof RoutesEnum.DAMAGES,
                number
              >,
            );

          if (accidentsError && accidentsData?.accidentsForm) {
            sectionsProgress[RoutesEnum.ACCIDENTS] = getProgressFromErrors(
              accidentsData.accidentsForm,
              accidentsError,
            ).ACCIDENTS;
          }

          if (damagesError && damagesForm) {
            sectionsProgress[RoutesEnum.DAMAGES] = getDamagesProgressFromErrors(
              damagesForm,
              damagesError,
              paintThicknessData,
            ).DAMAGES;
          }

          setProgress({
            [RoutesEnum.CAR_QUALITY]: getPageProgress(
              Object.values(sectionsProgress),
            ),
            ...sectionsProgress,
          });
        });
    },
    [translations, setProgress, shouldUseNewAccidentModel, translate],
  );

  return getCarQualityProgress;
}

export { useProgress };
