import { USER_ACCESS_KEY, GOOGLE_USER_UUID } from 'auth/constants';
import { APP_NAMES } from 'config/urls';
import { localStorageService } from 'config/localStorageService';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { rootElementId } from 'shared/constants';

let uuid: string | null | undefined;
async function getUserId(attempts = 0) {
  if (uuid) return Promise.resolve(uuid);

  if (process.env.REACT_APP_NAME === APP_NAMES.MOBILE_EVALUATOR) {
    // todo use localStorageService once auth manager is fixed for adding object to localstorage
    uuid = window.localStorage.getItem(GOOGLE_USER_UUID);

    if (uuid) {
      return Promise.resolve(uuid);
    }

    if (attempts === 5) {
      throw new Error('Error trying to get user id');
    }

    return new Promise<string>((resolve) => {
      setTimeout(() => {
        resolve(getUserId(attempts + 1));
      }, 500);
    });
  }

  const tokenObj = (await localStorageService.getItem(USER_ACCESS_KEY)) as {
    uuid?: string;
  };
  uuid = tokenObj?.uuid;

  if (uuid) {
    return Promise.resolve(uuid);
  }

  if (attempts === 5) {
    throw new Error('Error trying to get user id');
  }

  return new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve(getUserId(attempts + 1));
    }, 500);
  });
}

function setUserIdToRootElement(uuid: string) {
  const rootElement = document.getElementById(rootElementId);

  if (!rootElement) {
    return;
  }

  rootElement.dataset.userUuid = uuid;
}

function addProxyToTracking() {
  const proxyHandler = {
    get<U extends string>(target: Record<U, (args: unknown) => void>, prop: U) {
      return (args: Record<string, unknown>) => {
        target[prop]?.({
          app: isMobileEvaluatorApp() ? 'eva-mob' : 'auto1',
          ...args,
        });
      };
    },
  };
  const trackingProxy = new Proxy(window.tracking.eva, proxyHandler);
  window.tracking = { eva: trackingProxy };
}

function configFakeTracking() {
  const proxyHandler = {
    get(_: unknown, prop: string) {
      return (...args: ReadonlyArray<unknown>) => {
        console.info(`📊tracking:${prop}`, ...args);
      };
    },
  };
  const trackingProxy = new Proxy(
    window.tracking.eva,
    proxyHandler,
  ) as typeof window.tracking.eva;
  window.tracking = { eva: trackingProxy };
}

export {
  addProxyToTracking,
  configFakeTracking,
  getUserId,
  setUserIdToRootElement,
};
