import * as React from 'react';
import { Modal } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';

type Props = {
  handleClose: React.ComponentProps<typeof Modal>['onClose'];
  handleClickFillManually: React.ComponentProps<typeof Modal>['onOk'];
  qaIdPrefix?: string;
};

const DATTimeoutModal: React.FC<Props> = ({
  handleClose,
  handleClickFillManually,
  qaIdPrefix = 'dat-timeout-modal',
}) => {
  const { translations } = useTranslation();

  return (
    <Modal
      isOpen
      title={translations.DAT_TIMEOUT_MODAL_TITLE}
      okLabel={translations.FILL_MANUALLY}
      onOk={handleClickFillManually}
      cancelLabel={translations.CANCEL}
      onCancel={handleClose}
      onClose={handleClose}
      qaIdPrefix={qaIdPrefix}
    >
      <Typography
        variant="textSmall"
        tag="div"
        data-qa-id={`${qaIdPrefix}-text`}
      >
        {translations.DAT_TIMEOUT_MODAL_TEXT}
      </Typography>
    </Modal>
  );
};

export { DATTimeoutModal };
