import * as React from 'react';
import { TaskPool } from './taskPool';
import type { UploadResponse } from 'shared/utils/types';

const MAX_PARALLEL_UPLOADS = 10;
const uploadPool = new TaskPool<{ data: UploadResponse; mimeType: string }>(
  MAX_PARALLEL_UPLOADS,
);

function useUploadPool() {
  const [isUploadPoolEmpty, setIsUploadPoolEmpty] = React.useState(true);
  React.useEffect(() => {
    function runningTaskCountChangeHandler(event: Event) {
      setIsUploadPoolEmpty((event as Event & { detail: number }).detail === 0);
    }

    uploadPool.addEventListener(
      'onRunningTaskCountChange',
      runningTaskCountChangeHandler,
    );

    return () => {
      uploadPool.removeEventListener(
        'onRunningTaskCountChange',
        runningTaskCountChangeHandler,
      );
    };
  }, []);

  return { uploadPool, isUploadPoolEmpty };
}

export { useUploadPool };
