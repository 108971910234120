import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { appRoutes } from 'appRoutes';
import { useTranslation } from 'shared/hooks/useTranslation';
import { formatVinToAPI } from 'shared/utils/formatVin';
import { useInspection } from 'shared/components/InspectionContext';
import {
  CarStatusDataDocument,
  useManufacturersQuery,
  useGetMainTypesQuery,
  useGetBuiltDatesQuery,
  useGetBodyTypesQuery,
  useGetMainTypeDetailsQuery,
  useGetMainTypeSubsQuery,
  UpdateEcodeDocument,
  PdfUpdateEcodeDocument,
} from 'gql/graphql';
import type { CarDataItem } from 'gql/graphql';

type OptionType = {
  label: string;
  value: string;
};
function buildOptionList(
  gqlResult: ReadonlyArray<CarDataItem | null> = [],
): OptionType[] {
  return gqlResult.map((item) => ({
    label: item?.value ?? '',
    value: item?.id ?? '',
  }));
}

type CarDetailsManualFlowArgs = {
  manufacturerId: string;
  mainTypeId: string;
  builtDate: string;
  bodyType: string;
  mainTypeDetailId: string;
  ecode: string;
  vin: string;
  branch: number;
  country: string;
  inspectionId: number | null;
  licensePlate: string;
  externalId: string;
  setIsBeforeEcodeSubmit: React.Dispatch<React.SetStateAction<boolean>>;
};
const useCarDetailsManualFlow = ({
  manufacturerId,
  mainTypeId,
  builtDate,
  bodyType,
  mainTypeDetailId,
  ecode,
  vin,
  branch,
  country,
  inspectionId,
  licensePlate,
  externalId,
  setIsBeforeEcodeSubmit,
}: CarDetailsManualFlowArgs) => {
  const { locale } = useTranslation();
  const { isPdfInspection } = useInspection();
  const history = useHistory();

  const [ecodeUpdater, ecodeUpdateState] = useMutation(
    isPdfInspection ? PdfUpdateEcodeDocument : UpdateEcodeDocument,
    {
      onCompleted: (response) => {
        const result = isPdfInspection
          ? response.msiPdfSubmitEcode
          : response.msiSubmitEcode;

        history.push(appRoutes.carDetails(result?.inspectionId, true));
      },
      refetchQueries: [CarStatusDataDocument],
    },
  );

  const { data: manufacturersData } = useManufacturersQuery({
    variables: { locale: country },
    fetchPolicy: 'no-cache',
  });

  const { data: mainTypesData } = useGetMainTypesQuery({
    variables: { manufacturerId, country, locale },
    skip: !manufacturerId,
    fetchPolicy: 'no-cache',
  });

  const { data: builtDatesData } = useGetBuiltDatesQuery({
    variables: {
      manufacturerId,
      mainTypeId,
      country,
    },
    skip: !(manufacturerId && mainTypeId),
    fetchPolicy: 'no-cache',
  });

  const { data: bodyTypesData } = useGetBodyTypesQuery({
    variables: {
      locale,
      country,
      manufacturerId,
      mainTypeId,
      builtDate,
    },
    skip: !(manufacturerId && mainTypeId && builtDate),
    fetchPolicy: 'no-cache',
  });

  const { data: mainTypeDetailsData } = useGetMainTypeDetailsQuery({
    variables: {
      locale,
      country,
      manufacturerId,
      mainTypeId,
      builtDate,
      bodyType,
    },
    skip: !(manufacturerId && mainTypeId && builtDate && bodyType),
    fetchPolicy: 'no-cache',
  });

  const { data: mainTypeSubsData } = useGetMainTypeSubsQuery({
    variables: {
      locale,
      country,
      manufacturerId,
      mainTypeId,
      builtDate,
      bodyType,
      mainTypeDetailId,
    },
    skip: !(
      manufacturerId &&
      mainTypeId &&
      builtDate &&
      bodyType &&
      mainTypeDetailId
    ),
    fetchPolicy: 'no-cache',
  });

  const submitEcode = useCallback(() => {
    return ecodeUpdater({
      variables: {
        vin: formatVinToAPI(vin),
        inspectionId,
        ecode,
        year: parseInt(builtDate, 10),
        branch,
        licensePlate,
        externalId,
      },
    });
  }, [
    branch,
    builtDate,
    ecode,
    ecodeUpdater,
    inspectionId,
    vin,
    licensePlate,
    externalId,
  ]);
  const handleEcodeSubmit = useCallback(() => {
    setIsBeforeEcodeSubmit(true);
    submitEcode();
  }, [setIsBeforeEcodeSubmit, submitEcode]);

  return {
    manufacturers: buildOptionList(manufacturersData?.getManufacturers),
    mainTypes: buildOptionList(mainTypesData?.getMainTypes),
    builtDates: buildOptionList(
      [...(builtDatesData?.getBuiltDates ?? [])].sort(
        (a, b) => parseInt(b?.value ?? '', 10) - parseInt(a?.value ?? '', 10),
      ),
    ),
    bodyTypes: buildOptionList(bodyTypesData?.getBodyTypes),
    mainTypeDetails: buildOptionList(mainTypeDetailsData?.getMainTypeDetails),
    mainTypeSubs: buildOptionList(mainTypeSubsData?.getMainTypeSubs),
    isEcodeUpdateLoading: ecodeUpdateState.loading,
    handleEcodeSubmit,
  };
};

export { useCarDetailsManualFlow };
