import * as React from 'react';
import ReactDOM from 'react-dom';
import { isProductionEnv } from 'shared/utils/isProductionUrl';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import {
  configFakeTracking,
  getUserId,
  setUserIdToRootElement,
} from 'shared/utils/tracking';
import { isLocalEnv } from 'shared/utils/isProductionUrl';
import { rootElementId } from 'shared/constants';
import App from './App';

const rootElement = document.getElementById(rootElementId);
async function init() {
  try {
    const userId = await getUserId();
    setUserIdToRootElement(userId);
  } catch {
    // empty block
  } finally {
    ReactDOM.render(<App />, rootElement);
  }
}

if (isLocalEnv()) {
  configFakeTracking();
}

if (isMobileEvaluatorApp()) {
  window.nodejsLayerUrl = isProductionEnv()
    ? 'https://www.auto1.com'
    : 'https://auto1-training-1.auto1-test.com';

  init();
} else {
  ReactDOM.render(<App />, rootElement);
}
