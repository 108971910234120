import * as React from 'react';
import type { VariationType } from '../types';

type Props = {
  variant: VariationType;
  qaIdPrefix?: string;
};

const ExteriorRight: React.FC<Props> = ({ variant, qaIdPrefix = '' }) => (
  <>
    <mask
      id="mask0_431_9762"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="323"
      y="102"
      width="179"
      height="324"
    >
      <path
        d="M460.276 248.725C484.094 250.852 503.964 230.904 501.845 206.991C500.211 188.657 485.515 173.903 467.254 172.263C443.436 170.135 423.567 190.084 425.686 213.997C427.32 232.33 442.015 247.085 460.276 248.725ZM430.349 106.65C437.437 103.874 436.237 102.063 445.368 102.182C454.499 102.31 455.46 104.113 457.86 107.496C460.259 110.879 465.791 109.547 466.99 108.462C468.19 107.377 471.671 106.283 475.159 110.751C478.64 115.211 479.44 125.582 479.44 125.582V163.942V167.179C474.547 165.394 469.271 164.42 463.765 164.42C437.82 164.42 416.904 186.034 417.908 212.305C418.819 236.192 438.186 255.628 461.978 256.533C468.131 256.773 474.028 255.782 479.44 253.808V257.772V416.497C463.765 413.285 435.072 410.551 408.965 412.192C376.655 413.952 323.429 425.502 323.429 425.502V406.365C323.429 374.764 334.244 340.992 338.329 330.142C342.413 319.284 383.981 240.566 383.981 240.566C383.981 240.566 386.704 212.425 389.427 192.809C392.15 173.185 400.796 142.105 405.884 126.915C410.965 111.717 423.261 109.427 430.349 106.65Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_431_9762)">
      <path
        d="M383.658 435.062V514.907C383.658 520.469 378.459 524.544 373.081 523.194L341.392 515.24C339.12 514.668 337.529 512.617 337.529 510.268V436.377C337.529 433.831 339.392 431.661 341.903 431.294L373.923 426.604C379.055 425.852 383.658 429.85 383.658 435.062Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M387.633 296.849L383.897 399.607C383.753 403.494 381.021 406.792 377.243 407.638L343.401 415.19C340.261 415.89 337.249 413.532 337.189 410.295C336.985 399.077 339.453 374.712 351.306 347.989C366.666 313.346 377.6 299.924 383.302 294.798C385.012 293.26 387.718 294.542 387.633 296.849Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M336.763 334.508L339.92 342.581C340.618 344.366 343.119 344.401 343.86 342.632C348.191 332.269 358.572 308.211 365.397 298.617C374.204 286.238 385.258 272.885 385.419 265.333C385.496 261.557 384.739 250.937 383.956 241.275"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M386.679 323.12C378.212 337.02 363.959 322.112 368.715 313.851"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M332.066 426.954C332.066 426.954 376.655 417.01 408.956 415.25C433.787 413.9 456.09 415.968 471.186 418.112V285.401C471.186 281.206 467.799 277.806 463.621 277.806H399.256C395.98 277.806 392.806 278.866 390.151 280.796C374.825 291.92 360.496 314.653 346.634 345.913C333.112 376.395 332.066 402.401 332.066 409.501V532.985C332.066 534.83 331.436 536.616 330.27 538.042L328.066 540.751"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M471.194 415.908L469.509 506.364C443.879 504.519 432.272 514.737 422.419 526.022L413.518 538.538C408.25 543.929 400.345 545.766 393.257 543.245L332.074 521.554"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M446.448 227.832C436.941 218.246 436.992 202.719 446.563 193.15C456.133 183.582 471.599 183.596 481.107 193.181C490.614 202.766 490.563 218.293 480.992 227.862C471.422 237.431 455.956 237.417 446.448 227.832Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M385.191 258.473C388.331 256.072 391.658 251.57 392.305 243.146C393.589 226.418 402.873 144.728 413.127 139.585C423.381 134.442 430.103 136.372 430.427 128.008C430.75 119.645 430.427 86.1978 430.427 86.1978"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M427.192 182.669V133.998"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M404.115 386.245V399.462C404.115 401.991 402.073 404.041 399.554 404.041H399.546C397.027 404.041 394.985 401.991 394.985 399.462V386.245C394.985 383.716 397.027 381.666 399.546 381.666H399.554C402.073 381.666 404.115 383.716 404.115 386.245Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M413.799 108.863C411.314 119.03 409.476 132.016 408.591 146.599"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M468.225 98.4917V113.459C468.225 116.287 465.595 118.38 462.855 117.74L457.511 116.484C455.341 115.98 453.81 114.04 453.81 111.802V98.4917H468.225Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <mask
      id="mask1_431_9762"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="323"
      y="403"
      width="179"
      height="281"
    >
      <path
        d="M460.276 595.573C484.094 597.7 503.964 577.751 501.845 553.839C500.211 535.514 485.515 520.751 467.254 519.119C443.436 516.983 423.567 536.932 425.686 560.844C427.32 579.178 442.015 593.941 460.276 595.573ZM408.965 403.52C435.072 401.88 463.765 404.614 479.44 407.826V509.927L479.21 513.95C473.347 511.849 466.939 510.9 460.251 511.404C437.735 513.096 419.602 531.362 417.993 553.967C416.079 580.938 437.31 603.415 463.765 603.415C467.348 603.415 470.828 602.988 474.172 602.211L473.994 605.295C473.994 605.295 469.994 662.706 469.671 668.173C469.347 673.641 465.986 676.05 463.263 675.726C461.808 675.555 459.162 677.802 457.017 679.92C454.669 682.244 451.486 683.526 448.185 683.526H435.157C428.664 683.526 422.418 672.676 422.418 672.676L418.334 674.846C418.334 674.846 397.673 674.846 386.858 674.846C380.254 674.846 375.566 671.471 376.944 652.471C364.759 625.149 325.139 532.626 324.373 530.328C323.752 528.457 323.429 526.5 323.429 524.527V416.831C323.429 416.831 376.655 405.28 408.965 403.52Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_431_9762)">
      <path
        d="M383.658 435.063V514.908C383.658 520.47 378.459 524.545 373.081 523.195L341.392 515.241C339.12 514.669 337.529 512.618 337.529 510.269V436.378C337.529 433.832 339.392 431.662 341.903 431.295L373.923 426.605C379.055 425.853 383.658 429.851 383.658 435.063Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M376.171 545.313C380.681 546.851 383.71 551.106 383.71 555.89V579.034C383.71 585.714 375.371 588.653 371.201 583.527C371.125 583.433 371.056 583.331 370.988 583.228L341.503 536.146C340.193 534.053 342.227 531.465 344.558 532.259L376.162 545.313H376.171Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M387.633 296.849L383.897 399.607C383.753 403.495 381.021 406.792 377.243 407.638L343.401 415.19C340.261 415.891 337.249 413.533 337.189 410.295C336.985 399.078 339.453 374.712 351.306 347.989C366.666 313.346 377.6 299.925 383.302 294.799C385.012 293.261 387.718 294.542 387.633 296.849Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M332.976 552.259L333.453 551.738C335.027 550.029 339.46 547.56 340.481 549.653L377.727 625.432C379.752 629.55 380.799 634.069 380.799 638.657L376.936 652.471"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M332.066 423.897C332.066 423.897 376.655 413.952 408.956 412.192C433.787 410.843 456.09 412.91 471.186 415.054V285.393C471.186 281.199 467.799 277.798 463.621 277.798H399.256C395.98 277.798 392.806 278.858 390.151 280.788C374.825 291.912 360.496 314.645 346.634 345.905C333.112 376.388 332.066 402.393 332.066 409.493V532.977C332.066 534.822 331.436 536.608 330.27 538.035L328.066 540.743"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M471.194 415.055L469.509 505.511C443.879 503.665 432.272 513.883 422.419 525.169L413.518 537.685C408.25 543.075 400.345 544.912 393.257 542.392L332.074 520.701"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M407.314 658.733V675.273C407.314 679.166 404.171 682.321 400.294 682.321H393.444C389.567 682.321 386.424 679.166 386.424 675.273V658.733C386.424 654.841 389.567 651.685 393.444 651.685H400.294C404.171 651.685 407.314 654.841 407.314 658.733Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M446.452 574.7C436.945 565.115 436.996 549.588 446.567 540.019C456.137 530.451 471.603 530.464 481.111 540.049C490.618 549.635 490.567 565.162 480.996 574.731C471.425 584.299 455.96 584.286 446.452 574.7Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M422.896 677.862V578.333"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M401.435 505.664V518.88C401.435 521.409 399.393 523.459 396.874 523.459H396.865C394.346 523.459 392.304 521.409 392.304 518.88V505.664C392.304 503.135 394.346 501.085 396.865 501.085H396.874C399.393 501.085 401.435 503.135 401.435 505.664Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M404.115 386.246V399.463C404.115 401.992 402.073 404.042 399.554 404.042H399.546C397.027 404.042 394.985 401.992 394.985 399.463V386.246C394.985 383.717 397.027 381.667 399.546 381.667H399.554C402.073 381.667 404.115 383.717 404.115 386.246Z"
        stroke="#E0E0E0"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <g className={variant} data-qa-id={`${qaIdPrefix}-exterior-right`}>
      <path
        d="M425.686 214.496C427.32 232.829 442.015 247.584 460.276 249.224C484.094 251.351 503.964 231.403 501.845 207.49C500.211 189.156 485.515 174.402 467.254 172.762C443.436 170.635 423.567 190.583 425.686 214.496Z"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M418.575 553.967C420.184 531.362 438.317 513.096 460.833 511.405C467.521 510.9 473.929 511.849 479.792 513.95L480.021 509.927V416.497V257.772V253.808C474.61 255.782 468.713 256.773 462.56 256.533C438.768 255.628 419.401 236.192 418.49 212.305C417.486 186.034 438.402 164.42 464.347 164.42C469.853 164.42 475.129 165.394 480.021 167.179V163.942V125.582C480.021 125.582 479.222 115.211 475.741 110.751C472.252 106.283 468.772 107.377 467.572 108.462C466.372 109.547 460.841 110.879 458.442 107.496C456.042 104.113 455.08 102.31 445.95 102.182C436.819 102.063 438.019 103.874 430.931 106.65C423.843 109.427 411.546 111.717 406.466 126.915C401.378 142.105 392.732 173.185 390.009 192.809C387.286 212.425 384.563 240.566 384.563 240.566C384.563 240.566 342.995 319.284 338.91 330.142C334.826 340.992 324.01 374.764 324.01 406.365V425.502V524.527C324.01 526.501 324.334 528.457 324.955 530.328C325.721 532.626 365.341 625.149 377.526 652.471C376.147 671.471 380.836 674.846 387.439 674.846C398.255 674.846 418.916 674.846 418.916 674.846L423 672.676C423 672.676 429.246 683.526 435.739 683.526H448.767C452.068 683.526 455.251 682.244 457.599 679.92C459.744 677.802 462.39 675.555 463.845 675.726C466.568 676.05 469.929 673.641 470.253 668.173C470.576 662.706 474.575 605.295 474.575 605.295L474.754 602.211C471.41 602.988 467.93 603.415 464.347 603.415C437.892 603.415 416.661 580.938 418.575 553.967Z"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M467.254 519.618C443.436 517.482 423.567 537.431 425.686 561.343C427.32 579.677 442.015 594.44 460.276 596.072C484.094 598.199 503.964 578.25 501.845 554.338C500.211 536.012 485.515 521.25 467.254 519.618Z"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </g>
  </>
);

export { ExteriorRight };
