import * as React from 'react';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { apm } from '@elastic/apm-rum';

import { useTranslation } from 'shared/hooks/useTranslation';
import { useScrollToSection } from 'shared/utils/useSectionScroll';
import { appRoutes } from 'appRoutes';
import { useInspection } from 'shared/components/InspectionContext';
import { RoutesEnum } from 'shared/utils/route';
import { scrollToError } from 'shared/utils/scrollToError';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import {
  useTestDriveInitialDataQuery,
  useSubmitTestDriveMutation,
} from 'gql/graphql';
import {
  mapDataToFormValues,
  mapAnswersToMutation,
  mapMileageManipulationSuspicionsToMutation,
} from './utils';
import { getTestDriveSchema } from './schema';
import { useProgress } from './hooks/useProgress';
import type { Question, Form } from './types';

const useTestDrive = ({ inspectionId }: { inspectionId: number }) => {
  const [questions, setQuestions] = React.useState<Array<Question>>([]);

  const history = useHistory();
  const { removeStepFromNotCompleted, isPdfInspection } = useInspection();
  const { submitTracking } = useSubmitTracking('testDrive');
  const { translations } = useTranslation();

  const { location } = history;
  useScrollToSection(location.hash);

  const submitContext = React.useRef('');
  const formMethods = useForm<Form>({
    context: submitContext,
    resolver: (values, context, options) =>
      yupResolver(
        getTestDriveSchema({
          translations,
          questions,
          isPdfInspection,
          isOptional: submitContext.current === 'save',
        }),
      )(values, context, options),
  });

  const [submitTestDrive, { error: mutationError }] =
    useSubmitTestDriveMutation();
  const serverError = mutationError?.graphQLErrors?.[0]?.message;

  const { loading } = useTestDriveInitialDataQuery({
    variables: {
      inspectionId,
    },
    onCompleted: (data) => {
      const { form, questions: qs } = mapDataToFormValues(data);
      formMethods.reset(form);
      setQuestions(qs);
    },
  });

  const setProgress = useProgress();

  const onSubmitError = () => {
    scrollToError();
    setProgress({
      formValues: formMethods.getValues(),
      questions,
    });
  };
  const handleSubmit = async (formValues: Form) => {
    const redirect = submitContext.current === 'submit';
    try {
      const { question, mileage } = formValues;
      const qs = Object.entries(pickBy(question, identity)).reduce(
        (acc, [key, value]) => {
          return {
            ...acc,
            [key.replace('_', '')]: value,
          };
        },
        {},
      );
      const variables = {
        inspectionId,
        answers: mapAnswersToMutation(qs),
        mileage: mileage.currentMileage,
        skipSubmitMileageManipulationSuspicions:
          mileage.isMileageManipulated === null,
        mileageSuspicions: mapMileageManipulationSuspicionsToMutation(mileage),
        incomplete: submitContext.current === 'save',
      };
      setProgress({
        formValues: formMethods.getValues(),
        questions,
      });
      await submitTestDrive({
        variables,
      });
      formMethods.reset(formValues);
      removeStepFromNotCompleted(RoutesEnum.TEST_DRIVE);

      if (redirect) {
        submitTracking();
        history.push(appRoutes.carPictures(`${inspectionId}`, true));
        return;
      }
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
    }
  };

  return {
    questions,
    formMethods,
    translations,
    handleSubmit,
    onSubmitError,
    loading,
    submitContext,
    serverError,
  };
};

export { useTestDrive };
