import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useCarDetailsCarInfoQuery } from 'gql/graphql';
import type { CarDetailsCarInfoQuery } from 'gql/graphql';
import { isElectricCar } from 'shared/constants';

type Context = {
  loading: boolean;
  isElectricCar: boolean;
  carInfo: {
    car?: NonNullable<NonNullable<CarDetailsCarInfoQuery>['inspection']>['car'];
    vehicle?: NonNullable<
      NonNullable<CarDetailsCarInfoQuery>['inspection']
    >['vehicle'];
    branch?: NonNullable<
      NonNullable<CarDetailsCarInfoQuery>['inspection']
    >['branch'];
  };
};

const CarInfoContext = React.createContext<Context>({
  loading: true,
  isElectricCar: false,
  carInfo: {},
});

const CarInfoProvider: React.FC = ({ children }) => {
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const { data = {}, loading } = useCarDetailsCarInfoQuery({
    variables: {
      inspectionId: Number.parseInt(inspectionId, 10),
    },
    skip: !inspectionId,
  });
  const { car, vehicle, branch } = data?.inspection ?? {};
  const fuelType = vehicle?.engine?.fuelType;

  return (
    <CarInfoContext.Provider
      value={{
        loading,
        carInfo: {
          car,
          vehicle,
          branch,
        },
        isElectricCar: fuelType ? isElectricCar(fuelType) : false,
      }}
    >
      {children}
    </CarInfoContext.Provider>
  );
};

const useCarInfo = () => {
  return React.useContext(CarInfoContext);
};

export { CarInfoProvider, useCarInfo };
