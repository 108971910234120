export const testDriveQuestions = {
  PERFORMED: 11000,
  PERFORMED_YES_RESPONSE: 11010,
  PERFORMED_NO_RESPONSE: 11020,
  WHERE: 10010,
  WHERE_BRANCH_AREA_RESPONSE: 10040,
} as const;

export const TESTDRIVE_QUESTION_IDS = [
  testDriveQuestions.PERFORMED,
  testDriveQuestions.WHERE,
] as const;

export const TEST_DRIVE_SECTION_QUESTION_IDS = [
  testDriveQuestions.PERFORMED,
  testDriveQuestions.PERFORMED_NO_RESPONSE,
  testDriveQuestions.WHERE,
  testDriveQuestions.WHERE_BRANCH_AREA_RESPONSE,
] as const;
