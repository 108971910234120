import * as React from 'react';
import cn from 'classnames';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import { FormUploaderPlaceholder } from 'shared/components/FormUploader/Placeholder';
import { FormDropdownPlaceholder } from 'shared/components/FormDropdown/Placeholder';
import { FormTextareaPlaceholder } from 'shared/components/FormTextarea/Placeholder';

import styles from '../index.module.scss';

const OtherDocumentsPlaceholder: React.FC = () => (
  <Card
    paddingTop={32}
    paddingBottom={32}
    externalStyle={styles.card}
    qaIdPrefix="other-documents-loader"
    id="other-documents"
  >
    <div className={styles.title}>
      <Typography variant="titleXL">
        <Skeleton width="186px" height="100%" />
      </Typography>
    </div>
    <div className={styles.formElementContainer}>
      <div className={styles.formElementContainer}>
        <Typography additonalClassNames={styles.labelTitle}>
          <Skeleton width="303px" height="100%" style={{ marginLeft: '0' }} />
        </Typography>
        <FormUploaderPlaceholder />
      </div>
      <div
        className={cn([
          styles.inspectionCompanyInput,
          styles.formElementContainer,
        ])}
      >
        <FormDropdownPlaceholder labelWidth="137px" />
      </div>
      <FormTextareaPlaceholder labelWidth="100px" />
    </div>
  </Card>
);

export { OtherDocumentsPlaceholder };
