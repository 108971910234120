import React from 'react';
import { Skeleton } from 'shared/components/Skeleton';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { FormToggleButtonPlaceholder } from 'shared/components/FormToggleButton/Placeholder';
import { FormInputPlaceholder } from 'shared/components/FormInput/Placeholder';
import styles from '../index.module.scss';

const ServicePlaceholder: React.FC = () => (
  <Card
    paddingTop={32}
    paddingBottom={32}
    externalStyle={styles.card}
    id="service"
    qaIdPrefix="service-loader"
    showBottomBorder
  >
    <div className={styles.title}>
      <Typography variant="titleXL">
        <Skeleton width="100px" height="100%" />
      </Typography>
    </div>
    <div className={styles.formElementContainer}>
      <FormToggleButtonPlaceholder labelWidth="156px" width="519px" />
    </div>
    <div className={styles.formElementContainer}>
      <FormToggleButtonPlaceholder labelWidth="133px" width="216px" />
    </div>
    <div className={styles.formElementContainer}>
      <FormToggleButtonPlaceholder labelWidth="115px" width="216px" />
    </div>
    <div className={styles.formElementContainer}>
      <FormToggleButtonPlaceholder labelWidth="359px" width="216px" />
    </div>
    <div className={styles.formElementContainer}>
      <FormToggleButtonPlaceholder labelWidth="119px" width="216px" />
    </div>
    <div>
      <FormInputPlaceholder labelWidth="191px" width="288px" />
    </div>
  </Card>
);

export { ServicePlaceholder };
