import * as React from 'react';
import cn from 'classnames';
import { ExclamationInCircle } from '@auto1-ui/icons';
import { Typography } from 'shared/components/Typography';

import styles from './styles.module.scss';

type AlertType = 'info' | 'error';

type Props = {
  message: string;
  type: AlertType;
  additionalClassNames?: string;
  qaTag?: string;
};

function getVariationClasses(type: AlertType) {
  switch (type) {
    case 'error':
      return [styles.errorContainer, styles.errorIcon];
    default:
      return [styles.infoContainer, styles.infoIcon];
  }
}

const AlertBar: React.FC<Props> = ({
  message,
  type = 'info',
  additionalClassNames,
  qaTag,
}) => {
  const [containerVariationClass, iconVariationClass] =
    getVariationClasses(type);

  return (
    <div
      className={cn(
        styles.alertContainer,
        containerVariationClass,
        additionalClassNames,
      )}
      data-qa-id={qaTag}
    >
      <ExclamationInCircle className={cn(styles.icon, iconVariationClass)} />
      <Typography variant="textSmall">{message}</Typography>
    </div>
  );
};

export { AlertBar };
