import * as React from 'react';
import { Dialog } from '@auto1-ui/dialog';
import { Button, ButtonThemes, ButtonTypes } from '@auto1-ui/button';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';

type Props = {
  onClick: () => void;
  qaIdPrefix?: string;
};

const DeleteNotPossibleModal: React.FC<Props> = ({
  onClick,
  qaIdPrefix = 'delete-not-possible-modal',
}) => {
  const { translations } = useTranslation();

  return (
    <Dialog
      isOpen
      isCloseIconVisible
      contentExtraClassName={styles.modalContent}
      handleClose={onClick}
    >
      <Typography
        tag="div"
        variant="titleXL"
        additonalClassNames={styles.title}
        data-qa-id={`${qaIdPrefix}-title`}
      >
        {translations.ACCIDENT_DELETE_NOT_POSSIBLE_MODAL_TITLE}
      </Typography>
      <Typography
        tag="p"
        additonalClassNames={styles.text}
        data-qa-id={`${qaIdPrefix}-subtitle`}
      >
        {translations.ACCIDENT_DELETE_NOT_POSSIBLE_MODAL_TEXT}
      </Typography>
      <Button
        theme={ButtonThemes.orange}
        type={ButtonTypes.button}
        onClick={onClick}
        qaId={`${qaIdPrefix}-primary-button`}
      >
        {translations.GOT_IT}
      </Button>
    </Dialog>
  );
};

export { DeleteNotPossibleModal };
