import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import type { Resolver, ResolverOptions } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonTypes, ButtonThemes } from '@auto1-ui/button';
import { withTransaction } from '@elastic/apm-rum-react';

import { appRoutes } from 'appRoutes';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { AlertBanner } from 'shared/components/AlertBanner';
import { useTranslation } from 'shared/hooks/useTranslation';
import { PdfJobStatus } from 'shared/constants';
import { Card } from 'shared/components/Card';
import { Grid } from 'shared/components/Grid';
import { FormDropdown } from 'shared/components/FormDropdown';
import { VinInfo } from 'shared/components/VinInfo';
import { NavigationFooterPlaceholder } from 'shared/components/NavigationFooter/Placeholder';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import Loader from 'shared/components/Loader';
import { formatVinToUI } from 'shared/utils/formatVin';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import stylesNavigationFooter from 'shared/components/NavigationFooter/index.module.scss';
import { VinInfoPlaceholder } from 'shared/components/VinInfo/Placeholder';

import { useManualFlowInitialDataQuery } from 'gql/graphql';
import type { PdfJobStatus as PdfJobStatusType } from 'gql/graphql';
import { useCarDetailsManualFlow } from './hooks/useCarDetailsManualFlow';
import { ManualFlowSchema } from './ManualFlowSchema';
import { CarInfoSchema } from 'shared/components/VinInfo/car-info-schema';
import type { Form } from './types';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
};
const ManualFlow: React.FC<Props> = ({ qaIdPrefix = 'manual-flow-page' }) => {
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [isBeforeEcodeSubmit, setIsBeforeEcodeSubmit] = React.useState(false);
  const [shouldSubmitAutomatically, setShouldSubmitAutomatically] =
    React.useState(true);
  const location =
    useLocation<{
      pdfStatus?: PdfJobStatusType | null;
      vin?: string;
      licensePlate?: string;
      externalId?: string;
    }>();
  const params = useParams<{ branchId?: string; inspectionId?: string }>();
  const { translations } = useTranslation();
  const inspectionId = params.inspectionId ?? null;

  const formValidationResolver: Resolver<Form> = async (
    data: Form,
    context: unknown,
    options: ResolverOptions<Form>,
  ) => {
    const manualFlowErrors = await yupResolver(ManualFlowSchema(translations))(
      data,
      context,
      options,
    );
    const carInfoErrors = await yupResolver(CarInfoSchema(translations))(
      data,
      context,
      options,
    );

    return {
      values: {
        ...manualFlowErrors.values,
        ...carInfoErrors.values,
      },
      errors: {
        ...manualFlowErrors.errors,
        ...carInfoErrors.errors,
      },
    };
  };

  const formMethods = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      vin: location?.state?.vin ?? '',
      licensePlate: location?.state?.licensePlate ?? '',
      externalId: location?.state?.externalId ?? '',
    },
    resolver: formValidationResolver,
  });
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = formMethods;

  const { data, loading } = useManualFlowInitialDataQuery({
    skip: inspectionId === null,
    variables: {
      inspectionId: Number(inspectionId),
    },
    onCompleted: (result) => {
      const { licensePlate, externalId } =
        result?.inspection?.vehicle?.identifiers ?? {};
      const vin =
        result?.inspection?.vehicle?.identifiers?.vin ??
        result?.inspection?.car.vin;
      formMethods.reset({
        vin: location?.state?.vin
          ? formatVinToUI(location?.state?.vin)
          : formatVinToUI(vin ?? ''),
        licensePlate: location?.state?.licensePlate ?? licensePlate ?? '',
        externalId: location?.state?.externalId ?? externalId ?? '',
      });
    },
  });
  const branchId = params?.branchId
    ? Number(params?.branchId)
    : data?.inspection?.branch?.id;

  const vin = watch('vin');
  const licensePlate = watch('licensePlate');
  const externalId = watch('externalId');
  const manufacturerId = watch('manufacturerId');
  const mainTypeId = watch('mainTypeId');
  const builtDate = watch('builtDate');
  const bodyType = watch('bodyType');
  const mainTypeDetailId = watch('mainTypeDetailId');
  const ecode = watch('ecode');

  React.useEffect(() => setValue('mainTypeId', ''), [manufacturerId, setValue]);
  React.useEffect(() => setValue('builtDate', ''), [mainTypeId, setValue]);
  React.useEffect(() => setValue('bodyType', ''), [builtDate, setValue]);
  React.useEffect(() => setValue('mainTypeDetailId', ''), [bodyType, setValue]);
  React.useEffect(() => setValue('ecode', ''), [mainTypeDetailId, setValue]);

  const {
    manufacturers,
    mainTypes,
    builtDates,
    bodyTypes,
    mainTypeDetails,
    mainTypeSubs,
    handleEcodeSubmit,
    isEcodeUpdateLoading,
  } = useCarDetailsManualFlow({
    manufacturerId,
    mainTypeId,
    builtDate,
    bodyType,
    mainTypeDetailId,
    ecode,
    vin: (vin || data?.inspection?.vehicle?.identifiers?.vin) ?? '',
    licensePlate: licensePlate ?? '',
    externalId: externalId ?? '',
    branch: branchId!,
    country: data?.inspection?.branch?.country ?? '',
    inspectionId: inspectionId === null ? null : Number(inspectionId),
    setIsBeforeEcodeSubmit,
  });

  const ecodeSubmitProps = () => {
    if (!ecode || !isValid) {
      return {
        'data-microtip-position': 'top',
        'aria-label': translations.SUBMIT_ECODE_BUTTON_TOOLTIP,
        role: 'tooltip',
      };
    }

    return {};
  };

  React.useLayoutEffect(() => {
    if (location?.state?.pdfStatus === PdfJobStatus.FINISHED) {
      setIsAlertVisible(true);
    }
  }, [location]);

  const vinError = errors?.vin;
  React.useEffect(() => {
    if (vinError) {
      setShouldSubmitAutomatically(false);
    }
  }, [vinError]);

  React.useEffect(() => {
    if (ecode && shouldSubmitAutomatically && isValid) {
      handleEcodeSubmit();
    }
  }, [ecode, handleEcodeSubmit, isValid, shouldSubmitAutomatically]);

  return (
    <FormProvider {...formMethods}>
      <LayoutContainer>
        {isAlertVisible && (
          <AlertBanner onClose={() => setIsAlertVisible(false)}>
            {translations.PDF_INFO_POPULATED_SUCCESSFULLY}
          </AlertBanner>
        )}
        {loading ? (
          <VinInfoPlaceholder />
        ) : (
          <VinInfo
            vin={data?.inspection?.vehicle?.identifiers?.vin ?? undefined}
            inspectionId={inspectionId ? Number(inspectionId) : null}
            branchId={branchId || data?.inspection?.branch?.id || null}
            licensePlateCallEnabled={
              data?.inspection?.branch?.licensePlateCallEnabled
            }
            qaIdPrefix={`${qaIdPrefix}-vin-info`}
            setIsBeforeEcodeSubmit={setIsBeforeEcodeSubmit}
            showManualFlow
          />
        )}
        <Card paddingBottom={32}>
          <Grid
            qaIdPrefix={`${qaIdPrefix}-manual-flow-form`}
            columns={2}
            gutter={[2, 2]}
          >
            <Grid item>
              <FormDropdown
                label={translations.MAKE}
                qaIdPrefix={`${qaIdPrefix}-make-dropdown`}
                name="manufacturerId"
                defaultValue=""
                options={manufacturers}
                disabled={!manufacturers.length}
                searchable
                containerClassName={styles.inputContainerFullWidth}
              />
            </Grid>
            <Grid item>
              <FormDropdown
                label={translations.MAIN_TYPE}
                qaId={`${qaIdPrefix}-main-type-dropdown`}
                name="mainTypeId"
                defaultValue=""
                options={mainTypes}
                disabled={!mainTypes.length}
                searchable
                containerClassName={styles.inputContainerFullWidth}
              />
            </Grid>
            <Grid item>
              <FormDropdown
                label={translations.BUILT_YEAR}
                qaIdPrefix={`${qaIdPrefix}-built-year-dropdown`}
                name="builtDate"
                defaultValue=""
                options={builtDates}
                disabled={!builtDates.length}
                searchable
                containerClassName={styles.inputContainerFullWidth}
              />
            </Grid>
            <Grid item>
              <FormDropdown
                label={translations.BODY_TYPE}
                qaIdPrefix={`${qaIdPrefix}-body-type-dropdown`}
                name="bodyType"
                defaultValue=""
                options={bodyTypes}
                disabled={!bodyTypes.length}
                searchable
                containerClassName={styles.inputContainerFullWidth}
              />
            </Grid>
            <Grid item>
              <FormDropdown
                label={translations.SUBTYPE}
                qaIdPrefix={`${qaIdPrefix}-subtype-dropdown`}
                name="mainTypeDetailId"
                defaultValue=""
                options={mainTypeDetails}
                disabled={!mainTypeDetails.length}
                searchable
                containerClassName={styles.inputContainerFullWidth}
              />
            </Grid>
            <Grid item>
              <FormDropdown
                label={translations.MODEL}
                qaIdPrefix={`${qaIdPrefix}-model-dropdown`}
                name="ecode"
                defaultValue=""
                options={mainTypeSubs}
                disabled={!mainTypeSubs.length}
                searchable
                containerClassName={styles.inputContainerFullWidth}
              />
            </Grid>
          </Grid>
        </Card>
      </LayoutContainer>
      {loading ? (
        <NavigationFooterPlaceholder />
      ) : (
        <NavigationFooter
          backButtonLink={appRoutes.carInfo(true)}
          rightAreaContent={
            <Button
              type={ButtonTypes.button}
              theme={ButtonThemes.orange}
              extraClass={stylesNavigationFooter.nextButton}
              qaId={`${qaIdPrefix}-footer-next-link`}
              isActivated={false}
              onClick={handleSubmit(handleEcodeSubmit)}
              isDisabled={!ecode || !isValid || isEcodeUpdateLoading}
              {...ecodeSubmitProps()}
            >
              {translations.NEXT}
              {isEcodeUpdateLoading && (
                <Loader extraClass={stylesNavigationFooter.nextButtonLoader} />
              )}
            </Button>
          }
        />
      )}
      <RouteLeavingGuard
        when={isDirty && !isBeforeEcodeSubmit}
        title={translations.UNSAVED_CHANGES}
      >
        <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
      </RouteLeavingGuard>
    </FormProvider>
  );
};

const ManualFlowWithTransaction = withTransaction(
  'ManualFlow',
  'component',
)(ManualFlow);

export { ManualFlowWithTransaction as ManualFlow };
