import * as React from 'react';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Typography } from 'shared/components/Typography';
import styles from './index.module.scss';

type Props = {
  loading: boolean;
  currentPage: number;
  loadNextPage: () => void;
};

const ListFooter: React.FC<Props> = ({
  loading,
  currentPage,
  loadNextPage,
}) => {
  const { translations } = useTranslation();
  const isLoading = loading && currentPage > 0;

  return (
    <div className={isLoading ? styles.loading : styles.loadMore}>
      <Typography
        variant={isLoading ? 'labelXS' : 'linkSmallSky'}
        onClick={isLoading ? () => {} : loadNextPage}
      >
        {isLoading
          ? translations.LOADING_BRANCHES
          : translations.LOAD_MORE_BRANCHES}
      </Typography>
    </div>
  );
};

export { ListFooter };
