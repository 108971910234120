import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import get from 'lodash/get';
import { isLocalEnv } from 'shared/utils/isProductionUrl';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { getToken, refreshToken } from 'auth/tokenManager';
import { GOOGLE_USER_ACCESS_KEY, USER_ACCESS_KEY } from 'auth/constants';
import { authManager } from 'auth/authManager';
import authResolvers from 'auth/resolvers';
import type { UserAccessToken } from 'auth/tokenManager';
import { cache, resetApolloCache } from './apolloCache';
import * as urlsConfig from './urls';
import { localStorageService } from './localStorageService';

const retryLink = new RetryLink({
  attempts: async (count, _, error) => {
    if (count >= 4 && isMobileEvaluatorApp()) {
      await resetApolloCache();
      window.location.href = '/';
    }

    if (error.statusCode === 401) {
      try {
        await refreshToken();

        return true;
      } catch (e) {
        console.log('error caused due to', e);
      }
    }

    return false;
  },
  delay: (count) => {
    // 1.. 3.. 9
    return [1000, 3 * 1000, 9 * 1000][count - 1] || 1000;
  },
});

const httpLink = new HttpLink({
  uri: urlsConfig.graphQLBaseUrl,
});

const authLink = setContext(async (_, { headers = {}, ...context }) => {
  let shouldGenerateToken;
  const tokenObj = localStorageService.getItem(
    USER_ACCESS_KEY,
  ) as UserAccessToken | null;
  let token: string | null = tokenObj?.jwt ?? null;
  if (isMobileEvaluatorApp()) {
    // todo use localStorageService once auth manager is fixed for adding object to localstorage
    token = window.localStorage.getItem(GOOGLE_USER_ACCESS_KEY) ?? '';
  } else {
    try {
      const viewer = await authManager(USER_ACCESS_KEY).getViewer();
      if (isLocalEnv() && viewer?.email !== process.env.REACT_APP_API_EMAIL) {
        shouldGenerateToken = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (!token || shouldGenerateToken) {
    try {
      const response = await getToken();
      token = get(response, 'data.response.accessToken');
    } catch (e) {
      token = null;
      console.log('error caused due to', e);
    }
  }

  const appHeaders = token ? { Authorization: `Bearer ${token}` } : {};
  return {
    headers: {
      ...headers,
      ...appHeaders,
    },
    ...context,
  };
});

const link = ApolloLink.from([retryLink, authLink, httpLink]);

export const apolloClient = new ApolloClient({
  connectToDevTools: isLocalEnv(),
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
  resolvers: {
    Query: {
      ...authResolvers.Query,
    },
    Mutation: {
      ...authResolvers.Mutation,
    },
  },
});
