import * as React from 'react';
import { MultiSelect } from '@auto1-ui/multi-select';

import { withFormItem } from 'shared/components/withFormItem';
import styles from './index.module.scss';

type MultiSelectValue = {
  value: string
  label: string
  icon?: string
};

const sortWithOptions = (initialOptions: MultiSelectValue[]) => (values: string[]) => {
  return values.sort((a: string, b: string) => {
    const aIndex = initialOptions.findIndex(
      (initialOption) => initialOption.value === a,
    );
    const bIndex = initialOptions.findIndex(
      (initialOption) => initialOption.value === b,
    );

    return aIndex - bIndex;
  });
};

type Props = {
  transform?: (value: string[]) => string;
  onValidateSelection?: (value: string | string[] | undefined) => string[];
  value?: string | string[];
  placeholder?: string;
  options: MultiSelectValue[];
  onChange: (value: string[]) => void;
  qaIdPrefix: string,
}

const Select: React.FC<Props> = ({
  value,
  transform,
  onValidateSelection,
  placeholder = '',
  options,
  onChange,
  qaIdPrefix,
  ...props
}) => {
  const sortByInitialOptions = React.useMemo(
    () => sortWithOptions(options),
    [options],
  );
  const transformedValue = onValidateSelection ? onValidateSelection(value) : [];
  let label: string = '';
  label =
    Array.isArray(transformedValue) && transform
      ? transform(transformedValue)
      : transformedValue.join(' ,');

  return (
    <MultiSelect
      placeholder={placeholder}
      label={label}
      options={options}
      qaId={qaIdPrefix}
      {...props}
      value={transformedValue}
      onChange={(values) => {
        onChange(sortByInitialOptions(onValidateSelection ? onValidateSelection(values) : []));
      }}
    />
  );
};

const EquipmentMultiSelect = withFormItem(Select, {
  classNames: {
    error: styles.error,
    containerClassName: styles.container,
  },
});

export { EquipmentMultiSelect };
