import * as React from 'react';
import styles from './index.module.scss';

const RearRightSide: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="matrix(-1 0 0 1 175 0)">
          <g
            className={styles.background}
            strokeDasharray="4"
            strokeWidth="2"
            mask="url(#prefix__b)"
          >
            <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
          </g>
        </g>
        <g>
          <path
            className={styles.stroke}
            strokeWidth="1.5"
            d="M30.445 59.88c-.917.195-1.678.951-2.316 2.059-.752 1.307-1.314 3.106-1.66 5.257-.677 4.183-.528 9.669.72 15.532 1.379 6.479 2.99 12.02 4.984 15.6.828 1.488 1.715 2.628 2.694 3.314.816.572 1.695.807 2.648.619 1.532-.303 2.766-1.56 3.692-3.424 1.015-2.043 1.65-4.817 1.828-8.063-.117-.32-.178-.608-.192-.866-4.481-14.855-8.516-24.884-12.398-30.028z"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
          <path
            className={styles.stroke}
            strokeWidth="1.5"
            d="M8.913 26.664c2.855-8.208 7.428-14.568 13.719-19.08 9.436-6.769 51.72-11.97 107.255 0 1.233.719 1.912 1.658 2.037 2.818l-.986 1.514 16.27 20.096c4.156 4.886 6.547 8.188 7.172 9.904.937 2.574-2.825 14.973-1.865 18.153.961 3.18 2.769 5.735 1.865 7.865-.603 1.42-1.616 3.83-3.039 7.231v2.557c-7.141 6.807-20.61 14.51-40.405 23.11 0 0-20.975 7.132-23.98 7.132H77.82c-18.653-6.779-29.074-11.309-31.26-13.592-2.187-2.282-3.278-3.92-3.273-4.916-6.674-22.183-12.402-33.745-17.184-34.687-3.471 0-1.593 11.042-1.593 12.502v3.778L7.402 51.81c-2.083-9.458-3.886-13.21-5.41-11.252-2.045-4.603-.477-8.922 1.04-10.237 1.01-.877 2.971-2.096 5.88-3.657z"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
          <path
            className={styles.stroke}
            strokeWidth="1.5"
            d="M22.4 8.042c12.767-3.637 22.81-3.637 30.126 0 4 3.04 9.016 13.078 12.656 25.842 0 2.472-1.611 3.692-2.926 3.692-1.314 0-35.507-11.358-53.463-10.712"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
          <path
            className={(styles.stroke, styles.fill)}
            strokeWidth="1.5"
            d="M8.867 25.277s.077-2.965-1.063-3.228c-1.14-.263-6.318-.159-6.59 1.206-.272 1.364-.524 4.147 1.115 4.367 1.64.22 7.962.253 8.283 0 .322-.252.117-2.345-1.745-2.345z"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
          <path
            className={styles.stroke}
            strokeWidth="1.5"
            d="M107.542 50.81c2.863 2.061 3.334 5.559 3.334 6.892 0 1.333-1.985 4.017-3.334 5.295-1.35 1.279-16.586 7.99-24.072 6.669-7.486-1.321-16.3-9.261-20.12-16.03-.994-2.826 4.872-6.284 6.107-6.284h12.299c4.034 0 22.922 1.397 25.786 3.458z"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
          <path
            className={styles.stroke}
            strokeWidth="2"
            d="M152.682 40.509c-1.687 1.337-2.53 2.256-2.53 2.757 0 .75-1.36 3.648-1.36 4.6 0 .95.11 4.374 1.36 4.374.833 0 2.009-.326 3.529-.978"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
          <path
            className={styles.stroke}
            strokeWidth="1.5"
            d="M121.679 5.872C105.6 2.922 73.546 1.292 69.07 8.428c.823 5.111 3.593 19.225 8.048 26.326 0 0 13.274 3.73 21.221 3.73 7.948 0 34.568 1.076 48.51-5.272M141.592 48.906c-.054-.034-.121-.048-.189-.033L117.538 54.1c-.064.014-.117.051-.152.102-.034.05-.051.112-.042.177l1.214 8.572c.022.05.06.091.107.117.056.031.124.04.19.022l23.23-6.639c.058-.016.105-.052.137-.098.032-.046.049-.103.044-.162l-.674-7.285z"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
          <path
            className={styles.stroke}
            strokeWidth="1.5"
            d="M81.71 35.62c2.286 6.844 3.67 10.616 4.15 11.318.481.701-.902-3.071-4.15-11.318zM93.376 68.79c1.218 8.402 2.68 13.061 4.384 13.978 2.557 1.376 6.199.559 7.566 0 1.367-.558 31.772-8.803 40.878-17.504 1.03-.993 2.604-2.73 3.552-4.23.673-1.067 1.649-4.324 2.926-9.772"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
          <path
            className={styles.stroke}
            strokeWidth="1.5"
            d="M2.496 40.763c-.212.352-.363.861-.473 1.5-.244 1.43-.249 3.454.01 5.916.285 2.716.638 5.048 1.252 6.62.226.578.482 1.046.8 1.361.218.215.465.348.76.361.349.016.652-.164.908-.45.363-.405.636-1.01.815-1.752.367-1.515.326-3.574-.3-5.863-.729-2.66-1.465-4.908-2.206-6.404-.297-.6-.587-1.07-.884-1.372-.114-.117-.218-.248-.682.083z"
            transform="matrix(-1 0 0 1 165.278 11.731)"
          />
        </g>
      </g>
    </svg>
  );
};

export { RearRightSide };
