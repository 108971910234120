import * as React from 'react';

import get from 'lodash/get';
import cn from 'classnames';

import { Uploader } from '@auto1-ui/uploader';
import { Controller, useFormContext } from 'react-hook-form';
import type { ControllerProps } from 'react-hook-form';
import { FormUploaderCarousel } from 'shared/components/FormUploaderCarousel';
import { useTranslation } from 'shared/hooks/useTranslation';
import loadingPicture from 'assets/icons/loading-picture.svg';
import { filterNull } from 'shared/utils/filterNull';

import { Typography } from '../Typography';
import styles from './index.module.scss';

type Props = {
  name: string;
  title?: string;
  containerClassName?: string;
  extraClassName?: string;
  errorClassName?: string;
  includeCarousel?: boolean;
  allowMultipleFiles?: boolean;
  uploadFileTypes?: string;
  children: React.ComponentProps<typeof Uploader>['children'];
  onSelectFile?: (name: string, files: File[]) => void;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  qaIdPrefix: string;
  inputQaId?: string;
  processSelectedFile?: (file: File) => Promise<File>;
} & Partial<ControllerProps>;

function isMimeType(type: string) {
  return type.includes('/');
}

function isMatchedMimeType(mimeType: string, file: File) {
  const [type, format] = mimeType.split('/');
  const [targetType, targetFormat] = file.type.split('/');

  if (!file.type) {
    return file.name.toLowerCase().endsWith(format.toLowerCase());
  }

  if (type !== targetType) {
    return false;
  }

  if (format === '*') {
    return true;
  }

  return format === targetFormat;
}

function filterFilesByType(types: string) {
  const splittedTypes = types.split(',').map((type) => type.trim());

  return (file: File) =>
    splittedTypes.some((type) => {
      if (isMimeType(type)) {
        return isMatchedMimeType(type, file);
      }

      return file.name.endsWith(type);
    });
}

const FormUploader = ({
  name,
  title = '',
  containerClassName = '',
  extraClassName = '',
  errorClassName = '',
  allowMultipleFiles = true,
  includeCarousel = true,
  uploadFileTypes = 'image/*',
  children,
  onSelectFile = () => { },
  inputRef,
  qaIdPrefix,
  inputQaId = 'form-uploader-input',
  processSelectedFile = (file: File) => Promise.resolve(file),
  ...props
}: Props) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const [customError, setCustomError] = React.useState('');
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { translations } = useTranslation();
  const [isDisabled, setIsDisabled] = React.useState(false);
  const handleChange = async (files: FileList) => {
    if (files.length === 0) {
      return;
    }

    setIsDisabled(true);
    const filteredFiles = [...files].filter(filterFilesByType(uploadFileTypes));

    setCustomError('');
    if (files.length > 1 && allowMultipleFiles === false) {
      return setCustomError(translations.ONLY_ONE_FILE_IS_ALLOWED_HERE);
    }
    if (files.length > filteredFiles.length) {
      if (uploadFileTypes === 'application/pdf') {
        setCustomError(translations.ONLY_PDF_FILE_SUPPORTED);
      } else {
        setCustomError(translations.SOME_FILES_MAY_NOT_BE_INCLUDED);
      }
    }

    const placeholderFiles = filteredFiles.map((file) => ({
      absoluteUrl: loadingPicture,
      id: file.name,
    }));

    let value = getValues(name) ?? [];
    value = Array.isArray(value) ? value : [value];
    let valuesWithPlaceholders: Array<
      File | { absoluteUrl: string; id: string }
    > = [];
    if (allowMultipleFiles) {
      valuesWithPlaceholders = [...value, ...placeholderFiles];
      setValue(name, valuesWithPlaceholders, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      const [file] = placeholderFiles;
      if (file) {
        valuesWithPlaceholders = [file];
        setValue(name, valuesWithPlaceholders, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }

    const convertedFilesMap = (
      await Promise.all(
        filteredFiles.map(async (file) => {
          try {
            return await processSelectedFile(file);
          } catch (e) {
            return null;
          }
        }),
      )
    ).filter(filterNull);

    setValue(name, [...value, ...convertedFilesMap]);

    if (filteredFiles.length > convertedFilesMap.length) {
      setCustomError(translations.SOME_FILES_COULD_NOT_BE_PROCESSED);
    }

    if (allowMultipleFiles) {
      if (convertedFilesMap.length) {
        if (onSelectFile) {
          onSelectFile(name, convertedFilesMap);
        }
      }
    } else {
      const [file] = convertedFilesMap;
      if (file) {
        if (onSelectFile) {
          onSelectFile(name, [file]);
        }
      }
    }

    setIsDisabled(false);
    return undefined;
  };

  React.useEffect(() => {
    if (inputRef) {
      inputRef.current =
        containerRef.current?.querySelector('input[type="file"]') ?? null;

      return () => {
        inputRef.current = null;
      };
    }

    return () => { };
  }, [containerRef, inputRef]);

  return (
    <div ref={containerRef} className={containerClassName}>
      <Controller
        render={({ field: { ref, ...renderProps } }) => (
          <Uploader
            qaId={qaIdPrefix}
            inputQaId={inputQaId}
            allowMultipleFiles={allowMultipleFiles}
            uploadFileTypes={uploadFileTypes}
            onFileSelect={handleChange}
            extraClassName={cn(extraClassName, {
              [styles.uploaderWithError]: Boolean(error || customError),
            })}
            disabled={isDisabled}
            {...renderProps}
          >
            {children}
          </Uploader>
        )}
        control={control}
        name={name}
        shouldUnregister
        {...props}
      />
      <Typography
        tag="div"
        additonalClassNames={cn(errorClassName, styles.message)}
        variant="textXSRed"
        data-qa-id={`${qaIdPrefix}-error-message`}
      >
        {error?.message || customError || ''}
      </Typography>
      {title && (
        <div className={styles.uploaderTitle}>
          <Typography
            tag="p"
            variant="textSmall"
            additonalClassNames={styles.uploaderTitle}
            data-qa-id={`${qaIdPrefix}-title`}
          >
            {title}
          </Typography>
        </div>
      )}
      {includeCarousel && <FormUploaderCarousel name={name} />}
    </div>
  );
};

export { FormUploader };
