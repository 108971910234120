import type { Translations } from 'shared/utils/translations';

export const booleanOptions = (translations: Translations) =>
  [
    {
      label: translations.YES,
      value: true,
    },
    {
      label: translations.NO,
      value: false,
    },
  ] as const;

export const batteryHealthOptions = [
  {
    label: '0-25%',
    value: 'HEALTH_0_25',
  },
  {
    label: '25-50%',
    value: 'HEALTH_25_50',
  },
  {
    label: '50-75%',
    value: 'HEALTH_50_75',
  },
  {
    label: '75-100%',
    value: 'HEALTH_75_100',
  },
] as const;
