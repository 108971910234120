import * as React from 'react';
import { User } from '@auto1-ui/icons';
import { Button } from '@auto1-ui/button';
import { resetApolloCache } from 'config/apolloCache';
import { useHistory, Redirect } from 'react-router-dom';
import { useLayoutViewerQuery, useLogoutMutation } from 'gql/graphql';
import { Typography } from '../Typography';
import Auto1Logo from './auto1-logo.png';
import styles from './index.module.scss';
import { appRoutes } from '../../../appRoutes';

const MobileEvaluatorsHeader: React.FC = () => {
  const history = useHistory();
  const { data: userData } = useLayoutViewerQuery();

  const [logout, { ...logoutOptions }] = useLogoutMutation({
    onCompleted: (data) => {
      if (data?.logout?.success) {
        resetApolloCache();
      }
    },
  });

  if (logoutOptions?.data?.logout?.success) {
    return <Redirect to={appRoutes.login()} />;
  }
  const userName = userData?.viewer?.userName;

  const redirectToHome = () => {
    return history.push(appRoutes.login());
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.leftItems} onClick={redirectToHome}>
          <img src={Auto1Logo} alt="auto1-logo" width="120" />
        </div>
        <div className={styles.rightItems}>
          {userName && (
            <>
              <div className={styles.userName}>
                <User className={styles.userIcon} />
                <Typography variant="textSmall">{userName}</Typography>
              </div>
              <Button theme="link" onClick={logout}>
                Log out
              </Button>
            </>
          )}
          {/* todo this will be implemented post mvp */}
          {/* <div className={styles.language}> */}
          {/*  <img src={EnglishFlag} alt="EN" className={styles.flag} /> */}
          {/*  EN */}
          {/* </div> */}
        </div>
      </div>
    </nav>
  );
};

export { MobileEvaluatorsHeader };
