import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import omitBy from 'lodash/omitBy';
import { useFormContext } from 'react-hook-form';

import { Accordion } from '@auto1-ui/accordion';
import { Checked } from '@auto1-ui/icons';

import { FormDropdown } from 'shared/components/FormDropdown';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import { FormDotInput } from 'shared/components/FormDotInput';
import { Typography } from 'shared/components/Typography';
import { Grid } from 'shared/components/Grid';
import { useTranslation } from 'shared/hooks/useTranslation';
import {
  brakesConditionValues,
  wheelPositionMapper,
  tireTypesValues,
  rimTypeValues,
  rimTypeEnum,
} from 'pages/TiresBrakes/tires-brakes-data';
import { VehicleExteriorWheelDetailInput } from 'pages/TiresBrakes/tires-brakes-schema';
import type { Form, PrimaryWheel } from '../types';

import styles from './styles.module.scss';

type FieldName = `${'primaryWheels' | 'additionalWheels'}.${number}`;
type Props = {
  qaIdPrefix: string;
  fieldName: FieldName;
};

const getProfileDepths = (isPrimary: boolean) => {
  const profileDepths = Array(9)
    .fill(null)
    .map((_, index) => ({
      label: `${index + 1}mm`,
      value: `${index + 1}`,
    }));

  if (!isPrimary) {
    profileDepths.unshift({
      label: 'N/A',
      value: '0',
    });
  }
  return profileDepths;
};

const convertMapToDropdownValues = (map: Record<string, string>) =>
  (Object.entries(map) as ReadonlyArray<[string, string]>).reduce(
    (acc, [value, label]) => [
      ...acc,
      {
        label,
        value,
      },
    ],
    [] as Array<{ label: string; value: string }>,
  );

const WheelDetails = React.memo(({ fieldName, qaIdPrefix }: Props) => {
  const {
    setValue,
    watch,
    register,
    unregister,
    trigger,
    formState: { isSubmitted },
  } = useFormContext<Form>();
  const field =
    watch < `${'primaryWheels' | 'additionalWheels'}.${number}` > (fieldName);
  const [isOpen, setIsOpen] = React.useState(true);
  const [isValid, setIsValid] = React.useState(false);
  const toggleAccordion = () => setIsOpen(!isOpen);
  const { translations } = useTranslation();

  const dotNumberField = `${fieldName}.dotNumber` as const;
  const areaField = `${fieldName}.area` as const;
  const isPrimaryField = `${fieldName}.isPrimary` as const;
  const profileDepthField = `${fieldName}.profileDepth` as const;
  const brakesConditionField = `${fieldName}.brakesCondition` as const;
  const tireTypeField = `${fieldName}.tireType` as const;
  const rimTypeField = `${fieldName}.rimType` as const;
  const notAvailableField = `${fieldName}.notAvailable` as const;

  const {
    area,
    dotNumber,
    notAvailable,
    profileDepth,
    rimType,
    tireType,
    isPrimary,
  } = field;
  const brakesCondition =
    'brakesCondition' in field ? field.brakesCondition : null;

  React.useEffect(() => {
    // omit fields which are already declared
    const formValues = omitBy(field, (_, key) =>
      ['area', 'isPrimary', 'notAvailable', 'dotNumber'].includes(key),
    );

    const isDotNumberAvailable =
      !isPrimary || (notAvailable === false && dotNumber) || notAvailable;

    const values = Object.values(formValues);
    const allFilledIn =
      values.length && isDotNumberAvailable && values.every((value) => value);

    setIsOpen(!allFilledIn);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let callback = setIsValid;

    VehicleExteriorWheelDetailInput(translations)
      .isValid({
        brakesCondition,
        dotNumber,
        notAvailable,
        profileDepth,
        rimType,
        tireType,
        area,
        isPrimary,
      })
      .then((...args) => {
        callback(...args);
      });

    return () => {
      callback = () => { };
    };
  }, [
    area,
    brakesCondition,
    dotNumber,
    notAvailable,
    profileDepth,
    rimType,
    tireType,
    isPrimary,
    translations,
  ]);

  React.useEffect(() => {
    register(areaField);
    register(isPrimaryField);

    setValue(areaField, area);
    setValue(isPrimaryField, isPrimary);

    return () => {
      unregister(areaField);
      unregister(isPrimaryField);
    };
  }, [area, isPrimary, register, setValue, unregister]);

  React.useEffect(() => {
    if (!notAvailable && dotNumber) {
      setValue(dotNumberField, dotNumber);
    } else {
      setValue(dotNumberField, '');
    }
  }, [dotNumber, notAvailable, setValue]);

  const profileDepths = React.useMemo(
    () => getProfileDepths(isPrimary),
    [isPrimary],
  );

  const rimTypes = React.useMemo(() => {
    const values = convertMapToDropdownValues(rimTypeValues(translations));
    if (!isPrimary) {
      return values;
    }

    return values.filter(({ value }) => value !== rimTypeEnum.WITHOUT_RIMS);
  }, [isPrimary, translations]);

  return (
    <Accordion
      toggle={toggleAccordion}
      qaId={`${qaIdPrefix}-accordion`}
      isOpen={isOpen}
      title={
        <>
          {isValid && <Checked className={styles.titleIcon} />}
          <Typography
            variant="textSmall"
            tag="div"
            className={styles.title}
            data-qa-id={`${qaIdPrefix}-${kebabCase(
              wheelPositionMapper[area as keyof typeof wheelPositionMapper],
            )}-title`}
          >
            {
              translations[
              wheelPositionMapper[area as keyof typeof wheelPositionMapper]
              ]
            }
          </Typography>
        </>
      }
    >
      <Grid columns={2} gutter={[1, 0]} className={styles.grid}>
        <Grid item qaIdPrefix="profile-depth">
          <FormDropdown
            label={translations.PROFILE_DEPTH}
            name={profileDepthField}
            placeholder={translations.PLEASE_SELECT}
            qaIdPrefix="profile-depth-dropdown"
            options={profileDepths}
            containerClassName={styles.inputContainerFullWidth}
            defaultValue={field.profileDepth}
            searchable
          />
        </Grid>
        {isPrimary && (
          <Grid item qaIdPrefix="brakes-condition">
            <FormDropdown
              label={translations.BRAKES_CONDITION}
              name={brakesConditionField}
              placeholder={translations.PLEASE_SELECT}
              qaIdPrefix="brakes-condition-dropdown"
              options={convertMapToDropdownValues(
                brakesConditionValues(translations),
              )}
              containerClassName={styles.inputContainerFullWidth}
              defaultValue={(field as PrimaryWheel).brakesCondition}
              searchable
            />
          </Grid>
        )}
        <Grid item qaIdPrefix="tire-type">
          <FormDropdown
            label={translations.TYRE_TYPE}
            name={tireTypeField}
            placeholder={translations.PLEASE_SELECT}
            qaIdPrefix="tire-type-dropdown"
            options={convertMapToDropdownValues(tireTypesValues(translations))}
            containerClassName={styles.inputContainerFullWidth}
            defaultValue={field.tireType}
            searchable
          />
        </Grid>
        <Grid item qaIdPrefix="rim-type">
          <FormDropdown
            label={translations.RIM_TYPE}
            qaIdPrefix="rim-type-dropdown"
            name={rimTypeField}
            placeholder={translations.PLEASE_SELECT}
            options={rimTypes}
            containerClassName={styles.inputContainerFullWidth}
            defaultValue={field.rimType}
            searchable
          />
        </Grid>
        <Grid item qaIdPrefix="dot">
          <FormDotInput
            label={translations.DOT}
            qaIdPrefix="dot-input"
            disabled={typeof notAvailable === 'boolean' && notAvailable}
            name={dotNumberField}
            className={styles.textInput}
            containerClassName={styles.inputContainerFullWidth}
            defaultValue={field.dotNumber ?? ''}
          />

          <FormCheckbox
            name={notAvailableField}
            qaIdPrefix="not-available"
            uiLabel={translations.NOT_AVALIABLE}
            containerClassName={styles.checkbox}
            defaultValue={field.notAvailable}
            afterChange={() => {
              if (isSubmitted) {
                trigger(dotNumberField);
              }
            }}
          />
        </Grid>
      </Grid>
    </Accordion>
  );
});

WheelDetails.displayName = 'WheelDetails';

export { WheelDetails };
