import * as React from 'react';

import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';

import { ThicknessItem } from '../components/ThicknessItem';

import { paintThicknessItems } from './paintThicknessData';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
};

const PaintThickness: React.FC<Props> = ({ qaIdPrefix }) => {
  const { translations } = useTranslation();
  const items = React.useMemo(
    () => paintThicknessItems(translations),
    [translations],
  );
  return (
    <Card
      externalStyle={styles.container}
      showBottomBorder
      id="thickness"
      qaIdPrefix={`${qaIdPrefix}-paint-thickness-container`}
    >
      <Typography
        data-qa-id={`${qaIdPrefix}-paint-thickness-title`}
        variant="titleXL"
        tag="div"
        additonalClassNames={styles.title}
      >
        {translations.PAINT_THICKNESS_HEADER}
      </Typography>

      <Typography
        tag="div"
        data-qa-id={`${qaIdPrefix}-paint-thickness-subtitle`}
      >
        {translations.PAINT_THICKNESS_DESCRIPTION}
      </Typography>
      <div
        className={styles.grid}
        data-qa-id={`${qaIdPrefix}-paint-thickness-item-container`}
      >
        {items.map(({ label, name }) => (
          <ThicknessItem
            key={label}
            label={label}
            name={name}
            qaIdPrefix={`${qaIdPrefix}-paint-thickness-item`}
          />
        ))}
      </div>
    </Card>
  );
};

export { PaintThickness };
