import React from 'react';
import clsx from 'classnames';
import styles from './index.module.scss';

type Props = {
  tag?: string | React.ElementType;
  variant?: typeof typographyVariants[number];
  children: React.ReactNode | string;
  additonalClassNames?: string;
  'data-qa-id'?: string;
  [key: string]: unknown;
};

const Typography = React.forwardRef<HTMLElement, Props>(
  (
    {
      tag = 'span',
      variant = 'textSmall',
      additonalClassNames = '',
      children,
      ...props
    },
    ref,
  ) => {
    const TypographyTag = tag;
    return (
      <TypographyTag
        className={clsx(styles[variant], additonalClassNames)}
        data-qa-id={props['data-qa-id'] ?? 'typography'}
        ref={ref}
        {...props}
      >
        {children}
      </TypographyTag>
    );
  },
);

Typography.displayName = 'Typography';

const typographyVariants = Object.freeze([
  'titleXL',
  'titleLBlue',
  'titleL',
  'titleM',
  'titleXS',
  'textSmallBold',
  'textSmall',
  'textXSbold',
  'textXS',
  'textXXS',
  'textXSRedBold',
  'textXSRed',
  'linkSmall',
  'linkSmallSky',
  'linkLarge',
  'labelXS',
  'labelXSorange',
  'labelXSgreen',
  'labelXSblue',
] as const);

export { Typography, typographyVariants };
