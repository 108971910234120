import * as React from 'react';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { PaintThicknessPlaceholder } from './PaintThickness/Placeholder';
import { HighlightsPlaceholder } from './Highlights/Placeholder';

const Placeholder: React.FC = () => (
  <LayoutContainer>
    <PaintThicknessPlaceholder />
    <HighlightsPlaceholder />
  </LayoutContainer>
);

export { Placeholder };
