import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { apm } from '@elastic/apm-rum';
import { ButtonThemes } from '@auto1-ui/button';
import type { VehicleImageArea, VehicleImagePart } from 'gql/graphql';
import { useMediaUploader } from 'shared/utils/useMediaUploader';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { useUploadPool } from 'shared/utils/uploadPool';
import { VEHICLE_MEDIA_AREAS } from '../../constants';
import { getMimeType } from '../../helpers';

type Args = {
  area: VehicleImageArea;
  name: string;
  sourceType: 'image';
  mediaUrl: string;
  mediaData: {
    stockNumber: string;
    part: VehicleImagePart;
  };
  selectedImageIndex: number;
};

const useCarGeneralPictureItem = ({
  area,
  name,
  sourceType,
  mediaUrl,
  mediaData,
  selectedImageIndex,
}: Args) => {
  const { inspectionId, stockNumber } = useInspection();
  const ref = React.useRef<HTMLDivElement>(null);
  const { setValue, getValues } = useFormContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isUploadFromReplace, setUploadFromReplace] = React.useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = React.useState(false);
  const { translations } = useTranslation();
  const { uploadPool } = useUploadPool();

  const handleDeleteConfirmation = () => {
    const files = getValues(VEHICLE_MEDIA_AREAS.GENERAL);
    setValue(
      name,
      [
        ...files.slice(0, selectedImageIndex),
        ...files.slice(selectedImageIndex + 1),
      ],
      { shouldValidate: true, shouldDirty: true },
    );

    setIsOpen(false);
    setIsOpenConfirmation(false);
  };

  const handleCancelConfirmation = () => {
    setIsOpenConfirmation(false);
    setIsOpen(true);
  };

  const handleDelete = () => {
    setIsOpenConfirmation(true);
  };

  const handleReplace = () => {
    setUploadFromReplace(true);
    if (!ref.current) return;
    const inputs = ref.current.getElementsByTagName('input');
    if (!inputs.length) return;
    const [input] = inputs;
    input.click();
  };

  const handleTrackingEvents = React.useCallback(
    (file: File) => {
      window.tracking.eva[
        isUploadFromReplace ? 'replacePhoto' : 'uploadPhoto'
      ]?.({
        area,
        size: file.size / 1024 / 1024,
        inspectionId,
        stockNumber,
      });
    },
    [inspectionId, isUploadFromReplace, area, sourceType, stockNumber],
  );

  const { uploadMedia } = useMediaUploader(mediaUrl);

  const handleUploadMedia = React.useCallback(
    async (file) => {
      if (!(file instanceof File)) return;

      handleTrackingEvents(file);
      setIsOpen(false);
      try {
        const {
          data: {
            wkda: { url },
          },
        } = await uploadPool.add(() =>
          uploadMedia({
            file,
            source: 11,
            ...mediaData,
          }),
        );
        const files = getValues(VEHICLE_MEDIA_AREAS.GENERAL);
        setValue(
          name,
          [
            ...files.slice(0, selectedImageIndex),
            {
              absoluteUrl: url,
              area,
              mimeType: getMimeType(url),
            },
            ...files.slice(selectedImageIndex + 1),
          ],
          { shouldValidate: true, shouldDirty: true },
        );
      } catch (e) {
        apm.captureError(e as Error);
        console.info(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [area, selectedImageIndex, mediaData, name, setValue, handleTrackingEvents],
  );

  const buttons = [
    {
      label: translations.REPLACE,
      theme: ButtonThemes.outlined,
      alignment: 'left',
      onClick: handleReplace,
    },
    {
      label: translations.DELETE,
      theme: ButtonThemes.outlined,
      alignment: 'right',
      onClick: handleDelete,
    },
  ] as const;

  return {
    ref,
    isOpen,
    buttons,
    setIsOpen,
    isOpenConfirmation,
    handleDeleteConfirmation,
    handleCancelConfirmation,
    handleUploadMedia,
  };
};

export { useCarGeneralPictureItem };
