import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  qaIdPrefix?: string;
};

const EditButton: React.FC<Props> = ({
  className,
  qaIdPrefix = 'area-edit-button',
  ...props
}) => (
  <button
    className={cn(styles.areaButtonButton, className)}
    data-qa-id={qaIdPrefix}
    {...props}
  >
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.294922 10.59L4.87492 6L0.294922 1.41L1.70492 0L7.70492 6L1.70492 12L0.294922 10.59Z"
        fill="#358CCB"
      />
    </svg>
  </button>
);

export { EditButton };
