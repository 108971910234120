import * as React from 'react';
import { Dialog } from '@auto1-ui/dialog';
import { Button, ButtonThemes, ButtonTypes } from '@auto1-ui/button';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';

type Props = {
  onClickDelete: () => void;
  onClickKeep: () => void;
  qaIdPrefix?: string;
};

const DeleteModal: React.FC<Props> = ({
  onClickDelete,
  onClickKeep,
  qaIdPrefix = 'delete-modal',
}) => {
  const { translations } = useTranslation();

  return (
    <Dialog
      isOpen
      isCloseIconVisible
      contentExtraClassName={styles.modalContent}
      handleClose={onClickKeep}
    >
      <Typography
        tag="div"
        variant="titleXL"
        additonalClassNames={styles.title}
        data-qa-id={`${qaIdPrefix}-title`}
      >
        {translations.ACCIDENT_DELETE_MODAL_TITLE}
      </Typography>
      <Typography
        tag="p"
        additonalClassNames={styles.text}
        data-qa-id={`${qaIdPrefix}-subtitle`}
      >
        {translations.ACCIDENT_DELETE_MODAL_TEXT}
      </Typography>
      <div className={styles.buttonWrapper}>
        <Button
          theme={ButtonThemes.link}
          type={ButtonTypes.button}
          onClick={onClickDelete}
          qaId={`${qaIdPrefix}-secondary-button`}
        >
          {translations.ACCIDENT_DELETE_MODAL_DELETE_BUTTON}
        </Button>
        <Button
          theme={ButtonThemes.orange}
          type={ButtonTypes.button}
          onClick={onClickKeep}
          qaId={`${qaIdPrefix}-primary-button`}
        >
          {translations.ACCIDENT_DELETE_MODAL_KEEP_BUTTON}
        </Button>
      </div>
    </Dialog>
  );
};

export { DeleteModal };
