import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LazyQueryHookOptions } from '@apollo/client';
import { CheckBeforeSubmitVinV2Document } from 'gql/graphql';
import type {
  CheckBeforeSubmitVinV2QueryVariables,
  CheckBeforeSubmitVinV2Query,
} from 'gql/graphql';
import { useInspection } from 'shared/components/InspectionContext';
import { EmptyVINModal } from 'shared/components/EmptyVINModal';
import { DATTimeoutModal } from 'shared/components/DATTimeoutModal';
import { useTrackManualFlow } from 'shared/components/CarVinInfo/hooks/useTrackManualFlow';
import { appRoutes } from 'appRoutes';
import { useLazyQueryPromise } from 'shared/hooks/useLazyQueryPromise';
import { VinStatus } from 'shared/utils/graphqlEnums';

type CheckVin = {
  branchId: number;
  vin: string;
  onVinFound: () => void;
  onError: (error: Error) => void;
  onBeforeRedirectToManualFlow?: () => void;
  licensePlate?: string;
};

function useCheckBeforeSubmitVinV2LazyQuery(
  options?: LazyQueryHookOptions<
    CheckBeforeSubmitVinV2Query,
    CheckBeforeSubmitVinV2QueryVariables
  >,
) {
  return useLazyQueryPromise<
    CheckBeforeSubmitVinV2Query,
    CheckBeforeSubmitVinV2QueryVariables
  >(CheckBeforeSubmitVinV2Document, options);
}

const EVALUATION_TYPE = 'MSI_APP';
const REFETCH_DELAY = 10;
const MAX_REFETCH_COUNT = 2;

const useCheckVin = () => {
  const history = useHistory();
  const isManualFlowPage = !!useRouteMatch(
    appRoutes.manualFlow(undefined, false),
  );
  const { trackClickManualFlow } = useTrackManualFlow();
  const { inspectionId } = useInspection();
  const [modal, setModal] = React.useState<React.ReactElement | null>(null);
  const [loading, setLoading] = React.useState(false);
  const requestCount = React.useRef(0);
  const [checkBeforeSubmitVinV2] = useCheckBeforeSubmitVinV2LazyQuery({
    fetchPolicy: 'no-cache',
  });

  const checkVin = async ({
    onVinFound,
    onError,
    onBeforeRedirectToManualFlow,
    ...variables
  }: CheckVin) => {
    setLoading(true);
    let result;
    try {
      result = await checkBeforeSubmitVinV2({
        variables: {
          ...variables,
          evaluationType: EVALUATION_TYPE,
        },
      }).then(({ checkBeforeSubmitVinV2 }) => checkBeforeSubmitVinV2);
    } catch (error) {
      onError(error as Error);
      setLoading(false);
      return;
    }

    const redirectFn = history[isManualFlowPage ? 'replace' : 'push'];
    const manualFlowRoute = inspectionId
      ? appRoutes.manualFlow(`${inspectionId}`, true)
      : appRoutes.manualFlowWithBranch(`${variables.branchId}`, true);
    switch (result?.vinStatus) {
      case VinStatus.NoVehicleInfo:
        setLoading(false);
        setModal(
          <EmptyVINModal
            handleClose={() => {
              setModal(null);
            }}
            handleClickFillManually={() => {
              onBeforeRedirectToManualFlow?.();
              setTimeout(() => {
                redirectFn(manualFlowRoute, {
                  vin: variables.vin,
                  licensePlate: variables.licensePlate,
                });
              });
              setModal(null);
              trackClickManualFlow({
                vin: variables.vin,
              });
            }}
          />,
        );
        break;
      case VinStatus.VehicleInfoTimeout:
        requestCount.current += 1;
        if (requestCount.current <= MAX_REFETCH_COUNT) {
          setTimeout(() => {
            checkVin({
              ...variables,
              onVinFound,
              onError,
              onBeforeRedirectToManualFlow,
            });
          }, (result.retryAfter ?? REFETCH_DELAY) * 1000);
          return;
        }

        setLoading(false);
        setModal(
          <DATTimeoutModal
            handleClose={() => {
              setModal(null);
            }}
            handleClickFillManually={() => {
              onBeforeRedirectToManualFlow?.();
              setTimeout(() => {
                redirectFn(manualFlowRoute, {
                  vin: variables.vin,
                  licensePlate: variables.licensePlate,
                });
              });
              setModal(null);
              trackClickManualFlow({
                vin: variables.vin,
              });
            }}
          />,
        );
        break;
      default:
        onVinFound();
        setLoading(false);
        break;
    }
  };

  return { checkVin, loading, modal };
};

export { useCheckVin };
