const formatVinToUI = (vin: string) => {
  if (!vin) return '';
  return [
    vin.slice(0, 3),
    vin.slice(3, 8),
    vin.slice(8, 11),
    vin.slice(11, 17),
  ].join(' ');
};

const formatVinToAPI = (vin: string) => {
  const vinWithoutSpaces = vin && vin.replace(/\s/g, '');
  return vinWithoutSpaces.toUpperCase();
};

export { formatVinToUI, formatVinToAPI };
