import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useTrackManualFlow = () => {
  const location = useLocation();
  const history = useHistory();

  const trackClickManualFlow = React.useCallback(
    ({ vin, inspectionId, stockNumber }) => {
      window.tracking.eva.clickFillManually({
        page: 'carInfo',
        vin,
        inspectionId,
        stockNumber,
      });
    },
    [],
  );

  React.useEffect(() => {
    const isMenuClick = location.search.includes('ref=menuClick');
    const isPdfInspectionInfo = location.pathname.includes('pdf-inspection');
    window.tracking.eva[
      isPdfInspectionInfo ? 'openAuto1EVA' : 'openAuto1EVANew'
    ]?.({
      menuClick: isMenuClick ? 'yes' : 'no',
    });
    if (isMenuClick) {
      const searchParamsWithoutMenuRef = location.search
        .replace('?', '')
        .split('&')
        .filter((search) => search !== 'ref=menuClick')
        .join('&');
      history.replace({
        ...location,
        search: searchParamsWithoutMenuRef.length
          ? `?${searchParamsWithoutMenuRef}`
          : '',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    trackClickManualFlow,
  };
};

export { useTrackManualFlow };
