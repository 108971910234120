import * as React from 'react';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import styles from './styles.module.scss';

const Placeholder: React.FC = () => (
  <LayoutContainer>
    <Card
      paddingTop={32}
      paddingBottom={32}
      externalStyle={styles.card}
      data-qa-id="tires-and-brakes-page-installed-wheels-loader"
      id="installed-wheels"
    >
      <Typography variant="titleXL" additonalClassNames={styles.title}>
        <Skeleton width="171px" height="100%" />
      </Typography>
      <Skeleton width="100%" height="59px" style={{ marginBottom: '25px' }} />
      <Skeleton
        width="160px"
        height="40px"
        style={{ marginTop: '4px', marginBottom: '24px' }}
      />
      <Skeleton width="100%" height="59px" style={{ marginBottom: '25px' }} />
      <Skeleton width="100%" height="59px" style={{ marginBottom: '25px' }} />
      <Skeleton width="100%" height="59px" style={{ marginBottom: '25px' }} />
    </Card>
  </LayoutContainer>
);

export { Placeholder };
