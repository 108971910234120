import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = React.HTMLProps<HTMLLabelElement>;

const Label: React.FC<Props> = ({ children, className, ...props }) => (
  <label className={cn(styles.label, className)} {...props}>
    {children}
  </label>
);

export { Label };
