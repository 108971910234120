import * as React from 'react';
import { CarInfoProvider } from 'shared/components/CarInfoContext';
import styles from './index.module.scss';
import { CarInformationTopBanner } from '../CarInformationTopBanner';

type Props = {
  sidebar: React.ReactNode;
};

const DividedLayout: React.FC<Props> = ({ children, sidebar }) => {
  return (
    <CarInfoProvider>
      {/* this component can be used for adding header, snackbar, common notifications or something which will be common to every screen with the app */}
      <div className={styles.container}>
        <div id="alert-container" className={styles.alertContainer}></div>
        <div className={styles.sidebar}>{sidebar}</div>
        <div className={styles.pages}>
          <CarInformationTopBanner />
          {children}
        </div>
      </div>
    </CarInfoProvider>
  );
};

export { DividedLayout };
