import * as React from 'react';
import cn from 'classnames';

import { useTranslation } from 'shared/hooks/useTranslation';

import styles from '../index.module.scss';

type Props = {
  showV2Label?: boolean;
  translationKey: string;
  withPrefix?: boolean;
};

const LabelWithSubtitle: React.FC<Props> = ({
  showV2Label = false,
  translationKey,
  withPrefix = false,
}) => {
  const { translate } = useTranslation();
  const getTranslationKey = React.useCallback(
    (key) => (withPrefix ? `a1-inspection-app-${key}` : key),
    [withPrefix],
  );
  const label = React.useMemo(() => {
    if (showV2Label) {
      const translationKeyTitleV2 = getTranslationKey(`${translationKey}.v2`);
      const translationKeySubTitleV2 = getTranslationKey(
        `${translationKey}.v2.subtitle`,
      );
      const translationTitleV2 = translate(translationKeyTitleV2);
      const translationSubtitleV2 = translate(translationKeySubTitleV2);

      if (translationTitleV2 !== translationKeyTitleV2) {
        if (translationSubtitleV2 !== translationKeySubTitleV2) {
          return {
            title: translationTitleV2,
            subtitle: translationSubtitleV2,
          };
        }
        return { title: translationTitleV2 };
      }
    }

    return { title: translate(getTranslationKey(translationKey)) };
  }, [translationKey, translate, showV2Label, getTranslationKey]);

  return (
    <>
      <span className={cn({ [styles.findingsLabelTitle]: showV2Label })}>
        {label.title}
      </span>
      {label.subtitle && (
        <span
          className={cn({
            [styles.findingsLabelSubtitle]: showV2Label,
          })}
        >
          {label.subtitle}
        </span>
      )}
    </>
  );
};

export { LabelWithSubtitle };
