function fileFilter<T>(imagesArray: (File | T)[] = []) {
  return imagesArray.reduce<{
    files: Array<File>;
    previouslyUploadedImages: Array<T>;
  }>(
    (acc, item) => {
      if (item instanceof File) {
        acc.files.push(item);
      } else {
        acc.previouslyUploadedImages.push(item);
      }

      return acc;
    },
    {
      files: [],
      previouslyUploadedImages: [],
    },
  );
}

export { fileFilter };
