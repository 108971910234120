import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'shared/hooks/useTranslation';

import { Button } from '@auto1-ui/button';

import { DamageItem } from '../DamageItem';
import { DamageItemContainer } from '../DamageItemContainer';
import { DeleteNotPossibleModal } from '../DeleteNotPossibleModal';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
  paintThicknessThreshold: number;
};

const DamageFields: React.FC<Props> = ({ qaIdPrefix, paintThicknessThreshold }) => {
  const { translations } = useTranslation();
  const { getValues } = useFormContext();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const {
    fields: damages,
    append,
    remove,
  } = useFieldArray({
    name: 'damages',
  });

  const isDamageAutoCreated = (damage: string) => {
    const source = getValues(`${damage}.source`);

    return source === 'paintThicknessTooHigh';
  }

  return (
    <>
      {damages.map((damage, index) => (
        <DamageItemContainer
          key={damage.id}
          title={
            <>
              {`${translations.QUALITY_DAMAGE} #${index + 1}`}{' '}
              <span className={styles.autoCreatedText}>
                {isDamageAutoCreated(`damages[${index}]`) ? translations.DAMAGE_AUTO_CREATED_TEXT : ''}
              </span>
            </>
          }
          initialState
          onDelete={() => {
            if (isDamageAutoCreated(`damages[${index}]`)) {
              setIsDeleteModalOpen(true);
            } else {
              remove(index);
            }
          }}
          qaIdPrefix={`${qaIdPrefix}-item-container-${index}`}
        >
          <DamageItem
            qaIdPrefix={`${qaIdPrefix}-item-${index}`}
            fieldName={`damages[${index}]`}
            isDamageAutoCreated={isDamageAutoCreated(`damages[${index}]`)}
          />
        </DamageItemContainer >
      ))}
      <Button
        theme="outlined"
        isActivated={false}
        qaId={`${qaIdPrefix}-add-button`}
        extraClass={styles.button}
        onClick={() => {
          append({
            files: [],
            area: null,
            type: null,
            part: null,
            isAccidentReason: false,
            source: null,
          });
        }}
      >
        + {translations.DAMAGES_ADD_BUTTON}
      </Button>
      {isDeleteModalOpen && (
        <DeleteNotPossibleModal
          paintThicknessThreshold={paintThicknessThreshold}
          onClick={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export { DamageFields };
