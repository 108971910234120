import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { apm } from '@elastic/apm-rum';

import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import {
  useTiresBrakesInitialDataQuery,
  UpdateTiresBreaksDocument,
} from 'gql/graphql';
import { TiresBrakesSchema } from 'pages/TiresBrakes/tires-brakes-schema';

import { appRoutes } from 'appRoutes';
import { RoutesEnum } from 'shared/utils/route';
import { scrollToError } from 'shared/utils/scrollToError';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { useProgress } from './hooks/useProgress';
import {
  graphqlToSchema,
  mapSchemaToMutation,
  isFormWithData,
} from './helpers';
import type { Form } from './types';

const useTiresBrakes = () => {
  const history = useHistory();
  const { removeStepFromNotCompleted, isPdfInspection, inspectionId } =
    useInspection();
  const [updateTiresBreaks, { error: mutationError }] = useMutation(
    UpdateTiresBreaksDocument,
  );
  const serverError = mutationError?.graphQLErrors?.[0]?.message;
  const { translations } = useTranslation();
  const { submitTracking } = useSubmitTracking('wheels');

  const submitContext = React.useRef('');
  const formMethods = useForm<Form>({
    context: submitContext,
    resolver: async (data, context, options) => {
      const { primaryWheels = [], additionalWheels = [] } = data;
      const formHasData = isFormWithData(primaryWheels, additionalWheels);
      const optionalSchema = context.current === 'save';
      const isOptional = optionalSchema || (isPdfInspection && !formHasData);

      return yupResolver(TiresBrakesSchema(translations, isOptional))(
        data,
        context,
        options,
      );
    },
  });

  const applyAllWheels = (name: 'primaryWheels' | 'additionalWheels') => {
    const { getValues, setValue } = formMethods;
    const form = getValues();
    const [firstWheel] = form[name];

    for (let i = 1; i < 4; i += 1) {
      setValue(`${name}.${i}.dotNumber`, firstWheel.dotNumber, {
        shouldDirty: true,
      });
      setValue(`${name}.${i}.notAvailable`, firstWheel.notAvailable, {
        shouldDirty: true,
      });
      setValue(`${name}.${i}.rimType`, firstWheel.rimType, {
        shouldDirty: true,
      });
      setValue(`${name}.${i}.tireType`, firstWheel.tireType, {
        shouldDirty: true,
      });
    }

    if (formMethods.formState.isSubmitted) {
      formMethods.trigger();
    }
  };
  const { loading } = useTiresBrakesInitialDataQuery({
    variables: {
      inspectionId,
    },
    onCompleted: (data) => {
      setTimeout(() => {
        const form = graphqlToSchema(data);
        formMethods.reset(form);
      });
    },
  });

  const setProgress = useProgress();

  const onSubmitError = () => {
    scrollToError();
    setProgress(formMethods.getValues());
  };
  const handleSubmit = async (values: Form) => {
    const optionalSchema = submitContext.current === 'save';
    const shouldRedirect = submitContext.current === 'submit';
    const { primaryWheels = [], additionalWheels = [] } = values;
    const formHasData = isFormWithData(primaryWheels, additionalWheels);

    try {
      const { brakesCondition, wheels, extraWheels } =
        mapSchemaToMutation(values);
      if (optionalSchema || !isPdfInspection || formHasData) {
        await updateTiresBreaks({
          variables: {
            inspectionId,
            wheels,
            extraWheels,
            brakesCondition,
            incomplete: optionalSchema,
            isPrimarySet: true,
            isExtraSet: false,
          },
        });
      }
      await setProgress(values);
      formMethods.reset(values);
      removeStepFromNotCompleted(RoutesEnum.TIRES_AND_BRAKES);

      if (shouldRedirect) {
        submitTracking();
        history.push(appRoutes.carStatus(`${inspectionId}`, true));
      }
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
    }
  };

  return {
    loading,
    formMethods,
    applyAllWheels,
    handleSubmit,
    submitContext,
    onSubmitError,
    serverError,
  };
};

export { useTiresBrakes };
