import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = {
  label: string;
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  labelProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;
  separatorProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;
  valueProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;
  qaIdPrefix?: string;
  extraClassName?: string;
};

const InfoField: React.FC<Props> = ({
  label,
  children,
  containerProps = {},
  labelProps = {},
  separatorProps = {},
  valueProps = {},
  qaIdPrefix = 'info-field',
  extraClassName = '',
}) => {
  const [isTextOverflown, setIsTextOverflown] = React.useState(false);
  const container = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (!container.current) {
      return;
    }

    if (container.current.clientWidth < container.current.scrollWidth) {
      setIsTextOverflown(true);
    }
  }, [container]);

  return (
    <div
      className={cn(styles.infoField, { [extraClassName]: isTextOverflown })}
      ref={container}
      {...containerProps}
    >
      <span
        className={styles.label}
        data-qa-id={`${qaIdPrefix}-label`}
        {...labelProps}
      >
        {label}
      </span>
      <span
        className={styles.separator}
        data-qa-id={`${qaIdPrefix}-separator`}
        {...separatorProps}
      />
      <span
        className={styles.value}
        data-qa-id={`${qaIdPrefix}-value`}
        {...valueProps}
      >
        {children}
      </span>
    </div>
  );
};

export { InfoField };
