import * as React from 'react';
import { useSummaryInitialDataQuery } from 'gql/graphql';

type Params = Parameters<typeof useSummaryInitialDataQuery>[0] & {
  inspectionId: number;
};

const useSummary = ({ inspectionId, ...options }: Params) => {
  const { loading, data } = useSummaryInitialDataQuery({
    fetchPolicy: 'network-only',
    variables: {
      inspectionId,
    },
    ...options,
  });

  // todo, once the menu and the anchor scrolling is done remove this
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return {
    data,
    loading,
  };
};

export { useSummary };
