import * as React from 'react';

import { useServiceDocumentsInitialDataQuery } from 'gql/graphql';
import { useProgress } from 'pages/ServicesDocuments/hooks/useProgress';
import { formatDataToFormValues } from 'pages/ServicesDocuments/helpers';
import { useInspection } from 'shared/components/InspectionContext';

function useServicesDocumentsProgress(inspectionId: number) {
  const { shouldUseNewAccidentModel } = useInspection();
  const { data } = useServiceDocumentsInitialDataQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setServicesDocumentsProgress = useProgress();
  const updateProgress = React.useCallback(() => {
    setServicesDocumentsProgress(
      formatDataToFormValues(data?.inspection, shouldUseNewAccidentModel),
    );
  }, [data, setServicesDocumentsProgress]);

  React.useEffect(() => {
    setServicesDocumentsProgress(
      formatDataToFormValues(data?.inspection, shouldUseNewAccidentModel),
    );
  }, [data, setServicesDocumentsProgress]);

  return updateProgress;
}

export { useServicesDocumentsProgress };
