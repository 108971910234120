import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  qaIdPrefix?: string;
};

const AddButton: React.FC<Props> = ({
  className,
  qaIdPrefix = 'area-add-button',
  ...props
}) => (
  <button
    className={cn(
      styles.areaButtonButton,
      styles.areaButtonButtonAdd,
      className,
    )}
    data-qa-id={qaIdPrefix}
    {...props}
  >
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5 8H8.5V14H6.5V8H0.5V6H6.5V0H8.5V6H14.5V8Z" fill="white" />
    </svg>
  </button>
);

export { AddButton };
