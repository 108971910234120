import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';

import { Button, ButtonTypes } from '@auto1-ui/button';

import { appRoutes } from 'appRoutes';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import { useTranslation } from 'shared/hooks/useTranslation';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import styles from 'shared/components/NavigationFooter/index.module.scss';
import { useScrollToSection } from 'shared/utils/useSectionScroll';
import Loader from 'shared/components/Loader';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import { useSideMenuNavigationTracking } from 'shared/hooks/useSideMenuNavigationTracking';
import { useInspection } from 'shared/components/InspectionContext';
import { AlertBar } from 'shared/components/AlertBar';
import type { Translations } from 'shared/utils/translations';

import { Mileage } from './Mileage';
import { TestDriveSection } from './components/TestDriveSection';
import { useTestDrive } from './useTestDrive';
import {
  TESTDRIVE_QUESTION_IDS,
  testDriveQuestions,
} from './TestDriveSection/TestDriveQuestionid';
import { FINDINGS_QUESTION_IDS } from './Findings/FindingQuestionIds';
import { Placeholder } from './Placeholder';
import type { Form } from './types';

type Props = {
  qaIdPrefix?: string;
};

const TestDrive: React.FC<Props> = ({ qaIdPrefix = 'test-drive-page' }) => {
  const location =
    useLocation<{
      prev: {
        pathname: string;
        hash: string;
      };
      sideMenuNavigation?: boolean;
    }>();
  const { inspectionId } = useInspection();

  const {
    questions,
    formMethods,
    handleSubmit: onSubmit,
    onSubmitError,
    loading,
    submitContext,
    serverError,
  } = useTestDrive({
    inspectionId,
  });

  const { scroll } = useScrollToSection(location.hash);
  React.useEffect(() => {
    if (!loading && questions.length > 0) {
      scroll();
    }
  }, [loading, questions, scroll]);
  useSideMenuNavigationTracking(
    location.state && location.state.prev,
    location,
  );
  const { translations } = useTranslation();
  const testDrivePerformed = formMethods.watch(
    `question[_${testDriveQuestions.PERFORMED}]` as FieldPath<Form>,
  );
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = formMethods;

  if (loading) {
    return <Placeholder />;
  }

  return (
    <LayoutContainer>
      <FormProvider {...formMethods}>
        <Mileage qaIdPrefix={qaIdPrefix} />

        <TestDriveSection
          questions={questions}
          qaIdPrefix={`${qaIdPrefix}-test-drive`}
          title={translations.TEST_DRIVE}
          questionIds={TESTDRIVE_QUESTION_IDS}
          id="test-drive"
          showDisclaimerMsg
        />

        {testDriveQuestions.PERFORMED_YES_RESPONSE === testDrivePerformed && (
          <TestDriveSection
            questions={questions}
            qaIdPrefix={`${qaIdPrefix}-findings`}
            title={translations.FINDINGS}
            questionIds={FINDINGS_QUESTION_IDS}
            id="findings"
            section="findings"
          />
        )}

        <NavigationFooter
          qaPrefix={qaIdPrefix}
          backButtonLink={appRoutes.carDetails(`${inspectionId}`, true)}
          rightAreaContent={
            <>
              <Button
                type={ButtonTypes.button}
                theme="outlined"
                isActivated={false}
                qaId={`${qaIdPrefix}-footer-save-link`}
                extraClass={styles.saveButton}
                onClick={() => {
                  submitContext.current = 'save';
                  handleSubmit(onSubmit, onSubmitError)();
                }}
                isDisabled={isSubmitting}
              >
                {translations.SAVE}
                {isSubmitting && submitContext.current === 'save' && (
                  <Loader extraClass={styles.saveButtonLoader} />
                )}
              </Button>
              <Button
                type={ButtonTypes.button}
                theme="orange"
                extraClass={styles.nextButton}
                qaId={`${qaIdPrefix}-footer-next-link`}
                isActivated={false}
                onClick={() => {
                  submitContext.current = 'submit';
                  handleSubmit(onSubmit, onSubmitError)();
                }}
                isDisabled={isSubmitting}
              >
                {translations.NEXT}
                {isSubmitting && submitContext.current === 'submit' && (
                  <Loader extraClass={styles.nextButtonLoader} />
                )}
              </Button>
            </>
          }
          bottomAreaContent={
            serverError && (
              <AlertBar
                type="error"
                message={
                  serverError in translations
                    ? translations[serverError as keyof Translations]
                    : translations.GENERIC_ERROR_MESSAGE
                }
                additionalClassNames={styles.alertBar}
              />
            )
          }
        />
        <RouteLeavingGuard when={isDirty} title={translations.UNSAVED_CHANGES}>
          <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
        </RouteLeavingGuard>
      </FormProvider>
    </LayoutContainer>
  );
};

const TestDriveWithTransaction = withTransaction(
  'TestDrive',
  'component',
)(TestDrive);

export { TestDriveWithTransaction as TestDrive };
