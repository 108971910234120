import * as React from 'react';
import cn from 'classnames';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';

type Props = {
  name: string;
  url?: string;
  containerClassName?: string;
  linkAdditonalClassname?: string;
  removeAdditionalClassname?: string;
  isUploadingPdf?: boolean;
  onRemove?: React.MouseEventHandler<HTMLDivElement>;
  qaIdPrefix?: string;
};

const PdfFile: React.FC<Props> = ({
  name,
  url = '',
  containerClassName = '',
  isUploadingPdf = false,
  onRemove = () => { },
  qaIdPrefix = 'pdf-file',
  linkAdditonalClassname = '',
  removeAdditionalClassname = '',
}) => {
  const { translations } = useTranslation();

  return (
    <div className={cn(styles.pdfContainer, containerClassName)}>
      <div className={styles.pdfIcon} />
      {isUploadingPdf && <Typography>{translations.UPLOADING_PDF}</Typography>}
      {name && (
        <>
          <a
            className={cn(styles.pdfNameLink, linkAdditonalClassname)}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            data-qa-id={qaIdPrefix}
          >
            <Typography variant="linkSmallSky">{name}</Typography>
          </a>
          <div
            className={cn(styles.closeIcon, removeAdditionalClassname)}
            onClick={onRemove}
            data-qa-id={`${qaIdPrefix}-close-icon`}
          />
        </>
      )}
    </div>
  );
};

export { PdfFile };
