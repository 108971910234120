import React from 'react';
import { TranslationEnum } from 'shared/utils/translations';

type Translate = (key: string, interpolations?: IInterpolationMap) => string;
type UseTranslation = () => {
  locale?: string;
  translate: Translate;
  translations: ReturnType<typeof TranslationEnum>;
};

function translate(...args: Parameters<typeof window.Translator.trans>) {
  return window.Translator.trans(...args);
}

const useTranslation: UseTranslation = () => {
  const translations = React.useMemo(() => {
    const { Translator } = window;
    return TranslationEnum((key) => Translator.trans(key));
  }, []);

  return {
    locale: window.Translator?.locale,
    translate,
    translations,
  };
};

export { useTranslation };
