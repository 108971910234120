import * as React from 'react';
import { Card } from 'shared/components/Card';
import { Grid } from 'shared/components/Grid';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import { FormDropdownPlaceholder } from 'shared/components/FormDropdown/Placeholder';
import styles from '../index.module.scss';

const EquipmentPlaceholder: React.FC = () => (
  <Card
    paddingTop={32}
    paddingBottom={32}
    externalStyle={styles.card}
    showBottomBorder
  >
    <Typography variant="titleXL" tag="div" additonalClassNames={styles.title}>
      <Skeleton width="116px" height="100%" />
    </Typography>
    <Grid gutter={[2, 3]} columns={2}>
      {new Array(3).fill(null).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <Grid item>
            <FormDropdownPlaceholder labelWidth="150px" />
          </Grid>
          <Grid item>
            <FormDropdownPlaceholder labelWidth="150px" />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  </Card>
);

export { EquipmentPlaceholder };
