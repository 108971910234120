import * as React from 'react';
import styles from './index.module.scss';

type Props = {
  style?: { [key: string]: string };
  width?: string;
  height?: string;
};

const Skeleton: React.FC<Props> = ({ width, height, style = {} }) => {
  const internalStyle = { ...style };

  if (width) {
    internalStyle.width = width;
  }

  if (height) {
    internalStyle.height = height;
  }

  return (
    <span className={styles.skeleton} style={internalStyle}>
      &zwnj;
    </span>
  );
};

export { Skeleton };
