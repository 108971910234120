import { useRef } from 'react';
import { refreshToken } from 'auth/tokenManager';
import { imageServiceBaseUrl } from 'config/urls';
import { getAuthToken } from 'auth/tokenManager';
import type { UploadResponse, UploadData } from 'shared/utils/types';

function getFetch(useAuthorization: boolean) {
  const baseUrl = imageServiceBaseUrl();
  const baseUrlSanitized = baseUrl.endsWith('/')
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl;

  return function (
    url: Parameters<typeof fetch>[0],
    options: Parameters<typeof fetch>[1] = {},
  ) {
    const abortController = new AbortController();

    const fetchPromise = fetch(`${baseUrlSanitized}${url}`, {
      ...options,
      signal: abortController.signal,
      headers: useAuthorization
        ? {
            ...options.headers,
            Authorization: `Bearer ${getAuthToken() ?? ''}`,
          }
        : options.headers,
    });

    return [fetchPromise, abortController] as const;
  };
}

function isHttpClientError(status: number) {
  return status >= 400 && status < 500;
}

function useMediaUploader(url: string) {
  const currentRequest = useRef<AbortController | null>(null);

  const uploadMedia = async (
    data: UploadData,
    { url: innerUrl = url, useAuthorization = true } = {},
    attempts = 0,
  ): Promise<{ data: UploadResponse; mimeType: string }> => {
    if (currentRequest.current) {
      currentRequest.current.abort();
    }

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(
        key,
        value === null ? '' : typeof value === 'number' ? `${value}` : value,
      );
    });

    const fetch = getFetch(useAuthorization);
    const [fetchPromise, abortController] = fetch(innerUrl, {
      method: 'POST',
      body: formData,
    });
    currentRequest.current = abortController;

    try {
      const response = await fetchPromise;

      if (isHttpClientError(response.status)) {
        if (attempts < 3) {
          await refreshToken();
          return uploadMedia(
            data,
            { url: innerUrl, useAuthorization },
            attempts + 1,
          );
        }

        throw new Error('Unable to refresh token');
      }

      const responseData = await response.json();
      return { data: responseData as UploadResponse, mimeType: data.file.type };
    } finally {
      currentRequest.current = null;
    }
  };

  return {
    uploadMedia,
  };
}

export { useMediaUploader };
