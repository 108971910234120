import * as React from 'react';

export const InfoInCircle: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 12H7.2V7.2h1.6V12zm0-6.4H7.2V4h1.6v1.6z"
      fill="#358CCB"
      fillRule="nonzero"
    />
  </svg>
);
