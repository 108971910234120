import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { appRoutes } from 'appRoutes';
import { inspectionSources, InspectionActions } from 'shared/constants';
import { PageLoader } from 'shared/components/PageLoader';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useGetInspectionByStockNumberQuery } from 'gql/graphql';
import styles from './index.module.scss';

const StockNumberRedirection: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ inspectionId: string }>();
  const stockNumber = params.inspectionId;
  const { translations } = useTranslation();
  const [error, setError] = React.useState<string>('');
  useGetInspectionByStockNumberQuery({
    variables: {
      stockNumberOrVin: stockNumber,
    },
    onCompleted: (data) => {
      const { inspectionId, sourceType, actions } = data?.search ?? {};
      if (inspectionId) {
        const canConvertToEva = actions?.find(
          ({ name }) => name === InspectionActions.ADD_TO_FULL_EVALUATION,
        );
        if (sourceType === inspectionSources.PI_APP && !canConvertToEva) {
          setError(translations.INVALID_STOCK_NUMBER);
        } else {
          history.push(appRoutes.carDetails(`${inspectionId}`, true));
        }
      } else {
        setError(translations.INVALID_STOCK_NUMBER);
      }
    },
    onError: ({ graphQLErrors }) => {
      // @ts-ignore
      const notFoundError = graphQLErrors.find(({ code }) => code === 404);
      if (notFoundError) {
        setError(notFoundError?.message);
      }
    },
  });

  if (error) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.errorContainer}>
          <Typography variant="textXSRed">{error}</Typography>
        </div>
      </div>
    );
  }

  return (
    <PageLoader
      className={styles.loader}
      message={translations.LOADING_INSPECTION_APP}
    />
  );
};

export { StockNumberRedirection };
