import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

import { FormNumberInput } from 'shared/components/FormNumberField';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';

type Props = {
  area: string;
  canBePlastic: boolean;
  canBeFabric: boolean;
  qaIdPrefix: string;
};

function getMaterial(canBePlastic: boolean, canBeFabric: boolean) {
  if (canBePlastic) {
    return 'plastic';
  }

  if (canBeFabric) {
    return 'fabric';
  }

  return '';
}

function scrollToCenter(e: React.FocusEvent<HTMLInputElement>) {
  e.target.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
}

const ThicknessItemV2: React.FC<Props> = ({
  area,
  canBePlastic,
  canBeFabric,
  qaIdPrefix,
}) => {
  const { translate, translations } = useTranslation();
  const material = getMaterial(canBePlastic, canBeFabric);
  const {
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();
  const isNotMeasurable = watch(
    `paintThickness.measurements.${area}.isNotMeasurable`,
  );

  return (
    <div className={styles.container}>
      <span className={styles.area}>
        {translate(
          `a1-inspectionApp-quality-paint-thickness-question-front-${kebabCase(
            area,
          )}`,
        )}
      </span>
      <FormNumberInput
        qaIdPrefix={`${qaIdPrefix}-${kebabCase(area)}`}
        name={`paintThickness.measurements.${area}.value`}
        label={translations.PAINT_THICKNESS_LABEL}
        actionIcon={<span className={styles.micrometer}>µm</span>}
        containerClassName={styles.formInputContainer}
        className={styles.formInput}
        errorClassName={styles.formInputError}
        disabled={isNotMeasurable}
        defaultValue={null}
        onFocus={scrollToCenter}
      />
      {material !== '' && (
        <FormCheckbox
          name={`paintThickness.measurements.${area}.isNotMeasurable`}
          qaIdPrefix={`${qaIdPrefix}-${kebabCase(area)}-not-measurable`}
          uiLabel={translate(
            `a1-inspectionApp-quality-paint-thickness-${material}-not-measurable`,
          )}
          containerClassName={styles.containerFormCheckbox}
          defaultValue={false}
          afterChange={(isChecked) => {
            if (get(errors, `paintThickness.measurements.${area}.value`)) {
              trigger(`paintThickness.measurements.${area}.value`);
            }
            if (isChecked) {
              setValue(`paintThickness.measurements.${area}.value`, null);
            }
          }}
        />
      )}
    </div>
  );
};

export { ThicknessItemV2 };
