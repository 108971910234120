import * as React from 'react';
import set from 'lodash/set';
import { appRoutes } from 'appRoutes';
import { ServerError } from '@apollo/client';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';
import { apm } from '@elastic/apm-rum';
import { withTransaction } from '@elastic/apm-rum-react';

import {
  useSubmitLicensePlateMutation,
  useCheckBeforeSubmitVinV2LazyQuery,
  useUserInspectionTypesQuery,
  useMsiSubmitVinMutation,
  useResolveLicensePlateLazyQuery,
} from 'gql/graphql';
import type { CheckBeforeSubmitVinV2Query, UserBranchesQuery } from 'gql/graphql';
import { inspectionSources, InspectionActions } from 'shared/constants';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { formatVinToAPI } from 'shared/utils/formatVin';
import { useTranslation } from 'shared/hooks/useTranslation';
import { BranchSelection } from 'shared/components/BranchSelection';
import { BranchSelectionPaginated } from 'shared/components/BranchSelectionPaginated';
import { Card } from 'shared/components/Card';
import { Grid } from 'shared/components/Grid';
import { Typography } from 'shared/components/Typography';
import { CarVinInfo } from 'shared/components/CarVinInfo';
import { useRedirectToInspection } from 'shared/components/CarVinInfo/hooks/useRedirectToInspection';
import { useTracking } from 'shared/components/CarVinInfo/hooks/useTracking';
import { AllInspectionsLink } from 'shared/components/AllInspectionsLink';
import { VinStatus } from 'shared/utils/graphqlEnums';
import {
  DuplicateVinModal,
  NoRerunRemainingModal,
  RerunRemainingModal,
  NoRerunRemainingModalType,
} from 'shared/components/WarningModal';
import { EmptyVINModal } from 'shared/components/EmptyVINModal';
import { DATTimeoutModal } from 'shared/components/DATTimeoutModal';
import { PageLoader } from 'shared/components/PageLoader';
import { ErrorMessagePage } from 'pages/ErrorMessagePage';
import { useLicensePlate } from './hooks/useLicensePlate';
import { useDATTimeout } from './hooks/useDATTimeout';
import { LicensePlateInfo } from './components/LicensePlateInfo';
import { CarInfoSchema } from './carInfo.schema';
import type { Form } from './types';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
};

type WarningModalProps = { isOpen: boolean; vinStatus?: string } & Partial<
  | React.ComponentProps<typeof DuplicateVinModal>
  | React.ComponentProps<typeof RerunRemainingModal>
  | React.ComponentProps<typeof NoRerunRemainingModal>
  | React.ComponentProps<typeof EmptyVINModal>
  | React.ComponentProps<typeof DATTimeoutModal>
>;

const CarInformationPage: React.FC<Props> = ({
  qaIdPrefix = 'eva-inspection',
}) => {
  const history = useHistory();
  const { translations } = useTranslation();
  const [hasUserBranches, setHasUserBranches] = React.useState(
    isMobileEvaluatorApp(),
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submitContext =
    React.useRef<'vinInfo' | 'licensePlateInfo' | null>(null);
  const formMethods = useForm({
    context: submitContext,
    resolver: async (data, context) => {
      const isVinInfo = context?.current === 'vinInfo';
      try {
        const values = await CarInfoSchema(translations, isVinInfo).validate(
          data,
          { abortEarly: false },
        );

        return {
          values,
          errors: {},
        };
      } catch (err) {
        const { inner } = err as ValidationError;
        const errors = inner.reduce((acc, { path, type, message }) => {
          if (path) {
            return set(acc, path, { type, message });
          }

          return acc;
        }, {});

        return {
          values: {},
          errors,
        };
      }
    },
  });

  const { control, watch, getValues, handleSubmit, setError, clearErrors } =
    formMethods;
  const vinValue = watch('vin');
  const licensePlateValue = watch('licensePlate');
  const branchId = useWatch({
    name: 'branch',
    control,
  });

  const [serverError, setServerError] = React.useState('');
  const [selectedBranch, setSelectedBranch] =
    React.useState<UserBranchesQuery['userBranches'][number]>();
  const [warningModalProps, setWarningModalProps] =
    React.useState<WarningModalProps>({ isOpen: false, vinStatus: '' });

  const { handleCompleted, handleTimeoutError, trackClickManualFlow } = useRedirectToInspection();
  const {
    trackRedirectToCarDetails,
    trackOpenDuplicateVinPopup,
    trackClickCreateNewEvaluation,
    trackCloseDuplicateVinPopup,
    trackClickLoadLicensePlate,
    trackLoadLicensePlateSuccess,
  } = useTracking();

  const { data: userInspectionTypes, loading: loadingUserInspectionTypes } =
    useUserInspectionTypesQuery();

  const [submitVin, { loading: isSubmittingVin }] = useMsiSubmitVinMutation({
    onCompleted: (data) => {
      setIsSubmitting(false);
      const result = data.msiSubmitVin ?? {};
      setServerError('');
      handleCompleted({ result, branchId, vinValue, action: 'loadVIN' });
    },
    onError: (error) => {
      setIsSubmitting(false);
      if (
        (error.networkError as ServerError)?.statusCode === 504 ||
        error.graphQLErrors?.some(({ code }) => code === 504)
      ) {
        handleTimeoutError({ branchId, vinValue });
      } else {
        setServerError(translations.LOAD_DATA_SERVER_ERROR);
        setWarningModalProps({ isOpen: false });
      }
    },
  });

  const [submitLicensePlate, { loading: isSubmittingLicensePlate }] =
    useSubmitLicensePlateMutation({
      onCompleted: (data) => {
        setServerError('');
        setIsSubmitting(false);
        const result = data.submitLicensePlate;
        const licensePlate = result?.vehicle?.identifiers?.licensePlate;
        const inspectionId = result?.inspectionId;
        const stockNumber = result?.car?.stockNumber;

        trackLoadLicensePlateSuccess({
          licensePlate,
          inspectionId,
          stockNumber,
          branch: branchId,
        });
        handleCompleted({ result, branchId, vinValue, action: 'loadVIN' });
      },
      onError: (error) => {
        setIsSubmitting(false);
        if (
          (error.networkError as ServerError)?.statusCode === 504 ||
          error.graphQLErrors?.some(({ code }) => code === 504)
        ) {
          handleTimeoutError({ branchId, vinValue });
        } else {
          setServerError(translations.LOAD_DATA_SERVER_ERROR);
          setWarningModalProps({ isOpen: false });
        }
      },
    });

  const redirectToManualFlow = ({ branch, licensePlate, externalId, vin }: Form) => {
    history.push(
      appRoutes.manualFlowWithBranch(`${branch}`, true),
      {
        vin: formatVinToAPI(vin ?? ''),
        licensePlate,
        externalId,
      },
    );
    trackClickManualFlow({
      vin: formatVinToAPI(vin ?? ''),
    });
  };

  const [getCarFromLicensePlate] = useResolveLicensePlateLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: async (result) => {
      setServerError('');
      const { branch, externalId, licensePlate, vin } = getValues() as Form;

      const vinValue = vin ?? result?.resolveLicensePlate;
      if (vinValue) {
        redirectToManualFlow({ branch, externalId, licensePlate, vin: vinValue })
      } else {
        setError('licensePlate', {
          type: 'custom',
          message: translations.LICENSE_PLATE_FAIL_MESSAGE,
        });
      }
    },
    onError: () => {
      setError('licensePlate', {
        type: 'custom',
        message: translations.LICENSE_PLATE_FAIL_MESSAGE,
      });
    }
  });

  const handleClickFillManually = () => {
    const { branch, licensePlate, externalId, vin } = getValues() as Form;
    if (branch === undefined || licensePlate === undefined) {
      throw new Error('Incomplete values from form');
    }
    if (submitContext?.current === 'licensePlateInfo') {
      getCarFromLicensePlate({
        variables: {
          branch,
          licensePlate,
        },
      });
    } else {
      redirectToManualFlow({ branch, externalId, licensePlate, vin })
    }
  };

  const evaluationType = 'MSI_APP';
  const checkCompleted = (
    result: CheckBeforeSubmitVinV2Query['checkBeforeSubmitVinV2'],
  ) => {
    const { vinStatus, relatedInspection: inspection } = result;
    const vin = inspection?.vehicle?.identifiers?.vin;
    switch (vinStatus) {
      case VinStatus.NoVehicleInfo:
        // Show modal that allow user to fill manually
        setServerError('');
        setIsSubmitting(false);
        setWarningModalProps({
          isOpen: true,
          vinStatus,
          handleClickFillManually: () =>
            handleClickFillManually(),
          handleClose: () => {
            setWarningModalProps({
              isOpen: false,
            });
          },
        });
        break;
      case VinStatus.VehicleInfoTimeout:
        // Show modal that allow user to fill manually
        if (refetchCompleted) {
          setServerError('');
          setIsSubmitting(false);
          setWarningModalProps({
            isOpen: true,
            vinStatus,
            handleClickFillManually: () =>
              handleClickFillManually(),
            handleClose: () => {
              setWarningModalProps({
                isOpen: false,
              });
            },
          });
        }
        break;
      case VinStatus.Ok:
        // Proceed to car details page
        // eslint-disable-next-line no-use-before-define
        handleCreateEvaluation(inspection);
        break;
      case VinStatus.DuplicatedVinUnfinished:
        // Show old duplicate vin modal
        setServerError('');
        setIsSubmitting(false);
        setWarningModalProps({
          isOpen: true,
          vinStatus,
          inspectionInfo: inspection,
          handleContinueEvaluation: () =>
            // eslint-disable-next-line no-use-before-define
            handleContinueEvaluation(inspection),
          handleCreateEvaluation: () =>
            // eslint-disable-next-line no-use-before-define
            handleCreateEvaluation(inspection, true),
          handleClose: () => {
            setWarningModalProps({
              isOpen: false,
            });
            trackCloseDuplicateVinPopup({
              vin,
              branch: branchId,
            });
          },
        });
        trackOpenDuplicateVinPopup({
          inspectionId: inspection?.inspectionId,
          vin: inspection?.vehicle?.identifiers?.vin,
          branch: inspection?.branch?.id,
          stockNumber: inspection?.car?.stockNumber,
          evaluationSource: inspection?.sourceType,
        });
        break;
      case VinStatus.RunningAuction: // Show duplicate vin modal that allow user to create new evaluation
      case VinStatus.AuctionEnded: // Show duplicate vin modal that allow user to create new evaluation
        setServerError('');
        setIsSubmitting(false);
        setWarningModalProps({
          isOpen: true,
          vinStatus,
          inspectionInfo: inspection,
          onSecondaryButtonClick: () => {
            // eslint-disable-next-line no-use-before-define
            handleCreateEvaluation(null);
          },
          handleClose: () => {
            setWarningModalProps({
              isOpen: false,
            });
          },
        });
        break;
      case VinStatus.RerunLimitExhausted: // Show duplicate vin modal that doesn't allow user to create new evaluation
        setServerError('');
        setIsSubmitting(false);
        setWarningModalProps({
          isOpen: true,
          vinStatus,
          inspectionInfo: inspection,
          handleClose: () => {
            setWarningModalProps({
              isOpen: false,
            });
          },
        });
        break;
      default:
        break;
    }
  };

  const [
    checkBeforeSubmitVinV2, {
      loading: checkVinLoading,
      refetch: checkVinRefetch,
      data: checkVinData,
    }
  ] = useCheckBeforeSubmitVinV2LazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      checkCompleted(result?.checkBeforeSubmitVinV2);
    },
    onError: () => {
      // eslint-disable-next-line no-use-before-define
      handleCreateEvaluation();
    },
  });

  const updateContext = (contextName: 'vinInfo' | 'licensePlateInfo') => {
    submitContext.current = contextName;
  };

  const {
    checkBeforeSubmitLicensePlateV2,
    handleUseVin,
    shouldUseVin,
    licensePlateLoading,
    licensePlateRefetch,
    licensePlateData,
  } =
    useLicensePlate({
      branchId,
      licensePlateValue,
      checkCompleted,
      setServerError,
      setIsSubmitting,
      setError,
      clearErrors,
      updateContext,
    });

  const contextData = submitContext?.current === 'licensePlateInfo' ? {
    loading: licensePlateLoading,
    refetch: licensePlateRefetch,
    vinStatus: licensePlateData?.checkBeforeSubmitLicensePlateV2.vinStatus,
    retryAfter: licensePlateData?.checkBeforeSubmitLicensePlateV2.retryAfter,
  } : {
      loading: checkVinLoading,
      refetch: checkVinRefetch,
      vinStatus: checkVinData?.checkBeforeSubmitVinV2.vinStatus,
      retryAfter: checkVinData?.checkBeforeSubmitVinV2.retryAfter,
    };

  const { refetchCompleted } = useDATTimeout(contextData);

  const handleCreateEvaluation = (
    inspectionInfo?: NonNullable<
      CheckBeforeSubmitVinV2Query['checkBeforeSubmitVinV2']
    >['relatedInspection'],
    enableTracking: boolean = false,
  ) => {
    const vin = inspectionInfo?.vehicle?.identifiers?.vin;
    const values = getValues() as Form;
    try {
      if (enableTracking) {
        trackClickCreateNewEvaluation({
          vin,
          branch: branchId,
        });
      }
      if (submitContext?.current === 'vinInfo') {
        submitVin({
          variables: {
            ...values,
            branch: Number(values.branch),
            vin: vin ?? formatVinToAPI(values.vin ?? ''),
          },
        });
      } else if (submitContext?.current === 'licensePlateInfo') {
        submitLicensePlate({
          variables: {
            ...values,
            licensePlate: values.licensePlate ?? '', // Added this line for Typescript linter
            branch: Number(values.branch),
          },
        });
      }
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
    }
  };

  const handleContinueEvaluation = (
    inspectionInfo: NonNullable<
      CheckBeforeSubmitVinV2Query['checkBeforeSubmitVinV2']
    >['relatedInspection'],
  ) => {
    const vin = inspectionInfo?.vehicle?.identifiers?.vin;
    if (inspectionInfo?.sourceType === inspectionSources.PI_APP) {
      if (
        inspectionInfo?.actions?.find(
          ({ name }) => name === InspectionActions.ADD_TO_FULL_EVALUATION,
        )
      ) {
        if (!inspectionInfo.car.stockNumber) {
          throw new Error('stock number is not defined');
        }

        history.push(
          appRoutes.convertToFullEva(inspectionInfo.car.stockNumber, true),
        );
      } else {
        window.location.href = appRoutes.searchRemarketing(
          inspectionInfo?.car?.stockNumber ?? '',
        );
      }
    } else {
      handleCompleted({ result: inspectionInfo, branchId });
    }
    trackRedirectToCarDetails({
      action: 'goToEvaluation',
      inspectionId: inspectionInfo?.inspectionId,
      vin,
      branch: branchId,
    });
  };

  const handleLoadVin = async (values: Form) => {
    setIsSubmitting(true);
    const { vin: vinVal, branch } = values;
    checkBeforeSubmitVinV2({
      variables: {
        vin: formatVinToAPI(vinVal ?? ''),
        branchId: Number(branch),
        evaluationType,
      },
    });
  };

  const handleLoadCarData = (values: Form) => {
    if (values.branch === undefined || values.licensePlate === undefined) {
      throw new Error('Incomplete values from form');
    }

    setIsSubmitting(true);
    const { licensePlate, branch } = values;
    trackClickLoadLicensePlate({
      licensePlate,
      branch: branchId,
    });
    checkBeforeSubmitLicensePlateV2({
      variables: {
        branchId: branch,
        licensePlate,
        evaluationType,
      },
    });
  };

  const getWarningModal = (params: WarningModalProps) => {
    if (!params.isOpen) {
      return null;
    }

    switch (params.vinStatus) {
      case 'RUNNING_AUCTION':
      case 'AUCTION_ENDED':
        return (
          <RerunRemainingModal
            {...(params as React.ComponentProps<typeof RerunRemainingModal>)}
            trackingEvents={{
              view: 'EvaluateDuplicateVINWarning',
              mainButtonClick: 'EvaluateDuplicateVINWarningViewAuction',
              secondaryButtonClick: 'EvaluateDuplicateVINWarningCreateNew',
            }}
          />
        );
      case 'RERUN_LIMIT_EXHAUSTED':
        return (
          <NoRerunRemainingModal
            {...(params as React.ComponentProps<typeof NoRerunRemainingModal>)}
            modalType={NoRerunRemainingModalType.CreateVin}
            trackingEvents={{
              view: 'EvaluateDuplicateVINBlocked',
              mainButtonClick: 'EvaluateDuplicateVINBlockedViewAuction',
              secondaryButtonClick: 'EvaluateDuplicateVINBlockedClose',
            }}
          />
        );
      case 'NO_VEHICLE_INFO':
        return (
          <EmptyVINModal
            {...(params as React.ComponentProps<typeof EmptyVINModal>)}
          />
        );
      case 'VEHICLE_INFO_TIMEOUT':
        // wait 10 sec , request
        return (
          <DATTimeoutModal
            {...(params as React.ComponentProps<typeof DATTimeoutModal>)}
          />
        );
      default:
        return (
          <DuplicateVinModal
            {...(params as React.ComponentProps<typeof DuplicateVinModal>)}
          />
        );
    }
  };

  if (loadingUserInspectionTypes) {
    return <PageLoader message={translations.LOADING_INSPECTION_APP} />;
  }

  if (
    !isMobileEvaluatorApp() &&
    userInspectionTypes &&
    !userInspectionTypes?.user?.inspectionTypes.some(
      ({ type, enabled }) => type === 'FULL' && enabled === true,
    )
  ) {
    return <ErrorMessagePage component={translations.USER_NOT_ALLOWED} />;
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <AllInspectionsLink />
        <Card
          paddingBottom={34}
          paddingTop={34}
          externalStyle={styles.carInformationCard}
          qaIdPrefix={qaIdPrefix}
        >
          <Grid item className={styles.contentContainer}>
            <Typography
              tag="h2"
              data-qa-id={`${qaIdPrefix}-header`}
              variant="titleXL"
              additonalClassNames={styles.pageHeader}
            >
              {translations.CAR_INFO}
            </Typography>
            <Grid item className={styles.fieldWrapper}>
              {isMobileEvaluatorApp() ? (
                <BranchSelectionPaginated
                  setHasUserBranches={setHasUserBranches}
                  setSelectedBranch={setSelectedBranch}
                />
              ) : (
                  <BranchSelection
                    setHasUserBranches={setHasUserBranches}
                    setSelectedBranch={setSelectedBranch}
                  />
                )}
            </Grid>
            {hasUserBranches &&
              selectedBranch &&
              (!selectedBranch.licensePlateCallEnabled ||
                shouldUseVin === true) ? (
                <CarVinInfo
                  qaIdPrefix={qaIdPrefix}
                  serverError={serverError}
                  isSubmitting={isSubmitting}
                  shouldUseVin={shouldUseVin}
                  branchId={selectedBranch.value}
                  handleLoadVin={() => {
                    submitContext.current = 'vinInfo';
                    handleSubmit(handleLoadVin)();
                  }}
                />
              ) : null}
            {hasUserBranches &&
              selectedBranch?.licensePlateCallEnabled === true &&
              !shouldUseVin ? (
                <LicensePlateInfo
                  serverError={serverError}
                  isSubmitting={isSubmitting}
                  branchId={selectedBranch.value}
                  handleLoadCarData={() => {
                    submitContext.current = 'licensePlateInfo';
                    handleSubmit(handleLoadCarData)();
                  }}
                  handleUseVin={() => {
                    submitContext.current = 'vinInfo';
                    handleUseVin();
                  }}
                />
              ) : null}
          </Grid>
        </Card>
      </FormProvider>
      {getWarningModal({
        ...warningModalProps,
        isSubmitting: isSubmittingVin || isSubmittingLicensePlate,
      })}
    </>
  );
};

const CarInformationPageWithTransaction = withTransaction(
  'CarInformationPage',
  'component',
)(CarInformationPage);

export { CarInformationPageWithTransaction as CarInformationPage };
