import * as React from 'react';
import { useCarStatusDataQuery } from 'gql/graphql';
import { useProgress } from 'pages/CarStatus/hooks/useProgress';
import { graphqlDataToSchema } from 'pages/CarStatus/graphqlMapper';

function useCarStatusProgress(inspectionId: number) {
  const { data } = useCarStatusDataQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setCarStatusProgress = useProgress();
  const updateProgress = React.useCallback(() => {
    setCarStatusProgress(graphqlDataToSchema(data));
  }, [data, setCarStatusProgress]);

  React.useEffect(() => {
    setCarStatusProgress(graphqlDataToSchema(data));
  }, [data, setCarStatusProgress]);

  return updateProgress;
}

export { useCarStatusProgress };
