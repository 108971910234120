import * as React from 'react';
import { ToggleButton } from '@auto1-ui/togglebutton';
import cn from 'classnames';
import { withFormItem } from '../withFormItem';
import styles from './index.module.scss';

type ToggleButtonProps = React.ComponentProps<typeof ToggleButton>;
type Option<V> = Omit<ToggleButtonProps['options'][number], 'value'> & {
  value: V;
};
type Props<V> = Omit<
  ToggleButtonProps,
  'value' | 'options' | 'onChange' | 'defaultValue'
> & {
  transform?: (value: V) => string;
  className?: string;
  value: V;
  options: ReadonlyArray<Option<V>>;
  onChange: (value: V) => void;
};

function toValueAsString<V>({ value, ...rest }: Option<V>) {
  return {
    ...rest,
    value: `${value}`,
    originalValue: value,
  };
}

function Toggle<V>({
  transform,
  value,
  className = '',
  options,
  onChange,
  ...props
}: Props<V>) {
  const ops = options.map(toValueAsString);

  return (
    <ToggleButton
      {...props}
      options={ops}
      className={cn(styles.container, className)}
      value={
        transform
          ? transform(value)
          : value !== null && value !== undefined
          ? `${value}`
          : ''
      }
      onChange={(val) => {
        const selectedValue = ops.find(({ value }) => value === val);

        if (!selectedValue) {
          console.error('selected value does not exist');
          return;
        }

        onChange(selectedValue.originalValue);
      }}
    />
  );
}

const FormToggleButton = withFormItem(Toggle, {
  classNames: {
    error: styles.error,
  },
});

export { FormToggleButton };
