import { useApolloClient } from '@apollo/client';
import { GetFileUploadUrlDocument } from 'gql/graphql';

const usePdfParserUploader = () => {
  const client = useApolloClient();

  return async (file: File) => {
    const { data } = await client.query({
      query: GetFileUploadUrlDocument,
      fetchPolicy: 'no-cache',
    });

    const response = await fetch(data?.getFileUploadUrl.url, {
      method: 'PUT',
      body: file,
    });

    return response.url;
  };
};

export { usePdfParserUploader };
