import * as React from 'react';
import {
  useEvaSubmitToAuctionMutation,
  useEvaSubmitToBatchAuctionMutation,
} from 'gql/graphql';
import type {
  EvaSubmitToAuctionMutation,
  EvaSubmitToBatchAuctionMutation,
} from 'gql/graphql';
import type { ApolloError, FetchResult, MutationResult } from '@apollo/client';
import type { UseFormSetError } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { useTranslation } from 'shared/hooks/useTranslation';
import { appRoutes } from 'appRoutes';
import { useInspection } from 'shared/components/InspectionContext';
import { requiredMutations } from 'shared/utils/route2ReqMutationsMap';
import { SectionsEnum } from 'shared/utils/route';
import type { Translations } from 'shared/utils/translations';
import type { SubmitAuctionForm } from '../types';
import { SubmitToAuctionModal } from '../constants';

type SubmitAuction = (variables: {
  variables: {
    inspectionId: number;
    expectedPrice: number;
    startPrice: number;
    termsAccepted: boolean;
    failedReasons: number[];
    auctionTypeId: number | null;
  };
}) => Promise<
  FetchResult<EvaSubmitToAuctionMutation | EvaSubmitToBatchAuctionMutation>
>;

const translatedMsgs = [
  'INCOMPLETE_INSPECTION',
  'INSPECTION_ALREADY_SUBMITTED',
];

const useSubmitToAuction = ({
  setError,
  submitTracking,
  auctionModal,
}: {
  setError: UseFormSetError<SubmitAuctionForm>;
  submitTracking: () => void;
  auctionModal: keyof typeof SubmitToAuctionModal;
}) => {
  const [openModalDate] = React.useState(Date.now());
  const { translations } = useTranslation();
  const history = useHistory();
  const { inspectionId, stockNumber, isPdfInspection, setNotCompletedSteps } =
    useInspection();

  const handleMutationCompleted = () => {
    submitTracking();
    history.push(appRoutes.completed(true));
  };

  const handleMutationError = async (error: ApolloError) => {
    const errorMessage = error.message.replace('GraphQL error: ', '');
    const inCompleteMutations = error.graphQLErrors.flatMap((error) => [
      ...(error.incompleteMutations?.WEB ?? []),
      ...(error.missingMutations ?? []),
    ]);

    const sections = Object.entries(requiredMutations(isPdfInspection)).reduce<
      string[]
    >((acc, [key, value]) => {
      const missedSection = inCompleteMutations.some((mutation) =>
        value?.includes(mutation),
      );
      if (missedSection) {
        return [...acc, SectionsEnum[key as keyof typeof SectionsEnum]];
      }
      return acc;
    }, []);
    window.tracking.eva.incompleteSubmit({
      section: sections.join(','),
      inspectionId,
      stockNumber,
      duration: differenceInSeconds(Date.now(), openModalDate),
    });

    setNotCompletedSteps(inCompleteMutations);
    setError('expectedPrice', {
      message: translatedMsgs.includes(errorMessage)
        ? translations[errorMessage as keyof Translations]
        : errorMessage,
    });
  };

  const [submitToAuction, submitToAuctionResult] =
    useEvaSubmitToAuctionMutation({
      onCompleted: handleMutationCompleted,
      onError: handleMutationError,
    });
  const [submitToBatchAuction, submitToBatchAuctionResult] =
    useEvaSubmitToBatchAuctionMutation({
      onCompleted: handleMutationCompleted,
      onError: handleMutationError,
    });

  return [
    auctionModal === SubmitToAuctionModal.FullService
      ? submitToAuction
      : submitToBatchAuction,
    auctionModal === SubmitToAuctionModal.FullService
      ? submitToAuctionResult
      : submitToBatchAuctionResult,
  ] as [
    SubmitAuction,
    MutationResult<
      EvaSubmitToAuctionMutation | EvaSubmitToBatchAuctionMutation
    >,
  ];
};

export { useSubmitToAuction };
