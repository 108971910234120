import * as React from 'react';
import { format } from 'date-fns';
import type {
  CheckBeforeSubmitToAuctionQuery,
} from 'gql/graphql';
import { filterImagesByArea } from 'shared/utils/filterImagesByArea';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Typography } from 'shared/components/Typography';
import styles from './index.module.scss';

type Inspection =
  CheckBeforeSubmitToAuctionQuery['checkBeforeSubmitToAuction']['relatedInspection'];

type Props = {
  inspection: Inspection;
};

const CarCard: React.FC<Props> = ({ inspection }) => {
  const { translations } = useTranslation();
  const { car, vehicle, branch, lastChangedTime } = inspection ?? {};
  const evaluationUpdated = lastChangedTime
    ? format(new Date(lastChangedTime), 'dd/MM/yyyy')
    : '';
  const imgUrl = filterImagesByArea(vehicle?.images, 'FRONT_LEFT');

  return (
    <div className={styles.info}>
      <div className={styles.imageContainer}>
        {imgUrl && <img src={imgUrl} alt="warning-modal-car" />}
      </div>
      <div
        className={styles.contentContainer}
        data-qa-id="car-information-warning-content-container"
      >
        <Typography
          additonalClassNames={styles.title}
          tag="div"
          data-qa-id="car-information-warning-title"
        >
          {`${car?.stockNumber} - ${vehicle?.model?.make} ${vehicle?.model?.model} ${vehicle?.model?.subModel}`}
        </Typography>
        <Typography
          variant="textXS"
          tag="div"
          data-qa-id="car-information-warning-vin"
        >
          {vehicle?.identifiers?.vin ?? ''}
        </Typography>
        <Typography
          variant="textXS"
          tag="div"
          data-qa-id="car-information-warning-branch-name"
        >
          {branch?.name ?? ''}
        </Typography>
        <Typography
          variant="textXS"
          tag="div"
          data-qa-id="car-information-warning-inspection-date"
        >
          {translations.EVALUATION_UPDATED}: {evaluationUpdated}
        </Typography>
      </div>
    </div>
  );
};

export { CarCard };
