import { useMultipleMediaUploader } from 'shared/utils/useMultipleMediaUploader';
import { fileFilter } from 'shared/utils/fileFilter';
import { VehicleImageInput, useCarDetailsUpdaterMutation } from 'gql/graphql';
import { mimeTypeToVehicleMimeType } from 'shared/utils/mimeType';
import type { Mutation } from '../types';

import {
  DOCUMENTS_AREA,
  REGISTRATION_PART,
  CERTIFICATE_PART,
  SUPPORTING_DOCUMENTS_PART,
} from '../constants';

const formatPart = (area: string, part: string) => {
  return `vehicle__${area}__${part}`.toLowerCase();
};

function useCarDetailsUpdater(stockNumber?: string | null) {
  const { uploadMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );
  const [mutate, mutateResult] = useCarDetailsUpdaterMutation();

  const updateCarDetails = async (
    variables: Mutation & { inspectionId: number; incomplete: boolean },
  ) => {
    const carRegistrationImages = fileFilter(variables.carRegistrationImages);
    const certificateImages = fileFilter(variables.certificateImages);
    const supportingDocuments = fileFilter(variables.supportingDocuments);
    const [
      carRegistrationImagesToUpload,
      certificateImagesToUpload,
      supportingDocumentsToUpload,
    ] = await Promise.all([
      uploadMedia(
        carRegistrationImages.files.map((file) => ({
          stockNumber,
          file,
          part: formatPart(DOCUMENTS_AREA, REGISTRATION_PART),
          source: 11,
        })),
      ).then((results) => [
        ...carRegistrationImages.previouslyUploadedImages,
        ...results.map<VehicleImageInput>(
          ({
            data: {
              wkda: { url },
            },
            mimeType,
          }) => ({
            absoluteUrl: url,
            mimeType: mimeTypeToVehicleMimeType(mimeType) ?? 'JPEG',
            pointsOfInterest: [],
            area: DOCUMENTS_AREA,
            part: REGISTRATION_PART,
          }),
        ),
      ]),
      uploadMedia(
        certificateImages.files.map((file) => ({
          stockNumber,
          file,
          part: formatPart(DOCUMENTS_AREA, CERTIFICATE_PART),
          source: 11,
        })),
      ).then((results) => [
        ...certificateImages.previouslyUploadedImages,
        ...results.map<VehicleImageInput>(
          ({
            data: {
              wkda: { url },
            },
            mimeType,
          }) => ({
            absoluteUrl: url,
            mimeType: mimeTypeToVehicleMimeType(mimeType) ?? 'JPEG',
            pointsOfInterest: [],
            area: DOCUMENTS_AREA,
            part: CERTIFICATE_PART,
          }),
        ),
      ]),
      uploadMedia(
        supportingDocuments.files.map((file) => ({
          stockNumber,
          file,
          part: formatPart(DOCUMENTS_AREA, SUPPORTING_DOCUMENTS_PART),
          source: 11,
        })),
      ).then((results) => {
        return [
          ...supportingDocuments.previouslyUploadedImages,
          ...results.map<VehicleImageInput>(
            ({
              data: {
                wkda: { url },
              },
              mimeType,
            }) => ({
              absoluteUrl: url,
              mimeType: mimeTypeToVehicleMimeType(mimeType) ?? 'JPEG',
              pointsOfInterest: [],
              area: DOCUMENTS_AREA,
              part: SUPPORTING_DOCUMENTS_PART,
            }),
          ),
        ];
      }),
    ]);

    const result = await mutate({
      variables: {
        ...variables,
        co2Emission:
          variables.co2Emission !== null ? Number(variables.co2Emission) : null,
        otherNote: variables.otherNote || 'emptynote',
        skipOtherNote: !variables.otherNote,
        carRegistrationImages:
          carRegistrationImagesToUpload as VehicleImageInput[],
        certificateImages: certificateImagesToUpload as VehicleImageInput[],
        supportingDocuments: !variables.skipElectricCar
          ? (supportingDocumentsToUpload as VehicleImageInput[])
          : [],
        totalOwnerCount:
          variables.totalOwnerCount !== null
            ? Number(variables.totalOwnerCount)
            : null,
      },
    });

    return result;
  };

  return [updateCarDetails, mutateResult] as const;
}

export { useCarDetailsUpdater };
