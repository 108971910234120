import * as React from 'react';
import { formatVinToAPI } from 'shared/utils/formatVin';

const useTrackingEvents = () => {
  const trackClickManualFlow = React.useCallback(
    ({
      vin: vinInternal,
      inspectionId: inspectionIdInternal,
      stockNumber: stockNumberInternal,
    }) => {
      window.tracking.eva.clickFillManually({
        page: 'carDetails',
        vin: formatVinToAPI(vinInternal),
        inspectionId: inspectionIdInternal,
        stockNumber: stockNumberInternal,
      });
    },
    [],
  );

  return {
    trackClickManualFlow,
  };
};

export { useTrackingEvents };
