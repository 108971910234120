import * as React from 'react';
import cn from 'classnames';

import { useTranslation } from 'shared/hooks/useTranslation';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import type { Translate } from 'shared/utils/translations';
import { LabelWithSubtitle } from './LabelWithSubtitle';
import type { Question } from '../types';

import styles from '../index.module.scss';

type Props = {
  qaIdPrefix: string;
  question: Question;
  onChange?: () => void;
  section?: string;
};

const toggleOptionGenerator = (
  options: Question['options'],
  getTranslation: Translate,
) => {
  return options?.map((option) => {
    return {
      label: getTranslation(`a1-inspection-app-${option.translationKey}`),
      value: option.id,
    };
  });
};

const ToggleButtonQuestion: React.FC<Props> = ({
  question,
  qaIdPrefix,
  onChange = () => {},
  section = '',
}) => {
  const { translate } = useTranslation();
  const name = `question[_${question.id}]`;

  return (
    <div
      data-qa-id={`${qaIdPrefix}-${question.translationKey}-options-wrapper`}
    >
      <FormToggleButton
        label={
          <LabelWithSubtitle
            showV2Label={section === 'findings'}
            translationKey={question.translationKey}
            withPrefix
          />
        }
        name={name}
        options={toggleOptionGenerator(question.options, translate)}
        className={styles.smallToggleButton}
        qaIdPrefix={`${qaIdPrefix}-${question.translationKey}`}
        defaultValue={null}
        beforeChange={onChange}
        containerClassName={
          section === 'findings'
            ? styles.findingsToggleButton
            : styles.toggleButton
        }
        errorClassName={cn({
          [styles.findingsToggleError]: section === 'findings',
        })}
      />
    </div>
  );
};

export { ToggleButtonQuestion };
