import * as React from 'react';
import cn from 'classnames';
import type { AccidentArea } from 'gql/graphql';
import kebabCase from 'lodash/kebabCase';
import { AddButton } from './AddButton';
import { EditButton } from './EditButton';
import { ElipsisButton } from './ElipsisButton';
import { DeleteButton } from './DeleteButton';
import { Variation } from '../constants';
import type { VariationType } from '../types';
import styles from './index.module.scss';

type Props = {
  area: AccidentArea;
  label: string;
  className?: string;
  variant: VariationType;
  onAdd: (area: AccidentArea) => void;
  onDelete: (area: AccidentArea) => void;
  qaIdPrefix?: string;
};

const AreaButton: React.FC<Props> = ({
  area,
  label,
  className = '',
  variant,
  onAdd,
  onDelete,
  qaIdPrefix = kebabCase(area),
}) => {
  return (
    <div
      className={cn(styles.areaButtonContainer, className)}
      data-qa-id={`${qaIdPrefix}-buttons`}
    >
      {variant === Variation.Default ? (
        <AddButton
          onClick={() => onAdd(area)}
          qaIdPrefix={`${qaIdPrefix}-add-button`}
        />
      ) : variant === Variation.Accident ? (
        <div className={styles.areaButtonEdit}>
          <DeleteButton
            onClick={() => onDelete(area)}
            qaIdPrefix={`${qaIdPrefix}-delete-button`}
          />
          <EditButton
            onClick={() => {
              onAdd(area);
            }}
            qaIdPrefix={`${qaIdPrefix}-edit-button`}
          />
        </div>
      ) : (
        <ElipsisButton
          onClick={() => {
            onAdd(area);
          }}
          qaIdPrefix={`${qaIdPrefix}-edit-button`}
        />
      )}
      <label className={styles.label} data-qa-id={`${qaIdPrefix}-label`}>
        {label}
      </label>
    </div>
  );
};

export { AreaButton };
