import * as React from 'react';
import { useProgress } from 'pages/CarPictures/hooks/useProgress';
import { useCarPicturesVehicleQuery } from 'gql/graphql';
import { mapDataToFormValues } from 'pages/CarPictures/helpers';
import { useInspection } from 'shared/components/InspectionContext';

function useCarPicturesProgress(inspectionId: number) {
  const { shouldUseNewAccidentModel } = useInspection();
  const { data } = useCarPicturesVehicleQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setCarPicturesProgress = useProgress();
  const updateProgress = React.useCallback(() => {
    setCarPicturesProgress(
      mapDataToFormValues(data?.inspection, shouldUseNewAccidentModel),
    );
  }, [data, setCarPicturesProgress]);

  React.useEffect(() => {
    setCarPicturesProgress(
      mapDataToFormValues(data?.inspection, shouldUseNewAccidentModel),
    );
  }, [data, setCarPicturesProgress]);

  return updateProgress;
}

export { useCarPicturesProgress };
