import * as React from 'react';

import cn from 'classnames';
import styles from './index.module.scss';

type PreDefinedCSSValues = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type PreDefinedCSSValuesWithZero = 0 | PreDefinedCSSValues;

type Props = {
  columns?: PreDefinedCSSValues;
  item?: boolean;
  component?: 'div' | 'form';
  qaIdPrefix?: string;
  className?: string;
  gutter?:
    | [PreDefinedCSSValuesWithZero, PreDefinedCSSValuesWithZero]
    | PreDefinedCSSValuesWithZero;
  span?: PreDefinedCSSValues;
};

const Grid: React.FC<Props> = ({
  item = false,
  component = 'div',
  qaIdPrefix = 'grid',
  children = null,
  className = '',
  gutter = [1, 0],
  columns = 1,
  span = 1,
}) => {
  const Component = React.useMemo(() => component, [component]);

  const qaId = React.useMemo(
    () => `${qaIdPrefix}-${item ? 'item' : 'container'}`,
    [item, qaIdPrefix],
  );
  const [xGutter, yGutter] = React.useMemo(() => {
    if (Array.isArray(gutter)) {
      return gutter;
    }
    return [gutter, gutter];
  }, [gutter]);

  return (
    <Component
      data-qa-id={qaId}
      className={cn(
        styles.root,
        {
          [styles[`xGutter${xGutter}`]]: !item,
          [styles[`yGutter${yGutter}`]]: !item,
          [styles[`columns${columns}`]]: Number.isInteger(columns),
          [styles.item]: item,
          [styles[`span${span}`]]: span,
        },
        className,
      )}
    >
      {children}
    </Component>
  );
};

export { Grid };
