import { useHistory } from 'react-router-dom';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { appRoutes } from 'appRoutes';
import { useSubmitToInstantPriceMutation } from 'gql/graphql';
import { useInspection } from 'shared/components/InspectionContext';
import { CheckoutTypeTypes, CheckoutTypeReason } from 'shared/constants';
import type { CheckoutTypeType, InsantOfferStatusesType } from '../types';
import { InstantOfferButtonStatus } from '../constants';

const useInstantOffer = () => {
  const history = useHistory();
  const { setNotCompletedSteps } = useInspection();
  const { submitTracking } = useSubmitTracking('summary');

  const getInstantOfferButtonStatus = (
    checkoutTypes: ReadonlyArray<CheckoutTypeType> = [],
  ): InsantOfferStatusesType => {
    const result =
      checkoutTypes.reduce<keyof typeof InstantOfferButtonStatus | null>(
        (acc, { enabled, type, reason }) => {
          if (acc) {
            return acc;
          }

          if (type === CheckoutTypeTypes.PRICING) {
            if (enabled) {
              return InstantOfferButtonStatus.ENABLED;
            }

            if (reason === CheckoutTypeReason.RISK_FREE) {
              return InstantOfferButtonStatus.DISABLED;
            }

            return InstantOfferButtonStatus.INVISIBLE;
          }

          return acc;
        },
        null,
      ) ?? InstantOfferButtonStatus.INVISIBLE;

    return {
      isInvisible: result === InstantOfferButtonStatus.INVISIBLE,
      isEnabled: result === InstantOfferButtonStatus.ENABLED,
      isDisabled: result === InstantOfferButtonStatus.DISABLED,
    };
  };

  const [submitAutoPrice, { loading, error }] = useSubmitToInstantPriceMutation(
    {
      onCompleted: () => {
        history.push(appRoutes.completed(true));
        submitTracking('getInstantOffer');
      },
      onError: (apolloError) => {
        const [gqlError] = apolloError.graphQLErrors;

        if (gqlError?.message === 'INCOMPLETE_INSPECTION') {
          setNotCompletedSteps([
            ...(gqlError.incompleteMutations?.WEB ?? []),
            ...(gqlError.missingMutations ?? []),
          ]);
        }
      },
    },
  );

  return {
    getInstantOfferButtonStatus,
    submitAutoPrice,
    error: error?.graphQLErrors?.[0],
    loading,
  };
};

export { useInstantOffer };
