import * as React from 'react';

declare global {
  interface Window {
    RumDevtools: {
      renderFloating: ({
        showKibanaLinks,
      }: {
        showKibanaLinks: boolean;
      }) => void;
    };
  }
}

const version = '0.2.2';
const fileSrc: string = `https://static.wirkaufendeinauto.de/libraries/rum-devtools/${version}`;

function useRumDevtools(isActive: boolean) {
  React.useEffect(() => {
    if (!isActive) {
      return;
    }

    const script = document.createElement('script');
    script.src = `${fileSrc}/standalone.global.js`;
    script.onload = () => {
      window.RumDevtools?.renderFloating({ showKibanaLinks: true });
    };
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', `${fileSrc}/main.css`);
    document.body.appendChild(link);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(link);
    };
  }, [isActive]);
}

export { useRumDevtools };
