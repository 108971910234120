import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { apm } from '@elastic/apm-rum';

import { ButtonThemes } from '@auto1-ui/button';
import { useCreateVideoMutation } from 'gql/graphql';
import type { VehicleImagePart } from 'gql/graphql';
import { useMediaUploader } from 'shared/utils/useMediaUploader';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import { uploadToPresignedUrl } from 'shared/utils/uploadToPresignedUrl';
import { useUploadPool } from 'shared/utils/uploadPool';

type Args = {
  name: string;
  sourceType: 'image' | 'video';
  mediaUrl: string;
  mediaData: { stockNumber: string; part: VehicleImagePart };
};

const useCarPictureItem = ({ name, sourceType, mediaUrl, mediaData }: Args) => {
  const [loading, setLoading] = React.useState(false);
  const { inspectionId, stockNumber } = useInspection();
  const ref = React.useRef<HTMLDivElement>(null);
  const { watch, setValue, setError } = useFormContext();

  const [isOpen, setIsOpen] = React.useState(false);
  const [isUploadFromReplace, setUploadFromReplace] = React.useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);

  const source = watch(name);

  const isVideo = React.useMemo(() => sourceType === 'video', [sourceType]);
  const { translations } = useTranslation();
  const { uploadPool } = useUploadPool();

  const [createVideo] = useCreateVideoMutation();

  React.useEffect(() => {
    setIsOpen(false);
    setIsOpenConfirmation(false);
  }, [source]);

  const handleDeleteConfirmation = () => {
    setValue(name, null, { shouldDirty: true });
    const input = ref.current?.getElementsByTagName('input');
    const size = input && input[0] ? input[0]?.files?.[0]?.size ?? 0 : 0;

    setIsOpen(false);
    setIsOpenConfirmation(false);
    window.tracking.eva[
      sourceType === 'video' ? 'deleteVideo' : 'deletePhoto'
    ]?.({
      area: name,
      size: size / 1024 / 1024,
      inspectionId,
      stockNumber,
    });
  };

  const handleCancelConfirmation = () => {
    setIsOpenConfirmation(false);
    setIsOpen(true);
  };

  const handleDelete = () => {
    setIsOpenConfirmation(true);
  };

  const handleTrackingEvents = React.useCallback(
    (file: File) => {
      const getTrackingEvent = () => {
        if (isUploadFromReplace) {
          return sourceType === 'video' ? 'replaceVideo' : 'replacePhoto';
        }

        return sourceType === 'video' ? 'uploadVideo' : 'uploadPhoto';
      };
      window.tracking.eva[getTrackingEvent()]?.({
        area: name,
        size: file.size / 1024 / 1024,
        inspectionId,
        stockNumber,
      });
    },
    [inspectionId, isUploadFromReplace, name, sourceType, stockNumber],
  );

  const { uploadMedia } = useMediaUploader(mediaUrl);

  const handleUploadMedia = React.useCallback(
    async (file) => {
      handleTrackingEvents(file);
      const fileUrl = URL.createObjectURL(file);
      setValue(name, fileUrl);
      try {
        setIsUploading(true);
        const {
          data: {
            wkda: { url },
          },
        } = await uploadPool.add(() =>
          uploadMedia({
            file,
            source: 11,
            ...mediaData,
          }),
        );

        setValue(name, url);
      } catch (e) {
        apm.captureError(e as Error);
        setValue(name, null);
        setError(name, { message: translations.GENERIC_ERROR_MESSAGE });
      } finally {
        setIsUploading(false);
      }
    },
    [mediaData, name, setValue, uploadMedia, handleTrackingEvents],
  );

  const handleUploadVideo = React.useCallback(
    async (file) => {
      setLoading(true);
      handleTrackingEvents(file);
      try {
        const fileUrl = URL.createObjectURL(file);
        setValue(name, fileUrl);
        const { data } = await createVideo({
          variables: {
            inspectionId,
            type: 'OVERVIEW_ENGINE_VIDEO',
          },
        });
        const getUrl = data?.createVideo?.getUrl;
        const preSignedUrl = data?.createVideo?.preSignedUrl;
        await uploadToPresignedUrl(file, preSignedUrl ?? '');
        setValue(name, getUrl);
      } catch (e) {
        apm.captureError(e as Error);
        console.info(e);
        setValue(name, null);
      } finally {
        setLoading(false);
      }
    },
    [createVideo, handleTrackingEvents, inspectionId, name, setValue],
  );

  React.useEffect(() => {
    if (!(source instanceof File)) return;
    if (isVideo) {
      handleUploadVideo(source);
    } else {
      handleUploadMedia(source);
    }
  }, [handleUploadMedia, handleUploadVideo, isVideo, source]);

  const handleReplace = () => {
    setUploadFromReplace(true);
    // todo: @richard.sandoval add forwardRef in MediaUploader component
    if (!ref.current) return;
    const inputs = ref.current.getElementsByTagName('input');

    if (!inputs.length) return;
    const [input] = inputs;

    input.click();
  };

  const buttons = [
    {
      label: translations.REPLACE,
      theme: ButtonThemes.outlined,
      alignment: 'left',
      onClick: handleReplace,
    },
    {
      label: translations.DELETE,
      theme: ButtonThemes.outlined,
      alignment: 'right',
      onClick: handleDelete,
    },
  ] as const;

  return {
    ref,
    isOpen,
    source,
    isVideo,
    buttons,
    setIsOpen,
    isUploading: isUploading || loading,
    isOpenConfirmation,
    handleDeleteConfirmation,
    handleCancelConfirmation,
  };
};

export { useCarPictureItem };
