import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { appRoutes, getLanguage } from 'appRoutes';
import { resetApolloCache } from 'config/apolloCache';
import GoogleAuth from 'auth/components/GoogleAuth';
import { Card } from 'shared/components/Card';
import { setUserIdToRootElement } from 'shared/utils/tracking';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useLoginWithGoogleCodeMutation } from 'gql/graphql';
import styles from './index.module.scss';

type Props = {
  qaPrefix?: string;
};

const LoginPage: React.FC<Props> = ({ qaPrefix = 'login-page' }) => {
  const [googleFailureMessage, setGoogleFailureMessage] = React.useState('');

  const [isNewLangLoaded, setNewLanguage] = React.useState(false);

  const { translations } = useTranslation();

  const [loginWithGoogleCode, { ...loginWithGoogleCodeOptions }] =
    useLoginWithGoogleCodeMutation({
      onCompleted: (data) => {
        if (data?.loginWithGoogleCode?.success) {
          resetApolloCache();
        }
      },
    });

  if (loginWithGoogleCodeOptions?.data?.loginWithGoogleCode?.success) {
    const { userLocale: locale, userUuid } =
      loginWithGoogleCodeOptions?.data?.loginWithGoogleCode?.data ?? {};
    setUserIdToRootElement(userUuid!);

    if (!locale && !isNewLangLoaded) {
      return <Redirect to={appRoutes.inspectionList()} />;
    }
    if (locale === getLanguage() && !isNewLangLoaded) {
      return <Redirect to={appRoutes.inspectionList()} />;
    }
    if (locale !== getLanguage() && !isNewLangLoaded) {
      setNewLanguage(true);
      window.location.replace(`/${locale}/inspectionList`);
    }
  }

  const loginGoogleError =
    loginWithGoogleCodeOptions?.data?.loginWithGoogleCode?.errorMessage ??
    googleFailureMessage;

  return (
    <Card externalStyle={styles.carInformationCard}>
      <Typography
        variant="titleXL"
        tag="div"
        data-qa-id={`${qaPrefix}-heading`}
        additonalClassNames={styles.heading}
      >
        {translations.LOGIN_TITLE}
      </Typography>
      <Typography
        variant="textSmall"
        tag="div"
        data-qa-id={`${qaPrefix}-subheading`}
        additonalClassNames={styles.subheading}
      >
        {translations.LOGIN_SUBTITLE}
      </Typography>
      <GoogleAuth
        onGoogleResponseSuccess={({ code }) =>
          loginWithGoogleCode({
            variables: { authCode: code },
          })
        }
        onGoogleResponseFailure={() => {
          // Check error codes in
          // https://developers.google.com/identity/sign-in/web/reference
          setGoogleFailureMessage('Error happened with google login');
        }}
      />
      <Typography additonalClassNames={styles.error} variant="textXSRed">
        {loginGoogleError}
      </Typography>
    </Card>
  );
};

export { LoginPage };
