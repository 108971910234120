import * as React from 'react';
import { useAutoPriceQuery } from 'gql/graphql';
import type { AutoPriceQuery } from 'gql/graphql';

const REFETCH_MAX_ATTEMPT = 5;
const REFETCH_DELAY = 2_000; // in ms

const useAutoPrice = ({ inspectionId }: { inspectionId: number }) => {
  const [refetchCount, setRefetchCount] = React.useState(1);
  const [autoPriceData, setAutoPriceData] = React.useState<{
    loading: boolean;
    data?: AutoPriceQuery;
  }>({
    loading: false,
  });
  const { loading, data, refetch } = useAutoPriceQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (loading) {
      return undefined;
    }

    if (
      data?.getAutoPrice.status === 'RUNNING' &&
      refetchCount < REFETCH_MAX_ATTEMPT
    ) {
      setAutoPriceData({ loading, data });
      const timeoutId = setTimeout(() => {
        setRefetchCount((count) => count + 1);
        refetch();
      }, REFETCH_DELAY);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (data?.getAutoPrice.status === 'FINISHED') {
      setAutoPriceData({ loading, data });
      return undefined;
    }

    setAutoPriceData({
      loading,
      data: {
        getAutoPrice: {
          status: 'FINISHED',
          priceInMinorUnits: null,
        },
      },
    });

    return undefined;
  }, [data, loading, refetch, refetchCount]);

  return autoPriceData;
};

export { useAutoPrice };
