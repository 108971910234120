import * as React from 'react';
import debounce from 'lodash/debounce';
import { useGetStartPriceLazyQuery } from 'gql/graphql';
import type {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormSetError,
} from 'react-hook-form';
import type { Translations } from 'shared/utils/translations';
import type { SubmitAuctionForm } from '../types';

const MIN_LENGTH_SEARCH = 3;

type Params = {
  inspectionId: number,
  expectedPriceValue: number | null,
  startPriceValue: number | null,
  clearErrors: UseFormClearErrors<SubmitAuctionForm>,
  getValues: UseFormGetValues<SubmitAuctionForm>,
  setValue: UseFormSetValue<SubmitAuctionForm>,
  setError: UseFormSetError<SubmitAuctionForm>,
  translations: Translations,
}

const useStartPrice = (
  {
    inspectionId,
    expectedPriceValue,
    startPriceValue,
    clearErrors,
    getValues,
    setValue,
    setError,
    translations,
  }: Params,
) => {

  const [getStartPrice, { loading, refetch }] = useGetStartPriceLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      const amount = result.getStartPrice?.amount ? result.getStartPrice?.amount / 100 : null;

      setValue('suggestedStartPrice', getValues().startPrice ?? amount)
      setValue('startPrice', amount);
    },
    onError: () => {
      setError('startPrice', {
        type: 'custom',
        message: translations.START_PRICE_ERROR_MESSAGE_SERVER_FAIL,
      });
    },
  });

  const getStartPriceAmount = (expectedPrice?: number | null) => {
    if (!expectedPrice) {
      return undefined;
    }
    if (expectedPrice.toString().length >= MIN_LENGTH_SEARCH) {
      clearErrors();
      getStartPrice({
        variables: {
          inspectionId,
          expectedPrice: (expectedPrice as number) * 100,
        },
      });
    }
  }

  React.useEffect(() => {
    if (loading || !expectedPriceValue) {
      setValue('startPrice', null);
    }
  }, [loading, expectedPriceValue]);

  React.useEffect(() => {
    clearErrors();
  }, [startPriceValue]);

  React.useEffect(() => {
    if (!expectedPriceValue) {
      return undefined;
    }
    getStartPrice({
      variables: {
        inspectionId,
        expectedPrice: (expectedPriceValue as number) * 100,
      },
    });
  }, []);

  const debouncedResults = React.useCallback(
    debounce(getStartPriceAmount, 700),
    [],
  );

  React.useEffect(() => {
    debouncedResults(expectedPriceValue);
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults, expectedPriceValue]);

  const handleRetry = () => {
    clearErrors();
    refetch && refetch();
  }

  return { loading, handleRetry };
};

export { useStartPrice };
