import * as React from 'react';
import kebabCase from 'lodash/kebabCase';

import { Card } from 'shared/components/Card';
import { Grid } from 'shared/components/Grid';
import { Typography } from 'shared/components/Typography';
import { AlertBar } from 'shared/components/AlertBar';

import { useTranslation } from 'shared/hooks/useTranslation';

import { EquipmentSelector } from './EquipmentSelector';
import { EquipmentFields } from '../types'
import styles from '../index.module.scss';

type Props = {
  equipments: Array<EquipmentFields>;
  qaIdPrefix: string;
};

const Equipment: React.FC<Props> = ({ equipments, qaIdPrefix }) => {
  const { translations } = useTranslation();
  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      externalStyle={styles.card}
      id="equipment"
      qaIdPrefix={`${qaIdPrefix}-equipment-container`}
      showBottomBorder
    >
      <Typography
        variant="titleXL"
        tag="div"
        data-qa-id={`${qaIdPrefix}-equipment-title`}
        additonalClassNames={styles.title}
      >
        {translations.EQUIPMENT_TITLE}
      </Typography>

      {equipments.length === 0 ? (
        <AlertBar
          message={translations.EQUIPMENTS_CANNOT_BE_SHOWN}
          type="info"
        />
      ) : (
          <Grid
            gutter={[2, 0]}
            columns={2}
            qaIdPrefix={`${qaIdPrefix}-equipment-fields`}
          >
            {equipments.map((equipment, index) => (
              <Grid
                item
                key={equipment.translationKey}
                qaIdPrefix={`${qaIdPrefix}-equipment-fields-${kebabCase(
                  equipment.translationKey,
                )}`}
                className={styles.gridItem}
              >
                <EquipmentSelector
                  {...equipment}
                  index={index}
                  qaIdPrefix={`${qaIdPrefix}-equipment-fields-${kebabCase(
                    equipment.translationKey,
                  )}`}
                />
              </Grid>
            ))}
          </Grid>
        )}
    </Card>
  );
};

export { Equipment };
