import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Accidents } from 'pages/Accidents';
import { Damages } from 'pages/Damages';
import { CarQuality as CarQualityComponent } from './CarQuality';

const CarQuality: React.FC = (props) => {
  const location = useLocation();

  switch (location.hash) {
    case '#accidents':
      return <Accidents {...props} />;
    case '#damages':
      return <Damages {...props} />;
    default:
      return <CarQualityComponent {...props} />;
  }
};

export { CarQuality };
