import * as yup from 'yup';
import parse from 'date-fns/parse';
import compareAsc from 'date-fns/compareAsc';
import { requiredString } from 'shared/utils/yupValidationHelpers';
import type { Translations } from 'shared/utils/translations';

const DOT_PATTERN = /((0[1-9])|([1-4][0-9])|(5[0-2]))([0-9]{2})/g;

const parseDotDate = (dotDate: string) => {
  const week = dotDate.slice(0, 2);
  const year = `20${dotDate.slice(2)}`;
  return parse(`${week}${year}`, 'IIRRRR', Date.now());
};

const requiredStringNullable = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  return requiredString(optionalSchema, translations).nullable();
};

const creatErr = (ref: yup.TestContext, message: string) => {
  return ref.createError({
    message,
  });
};

const validateDot = (
  ref: yup.TestContext,
  value: string | undefined,
  translations: Translations,
  required: boolean,
) => {
  if (required) {
    if (!value) return creatErr(ref, translations.THIS_FIELD_CANNOT_BE_EMPTY);

    if (value.length !== 4)
      return creatErr(ref, translations.INVALID_DOT_LENGTH);

    if (compareAsc(parseDotDate(value), Date.now()) > 0) {
      return creatErr(ref, translations.NOT_IN_FUTURE);
    }

    if (!value.match(DOT_PATTERN)) return false;
  }

  if (value && value.length !== 4)
    return creatErr(ref, translations.INVALID_DOT_LENGTH);

  if (value && compareAsc(parseDotDate(value), Date.now()) > 0) {
    return creatErr(ref, translations.NOT_IN_FUTURE);
  }

  if (value && !value.match(DOT_PATTERN)) return false;

  return true;
};

const VehicleExteriorWheelDetailInput = (
  translations: Translations,
  optionalSchema: boolean = false,
) => {
  return yup.object().shape({
    profileDepth: requiredString(optionalSchema, translations),
    area: requiredString(optionalSchema, translations),
    rimType: requiredStringNullable(optionalSchema, translations),
    tireType: requiredStringNullable(optionalSchema, translations),
    brakesCondition: yup.string().when('isPrimary', {
      is: true,
      then: requiredStringNullable(optionalSchema, translations),
      otherwise: yup.string().nullable(),
    }),
    dotNumber: yup
      .string()
      .test(
        'required',
        translations.INVALID_DOT_FORMAT,
        function testDot(value) {
          const notAvailableRef = yup.ref<boolean>('notAvailable');
          const isPrimaryRef = yup.ref<boolean>('isPrimary');

          if (this.resolve(notAvailableRef)) {
            return true;
          }

          if (this.resolve(isPrimaryRef)) {
            return validateDot(this, value, translations, !optionalSchema);
          }

          if (value) {
            return validateDot(this, value, translations, false);
          }

          return true;
        },
      )
      .nullable(),
    isPrimary: yup.boolean().default(true).nullable(),
    notAvailable: yup.boolean().default(false).nullable(),
  });
};

const TiresBrakesSchema = (
  translations: Translations,
  optionalSchema: boolean,
) => {
  return yup.object({
    primaryWheels: yup
      .array()
      .of(VehicleExteriorWheelDetailInput(translations, optionalSchema))
      .default([]),
    additionalWheels: yup
      .array()
      .of(VehicleExteriorWheelDetailInput(translations, optionalSchema))
      .default([]),
  });
};

export { TiresBrakesSchema, VehicleExteriorWheelDetailInput };
