import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';

import styles from './index.module.scss';

const NavigationFooterPlaceholder = () => (
  <div
    className={styles.navigationFooter}
    data-qa-id="navigation-footer-wrapper-loader"
  >
    <div className={styles.container}>
      <div className={styles.content}>
        <div style={{ alignSelf: 'center', flexGrow: '1' }}>
          <Skeleton width="27px" height="17px" />
        </div>
        <Skeleton width="174px" height="40px" style={{ alignSelf: 'center' }} />
        <Skeleton
          width="174px"
          height="40px"
          style={{ marginLeft: '32px', alignSelf: 'center' }}
        />
      </div>
    </div>
  </div>
);

export { NavigationFooterPlaceholder };
