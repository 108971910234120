import React from 'react';
import { useLocation } from 'react-router-dom';

import differenceInSeconds from 'date-fns/differenceInSeconds';
import { useInspection } from 'shared/components/InspectionContext';
import { pages, sections } from 'shared/utils/locationToSectionsMap';

type Location<T> = ReturnType<typeof useLocation<T>>;

const sectionName = (hash: string) => {
  if (hash) {
    const sectionKey = hash.slice(1);
    return sectionKey in sections
      ? sections[sectionKey as keyof typeof sections]
      : '';
  }
  return '';
};

const pageName = (path: string) => {
  if (path) {
    const pathArray = path.split('/');
    const pageKey = pathArray[pathArray.indexOf('inspection') + 1];
    return pageKey in pages ? pages[pageKey as keyof typeof pages] : '';
  }
  return '';
};

function storePageStartTime(start: number) {
  localStorage.setItem('tracking.pageStartTime', start.toString());
}

function getLastPageStart() {
  const pageStartTime = localStorage.getItem('tracking.pageStartTime');
  return Number(pageStartTime);
}

const useSideMenuNavigationTracking = (from?: {
      pathname: string;
      hash: string;
    }, to?: Location<{ sideMenuNavigation?: boolean }>) => {
  const { inspectionId, stockNumber } = useInspection();
  const [pageStartTime, setPageStartTime] = React.useState(getLastPageStart());

  const sideMenuNavigationTracking = React.useCallback(() => {
    setPageStartTime(getLastPageStart());
    if (
      to &&
      from &&
      to.state.sideMenuNavigation &&
      !Number.isNaN(pageStartTime)
    ) {
      window.tracking.eva.menuNavigation({
        pageFrom: pageName(from.pathname),
        pageTo: pageName(to.pathname),
        sectionFrom: sectionName(from.hash),
        sectionTo: sectionName(to.hash),
        inspectionId,
        stockNumber,
        duration: differenceInSeconds(Date.now(), pageStartTime),
      });
    }
  }, [to]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (Number.isNaN(getLastPageStart())) {
      storePageStartTime(Date.now());
      setPageStartTime(getLastPageStart());
    }
    if (to?.pathname !== from?.pathname) {
      setPageStartTime(getLastPageStart());
      storePageStartTime(Date.now());
    }
  }, [to, from]);

  React.useEffect(() => {
    sideMenuNavigationTracking();
  }, [to, sideMenuNavigationTracking]);
};

export { useSideMenuNavigationTracking };
