import omit from 'lodash/omit';
import type { DamagesInitialDataQuery } from 'gql/graphql';
import type { Form, PaintThickness, PaintThicknessDataType } from './types';

const getPaintThicknessData = (inspection: DamagesInitialDataQuery['inspection'],) => {
  return {
    isPaintThicknessChecked: inspection?.flags?.isPaintThicknessChecked,
    paintThicknesses: inspection?.vehicle?.condition?.paintThicknesses ?? [],
    threshold: inspection?.fields?.vehicle?.damageAutoCreation?.paintThicknessTooHigh?.threshold,
  };
}

const isPaintThicknessSkipped = (paintThicknessData?: PaintThicknessDataType) => {
  if (!paintThicknessData) {
    return true;
  }

  const { threshold, isPaintThicknessChecked, paintThicknesses } = paintThicknessData;
  if (!threshold) {
    return true;
  }

  if (isPaintThicknessChecked) {
    return !paintThicknesses.some(paintThickness => paintThickness?.value != null && paintThickness?.value >= threshold);
  }

  return true;
};

const mapDataToForm = (
  inspection: DamagesInitialDataQuery['inspection'],
) => {
  const vehicleDamages = (inspection?.vehicle?.damages ?? [])
    .map((damage) => ({
      area: damage.area ?? null,
      part: damage.part ?? null,
      type: damage.type ?? null,
      files: damage.images ?? [],
      isAccidentReason: !!damage.isAccidentReason,
      source: damage.meta?.source ?? null,
    }));

  const paintThicknesses = inspection?.vehicle?.condition?.paintThicknesses as PaintThickness[] | null | undefined;
  const { source, threshold, damageMapping } = inspection?.fields?.vehicle?.damageAutoCreation?.paintThicknessTooHigh ?? {};

  // Return non-auto-created damages if paint thickness data is unavailable
  if (!paintThicknesses?.length) {
    return vehicleDamages.filter(({ source: damageSource }) => {
      return damageSource !== source;
    });
  }

  const damagesFromPaintThickness = paintThicknesses.reduce((acc, paintThickness) => {
    const { area, value } = paintThickness ?? {};
    if (!value || !threshold || value < threshold) {
      return acc;
    }

    const damageFromPaintThickness = damageMapping?.find(
      ({ paintThicknessArea }) => paintThicknessArea === area,
    );

    if (!damageFromPaintThickness) {
      return acc;
    }

    return [...acc, {
      area: damageFromPaintThickness?.carArea ?? null,
      part: damageFromPaintThickness?.carPart ?? null,
      type: damageFromPaintThickness?.damageType ?? null,
      files: [],
      isAccidentReason: false,
      source: source ?? null,
    }];
  }, [] as Form['damages']);

  if (!vehicleDamages || vehicleDamages.length === 0) {
    return damagesFromPaintThickness;
  }

  const finalDamages = [] as Form['damages'];
  const existingDamages = new Set<Form['damages'][number]>();
  vehicleDamages.forEach((damage) => {
    if (damage.source === null) {
      finalDamages.push(damage);
    } else {
      const matchingDamage = damagesFromPaintThickness.find(
        ptDamage => damage.area === ptDamage.area && damage.part === ptDamage.part && damage.source === ptDamage.source
      );

      if (matchingDamage) {
        finalDamages.push(damage);
        existingDamages.add(matchingDamage);
      }
    }
  });
  const newDamages = damagesFromPaintThickness.filter(
    ptDamage => !Array.from(existingDamages).some(
      ({ area, part }) => area === ptDamage.area && part === ptDamage.part,
    )
  );
  return [...finalDamages, ...newDamages];
}

const mapDamagesToMutation = (data: Form) => {
  const { damages } = data;

  return damages.map(({ source, ...damage }) => ({
    ...omit(damage, '__typename'),
    meta: {
      source,
    },
    files: damage.files.map((image) =>
      image instanceof File ? image : omit(image, '__typename'),
    ),
  }));
}

export {
  getPaintThicknessData,
  mapDataToForm,
  mapDamagesToMutation,
  isPaintThicknessSkipped,
};
