import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import type { FieldErrors } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';

import { Button, ButtonTypes } from '@auto1-ui/button';

import { appRoutes } from 'appRoutes';
import Loader from 'shared/components/Loader';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { Typography } from 'shared/components/Typography';
import { Card } from 'shared/components/Card';
import { useSideMenuNavigationTracking } from 'shared/hooks/useSideMenuNavigationTracking';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { useInspection } from 'shared/components/InspectionContext';
import { isElectricCar } from 'shared/constants';
import { filterNull } from 'shared/utils/filterNull';

import { FailureReasons } from './components/FailureReasons';
import { CarPictures } from './components/CarPictures';
import { Details } from './components/Details';
import { useSummary } from './hooks/useSummary';
import { useAutoPrice } from './hooks/useAutoPrice';
import { useInstantOffer } from './hooks/useInstantOffer';
import { useAuctionSelector } from './hooks/useAuctionSelector';
import { getCarDetails, getConditional, getService } from './options';
import styles from './index.module.scss';
import { AuctionModal } from './components/AuctionModal';
import { DirectAuctionModal } from './components/DirectAuctionModal';
import { Placeholder } from './Placeholder';
import { useBlockingVin } from './hooks/useBlockingVin';
import { InstantOfferButton } from './components/InstantOfferButton';
import { Terms } from './components/Terms';
import { SubmitToAuctionModal, AuctionSelectorPage } from './constants';
import { ChooseAuction } from './components/ChooseAuction';
import { ChooseSellingChannel } from './components/ChooseSellingChannel';
import { BottomAreaContent } from './components/BottomAreaContent';
import { WarningModal } from './components/WarningModal';
import type { Form, CheckoutTypeType } from './types';

type Props = {
  qaIdPrefix?: string;
};

const Summary: React.FC<Props> = ({ qaIdPrefix = 'summary' }) => {
  const location =
    useLocation<{
      prev: { pathname: string; hash: string };
      sideMenuNavigation?: boolean;
    }>();
  const { inspectionId } = useInspection();
  const { translations } = useTranslation();
  const { submitTracking } = useSubmitTracking('summary');
  const termsRef = React.useRef<HTMLDivElement>(null);
  const failureReasonsRef = React.useRef<HTMLDivElement>(null);
  const [bottomErrorMessage, setBottomErrorMessage] = React.useState('');

  const validationSchema = yup.object({
    termsAccepted: yup.bool().oneOf([true], translations.PLEASE_ACCEPT_TERMS),
    failedReasons: yup
      .mixed()
      .test(
        'allChecked',
        translations.SHOULD_SELECT_ALL_FAILURE_REASONS,
        (value = {}) => {
          return Object.values(value).every((item) => item === true);
        },
      ),
  });

  const formHandlers = useForm<Form>({
    resolver: yupResolver(validationSchema),
  });
  const { data, loading } = useSummary({
    inspectionId,
    onCompleted: (result) => {
      formHandlers.reset({
        termsAccepted: true,
        failedReasons:
          result?.inspection?.meta?.verification?.failedReasons?.reduce(
            (acc, curr) => ({ ...acc, [curr.id]: false }),
            {},
          ) ?? {},
      });
    },
  });
  useSideMenuNavigationTracking(
    location.state && location.state.prev,
    location,
  );

  const checkoutTypes = data?.msiGetCheckoutTypes.filter(filterNull) ?? [];

  const {
    getAuctionSelectorPage,
    showAuctionSelectorPage,
    setShowAuctionSelectorPage,
    isFullServiceAllowed,
    isBatchAuctionEnabled,
  } = useAuctionSelector();

  React.useEffect(() => {
    // If we are in choose auction page
    if (showAuctionSelectorPage) {
      setShowAuctionSelectorPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const fuelType = data?.inspection?.vehicle?.engine?.fuelType;
  const carDetails = React.useMemo(
    () => getCarDetails(translations, isElectricCar(fuelType)),
    [translations, fuelType],
  );
  const conditional = React.useMemo(
    () => getConditional(translations),
    [translations],
  );
  const service = React.useMemo(() => getService(translations), [translations]);
  const { data: autoPriceData } = useAutoPrice({
    inspectionId,
  });
  const {
    getInstantOfferButtonStatus,
    submitAutoPrice,
    error: instantOfferSubmitError,
    loading: submittingToInstantOffer,
  } = useInstantOffer();
  const instantOfferButtonStatus = getInstantOfferButtonStatus(checkoutTypes);
  const auctionSelector = getAuctionSelectorPage({ checkoutTypes, instantOfferButtonStatus });

  const isFailedInspection = data?.inspection?.status === 'FAILED';
  const failedReasons =
    data?.inspection?.meta?.verification?.failedReasons ?? [];

  const { handleSubmit, getValues } = formHandlers;

  const {
    openedModal,
    setOpenedModal,
    isCheckingBeforeSubmit,
    checkBeforeSubmitToFullService,
    checkBeforeSubmitToDirectAuction,
    checkBeforeSubmitToBatchAuction,
    warningModalProps,
  } = useBlockingVin(data?.inspection?.car?.stockNumber ?? '');

  if (loading || !data) {
    return <Placeholder />;
  }

  const scrollToError = ({
    failedReasons: failureReasons,
    termsAccepted,
  }: FieldErrors<Form>) => {
    if (failureReasons && failureReasonsRef.current) {
      failureReasonsRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (termsAccepted && termsRef.current) {
      termsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onSendToAuction = () => {
    if (auctionSelector !== null) {
      setShowAuctionSelectorPage(true);
      return;
    }

    if (isFullServiceAllowed(checkoutTypes)) {
      checkBeforeSubmitToFullService({
        variables: {
          inspectionId: Number(inspectionId),
        },
      });
      return;
    }

    setBottomErrorMessage(translations.CHECKOUT_TYPE_ERROR);
  };

  const handleGetInstantOffer = () => {
    handleSubmit(async ({ termsAccepted }) => {
      submitAutoPrice({
        variables: {
          inspectionId: inspectionId as number,
          termsAccepted,
        },
      });
    }, scrollToError)();
  };

  const renderAuctionSelector = (
    checkoutTypes: ReadonlyArray<CheckoutTypeType> = [],
  ) => {
    if (auctionSelector === AuctionSelectorPage.SellingChannelPage) {
      return (
        <ChooseSellingChannel
          qaIdPrefix={`${qaIdPrefix}-choose-selling-channel`}
          setSellingChannel={(selectedValue) => {
            switch (selectedValue) {
              case 'instant-offer':
                handleGetInstantOffer();
                break;
              case 'remarketing-24h-auction':
                setBottomErrorMessage('');
                checkBeforeSubmitToFullService({
                  variables: {
                    inspectionId: Number(inspectionId),
                  },
                });
                break;
              case 'batch-auction':
                setBottomErrorMessage('');
                checkBeforeSubmitToBatchAuction({
                  variables: {
                    inspectionId: Number(inspectionId),
                  },
                });
                break;
              default:
                break;
            }
          }}
          isBatchAuctionEnabled={isBatchAuctionEnabled(checkoutTypes)}
          instantOfferButtonStatus={instantOfferButtonStatus}
          instantOfferSubmitError={instantOfferSubmitError}
          bottomErrorMessage={bottomErrorMessage}
        />
      );
    }
    if (auctionSelector === AuctionSelectorPage.ChooseAuctionPage) {
      return (
        <ChooseAuction
          qaIdPrefix={`${qaIdPrefix}-choose-auction`}
          isCheckingBeforeSubmit={isCheckingBeforeSubmit}
          onFullServiceClick={() => {
            checkBeforeSubmitToFullService({
              variables: {
                inspectionId: Number(inspectionId),
              },
            });
          }}
          onDirectAuctionClick={() => {
            checkBeforeSubmitToDirectAuction({
              variables: {
                inspectionId: Number(inspectionId),
              },
            });
          }}
        />
      );
    }
  };

  const isAuctionOpened =
    openedModal !== null &&
    openedModal !== SubmitToAuctionModal.DirectAuction &&
    (
      [
        SubmitToAuctionModal.FullService,
        SubmitToAuctionModal.BatchAuction,
      ] as ReadonlyArray<keyof typeof SubmitToAuctionModal>
    ).includes(openedModal);

  return (
    <FormProvider {...formHandlers}>
      <LayoutContainer qaIdPrefix="summary">
        {showAuctionSelectorPage ? (
          renderAuctionSelector(checkoutTypes)
        ) : (
            <>
              {isFailedInspection && failedReasons && (
                <FailureReasons reasons={failedReasons} ref={failureReasonsRef} />
              )}
              <Card
                paddingTop={32}
                paddingBottom={32}
                qaIdPrefix={`${qaIdPrefix}-container`}
              >
                <Typography
                  data-qa-id={`${qaIdPrefix}-title`}
                  variant="titleXL"
                  tag="div"
                  additonalClassNames={styles.title}
                >
                  {translations.SUMMARY}
                </Typography>
                <CarPictures
                  qaIdPrefix={`${qaIdPrefix}-car-pictures`}
                  data={data}
                />
                <Details
                  className={styles.details}
                  data={data}
                  qaIdPrefix={`${qaIdPrefix}-car-details`}
                  title={translations.CAR_DETAILS_TITLE}
                  items={carDetails}
                />
                <Details
                  className={styles.details}
                  data={data}
                  qaIdPrefix={`${qaIdPrefix}-conditional-and-technical-details`}
                  title={translations.CONDITIONAL_AND_TECHNICAL_DETAILS_TITLE}
                  items={conditional}
                />
                <Details
                  className={styles.details}
                  data={data}
                  qaIdPrefix={`${qaIdPrefix}-service-history`}
                  title={translations.SERVICE_HISTORY_TITLE}
                  items={service}
                />
                <Terms
                  ref={termsRef}
                  qaIdPrefix={qaIdPrefix}
                  country={
                    data?.userMerchant?.country ||
                    data?.inspection?.branch?.country ||
                    ''
                  }
                />
              </Card>

              <NavigationFooter
                backButtonLink={appRoutes.serviceDocuments(
                  `${inspectionId}`,
                  true,
                )}
                qaPrefix={qaIdPrefix}
                rightAreaContent={
                  <>
                    {auctionSelector !== AuctionSelectorPage.SellingChannelPage ? (
                      <InstantOfferButton
                        onClick={handleGetInstantOffer}
                        submitting={submittingToInstantOffer}
                        instantOfferButtonStatus={instantOfferButtonStatus}
                      />
                    ) : null}

                    <Button
                      type={ButtonTypes.button}
                      theme="orange"
                      extraClass={styles.nextButton}
                      qaId={`${qaIdPrefix}-footer-submit-link`}
                      isActivated={false}
                      isDisabled={
                        isCheckingBeforeSubmit || Boolean(bottomErrorMessage)
                      }
                      onClick={handleSubmit(onSendToAuction, (error) => {
                        scrollToError(error);
                      })}
                    >
                      {translations.SEND_TO_24H_AUCTION}
                      {isCheckingBeforeSubmit && (
                        <Loader extraClass={styles.buttonLoader} />
                      )}
                    </Button>
                  </>
                }
                bottomAreaContent={
                  <BottomAreaContent
                    instantOfferSubmitError={instantOfferSubmitError}
                    bottomErrorMessage={bottomErrorMessage}
                  />
                }
              />
            </>
          )}

        {warningModalProps.isOpen && <WarningModal {...warningModalProps} />}

        {isAuctionOpened && (
          <AuctionModal
            selectedAuctionModal={openedModal}
            submitTracking={submitTracking}
            handleCancelClick={() => setOpenedModal(null)}
            piPrice={
              data?.inspection?.meta?.evaluation?.priceIndicatorPrice ?? null
            }
            autoPrice={autoPriceData?.getAutoPrice}
            expectedPrice={data?.inspection?.meta?.evaluation?.expectedPrice}
            auctionType={data?.getAuctionOptions?.[0]}
            termsAccepted={getValues().termsAccepted}
            failedReasons={Object.keys(getValues().failedReasons ?? []).map(
              Number,
            )}
            carInfo={{
              lastChangedTime: data?.inspection?.lastChangedTime,
              car: data?.inspection?.car ?? {},
              vehicle: data?.inspection?.vehicle ?? {},
            }}
          />
        )}

        {openedModal === SubmitToAuctionModal.DirectAuction && (
          <DirectAuctionModal
            inspectionId={inspectionId}
            handleCancelClick={() => setOpenedModal(null)}
            termsAccepted={getValues().termsAccepted}
            failedReasons={Object.keys(getValues().failedReasons ?? []).map(
              Number,
            )}
            submitTracking={submitTracking}
          />
        )}
      </LayoutContainer>
    </FormProvider>
  );
};

const SummaryWithTransaction = withTransaction('Summary', 'component')(Summary);

export { SummaryWithTransaction as Summary };
