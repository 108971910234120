import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormCheckbox } from 'shared/components/FormCheckbox';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';

import { LabelWithSubtitle } from './LabelWithSubtitle';
import type { Question } from '../types';
import styles from '../index.module.scss';

type Props = {
  isPerformedNo?: boolean;
  qaIdPrefix: string;
  question: Question;
  onChange?: () => void;
  section?: string;
};

const CheckboxQuestion: React.FC<Props> = ({
  question,
  qaIdPrefix,
  isPerformedNo = false,
  onChange = () => {},
  section = '',
}) => {
  const name = `question[_${question.id}]`; // Add underscore so the form handler doesn't see it as an array
  const { translate } = useTranslation();
  const {
    register,
    unregister,
    trigger,
    formState: { errors, isSubmitted },
  } = useFormContext();

  React.useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [name, register, unregister]);

  const error = errors?.question?.[`_${question.id}`];

  const translationKey =
    (isPerformedNo
      ? `a1-inspection-app-${question.translationKey}.question`
      : question.translationKey) ?? '';

  return (
    <div
      className={styles.grid}
      data-qa-id={`${qaIdPrefix}-${question.translationKey}-options-wrapper`}
    >
      <Typography
        tag="div"
        variant="textSmall"
        data-qa-id={`${qaIdPrefix}-title`}
        additonalClassNames={styles.itemTitle}
      >
        <LabelWithSubtitle
          showV2Label={section === 'findings'}
          translationKey={translationKey}
        />
      </Typography>
      {question.options?.map((option) => {
        return (
          <FormCheckbox
            key={option.id}
            name={`question[_${option.id}]`}
            uiLabel={translate(`a1-inspection-app-${option.translationKey}`)}
            containerClassName={styles.checkBoxLabel}
            qaIdPrefix={`${qaIdPrefix}-${option.translationKey}`}
            defaultValue={false}
            beforeChange={onChange}
            afterChange={() => {
              if (isSubmitted) {
                trigger(`question[_${question.id}]`);
              }
            }}
          />
        );
      })}
      {error?.message && (
        <Typography
          tag="div"
          additonalClassNames={styles.message}
          variant="textXSRed"
          data-qa-id={`${qaIdPrefix}-error-message`}
        >
          {error?.message}
        </Typography>
      )}
    </div>
  );
};

export { CheckboxQuestion };
