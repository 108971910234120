import * as React from 'react';
import type { ValidationError } from 'yup';
import { RoutesEnum } from 'shared/utils/route';
import { useTranslation } from 'shared/hooks/useTranslation';
import {
  getSectionProgressYup,
  getPageProgress,
} from 'shared/utils/calculateProgress';
import { useInspection } from 'shared/components/InspectionContext';
import { TEST_DRIVE_SECTION_QUESTION_IDS } from '../TestDriveSection/TestDriveQuestionid';
import { FINDINGS_SECTION_QUESTION_IDS } from '../Findings/FindingQuestionIds';
import { getTestDriveSchema } from '../schema';
import type { Question, FormMileage, FormQuestion, Form } from '../types';

type FormDataParams = {
  formValues?: Form;
  questions?: ReadonlyArray<Question>;
};

const getSubsectionFields = (
  ids: ReadonlyArray<number>,
  question: FormQuestion,
) => {
  const questionIds = ids.filter(
    (id) =>
      Object.keys(question).includes(`_${id}`) ||
      Object.values(question).includes(id),
  );
  return questionIds.map((field) => `question._${field}`);
};

function getTotalFieldsFromMileageSection(formValues: FormMileage) {
  let count = 2; // currentMileage, isMileageManipulated

  if (formValues.isMileageManipulated) {
    count += 1;

    if (formValues.mileageSuspicions?.isTachometerReplaced) {
      count += 1;
    }
  }

  return count;
}

const useProgress = () => {
  const { translations } = useTranslation();
  const { setProgress, isPdfInspection } = useInspection();

  const getTestDriveProgress = React.useCallback(
    ({ formValues, questions }: FormDataParams) => {
      if (!formValues) {
        return;
      }

      let errors: ValidationError[] = [];
      try {
        getTestDriveSchema({
          translations,
          questions,
          isPdfInspection,
          isOptional: false,
        }).validateSync(formValues, { abortEarly: false });
      } catch (validationError) {
        const { inner } = validationError as ValidationError;
        errors = inner;
      }

      const mileageProgress = getSectionProgressYup({
        errors: errors?.filter(({ path }) => path?.startsWith('mileage.')),
        totalFieldsNb: getTotalFieldsFromMileageSection(formValues.mileage),
      });

      const progressArray = [mileageProgress];
      const { question = {} } = formValues;
      const testDriveFields = getSubsectionFields(
        TEST_DRIVE_SECTION_QUESTION_IDS,
        question,
      );
      const testDriveProgress = getSectionProgressYup({
        errors: errors?.filter(
          ({ path }) => path !== undefined && testDriveFields.includes(path),
        ),
        totalFieldsNb: testDriveFields.length,
        isOptional: isPdfInspection,
      });
      if (
        !isPdfInspection ||
        (isPdfInspection && Object.keys(testDriveFields).length > 0)
      ) {
        progressArray.push(testDriveProgress);
      }

      const findingsFields = getSubsectionFields(
        FINDINGS_SECTION_QUESTION_IDS,
        question,
      );
      const findingsProgress = getSectionProgressYup({
        errors: errors?.filter(
          ({ path }) => path !== undefined && findingsFields.includes(path),
        ),
        totalFieldsNb: findingsFields.length,
        isOptional: true,
      });
      if (findingsFields.length > 0) {
        progressArray.push(findingsProgress);
      }

      setProgress({
        [RoutesEnum.TEST_DRIVE_MILEAGE]: mileageProgress,
        [RoutesEnum.TEST_DRIVE_TEST_DRIVE]: testDriveProgress,
        [RoutesEnum.TEST_DRIVE_FINDINGS]: findingsProgress,
        [RoutesEnum.TEST_DRIVE]: getPageProgress(progressArray),
      });
    },
    [isPdfInspection, translations, setProgress],
  );

  return getTestDriveProgress;
};

export { useProgress };
