import { MaskedInput } from '../MaskedInput';
import { withFormItem } from '../withFormItem';

import styles from './index.module.scss';

const FormMaskedInput = withFormItem(MaskedInput, {
  classNames: {
    error: styles.error,
  },
});

export { FormMaskedInput };
