import type {
  InspectionSourceType,
  VehicleEngineFuelType,
  InspectionActionName,
} from 'gql/graphql';

const rootElementId = 'inspection-app';

const inspectionSources = Object.freeze({
  PDF_INSPECTION: 'PDF_INSPECTION',
  MSI_APP: 'MSI_APP',
  PA_APP: 'PA_APP',
  PI_APP: 'PI_APP',
  PUBAPI_APP: 'PUBAPI_APP',
  SELF_APP: 'SELF_APP',
  WALKIN_INSPECTION: 'WALKIN_INSPECTION',
  CATALOGUE_AUCTION: 'CATALOGUE_AUCTION',
}) as Record<InspectionSourceType, InspectionSourceType>;

const userActions = Object.freeze({
  CAN_CREATE_PDF_INSPECTION: 'canCreatePdfInspection',
});

const InspectionActions = Object.freeze({
  ACCEPT_BID: 'ACCEPT_BID',
  ACCEPT_COUNTER_OFFER: 'ACCEPT_COUNTER_OFFER',
  ACCEPT_GUARANTEED_PRICE: 'ACCEPT_GUARANTEED_PRICE',
  ACCEPT_PRICE: 'ACCEPT_PRICE',
  ACCEPT_SPECIFIC_BID: 'ACCEPT_SPECIFIC_BID',
  ACCEPT_TOP_BID: 'ACCEPT_TOP_BID',
  ADD_INVOICE: 'ADD_INVOICE',
  ADD_TO_AUTO1_AUCTION: 'ADD_TO_AUTO1_AUCTION',
  ADD_TO_AUTO1_DIRECT_AUCTION: 'ADD_TO_AUTO1_DIRECT_AUCTION',
  ADD_TO_FULL_EVALUATION: 'ADD_TO_FULL_EVALUATION',
  ARCHIVE_CAR: 'ARCHIVE_CAR',
  DECREASE_EXPECTED_PRICE: 'DECREASE_EXPECTED_PRICE',
  EDIT_PRICE_INDICATOR: 'EDIT_PRICE_INDICATOR',
  GENERATE_PDF: 'GENERATE_PDF',
  MAKE_COUNTER_OFFER: 'MAKE_COUNTER_OFFER',
  REJECT_COUNTER_OFFER: 'REJECT_COUNTER_OFFER',
  REJECT_GUARANTEED_PRICE: 'REJECT_GUARANTEED_PRICE',
  REJECT_OFFER: 'REJECT_OFFER',
  REQUEST_TRANSPORT: 'REQUEST_TRANSPORT',
  RE_RUN_AUTO1_AUCTION: 'RE_RUN_AUTO1_AUCTION',
  RE_RUN_AUTO1_DIRECT_AUCTION: 'RE_RUN_AUTO1_DIRECT_AUCTION',
  RE_RUN_CUSTOM_AUCTION: 'RE_RUN_CUSTOM_AUCTION',
  RE_RUN_CUSTOM_TRACK_AUCTION: 'RE_RUN_CUSTOM_TRACK_AUCTION',
  STOP_AUCTION: 'STOP_AUCTION',
}) as Record<InspectionActionName, InspectionActionName>;

const PdfJobStatus = Object.freeze({
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  TIMED_OUT: 'TIMED_OUT',
});

const CheckoutTypeTypes = Object.freeze({
  PRICING: 'PRICING',
  AUCTION: 'AUCTION',
  CUSTOM_AUCTION: 'CUSTOM_AUCTION',
  PURCHASE: 'PURCHASE',
  CUSTOM_AUCTION_TRACKS: 'CUSTOM_AUCTION_TRACKS',
  DIRECT_AUCTION: 'DIRECT_AUCTION',
  BATCH_AUCTION: 'BATCH_AUCTION',
});

const CheckoutTypeReason = Object.freeze({
  RISK_FREE: 'RISK_FREE',
});

// I'm explictly declaring interface here because of typescript Array.includes https://fettblog.eu/typescript-array-includes/
interface HybridCarFueltypeOptionsType {
  DIESEL_ELECTRIC_HYBRID: 'DIESEL_ELECTRIC_HYBRID';
  PETROL_ELECTRIC_HYBRID: 'PETROL_ELECTRIC_HYBRID';
  PLUG_IN_HYBRID: 'PLUG_IN_HYBRID';
  HYBRID: 'HYBRID';
}

const HybridCarFueltypeOptions: HybridCarFueltypeOptionsType = Object.freeze({
  DIESEL_ELECTRIC_HYBRID: 'DIESEL_ELECTRIC_HYBRID',
  PETROL_ELECTRIC_HYBRID: 'PETROL_ELECTRIC_HYBRID',
  PLUG_IN_HYBRID: 'PLUG_IN_HYBRID',
  HYBRID: 'HYBRID',
});

interface FuelTypeOptionsType extends HybridCarFueltypeOptionsType {
  PETROL: 'PETROL';
  DIESEL: 'DIESEL';
  ELECTRIC: 'ELECTRIC';
}

const FuelTypeOptions: FuelTypeOptionsType = Object.freeze({
  PETROL: 'PETROL',
  DIESEL: 'DIESEL',
  ELECTRIC: 'ELECTRIC',
  ...HybridCarFueltypeOptions,
});

const isFullyElectricCar = (fuelType?: VehicleEngineFuelType | null) =>
  fuelType === FuelTypeOptions.ELECTRIC;
const isHybridCar = (fuelType?: VehicleEngineFuelType | null) =>
  Object.values(HybridCarFueltypeOptions).includes(fuelType);
const isElectricCar = (fuelType?: VehicleEngineFuelType | null) =>
  isFullyElectricCar(fuelType) || isHybridCar(fuelType);

const BatteryOwnershipOptions = Object.freeze({
  LEASED: 'LEASED',
  OWNED: 'OWNED',
  MISSING: 'MISSING',
});

export {
  rootElementId,
  inspectionSources,
  userActions,
  InspectionActions,
  PdfJobStatus,
  CheckoutTypeTypes,
  CheckoutTypeReason,
  FuelTypeOptions,
  isFullyElectricCar,
  isHybridCar,
  isElectricCar,
  BatteryOwnershipOptions,
};
