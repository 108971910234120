import * as React from 'react';
import { Link } from 'react-router-dom';
import { appRoutes } from 'appRoutes';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './index.module.scss';

const AllInspectionsLink: React.FC = () => {
  const { translations } = useTranslation();

  if (isMobileEvaluatorApp()) {
    return (
      <Link className={styles.backLink} to={appRoutes.inspectionList()}>
        &lt; {translations.ALL_INSPECTIONS}
      </Link>
    );
  }

  return (
    <a className={styles.backLink} href={appRoutes.unfinishedEvaluations()}>
      &lt; {translations.ALL_INSPECTIONS}
    </a>
  );
};

export { AllInspectionsLink };
