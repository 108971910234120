import * as React from 'react';
import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { InputLabel } from 'shared/components/InputLabel';
import { Typography } from 'shared/components/Typography';

import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  value?: string;
  name: string;
  title: string;
  description: React.ReactNode;
  isDisabled?: boolean;
  defaultValue?: string;
  additonalClassName?: string;
  icon?: React.ReactNode;
  tooltipProps?: {
    'data-microtip-position'?: string;
    'aria-label'?: string;
    role?: string;
  };
};

const RadioItem: React.FC<Props> = ({
  qaIdPrefix = 'radio-item',
  value = '',
  name,
  title,
  description,
  isDisabled = false,
  additonalClassName = '',
  defaultValue,
  icon,
  tooltipProps = {},
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, name, value: selectedValue } }) => {
        return (
          <div
            className={cn(styles.radioItemWrapper, additonalClassName, {
              [styles.selected]: value === selectedValue,
            })}
            {...tooltipProps}
          >
            <InputLabel
              additionalClassName={cn(styles.radioCard, {
                [styles.disabled]: isDisabled,
              })}
              data-qa-id={`${qaIdPrefix}-submit-container`}
            >
              <input
                type="radio"
                name={name}
                value={value}
                defaultValue={defaultValue}
                checked={value === selectedValue}
                onChange={() => {
                  onChange?.(value);
                }}
                disabled={isDisabled}
                id={`${name}-${value}`}
                className={styles.input}
                data-qa-id={`${qaIdPrefix}-option`}
              />
              <div className={styles.content}>
                <Typography
                  tag="div"
                  data-qa-id={`${qaIdPrefix}-text`}
                  additonalClassNames={styles.itemTitle}
                >
                  {title}
                </Typography>
                <Typography
                  tag="div"
                  variant="textSmall"
                  data-qa-id={`${qaIdPrefix}-text`}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              {icon ? <div className={styles.icon}>{icon}</div> : null}
            </InputLabel>
          </div>
        );
      }}
    />
  );
};

export { RadioItem };
