import * as React from 'react';
import { differenceInSeconds } from 'date-fns';

const useTracking = () => {
  const [renderPageStartTime] = React.useState(Date.now());

  const trackRedirectToCarDetails = React.useCallback(
    ({ action, inspectionId, vin, branch }) => {
      window.tracking.eva[action]?.({
        page: 'carInfo',
        duration: differenceInSeconds(Date.now(), renderPageStartTime),
        inspectionId,
        vin,
        carTriggered: action === 'loadVIN' ? 'yes' : undefined,
        stockNumber: undefined,
        branch,
      });
    },
    [renderPageStartTime],
  );

  const trackOpenDuplicateVinPopup = React.useCallback(
    ({ inspectionId, vin, branch, stockNumber, evaluationSource }) => {
      window.tracking.eva.openDuplicateVinPopup({
        duration: differenceInSeconds(Date.now(), renderPageStartTime),
        inspectionId,
        vin,
        branch: branch && `${branch}`,
        stockNumber,
        evaluationSource,
      });
    },
    [renderPageStartTime],
  );

  const trackClickCreateNewEvaluation = React.useCallback(
    ({ vin, branch }) => {
      window.tracking.eva.createNewEvaluation({
        duration: differenceInSeconds(Date.now(), renderPageStartTime),
        vin,
        branch: branch && `${branch}`,
      });
    },
    [renderPageStartTime],
  );

  const trackCloseDuplicateVinPopup = React.useCallback(({ vin, branch }) => {
    window.tracking.eva.closeDuplicateVinPopup({
      vin,
      branch: branch && `${branch}`,
    });
  }, []);

  const trackClickLoadLicensePlate = React.useCallback(
    ({ licensePlate, branch }) => {
      window.tracking.eva.clickloadLicensePlate({
        licensePlate,
        branch: branch && `${branch}`,
        duration: differenceInSeconds(Date.now(), renderPageStartTime),
      });
    },
    [renderPageStartTime],
  );

  const trackLoadLicensePlateSuccess = React.useCallback(
    ({ stockNumber, inspectionId, licensePlate, branch }) => {
      window.tracking.eva.loadLicensePlateSuccess({
        stockNumber,
        inspectionId,
        licensePlate,
        branch: branch && `${branch}`,
        duration: differenceInSeconds(Date.now(), renderPageStartTime),
      });
    },
    [renderPageStartTime],
  );

  return {
    trackRedirectToCarDetails,
    trackOpenDuplicateVinPopup,
    trackClickCreateNewEvaluation,
    trackCloseDuplicateVinPopup,
    trackClickLoadLicensePlate,
    trackLoadLicensePlateSuccess,
  };
};

export { useTracking };
