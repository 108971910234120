import * as React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { useGuestRouteViewerQuery } from 'gql/graphql';
import { appRoutes } from 'appRoutes';

type RouteRenderProps = Parameters<NonNullable<RouteProps['render']>>[0];
type Props = {
  component: React.ComponentType<RouteRenderProps>;
} & React.ComponentProps<typeof Route>;

const GuestRoute = ({
  component: Component,
  ...rest
}: React.PropsWithChildren<Props>): React.ReactElement | null => {
  const { data, loading } = useGuestRouteViewerQuery();

  if (loading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // User doesn't exist
        if (!(data && data.viewer)) {
          return <Component {...props} />;
        }

        return <Redirect to={appRoutes.inspectionList()} />;
      }}
    />
  );
};

GuestRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export { GuestRoute };
