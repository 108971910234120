import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';
import styles from './index.module.scss';

const InfoFieldPlaceholder = () => (
  <div className={styles.infoField}>
    <span className={styles.label} style={{ width: '100%' }}>
      <Skeleton width="100%" height="100%" />
    </span>
  </div>
);

export { InfoFieldPlaceholder };
