import * as React from 'react';
import { Card } from 'shared/components/Card';
import { Grid } from 'shared/components/Grid';
import { InfoFieldPlaceholder } from 'shared/components/InfoField/Placeholder';
import styles from './index.module.scss';

const CarInfoPlaceholder = () => (
  <Card
    paddingTop={42}
    paddingBottom={42}
    qaIdPrefix="car-info-loader"
    externalStyle={styles.card}
    showBottomBorder
  >
    <div className={styles.flexGrid}>
      <Grid gutter={[2, 0]} columns={2} className={styles.loadingGrid}>
        {new Array(6).fill(null).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <Grid item>
              <InfoFieldPlaceholder />
            </Grid>
            <Grid item>
              <InfoFieldPlaceholder />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </div>
  </Card>
);

export { CarInfoPlaceholder };
