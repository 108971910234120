import 'remote-web-worker';

async function heicConvert(
  file: File,
  {
    type = 'image/jpeg',
    quality = 0.94,
  }: { type?: string; quality?: number } = {},
): Promise<File> {
  const canvas = document.createElement('canvas');
  const offscreen = canvas.transferControlToOffscreen();

  const worker = new Worker(
    //@ts-ignore
    new URL('shared/workers/heic-convert', import.meta.url),
  );

  try {
    const blob = await new Promise<Blob>((resolve, reject) => {
      worker.onmessage = (e: MessageEvent<Blob | { error: string }>) => {
        if ('error' in e.data) {
          reject(e.data.error);
        } else {
          resolve(e.data);
        }
      };
      worker.onerror = (e: ErrorEvent) => {
        reject(e);
      };

      worker.postMessage({ canvas: offscreen, file, type, quality }, [
        offscreen,
      ]);
    });

    const convertedFile = new File(
      [blob],
      file.name.toLowerCase().replace('heic', 'jpeg'),
      {
        lastModified: file.lastModified,
        type,
      },
    );
    return convertedFile;
  } finally {
    worker.terminate();
  }
}

export { heicConvert };
