import * as React from 'react';
import { useCarDetailsInitialDataQuery } from 'gql/graphql';
import { useProgress } from 'pages/CarDetails/hooks/useProgress';
import { mapDataToFormValues } from 'pages/CarDetails/helpers';

function useCarDetailsProgress(inspectionId: number) {
  const { data } = useCarDetailsInitialDataQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });
  const setCarDetailsProgress = useProgress();
  const updateProgress = React.useCallback(() => {
    setCarDetailsProgress({
      vin: data?.inspection?.vehicle?.identifiers?.vin,
      showManualFlow: !data?.inspection?.vehicle?.model,
      formValues: mapDataToFormValues(data?.inspection),
    });
  }, [data, setCarDetailsProgress]);

  React.useEffect(() => {
    setCarDetailsProgress({
      vin: data?.inspection?.vehicle?.identifiers?.vin,
      showManualFlow: !data?.inspection?.vehicle?.model,
      formValues: mapDataToFormValues(data?.inspection),
    });
  }, [data, setCarDetailsProgress]);

  return updateProgress;
}

export { useCarDetailsProgress };
