import * as React from 'react';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { FormDropdownPlaceholder } from 'shared/components/FormDropdown/Placeholder';
import { FormToggleButtonPlaceholder } from 'shared/components/FormToggleButton/Placeholder';
import { Skeleton } from 'shared/components/Skeleton';
import styles from '../index.module.scss';

const OtherInformationPlaceholder: React.FC = () => (
  <Card paddingTop={32} paddingBottom={32}>
    <Typography tag="div" additonalClassNames={styles.title} variant="titleXL">
      <Skeleton width="195px" height="100%" />
    </Typography>
    <div className={styles.carStatusContainer}>
      <div className={styles.carStatusColumn}>
        <div className={styles.formElementContainer}>
          <FormDropdownPlaceholder labelWidth="112px" />
        </div>
        <div className={styles.formElementContainer}>
          <FormDropdownPlaceholder labelWidth="112px" />
        </div>
        <Skeleton
          width="125px"
          height="26px"
          style={{ marginBottom: '16px' }}
        />
        <Skeleton
          width="125px"
          height="26px"
          style={{ marginBottom: '16px' }}
        />
      </div>
      <div className={styles.carStatusColumn}>
        <div className={styles.formElementContainer}>
          <FormDropdownPlaceholder labelWidth="112px" />
        </div>
        <div className={styles.formElementContainer}>
          <FormDropdownPlaceholder labelWidth="112px" />
        </div>
        <Skeleton
          width="125px"
          height="26px"
          style={{ marginBottom: '16px' }}
        />
      </div>
    </div>
    <div className={styles.formElementContainer}>
      <FormToggleButtonPlaceholder labelWidth="46px" width="218px" />
    </div>
    <div className={styles.formElementContainer}>
      <FormToggleButtonPlaceholder labelWidth="46px" width="218px" />
    </div>
  </Card>
);

export { OtherInformationPlaceholder };
