import type {
  VehicleImage,
  VehicleImageArea,
} from 'gql/graphql';

function filterImagesByArea(
  images: Array<Omit<VehicleImage, 'mimeType'>> | null | undefined,
  area: VehicleImageArea,
) {
  if (!images) return null;
  const imageArr = images.find((image) => image.area === area);
  return imageArr?.absoluteUrl;
};

export { filterImagesByArea }
