import * as React from 'react';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import { FormToggleButtonPlaceholder } from 'shared/components/FormToggleButton/Placeholder';
import styles from '../index.module.scss';

const TestDriveSectionPlaceholder: React.FC = () => (
  <Card paddingTop={32} paddingBottom={32} showBottomBorder>
    <div className={styles.title}>
      <Typography variant="titleXL">
        <Skeleton width="104px" height="100%" />
      </Typography>
    </div>
    <FormToggleButtonPlaceholder labelWidth="178px" width="216px" />
  </Card>
);

export { TestDriveSectionPlaceholder };
