import * as React from 'react';
import type { RouteProps } from 'react-router-dom';
import { APP_NAMES } from 'config/urls';
import { MerchantRoute } from '../MerchantRoute';
import { MobileEvaluatorRoute } from '../MobileEvaluatorRoute';

type Props = {
  permissions?: string[];
} & RouteProps;

const RouteHandler: React.FC<Props> = ({ ...props }) => {
  // for merchant app, the auth is handled by PHP(auto1-platform)
  // all the routes for mobile evaluator app will be protected except few guest routes defined explicity in App.js like login, notFound route etc.
  if (process.env.REACT_APP_NAME === APP_NAMES.MOBILE_EVALUATOR) {
    return <MobileEvaluatorRoute {...props} />;
  }
  return <MerchantRoute {...props} />;
};

export { RouteHandler };
