import * as yup from 'yup';
import type { Translations } from 'shared/utils/translations';

const CarInfoSchema = (translations: Translations, isVinInfo: boolean) => {
  const vinSchema = () => {
    const vin = yup
      .string()
      .length(20, ({ value = '', length }) =>
        window.Translator.trans('a1-inspectionApp-vin-length', {
          length,
          current: value.length,
        }),
      )
      .matches(/^[^qioQIO]+$/, translations.PLEASE_DO_NOT_USE_THIS_LETTERS);

    if (isVinInfo) {
      return vin.required(translations.THIS_FIELD_CANNOT_BE_EMPTY);
    }

    return vin;
  };

  const licensePlateSchema = () => {
    const licensePlate = yup
      .string()
      .uppercase()
      .nullable()
      .max(45, translations.INVALID_LENGTH);

    if (isVinInfo) {
      return licensePlate;
    }

    return licensePlate.required(translations.THIS_FIELD_CANNOT_BE_EMPTY);
  };

  return yup.object().shape({
    vin: vinSchema(),
    branch: yup
      .number()
      .nullable()
      .required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
    licensePlate: licensePlateSchema(),
  });
};

export { CarInfoSchema };
