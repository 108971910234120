import * as React from 'react';
import styles from './index.module.scss';

const Speedometer: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g>
          <g>
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M10.437 51.6H48.4c5.765-6.267 9.62-13.918 9.62-22.418C58.02 13.065 45.03 0 29.01 0 12.988 0 0 13.065 0 29.182c0 9.01 4.059 17.066 10.437 22.419z"
              transform="translate(0 37.15) translate(11.667)"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M38.897 40.632H19.123v6.282h19.774v-6.282z"
              transform="translate(0 37.15) translate(11.667)"
            />
            <g className={styles.stroke} strokeWidth="1.5">
              <path
                d="M1.742 21.995l-1.52-1.907S5.955 13.08 5.128 3.806c-.084-.95-.237-1.924-.473-2.916L6.683.127M3.642 18.817L1.869 17.672M5.543 15.13L3.389 14.113M6.81 11.443L4.529 10.807M7.57 7.756L5.036 7.501M7.697 3.687L5.129 3.806"
                transform="translate(0 37.15) translate(11.667) translate(47.14 23.346)"
              />
            </g>
            <path
              className={styles.fill}
              d="M32 29.22c0 1.632-1.318 2.955-2.945 2.955-1.628 0-2.946-1.323-2.946-2.955 0-1.633 1.318-2.956 2.946-2.956 1.627 0 2.945 1.323 2.945 2.956"
              transform="translate(0 37.15) translate(11.667)"
            />
            <path
              className={styles.fill}
              d="M31.32 21.036L7.767 23.758 31.514 22.369z"
              transform="translate(0 37.15) translate(11.667) rotate(40 19.64 22.397)"
            />
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M29.493 2.622L29.493 5.673M11.158 9.826L13.524 11.946M2.796 28.453L6.344 28.453M12.341 43.985L9.638 46.104"
              transform="translate(0 37.15) translate(11.667)"
            />
            <g>
              <path
                className={styles.fill}
                d="M32 29.22c0 1.632-1.318 2.955-2.945 2.955-1.628 0-2.946-1.323-2.946-2.955 0-1.633 1.318-2.956 2.946-2.956 1.627 0 2.945 1.323 2.945 2.956"
                transform="translate(0 37.15) translate(11.667) translate(94.306)"
              />
              <path
                className={styles.fill}
                d="M28.606 28.813L29.556 29.703 13.085 42.735z"
                transform="translate(0 37.15) translate(11.667) translate(94.306)"
              />
              <g className={styles.stroke} strokeWidth="1.5">
                <path
                  d="M23.449.212L23.449 3.772M35.608 3.603L33.919 5.806M44.14 11.811L41.521 13.265M47.856 24.075L44.815 24.075M42.83 33.777L45.576 35.134M4.608 34.455L2.157 35.906M2.75 24.562L.216 24.562M5.96 13.265L3.383 11.807M13.646 6.145L12.379 3.603"
                  transform="translate(0 37.15) translate(11.667) translate(94.306) translate(5.077 2.918)"
                />
              </g>
              <path
                className={styles.stroke}
                strokeWidth="1.5"
                d="M10.437 51.6H48.4c5.765-6.267 9.62-13.918 9.62-22.418C58.02 13.065 45.03 0 29.01 0 12.988 0 0 13.065 0 29.182c0 9.01 4.059 17.066 10.437 22.419z"
                transform="translate(0 37.15) translate(11.667) translate(94.306)"
              />
            </g>
            <path
              className={styles.stroke}
              strokeWidth="1.5"
              d="M86.75 9.549H64.917v36.626H86.75V9.55z"
              transform="translate(0 37.15) translate(11.667)"
            />
          </g>
          <path
            className={styles.stroke}
            strokeWidth="1.5"
            d="M0 62.567L175 62.567"
            transform="translate(0 37.15)"
          />
        </g>
      </g>
    </svg>
  );
};

export { Speedometer };
