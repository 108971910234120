import * as React from 'react';
import styles from './index.module.scss';

const ConsoleDashboard: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g className={styles.stroke} strokeWidth="1.5" mask="url(#prefix__d)">
          <g>
            <path
              strokeLinecap="round"
              d="M37.039 67.454C19.059 67.309 7.296 54.792 7.833 39.03c.52-15.252 12.39-27.299 29.178-27.299s29.56 11.505 30.398 26.744c.918 16.72-12.653 29.122-30.37 28.979z"
              transform="translate(-.972 21.507)"
            />
            <path
              strokeLinecap="round"
              d="M12.382 36.956c-3.018-.928 4.358-20.853 22.664-21.305 18.306-.451 27.339 15.674 27.516 19.1.178 3.425-12.012-.29-12.012-.29-4.433-6.526-17.3-8.065-25.209.696 0 0-9.941 2.728-12.96 1.8zM26.321 43.547s-8.447-.854-10.646-.398c-2.199.455-.347 4.382 1.042 6.658 1.388 2.277 2.661 5.919 6.364 2.675 3.703-3.244 5.266-4.61 5.266-4.61l-2.026-4.325h0zM51.81 48.212s5.383 6.395 7.36 4.07c1.978-2.326 5.548-9.672 1.648-9.25-3.9.423-7.964.74-7.964.74l-1.044 4.44h0zM34.43 54.018s6.278 3.268 12.54-.095c.092-.05.185-.101.278-.154 0 0 5.275 3.432 4.48 5.08-.793 1.648-13.78 7.209-23.196.455 0 0-1.815-1.592 5.899-5.286h0zM91.102 64.04s1.8 1.086 3.639 0c0 0 .08-3.05.68-4.102.599-1.052 3.238-4.066-1-5.469-4.239-1.402-5.998-.841-5.318 2.98 0 0 2.959 4.452 2 6.59h0zM94.827 64.522s1.435.964 1.48 4.524c.045 3.56 3.408 6.23 3.408 6.23M79.185 24.44L99.715 25.022 98.923 35.194 79.185 34.395zM0 0c0 5.377 6.82 10.002 15.048 8.952 8.228-1.051 71.66-5.831 145.085 1.654 0 0 12.203 2.229 15.84-10.606M110.47 50.771c7.628-10.786 8.912-15.577 26.91-15.577 9.324 0 39.02.822 39.02.822"
              transform="translate(-.972 21.507)"
            />
            <path
              strokeLinecap="round"
              d="M40.64 11.731c6.181-.79 19.598-1.924 35.054.753 15.456 2.677 23.618 5.776 29.257 8.973 5.639 3.196 5.518 11.511 5.518 23.751 0 12.24-.639 64.285-.639 64.285"
              transform="translate(-.972 21.507)"
            />
            <path
              strokeLinecap="round"
              d="M67.453 44.044s7.516-.818 7.674 4.04c.158 4.858 1.899 24.193 1.74 25.892-.158 1.699 1.891 35.517 1.891 35.517"
              transform="translate(-.972 21.507)"
            />
            <path
              d="M90.306 65.5s-4.28.074-5.08 1.26c-.8 1.185 1.349 17.286 1.971 17.878.622.593 8.512.715 13.46-.767 0 0 .3-17.01-.855-17.691-1.156-.682-3.819-.68-3.819-.68h-.296"
              transform="translate(-.972 21.507)"
            />
            <path
              strokeLinecap="round"
              d="M91.044 64.522s-.454-.667 0 3.366c.453 4.032-3.484 7.829-3.484 7.829"
              transform="translate(-.972 21.507)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { ConsoleDashboard };
