import * as React from 'react';
import ReactDOM from 'react-dom';
import { CheckMarkIcon, CloseIcon } from '@auto1-ui/icons';
import { Typography } from 'shared/components/Typography';

import styles from './styles.module.scss';

type Props = {
  qaIdPrefix?: string;
  onClose: React.MouseEventHandler;
};

const AlertBanner: React.FC<Props> = ({
  qaIdPrefix = 'alert',
  children,
  onClose,
}) => {
  const alertContainer = document.getElementById('alert-container');

  if (!alertContainer) {
    console.error('Alert container #alert-container not found');
    return null;
  }

  return ReactDOM.createPortal(
    <div data-qa-id={`${qaIdPrefix}-container`} className={styles.container}>
      <CheckMarkIcon fill="transparent" className={styles.icon} />
      <Typography variant="textSmall" additonalClassNames={styles.text}>
        {children}
      </Typography>
      <button onClick={onClose} className={styles.closeButton}>
        <CloseIcon className={styles.closeIcon} />
      </button>
    </div>,
    alertContainer,
  );
};

export { AlertBanner };
