import type { AccidentReason as AccidentReasonType } from 'gql/graphql';
import { TranslationEnum } from 'shared/utils/translations';
import { AccidentReason } from '../../constants';

const translations = TranslationEnum((key) => key);

const Variation = {
  Default: 'default',
  Accident: 'accident',
  Damage: 'damage',
} as const;

const Area = {
  Front: 'FRONT',
  ExteriorLeft: 'LEFT',
  ExteriorRight: 'RIGHT',
  Rear: 'REAR',
  Roof: 'ROOF',
  Underbody: 'UNDERBODY',
  Interior: 'INTERIOR',
} as const;

const AREA_LABELS = {
  [Area.Front]: translations.FRONT,
  [Area.ExteriorLeft]: translations.EXTERIOR_LEFT,
  [Area.ExteriorRight]: translations.EXTERIOR_RIGHT,
  [Area.Rear]: translations.REAR,
  [Area.Roof]: translations.ROOF,
  [Area.Underbody]: translations.UNDERBODY,
  [Area.Interior]: translations.INTERIOR,
} as const;

const REASON_LABELS = {
  [AccidentReason.PanelsWithHighPaintThickness]:
    translations.ACCIDENT_REASON_PANELS_WITH_HIGH_PAINT_THICKNESS,
  [AccidentReason.PanelsWithDifferentTones]:
    translations.ACCIDENT_REASON_PANELS_WITH_DIFFERENT_TONES,
  [AccidentReason.PanelsDeformedDueToDamage]:
    translations.ACCIDENT_REASON_PANELS_DEFORMED_DUE_TO_DAMAGE,
  [AccidentReason.PanelsReplacedDueToDamage]:
    translations.ACCIDENT_REASON_PANELS_REPLACED_DUE_TO_DAMAGE,
  [AccidentReason.BodyGapsOrMisalignedPanel]:
    translations.ACCIDENT_REASON_BODY_GAPS_OR_MISALIGNED_PANELS,
  [AccidentReason.UnderlyingStructureDeformed]:
    translations.ACCIDENT_REASON_UNDERLYING_STRUCTURE_DEFORMED,
  [AccidentReason.SignsOfStructuralRepair]:
    translations.ACCIDENT_REASON_SIGNS_OF_STRUCTURAL_REPAIR,
  [AccidentReason.WaterFireOrHailDamage]:
    translations.ACCIDENT_REASON_WATER_FIRE_OR_HAIL_DAMAGE,
  [AccidentReason.DatabaseProofOfAccident]:
    translations.ACCIDENT_REASON_DATABASE_PROOF_OF_ACCIDENT,
  [AccidentReason.InsuranceOrRepairInvoice]:
    translations.ACCIDENT_REASON_INSURANCE_OR_REPAIR_INVOICE,
  [AccidentReason.Other]: translations.OTHER,
} as Record<AccidentReasonType, string>;

export { Variation, Area, AREA_LABELS, AccidentReason, REASON_LABELS };
