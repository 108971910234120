const Colors = {
  BEIGE: 'Beige',
  BLACK: 'Black',
  BLUE: 'Blue',
  BROWN: 'Brown',
  GRAY: 'Gray',
  GOLD: 'Gold',
  GREEN: 'Green',
  ORANGE: 'Orange',
  PURPLE: 'Purple',
  RED: 'Red',
  SILVER: 'Silver',
  WHITE: 'White',
  YELLOW: 'Yellow',
};
const VehicleInteriorUpholstery = () => {
  const translate = (key: string) => window.Translator.trans(key);
  return {
    ALCANTARA: translate('global.car_details.upholstery.Alcantara'),
    CLOTH: translate('global.car_details.upholstery.Stoff'),
    LEATHER: translate('global.car_details.upholstery.Leder'),
    PART_LEATHER: translate('global.car_details.upholstery.Teilleder'),
  };
};

const Source = {
  DEFAULT: 'default',
  VIN: 'vin',
  ECODE: 'ecode',
  REPLACEMENT: 'replacement',
};

const ToggleOption = {
  UNSELECTED: 'UNSELECTED',
  NO: 'NO',
};

const MultiSelectOption = {
  NOT_PRESENT: 'NOT_PRESENT',
};

export {
  Colors,
  VehicleInteriorUpholstery as Upholstery,
  Source,
  ToggleOption,
  MultiSelectOption,
};
