import * as yup from 'yup';
import type { Translations } from 'shared/utils/translations';

const CarInfoSchema = (translations: Translations, optionalVin: boolean) => {
  return yup.object().shape({
    vin: yup.string().when('inspectionReport', {
      is: (pdf?: File | null) =>
        pdf === undefined || pdf === null || !optionalVin,
      then: yup
        .string()
        .required(translations.THIS_FIELD_CANNOT_BE_EMPTY)
        .length(20, ({ value = '', length }) =>
          window.Translator.trans('a1-inspectionApp-vin-length', {
            length,
            current: value.length,
          }),
        )
        .matches(/^[^qioQIO]+$/, translations.PLEASE_DO_NOT_USE_THIS_LETTERS),
      otherwise: yup
        .string()
        .test(
          'vin-qio',
          translations.PLEASE_DO_NOT_USE_THIS_LETTERS,
          (vin?: string) => {
            if (!vin || vin.trim().length === 0) {
              return true;
            }

            return /^[^qioQIO]+$/.test(vin);
          },
        )
        .test(
          'vin-length',
          window.Translator.trans('a1-inspectionApp-vin-length'),
          (vin?: string) => {
            if (!vin || vin.trim().length === 0) {
              return true;
            }

            return vin.trim().length === 20;
          },
        ),
    }),
    branch: yup
      .number()
      .nullable()
      .required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
    licensePlate: yup
      .string()
      .uppercase()
      .nullable()
      .max(45, translations.INVALID_LENGTH),
  });
};

export { CarInfoSchema };
