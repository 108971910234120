import * as React from 'react';
import { FormInput } from 'shared/components/FormInput';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Typography } from 'shared/components/Typography';
import { useFormContext } from 'react-hook-form';
import { LoadingCircle } from 'shared/components/LoaderCircle';
import styles from '../../index.module.scss';
import { MIN_LENGTH_SEARCH } from '../../useAddressPredictions';

type Props = {
  label: string;
  name: string;
  onFillManuallyClick: () => void;
  onOptionClick: (description: string, placeId: string) => void;
  setIsOpen: (value: boolean) => void;
  onKeyUp: (e: React.ChangeEvent<HTMLInputElement>) => void;
  results: ReadonlyArray<google.maps.places.AutocompletePrediction>;
  loading: boolean;
  value: string;
  isOpen: boolean;
  qaIdPrefix: string;
  error?: boolean;
};

const AddressField: React.FC<Props> = ({
  error = null,
  qaIdPrefix,
  label,
  name,
  onOptionClick,
  onFillManuallyClick,
  results = [],
  isOpen,
  setIsOpen,
  onKeyUp,
  loading,
  value,
}) => {
  const handleOptionClick = (
    item: google.maps.places.AutocompletePrediction,
  ) => {
    setIsOpen(false);
    onOptionClick(item.description, item.place_id);
  };

  const ContainerField = React.useRef<HTMLDivElement>(null);

  const { watch } = useFormContext();
  const branchName = watch('name');

  useClickOutside(ContainerField, () => setIsOpen(false));

  const handleTyping = (e: React.ChangeEvent<HTMLInputElement>) => {
    onKeyUp(e);
    setIsOpen(true);
  };

  const onClickFillManually = () => {
    onFillManuallyClick();
    setIsOpen(false);

    window.tracking.eva.fillManuallyButton({
      branchName,
    });
  };

  const { translations } = useTranslation();
  return (
    <div className={styles.addressField} ref={ContainerField}>
      <FormInput
        name={name}
        qaIdPrefix={`${qaIdPrefix}-name`}
        label={label}
        hasAutoComplete={false}
        onChange={handleTyping}
        defaultValue=""
        containerClassName={styles.inputContainerFullWidth}
      />
      {loading && <LoadingCircle size={16} color="#358ccb" />}

      {isOpen && (
        <div className={styles.suggestionsListWrapper}>
          <ul data-qa-id={`${qaIdPrefix}-suggestion-list`}>
            {!!results?.length &&
              results.map((item) => {
                return (
                  <li
                    onClick={() => handleOptionClick(item)}
                    key={item.place_id}
                    data-qa-id={`suggestion-item-${item.place_id}`}
                  >
                    {item.description}
                  </li>
                );
              })}
            {!results?.length &&
              !loading &&
              value?.length < MIN_LENGTH_SEARCH && (
                <li className={styles.notFound}>
                  {translations.LOCATIONS_INPUT_LESS_THAN_THREE}
                </li>
              )}
            {!results?.length &&
              !loading &&
              value?.length >= MIN_LENGTH_SEARCH && (
                <li className={styles.notFound}>
                  {translations.LOCATIONS_RESULT_NOT_FOUND}
                </li>
              )}
          </ul>
          <div
            className={styles.fillManually}
            onClick={onClickFillManually}
            key="fill_manually_button"
            data-qa-id={`${qaIdPrefix}-fill-manually`}
          >
            Fill manually
          </div>
        </div>
      )}
      {error && (
        <Typography variant="textXSRed">
          {translations.LOCATIONS_ADDRESS_ERROR}
        </Typography>
      )}
    </div>
  );
};

export { AddressField };
