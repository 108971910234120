import * as React from 'react';
import type { CheckBeforeSubmitToAuctionQuery } from 'gql/graphql';
import { useClickOutside } from 'shared/hooks/useClickOutside';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Typography } from 'shared/components/Typography';
import { Modal } from 'shared/components/Modal';
import { appRoutes } from 'appRoutes';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { lowerFirstChar } from 'shared/utils/strings';
import { CarCard } from './CarCard';
import styles from './index.module.scss';

type Inspection =
  CheckBeforeSubmitToAuctionQuery['checkBeforeSubmitToAuction']['relatedInspection'];

const NoRerunRemainingModalType = Object.freeze({
  CreateVin: 'CreateVin',
  SubmitToAuction: 'SubmitToAuction',
});

type ModalType = keyof typeof NoRerunRemainingModalType;

type Props = {
  handleClose?: () => void;
  inspectionInfo?: Inspection;
  isOpen?: boolean;
  modalType: ModalType;
  vinStatus: string;
  currentStockNumber?: string;
  trackingEvents: {
    view: string;
    mainButtonClick: string;
    secondaryButtonClick: string;
  };
};

function getTitleTranslationKey(modalType: ModalType, inspection: Inspection) {
  if (modalType !== NoRerunRemainingModalType.CreateVin) {
    return 'EVALUATION_CANNOT_BE_SUBMITTED';
  }

  return inspection ? 'EVALUATION_CANNOT_BE_CREATED' : 'EXISTING_AUCTION_TITLE';
}

const NoRerunRemainingModal: React.FC<Props> = ({
  handleClose = () => {},
  inspectionInfo,
  isOpen = false,
  modalType,
  vinStatus,
  currentStockNumber = '',
  trackingEvents,
}) => {
  const { translations } = useTranslation();
  const containerDiv = React.useRef<HTMLDivElement>(null);
  const { car, vehicle } = inspectionInfo ?? {};
  const trackingOriginalStockNumber = car?.stockNumber;
  const trackingVin = vehicle?.identifiers?.vin;
  const trackingInspectionId = inspectionInfo?.inspectionId;
  const okClickHandler = () => {
    window.tracking.eva[lowerFirstChar(trackingEvents.mainButtonClick)]?.({
      originalStockNumber: trackingOriginalStockNumber,
      vin: trackingVin,
      stockNumber: currentStockNumber,
      inspectionId: trackingInspectionId,
    });

    if (!car?.stockNumber) {
      throw new Error('No stock number available');
    }

    window.open(
      vinStatus === 'RUNNING_AUCTION'
        ? appRoutes.runningAuctions()
        : appRoutes.searchRemarketing(car?.stockNumber),
      '_blank',
    );
  };
  const cancelClickHandler = () => {
    window.tracking.eva[lowerFirstChar(trackingEvents.secondaryButtonClick)]?.({
      originalStockNumber: trackingOriginalStockNumber,
      vin: trackingVin,
      stockNumber: currentStockNumber,
      inspectionId: trackingInspectionId,
    });
    handleClose();
  };

  React.useEffect(() => {
    window.tracking.eva[lowerFirstChar(trackingEvents.view)]?.({
      originalStockNumber: trackingOriginalStockNumber,
      vin: trackingVin,
      stockNumber: currentStockNumber,
      inspectionId: trackingInspectionId,
    });
  }, [
    trackingEvents.view,
    trackingOriginalStockNumber,
    trackingVin,
    currentStockNumber,
    trackingInspectionId,
  ]);

  useClickOutside(containerDiv, () => {
    handleClose();
  });

  return (
    <Modal
      isOpen={isOpen}
      className={styles.warningContainer}
      title={translations[getTitleTranslationKey(modalType, inspectionInfo)]}
      okLabel={translations.VIEW_AUCTION}
      onOk={okClickHandler}
      showOkButton={!isMobileEvaluatorApp() && Boolean(inspectionInfo)}
      okButtonExtraClass={styles.okButton}
      cancelLabel={translations.CLOSE}
      cancelButtonExtraClass={styles.cancelButtonExtraClass}
      onCancel={cancelClickHandler}
      onClose={handleClose}
      qaIdPrefix="car-information-warning-modal"
      ref={containerDiv}
    >
      {inspectionInfo && (
        <div>
          <Typography
            variant="textSmall"
            tag="div"
            data-qa-id="car-information-warning-title"
            additonalClassNames={styles.description}
          >
            <p>{translations.NO_RERUN_VIEW_EXISTING_AUCTION}</p>
          </Typography>
          <CarCard inspection={inspectionInfo} />
        </div>
      )}
    </Modal>
  );
};

export { NoRerunRemainingModal, NoRerunRemainingModalType };
