import * as React from 'react';
import { Textarea as TextareaUI } from '@auto1-ui/textarea';

import { withFormItem } from '../withFormItem';

import styles from './index.module.scss';

type Props = {
  isDisabled?: boolean;
} & React.ComponentProps<typeof TextareaUI>;

const TextArea: React.FC<Props> = ({ isDisabled = false, ...props }) => (
  <TextareaUI {...props} disabled={isDisabled} />
);

const FormTextarea = withFormItem(TextArea, {
  classNames: {
    error: styles.error,
  },
});

export { FormTextarea };
