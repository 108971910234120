import * as React from 'react';

import { Skeleton } from 'shared/components/Skeleton';

import { CarPictureItemPlaceholder } from '../CarPictureItem/CarPictureItemsPlaceholder';
import styles from './index.module.scss';

type Props = {
  size?: number;
};

const CarPictureSectionPlaceholder: React.FC<Props> = ({ size = 4 }) => {
  return (
    <>
      <Skeleton width="70px" height="14px" />
      <div className={styles.grid}>
        {Array(size)
          .fill(null)
          .map(() => (
            <CarPictureItemPlaceholder key={`${Math.random()}`} />
          ))}
      </div>
    </>
  );
};

export { CarPictureSectionPlaceholder };
