import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { appRoutes } from 'appRoutes';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { InputLabel } from 'shared/components/InputLabel';
import { Checkbox } from '@auto1-ui/checkbox';
import { Typography } from 'shared/components/Typography';
import { APP_NAMES } from 'config/urls';
import { useTranslation } from 'shared/hooks/useTranslation';
import type { Translations } from 'shared/utils/translations';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
  country: string;
};

const SHOULD_SHOW_TERMS = !isMobileEvaluatorApp();
const getTermsText = (translations: Translations, country: string) => {
  const confirmText =
    translations.VALIDATE_AND_CONFIRM_INFORMATION.split(/%l[se]%/);
  const isMobileEvaluator =
    process.env.REACT_APP_NAME === APP_NAMES.MOBILE_EVALUATOR;
  const termsUrl = appRoutes.termsAndConditions(country, isMobileEvaluator);

  return (
    <>
      {confirmText[0]}
      {isMobileEvaluator ? (
        <a href={termsUrl} rel="noopener noreferrer" target="_blank">
          {confirmText[1]}
        </a>
      ) : (
        <Link to={termsUrl} target="_blank">
          {confirmText[1]}
        </Link>
      )}
      {confirmText[2]}
    </>
  );
};

const Terms = React.forwardRef<HTMLDivElement, Props>(
  ({ qaIdPrefix, country }, ref) => {
    const { translations } = useTranslation();
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const terms = React.useMemo(
      () => getTermsText(translations, country.toLowerCase()),
      [country, translations],
    );

    return SHOULD_SHOW_TERMS ? (
      <Controller
        control={control}
        name="termsAccepted"
        render={({
          field: { onChange, value, name },
          fieldState: { invalid },
        }) => {
          return (
            <>
              <InputLabel
                additionalClassName={cn(styles.checkBoxLabel, {
                  [styles.error]: invalid,
                })}
                data-qa-id={`${qaIdPrefix}-submit-container`}
              >
                <Checkbox
                  isChecked={!!value}
                  onChange={(isChecked) => onChange(isChecked)}
                  qaId={`${qaIdPrefix}-submit`}
                  name="terms"
                  value="true"
                />
                <Typography
                  tag="div"
                  data-qa-id={`${qaIdPrefix}-submit-label`}
                  ref={ref}
                >
                  {terms}
                </Typography>
              </InputLabel>
              {invalid && (
                <Typography
                  variant="textXSRed"
                  additonalClassNames={styles.checkboxError}
                  data-qa-id="terms-error-message"
                >
                  {errors[name].message}
                </Typography>
              )}
            </>
          );
        }}
      />
    ) : null;
  },
);

Terms.displayName = 'Terms';

export { Terms };
