import * as React from 'react';
import { Button, ButtonThemes } from '@auto1-ui/button';
import Loader from 'shared/components/Loader';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import icon24h from 'assets/icons/24-h.svg';
import iconDirectAuction from 'assets/icons/icon-direct-auction.svg';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  onFullServiceClick: () => void;
  onDirectAuctionClick: () => void;
  isCheckingBeforeSubmit: boolean;
};

const ChooseAuction: React.FC<Props> = ({
  qaIdPrefix = 'choose-auction-page',
  onFullServiceClick,
  onDirectAuctionClick,
  isCheckingBeforeSubmit,
}) => {
  const { translations } = useTranslation();
  const submitButtonClicked = React.useRef('');

  return (
    <Card paddingTop={32} paddingBottom={32} qaIdPrefix={qaIdPrefix}>
      <Typography
        data-qa-id={`${qaIdPrefix}-title`}
        variant="titleXL"
        tag="h2"
        additonalClassNames={styles.title}
      >
        {translations.CHOOSE_AUCTION}
      </Typography>
      <div>
        <table className={styles.table} data-qa-id={`${qaIdPrefix}-table`}>
          <thead>
            <tr>
              <th></th>
              <th>
                <span data-qa-id={`${qaIdPrefix}-header-full-service`}>
                  <img src={icon24h} alt="Auto1 Full Service icon" />
                  &nbsp; {translations.AUTO1_FULL_SERVICE}
                </span>
              </th>
              <th>
                <span data-qa-id={`${qaIdPrefix}-header-direct-auction`}>
                  <img src={iconDirectAuction} alt="Auto1 Direct icon" />
                  &nbsp; {translations.AUTO1_DIRECT}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th data-qa-id={`${qaIdPrefix}-contact-partner`}>
                {translations.CONTRACT_PARTNER}
              </th>
              <td data-qa-id={`${qaIdPrefix}-contact-partner-full-service`}>
                {translations.CONTRACT_PARTNER_FULL_SERVICE}
              </td>
              <td data-qa-id={`${qaIdPrefix}-contact-partner-direct-auction`}>
                {translations.CONTRACT_PARTNER_DIRECT}
              </td>
            </tr>
            <tr>
              <th data-qa-id={`${qaIdPrefix}-potential-buyer`}>
                {translations.POTENTIAL_BUYER}
              </th>
              <td data-qa-id={`${qaIdPrefix}-potential-buyer-full-service`}>
                {translations.POTENTIAL_BUYER_FULL_SERVICE}
              </td>
              <td data-qa-id={`${qaIdPrefix}-potential-buyer-direct-auction`}>
                {translations.POTENTIAL_BUYER_DIRECT}
              </td>
            </tr>
            <tr>
              <th data-qa-id={`${qaIdPrefix}-car-handover`}>
                {translations.CAR_HANDOVER}
              </th>
              <td data-qa-id={`${qaIdPrefix}-car-handover-full-service`}>
                {translations.CAR_HANDOVER_FULL_SERVICE}
              </td>
              <td data-qa-id={`${qaIdPrefix}-car-handover-direct-auction`}>
                {translations.CAR_HANDOVER_DIRECT}
              </td>
            </tr>
            <tr>
              <th data-qa-id={`${qaIdPrefix}-document-handover`}>
                {translations.DOCUMENT_HANDOVER}
              </th>
              <td data-qa-id={`${qaIdPrefix}-document-handover-full-service`}>
                {translations.DOCUMENT_HANDOVER_FULL_SERVICE}
              </td>
              <td data-qa-id={`${qaIdPrefix}-document-handover-direct-auction`}>
                {translations.DOCUMENT_HANDOVER_DIRECT}
              </td>
            </tr>
            <tr>
              <th data-qa-id={`${qaIdPrefix}-payment`}>
                {translations.PAYMENT}
              </th>
              <td data-qa-id={`${qaIdPrefix}-payment-full-service`}>
                {translations.PAYMENT_FULL_SERVICE}
              </td>
              <td data-qa-id={`${qaIdPrefix}-payment-direct-auction`}>
                {translations.PAYMENT_DIRECT}
              </td>
            </tr>
            <tr>
              <th data-qa-id={`${qaIdPrefix}-claims-handling`}>
                {translations.CLAIMS_HANDLING}
              </th>
              <td data-qa-id={`${qaIdPrefix}-claims-handling-full-service`}>
                {translations.CLAIMS_HANDLING_FULL_SERVICE}
              </td>
              <td data-qa-id={`${qaIdPrefix}-claims-handling-direct-auction`}>
                {translations.CLAIMS_HANDLING_DIRECT}
              </td>
            </tr>
            <tr>
              <th data-qa-id={`${qaIdPrefix}-total-buyer-fee`}>
                {translations.TOTAL_BUYER_FEE}
              </th>
              <td data-qa-id={`${qaIdPrefix}-total-buyer-fee-full-service`}>
                {translations.TOTAL_BUYER_FEE_FULL_SERVICE}
              </td>
              <td data-qa-id={`${qaIdPrefix}-total-buyer-fee-direct-auction`}>
                {translations.TOTAL_BUYER_FEE_DIRECT}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <td>
                <Button
                  theme={ButtonThemes.outlined}
                  extraClass={styles.button}
                  onClick={() => {
                    submitButtonClicked.current = 'fullService';
                    onFullServiceClick();
                  }}
                  isDisabled={isCheckingBeforeSubmit}
                  qaId={`${qaIdPrefix}-select-full-service-button`}
                >
                  {translations.SELECT_FULL_SERVICE}
                  {isCheckingBeforeSubmit &&
                    submitButtonClicked.current === 'fullService' && (
                      <Loader extraClass={styles.loader} />
                    )}
                </Button>
              </td>
              <td>
                <Button
                  theme={ButtonThemes.outlined}
                  extraClass={styles.button}
                  onClick={() => {
                    submitButtonClicked.current = 'directAuction';
                    onDirectAuctionClick();
                  }}
                  isDisabled={isCheckingBeforeSubmit}
                  qaId={`${qaIdPrefix}-select-direct-auction-button`}
                >
                  {translations.SELECT_DIRECT}
                  {isCheckingBeforeSubmit &&
                    submitButtonClicked.current === 'directAuction' && (
                      <Loader extraClass={styles.loader} />
                    )}
                </Button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Card>
  );
};

export { ChooseAuction };
