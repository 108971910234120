import * as React from 'react';
import styles from './index.module.scss';

const FrontInterior: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="175"
      height="131"
      viewBox="0 0 175 131"
      className={styles.container}
    >
      <defs>
        <mask
          id="prefix__b"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <mask
          id="prefix__c"
          width="175"
          height="131"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#prefix__a" />
        </mask>
        <rect id="prefix__a" width="175" height="131" x="0" y="0" rx="4" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g
          className={styles.background}
          strokeDasharray="4"
          strokeWidth="2"
          mask="url(#prefix__b)"
        >
          <use mask="url(#prefix__c)" xlinkHref="#prefix__a" />
        </g>
        <g className={styles.stroke} mask="url(#prefix__d)">
          <g transform="translate(1.274 -1.955)">
            <path
              strokeWidth="1.5"
              d="M10.157 5.922c-1.115-.485-3.945-.243-8.488.729-2.225 2.611-2.225 7.954 0 16.028 3.34 12.11 31.281 77.683 33.021 81.178 1.16 2.33 5.305 13.92 12.436 34.771"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M5.45 7.327c3.167-1.873 7.81-1.873 13.929 0 1.177.067 2.572 1.644 4.184 4.73 2.418 4.63 6.406 14.13 5.237 17.644-1.17 3.514-5.98 3.57-8.533 4.833-2.553 1.262-9.384 4.43-12.632 3.235"
            />
            <path
              strokeWidth="1.5"
              d="M5.45 7.327c-1.012 4.046-.283 7.96 2.185 11.74 3.703 5.67 8.028 14.593 1.845 18.967M7.635 40.68c5.2-1.764 8.763-2.646 10.687-2.646 2.886 0 5.356-1.645 12.526 4.828 7.17 6.473 28.186 30.677 29.677 35.342 1.49 4.665-.678 10.673-3.59 16.022-2.91 5.35-6.674 15.711-6.674 17.689 0 1.977-1.253 10.071-3.135 10.071-1.883 0-3.484.39-6.366-1.997"
            />
            <path
              strokeWidth="1.5"
              d="M57.846 92.478c11.788-6.225 20.294-9.574 25.518-10.048 7.837-.71 14.79 1.606 17.971 5.024 0 0 3.124-.805 5.31 2.11 2.185 2.914 7.916 25.685 6.323 29.066-1.594 3.38-8.363 6.73-11.633 6.73-3.27 0-44.422 1.66-54.21-3.35"
            />
            <path
              strokeWidth="1.5"
              d="M109.53 122.01c.56-6.722-1.428-10.082-5.963-10.082-6.801 0-49.728 4.703-54.568 8.48"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M61.009 84.21c17.399-4.859 34.81-7.652 52.235-8.377l16.752-22.752 21.817 33.801c3.198-2.968 5.455-4.945 6.772-5.93M109.623 95.084L150.715 97.74"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M174.022 125.624c-8.019-5.77-15.788-15.065-23.307-27.884-7.52-12.82-13.037-21.186-16.552-25.1"
            />
            <path
              strokeWidth="1.5"
              d="M23.563 11.328c1.88-4.293 3.695-6.44 5.446-6.44 1.752 0 4.28.487 7.585 1.462 4.266 8.894 5.734 13.84 4.401 14.836-1.999 1.495-4.97 3.341-8.478 3.341h-3.508"
            />
            <path
              strokeWidth="1.5"
              d="M34.322 24.527c1.383-.826 1.844-3.392 1.383-7.696-.461-4.304-2.137-8.285-5.028-11.943"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M29.01 29.495c6.175-2.053 10.329-3.08 12.46-3.08 3.199 0 11.295 11.635 13.507 13.328 2.212 1.694 11.154 11.568 12.747 14.635 1.593 3.066.086 9.046-1.59 10.934-1.116 1.259-1.773 2.167-1.97 2.725"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M53.151 67.116c8.34.013 14.203.968 17.592 2.866 1.273 1.663-.29 8.222-1.316 8.222h-8.418M71.22 75.108c13.287-3.695 26.637-6.359 40.05-7.992 0 0 7.642-18.491 10.801-23.008 0 0-3.662-9.026-7.006-12.34"
            />
            <path
              strokeWidth="1.5"
              d="M66.134 65.312c7.76-4.504 13.828-6.924 18.202-7.258 6.561-.5 14.52 1.606 14.52 1.606s2.958 3.587 2.958 8.377"
            />
            <path
              className={styles.fill}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M105.312 73.75c2.138-2.874 2.32-4.648 4.95-5.713 2.414-2.782 2.775-4.446 1.084-4.991-1.71-1.183-3.355-1.181-4.935.005-1.58 1.186-1.947 2.848-1.099 4.986-2.908 2.95-4.074 4.485-3.498 4.602"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M103.308 69.982c-4.566.593-7.21 1.216-7.931 1.868-.721.651-.721 2.341 0 5.07M108.525 69.982L110.793 69.982 111.998 75.108"
            />
            <ellipse
              cx="107.763"
              cy="37.505"
              strokeWidth="1.5"
              rx="4.627"
              ry="15.87"
              transform="rotate(24 107.763 37.505)"
            />
            <ellipse
              cx="107.415"
              cy="37.034"
              strokeWidth="1.5"
              rx="2.183"
              ry="11.47"
              transform="rotate(24 107.415 37.034)"
            />
            <path
              strokeWidth="1.5"
              d="M105.048 50.779L110.262 48.209 111.998 50.779 116.815 54.875"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M55.476 1.955c46.579 11.662 86.095 30.362 118.546 56.1"
            />
            <path
              strokeWidth="1.5"
              d="M47.126 0c11.798 22.083 18.488 37.017 20.07 44.801 1.583 7.785 2.924 13.866 4.024 18.245M61.009 28.079L106.835 28.61M69.427 78.204L71.22 82.43M26.105 33.111L27.104 39.707"
            />
            <path d="M34.899 24.924L36.043 27.231M30.677 24.924L31.88 28.61" />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M103.308 34.848c-23.157.486-35.025.486-35.605 0-.58-.486.293-1.309 2.62-2.47l34.725-.61"
            />
            <path
              strokeWidth="1.5"
              d="M116.815 23.15c6.318 4.967 11.316 9.138 14.993 12.515 10.138 9.307 22.027 21.632 35.668 36.974 0 .541-2.964 3.312-8.891 8.312l-31.529-40.274 3.911-5.012"
            />
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M174.022 73.45c-9.069 5.84-14.714 11.28-16.935 16.317-3.331 7.558 14.302 27.799 17.913 28.72"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { FrontInterior };
