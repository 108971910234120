import { useInspection } from 'shared/components/InspectionContext';
import { useMediaUploader } from 'shared/utils/useMediaUploader';

const IMAGE_SOURCE_NUMBER = 11;

const usePdfUpdater = () => {
  const { stockNumber } = useInspection();
  const { uploadMedia } = useMediaUploader(
    `/v1.1/car/${stockNumber}/inspection-file`,
  );

  return async (file: File) => {
    if (!stockNumber) {
      throw new Error('Stock number is null');
    }

    const { data } = await uploadMedia({
      file,
      stockNumber,
      part: 'vehicle__report_pdf',
      source: IMAGE_SOURCE_NUMBER,
    });

    return data.wkda.url;
  };
};

export { usePdfUpdater };
