import * as React from 'react';
import { useTranslation } from 'shared/hooks/useTranslation';
import { VinStatus } from 'shared/utils/graphqlEnums';
import type { VinStatus as VinStatusType } from 'gql/graphql';
import {
  NoRerunRemainingModal,
  RerunRemainingModal,
  NoRerunRemainingModalType,
} from 'shared/components/WarningModal';

type Props = {
  isOpen: boolean;
  vinStatus?: VinStatusType;
  [key: string]: unknown;
};

const WarningModal: React.FC<Props> = (props) => {
  const { translations } = useTranslation();

  if (!props.isOpen) {
    return null;
  }

  switch (props.vinStatus) {
    case VinStatus.AuctionEnded:
      return (
        <RerunRemainingModal
          {...(props as React.ComponentProps<typeof RerunRemainingModal>)}
          secondaryButtonLabel={translations.CONTINUE}
          trackingEvents={{
            view: 'SubmitDuplicateVINWarning',
            mainButtonClick: 'SubmitDuplicateVINWarningViewAuction',
            secondaryButtonClick: 'SubmitDuplicateVINWarningContinue',
          }}
        />
      );
    case VinStatus.RunningAuction:
    case VinStatus.RerunLimitExhausted:
      return (
        <NoRerunRemainingModal
          {...(props as React.ComponentProps<typeof NoRerunRemainingModal>)}
          modalType={NoRerunRemainingModalType.SubmitToAuction}
          trackingEvents={{
            view: 'SubmitDuplicateVINBlocked',
            mainButtonClick: 'SubmitDuplicateVINBlockedViewAuction',
            secondaryButtonClick: 'SubmitDuplicateVINBlockedClose',
          }}
        />
      );
    default:
      return null;
  }
};

export { WarningModal };
