import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, ButtonThemes } from '@auto1-ui/button';
import { Card } from 'shared/components/Card';
import { FormUploader } from 'shared/components/FormUploader';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import { InputLabel } from 'shared/components/InputLabel';
import { Typography } from 'shared/components/Typography';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import { FormNumberInput } from 'shared/components/FormNumberField';
import { useTranslation } from 'shared/hooks/useTranslation';
import type { Form } from '../../types';
import {
  ACCIDENT_REPAIR_STATUS,
  ACCIDENT_REPAIRERS,
  ACCIDENT_REPAIRED,
  ACCIDENT_POOR_OR_PARTIALLY_REPAIRED,
  ACCIDENT_NOT_REPAIRED,
  REPAIRER_OEM_FRANCHISE_DEALERSHIP,
  REPAIRER_INDEPENDENT,
  REPAIRER_UNKNOWN,
} from '../../constants';
import {
  shouldDisableNotRepairedOption,
  shouldDisableUnknownOption,
  shouldShowDocumentsUploader,
} from '../../utils';
import styles from '../../index.module.scss';

type Props = {
  qaIdPrefix: string;
};

const Repair: React.FC<Props> = ({ qaIdPrefix }) => {
  const { translations } = useTranslation();
  const {
    setValue,
    trigger,
    watch,
    formState: { isSubmitted },
  } = useFormContext<Form>();
  const [repairStatus, repairedBy, isRepairCostAvailable, areas] = watch([
    'repair.repairStatus',
    'repair.repairedBy',
    'repair.isRepairCostAvailable',
    'areas',
  ]);

  React.useEffect(() => {
    if (isRepairCostAvailable) {
      setValue('repair.repairCost', null);
    }
  }, [isRepairCostAvailable, setValue]);

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      showBottomBorder
      id="repair"
      qaIdPrefix={`${qaIdPrefix}-container`}
    >
      <div className={styles.title}>
        <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
          {translations.ACCIDENT_REPAIR_TITLE}
        </Typography>
      </div>

      <div
        className={styles.formElementContainer}
        data-qa-id={`${qaIdPrefix}-repair-status-toggle-container`}
      >
        <FormToggleButton
          label={translations.ACCIDENT_QUESTION_WERE_DAMAGES_REPAIRED}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix={`${qaIdPrefix}-repair-status-toggle`}
          options={ACCIDENT_REPAIR_STATUS(translations).map((option) =>
            option.value === ACCIDENT_NOT_REPAIRED
              ? { ...option, isDisabled: shouldDisableNotRepairedOption(areas) }
              : option,
          )}
          name="repair.repairStatus"
          defaultValue={null}
        />
      </div>

      {repairStatus &&
        [ACCIDENT_REPAIRED, ACCIDENT_POOR_OR_PARTIALLY_REPAIRED].includes(
          repairStatus,
        ) && (
          <>
            <div
              className={styles.formElementContainer}
              data-qa-id={`${qaIdPrefix}-repaired-by-toggle-container`}
            >
              <FormToggleButton
                label={translations.ACCIDENT_QUESTION_WHO_REPAIRED_DAMAGE}
                containerClassName={styles.repairedBytoggleButton}
                qaIdPrefix={`${qaIdPrefix}-repaired-by-toggle`}
                options={ACCIDENT_REPAIRERS(translations).map((option) =>
                  option.value === REPAIRER_UNKNOWN
                    ? {
                      ...option,
                      isDisabled: shouldDisableUnknownOption(areas),
                    }
                    : option,
                )}
                name="repair.repairedBy"
                defaultValue={null}
              />
            </div>
          </>
        )}

      {/* DOCUMENTS */}
      {shouldShowDocumentsUploader(areas, repairedBy) && (
        <div className={styles.uploaderWrapper}>
          <InputLabel>{translations.ACCIDENT_DOCUMENTS}</InputLabel>

          <FormUploader
            name="repair.files"
            defaultValue={[]}
            allowMultipleFiles
            uploadFileTypes="image/png, image/jpeg, image/heic, image/heif, application/pdf"
            processSelectedFile={processHeicFile}
            extraClassName={styles.uploaderStyles}
            qaIdPrefix={`${qaIdPrefix}-documents`}
            inputQaId={`${qaIdPrefix}-documents-input`}
          >
            {({ handleUploadClick }) => (
              <>
                <div className={styles.uploadCloudIcon} />
                <Typography>{translations.DROP_PHOTOS_HERE}</Typography>
                <Button
                  qaId={`${qaIdPrefix}-browse-button`}
                  extraClass={styles.uploadButtonText}
                  theme={ButtonThemes.link}
                  onClick={(event) => {
                    event.preventDefault();
                    handleUploadClick();
                  }}
                >
                  {translations.BROWSE}
                </Button>
              </>
            )}
          </FormUploader>
        </div>
      )}

      {repairedBy &&
        [REPAIRER_OEM_FRANCHISE_DEALERSHIP, REPAIRER_INDEPENDENT].includes(
          repairedBy,
        ) && (
          <div className={styles.textFieldContainer}>
            <FormNumberInput
              label={
                <>
                  {translations.ACCIDENT_PREINVESTED_REPAIR_COST}{' '}
                  <span className={styles.optionalLabel}>
                    {translations.ACCIDENT_APPROXIMATELY}
                  </span>
                </>
              }
              name="repair.repairCost"
              icon="€"
              placeholder={translations.ACCIDENT_PRICE_IN_EURO}
              className={styles.repairCostInput}
              disabled={!!isRepairCostAvailable}
              qaIdPrefix={`${qaIdPrefix}-repaired-consts`}
              defaultValue={null}
            />

            <FormCheckbox
              name="repair.isRepairCostAvailable"
              uiLabel={translations.ACCIDENT_NOT_SURE}
              qaIdPrefix={`${qaIdPrefix}-repair-cost-avaliable`}
              containerClassName={styles.checkBoxLabel}
              afterChange={() => {
                if (isSubmitted) {
                  trigger('repair.repairCost');
                }
              }}
              defaultValue={false}
            />
          </div>
        )}

      <div
        className={styles.formElementContainer}
        data-qa-id={`${qaIdPrefix}-car-is-repaired-toggle-container`}
      >
        <FormToggleButton
          label={translations.ACCIDENT_QUESTION_IS_CAR_TOTAL_LOSS}
          containerClassName={styles.toggleButtonHorizontal}
          errorClassName={styles.toggleErrorHorizontal}
          qaIdPrefix={`${qaIdPrefix}-car-is-total-loss-toggle`}
          options={[
            {
              label: translations.YES,
              value: true,
            },
            {
              label: translations.UNKNOWN,
              value: false,
            },
          ]}
          name="repair.isTotalLoss"
          defaultValue={null}
        />
      </div>
    </Card>
  );
};

export { Repair };
