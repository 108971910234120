import * as React from 'react';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import { FormInputPlaceholder } from 'shared/components/FormInput/Placeholder';
import styles from '../index.module.scss';

const MileagePlaceholder: React.FC = () => (
  <Card paddingTop={32} paddingBottom={32} showBottomBorder>
    <div className={styles.title}>
      <Typography variant="titleXL">
        <Skeleton width="83px" height="100%" />
      </Typography>
    </div>
    <FormInputPlaceholder labelWidth="143px" width="325px" />
  </Card>
);

export { MileagePlaceholder };
