import * as React from 'react';
import { Typography } from 'shared/components/Typography';
import { Loader } from '@auto1-ui/loader';
import cn from 'classnames';
import styles from './index.module.scss';

type Props = {
  className?: string;
  message?: string;
};

const PageLoader: React.FC<Props> = ({
  className = '',
  message = 'Loading',
}) => {
  return (
    <div className={cn(styles.loaderContainer, className)}>
      <div className={styles.titleContainer}>
        <Typography variant="titleLBlue">{message}</Typography>
        <Loader className={styles.loader} />
      </div>
    </div>
  );
};

export { PageLoader };
