import * as React from 'react';
import type { VariationType } from '../types';

type Props = {
  variant: VariationType;
  qaIdPrefix?: string;
};

const Roof: React.FC<Props> = ({ variant, qaIdPrefix = '' }) => (
  <g className={variant} data-qa-id={`${qaIdPrefix}-roof`}>
    <path
      d="M333.67 342.007C328.854 357.811 323.108 381.581 323.108 405.019V522.856C323.108 524.807 323.423 526.758 324.054 528.641C324.208 529.075 325.998 533.361 329.647 541.915L330.448 543.789L328.462 544.241C308.07 548.884 280.926 551.44 252.017 551.44C223.109 551.44 195.743 548.858 175.291 544.181L173.305 543.729L174.107 541.855C178.71 531.078 179.571 528.956 179.673 528.649C180.304 526.784 180.62 524.841 180.62 522.864V405.028C180.62 381.624 174.882 357.845 170.066 342.016L169.52 340.227L171.353 339.852C192.12 335.566 221.472 333.104 251.872 333.104C282.273 333.104 311.625 335.566 332.392 339.852L334.225 340.227L333.679 342.016L333.67 342.007Z"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </g>
);

export { Roof };
