const SubmitToAuctionModal = Object.freeze({
  FullService: 'FullService',
  DirectAuction: 'DirectAuction',
  BatchAuction: 'BatchAuction',
});

const AuctionSelectorPage = Object.freeze({
  ChooseAuctionPage: 'ChooseAuctionPage',
  SellingChannelPage: 'SellingChannelPage',
});

const InstantOfferButtonStatus = Object.freeze({
  INVISIBLE: 'INVISIBLE',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
});

export {
  InstantOfferButtonStatus,
  SubmitToAuctionModal,
  AuctionSelectorPage,
};
