import * as React from 'react';
import { Skeleton } from 'shared/components/Skeleton';

type Props = {
  labelWidth?: string;
  width?: string;
};

const FormInputPlaceholder: React.FC<Props> = ({
  labelWidth = '100%',
  width = '100%',
}) => (
  <div style={{ marginBottom: '6px' }}>
    <Skeleton
      width={labelWidth}
      height="19px"
      style={{ marginBottom: '8px', display: 'block' }}
    />
    <Skeleton width={width} height="40px" />
  </div>
);

export { FormInputPlaceholder };
