import * as yup from 'yup';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import type { Translations } from 'shared/utils/translations';
import { IGNORED_QUESTIONS, FIRST_QUESTION } from './utils';
import type { Question, FormQuestion } from './types';

const MILEAGE_SUSPICIOUS_CHECKBOX = [
  'isTachometerReplaced',
  'isMileageConflictingCarCondition',
  'isOdometerBroken',
  'isMileageConflictingServiceHistory',
  'isMileageConflictingDatabase',
] as const;

function questionToSchema({
  isPdfInspection,
  translations,
  answers,
  question,
}: {
  isPdfInspection: boolean;
  translations: Translations;
  answers: FormQuestion;
  question: Question;
}): Record<string, yup.BaseSchema<number | boolean | null | undefined>> {
  if (
    isPdfInspection &&
    question.id === FIRST_QUESTION &&
    answers[`_${FIRST_QUESTION}`] === null
  ) {
    return {};
  }

  if (question.allowedOptions === 0) {
    return (
      question.options
        ?.map((question) =>
          questionToSchema({
            isPdfInspection,
            translations,
            answers,
            question,
          }),
        )
        .reduce((acc, curr) => ({ ...acc, ...curr }), {}) ?? {}
    );
  }

  if (question.allowedOptions === 1) {
    return {
      [`_${question.id}`]: yup
        .number()
        .nullable()
        .required(translations.CHOOSE_ONE_OPTION),

      ...(question.options
        ?.filter((option) => option.id === answers[`_${question.id}`])
        ?.map((question) =>
          questionToSchema({
            isPdfInspection,
            translations,
            answers,
            question,
          }),
        )
        ?.reduce((acc, curr) => ({ ...acc, ...curr }), {}) ?? {}),
    };
  }

  return {
    [`_${question.id}`]: yup
      .number()
      .nullable()
      .test(
        'required',
        translations.CHOOSE_ONE_OPTION,
        () =>
          question.options?.some(
            (option) => answers[`_${option.id}`] === true,
          ) ?? true,
      ),
    ...(question.options
      ?.filter((option) => answers[`_${option.id}`] === true)
      ?.map((question) =>
        questionToSchema({
          isPdfInspection,
          translations,
          answers,
          question,
        }),
      )
      ?.reduce((acc, curr) => ({ ...acc, ...curr }), {}) ?? {}),
  };
}

const getTestDriveSchema = ({
  translations,
  questions,
  isPdfInspection,
  isOptional,
}: {
  translations: Translations;
  questions?: ReadonlyArray<Question>;
  isPdfInspection: boolean;
  isOptional: boolean;
}) => {
  if (isOptional) {
    return yup.object({});
  }

  return yup.object({
    mileage: yup.object({
      currentMileage: yup
        .number()
        .nullable(true)
        .required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
      isMileageManipulated: yup
        .boolean()
        .nullable()
        .required(translations.CHOOSE_ONE_OPTION),
      mileageSuspicionsOptions: yup.mixed().when('isMileageManipulated', {
        is: true,
        then: (schema) =>
          schema.test(
            'required',
            translations.CHOOSE_ONE_OPTION,
            (_, context) =>
              Object.values(
                pick(
                  context.parent.mileageSuspicions ?? {},
                  MILEAGE_SUSPICIOUS_CHECKBOX,
                ),
              ).some((value) => value === true),
          ),
      }),
      mileageSuspicions: yup.object().when('isMileageManipulated', {
        is: true,
        then: (schema) =>
          schema.shape({
            isTachometerReplaced: yup.boolean(),
            isTachometerReplacementMileageAvailable: yup.boolean(),
            tachometerReplacementMileage: yup
              .number()
              .nullable(true)
              .when(
                [
                  'isTachometerReplaced',
                  'isTachometerReplacementMileageAvailable',
                ],
                {
                  is: (
                    isTachometerReplaced: boolean,
                    isTachometerReplacementMileageAvailable: boolean,
                  ) =>
                    isTachometerReplaced &&
                    !isTachometerReplacementMileageAvailable,
                  then: (schema) =>
                    schema.required(translations.THIS_FIELD_CANNOT_BE_EMPTY),
                },
              ),
          }),
      }),
    }),
    question: yup.lazy((answers: FormQuestion) =>
      yup.object(
        questions?.reduce(
          (acc, question) =>
            omit(
              {
                ...acc,
                ...questionToSchema({
                  question,
                  translations,
                  isPdfInspection,
                  answers,
                }),
              },
              IGNORED_QUESTIONS.map((id) => `_${id}`),
            ),
          {},
        ) ?? {},
      ),
    ),
  });
};

export { getTestDriveSchema };
