import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  reasons?: ReadonlyArray<{
    id: number;
    reasonName: string;
    comment?: string | null;
  }>;
};

const FailureReasons = React.forwardRef<HTMLDivElement, Props>(
  ({ qaIdPrefix = 'failure-reason', reasons = [] }, ref) => {
    const { translate, translations } = useTranslation();
    const {
      formState: { errors, isSubmitted },
      trigger,
    } = useFormContext();

    const getTranslation = React.useCallback(
      (key) => translate(`a1-inspection-app-${key}`),
      [translate],
    );

    return (
      <div ref={ref}>
        <Card
          externalStyle={styles.card}
          paddingTop={24}
          paddingBottom={24}
          showBottomBorder
          qaIdPrefix={qaIdPrefix}
        >
          <Typography
            data-qa-id={`${qaIdPrefix}-title`}
            variant="titleXL"
            tag="div"
          >
            {translations.FAILURE_REASONS_TITLE}
          </Typography>
          <div>
            {reasons.map((reason, index) => {
              return (
                <div key={reason.id} className={styles.failureReason}>
                  <FormCheckbox
                    name={`failedReasons[${reason.id}]`}
                    uiLabel={getTranslation(reason.reasonName)}
                    qaIdPrefix={`${qaIdPrefix}-checkbox-${index + 1}`}
                    containerClassName={styles.checkBoxLabel}
                    defaultValue={false}
                    afterChange={() => {
                      if (isSubmitted) {
                        trigger('failedReasons');
                      }
                    }}
                    shouldUnregister={false}
                  />
                  {reason.comment && (
                    <Typography
                      tag="div"
                      data-qa-id={`${qaIdPrefix}-comment-${index + 1}`}
                      additonalClassNames={styles.comment}
                      variant="textSmall"
                    >
                      {reason.comment}
                    </Typography>
                  )}
                </div>
              );
            })}
            {errors?.failedReasons?.message && (
              <Typography
                tag="div"
                additonalClassNames={styles.failureReasonError}
                variant="textXSRed"
                data-qa-id={`${qaIdPrefix}-error-message`}
              >
                {errors?.failedReasons?.message}
              </Typography>
            )}
          </div>
        </Card>
      </div>
    );
  },
);

FailureReasons.displayName = 'FailureReasons';

export { FailureReasons };
