import type { ValidationError } from 'yup';

const calculateSectionProgress = (filled: number, total: number) => {
  return Math.round((filled / total) * 100);
};

const getSectionProgress = ({
  errors = {},
  totalFieldsNb = 0,
  isOptional = false,
}: {
  errors: { [key: string]: { type: string; message: string } };
  totalFieldsNb: number;
  isOptional?: boolean;
}) => {
  if (isOptional && Object.keys(errors).length === 0) {
    return 100;
  }

  if (totalFieldsNb === 0 && Object.keys(errors).length === 0) {
    return 0;
  }

  const emptyFields = Object.values(errors).reduce<number>((accu, { type }) => {
    if (type === 'required') {
      return accu + 1;
    }
    return accu;
  }, 0);
  const filledInFieldsNb = totalFieldsNb - emptyFields;

  return calculateSectionProgress(filledInFieldsNb, totalFieldsNb);
};

const getSectionProgressYup = ({
  errors,
  totalFieldsNb = 0,
  isOptional = false,
}: {
  errors: ValidationError[];
  totalFieldsNb?: number;
  isOptional?: boolean;
}) => {
  if (isOptional && errors.length === 0) {
    return 100;
  }

  if (totalFieldsNb === 0 && errors.length === 0) {
    return 0;
  }

  const emptyFields = errors.reduce((sum, { type }) => {
    if (type === 'required') {
      return sum + 1;
    }

    return sum;
  }, 0);
  const filledInFieldsNb = totalFieldsNb - emptyFields;

  return calculateSectionProgress(filledInFieldsNb, totalFieldsNb);
};

const getPageProgress = (progressArray: ReadonlyArray<number>) => {
  const subsectionsNb = progressArray.length;
  const sum = progressArray.reduce((acc, val) => acc + val, 0);
  return Math.round(sum / subsectionsNb);
};

const getSectionErrors = (
  errors: ReadonlyArray<ValidationError>,
  sectionPrefix: string,
) => {
  return errors.reduce((accu, error) => {
    if (error.path?.startsWith(sectionPrefix)) {
      return { ...accu, [error.path]: error };
    }
    return accu;
  }, {});
};

const getSectionErrorsYup = (
  errors: ReadonlyArray<ValidationError>,
  sectionPrefix: string,
) => {
  return errors.reduce<{ [key: string]: { type: string; message: string } }>(
    (acc, curr) => {
      if (curr.path?.startsWith(sectionPrefix)) {
        acc[curr.path] = {
          type: curr.type ?? '',
          message: curr.message,
        };
      }

      return acc;
    },
    {},
  );
};

export {
  calculateSectionProgress,
  getSectionProgress,
  getSectionProgressYup,
  getPageProgress,
  getSectionErrors,
  getSectionErrorsYup,
};
