import * as React from 'react';
import { useInspectionsV2Query } from 'gql/graphql';
import type { InspectionsV2Query } from 'gql/graphql';
import { withTransaction } from '@elastic/apm-rum-react';

import { Card } from 'shared/components/Card';
import { ConvertToFullEvaModal } from 'shared/components/WarningModal';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Header } from './components/header';
import { Inspections } from './components/inspections';
import { useCheckInspectionBeforeOpen } from './useCheckInspectionBeforeOpen';

type Inspection = Omit<
  NonNullable<InspectionsV2Query['inspectionsV2']>['inspections'][number],
  'status'
>;

type Props = {
  qaIdPrefix?: string;
};

const InspectionListPage: React.FC<Props> = ({
  qaIdPrefix = 'inspections-list-page',
}) => {
  const [filterValue, setFilterValue] = React.useState('');
  const { translations } = useTranslation();
  const { data: inspectionsData, loading } = useInspectionsV2Query({
    variables: {
      branchesIds: [],
      statusesIds: [
        'UNFINISHED',
        'FAILED',
        'WAITING_A1_PRICE_INDICATOR',
        'A1_PRICE_INDICATOR_GIVEN',
        'GP_ACCEPTED',
        'GP_EXPIRED',
        'GP_REJECTED',
      ],
    },
  });

  const {
    showModal,
    isPiConvertibleToFullEva,
    inspectionInfo,
    handleCloseModal,
    handleOpenInspection,
  } = useCheckInspectionBeforeOpen();

  return (
    <>
      <Card paddingTop={32} paddingBottom={32}>
        <Header
          qaIdPrefix={qaIdPrefix}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          handleOpenInspection={handleOpenInspection}
        />

        {loading && translations.LOADING_INSPECTIONS_LIST}

        {!loading && inspectionsData && (
          <Inspections
            qaIdPrefix={qaIdPrefix}
            inspectionsData={inspectionsData}
            filterValue={filterValue}
            handleOpenInspection={handleOpenInspection}
          />
        )}
      </Card>
      {showModal ? (
        <ConvertToFullEvaModal
          isOkButtonDisabled={!isPiConvertibleToFullEva}
          handleClose={handleCloseModal}
          inspectionInfo={inspectionInfo as Inspection}
        ></ConvertToFullEvaModal>
      ) : null}
    </>
  );
};

const InspectionListPageWithTransaction = withTransaction(
  'InspectionListPage',
  'component',
)(InspectionListPage);

export { InspectionListPageWithTransaction as InspectionListPage };
