import { Source } from '../car-status-data';
import type { EquipmentItem } from 'gql/graphql';

const originalSources = [Source.VIN, Source.ECODE, Source.DEFAULT];
const countOriginalEquipments = (items: Array<EquipmentItem>) => {

  return items.reduce(
    ([original, nonOriginal], { source }) => {
      if (originalSources.includes(source)) {
        return [original + 1, nonOriginal];
      }

      return [original, nonOriginal + 1];
    },
    [0, 0],
  );
}
const isToggleField = (items: Array<EquipmentItem>): boolean => {
  const [countOriginal, countNonOriginal] = countOriginalEquipments(items);

  return countOriginal === 1 && countNonOriginal === 0;
};

export { isToggleField };
