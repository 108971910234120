import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@auto1-ui/button';
import { CheckedInCircle } from '@auto1-ui/icons';
import { appRoutes } from 'appRoutes';
import { useTranslation } from 'shared/hooks/useTranslation';
import isMobileEvaluator from 'shared/utils/isMobileEvaluatorApp';
import { Typography } from '../../shared/components/Typography';
import { Card } from '../../shared/components/Card';
import styles from './index.module.scss';

const CompletedInspection: React.FC = () => {
  const history = useHistory();
  // todo, once the menu and the anchor scrolling is done remove this
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { translations } = useTranslation();
  return (
    <>
      <Card paddingTop={32} paddingBottom={32} externalStyle={styles.card}>
        <div className={styles.iconContainer}>
          <CheckedInCircle className={styles.icon} />
        </div>
        <div className={styles.titleContainer}>
          <Typography
            variant="titleXL"
            tag="h1"
            additonalClassNames={styles.title}
          >
            {translations.CAR_INSPECTION_COMPLETED}
          </Typography>
          <Typography tag="h2" additonalClassNames={styles.subtitle}>
            {translations.CAR_IS_BEING_REVIEWED}
          </Typography>
        </div>
        <div className={styles.actionsContainer}>
          <a
            href={
              isMobileEvaluator()
                ? appRoutes.inspectionList()
                : appRoutes.remarketing()
            }
            className={`${styles.dashboardLink} ${styles.actionButton}`}
          >
            {translations.GO_TO_DASHBOARD}
          </a>
          <Button
            theme="orange"
            extraClass={styles.actionButton}
            onClick={() => history.push(appRoutes.carInfo(true))}
          >
            {translations.INSPECT_ANOTHER_CAR}
          </Button>
        </div>
      </Card>
    </>
  );
};

export { CompletedInspection };
