import * as yup from 'yup';
import type {
  AccidentAreaRepairStatus,
  AccidentAreaRepairedBy,
  AccidentAreaOption,
} from 'gql/graphql';
import { getRequiredMessage } from 'shared/utils/yupValidationHelpers';
import type { Translations, Translate } from 'shared/utils/translations';
import type { Damage, Form } from './types';
import {
  REPAIRER_UNKNOWN,
  ACCIDENT_NOT_REPAIRED,
  REASONS_WITH_DAMAGE_IMAGES,
} from './constants';
import { AREA_LABELS } from './components/Areas/constants';
import {
  mapAccidentDamageAreaToAccidentArea,
  isDocumentsRequired,
} from './utils';

const MAX_FILE_SIZE_MB = 10;

const requiredBool = (
  optional: boolean,
  translations: Translations,
  type?: 'toggle' | 'file',
) => {
  if (optional) {
    return yup.bool().nullable();
  }
  return yup.bool().nullable().required(getRequiredMessage(translations, type));
};

function requiredStringToggle(optional: boolean, translations: Translations) {
  if (optional) {
    return yup.string().nullable();
  }

  return yup
    .string()
    .nullable()
    .required(getRequiredMessage(translations, 'toggle'));
}

const filesArray = (
  optionalSchema: boolean,
  translations: Translations,
  t: Translate,
) => {
  if (!optionalSchema) {
    return yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            'isFile',
            translations.AT_LEAST_ONE_FILE_IS_REQUIRED,
            (value) => value instanceof File || value?.absoluteUrl,
          )
          .test(
            'maxFilesize',
            t('a1-inspectionApp-max-file-size', {
              size: `${MAX_FILE_SIZE_MB} MB`,
            }),
            (value) => {
              if (!(value instanceof File)) {
                return true;
              }

              return value.size <= MAX_FILE_SIZE_MB * 1024 * 1024;
            },
          ),
      )
      .test('required', getRequiredMessage(translations, 'file'), (value) =>
        value !== undefined ? value.length > 0 : false,
      );
  }
  return yup.array().of(
    yup
      .mixed()
      .test('isFile', translations.AT_LEAST_ONE_FILE_IS_REQUIRED, (value) => {
        return value instanceof File || value?.absoluteUrl;
      }),
  );
};

function hasNoReasonsSelected(areas: Form['areas']) {
  const allReasons = Object.values(areas);

  if (
    allReasons.length === 0 ||
    allReasons.every(
      (reason) =>
        !reason ||
        (typeof reason === 'object' &&
          reason !== null &&
          Object.values(reason).length === 0),
    )
  ) {
    return true;
  }

  return false;
}

// Check if an accident from damage has reasons selected
function hasOrphanAccidentDamage(damages: Damage[], areas: Form['areas']) {
  const areasWithAccidentFromDamage =
    mapAccidentDamageAreaToAccidentArea(damages);

  return areasWithAccidentFromDamage.some((areaWithAccidentFromDamage) => {
    const accidentArea = areas[areaWithAccidentFromDamage];

    if (!accidentArea || Object.values(accidentArea).length === 0) {
      return true;
    }

    return false;
  });
}

// In case the user removes a damage or damage image, there will be inconsistent state in areas
function areasWithoutDamageImagesSelected(
  damages: Damage[],
  accidentAreaOptions: AccidentAreaOption[],
  areas: Form['areas'],
) {
  const areasWithAccidentFromDamage =
    mapAccidentDamageAreaToAccidentArea(damages);

  const areasWithoutDamageImages = areasWithAccidentFromDamage.filter(
    (areaWithAccidentFromDamage) => {
      const imageReasonsWithDamageMapping = Object.entries(
        areas[areaWithAccidentFromDamage] ?? {},
      ).filter(([reason]) =>
        REASONS_WITH_DAMAGE_IMAGES.includes(
          reason as typeof REASONS_WITH_DAMAGE_IMAGES[number],
        ),
      );

      return imageReasonsWithDamageMapping.some(([reason, images]) => {
        if (images === null) {
          return true; // invalid
        }
        const accidentReasonOption = accidentAreaOptions
          .find(({ area }) => area === areaWithAccidentFromDamage)
          ?.reasons?.find(
            ({ reason: reasonConfig }) => reasonConfig === reason,
          );

        if (!accidentReasonOption?.minImageCount) {
          return false; // valid
        }

        return images.length < accidentReasonOption.minImageCount;
      });
    },
  );

  return areasWithoutDamageImages;
}

function getAccidentSchema(
  translations: Translations,
  damages: Damage[],
  accidentAreaOptions: AccidentAreaOption[],
  optional: boolean,
  translate: Translate,
) {
  return yup.lazy((formValues) =>
    yup.object({
      accidents: yup.object({
        hasHadAccident: requiredBool(optional, translations, 'toggle'),
      }),
      areas: yup.object().when('accidents.hasHadAccident', {
        is: true,
        then: (schema) =>
          optional
            ? schema.optional()
            : schema.test('required', (value: Form['areas'], context) => {
                if (!value) {
                  return context.createError({
                    message: translations.ACCIDENT_ERROR_REASON_REQUIRED,
                    type: 'required',
                  });
                }

                if (hasNoReasonsSelected(value)) {
                  return context.createError({
                    message: translations.ACCIDENT_ERROR_REASON_REQUIRED,
                    type: 'required',
                  });
                }

                if (hasOrphanAccidentDamage(damages, value)) {
                  return context.createError({
                    message: translations.ACCIDENT_ERROR_REASON_REQUIRED,
                    type: 'required',
                  });
                }

                const areasWithoutDamageImages =
                  areasWithoutDamageImagesSelected(
                    damages,
                    accidentAreaOptions,
                    value,
                  );
                if (areasWithoutDamageImages.length) {
                  return context.createError({
                    message: translate(
                      'a1-inspectionApp-accident-missing-image-from-damage',
                      {
                        areas: areasWithoutDamageImages
                          .map((area) => translate(AREA_LABELS[area]))
                          .join(', '),
                      },
                    ),
                    type: 'required',
                  });
                }

                return true;
              }),
        otherwise: (schema) => schema.optional(),
      }),
      repair: yup.object().when('accidents.hasHadAccident', {
        is: true,
        then: (schema) =>
          schema.shape({
            repairStatus: requiredStringToggle(optional, translations),
            repairedBy: yup
              .string()
              .nullable()
              .when('repairStatus', {
                is: (value: AccidentAreaRepairStatus) =>
                  value && value !== ACCIDENT_NOT_REPAIRED,
                then: requiredStringToggle(optional, translations),
              }),
            isRepairCostAvailable: yup.bool().nullable(),
            repairCost: yup
              .number()
              .nullable(true)
              .when(['repairStatus', 'repairedBy', 'isRepairCostAvailable'], {
                is: (
                  repairStatus: AccidentAreaRepairStatus,
                  repairedBy: AccidentAreaRepairedBy,
                  isRepairCostAvailable: boolean | null,
                ) => {
                  if (!repairStatus || repairStatus === ACCIDENT_NOT_REPAIRED) {
                    return false;
                  }

                  if (!repairedBy || repairedBy === REPAIRER_UNKNOWN) {
                    return false;
                  }
                  return (
                    isRepairCostAvailable === false ||
                    isRepairCostAvailable === null
                  );
                },
                then: (schema) =>
                  optional
                    ? schema
                    : schema
                        .required(translations.THIS_FIELD_CANNOT_BE_EMPTY)
                        .min(1, translations.GREATER_THAN_ZERO),
              }),
            files: isDocumentsRequired(formValues)
              ? filesArray(optional, translations, translate)
              : yup.mixed(),
            isTotalLoss: requiredBool(optional, translations, 'toggle'),
          }),
      }),
    }),
  );
}

export { getAccidentSchema };
