import * as React from 'react';

import { Checked } from '@auto1-ui/icons';
import { Accordion } from '@auto1-ui/accordion';

import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';

import styles from './index.module.scss';

type Props = {
  initialState?: boolean;
  qaIdPrefix: string;
  title: string | React.ReactNode;
  onDelete: () => void;
  className?: string;
};

const DamageItemContainer: React.FC<Props> = ({
  initialState = false,
  qaIdPrefix,
  title,
  onDelete,
  className = '',
  children,
}) => {
  const { translations } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(initialState);

  const toggle = React.useCallback(() => setIsOpen((state) => !state), []);
  return (
    <div className={className} data-qa-id={qaIdPrefix}>
      <Accordion
        title={
          <>
            <Checked
              className={styles.checked}
              data-qa-id={`${qaIdPrefix}-checked`}
            />
            <Typography
              variant="textSmall"
              tag="div"
              className={styles.title}
              data-qa-id={`${qaIdPrefix}-title`}
            >
              {title}
            </Typography>
          </>
        }
        isOpen={isOpen}
        toggle={toggle}
        actions={
          <>
            <span
              data-qa-id={`${qaIdPrefix}-delete`}
              onClick={(e) => {
                e.stopPropagation();
                if (onDelete) {
                  onDelete();
                }
              }}
            >
              {translations.DELETE}
            </span>
          </>
        }
      >
        {children}
      </Accordion>
    </div>
  );
};

export { DamageItemContainer };
