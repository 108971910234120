import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@auto1-ui/button';
import { FormInput } from 'shared/components/FormInput';
import { FormVinInput } from 'shared/components/FormVinInput';
import { Grid } from 'shared/components/Grid';
import { useTranslation } from 'shared/hooks/useTranslation';
import { formatVinToUI } from 'shared/utils/formatVin';
import Loader from 'shared/components/Loader';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  isSubmitting?: boolean;
  isButtonDisabled?: boolean;
  serverError?: string;
  shouldUseVin?: boolean;
  handleLoadVin?: () => void;
  flow?: 'eva-inspection' | 'pdf-inspection';
  branchId?: number;
};

const CarVinInfo: React.FC<Props> = ({
  qaIdPrefix = 'car-information',
  isSubmitting,
  isButtonDisabled,
  serverError = '',
  handleLoadVin = () => {},
  children,
  shouldUseVin = false,
  flow = 'eva-inspection',
  branchId,
}) => {
  const { translations } = useTranslation();
  const { setValue } = useFormContext();

  React.useEffect(() => {
    if (flow !== 'pdf-inspection' && !shouldUseVin) {
      window.tracking.eva.auto1EVAVINScreen({
        reason: 'not-eligible',
        branch: branchId && `${branchId}`,
        duration: undefined,
      });
    }
  }, [flow, shouldUseVin, branchId]);

  return (
    <>
      <Grid item className={styles.fieldWrapper}>
        <FormVinInput
          label={translations.VIN}
          name="vin"
          placeholder={translations.VIN}
          qaIdPrefix={`${qaIdPrefix}-vin`}
          className={styles.vinField}
          disabled={isSubmitting}
          defaultValue=""
          onPaste={(event) => {
            event.preventDefault();

            const sanitizedValue = event.clipboardData
              .getData('text')
              .replace(/\s+/g, '');
            setValue('vin', formatVinToUI(sanitizedValue));
          }}
        />
        <p className={styles.errorMsg}>{serverError}</p>
      </Grid>
      <Grid columns={2}>
        <Grid item>
          <FormInput
            label={
              <>
                {translations.LICENSE_PLATE}{' '}
                <span className={styles.optionalLabel}>
                  {translations.OPTIONAL}
                </span>
              </>
            }
            name="licensePlate"
            qaIdPrefix={`${qaIdPrefix}-license-plate`}
            className={styles.input}
            placeholder={translations.LICENSE_PLATE}
            disabled={isSubmitting}
            defaultValue=""
          />
        </Grid>
        <Grid item>
          <FormInput
            label={
              <>
                {translations.INTERNAL_NUMBER}{' '}
                <span className={styles.optionalLabel}>
                  {translations.OPTIONAL}
                </span>
              </>
            }
            name="externalId"
            qaIdPrefix={`${qaIdPrefix}-external-id`}
            className={styles.input}
            placeholder={translations.INTERNAL_NUMBER}
            disabled={isSubmitting}
            defaultValue=""
          />
        </Grid>
      </Grid>
      {children}
      <Button
        theme="orange"
        extraClass={styles.button}
        isDisabled={isButtonDisabled || isSubmitting}
        onClick={handleLoadVin}
        qaId={`${qaIdPrefix}-load-data`}
      >
        {translations.LOAD_VIN_DATA}
        {isSubmitting && <Loader extraClass={styles.buttonLoader} />}
      </Button>
    </>
  );
};

export { CarVinInfo };
