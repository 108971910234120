import * as React from 'react';

const UploadContext = React.createContext({
  hasUploadInProgress: false,
  notifyUploadStart: () => {},
  notifyUploadFinish: () => {},
});

function withUploadContext<T extends Record<string, unknown>>(
  Component: React.ComponentType<T>,
) {
  const UploadContextProvider = (props: T) => {
    const [uploadsInProgressCount, setUploadsInProgressCount] =
      React.useState(0);
    const notifyUploadStart = () => {
      setUploadsInProgressCount((value) => value + 1);
    };
    const notifyUploadFinish = () => {
      setUploadsInProgressCount((value) => value - 1);
    };

    return (
      <UploadContext.Provider
        value={{
          hasUploadInProgress: uploadsInProgressCount > 0,
          notifyUploadStart,
          notifyUploadFinish,
        }}
      >
        <Component {...props} />
      </UploadContext.Provider>
    );
  };

  return UploadContextProvider;
}

const useUploadContext = () => {
  return React.useContext(UploadContext);
};

export { useUploadContext, withUploadContext };
