import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import type {
  AccidentArea,
  AccidentReason,
  AccidentAreaOption,
  AccidentReasonOption,
} from 'gql/graphql';
import { useDamagesQuery } from 'gql/graphql';
import { Modal } from 'shared/components/Modal';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from 'shared/components/Typography';
import { useInspection } from 'shared/components/InspectionContext';
import { useTranslation } from 'shared/hooks/useTranslation';
import { scrollToError } from 'shared/utils/scrollToError';
import { AREA_LABELS } from '../constants';
import type { ReasonByArea, ImageData } from '../../../types';
import type { ReasonsForm } from './types';
import { ReasonOption } from './ReasonOption';
import { getAccidentAreaSchema } from './schema';
import { filterDamagesByArea } from '../../../utils';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
  accidentArea: AccidentArea;
  accidentAreaOptions?: AccidentAreaOption[] | null;
  selectedReasons?: ReasonByArea;
  onSelectReasons: (
    reasons: Partial<Record<AccidentReason, (File | ImageData)[]>>,
  ) => void;
};

type NotNullReason = Omit<AccidentReasonOption, 'reason'> & {
  reason: AccidentReason;
};

type NonNullableReasons = Record<
  keyof ReasonsForm['root'],
  NonNullable<ReasonsForm['root'][keyof ReasonsForm['root']]>
>;

function sanitizeReasons(reasons: ReasonsForm['root']) {
  return Object.entries(reasons).reduce(
    (acc, [reason, images]) =>
      images === null || images === undefined
        ? acc
        : {
            ...acc,
            [reason]: images,
          },
    {} as NonNullableReasons,
  );
}

const ReasonsModal: React.FC<Props> = ({
  qaIdPrefix,
  accidentArea,
  selectedReasons,
  accidentAreaOptions,
  onSelectReasons,
}) => {
  const [initialReasons] = React.useState({ ...selectedReasons });
  const { translations, translate: t } = useTranslation();
  const { inspectionId } = useInspection();
  const { data, loading } = useDamagesQuery({
    variables: { inspectionId },
    fetchPolicy: 'cache-first',
  });
  const damages = React.useMemo(
    () =>
      filterDamagesByArea(
        data?.inspection?.vehicle?.damages ?? [],
        accidentArea,
      ),
    [data?.inspection?.vehicle?.damages, accidentArea],
  );
  const reasons = React.useMemo(
    () =>
      accidentAreaOptions
        ?.find(({ area }) => accidentArea === area)
        ?.reasons?.filter(
          (accidentReason): accidentReason is NotNullReason =>
            !!accidentReason.reason,
        ),
    [accidentAreaOptions, accidentArea],
  );

  if (!reasons) {
    throw new Error('No accidentAreaOptions');
  }

  const formMethods = useForm<ReasonsForm>({
    mode: 'onSubmit',
    resolver: yupResolver(getAccidentAreaSchema(reasons, translations, t)),
    defaultValues: {
      root: selectedReasons,
    },
  });
  const closeHandler = () => {
    onSelectReasons(initialReasons);
  };
  const submitHandler = formMethods.handleSubmit(
    (values) => {
      onSelectReasons(sanitizeReasons({ ...values.root }));
    },
    () => {
      setTimeout(() => {
        scrollToError(
          `[data-qa-id="${qaIdPrefix}-modal"] [data-qa-id*="error-message"]`,
        );
      });
    },
  );
  const rootError = React.useMemo(
    () => formMethods.formState.errors?.root,
    [formMethods.formState.errors?.root],
  );

  return (
    <Modal
      qaIdPrefix={`${qaIdPrefix}-modal`}
      title={t(AREA_LABELS[accidentArea])}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      okLabel={translations.CONFIRM}
      onClose={closeHandler}
      onCancel={closeHandler}
      onOk={submitHandler}
      isOpen
    >
      <Typography>{translations.ACCIDENT_REASON_MODAL_SUBTITLE}</Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={submitHandler}>
          {loading && 'loading reasons...'}
          {!loading &&
            reasons.map(({ reason }) => (
              <ReasonOption
                key={reason}
                reason={reason}
                value={selectedReasons?.[reason]}
                qaIdPrefix={`reason-${kebabCase(reason)}`}
                damages={damages}
              />
            ))}
          {rootError && 'message' in rootError && rootError.message && (
            <Typography tag="p" variant="textXSRed">
              {rootError.message}
            </Typography>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export { ReasonsModal };
