import { useEffect, useCallback } from 'react';

const scrollToSection = (hash?: string | null) => {
  if (!hash) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    return;
  }

  const element = document.querySelector(hash);

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

const useScrollToSection = (hash?: string | null) => {
  const scroll = useCallback(() => scrollToSection(hash), [hash]);

  useEffect(() => {
    scrollToSection(hash);
  }, [hash]);

  return {
    scroll,
  };
};

export { useScrollToSection };
