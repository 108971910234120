import * as React from 'react';
import { ValidationError } from 'yup';
import { useTranslation } from 'shared/hooks/useTranslation';
import { RoutesEnum } from 'shared/utils/route';
import {
  getSectionProgress,
  getPageProgress,
  getSectionErrorsYup,
} from 'shared/utils/calculateProgress';
import { useInspection } from 'shared/components/InspectionContext';
import { isFormWithData } from '../helpers';
import { TiresBrakesSchema } from '../tires-brakes-schema';
import type { Form } from '../types';

const WHEELS_NB = 4;
const sections = [
  {
    sectionName: RoutesEnum.TIRES_AND_BRAKES_INSTALLED_WHEELS,
    prefix: 'primaryWheels',
    requiredFieldsNb: 5,
  },
  {
    sectionName: RoutesEnum.TIRES_AND_BRAKES_EXTRA_WHEELS,
    prefix: 'additionalWheels',
    requiredFieldsNb: 3,
  },
] as const;

const isOptional = ({
  formValues,
  isPdfInspection,
}: {
  formValues: Form;
  isPdfInspection: boolean;
}) => {
  const { primaryWheels = [], additionalWheels = [] } = formValues;
  const formHasData = isFormWithData(primaryWheels, additionalWheels);
  return isPdfInspection && !formHasData;
};

const useProgress = () => {
  const { setProgress, isPdfInspection } = useInspection();
  const { translations } = useTranslation();
  const getTiresBrakesProgress = React.useCallback(
    async (formValues: Form) => {
      const progressArray: number[] = [];
      const validationSchema = TiresBrakesSchema(
        translations,
        isOptional({ formValues, isPdfInspection }),
      );
      try {
        await validationSchema.validate(formValues, { abortEarly: false });
        const sectionsProgress = sections.reduce((acc, { sectionName }) => {
          acc[sectionName] = 100;

          return acc;
        }, {} as Record<typeof sections[number]['sectionName'], number>);

        setProgress({
          [RoutesEnum.TIRES_AND_BRAKES]: 100,
          ...sectionsProgress,
        });
      } catch (error) {
        const { inner: errors } = error as ValidationError;
        const sectionsProgress = sections.reduce(
          (acc, { sectionName, requiredFieldsNb, prefix }) => {
            const totalFieldsNb = requiredFieldsNb * WHEELS_NB;
            const sectionErrors = getSectionErrorsYup(errors, prefix);
            const progress = getSectionProgress({
              errors: sectionErrors,
              totalFieldsNb,
            });

            if (formValues?.[prefix]?.length > 0) {
              progressArray.push(progress);
            }
            acc[sectionName] = progress;

            return acc;
          },
          {} as Record<typeof sections[number]['sectionName'], number>,
        );

        setProgress({
          [RoutesEnum.TIRES_AND_BRAKES]: getPageProgress(progressArray),
          ...sectionsProgress,
        });
      }
    },
    [translations, isPdfInspection, setProgress],
  );

  return getTiresBrakesProgress;
};

export { useProgress };
