import * as React from 'react';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import styles from './index.module.scss';

const HighlightsPlaceholder: React.FC = () => (
  <Card
    paddingTop={32}
    paddingBottom={48}
    externalStyle={styles.card}
    id="highlights"
  >
    <Typography variant="titleXL" tag="div" additonalClassNames={styles.title}>
      <Skeleton width="106px" height="100%" />
    </Typography>
    <Skeleton width="160px" height="40px" />
  </Card>
);

export { HighlightsPlaceholder };
