import get from 'lodash/get';

const keyExtractor = (
  obj: {
    id?: string;
    hash?: string;
    [key: string]: unknown;
  },
  index: number,
) => {
  return get(obj, 'id', get(obj, 'hash', index));
};

export { keyExtractor };
