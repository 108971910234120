import * as React from 'react';
import cn from 'classnames';
import { Checkbox } from '@auto1-ui/checkbox';

import { withFormItem } from '../withFormItem';
import styles from './index.module.scss';

type Props = Omit<
  React.ComponentProps<typeof Checkbox>,
  'children' | 'value' | 'label' | 'extraClass'
> & {
  uiLabel?: string;
  value: boolean;
  className?: string;
};

const CheckboxCustom: React.FC<Props> = ({
  uiLabel,
  value,
  className,
  ...props
}) => (
  <Checkbox
    {...props}
    value=""
    extraClass={cn(styles.label, className)}
    label={uiLabel}
    isChecked={!!value}
  />
);

const FormCheckbox = withFormItem(CheckboxCustom, {
  reverseLabel: true,
});

export { FormCheckbox, CheckboxCustom };
