import * as React from 'react';
import styles from './index.module.scss';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      {/* this component can be used for adding header, snackbar, common notifications or something which will be common to every screen with the app */}
      <div className={styles.container}>
        <div id="alert-container" className={styles.alertContainer}></div>
        <div className={styles.pages}>{children}</div>
      </div>
    </>
  );
};

export { Layout };
