import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'shared/hooks/useTranslation';

import styles from './index.module.scss';

type Props = {
  qaPrefix?: string;
  backButtonLink?: string;
  withBackButton?: boolean;
  rightAreaContent: React.ReactNode;
  bottomAreaContent?: React.ReactNode;
};

const NavigationFooter: React.FC<Props> = ({
  qaPrefix = 'navigation',
  backButtonLink = null,
  withBackButton = true,
  rightAreaContent,
  bottomAreaContent = null,
}) => {
  const history = useHistory();
  const { translations } = useTranslation();
  const handleBackButtonClick = () => {
    if (backButtonLink) {
      return history.push(backButtonLink);
    }
    return history.goBack();
  };
  return (
    <div
      className={styles.navigationFooter}
      data-qa-id={`${qaPrefix}-footer-wrapper`}
    >
      <div
        className={styles.container}
        data-qa-id={`${qaPrefix}-footer-button-container`}
      >
        <div
          className={styles.content}
          data-qa-id={`${qaPrefix}-footer-back-link-container`}
        >
          {withBackButton && (
            <button
              data-qa-id={`${qaPrefix}-footer-back-link`}
              className={styles.goBackLink}
              onClick={handleBackButtonClick}
              type="button"
            >
              {translations.BACK_BUTTON}
            </button>
          )}

          {rightAreaContent}
        </div>
        {bottomAreaContent}
      </div>
    </div>
  );
};

export { NavigationFooter };
