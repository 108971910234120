export const testDriveQuestions = {
  PERFORMED: 1010,
  NOT_PERFORMED: 1020,
  LOCATION: 1010,
  SPEEDOMETER_OK: 10100,
  ENGINE_OK: 10150,
  GEARS_OK: 10280,
  STEERING_OK: 10460,
  SUSPENSION_OK: 10550,
  BRAKES_OK: 10640,
  OTHER_NOISE_LEVEL_OK: 10710,
  AC_AVAILABLE: 10790,
  NAVIGATION_SYSTEM_AVAILABLE: 10860,
  HEATED_SEATS_OK: 10940,
  SPEEDOMETER_OK_NO_RESPONSE: 10120,
  ENGINE_PROBLEMS_WHEN: 10180,
  ENGINE_PROBLEMS_WHICH: 10220,
  GEARS_OK_NO_RESPONSE: 10300,
  GEARS_MANUAL: 10310,
  GEARS_AUTOMATIC: 10380,
  STEERING_OK_NO_RESPONSE: 10480,
  SUSPENSION_OK_NO_RESPONSE: 10570,
  BRAKES_OK_NO_RESPONSE: 10660,
  NOISE_LEVEL_OK_NO_RESPONSE: 10730,
  AC_OK_NO_RESPONSE: 10820,
  NAVIGATION_SYSTEM_OK_NO_RESPONSE: 10890,
  HEATED_SEATS_OK_NO_RESPONSE: 10970,
} as const;

export const FINDINGS_QUESTION_IDS = [
  testDriveQuestions.SPEEDOMETER_OK,
  testDriveQuestions.ENGINE_OK,
  testDriveQuestions.GEARS_OK,
  testDriveQuestions.STEERING_OK,
  testDriveQuestions.SUSPENSION_OK,
  testDriveQuestions.BRAKES_OK,
  testDriveQuestions.OTHER_NOISE_LEVEL_OK,
  testDriveQuestions.AC_AVAILABLE,
  testDriveQuestions.NAVIGATION_SYSTEM_AVAILABLE,
  testDriveQuestions.HEATED_SEATS_OK,
] as const;

export const FINDINGS_SECTION_QUESTION_IDS = [
  testDriveQuestions.SPEEDOMETER_OK,
  testDriveQuestions.ENGINE_OK,
  testDriveQuestions.GEARS_OK,
  testDriveQuestions.STEERING_OK,
  testDriveQuestions.SUSPENSION_OK,
  testDriveQuestions.BRAKES_OK,
  testDriveQuestions.OTHER_NOISE_LEVEL_OK,
  testDriveQuestions.AC_AVAILABLE,
  testDriveQuestions.NAVIGATION_SYSTEM_AVAILABLE,
  testDriveQuestions.HEATED_SEATS_OK,
  testDriveQuestions.SPEEDOMETER_OK_NO_RESPONSE,
  testDriveQuestions.ENGINE_PROBLEMS_WHEN,
  testDriveQuestions.ENGINE_PROBLEMS_WHICH,
  testDriveQuestions.GEARS_OK_NO_RESPONSE,
  testDriveQuestions.GEARS_MANUAL,
  testDriveQuestions.GEARS_AUTOMATIC,
  testDriveQuestions.STEERING_OK_NO_RESPONSE,
  testDriveQuestions.SUSPENSION_OK_NO_RESPONSE,
  testDriveQuestions.BRAKES_OK_NO_RESPONSE,
  testDriveQuestions.NOISE_LEVEL_OK_NO_RESPONSE,
  testDriveQuestions.AC_OK_NO_RESPONSE,
  testDriveQuestions.NAVIGATION_SYSTEM_OK_NO_RESPONSE,
  testDriveQuestions.HEATED_SEATS_OK_NO_RESPONSE,
] as const;
