import * as React from 'react';
import kebabCase from 'lodash/kebabCase';
import type {
  AccidentArea,
  AccidentReason,
  AccidentAreaOption,
  AccidentReasonOption,
} from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { AREA_LABELS, REASON_LABELS } from '../constants';
import type { Form } from '../../../types';
import { areaIsEmpty } from '../../../utils';
import styles from './index.module.scss';

type Props = {
  areas: Form['areas'];
  accidentAreaOptions: AccidentAreaOption[];
};

function filterEmptyAreas(areas: Form['areas']) {
  return function (
    accidentAreaOption: AccidentAreaOption,
  ): accidentAreaOption is AccidentAreaOption & {
    reasons: NonNullable<AccidentAreaOption['reasons']>;
  } {
    return !areaIsEmpty(accidentAreaOption.area, areas);
  };
}

function filterEmptyReasons(area: AccidentArea, areas: Form['areas']) {
  return function (
    accidentReason: AccidentReasonOption,
  ): accidentReason is AccidentReasonOption & {
    reason: NonNullable<AccidentReasonOption>;
  } {
    return (
      !!accidentReason.reason &&
      !!areas[area] &&
      accidentReason.reason in areas[area] &&
      !!areas[area][accidentReason.reason]
    );
  };
}

const AreasList: React.FC<Props> = ({ areas, accidentAreaOptions }) => {
  const { translate: t } = useTranslation();
  const reasons = accidentAreaOptions
    .filter(filterEmptyAreas(areas))
    .map(({ area, reasons }) => ({
      area,
      reasons: reasons.filter(filterEmptyReasons(area, areas)),
    }));

  return (
    <div className={styles.reasonDescription} data-qa-id="reasons-list">
      {areas &&
        reasons.map(({ area, reasons }) => (
          <ul
            className={styles.reasonsList}
            key={area}
            data-qa-id={`${kebabCase(area)}-reasons-summary`}
          >
            <strong>{t(AREA_LABELS[area as AccidentArea])}</strong>
            {reasons?.map(({ reason }) => {
              return (
                <li
                  data-qa-id={`${kebabCase(area)}-reasons-summary-${kebabCase(
                    reason,
                  )}`}
                  key={reason}
                >
                  {t(REASON_LABELS[reason as AccidentReason])}
                </li>
              );
            })}
          </ul>
        ))}
    </div>
  );
};

export { AreasList };
